<template>
  <b-card
    :bg-variant="this.color"
    text-variant="white"
    :header="this.title"
    class="text-center"
  >
    <b-card-text>
      <h1>{{ this.count }}</h1>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "CountCard",
  props: ["count", "color", "title"],
  data() {
    return {
      records: []
    };
  },
  created() {}
};
</script>
