<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-text>
          <div>
            <CRUD
              title="Section"
              :path="this.$store.state.subject + '/sections'"
              :fields="this.fields"
              width="500"
              height="300"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
export default {
  name: "Sections-page",
  components: {
    CRUD
  },

  data() {
    return {
      fields: [
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>
