<template>
  <b-row>
    <v-row align="center" justify="end">
      <download-excel
        class="btn btn-default"
        :data="admission"
        :fields="fexport"
        type="csv"
        name="admission.xls"
      >
        <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
        <small style="color:#ffffff;">Export to Excel</small>
      </download-excel>
    </v-row>
    <b-col cols="12">
      <b-card-group deck>
        <b-card header="Total Applications" class="text-center">
          <b-card-text>
            <div class="row" style="margin-top:50px">
              <div class="col-5">
                <div class="icon-big text-center">
                  <b-icon icon="people" scale="7" color="gray"></b-icon>
                </div>
              </div>
              <div class="col-7">
                <div class="numbers">
                  <h1 class="card-title">{{ admission.length }}</h1>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-card-group>

      <br />

      <v-card>
        <!--     <v-card-text>
          <v-card dark style="background-color:#1b2e47">
            <v-card-title>
              <v-icon large left>mdi-calendar-month</v-icon>
              <span class="title font-weight-light">Applications List</span>

              <v-row align="center" justify="end">
                <download-excel
                  class="btn btn-default"
                  :data="admission"
                  :fields="fexport"
                  type="csv"
                  name="admission.xls"
                >
                  <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
                  <small style="color:#ffffff;">Export to Excel</small>
                </download-excel>
              </v-row>
            </v-card-title>

  
            <v-row class="card-text">
              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="schools"
                  v-model="student.school"
                  label="School"
                  @change="onSchoolChange()"
                ></v-select>
              </v-col>
           

           <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="level"
                  v-model="student.grade"
                  label="Grade Level"
                  @change="onGradeChange()"
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="4" sm="4">
                <v-autocomplete
                  v-model.trim="student.status"
                  :items="status"
                  index="type"
                  label="Status"
                  @change="onStatusChange()"
                ></v-autocomplete>
              </v-col>
              <v-col class="d-flex" cols="4" sm="4">
                <b-form-datepicker
                  dark
                  v-model="date"
                  locale="en"
                ></b-form-datepicker>
              </v-col>
              <v-col class="d-flex" cols="4" sm="4">
                <b-button variant="info" @click="search"
                  ><v-icon>mdi-magnify</v-icon> Search</b-button
                >
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer
              ><b-button variant="success" @click="clearSearch"
                >Clear Search</b-button
              ></v-card-actions
            >
          </v-card>
        </v-card-text>-->

        <p class="card-text">
          <vue-good-table
            :columns="fields"
            :rows="admission"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteboard(props.row.key, props.row.firstname)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </button>
                &nbsp;
                <button
                  class="btn btn-warning btn-sm"
                  @click="Edit(props.row.path, 'copy')"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </button>
                &nbsp;
                <!--   <button
                  class="btn btn-info btn-sm"
                  @click="Edit(props.row.path)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </button>-->

                <EditApplication
                  :path="props.row.path"
                  :key="props.row.key"
                ></EditApplication>

                &nbsp;
                <ViewApplication
                  :path="props.row.path"
                  :key="props.row.key"
                ></ViewApplication>

                &nbsp;
              </span>

              <span v-else-if="props.column.field == 'name'">{{
                props.row.firstname +
                  " " +
                  props.row.middlename +
                  " " +
                  props.row.lastname
              }}</span>
            </template>
          </vue-good-table>
        </p>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import EditApplication from "@/components/admission/EditApplication";
import ViewApplication from "@/components/admission/ViewApplication";

export default {
  name: "AdmissionList",
  components: { EditApplication, ViewApplication },

  data() {
    return {
      fields: [
        {
          field: "application_date",
          label: "Application Date",
          sortable: true,
          class: "text-left"
        },

        {
          field: "mother_mobile",
          label: "Mother Mobile",
          sortable: true,
          class: "text-left"
        },

        // {
        //   field: "admission_date",
        //   label: "Date",
        //   sortable: true,
        //   class: "text-left"
        // },
        // {
        //   field: "admission_time",
        //   label: "Time",
        //   sortable: true,
        //   class: "text-left"
        // },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        { field: "grade", label: "Grade", sortable: true, class: "text-left" },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Actions",
          class: "text-center",
          width: "220px"
        }
      ],
      fexport: {
        "School Name": "branch",
        "Grade Name": "grade",
        Class: "class",
        "Student Name": "studentname",
        "National ID": "nationalID",

        father_nationalID: "father_nationalID",
        mother_nationalID: "mother_nationalID",
        Citizenship: "citizenship",
        "Date Of Birth": "date_of_birth",
        "Father Academic Degree": "father_degree",
        "Father Company Business": "father_company",

        father_mobile: "father_mobile",
        father_occupation: "father_occupation",
        "Mother Academic Degree": "mother_degree",
        mother_email: "mother_email",
        mother_mobile: "mother_mobile",
        mother_name: "mother_name",
        mother_occupation: "mother_occupation",
        religion: "religion",
        second_language: "second_language"
      },
      date: "",
      admission: [],
      errors: [],

      student: [],
      schools: [],
      level: [],

      accepted: [],
      progress: [],
      rejected: [],
      status: [],

      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/infos"),

      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.admission = [];
      querySnapshot.forEach(doc => {
        this.admission.push({
          key: doc.id,
          path: doc.ref.path,
          branch: doc.data().branch,
          status: doc.data().admission_status,
          father_nationalID: doc.data().father_nationalID,
          mother_nationalID: doc.data().mother_nationalID,
          class: doc.data().class,

          laststatus: doc.data().admission_current,
          grade: doc.data().grade,
          firstname: doc.data().firstname,
          middlename: doc.data().middlename,
          lastname: doc.data().lastname,
          nationalID: doc.data().nationalID,
          studentname:
            doc.data().firstname +
            " " +
            doc.data().middlename +
            " " +
            doc.data().lastname,
          admission_date: doc.data().admission_date,
          admission_time: doc.data().admission_time,

          address: doc.data().address,

          application_date: doc.data().application_date,

          citizenship: doc.data().citizenship,
          date_of_birth: doc.data().date_of_birth,
          father_degree: doc.data().father_degree,
          father_company: doc.data().father_company,
          father_email: doc.data().father_email,

          father_mobile: doc.data().father_mobile,

          father_occupation: doc.data().father_occupation,

          mother_degree: doc.data().mother_degree,
          mother_email: doc.data().mother_email,
          mother_mobile: doc.data().mother_mobile,
          mother_name: doc.data().mother_name,

          mother_occupation: doc.data().mother_occupation,
          previous_school: doc.data().previous_school,
          religion: doc.data().religion,
          second_language: doc.data().second_language,
          admission_current: doc.data().admission_current
        });
      });
    });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  },
  methods: {
    clearSearch() {
      this.ref.onSnapshot(querySnapshot => {
        this.admission = [];
        querySnapshot.forEach(doc => {
          this.admission.push({
            key: doc.id,
            path: doc.ref.path,
            branch: doc.data().branch,
            status: doc.data().admission_status,
            laststatus: doc.data().admission_current,
            grade: doc.data().grade,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            lastname: doc.data().lastname,
            studentname:
              doc.data().firstname +
              " " +
              doc.data().middlename +
              " " +
              doc.data().lastname,
            admission_date: doc.data().admission_date,
            admission_time: doc.data().admission_time,

            address: doc.data().address,

            application_date: doc.data().application_date,

            citizenship: doc.data().citizenship,
            date_of_birth: doc.data().date_of_birth,
            father_academic_degree: doc.data().father_academic_degree,
            father_company_business: doc.data().father_company_business,
            father_email: doc.data().father_email,

            father_mobile: doc.data().father_mobile,

            father_occupation: doc.data().father_occupation,

            mother_academic_degree: doc.data().mother_academic_degree,
            mother_email: doc.data().mother_email,
            mother_mobile: doc.data().mother_mobile,
            mother_name: doc.data().mother_name,

            mother_occupation: doc.data().mother_occupation,
            previous_school: doc.data().previous_school,
            religion: doc.data().religion,
            second_language: doc.data().second_language,
            admission_current: doc.data().admission_current
          });
        });
      });
    },

    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/infos")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    onGradeChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/infos")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .onSnapshot(querySnapshot => {
          this.admission = [];
          querySnapshot.forEach(doc => {
            this.admission.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,
              grade: doc.data().grade,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().father_email,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current
            });
          });
        });
    },
    search() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/infos")
        .where("branch", "==", this.student.school)
        .where("admission_date", "==", this.date)
        .onSnapshot(querySnapshot => {
          this.admission = [];
          querySnapshot.forEach(doc => {
            this.admission.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,
              grade: doc.data().grade,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().father_email,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current
            });
          });
        });
    },
    onStatusChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/infos")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .where("admission_current", "==", this.student.status)
        .onSnapshot(querySnapshot => {
          this.admission = [];
          querySnapshot.forEach(doc => {
            this.admission.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,
              grade: doc.data().grade,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().father_email,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current
            });
          });
        });
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/"
        )
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    Edit(id, type) {
      router.push({
        name: "EditApplication",
        params: { path: id, type: type }
      });
    },
    View(id, type) {
      router.push({
        name: "ViewApplication",
        params: { path: id, type: type }
      });
    },
    Timeline(id, student) {
      router.push({
        name: "TimelineList",
        params: { path: id, student: student }
      });
    }
  }
};
</script>
