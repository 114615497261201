<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          Exam Answers
          <v-divider />

          <v-text-field
            style="width:10px"
            label="Overall Grade"
            placeholder="Grade"
            v-model="finalgrade"
            outlined
          ></v-text-field>
          / {{ examgrade }}
          <v-btn
            color="success"
            depressed
            x-small
            @click="saveGrade"
            style="margin-left:10px"
            ><v-icon>mdi-check</v-icon>Save Grade</v-btn
          >
        </v-card-title>
        <v-card-subtitle>{{ studentname }}</v-card-subtitle>
        <v-card-text>
          <v-card
            flat
            outlined
            v-for="(answer, index) in answers"
            :key="answer.key"
            style="margin-bottom:10px"
          >
            <v-card-title>Question {{ index + 1 }} </v-card-title>
            <v-card-subtitle>
              <v-chip color="info" small label
                >Weight: {{ answer.weight }}</v-chip
              >
            </v-card-subtitle>
            <v-card-text>
              <QuestionPreview :path="answer.question" />

              <v-card flat outlined style="margin-top:10px;">
                <v-card-subtitle style="color:#121212;  ">
                  <v-row>
                    <div
                      v-if="answer.qtype != 'Essay'"
                      style="margin-right:10px;"
                    >
                      <v-chip
                        color="success"
                        small
                        label
                        v-if="answer.answer == 'Right'"
                        >{{ answer.answer }}</v-chip
                      >
                      <v-chip color="error" small label v-else>{{
                        answer.answer
                      }}</v-chip>
                    </div>

                    Student Answer
                  </v-row>
                </v-card-subtitle>
                <v-card-text> {{ answer.studentanswer }}</v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-col cols="4">
                <v-text-field
                  label="Question Grade"
                  type="number"
                  v-model="answer.right"
                />
                <v-btn
                  color="#546E7A"
                  dark
                  small
                  depressed
                  @click="saveQuestionGrade(answer.path, answer.right)"
                  >Override Grade</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase";
import QuestionPreview from "./QuestionPreview";
export default {
  name: "Test-page",
  components: { QuestionPreview },

  data() {
    return {
      studentname: localStorage.getItem("studentname"),
      studentpath: localStorage.getItem("studentpath"),

      exampath: localStorage.getItem("exampath"),
      examtitle: localStorage.getItem("examtitle"),
      examgrade: localStorage.getItem("examgrade"),

      answers: [],
      subject: localStorage.getItem("subjectTitle"),
      grade: 0,
      totalweight: 0,
      defultgrade: 0,
      finalgrade: 0
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.studentpath + "/answers")
      .get()
      .then(querySnapshot => {
        this.answers = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          this.answers.push({
            key: doc.id,
            path: doc.ref.path,
            answer: data.answer,
            studentanswer: data.studentanswer,
            grade: data.grade,
            qtype: data.qtype,
            weight: data.weight,
            question: data.question,

            right: data.answer == "Right" ? 1 * data.weight : 0
          });
          this.totalweight += Number(data.weight);
          this.grade += data.answer == "Right" ? 1 * data.weight : 0;

          this.defultgrade = this.totalweight / this.grade;
          this.finalgrade = this.grade * this.defultgrade;
        });
      });

    firebase
      .firestore()
      .doc(this.studentpath)
      .onSnapshot(querySnapshot => {
        this.grade = querySnapshot.data().grade;
      });
  },

  props: {},
  methods: {
    saveGrade() {
      firebase
        .firestore()
        .doc(this.studentpath)
        .set({ grade: Number(this.grade) })
        .then(() => {
          this.$swal.queue([
            {
              icon: "success",
              title: "Grade Updated",
              html: "Grade Updated Successfully",
              timer: 2000
            }
          ]);
        });
    },
    saveQuestionGrade(path, grade) {
      firebase
        .firestore()
        .doc(path)
        .update({ right: Number(grade) })
        .then(() => {
          this.$swal.queue([
            {
              icon: "success",
              title: "Grade Updated",
              html: "Grade Updated Successfully",
              timer: 2000
            }
          ]);
        });
    }
  }
};
</script>
