<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon size="20" color="light-blue darken-4">{{ this.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ this.title }}</v-list-item-title>
    <v-list-item-subtitle>{{ this.value }}</v-list-item-subtitle>
  </v-list-item>
</template>
<script>
export default {
  name: "Field-page",
  props: ["title", "value", "icon"],
  data() {
    return {};
  }
};
</script>
