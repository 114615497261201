import { render, staticRenderFns } from "./FixedQuestion.vue?vue&type=template&id=ded70aec&scoped=true&"
import script from "./FixedQuestion.vue?vue&type=script&lang=js&"
export * from "./FixedQuestion.vue?vue&type=script&lang=js&"
import style0 from "./FixedQuestion.vue?vue&type=style&index=0&id=ded70aec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ded70aec",
  null
  
)

export default component.exports