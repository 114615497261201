<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Fixed Assets"
          color="primary"
          :path="'/schools/' + this.$store.state.school + '/fixedassets'"
        ></CountCard>
        <CountCard title="Depreciation" color="success"></CountCard>
        <CountCard title="Total Value" color="danger"></CountCard>
      </b-card-group>
      <br />

      <v-card>
        <v-card-title>Add New Fixed Asset</v-card-title>
        <v-card-text>
          <v-card>
            <v-card-title>
              <v-divider />
              <v-icon size="100" color="orange"
                >mdi-barcode</v-icon
              ></v-card-title
            >

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Scan Code" v-model.trim="code" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card style="margin-top:20px"
            ><v-card-text style="font-size:18px;line-height: 1.8;">
              <v-list flat>
                <v-list-item-group color="primary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-city</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b style="color:green">Location:</b>
                        {{
                          this.locations[0][this.code.substr(0, 2)]
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b style="color:green">School:</b>
                        {{
                          this.school[0][this.code.substr(2, 1)]
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-clipboard-list</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b style="color:green">Stage:</b>
                        {{
                          this.stage[0][this.code.substr(3, 1)]
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-shape</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b style="color:green">Category:</b>
                        {{
                          this.category[0][this.code.substr(4, 1)]
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-sitemap</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b style="color:green">Item:</b>
                        {{
                          this.item[0][this.code.substr(4, 3)]
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-barcode-scan</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><b style="color:green">Serial:</b>
                        {{ this.code.substr(7, 7) }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn depressed color="success" @click="onSubmit" small dark
                ><v-icon size="14">mdi-plus</v-icon> Add Item</v-btn
              >
            </v-card-actions>
          </v-card>

          <div>
            <CRUD
              title="Asset"
              :path="'/schools/' + this.$store.state.school + '/fixedassets'"
              :fields="this.fields"
              width="500"
              height="900"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import firebase from "@/Firebase";

import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "FixedAssets",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      code: "",
      fixedItem: {},

      fixeds: [],
      locations: [
        {
          "01": "م.نصر",
          "02": "التجمع",
          "03": "الشروق",
          "04": "بورسعيد",
          "05": "اكتوبر",
          "06": "العاصمة 1",
          "07": "العاصمة 2"
        }
      ],
      school: [
        {
          "1": "امريكية",
          "2": "فرنسية",
          "3": "انجليزية",
          "4": "بريطانية",
          "5": "دبلومة امريكية",
          "6": "ادارى"
        }
      ],
      // stage: [
      //   {
      //     "1": "PS",
      //     "2": "KG",
      //     "3": "PR1",
      //     "4": "PR7",
      //     "5": "SC10"
      //   }
      // ],
      stage: [
        {
          "01": "Pre KG",
          "02": "KG 1",
          "03": "KG 2",
          "04": "Grade 1",
          "05": "Grade 2",
          "06": "Grade 3",
          "07": "Grade 4",
          "08": "Grade 5",
          "09": "Grade 6",
          "10": "Grade 7",
          "11": "Grade 8",
          "12": "Grade 9",
          "13": "Grade 10",
          "14": "Grade 11",
          "15": "Grade 12"
        }
      ],
      category: [
        {
          "1": "اثاث فصول ومهمات مدارس و تجهيزات معامل",
          "2": "اثاث ومهمات ادارية",
          "3": "اجهزه كهربائيه",
          "4": "اجهزه كمبيوتر"
        }
      ],
      item: [
        {
          "101": "سبورات تفاعلية بالبورجيكتور",
          "102": "سبورات فصول",
          "103": "بروجيكتور",
          "105": "ستائر فصول",
          "106": "دواليب ومكتبات",
          "107": "موكيت وسجاد",
          "108": "كاميرات مراقبة فصول ودور",
          "109": "ديسكات فصول",
          "110": "كراسى فصول",
          "111": "اثاث وتجهيزات معامل",
          "112": "اجهزة معامل",
          "113": "وحدات لوكرز",
          "114": "العاب حضانة",
          "115": "شاشه سمارت",
          "116": "تكييف",
          "117": "كرسي مدرس",
          "118": "مكتب مدرس",

          "201": "مكاتب",
          "202": "كراسى عجل",
          "203": "كراسى انتظار",
          "204": "اطقم جلد",
          "205": "مكتبات",
          "206": "ترابيزة اجتماعات",
          "207": "ستائر مكاتب",
          "208": "سجاد مكاتب",
          "209": "ابواب مكاتب",
          "210": "شبابيك الموتال",
          "211": "باركيه وسيراميك ودهانات",
          "212": "براويز وساعات حائط",
          "213": "خزانات مياه",
          "214": "خزائن حديدية",
          "215": "كرفانات وحاويات",
          "216": "وحدات مخازن",
          "217": "مظلة هرمية",
          "218": "دكك حديد * خشب",

          "301": "تكيفات",
          "302": "ثلاجه",
          "303": "لوازم اذاعه مدرسية وحفلات",
          "304": "ماكينة فرم مستندات",
          "305": "عدد محمول ولاسلكى",
          "306": "ماكينات حضور وإنصراف",
          "307": "شاشات TV",
          "308": "مراوح",
          "309": "مبردات مياه",
          "310": "سخانات كهربائية",
          "311": "شفاطات ومكانس كهربائية",
          "312": "مواتير مياه",
          "313": "بوتجازات",
          "314": "كاسيت",
          "315": "غسالات كهربائية",
          "316": "فاكس",
          "317": "مولدات كهربائية",
          "318": "ماكينات حياكة",
          "319": "ماكينة تصوير",
          "320": "دفايات كهربائية",
          "321": "مجففات",
          "322": "خلاطات كهربائية",
          "323": "مكواه بخار",
          "324": "افران وميكروويف وتوستر",
          "325": "وحدات اضاءة",
          "326": "كشافات طوارئ",
          "327": "الات موسيقية",
          "328": "كاميرات مراقبة",
          "329": "نظام اطفاء حريق",
          "330": "طرمبه نزح مياه",
          "331": "ماكينة قص نجيله",
          "332": "مثبت تيار",
          "333": "غلاية",
          "334": "نظام انتظار الكترونى",
          "335": "منشار كهربائى",
          "336": "اجراس",
          "337": "عدد وادوات  ومعدات",
          "338": "ستائر هواء للتكيفات",
          "339": "ماكينات تغليف",
          "340": "اجهزة جيم",
          "341": "تجهيزات ملاعب وصالات رياضية",

          "401": "طباعات",
          "402": "حاسبات",
          "403": "شاشات كمبيوتر",
          "404": "لاب توب",
          "405": "وحدات دخول للانترنت",
          "406": "سيرفر",
          "407": "DVD-- CD PLAYER",
          "408": "مستلزمات شبكة Net Work",
          "409": "طابعه ID ومستلزماتها",
          "410": "ماسح ضوئى Scaner",
          "411": "طابعة باركود",
          "412": "GPS اجهزة  تحكم سيارات"
        }
      ],
      fields: [
        {
          field: "code",
          label: "Code",
          sortable: true,
          class: "text-left"
        },
        {
          field: "location",
          label: "Location",
          sortable: true,
          class: "text-left"
        },
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "stage",
          label: "Stage",
          sortable: true,
          class: "text-left",
          ftype: "date"
        },
        {
          field: "category",
          label: "Category",
          sortable: true,
          class: "text-left",
          ftype: "date"
        },
        {
          field: "item",
          label: "Item",
          sortable: true,
          class: "text-left",
          ftype: "date"
        },
        {
          field: "serial",
          label: "Serial",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/fixedassets")
      .onSnapshot(querySnapshot => {
        this.fixeds = [];
        querySnapshot.forEach(doc => {
          this.fixeds.push({
            key: doc.id,
            path: doc.ref.path,
            code: doc.data().code,
            location: doc.data().location,
            school: doc.data().school,
            stage: doc.data().stage,
            category: doc.data().category,
            item: doc.data().item,
            serial: doc.data().serial
          });
        });
      });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.fixedItem.location = this.locations[0][this.code.substr(0, 2)];
      this.fixedItem.locationId = this.code.substr(0, 2);

      this.fixedItem.school = this.school[0][this.code.substr(2, 1)];
      this.fixedItem.schoolId = this.code.substr(2, 1);

      this.fixedItem.stage = this.stage[0][this.code.substr(3, 2)];
      this.fixedItem.stageId = this.code.substr(3, 2);

      this.fixedItem.category = this.category[0][this.code.substr(4, 1)];
      this.fixedItem.categoryId = this.code.substr(4, 1);

      this.fixedItem.item = this.item[0][this.code.substr(4, 3)];
      this.fixedItem.itemId = this.code.substr(4, 3);

      this.fixedItem.serial = this.code.substr(7, 7);

      this.fixedItem.code = this.code;

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/fixedassets")
        .doc(this.code)
        .set(this.fixedItem)
        .then(() => {
          this.code = "";
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },
    deleteItem(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(
                "schools/" + this.$store.state.school + "/fixedassets"
              )
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Item Deleted", {
                  title: "Item Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>

<style></style>
