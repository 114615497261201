<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Cars"
          color="primary"
          :path="'/schools/' + this.$store.state.school + '/fleets'"
        ></CountCard>
        <CountCard
          title="Drivers"
          color="success"
          :path="'/schools/' + this.$store.state.school + '/drivers'"
        ></CountCard>
        <CountCard
          title="Supervisors"
          color="danger"
          :path="'/schools/' + this.$store.state.school + '/supervisors'"
        ></CountCard>
      </b-card-group>
      <br />

      <v-card>
        <v-card-text>
          <div>
            <CRUD
              title="Supervisor"
              :path="'/schools/' + this.$store.state.school + '/supervisors'"
              :fields="this.fields"
              width="500"
              height="600"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "Supervisors-page",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      fields: [
        {
          field: "name",
          label: "Supervisor Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "nationalid",
          label: "National ID",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style></style>
