var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('h5',[_vm._v(_vm._s(this.student))]),(this.last)?_c('span',[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"info","x-small":"","depressed":""}},[_vm._v("View All")]),_c('b',[_vm._v("Last Attempt:")]),_vm._v(" "+_vm._s(this.last)+" "),_c('br'),_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"warning","x-small":"","depressed":""}},[_vm._v("Recalculate")]),_vm._v(" This Grade overridden by ")],1):_vm._e()]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"background-color":_vm.grade ? 'white' : 'red lighten-4',"label":"Grade","placeholder":"Placeholder","outlined":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('br'),_c('v-btn',{attrs:{"color":"info","block":"","depressed":"","small":""},on:{"click":function($event){return _vm.goToAnswers(
                _vm.student,
                'schools/' +
                  _vm.$store.state.school +
                  '/StudentsData/' +
                  _vm.id +
                  '/exams/' +
                  _vm.subject
              )}}},[_vm._v("Show Student Answers")]),_c('v-btn',{staticStyle:{"margin-top":"10px"},attrs:{"color":"success","block":"","depressed":"","small":""},on:{"click":_vm.saveGrade}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v("Save Grade")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }