<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ this.$route.params.job }}

          <v-divider /><img :src="this.logo" width="100px" />
        </v-card-title>
        <v-card-subtitle>Job Description</v-card-subtitle>
        <v-card-text>
          <v-list dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >Objective / Purpose of the Department:</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.res_focus" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >Job Objectives:</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.objective" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green">Authority:</b></v-list-item-title
                  >
                  <span v-html="this.job[0].job.authority" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >Functional Relationships:</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.relationships" />
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >ORGANIZATIONAL FOCUS & SCOPE</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.org_focus" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >INSTRUCTIONAL FOCUS & SCOPE</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.ins_focus" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >EXECUTIVE FOCUS & SCOPE</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.exe_focus" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >RESOURCES FOCUS & SCOPE</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.res_focus" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >ETHICS / BEHAVIORAL FOCUS & SCOPE</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.eth_focus" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green"
                      >Tools & Skills needed for the job:</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.tools" />
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-label-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><b style="color:green">
                      Essential Educational Background & Experience:</b
                    ></v-list-item-title
                  >
                  <span v-html="this.job[0].job.edu_background" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";

export default {
  data() {
    return {
      job: [],
      logo: ""
    };
  },
  created() {
    this.logo = localStorage.getItem("logo");
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/jobs")
      .where("title", "==", this.$route.params.job)
      .onSnapshot(querySnapshot => {
        this.job = [];
        querySnapshot.forEach(doc => {
          this.job.push({
            job: doc.data()
          });
        });
      });
  }
};
</script>
