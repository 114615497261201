import Vue from "vue";
import firebase from "@/Firebase";
import App from "./App.vue";
import store from "./store";
import VueRouter from "vue-router";
import router from "./router";
import "./plugins/element.js";
import {
    BootstrapVue,
    BootstrapVueIcons
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueConfirmDialog from "vue-confirm-dialog";
import VueGoodTablePlugin from "vue-good-table";
// import the styles
import "vue-good-table/dist/vue-good-table.css";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import VueEllipseProgress from "vue-ellipse-progress";

import ImageUploader from "vue-image-upload-resize";

import VModal from "vue-js-modal";

import {
    ValidationProvider
} from "vee-validate";
import {
    firestorePlugin
} from "vuefire";

import JsonExcel from "vue-json-excel";

import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import "./assets/css/main.css";

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';
import VueApexCharts from 'vue-apexcharts';
import Embed from 'v-video-embed';
import VueSweetalert2 from 'vue-sweetalert2';
import vuescroll from "vuescroll";
import VueHtmlToPaper from "vue-html-to-paper";
import AudioRecorder from 'vue-audio-recorder'



// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios';

import AxiosPlugin from 'vue-axios-cors';
import VueComp from '@vue/composition-api';
import VueyeTable from 'vueye-table';




Vue.use(VueyeTable);
Vue.use(VueComp);



Vue.use(AxiosPlugin);
Vue.use(axios);



const options = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "https://unpkg.com/kidlat-css/css/kidlat.css"
    ]
};



Vue.use(require('vue-moment'));

Vue.use(VueSweetalert2);
Vue.use(vuescroll);
Vue.use(VueHtmlToPaper, options);
Vue.use(AudioRecorder);



Vue.use(Embed);
Vue.use(VueApexCharts);
Vue.component('apexchart-chart', VueApexCharts);




Vue.use(VueKatex, {
    globalOptions: {
        //... Define globally applied KaTeX options here
    }
});

Vue.use(VueQuillEditor, /* { default global options } */ )

Vue.use(firestorePlugin);

Vue.use(VModal);

Vue.config.productionTip = false;
Vue.use(VueRouter);
// Vue.use(moment);
// Vue.use(require("moment"));
Vue.use(ImageUploader);
Vue.use(VueEllipseProgress);

Vue.use(Datepicker);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueGoodTablePlugin);
Vue.component("downloadExcel", JsonExcel);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

Vue.mixin({
    methods: {
        AddLog: function(user, firstname, lastname, action, description, location, type) {

            var log = {};
            log.user = user;
            log.firstname = firstname;
            log.lastname = lastname;
            log.action = action;
            log.type = type;
            log.description = description;
            log.location = location;
            log.date = new Date().toLocaleString();
            firebase
                .firestore()
                .collection("schools/" + this.$store.state.school + "/logs")
                .add(log)
                .then(() => {
                    // router.push({
                    //   name: 'BoardList'
                    // })
                })
                .catch(error => {
                    alert("Error removing document: ", error);

                });
        },
    },
})


new Vue({
    render: h => h(App),
    router,
    store,
    vuetify
}).$mount("#app");


// new Vue({
//     router,
//     store,
//     vuetify,
//     el: "#app",
//     data: function() {
//         return {
//             showChoices: true,
//             choicesSelect: null
//         }
//     },
//     mounted: function() {
//         this.initializeChoices()
//     },
//     render: h => h(App),
//     methods: {
//         initializeChoices: function() {
//             let list = []
//             for (let i = 0; i < 1000; i++) {
//                 list.push({
//                     label: "Item " + i,
//                     value: i
//                 })
//             }
//             // Set a reference to our choicesSelect in our Vue instance's data object

//         },
//         show: function() {
//             this.showChoices = true
//             this.$nextTick(() => {
//                 this.initializeChoices()
//             })
//         },
//         hide: function() {
//             // now we can use the reference to Choices to perform clean up here
//             // prior to removing the elements from the DOM
//             this.choicesSelect.destroy()
//             this.showChoices = false
//         }
//     }
// }).$mount("#app");