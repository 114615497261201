<template>
  <v-card
    ><v-card-text>
      <iframe
        width="100%"
        height="900"
        src="https://datastudio.google.com/embed/reporting/374c0ce0-afb4-4516-812f-94ec97cf9d29/page/fSZCC"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></iframe>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {}
};
</script>
