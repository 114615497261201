<template>
  <div>
    <v-card flat outlined>
      <v-card-text>
        <v-row
          ><v-col cols="9">
            <h5>{{ this.student }}</h5>

            <span v-if="this.last">
              <v-btn color="info" x-small depressed style="margin-right:10px"
                >View All</v-btn
              >
              <b>Last Attempt:</b> {{ this.last }}

              <br />
              <v-btn color="warning" x-small depressed style="margin-right:10px"
                >Recalculate</v-btn
              >
              This Grade overridden by
            </span>
          </v-col>

          <v-col cols="3">
            <v-text-field
              :background-color="grade ? 'white' : 'red lighten-4'"
              label="Grade"
              placeholder="Placeholder"
              v-model="grade"
              outlined
            ></v-text-field>
            <br />
            <v-btn
              color="info"
              block
              depressed
              small
              @click="
                goToAnswers(
                  student,
                  'schools/' +
                    $store.state.school +
                    '/StudentsData/' +
                    id +
                    '/exams/' +
                    subject
                )
              "
              >Show Student Answers</v-btn
            >
            <v-btn
              color="success"
              block
              depressed
              small
              @click="saveGrade"
              style="margin-top:10px"
              ><v-icon>mdi-check</v-icon>Save Grade</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
export default {
  props: ["student", "id", "assignment", "index"],
  data() {
    return {
      grade: 0,
      last: "",
      wrong: 0,
      right: 0,
      missed: 0,
      attemps: [],
      subject: this.$store.state.subjectTitle
    };
  },
  created() {
    firebase
      .firestore()
      .doc(
        "schools/" +
          this.$store.state.school +
          "/StudentsData/" +
          this.id +
          "/exams/" +
          this.subject
      )
      .onSnapshot(querySnapshot => {
        this.grade = querySnapshot.data().grade;
      });
  },
  methods: {
    saveGrade() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.id +
            "/exams/" +
            this.subject
        )
        .set({ grade: Number(this.grade) })
        .then(() => {
          this.$swal.queue([
            {
              icon: "success",
              title: "Grade Updated",
              html: "Grade Updated Successfully",
              timer: 2000
            }
          ]);
        });
    },

    goToAnswers(studentname, studentpath) {
      localStorage.setItem("studentname", studentname);
      localStorage.setItem("studentpath", studentpath);
      router.push({
        name: "Answers"
      });
    }
  }
};
</script>
