<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-help-circle-outline</v-icon>

          <span class="title font-weight-light" v-if="source == 'edit'"
            >Edit This Question
            <span style="color: green">({{ this.type }})</span></span
          >
          <span class="title font-weight-light" v-else
            >Create New Question
            <span style="color: green">({{ this.type }})</span></span
          >

          <v-spacer /><v-icon @click="this.$modal.hideAll">mdi-close</v-icon>
        </v-card-title>
        <v-card-text
          style="padding-right: 50px; padding-left: 50px; overflow: auto; height: 65vh;"
        >
          <div>
            <v-row style="padding-left: 20px">
              <v-col cols="3">
                <b-form-checkbox
                  v-model="lesson.exam"
                  name="exam-button"
                  switch
                >
                  Exam Question
                </b-form-checkbox>
              </v-col>
              <v-col cols="3">
                <b-form-checkbox
                  v-model="lesson.flipped"
                  name="flipped-button"
                  switch
                >
                  Flipped Question
                </b-form-checkbox>
              </v-col>
              <v-col cols="3">
                <b-form-checkbox
                  v-model="lesson.exit"
                  name="exit-button"
                  switch
                >
                  Exit Question
                </b-form-checkbox>
              </v-col>
              <v-col cols="3">
                <b-form-checkbox
                  v-model="lesson.inclass"
                  name="exit-button"
                  switch
                >
                  Guided Practice Question
                </b-form-checkbox>
              </v-col>
            </v-row>

            <b-form-group
              id="fieldTtile"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Question Title"
            >
              <b-form-input
                id="title"
                placeholder="Title"
                v-model.trim="lesson.title"
              >
              </b-form-input>
              <b-form-text id="input-live-help">
                This title will not appear to students just use it for
                categorization and search to find your question easy if you
                leave this blank the system will put it automatically
              </b-form-text>
            </b-form-group>

            <b-form-group
              id="fieldBody"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Question Body"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="question_body"
                :options="editorOption"
              />
            </b-form-group>

            <!-- Upload Listening -->
            <v-card
              flat
              outlined
              style="background-color: #d7e9f9"
              v-if="this.type == 'Listening'"
            >
              <v-card-title>Listening Audio</v-card-title>
              <v-card-text>
                <Audio
                  style="margin-left:10px"
                  :src="this.sound"
                  controls
                ></Audio>

                <v-col cols="12" md="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Upload Audio
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <b-form-file
                          type="file"
                          @change="previewImage"
                          accept=".mp3"
                        />

                        <div>
                          <p>
                            <small>
                              Progress:
                              {{ uploadValue.toFixed() + "%" }}
                            </small>
                          </p>
                        </div>
                        <div v-if="this.sound != null">
                          Upload Completed
                        </div>

                        <v-card-actions>
                          <div v-if="soundData != null">
                            <v-progress-linear
                              id="progress"
                              color="info"
                              height="10"
                              :value="uploadValue"
                              max="100"
                              striped
                            ></v-progress-linear>
                            <b-button @click="onUpload" variant="success"
                              >Upload</b-button
                            >
                          </div></v-card-actions
                        >
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Record Audio
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <audio-recorder
                          :attempts="1"
                          :time="30"
                          :show-upload-button="false"
                          :select-record="callback"
                        />

                        <v-card-actions>
                          <div v-if="url != ''">
                            <v-progress-linear
                              id="progress"
                              color="info"
                              height="10"
                              :value="uploadNote"
                              max="100"
                              striped
                            ></v-progress-linear>
                            <b-button @click="uploadRecord" variant="success"
                              >Upload</b-button
                            >
                          </div></v-card-actions
                        >
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-card-text></v-card
            >

            <!-- End Upload Listening -->

            <b-form-group
              v-if="lesson.exam"
              id="fieldWeight"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Question Weight"
            >
              <b-form-input
                id="weight"
                placeholder="Weight"
                v-model.trim="lesson.weight"
                type="number"
              >
              </b-form-input>
            </b-form-group>

            <!-- <div v-if="this.source != 'edit'">-->

            <StandardsPicker
              :path="this.path"
              type="Question"
              :lesson="this.week"
              v-if="source == 'edit'"
            />

            <b-form-group
              id="fieldtopic"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Question's Topic"
            >
              <v-row>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <v-autocomplete
                    v-model.trim="lesson.topic"
                    :items="topics"
                    index="type"
                    label="Topic"
                  />
                </v-col>
              </v-row>
            </b-form-group>

            <b-form-group
              id="fieldobjectives"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Question's Objectives"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="question_objectives"
                :options="editorOption"
              />
            </b-form-group>

            <b-form-group
              id="fieldbloom"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Bloom Level"
            >
              <v-row>
                <v-col cols="1">
                  <Tip
                    text="Bloom's taxonomy is a set of three hierarchical models used to classify educational learning objectives into levels of complexity and specificity. ... The models were named after Benjamin Bloom, who chaired the committee of educators that devised the taxonomy."
                    image="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/pics%2Fbloom.png?alt=media&token=09bc6c8a-643d-440b-87fd-78f70c1af419"
                  />
                </v-col>
                <v-col cols="11">
                  <b-form-select
                    id="bloom"
                    placeholder="Bloom Level"
                    v-model.trim="lesson.bloom"
                    :options="bloom"
                  ></b-form-select>
                </v-col>
              </v-row>
            </b-form-group>

            <b-form-group
              id="fielddok"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="DOK Level"
            >
              <v-row>
                <v-col cols="1">
                  <Tip
                    text="The DOK has 4 levels. Depth of Knowledge, or DOK, is a way to think about content complexity, not content difficulty. That is the first thing with which people must come to understanding. Complexity is different from difficulty. For example, if students have not seen the word or content before, it might be difficult for them, but it is not complex."
                    image="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/pics%2Fdok.jpg?alt=media&token=992cfb2d-72ed-4746-92ad-ca03ef0c88ba"
                  />
                </v-col>

                <v-col cols="11">
                  <b-form-select
                    id="dok"
                    placeholder="DOK Level"
                    v-model.trim="lesson.dok"
                    :options="dok"
                  ></b-form-select>
                </v-col>
              </v-row>
            </b-form-group>

            <b-form-group
              id="fielddifficulty"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Difficulty Level"
            >
              <b-form-select
                id="difficulty"
                placeholder="Difficulty Level"
                v-model.trim="lesson.difficulty"
                :options="difficulty"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="fieldanswer"
              horizontal
              :label-cols="2"
              breakpoint="md"
              label="Answer Feedback"
            >
              <quill-editor
                ref="answer"
                v-model="question_answer"
                :options="editorOption"
              />
            </b-form-group>

            <v-divider></v-divider>
            <!-- Multiple Choice -->
            <div
              v-if="this.type == 'Multiple Choice' || this.type == 'Listening'"
              style="margin-bottom: 80px"
            >
              <div v-if="this.source != 'edit'">
                <v-card style="background-color: #d7e9f9" flat outlined>
                  <v-card-title>
                    {{ this.type }} Options <v-spacer></v-spacer
                    ><v-btn
                      style="background-color: green"
                      dark
                      small
                      depressed
                      @click="add()"
                      >Add New Option</v-btn
                    ></v-card-title
                  >
                  <v-card-text>
                    <div v-for="(i, index) in options_count" :key="index">
                      <v-row style="padding-right: 20px; padding-left: 20px">
                        <v-col cols="'1">
                          <!-- <v-badge
                            bordered
                            color="success"
                            icon="mdi-check"
                            overlap
                            v-if="props.row.note"
                          >
                            <Note @clicked="onClickChild" />
                          </v-badge> -->
                          <Note @clicked="onClickChild" :index="index" />
                        </v-col>
                        <v-col cols="8">
                          <b-form-input
                            placeholder="Option Title"
                            v-model="options[index].title"
                          ></b-form-input>
                        </v-col>
                        <v-col cols="3">
                          <b-form-select
                            v-model="options[index].grade"
                            :options="[
                              { text: 'Right', value: 1 },
                              { text: 'Wrong', value: 0 }
                            ]"
                          ></b-form-select>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <!-- End Multiple Choice -->
            <!-- True/False -->
            <div
              v-else-if="this.type == 'True/False'"
              style="margin-bottom: 80px"
            >
              <b-form-group
                id="fieldright"
                horizontal
                :label-cols="2"
                breakpoint="md"
                label="Right Answer"
              >
                <b-form-select
                  id="righr"
                  placeholder="Right Answer"
                  v-model.trim="lesson.correct"
                  :options="[
                    { text: 'Right', value: 'true' },
                    { text: 'Wrong', value: 'false' }
                  ]"
                ></b-form-select>
              </b-form-group>
            </div>
            <!-- End True/False -->
            <!-- Complete -->
            <div
              v-else-if="this.type == 'Complete'"
              style="margin-bottom: 80px"
            >
              <b-form-group
                id="fieldmissing"
                horizontal
                :label-cols="2"
                breakpoint="md"
                label="Missing Word"
              >
                <b-input
                  id="missing"
                  placeholder="Missing Word"
                  v-model.trim="lesson.correct"
                ></b-input>
              </b-form-group>
            </div>
            <!-- End Complete -->
          </div>

          <v-card
            flat
            outlined
            v-if="this.source == 'edit'"
            style="background-color:#FFCCBC; color:#000"
            ><v-card-text style="color:#000">
              <v-row style="padding-left: 20px">
                <v-divider></v-divider>
                <v-col cols="3">
                  <b-form-checkbox
                    v-model="lesson.report"
                    name="report-button"
                    switch
                  >
                    Report Problem
                  </b-form-checkbox>
                </v-col>
                <v-col cols="9">
                  <b-form-input
                    id="reportreason"
                    placeholder="Report Reason"
                    v-model.trim="lesson.reportreason"
                  >
                  </b-form-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-divider></v-divider>

          <b-button
            variant="danger"
            class="float-right"
            @click="this.$modal.hideAll"
            >Close</b-button
          >

          <b-button
            style="margin-left: 10px"
            variant="success"
            class="float-right"
            @click="edit"
            v-if="source == 'edit'"
            >Edit Question</b-button
          >
          <b-button
            style="margin-left: 10px"
            variant="success"
            class="float-right"
            @click="onSubmit"
            v-else
            >Create Question</b-button
          >
        </v-card-actions>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import katex from "katex";
import "katex/dist/katex.min.css";
import Tip from "@/components/dna/Tip";
import StandardsPicker from "@/components/lms/standards/StandardsPicker";
import Note from "@/components/dna/notes/QuestionNote.vue";

export default {
  name: "ViewRecord-page",
  props: ["path", "order", "type", "source", "qtype", "target", "week"],

  components: { Tip, StandardsPicker, Note },
  data() {
    return {
      options: [{ title: "", grade: "" }],
      switchoptions: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" }
      ],
      standarop: [{ standard: "", sub: "" }],
      standards_count: 0,
      audiolink: "",
      options_count: 1,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
            ["image"],
            ["video"],
            ["formula"]
          ]
        }
      },
      bloom: [
        { value: "Knowledge (Remembering)", text: "Knowledge (Remembering)" },

        {
          value: "Comprehension (Understanding)",
          text: "Comprehension (Understanding)"
        },
        {
          value: "Application (Transferring)",
          text: "Application (Transferring)"
        },
        { value: "Analysis (Relating)", text: "Analysis (Relating)" },
        { value: "Evaluation (Judging)", text: "Evaluation (Judging)" },
        { value: "Synthesis (Creating)", text: "Synthesis (Creating)" }
      ],

      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      difficulty: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" }
      ],
      questiontype: [
        { value: "mc", text: "Multiple Choice" },
        { value: "tf", text: "True/False" },
        { value: "speech", text: "Speech" },
        { value: "complete", text: "Complete" },
        { value: "essay", text: "Essay" },
        { value: "listening", text: "Listening" }
      ],

      soundData: null,
      sound: null,
      uploadValue: 0,

      lesson: {},
      standards: [],
      topics: [],

      question_body: "",
      question_answer: "",
      question_objectives: "",
      url: "",

      sub: [],
      ref: firebase.firestore().collection(this.path + "/" + this.target + "/"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      newfield: {}
    };
  },
  methods: {
    previewImage(event) {
      this.uploadValue = 0;
      this.sound = null;
      this.soundData = event.target.files[0];
    },
    callback(data) {
      this.url = data.url;
    },

    onClickChild(value) {
      console.log(value); // someValue

      //! Save Options Audio
      this.options[value[1]].sound = value[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/questions sounds/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.soundData.name
        )
        .put(this.soundData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.sound = url;
          });
        }
      );
    },

    uploadRecord() {
      if (this.url != "") {
        this.note = null;

        fetch(this.url)
          .then(res => res.blob())
          .then(blob => {
            const storageRef = firebase
              .storage()
              .ref(
                "/questions sounds/" +
                  Math.floor(Math.random() * 900000 + 1000000).toString()
              )
              .put(blob);

            storageRef.on(
              `state_changed`,
              snapshot => {
                this.uploadValue =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              error => {
                console.log(error.message);
              },
              () => {
                this.uploadValue = 100;
                storageRef.snapshot.ref.getDownloadURL().then(url => {
                  this.sound = url;
                });
              }
            );
          });
      }
    },

    add() {
      this.options_count++;
      this.options.push({ title: "", grade: "" });
    },
    addstandard() {
      this.standards_count++;
      this.standarop.push({ standard: "", sub: "" });
    },

    deletestandard(index) {
      this.standarop.splice(index, 1);
    },

    standardchange(index) {
      this.sub = [];

      firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards")
        .doc(this.standarop[index].standard)
        .collection("substandards")
        .onSnapshot(querySnapshot => {
          this.sub = [];
          querySnapshot.forEach(doc => {
            this.sub.push({
              text: doc.data().code + "-" + doc.data().title,
              value: doc.id
            });
          });
        });
    },

    edit(evt) {
      evt.preventDefault();
      if (this.question_body == null) {
        alert("Please Enter the Question Body");
      } else {
        this.lesson.exam = this.lesson.exam == true ? "Yes" : "No";
        this.lesson.flipped = this.lesson.flipped == true ? "Yes" : "No";
        this.lesson.exit = this.lesson.exit == true ? "Yes" : "No";

        if (this.sound != null) {
          this.lesson.sound = this.sound;
        }

        if (this.lesson.title == null || this.lesson.title == "") {
          let regex = /(<([^>]+)>)/gi;
          this.lesson.title = this.question_body
            .replace(regex, "")
            .substr(0, 100);
        }

        this.lesson.standard = this.standarop;
        //!kamal shalaby Editor
        this.lesson.editor = this.$store.state.username;
        this.lesson.editorfirstname = this.$store.state.firstname;
        this.lesson.editorlastname = this.$store.state.lastname;
        this.lesson.lastedit = new Date().toLocaleString();

        firebase
          .firestore()
          .doc(this.path)
          .update(this.lesson)
          .then(() => {
            //! Update details Sub Collections
            firebase
              .firestore()
              .doc(this.path + "/details/details")
              .update({
                body: this.question_body,
                answer: this.question_answer,
                objectives: this.question_objectives
              })
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Update",
                  this.lesson,
                  this.$store.state.subject,
                  "Question"
                );

                this.lesson.title = "";
                this.lesson.link = "";

                this.$bvToast.toast("Question Created", {
                  title: "Question Created Successfully",
                  variant: "success",
                  solid: true
                });
                this.$modal.hideAll();
              })
              .catch(error => {
                console.log("Error adding document: ", error);
              });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.question_body == null) {
        alert("Please Enter the Question Body");
      } else {
        if (this.lesson.title == null || this.lesson.title == "") {
          let regex = /(<([^>]+)>)/gi;
          this.lesson.title = this.question_body
            .replace(regex, "")
            .substr(0, 100);
        }

        if (this.sound != null) {
          this.lesson.sound = this.sound;
        }

        this.lesson.exam = this.lesson.exam == true ? "Yes" : "No";
        this.lesson.flipped = this.lesson.flipped == true ? "Yes" : "No";
        this.lesson.exit = this.lesson.exit == true ? "Yes" : "No";

        this.lesson.type = "question";
        this.lesson.order = Number(this.order) + 1;

        this.lesson.qtype = this.qtype;

        this.lesson.standard = this.standarop;

        this.ref
          .add(this.lesson)
          .then(question => {
            //! Add Options to the Questions

            if (
              this.qtype == "mc" ||
              this.qtype == "listening" ||
              this.qtype == null
            ) {
              for (var i = 0; i <= this.options.length - 1; i++) {
                firebase
                  .firestore()
                  .collection(question.path + "/options/")
                  .add({ ...this.options[i] })
                  .then(() => {})
                  .catch(error => {
                    console.log("Error adding document: ", error);
                  });
              }
            }

            //! Add details Sub Collections
            firebase
              .firestore()
              .doc(question.path + "/details/details")
              .set({
                body: this.question_body,
                answer: this.question_answer,
                objectives: this.question_objectives
              })
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Create",
                  this.lesson,
                  this.$store.state.subject,
                  "Question"
                );

                this.lesson.title = "";
                this.lesson.link = "";

                this.$bvToast.toast("Question Created", {
                  title: "Question Created Successfully",
                  variant: "success",
                  solid: true
                });
                this.$modal.hideAll();
              })
              .catch(error => {
                console.log("Error adding document: ", error);
              });
          })
          .catch(error => {
            console.log("Error adding document: ", error);
          });
      }
    }
  },

  mount() {
    this.show();
    window.katex = katex;
  },

  created() {
    if (this.source == "edit") {
      if (this.qtype == "tf") {
        // eslint-disable-next-line vue/no-mutating-props
        this.type = "True/False";
      } else if (this.qtype == "speech") {
        // eslint-disable-next-line vue/no-mutating-props
        this.type = "Speech";
      } else if (this.qtype == "complete") {
        // eslint-disable-next-line vue/no-mutating-props
        this.type = "Complete";
      } else if (this.qtype == "listening") {
        // eslint-disable-next-line vue/no-mutating-props
        this.type = "Listening";
      } else if (this.qtype == "essay") {
        // eslint-disable-next-line vue/no-mutating-props
        this.type = "Essay";
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.type = "Multiple Choice";
      }

      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.lesson = doc.data();
            this.sound = this.lesson.sound;
            this.lesson.exam = this.lesson.exam == "Yes" ? true : false;
            this.lesson.flipped = this.lesson.flipped == "Yes" ? true : false;
            this.lesson.exit = this.lesson.exit == "Yes" ? true : false;

            if (doc.data().standard[0].standard != null) {
              this.standarop = doc.data().standard;
              this.standards_count = this.standarop.length - 1;
            }
          } else {
            alert("No such document!");
          }
        });

      //! Get Details
      firebase
        .firestore()
        .doc(this.path + "/details/details")
        .get()
        .then(doc => {
          const details = doc.data();
          this.question_body = details.body;
          this.question_answer = details.answer;
          this.question_objectives = details.objectives;
        });
    }

    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.ref.id
        });
      });
    });

    firebase
      .firestore()
      .collection(this.$store.state.subject + "/topics/")
      .onSnapshot(querySnapshot => {
        this.topics = [];
        querySnapshot.forEach(doc => {
          this.topics.push({
            text: doc.data().code + "  " + doc.data().title,
            value: doc.ref.id
          });
        });
      });
  }
};
</script>
<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(24, 146, 0, 0.904);
  cursor: pointer;
}
</style>
