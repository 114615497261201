<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button variant="warning" v-bind="attrs" v-on="on">
        <v-icon size="20">mdi-help-circle-outline</v-icon>
        <small style="margin-left: 10px">Central Question Bank</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px">mdi-help</v-icon>Central Question
          Bank
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- School -->
        <v-row class="card-text">
          <!-- System -->

          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="csystems"
              v-model="activities.system"
              @change="onSchoolChange"
              label="System"
            ></v-select>
          </v-col>
          <!-- Grade -->
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="grades"
              v-model="activities.grade"
              @change="onGradeChange"
              label="Grade"
            ></v-select>
          </v-col>
          <!-- Grade -->
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="subjects"
              v-model="activities.subject"
              label="Subject"
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="3" sm="3">
            <div>
              <b-button variant="info" @click="search">
                <v-icon>mdi-magnify</v-icon>Search
              </b-button>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="this.videolink">
          <v-col class="d-flex" cols="6" sm="6">
            <video-embed :src="this.videolink"></video-embed>
          </v-col>
          <v-col class="d-flex" cols="6" sm="6">
            <v-card flat>
              <v-card-subtitle>{{ this.selectedWeek }}</v-card-subtitle>
              <v-card-title>{{ this.selectedTitle }}</v-card-title>
              <v-card-text
                ><b-button @click="importS(selectedTitle, videolink)" size="sm"
                  >Import to lesson</b-button
                ></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <br />

        <vue-good-table
          :columns="sfields"
          :rows="activities"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records' }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn btn-danger btn-sm"
                @click="
                  setvideo(
                    props.row.link,
                    props.row.title,
                    props.row.week_title
                  )
                "
              >
                <v-icon>mdi-help</v-icon>&nbsp; Preview
              </button>
              &nbsp;
              <button
                class="btn btn-success btn-sm"
                style="padding-left: 20"
                @click="importS(props.row.path)"
              >
                <v-icon>mdi-application-import</v-icon>&nbsp; Import
              </button>
            </span>
            <span v-else-if="props.column.field == 'title'">
              <span>{{ removeHTML(props.row.title) }}</span>
            </span>
            <span v-else-if="props.column.field == 'type'">
              <v-icon color="green">mdi-help</v-icon>
            </span>
            <span v-else-if="props.column.field == 'dok'">
              {{
                props.row.difficulty != ""
                  ? dok[
                      Number(
                        props.row.difficulty != null ? props.row.difficulty : 0
                      ) - 1
                    ].text
                  : "Recall"
              }}
            </span>
            <span v-else-if="props.column.field == 'bloom'">
              {{
                props.row.difficulty != ""
                  ? bloom[
                      Number(
                        props.row.difficulty != null ? props.row.difficulty : 0
                      ) - 1
                    ].text
                  : "Knowledge (Remembering)"
              }}
            </span>
          </template>
        </vue-good-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["path", "order", "source"],
  data() {
    return {
      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      bloom: [
        { value: "Knowledge (Remembering)", text: "Knowledge (Remembering)" },
        {
          value: "Application (Transferring)",
          text: "Application (Transferring)"
        },
        {
          value: "Comprehension (Understanding)",
          text: "Comprehension (Understanding)"
        },

        { value: "Analysis (Relating)", text: "Analysis (Relating)" },
        { value: "Evaluation (Judging)", text: "Evaluation (Judging)" },
        { value: "Synthesis (Creating)", text: "Synthesis (Creating)" }
      ],
      dialog: false,
      activities: [],
      csystems: [],
      grades: [],
      subjects: [],
      options: {},
      question: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      sfields: [
        {
          field: "type",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "difficulty",
          label: "Difficulty",
          sortable: true,
          class: "text-left"
        },
        {
          field: "dok",
          label: "DOK",
          sortable: true,
          class: "text-left"
        },
        {
          field: "bloom",
          label: "Bloom",
          sortable: true,
          class: "text-left"
        },
        {
          field: "week",
          label: "Week",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    firebase
      .firestore()
      .collection("questions_hub/")
      .onSnapshot(querySnapshot => {
        this.csystems = [];
        querySnapshot.forEach(doc => {
          this.csystems.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  },
  methods: {
    removeHTML(text) {
      let regex = /(<([^>]+)>)/gi;

      return text.replace(regex, "");
    },
    onSchoolChange() {
      this.grades = [];

      firebase
        .firestore()
        .collection("questions_hub/" + this.activities.system + "/grades/")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    onGradeChange() {
      this.subjects = [];

      firebase
        .firestore()
        .collection(
          "questions_hub/" +
            this.activities.system +
            "/grades/" +
            this.activities.grade +
            "/subjects"
        )
        .onSnapshot(querySnapshot => {
          this.subjects = [];
          querySnapshot.forEach(doc => {
            this.subjects.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    setvideo(link, title, week) {
      this.videolink = link;
      this.selectedTitle = title;
      this.selectedWeek = week;
    },
    search() {
      firebase
        .firestore()
        .collection(
          "questions_hub/" +
            this.activities.system +
            "/grades/" +
            this.activities.grade +
            "/subjects/" +
            this.activities.subject +
            "/questions"
        )
        .onSnapshot(querySnapshot => {
          this.activities = [];
          querySnapshot.forEach(doc => {
            this.activities.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().type,
              difficulty: doc.data().difficulty,
              dok: doc.data().dok,
              week: doc.data().week
            });
          });
        });
    },

    importS(path) {
      firebase
        .firestore()
        .doc(path)
        .get()
        .then(snapshot => {
          this.question = snapshot.data();
          this.question.order = Number(this.order) + 1;
          this.question.type = "question";

          let regex = /(<([^>]+)>)/gi;

          const question_body =
            this.question.title == null ? "" : this.question.title;
          const question_answer =
            this.question.answer == null ? "" : this.question.answer;
          const question_objectives =
            this.question.objectives == null ? "" : this.question.objectives;

          this.question.title = this.question.title
            .replace(regex, "")
            .substr(0, 100);

          this.question.answer = "";
          this.question.objectives = "";

          this.question.dok =
            this.question.difficulty != ""
              ? this.dok[
                  Number(
                    this.question.difficulty != null
                      ? this.question.difficulty
                      : 0
                  ) - 1
                ].text
              : "Recall";
          this.question.bloom =
            this.question.difficulty != ""
              ? this.bloom[
                  Number(
                    this.question.difficulty != null
                      ? this.question.difficulty
                      : 0
                  ) - 1
                ].text
              : "Knowledge (Remembering)";

          firebase
            .firestore()
            .collection(this.path + "/" + this.source)
            .add(this.question)
            .then(k => {
              //! Add Options Collection
              firebase
                .firestore()
                .collection(path + "/options")
                .onSnapshot(querySnapshot => {
                  querySnapshot.forEach(doc => {
                    this.options = doc.data();

                    firebase
                      .firestore()
                      .collection(
                        this.path + "/" + this.source + "/" + k.id + "/options"
                      )
                      .add(this.options)
                      .then(() => {});
                  });
                });

              //! Add Details Collection

              firebase
                .firestore()
                .doc(
                  this.path +
                    "/" +
                    this.source +
                    "/" +
                    k.id +
                    "/details/details"
                )
                .set({
                  body: question_body,
                  answer: question_answer,
                  objectives: question_objectives
                })
                .then(() => {});

              this.AddLog(
                this.$store.state.username,
                this.$store.state.firstname,
                this.$store.state.lastname,
                "Import",
                this.question,
                this.path,
                "Question"
              );

              this.$bvToast.toast("Activity Imported", {
                title: "Activity Imported Successfully",
                variant: "success",
                solid: true
              });
            })
            .catch(error => {
              console.log(error);
            });
        });
    }
  }
};
</script>
