<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon size="30px">mdi-account-tie-outline</v-icon>
          &nbsp;Parents List
          <v-row align="center" justify="end">
            <download-excel
              class="btn btn-default"
              :data="parents"
              :fields="fexport"
              type="csv"
              name="parents.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
              <small>Export to Excel</small>
            </download-excel>
          </v-row>
        </v-card-title>
        <v-card-text>
          <AddParent></AddParent>
          <br />
          <!-- Start Parents Accounts -->
          <vue-good-table
            :columns="fields"
            :rows="parents"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click.stop="deleteRecord(props.row.key, props.row.title)"
                >
                  <b-icon icon="trash" scale="1"></b-icon>&nbsp;
                  <small>Delete</small>
                </button>
              </span>
            </template>
          </vue-good-table>

          <!-- End Parents Accounts -->
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import AddParent from "@/components/sis/parents/AddParent";

export default {
  name: "ParentsList",
  components: { AddParent },
  props: ["path"],
  data() {
    return {
      fields: [
        {
          field: "relation",
          label: "Relation",
          sortable: true,
          class: "text-left"
        },
        {
          field: "name",
          label: "Parent Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "username",
          label: "Username",
          sortable: true,
          class: "text-left"
        },

        {
          field: "password",
          label: "Password",
          sortable: true,
          class: "text-left"
        },
        { field: "actions", label: "Action", class: "text-center" }
      ],
      fexport: {
        Relation: "relationv",
        "Parent Name": "name",
        Username: "username",
        Password: "password"
      },

      parents: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/parents")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.parents = [];
      querySnapshot.forEach(doc => {
        this.parents.push({
          key: doc.id,
          path: doc.ref.path,
          name: doc.data().name,
          username: doc.data().username,
          password: doc.data().password,
          relation: doc.data().relation
        });
      });
    });
  },
  methods: {
    deleteRecord(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("schools/" + this.$store.state.school + "/parents")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
