<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Resource</b-button
      >

      <b-collapse id="accordion-1" class="mt-2">
        <v-card flat outlined>
          <v-container>
            <b-form @submit.stop.prevent="onSubmit">
              <!-- Grade Order -->

              <b-form-checkbox
                v-model="resource.hidden"
                name="check-button"
                switch
              >
                Hide The Resource
              </b-form-checkbox>

              <br />

              <v-text-field
                id="ResourceTtile"
                v-model="resource.title"
                label="Resource Title"
              ></v-text-field>
              <v-text-field
                id="Ttile"
                v-model="resource.link"
                label="Resource Link"
              ></v-text-field>

              <!-- Upload Image -->

              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="8" md="8">
                    <v-file-input
                      v-model="files"
                      show-size
                      accept=".pdf, .pptx, .doc, .docx"
                      prepend-icon="mdi-file"
                      label="File (Max Size 30 MB)"
                      @change="previewImage"
                    ></v-file-input>
                    {{ uploadValue.toFixed() + "%" }}
                    <v-progress-linear
                      id="progress"
                      color="light-blue"
                      height="10"
                      :value="uploadValue"
                      max="100"
                      striped
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="4" md="4">
                    <b-button @click="onUpload" variant="primary"
                      >Upload File</b-button
                    >
                  </v-col>
                </v-row>
              </v-col>

              <!-- End Of Uploading -->

              <b-card-footer>
                <!-- Submit Form -->
                <b-button type="submit" variant="primary"
                  >Add Resource</b-button
                >
              </b-card-footer>
            </b-form>
          </v-container>
        </v-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddActivity",
  props: ["order"],
  data() {
    return {
      thisOrder: 0,

      resource: {},
      selected: null,

      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  created() {},

  methods: {
    previewImage(event) {
      this.imageData = event;
      console.log(this.imageData);
    },

    onUpload() {
      if (this.files.size / 1048576 > 30) {
        alert("The File Is Bigger Than 30 MB");
      } else {
        this.picture = null;
        const storageRef = firebase
          .storage()
          .ref(
            "schools/" +
              this.$store.state.school +
              "/resources/" +
              Math.floor(Math.random() * 900000 + 1000000).toString() +
              this.imageData.name
          )
          .put(this.files);

        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              this.picture = url;
            });
          }
        );
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.resource.title == null) {
        alert("Please Enter the Resource Title");
      } else {
        if (this.picture != null) {
          this.resource.link = this.picture;
        }

        firebase
          .firestore()
          .collection(this.$store.state.subject + "/resources/")
          .add(this.resource)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.resource,
              this.$store.state.subject,
              "Resource"
            );

            this.resource.title = "";

            this.$bvToast.toast("Resource Created", {
              title: "Resource Created Successfully",
              variant: "success",
              solid: true
            });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  }
};
</script>
