import { render, staticRenderFns } from "./Attendance.vue?vue&type=template&id=3b5c678b&"
import script from "./Attendance.vue?vue&type=script&lang=js&"
export * from "./Attendance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports