<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title> ({{ this.parentid }}) Sub Standards</v-card-title>
        <v-card-subtitle> {{ this.$route.params.title }} </v-card-subtitle>

        <v-card-text>
          <vue-good-table
            style="margin-top: 20px"
            :columns="fields"
            :rows="standards"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <v-btn
                  style="margin-left: 5px"
                  small
                  color="info"
                  dark
                  depressed
                  @click="goToEdit(props.row.title, props.row.path)"
                >
                  <v-icon color="white" size="20">mdi-playlist-edit</v-icon>
                </v-btn>
                <v-btn
                  style="margin-left: 5px"
                  small
                  color="red"
                  dark
                  depressed
                  @click="deletestandard(props.row.key, props.row.code)"
                >
                  <v-icon color="white" size="20">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
              <span v-else-if="props.column.field == 'des'">
                <v-btn
                  small
                  color="info"
                  block
                  dark
                  depressed
                  @click="gotodistribute(props.row.code)"
                >
                  <v-icon>mdi-align-vertical-distribute</v-icon>
                  Distribution</v-btn
                >
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "Standards-page",
  components: {},
  props: ["parent"],
  data() {
    return {
      parentid: localStorage.getItem("parent"),
      newStandard: {},
      standards: [],
      dialog: false,
      fields: [
        {
          field: "code",
          label: "Code",
          sortable: true,
          class: "text-left",
          width: "120px"
        },

        {
          field: "domain",
          label: "Domain",
          sortable: true,
          class: "text-left"
        },
        {
          field: "cluster",
          label: "Clusters",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Description",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Actions",
          sortable: true,
          class: "text-left",
          width: "150px"
        },
        {
          field: "des",
          label: "",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$store.state.subject + "/standards")
      .where("parent", "==", this.parentid)
      .onSnapshot(querySnapshot => {
        this.standards = [];
        querySnapshot.forEach(doc => {
          this.standards.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            code: doc.data().code,
            domain: doc.data().domain,
            cluster: doc.data().cluster,
            source: doc.data().source,
            strand: doc.data().strand,
            parent: doc.data().parent,
            topic: doc.data().topic
          });
        });
      });
  },
  methods: {
    goToEdit(title, path) {
      router.push({
        name: "EditStandard",
        params: { title: title, path: path }
      });
    },

    gotodistribute(parent) {
      localStorage.setItem("isparent", false);
      router.push({
        name: "Distribute",
        params: { parent: parent }
      });
    },

    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$store.state.subject + "/standards")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Standard Deleted", {
                  title: "Standard Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
