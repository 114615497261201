<template>
  <div>
    <v-row>
      <v-col cols="4">
        <b> {{ this.day }}</b>
      </v-col>
      <v-col cols="3">
        <span style="color:blue">
          {{ this.record.length > 0 ? this.record[0].in : "" }}</span
        >
        <span style="color:red">
          {{ this.record.length > 0 ? this.record[0].inrule : "Absence" }}</span
        >
      </v-col>
      <v-col cols="3">
        <span style="color:green">
          {{ this.record.length > 0 ? this.record[0].out : "" }}
        </span>
        <span style="color:red">
          {{ this.record.length > 0 ? this.record[0].outrule : "" }}</span
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["day", "emp"],
  data() {
    return {
      record: []
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.emp + "/timesheet")
      .where("date", "==", this.day)
      .onSnapshot(querySnapshot => {
        this.record = [];
        querySnapshot.forEach(doc => {
          this.record.push({
            date: doc.data().date,
            in: doc.data().in,
            inrule: doc.data().inrule,
            out: doc.data().out,
            outrule: doc.data().outrule
          });
        });
      });
  }
};
</script>
