<template>
  <div>
    <v-card flat outlined>
      <v-card-title>Create New Exam</v-card-title>

      <v-card-text>
        <div>
          <v-stepper v-model="e1">
            <b-form @submit.stop.prevent="onSubmit">
              <v-stepper-header>
                <v-stepper-step editable :complete="e1 > 1" step="1">
                  Exam Title
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step editable :complete="e1 > 2" step="2">
                  Exam Options
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step editable step="3">
                  Exam Schedule & Setting
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card height="500px" flat outlined>
                    <!--Exam Type -->
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            id="Examtitle"
                            label="Exam Title"
                            required
                            value
                            autofocus
                            v-model="assign.title"
                          ></v-text-field>

                          <v-switch
                            inset
                            v-model="assign.sclass"
                            color="green"
                            label="This Exam For Specific Class"
                          ></v-switch>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" v-show="assign.sclass">
                          <v-select
                            :items="eclasses"
                            v-model.trim="assign.class"
                            label="Class"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            :items="weights"
                            v-model.trim="assign.weight"
                            label="Exam Weight"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="2">
                          <v-text-field
                            id="Gradetitle"
                            label="Grade"
                            type="number"
                            required
                            value
                            autofocus
                            v-model="assign.grade"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <!-- End Exam Type -->
                    <v-card-actions>
                      <v-btn color="info" @click="backStep" depressed>
                        Back
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="nextStep"
                        style="margin-left:10px"
                        depressed
                      >
                        Continue
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <b-card height="500px">
                    <b-card-text>
                      <!-- Exam Area  -->

                      <v-select
                        :items="quiztypes"
                        v-model.trim="assign.quiztype"
                        label="Exam Type"
                      ></v-select>

                      <!--Fixed Questions-->
                      <div v-if="assign.quiztype == 'Fixed Questions'">
                        <div>
                          <v-alert
                            color="orange"
                            dark
                            dense
                            icon="mdi-help-circle-outline"
                            prominent
                          >
                            Exam Questions
                          </v-alert>

                          <!-- Fixed Questions Dialog -->
                          <v-dialog v-model="dialog" width="60%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="grey darken-3"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                style="margin-bottom:20px;"
                              >
                                <v-icon
                                  color="orange"
                                  style="padding-right:20px;"
                                  >mdi-help-circle-outline</v-icon
                                >
                                Select from Question Bank
                              </v-btn>
                            </template>
                            <v-card flat outlined>
                              <v-card-title>
                                <span class="headline">
                                  <v-icon>mdi-help-circle-outline</v-icon>
                                  Select Question</span
                                >
                              </v-card-title>

                              <v-card-text>
                                <!-- Select Questions -->
                                <!--  <v-row class="card-text">
                                   <v-col class="d-flex" cols="12" sm="12">
                                   <v-autocomplete
                                      @change="changeLesson"
                                      v-model.trim="selectedlesson"
                                      :items="lessons"
                                      dense
                                      label="Select Lessons"
                                    ></v-autocomplete> 
                                  </v-col>
                                </v-row>-->
                                <v-row class="card-text">
                                  <v-col class="d-flex" cols="12" sm="12">
                                    <v-autocomplete
                                      v-model.trim="selectedquestion"
                                      :items="questionsbank"
                                      dense
                                      label="Select Question"
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                  color="red darken-1"
                                  text
                                  @click="dialog = false"
                                  >Back</v-btn
                                >
                                <v-btn
                                  color="green darken-1"
                                  text
                                  @click="addToFixed"
                                  >Add Question</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <!-- End Fixed Questions  Dialog -->
                          <!-- List of Fixed Questions -->

                          <FixedQuestion
                            v-for="(q, index) in fixedQuestions"
                            :key="q"
                            :path="q"
                            :index="index"
                          >
                            <v-btn
                              style="margin-left:20px"
                              small
                              color="red"
                              @click="deleteQuestion(index)"
                            >
                              <v-icon>mdi-trash-can-outline</v-icon>

                              Delete Question</v-btn
                            ></FixedQuestion
                          >

                          <!-- End List of Fixed Questions -->
                        </div>
                      </div>

                      <!--End Fixed Questions-->

                      <!--Random Questions-->

                      <div
                        v-else-if="
                          assign.quiztype == 'Random From Question Bank'
                        "
                      >
                        <v-row style="background-color:#eee">
                          <v-col cols="4">
                            <v-text-field
                              v-model.trim="assign.count"
                              label="Exam Total Questions"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row
                          ><v-col cols="12">
                            <v-card
                              v-for="week in weeks"
                              :key="week.id"
                              fl
                              outlined
                              style="margin-bottom:10px"
                            >
                              <v-card-title>
                                {{ week.title }} <v-divider />
                                <v-text-field
                                  v-model.trim="qmatrix[week.id]"
                                  label="Questions Count"
                                  type="number"
                                ></v-text-field
                              ></v-card-title>
                              <v-card-text>
                                <v-row>
                                  <v-col cols="4">
                                    <b-card
                                      border-variant="primary"
                                      header="Bloom"
                                      header-bg-variant="primary"
                                      header-text-variant="white"
                                      align="center"
                                    >
                                      <b-card-text>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'knowledge']
                                          "
                                          label="Knowledge (Remembering)"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'application']
                                          "
                                          label="Application (Transferring)"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'comprehension']
                                          "
                                          label="Comprehension (Understanding)"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'analysis']
                                          "
                                          label="Analysis (Relating)"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'synthesis']
                                          "
                                          label="Synthesis (Creating)"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'evaluation']
                                          "
                                          label="Evaluation (Judging)"
                                          type="number"
                                        ></v-text-field>
                                      </b-card-text>
                                    </b-card>
                                  </v-col>
                                  <v-col cols="4">
                                    <b-card
                                      border-variant="primary"
                                      header="DOK"
                                      header-bg-variant="primary"
                                      header-text-variant="white"
                                      align="center"
                                    >
                                      <b-card-text>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'recall']
                                          "
                                          label="Recall"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'skill']
                                          "
                                          label="Skill Concept"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'strategic']
                                          "
                                          label="Strategic Thinking"
                                          type="number"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model.trim="
                                            qmatrix[week.id + 'extended']
                                          "
                                          label="Extended Thinking"
                                          type="number"
                                        ></v-text-field>
                                      </b-card-text>
                                    </b-card>
                                  </v-col>
                                </v-row>
                                <v-divider />
                              </v-card-text>
                            </v-card> </v-col
                        ></v-row>
                      </div>

                      <!--EndRandom Questions-->
                    </b-card-text>
                    <template v-slot:footer>
                      <v-btn color="info" @click="backStep" depressed>
                        Back
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="nextStep"
                        style="margin-left:10px"
                        depressed
                      >
                        Continue
                      </v-btn>
                    </template>
                  </b-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <b-card height="300px">
                    <b-card-text>
                      <v-switch
                        inset
                        v-model="assign.open"
                        color="green"
                        label="Always Open"
                      ></v-switch>
                      <v-switch
                        inset
                        v-model="assign.recognition"
                        color="green"
                        label="Face Recognition"
                      ></v-switch>

                      <v-switch
                        inset
                        v-model="assign.denyaccess"
                        color="green"
                        label="Deny access to resources"
                      ></v-switch>

                      <v-text-field
                        v-model="assign.live"
                        label="Live Monitoring Link"
                      ></v-text-field>

                      <v-text-field
                        v-model.trim="assign.attempts"
                        label="Number of allowed attempts"
                        type="number"
                      ></v-text-field>

                      <v-row>
                        <!-- Start Date Name -->
                        <v-col cols="12" sm="6" md="4">
                          <label for="opendate">Exam Open Date</label>
                          <b-form-datepicker
                            id="opendate"
                            v-model="startDate"
                            class="mb-2"
                          ></b-form-datepicker>
                        </v-col>
                        <!-- End Date Name -->

                        <!-- Start Date Name -->
                        <v-col cols="12" sm="6" md="4">
                          <label for="closedate">Exam Close Date</label>
                          <b-form-datepicker
                            id="closedate"
                            v-model="startDate"
                            class="mb-2"
                          ></b-form-datepicker>
                        </v-col>
                      </v-row>

                      <!-- Exam Time -->
                      <v-row>
                        <v-col cols="6">Start Time</v-col
                        ><v-col cols="6">End Time</v-col></v-row
                      >

                      <v-row>
                        <!-- Start Date Name -->
                        <v-col cols="6">
                          <v-time-picker
                            v-model="assign.startTime"
                            class="mt-4"
                            scrollable
                          ></v-time-picker>
                        </v-col>
                        <!-- End Date Name -->

                        <!-- Start Date Name -->
                        <v-col cols="6">
                          <v-time-picker
                            v-model="assign.endTime"
                            class="mt-4"
                            scrollable
                          ></v-time-picker>
                        </v-col>
                      </v-row>
                      <!-- End Exam Time -->
                    </b-card-text>
                    <template v-slot:footer>
                      <v-btn color="primary" @click="onSubmit">
                        Submit
                      </v-btn>
                    </template>
                  </b-card>
                </v-stepper-content>
              </v-stepper-items>
            </b-form>
          </v-stepper>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import FixedQuestion from "./FixedQuestion";
// import router from "../router";

export default {
  name: "AddExam",
  props: ["week", "title"],
  components: { FixedQuestion },
  data() {
    return {
      e1: 1,
      menu: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      assign: {},
      qmatrix: {},
      selected: null,
      weeks: [],
      lessons: [],
      dialog: false,
      selectedquestion: "",
      selectedlesson: "",
      fixedQuestions: [],
      questionsbank: [],
      eclasses: [],
      subject: this.$store.state.subjectTitle,
      subjectPath: this.$store.state.subject,
      gradePath: this.$store.state.gradePath,
      fields: [
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "100px"
        }
      ],

      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      bloom: [
        { value: "Knowledge (Remembering)", text: "Knowledge (Remembering)" },
        {
          value: "Application (Transferring)",
          text: "Application (Transferring)"
        },
        {
          value: "Comprehension (Understanding)",
          text: "Comprehension (Understanding)"
        },

        { value: "Analysis (Relating)", text: "Analysis (Relating)" },
        { value: "Synthesis (Creating)", text: "Synthesis (Creating)" },
        { value: "Evaluation (Judging)", text: "Evaluation (Judging)" }
      ],

      quiztypes: [
        {
          value: "Random From Question Bank",
          text: "Random From Question Bank"
        },
        {
          value: "Fixed Questions",
          text: "Fixed Questions"
        }
      ],

      weights: []
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.subjectPath + "/weights")
      .onSnapshot(querySnapshot => {
        this.weights = [];
        querySnapshot.forEach(doc => {
          this.weights.push({
            value: [doc.ref.id, doc.data().category],
            text: doc.data().category
          });
        });
      });

    firebase
      .firestore()
      .collection(this.gradePath + "/classes")
      .onSnapshot(querySnapshot => {
        this.eclasses = [];
        querySnapshot.forEach(doc => {
          this.eclasses.push({
            value: doc.data().title,
            text: doc.data().title
          });
        });
      });

    firebase
      .firestore()
      .collection(this.subjectPath + "/weeks")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.weeks = [];
        this.lessons = [];
        querySnapshot.forEach(doc => {
          this.weeks.push({
            id: doc.ref.id,
            title: doc.data().title
          });
        });
        querySnapshot.forEach(doc => {
          this.lessons.push({
            value: doc.ref.path,
            text: doc.data().title
          });
        });
      });

    firebase
      .firestore()
      .collection(this.subjectPath + "/questions")
      .onSnapshot(querySnapshot => {
        this.questionsbank = [];
        querySnapshot.forEach(doc => {
          if (doc.data().exam == "Yes")
            this.questionsbank.push({
              value: doc.ref.path,
              text: doc.data().title.replace(/<\/?[^>]+(>|$)/g, "")
            });
          else
            this.questionsbank.push({
              value: doc.ref.path,
              text: doc.data().title.replace(/<\/?[^>]+(>|$)/g, "")
            });
        });
      });
  },
  methods: {
    deleteQuestion(index) {
      this.fixedQuestions.splice(index, 1);
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.assign.weightcode = this.assign.weight[0];
      this.assign.weight = this.assign.weight[1];
      this.assign.fixedquestions = this.fixedQuestions;
      this.assign.opendate = this.startDate;
      this.assign.closedate = this.endDate;
      this.assign.qmatrix = this.qmatrix;

      firebase
        .firestore()
        .collection(this.subjectPath + "/exams")
        .add(this.assign)
        .then(() => {
          this.assign.title = "";

          this.$bvToast.toast("Exam Created", {
            title: "Exam Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    addToFixed() {
      if (this.fixedQuestions.includes(this.selectedquestion)) {
        alert("This Question alrady in this Exam");
      } else {
        this.fixedQuestions.push(this.selectedquestion);
      }
    },

    changeLesson() {
      firebase
        .firestore()
        .collection(this.selectedlesson + "/questions")
        .onSnapshot(querySnapshot => {
          this.questionsbank = [];
          querySnapshot.forEach(doc => {
            if (doc.data().exam == "Yes")
              this.questionsbank.push({
                value: doc.ref.path,
                text:
                  "Exam Question --------> " +
                  doc.data().title.replace(/<\/?[^>]+(>|$)/g, "")
              });
            else
              this.questionsbank.push({
                value: doc.ref.path,
                text: doc.data().title.replace(/<\/?[^>]+(>|$)/g, "")
              });
          });
        });
    },

    nextStep() {
      if (this.e1 < 3) {
        this.e1 = Number(this.e1) + 1;
      } else {
        this.e1 = 1;
      }
    },
    backStep() {
      if (this.e1 > 1) this.e1 = Number(this.e1) - 1;
    }
  }
};
</script>
