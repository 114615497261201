<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ this.examtitle }}</v-card-title>
        <v-card-text>
          <b-row>
            <b-col cols="9">
              <Attemp
                v-for="(student, index) in students"
                :key="student.key"
                style="margin-bottom:10px"
                :student="
                  student.firstname +
                    ' ' +
                    student.middlename +
                    ' ' +
                    student.lastname
                "
                :id="student.key"
                :assignment="exampath"
                :index="index + 1"
              />
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import Attemp from "./ExamAttemp";

export default {
  name: "Submissions-page",
  components: { Attemp },
  props: ["path", "grade"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      exampath: localStorage.getItem("exampath"),
      examtitle: localStorage.getItem("examtitle"),
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "student",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center" }
      ],
      classes: [],

      gradePath: localStorage.getItem("gradePath"),
      gradetitle: this.$store.state.thisGrade,
      students: [],
      errors: []
    };
  },
  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/StudentsData")
      .where("branch", "==", "Examination Center")
      .where("grade", "==", this.$store.state.thisGrade)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.students.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            lastname: doc.data().lastname,
            class: doc.data().class
          });
        });
      });
  },
  methods: {
    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/teachers/")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    },

    goToGrades(student, studentName) {
      router.push({
        name: "Gradebook",
        params: { student: student, sname: studentName }
      });
    },
    clearSearch() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    }
  }
};
</script>
