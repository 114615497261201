<template>
  <v-row
    ><v-col cols="12">
      <v-card flat outlined>
        <v-card-title>Exams</v-card-title>

        <v-card-text> <ExamsList></ExamsList> </v-card-text
      ></v-card>
    </v-col>
  </v-row>
</template>
<script>
import ExamsList from "./ExamsList";

export default {
  name: "Exams-page",
  components: { ExamsList }
};
</script>
