<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" depressed x-small dark color="success">
        <v-icon size="18">mdi-eye</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-eye</v-icon>
          Question Preview
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <div style="width: 100%; ">
          <Question :path="this.path"></Question>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn depressed color="success" small @click="dialog = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import Question from "@/components/dna/Question";
export default {
  props: ["path"],
  components: {
    Question
  },
  data() {
    return { dialog: false };
  },

  created() {},
  methods: {}
};
</script>
