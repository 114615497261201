<template>
  <div>
    <v-list rounded two-line>
      <v-list-item @click="goToPage('SubjectDash')">
        <v-list-item-avatar tile>
          <img src="@/assets/lessons.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Lessons</v-list-item-title>
          <v-list-item-subtitle>Lessons and Weeks</v-list-item-subtitle>
        </v-list-item-content>
        <v-card-actions>
          {{ lessons }}
        </v-card-actions>
      </v-list-item>
      <v-divider />

      <v-list-item @click="goToPage('Standards')">
        <v-list-item-avatar tile>
          <img src="@/assets/standards.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Standards</v-list-item-title>
          <v-list-item-subtitle>Standards & Benchmarks</v-list-item-subtitle>
        </v-list-item-content>
        <v-card-actions>
          {{ standards }}
        </v-card-actions>
      </v-list-item>

      <v-list-item @click="goToPage('Topics')">
        <v-list-item-avatar tile>
          <img src="@/assets/subject/blended.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Topics</v-list-item-title>
          <v-list-item-subtitle>Subject Topics</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('SubjectResources')">
        <v-list-item-avatar tile>
          <img src="@/assets/resources.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Resources</v-list-item-title>
          <v-list-item-subtitle>Books & Sheets</v-list-item-subtitle>
        </v-list-item-content>
        <v-card-actions>
          {{ resources }}
        </v-card-actions>
      </v-list-item>

      <v-list-item @click="goToPage('Sections')">
        <v-list-item-avatar tile>
          <img src="@/assets/sections.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Sections</v-list-item-title>
          <v-list-item-subtitle>Subject Sections</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('Live')">
        <v-list-item-avatar tile>
          <img src="@/assets/livesession.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Live Sessions</v-list-item-title>
          <v-list-item-subtitle>Live & PreRecorded</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list-item @click="goToPage('SubjectAttendance')">
        <v-list-item-avatar tile>
          <img src="@/assets/attendance.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Attendance</v-list-item-title>
          <v-list-item-subtitle>Students Attendance</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('SubjectStudents')">
        <v-list-item-avatar tile>
          <img src="@/assets/students.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Gradebook</v-list-item-title>
          <v-list-item-subtitle>Students Gradebook</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('Control')">
        <v-list-item-avatar tile>
          <img src="@/assets/conrol.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Control</v-list-item-title>
          <v-list-item-subtitle>Exams Control</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('SubjectQuestionBank')">
        <v-list-item-avatar tile>
          <img src="@/assets/questions.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Questions Bank</v-list-item-title>
          <v-list-item-subtitle>Subject Questions Bank</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item @click="goToPage('ReportCard')">
        <v-list-item-avatar tile>
          <img src="@/assets/questions.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Report Card</v-list-item-title>
          <v-list-item-subtitle>ReportCard</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item @click="goToPage('AllAssignments')" v-if="master == true">
        <v-list-item-avatar tile>
          <img src="@/assets/assignments.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Assignments</v-list-item-title>
          <v-list-item-subtitle>Subject Assignments</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('ExamSections')" v-if="this.rules.exam">
        <v-list-item-avatar tile>
          <img src="@/assets/feeling.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Exam Sections</v-list-item-title>
          <v-list-item-subtitle>Exam Sections</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('ExamBank')" v-if="this.rules.exam">
        <v-list-item-avatar tile>
          <img src="@/assets/rules.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Exam Questions</v-list-item-title>
          <v-list-item-subtitle>Exam Questions Bank</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('SubjectExams')" v-if="this.rules.exam">
        <v-list-item-avatar tile>
          <img src="@/assets/exams.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Exams</v-list-item-title>
          <v-list-item-subtitle>Subject Exams</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('SubjectCalendar')">
        <v-list-item-avatar tile>
          <img src="@/assets/calendar.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Calendar</v-list-item-title>
          <v-list-item-subtitle>Activities Schedule</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="goToPage('TeachersList')">
        <v-list-item-avatar tile>
          <img src="@/assets/hr.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Teachers</v-list-item-title>
          <v-list-item-subtitle>Subject Teachers</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item @click="goToPage('SubjectSetting')">
        <v-list-item-avatar tile>
          <img src="@/assets/setting.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> Setting</v-list-item-title>
          <v-list-item-subtitle>Subject Setting</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "SubjecSections",
  data() {
    return {
      master: this.$store.state.master,
      lessons: 0,
      standards: 0,
      resources: 0,
      rules: this.$store.state.rules
    };
  },
  async created() {
    await firebase
      .firestore()
      .collection(this.$store.state.subject + "/weeks")
      .get()
      .then(snapshot => (this.lessons = snapshot.size));
    await firebase
      .firestore()
      .collection(this.$store.state.subject + "/standards")
      .get()
      .then(snapshot => (this.standards = snapshot.size));
    await firebase
      .firestore()
      .collection(this.$store.state.subject + "/resources")
      .get()
      .then(snapshot => (this.resources = snapshot.size));
  },
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
