<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button variant="danger" v-bind="attrs" v-on="on">
        <v-icon size="20">mdi-video-outline</v-icon>
        <small style="margin-left: 10px;">Central Video Library</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-video-outline</v-icon>Central
          Video Library
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- School -->
        <v-row class="card-text">
          <!-- System -->

          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="csystems"
              v-model="activities.system"
              @change="onSchoolChange"
              label="System"
            ></v-select>
          </v-col>
          <!-- Grade -->
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="grades"
              v-model="activities.grade"
              @change="onGradeChange"
              label="Grade"
            ></v-select>
          </v-col>
          <!-- Grade -->
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="subjects"
              v-model="activities.subject"
              label="Subject"
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="3" sm="3">
            <div>
              <b-button variant="info" @click="search">
                <v-icon>mdi-magnify</v-icon>Search
              </b-button>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="this.videolink">
          <v-col class="d-flex" cols="6" sm="6">
            <video-embed :src="this.videolink"></video-embed>
          </v-col>
          <v-col class="d-flex" cols="6" sm="6">
            <v-card flat>
              <v-card-subtitle>{{ this.selectedWeek }}</v-card-subtitle>
              <v-card-title>{{ this.selectedTitle }}</v-card-title>
              <v-card-text
                ><b-button
                  @click="
                    importS(
                      selectedTitle,
                      videolink,
                      system,
                      grade,
                      subject,
                      weekid
                    )
                  "
                  size="sm"
                  >Import to lesson</b-button
                ></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <br />

        <vue-good-table
          :columns="sfields"
          :rows="activities"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records' }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn btn-danger btn-sm"
                @click="
                  setvideo(
                    props.row.link,
                    props.row.title,
                    props.row.week_title,

                    props.row.system,
                    props.row.grade,
                    props.row.subject,
                    props.row.week
                  )
                "
              >
                <v-icon>mdi-youtube</v-icon>&nbsp; Preview
              </button>
              &nbsp;
              <button
                class="btn btn-success btn-sm"
                style="padding-left:20"
                @click="
                  importS(
                    props.row.title,
                    props.row.link,
                    props.row.system,
                    props.row.grade,
                    props.row.subject,
                    props.row.week
                  )
                "
              >
                <v-icon>mdi-application-import</v-icon>&nbsp; Import
              </button>
            </span>
            <span v-else-if="props.column.field == 'type'">
              <v-icon color="red">mdi-video-outline</v-icon>
            </span>
          </template>
        </vue-good-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["path", "order"],
  data() {
    return {
      dialog: false,
      activities: [],
      csystems: [],
      grades: [],
      subjects: [],
      newActivity: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      system: "",
      grade: "",
      subject: "",
      weekid: "",

      sfields: [
        {
          field: "type",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "system",
          label: "System",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "week",
          label: "Week",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    firebase
      .firestore()
      .collection("activities_hub/")
      .onSnapshot(querySnapshot => {
        this.csystems = [];
        querySnapshot.forEach(doc => {
          this.csystems.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  },
  methods: {
    onSchoolChange() {
      this.grades = [];

      firebase
        .firestore()
        .collection("activities_hub/" + this.activities.system + "/grades/")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    onGradeChange() {
      this.subjects = [];

      firebase
        .firestore()
        .collection(
          "activities_hub/" +
            this.activities.system +
            "/grades/" +
            this.activities.grade +
            "/subjects"
        )
        .onSnapshot(querySnapshot => {
          this.subjects = [];
          querySnapshot.forEach(doc => {
            this.subjects.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    setvideo(link, title, week, system, grade, subject, weekid) {
      this.videolink = link;
      this.selectedTitle = title;
      this.selectedWeek = week;

      this.system = system;
      this.grade = grade;
      this.subject = subject;
      this.weekid = weekid;
    },
    search() {
      firebase
        .firestore()
        .collection(
          "activities_hub/" +
            this.activities.system +
            "/grades/" +
            this.activities.grade +
            "/subjects/" +
            this.activities.subject +
            "/videos"
        )
        .orderBy("week")
        .onSnapshot(querySnapshot => {
          this.activities = [];
          querySnapshot.forEach(doc => {
            this.activities.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              system: doc.data().system,
              grade: doc.data().grade,
              subject: doc.data().subject,
              link: doc.data().link,
              week: doc.data().week,
              week_title: doc.data().week_title,
              section: doc.data().section
            });
          });
        });
    },

    importS(title, link, system, grade, subject, week) {
      this.newActivity = {};
      this.newActivity.order = Number(this.order) + 1;
      this.newActivity.type = "video";
      this.newActivity.title = title;
      this.newActivity.link = link;

      this.newActivity.system = system;
      this.newActivity.grade = grade;
      this.newActivity.subject = subject;
      this.newActivity.week = week;

      firebase
        .firestore()
        .collection(this.path + "/lesson/")
        .add(this.newActivity)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Import",
            this.newActivity,
            this.path,
            "Vidoe"
          );

          this.$bvToast.toast("Activity Imported", {
            title: "Activity Imported Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
