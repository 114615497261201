<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Discipline</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <v-card>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Grade Name -->

            <!-- <v-col cols="12" md="6">
                <v-text-field
                  
                  :counter="10"
                  label="Reason"
                  v-model.trim="record.reason"
                  required
                ></v-text-field>
              </v-col>-->

            <v-col class="d-flex" cols="12" sm="12">
              <v-autocomplete
                :items="reasons"
                v-model.trim="record.reason"
                label="Reason of referral"
              ></v-autocomplete>
            </v-col>
            <!-- <v-col class="d-flex" cols="12" sm="12">
              <v-select
                :items="action"
                v-model.trim="record.action"
                label="Prior action"
              ></v-select>
            </v-col> -->
            <v-col class="d-flex" cols="12" sm="12">
              <v-autocomplete
                :items="adminaction"
                v-model.trim="record.adminaction"
                label="Administrative action"
              ></v-autocomplete>
            </v-col>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary"
                >Add Discipline</b-button
              >
            </b-card-footer>
          </b-form>
        </v-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddDiscipline",
  props: ["id"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.id +
            "/disciplines"
        ),
      record: {},
      timestamp: "",
      reasons: [
        { value: "Disruption in Class", text: "Disruption in Class" },
        { value: "Disruption Common Area", text: "Disruption Common Area" },
        { value: "Disruption Hall", text: "Disruption Hall" },
        {
          value: "Disruption Restroom",
          text: "Disruption Restroom"
        },
        {
          value: "Disruption School Grounds",
          text: "Disruption School Grounds"
        },
        {
          value: "Unacceptable Language",
          text: "Unacceptable Language"
        },
        {
          value: "Chronic Tardiness in the morning",
          text: "Chronic Tardiness in the morning"
        },
        {
          value: "Chronic Tardiness to Class",
          text: "Chronic Tardiness to Class"
        },
        {
          value: "Dress Code Violation",
          text: "Dress Code Violation"
        },
        {
          value: "Disrespectful Actions to Peers",
          text: "Disrespectful Actions to Peers"
        },
        {
          value: "Disrespectful Actions to Adults",
          text: "Disrespectful Actions to Adults"
        },
        {
          value: "Unable to Follow Reasonable Directions",
          text: "Unable to Follow Reasonable Directions"
        },
        { value: "Misuse of Technology", text: "Misuse of Technology" },
        { value: "Skipping Class", text: "Skipping Class" },
        {
          value: "Skipping Detention",
          text: "Skipping Detention"
        },
        {
          value: "Horseplay",
          text: "Horseplay"
        },
        { value: "Play Fighting", text: "Play Fighting" },
        {
          value: "Willful Damage of Another’s Property",
          text: "Willful Damage of Another’s Property"
        },
        { value: "Minor Vandalism", text: "Minor Vandalism" },
        { value: "Leaving School Building", text: "Leaving School Building" },
        { value: "Leaving School Grounds", text: "Leaving School Grounds" },
        { value: "Theft of property", text: "Theft of property" },
        { value: "Fighting", text: "Fighting" },
        { value: "Cyber Bullying", text: "Cyber Bullying" },
        { value: "Verbal Bullying", text: "Verbal Bullying" },
        { value: "Physical Bullying", text: "Physical Bullying" },
        { value: "Major Vandalism", text: "Major Vandalism" },
        { value: "Intentional Assault", text: "Intentional Assault" },
        {
          value: "Possession of Inappropriate substances",
          text: "Possession of Inappropriate substances"
        },
        {
          value: "Use of Inappropriate substances",
          text: "Use of Inappropriate substances"
        },
        {
          value: "Possession of Dangerous Items",
          text: "Possession of Dangerous Items"
        },
        {
          value: "Use Possession of Dangerous Items",
          text: "Use Possession of Dangerous Items"
        }
      ],

      // action: [
      //   { value: "Verbal Warning", text: "Verbal Warning" },
      //   { value: "Verbal Warning", text: "Verbal Warning" },
      //   { value: "Silent Lunch", text: "Silent Lunch" },
      //   { value: "Conference with Parents", text: "Conference with Parents" },
      //   { value: "Conference with Student", text: "Conference with Student" },
      //   {
      //     value: "In-Team / In-Grade Displacement",
      //     text: "In-Team / In-Grade Displacement"
      //   },
      //   { value: "After-School Detentio", text: "After-School Detentio" }
      // ],
      adminaction: [
        {
          value: "Verbal Warning",
          text: "Verbal Warning"
        },
        {
          value: "Written Warning",
          text: "Written Warning"
        },
        {
          value: "Silent Lunch",
          text: "Silent Lunch"
        },
        {
          value: "After School Detention",
          text: "After School Detention"
        },
        {
          value: "In-school Suspension",
          text: "In-school Suspension"
        },
        { value: "Out of School Suspension", text: "Out of School Suspension" }
      ]
    };
  },

  created() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.record.date = this.timestamp;

      this.ref
        .add(this.record)
        .then(() => {
          this.record.reason = "";
          this.record.action = "";
          this.record.adminaction = "";

          this.$bvToast.toast("Record Created", {
            title: "Record Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
