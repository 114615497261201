<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-text>
          <v-card dark style="background-color:#1b2e47">
            <v-card-title>
              <v-icon large left>mdi-account-multiple-check-outline</v-icon>
              <span class="title">Students Attendance</span>
              <!--  <b-button @click="transfer">Transfer</b-button> -->

              <v-row align="center" justify="end"> </v-row>

              <v-col cols="12" sm="6" md="4">
                <label for="Attendance">Attendance Date</label>
                <b-form-datepicker
                  id="Attendance"
                  v-model="date"
                  class="mb-2"
                ></b-form-datepicker>
              </v-col>
            </v-card-title>

            <!-- School -->
            <v-card-text>
              <v-row>
                <v-col class="d-flex" cols="4" sm="4">
                  <v-select
                    :items="schools"
                    v-model="student.school"
                    label="School"
                    @change="onSchoolChange()"
                  ></v-select>
                </v-col>
                <!-- Grade Level -->

                <v-col class="d-flex" cols="4" sm="4">
                  <v-select
                    :items="level"
                    v-model="student.grade"
                    label="Grade Level"
                    @change="onGradeChange()"
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="4" sm="4">
                  <v-select
                    :items="classes"
                    v-model="student.class"
                    label="Class"
                    @change="onClassChange()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer
                ><b-button variant="success" @click="clearSearch"
                  >Clear Search</b-button
                ></v-card-actions
              >
            </v-card-text>
          </v-card>

          <vue-good-table
            style="margin-top:20px;"
            :columns="fields"
            :rows="studentInfo"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'absence'">
                <v-btn
                  block
                  dark
                  small
                  color="red"
                  elevation="0"
                  class="btn btn-danger btn-sm"
                  @click="
                    absence(
                      props.row.key,
                      props.row.firstname + ' ' + props.row.lastname
                    )
                  "
                >
                  Absence
                </v-btn>
              </span>
              <span v-else-if="props.column.field == 'late'">
                <v-btn
                  block
                  dark
                  small
                  color="green"
                  elevation="0"
                  @click="
                    late(
                      props.row.key,
                      props.row.firstname + ' ' + props.row.lastname
                    )
                  "
                >
                  Late
                </v-btn>
              </span>
              <span v-else-if="props.column.field == 'leave'">
                <v-btn
                  block
                  dark
                  small
                  color="blue"
                  elevation="0"
                  @click="
                    leave(
                      props.row.key,
                      props.row.firstname + ' ' + props.row.lastname
                    )
                  "
                >
                  Leave
                </v-btn>
              </span>

              <span v-else-if="props.column.field == 'firstname'">
                {{
                  props.row.firstname +
                    " " +
                    props.row.middlename +
                    " " +
                    props.row.lastname
                }}
              </span>
              <span v-else-if="props.column.field == 'list'">
                <v-btn block dark small color="blue-grey" elevation="0">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "Attendance-page",
  components: {},

  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      fields: [
        {
          field: "list",
          label: "",
          sortable: true,
          class: "text-left",
          width: "50px"
        },
        {
          field: "branch",
          label: "Branch",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firstname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "absence",
          label: "",
          class: "text-center",
          width: "100px"
        },
        {
          field: "late",
          label: "",
          class: "text-center",
          width: "100px"
        },
        {
          field: "leave",
          label: "",
          class: "text-center",
          width: "100px"
        }
      ],
      record: {},
      studentInfo: [],
      studentExpo: {},
      errors: [],
      student: [],
      schools: [],
      level: [],
      classes: [],
      ref: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData"),

      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  created() {
    // this.ref.onSnapshot(querySnapshot => {
    //   this.studentInfo = [];
    //   querySnapshot.forEach(doc => {
    //     if (doc.data().type != "parent")
    //       this.studentInfo.push({
    //         key: doc.id,
    //         path: doc.ref.path,
    //         branch: doc.data().branch,
    //         grade: doc.data().grade,
    //         class: doc.data().class,
    //         firstname: doc.data().firstname,
    //         middlename: doc.data().middlename,
    //         lastname: doc.data().lastname,
    //         username: doc.data().username,
    //         password: doc.data().password
    //       });
    //   });
    // });

    // this.gradesRef.onSnapshot(querySnapshot => {
    //   this.level = [];
    //   querySnapshot.forEach(doc => {
    //     this.level.push({
    //       text: doc.data().title,
    //       value: doc.id
    //     });
    //   });
    // });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  },
  methods: {
    clearSearch() {
      this.studentInfo = [];
      // this.ref.onSnapshot(querySnapshot => {
      //   this.studentInfo = [];
      //   querySnapshot.forEach(doc => {
      //     if (doc.data().type != "parent")
      //       this.studentInfo.push({
      //         key: doc.id,
      //         path: doc.ref.path,
      //         grade: doc.data().grade,
      //         branch: doc.data().branch,
      //         class: doc.data().class,
      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         lastname: doc.data().lastname,
      //         username: doc.data().username,
      //         password: doc.data().password
      //       });
      //   });
      // });
    },
    absence(id, title) {
      this.$confirm({
        message: "Are you sure You want to mark " + title + " as absence?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.record.date = this.date;
            this.record.type = "absence";
            this.record.area = "full day";

            firebase
              .firestore()
              .collection(
                "schools/" + this.$store.state.school + "/StudentsData"
              )
              .doc(id)
              .collection("attendance")
              .add(this.record)
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    late(id, title) {
      this.$confirm({
        message: "Are you sure You want to mark " + title + " as late?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.record.date = this.date;
            this.record.type = "late";
            this.record.area = "full day";

            firebase
              .firestore()
              .collection(
                "schools/" + this.$store.state.school + "/StudentsData"
              )
              .doc(id)
              .collection("attendance")
              .add(this.record)
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    leave(id, title) {
      this.$confirm({
        message: "Are you sure You want to mark " + title + " as leave?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.record.date = this.date;
            this.record.type = "leave";
            this.record.area = "full day";

            firebase
              .firestore()
              .collection(
                "schools/" + this.$store.state.school + "/StudentsData"
              )
              .doc(id)
              .collection("attendance")
              .add(this.record)
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    onGradeChange() {
      this.classes = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/" +
            this.student.grade +
            "/classes"
        )
        .onSnapshot(querySnapshot => {
          this.classes = [];
          querySnapshot.forEach(doc => {
            this.classes.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("grade", "==", this.student.grade)
        .onSnapshot(querySnapshot => {
          this.studentInfo = [];
          querySnapshot.forEach(doc => {
            this.studentInfo.push({
              key: doc.id,
              path: doc.ref.path,
              grade: doc.data().grade,
              branch: doc.data().branch,
              class: doc.data().class,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              username: doc.data().username,
              password: doc.data().password
            });
          });
        });
    },
    onClassChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/students")
        .where("grade", "==", this.student.grade)
        .where("class", "==", this.student.class)
        .onSnapshot(querySnapshot => {
          this.studentInfo = [];
          querySnapshot.forEach(doc => {
            this.studentInfo.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              grade: doc.data().grade,
              class: doc.data().class,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              username: doc.data().username,
              password: doc.data().password
            });
          });
        });
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/"
        )
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });

      // firebase
      //   .firestore()
      //   .collection("schools/" + this.$store.state.school + "/students")
      //   .where("branch", "==", this.student.school)
      //   .onSnapshot(querySnapshot => {
      //     this.studentInfo = [];
      //     querySnapshot.forEach(doc => {
      //       this.studentInfo.push({
      //         key: doc.id,
      //         path: doc.ref.path,
      //         grade: doc.data().grade,
      //         branch: doc.data().branch,
      //         class: doc.data().class,
      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         lastname: doc.data().lastname,
      //         username: doc.data().username,
      //         password: doc.data().password
      //       });
      //     });
      //   });
    },

    details(id) {
      router.push({
        name: "ShowStudent",
        params: {
          id: id
        }
      });
    },
    Edit(id) {
      router.push({
        name: "EditStudent",
        params: {
          path: id
        }
      });
    }
  }
};
</script>
