<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button
        variant="#fff"
        style="background-color:#a0c633; color:#000; width:30%;"
        squared
        v-bind="attrs"
        v-on="on"
      >
        <img src="@/assets/calendarindex.png" width="40px" />
        <small style="margin-left: 10px;">Create Lesson</small>
      </b-button>
    </template>
    <v-card flat outlined>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-calendar</v-icon>Create Lesson
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <b-row>
          <b-col cols="12">
            <v-card outlined flat>
              <v-card-text>
                <b-form @submit.stop.prevent="onSubmit">
                  <!-- Week Name -->
                  <b-form-group
                    id="fieldsetname"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="Name"
                  >
                    <b-form-input
                      id="name"
                      placeholder="Lesson title"
                      v-model.trim="week.title"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-checkbox
                    v-model="week.hidden"
                    name="check-button"
                    switch
                  >
                    Hide The Lesson
                  </b-form-checkbox>
                  <br />

                  <b-form-checkbox
                    v-model="week.current"
                    name="check-button"
                    switch
                  >
                    Current Lesson
                  </b-form-checkbox>

                  <!-- <b-form-checkbox
                    v-model="week.revision"
                    name="check-button"
                    switch
                  >
                    Revision
                  </b-form-checkbox>
-->
                  <b-form-checkbox
                    v-model="week.nofc"
                    name="check-button"
                    switch
                  >
                    Exclude From Flipped Classroom
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="week.noexit"
                    name="check-button"
                    switch
                  >
                    Exclude From Exit Ticket
                  </b-form-checkbox>
                  <br />

                  <b-form-checkbox
                    v-model="week.summer"
                    name="check-button"
                    switch
                  >
                    Summer Work
                  </b-form-checkbox>

                  <br />

                  <b-form-checkbox
                    v-model="week.orientation"
                    name="check-button"
                    switch
                  >
                    Orientation Week
                  </b-form-checkbox>

                  <br />

                  <!--   <b-form-group
                id="fieldsetstandard"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Standard"
              >
                <v-autocomplete
                  v-model.trim="week.standard"
                  :items="standards"
                  dense
                ></v-autocomplete>
              </b-form-group>-->

                  <b-form-group
                    id="fieldsection"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="Section"
                  >
                    <b-form-select
                      id="section"
                      placeholder="Section"
                      v-model.trim="week.section"
                      :options="sections"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    id="fielddate"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="Lesson Date"
                  >
                    <b-form-datepicker
                      id="SessionDate"
                      v-model="date"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-form-group>

                  <!--    <b-form-group
                id="fieldObjectives"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Lesson Objectives"
              >
                <quill-editor
                  ref="name"
                  required
                  v-model="week.objectives"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                />
              </b-form-group>-->

                  <!-- Upload Image -->
                  <v-col cols="12" md="12">
                    <b-form-group
                      id="fielplan"
                      horizontal
                      :label-cols="4"
                      breakpoint="md"
                      label="Weekly Plan"
                    >
                      <p>Weekly Plan:</p>
                      <b-form-file
                        type="file"
                        @change="previewImage"
                        accept=".pdf, .pptx, .doc, .docx"
                      />

                      <div>
                        <p>
                          <small>
                            Progress:
                            {{ uploadValue.toFixed() + "%" }}
                          </small>
                        </p>
                      </div>
                      <div v-if="this.weekly != null">
                        Upload Completed
                      </div>
                      <div v-if="imageData != null">
                        <v-progress-linear
                          id="progress"
                          color="light-blue"
                          height="10"
                          :value="uploadValue"
                          max="100"
                          striped
                        ></v-progress-linear>
                        <b-button @click="onUpload" variant="success"
                          >Upload</b-button
                        >
                      </div>
                    </b-form-group>
                  </v-col>

                  <!-- End Of Uploading -->

                  <!-- Submit Form -->
                  <b-button type="submit" variant="primary"
                    >Add Lesson</b-button
                  >
                </b-form>
              </v-card-text>
            </v-card>
          </b-col>
        </b-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "@/Firebase";

// import router from "../router";

export default {
  name: "AddWeek",
  props: ["path", "order"],
  data() {
    return {
      dialog: false,
      standarop: [{ standard: "", sub: "" }],
      standards_count: 0,
      standards: [],
      sub: [],
      ref: firebase.firestore().collection(this.path + "/weeks"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      date: new Date().toISOString().substr(0, 10),
      week: {},
      menu2: false,
      selected: null,
      options: [
        { value: "1", text: "First Term" },
        { value: "2", text: "Second Term" }
      ],
      sections: [],
      //Upload Weekly Plan

      imageData: null,
      weekly: null,
      uploadValue: 0,

      sectionsRef: firebase.firestore().collection(this.path + "/sections/")
    };
  },
  methods: {
    addstandard() {
      this.standards_count++;
      this.standarop.push({ standard: "", sub: "" });
    },

    deletestandard(index) {
      this.standarop.splice(index, 1);
    },

    standardchange(index) {
      this.sub = [];

      firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards")
        .doc(this.standarop[index].standard)
        .collection("substandards")
        .onSnapshot(querySnapshot => {
          this.sub = [];
          querySnapshot.forEach(doc => {
            this.sub.push({
              text: doc.data().code + "-" + doc.data().title,
              value: doc.id
            });
          });
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.week.order = Number(this.order) + 1;
      this.week.date = this.date;
      this.week.standard = this.standarop;
      this.week.breakdown = this.$store.state.breakdown;
      if (this.weekly != null) {
        this.week.weekly = this.weekly;
      }
      if (this.week.term == null) {
        this.week.term = "First Term";
      }

      this.ref
        .add(this.week)
        .then(() => {
          this.week.title = "";
          this.week.term = "";
          this.week.order = "";
          this.week.section = "";
          this.week.weekly = "";
          this.standarop = "";
          this.week.breakdown = "";

          this.$bvToast.toast("Week Created", {
            title: "Week Created Successfully",
            variant: "success",
            solid: true
          });
          this.dialog = false;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.weekly = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/Weekly Plan/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.imageData.name
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.weekly = url;
          });
        }
      );
    }
  },
  created() {
    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.data().code
        });
      });
    });
    this.sectionsRef.onSnapshot(querySnapshot => {
      this.sections = [];
      querySnapshot.forEach(doc => {
        this.sections.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  }
};
</script>
