<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Grade</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <b-card>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- School Name -->
            <v-col class="d-flex" cols="12" sm="6">
              <b-select
                label="School"
                v-model.trim="grade.school"
                :options="schools"
              >
              </b-select>
            </v-col>

            <!-- Grade Order -->

            <v-col class="d-flex" cols="12" sm="2">
              <v-text-field
                type="number"
                label="Grade Order"
                v-model.trim="grade.order"
                required
              ></v-text-field>
            </v-col>
            <!-- Grade Name -->
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                label="Grade Title"
                v-model.trim="grade.title"
                required
              ></v-text-field>
            </v-col>
            <!-- Submit Form -->
            <b-card-footer>
              <b-button type="submit" variant="primary">Add Grade</b-button>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddGrade",
  data() {
    return {
      grade: {},
      schools: [],
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.grade.order = parseInt(this.grade.order);
      this.grade.openadmission = true;

      firebase
        .firestore()
        .doc(
          "/schools/" +
            this.$store.state.school +
            "/branches/" +
            this.grade.school +
            "/grades/" +
            this.grade.title
        )
        .set(this.grade)
        .then(() => {
          this.$bvToast.toast("Grade Created", {
            title: "Grade Created Successfully",
            variant: "success",
            solid: true,
          });

          this.grade.title = "";
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
  },
  created() {
    this.schoolsRef.onSnapshot((querySnapshot) => {
      this.schools = [];
      querySnapshot.forEach((doc) => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id,
        });
      });
    });
  },
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
