<template>
  <div>
    <WeeksList :path="this.$store.state.subject"></WeeksList>
  </div>
</template>

<script>
import WeeksList from "../weeks/WeeksList";
import router from "@/router";

export default {
  name: "SubjectDash",
  components: {
    WeeksList
  },

  data() {
    return {
      classlist: "",
      grade: this.$store.state.thisGrade
    };
  },
  created() {
    this.classlist = localStorage.getItem("classList");
  },
  methods: {
    goToLive() {
      window.open("https://teams.microsoft.com", "_blank");
    },
    goToQuestionBank() {
      router.push({
        name: "SubjectQuestionBank"
      });
    }
  }
};
</script>
