<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-text>
          <AddExam
            :title="this.$route.params.title"
            :week="this.subjectPath"
          ></AddExam>

          <br />
          <b-card-group deck>
            <CountCard
              title="Exams"
              color="primary"
              :path="this.subjectPath + '/exams'"
            ></CountCard>
            <CountCard title="Active" color="success"></CountCard>
            <CountCard title="InActive" color="danger"></CountCard>
          </b-card-group>
          <br />
          <vue-good-table
            :columns="fields"
            :rows="assignments"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->
              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteAssignment(props.row.key, props.row.title)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </button>
                &nbsp;
                <button
                  class="btn btn-info btn-sm"
                  @click="Edit(props.row.path)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </button>
                &nbsp;
                <button
                  class="btn btn-warning btn-sm"
                  @click="
                    goToSubmissions(
                      props.row.title,
                      props.row.path,
                      props.row.grade
                    )
                  "
                >
                  Submissions
                  <v-icon>mdi-format-list-checks</v-icon>
                </button>
                &nbsp;
                <button
                  class="btn btn-success btn-sm"
                  @click="goToExam(props.row.path, props.row.title)"
                >
                  View
                  <v-icon>mdi-eye</v-icon>
                </button>
                &nbsp;
                <!-- <button
                  class="btn btn-warning btn-sm"
                  @click="goToExamControl(props.row.title, props.row.path)"
                >
                  Exam Invigilator
                  <v-icon>mdi-monitor-eye</v-icon>
                </button> -->
              </span>

              <span v-else-if="props.column.field == 'disable'">
                <v-switch
                  inset
                  v-model="props.row.disable"
                  color="red"
                  @change="disableExam(props.row.path, props.row.disable)"
                ></v-switch>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import CountCard from "@/components/dna/widgets/CountCard.vue";
import AddExam from "./AddExam.vue";
import router from "@/router";

export default {
  name: "Dimensions-page",
  components: {
    CountCard,
    AddExam
  },

  data() {
    return {
      assignments: {},
      subjectPath: this.$store.state.subject,

      fields: [
        {
          field: "disable",
          label: "Disable",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "400px"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.subjectPath + "/exams")
      .onSnapshot(querySnapshot => {
        this.assignments = [];
        querySnapshot.forEach(doc => {
          this.assignments.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            type: doc.data().type,
            grade: doc.data().grade,
            disable: doc.data().disable,
            weight: doc.data().weight
          });
        });
      });
  },
  methods: {
    deleteAssignment(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.subjectPath + "/exams")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Exam Deleted", {
                  title: "Exam Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    disableExam(path, dis) {
      firebase
        .firestore()
        .doc(path)
        .update({ disable: dis });
    },
    goToSubmissions(title, path, grade) {
      localStorage.setItem("exampath", path);
      localStorage.setItem("examtitle", title);
      localStorage.setItem("examgrade", grade);

      router.push({
        name: "ExamSubmissions"
      });
    },
    goToExamControl(title, path) {
      router.push({
        name: "ExamControl",
        params: { title: title, path: path }
      });
    },

    goToExam(path, title) {
      router.push({
        name: "Exam",
        params: { path: path, title: title }
      });
    },
    Edit(path) {
      router.push({
        name: "EditExam",
        params: { path: path }
      });
    }
  }
};
</script>

<style></style>
