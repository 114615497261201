<template>
  <div>
    <b-navbar type="light" variant="warning">
      <b-navbar-nav>
        <!-- Navbar dropdowns -->
        <b-nav-item-dropdown text="Academic Reports">
          <b-dropdown-item @click="goToPage('HODReport')">
            <img src="@/assets/rresources.png" width="20px" />
            Resources Report</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('AssignmentsReport')">
            <img src="@/assets/rprogress.png" width="20px" />
            Assignments Report
          </b-dropdown-item>
          <b-dropdown-item @click="goToPage('SchoolsProgressReport')">
            <img src="@/assets/schools.png" width="20px" />

            School Progress</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('WeeklyProgressReport')">
            <img src="@/assets/lesson.png" width="20px" />

            Weekly Progress</b-dropdown-item
          >

          <b-dropdown-item @click="goToPage('StudentsProgressReport')">
            <img src="@/assets/admission.png" width="20px" />

            Students Progress</b-dropdown-item
          >

          <b-dropdown-item @click="goToPage('StandardsReport')">
            <img src="@/assets/exams.png" width="20px" />

            Standards Progress</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('StandardsListReport')">
            <img src="@/assets/assignments.png" width="20px" />

            Standards List</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Students Reports">
          <b-dropdown-item @click="goToPage('StudentsReport')">
            <img src="@/assets/standards.png" width="20px" />
            Students Report</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('AttendanceReport')">
            <img src="@/assets/attendance.png" width="20px" />
            Attendance Report</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item v-if="this.rules.admission">Admission Report</b-nav-item>

        <b-nav-item-dropdown
          text="Accounting Reports"
          v-if="this.rules.accounting"
        >
          <b-dropdown-item @click="goToPage('SchoolsCollectionReport')">
            <img src="@/assets/school.png" width="20px" />
            Schools Collection Monitor</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('CollectionReport')">
            <img src="@/assets/admission.png" width="20px" />
            Students Balance Report</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('BalanceMartrixReport')">
            <img src="@/assets/assignments.png" width="20px" />
            Balance Matrix Report</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('PerDateReport')">
            <img src="@/assets/timetable.png" width="20px" />
            Payments Per Date Report</b-dropdown-item
          >
          <b-dropdown-item @click="goToPage('DisountsReport')">
            <img src="@/assets/questions.png" width="20px" />
            Discounts Report</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item @click="goToPage('HRReport')" v-if="this.rules.hr"
          >Human Resources</b-nav-item
        >
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: "SubjecSections",
  data() {
    return {
      lessons: 0,
      standards: 0,
      resources: 0,
      rules: ""
    };
  },
  created() {
    this.rules = this.$store.state.rules;
  },

  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
