<template>
  <div>
    <!--Filter by Month-->
    <v-card dark style="background-color:#1b2e47" flat>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              :items="classList"
              label="Select Class"
              v-model="selectedClass"
            />
          </v-col>
          <v-col cols="7">
            <v-autocomplete
              :items="lessons"
              label="Select Lesson"
              v-model="selected"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="showall"
              label="All Weeks"
              color="green"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="clearSearch" depressed small
          >Clear Search</v-btn
        >
        <v-btn color="green" @click="getGrades" depressed small>Search</v-btn>
      </v-card-actions>
    </v-card>
    <div
      style="   overflow-y: auto;
  overflow-x: auto;"
    >
      <div style="margin-top:20px; width:80000px;">
        <v-row
          ><div style="width:300px; margin-left:20px;">
            Student Name
          </div>

          <v-row>
            <span v-for="assignment in assignments" :key="assignment.key">
              <v-card
                flat
                outlined
                class="assignlist"
                @click="gotoAssignment(assignment.title, assignment.path)"
              >
                <span style="color:red"> {{ assignment.weight }}</span>
                <br />
                {{ assignment.title.substr(0, 30) }}</v-card
              >
            </span>
          </v-row>
        </v-row>
      </div>
      <v-divider />

      <div v-for="student in students" :key="student.key">
        <StudentGrade
          :path="student.path"
          :studentname="student.studentname"
          :studentclass="student.studentclass"
          :assignmants="assignments"
          :studentid="student.key"
        />
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import StudentGrade from "./StudentGrade";
import router from "@/router";

export default {
  name: "GradebookOverview",
  components: { StudentGrade },
  data() {
    return {
      students: [],
      weeks: [],
      assignments: [],
      lessons: [],
      weights: [],
      week: "",
      date: new Date().toISOString().substr(0, 7),
      subject: this.$store.state.subject,
      subjecttitle: this.$store.state.subjectTitle,
      menu: false,
      modal: false,
      selected: "",
      selectedClass: "",
      breakdown: "",
      showall: false,
      mirror: this.$store.state.mirror,
      classList: []
    };
  },
  created() {
    try {
      if (localStorage.getItem("classList"))
        this.classList = JSON.parse(localStorage.getItem("classList"));
    } catch (e) {
      console.log(e);
    }

    if (
      this.mirror == null ||
      this.mirror == "undefined" ||
      this.mirror == ""
    ) {
      this.subject = this.$store.state.subject;
    } else {
      this.subject = this.$store.state.mirror;
    }

    if (this.$store.state.thisBranch.includes("American"))
      this.breakdown = "Quarter 1";
    else this.breakdown = "Term 1";

    firebase
      .firestore()
      .collection(this.subject + "/weeks/")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.lessons = [];
        querySnapshot.forEach(doc => {
          this.lessons.push({
            key: doc.id,
            text: doc.data().title,
            date: doc.data().date,
            value: [doc.ref.path, doc.id],
            noblended: doc.data().noblended
          });
        });
      });
  },
  methods: {
    clearSearch() {
      window.location.reload();
    },
    search() {
      //! Get Subject Weeks
      this.assignments = [];
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/tasks")
        .orderBy("weight")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const assignmentdata = doc.data();

            if (assignmentdata.graded != true)
              this.assignments.push({
                key: doc.id,
                title: assignmentdata.title,
                weight: assignmentdata.weight
              });
          });
        });

      // this.assignments.push({
      //   key: this.selected[1],
      //   title: "Flipped",
      //   weight: "Flipped"
      // });
      // this.assignments.push({
      //   key: this.selected[1],
      //   title: "Exit",
      //   weight: "Exit"
      // });
    },
    getGrades() {
      //! Get Subject Students
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.$store.state.gradePath)
        // .where("branch", "==", this.$store.state.thisBranch)
        // .where("grade", "==", this.$store.state.thisGrade)
        .where("class", "==", this.selectedClass)
        .get()
        .then(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            const studentdata = doc.data();
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              studentclass: studentdata.class,
              studentname:
                studentdata.firstname +
                " " +
                studentdata.middlename +
                " " +
                studentdata.lastname
            });
          });
        });

      if (this.showall == false) {
        //! Get Subject Weeks
        this.assignments = [];
        firebase
          .firestore()
          .collection(this.$store.state.subject + "/tasks")
          .where("week", "==", this.selected[0])
          // .orderBy("weight")
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const assignmentdata = doc.data();

              if (assignmentdata.graded != true)
                this.assignments.push({
                  key: doc.id,
                  path: doc.ref.path,
                  title: assignmentdata.title,
                  weight: assignmentdata.weight
                });
            });
          });

        this.assignments.push({
          key: this.selected[1],
          title: "Flipped",
          weight: "Flipped"
        });
        this.assignments.push({
          key: this.selected[1],
          title: "Exit",
          weight: "Exit"
        });
        this.assignments.push({
          key: this.selected[1],
          title: "Inclass",
          weight: "Inclass"
        });
        this.assignments.push({
          key: this.selected[1],
          title: "Participation",
          weight: "Participation"
        });
      } else {
        this.assignments = [];

        firebase
          .firestore()
          .collection(this.$store.state.subject + "/tasks")

          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const assignmentdata = doc.data();

              if (assignmentdata.graded != true)
                this.assignments.push({
                  key: doc.id,
                  path: doc.ref.path,
                  title: assignmentdata.title,
                  weight: assignmentdata.weight
                });
            });
          });
      }
    },

    gotoAssignment(title, path) {
      if (title != "Flipped" && title != "Exit" && title != "Participation")
        router.push({
          name: "ViewAssignment",
          params: { title: title, path: path }
        });
    }
  }
};
</script>
<style scoped>
.assignlist {
  position: sticky;
  top: 0;
  width: 300px;
  height: 70px;
  padding: 10px;
  margin-right: 20px;
  z-index: 1000;
}
</style>
