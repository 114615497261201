<template>
  <v-row>
    <v-col cols="8">
      <v-card flat>
        <v-card-text><EmployeesList /> </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center;"
        @click="goToPage('Recruitment')"
      >
        <v-card-text>
          <img src="@/assets/rec.png" height="80px" />

          <h6 style="padding-top:20px">
            Recruitment
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('Appraisal')"
      >
        <v-card-text>
          <img src="@/assets/leaderboard.png" height="80px" />

          <h6 style="padding-top:20px">
            Appraisal
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('ImportEmployees')"
      >
        <v-card-text>
          <img src="@/assets/employees.png" height="80px" />

          <h6 style="padding-top:20px">
            Import <span style="color:#3096b2;">Employees</span>
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('HRSetting')"
      >
        <v-card-text>
          <img src="@/assets/reports.png" height="80px" />

          <h6 style="padding-top:20px">
            HRM <span style="color:#3096b2;">Setting</span>
          </h6>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import firebase from "@/Firebase";
import router from "@/router";
import EmployeesList from "@/components/hr/employees/EmployeesList";

export default {
  name: "ManageSubjects",
  components: { EmployeesList },

  data() {
    return {
      errors: [],
      subjects: [],

      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: ""
    };
  },
  created() {},
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
