<template>
  <v-row style="margin-bottom: 20px;" max-width="100%">
    <v-col cols="12">
      <v-card class="mx-auto" dark @click="goToSubject()">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="mb-5" style="font-size:22px">{{
              subjectDate.title
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.thisSchool }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ this.thisGrade }}</v-list-item-subtitle>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar tile size="150" style="margin-top: -50px;">
            <img
              :src="this.subjectIcon.icon"
              width="100px"
              v-if="this.subjectIcon.icon"
            />
            <img src="@/assets/subjects/english.png" width="100px" v-else />
          </v-list-item-avatar>
        </v-list-item>
      </v-card>

      <v-card style="margin-top:10px" class="mx-auto" dark>
        <v-card-actions>
          <v-col style="font-size:small" cols="4">
            <v-switch color="green" @change="showSubject()" v-model="show" />
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            v-if="this.from == 'admin'"
            style="margin-left:20px"
            small
            color="info"
            @click="editSubject()"
          >
            Edit Subject</v-btn
          >
          <v-btn
            v-if="this.from == 'admin'"
            style="margin-left:20px"
            small
            color="red"
            @click="deleteboard()"
          >
            <v-icon>mdi-trash-can-outline</v-icon>

            Delete Subject</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "Subject-page",
  props: ["path", "from", "index", "classList", "master", "mirror"],
  data() {
    return {
      records: [],
      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: "",
      show: false,
      lessons: 0
    };
  },
  created() {
    if (this.path != null) {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;

          let grade = snapshot.ref.path.split("/")[5];
          let school = snapshot.ref.path.split("/")[3];
          this.subjectid = snapshot.id;
          this.subjectDate = snapshot.data();
          this.thisGrade = grade;
          this.thisSchool = school;
          this.gradePath = snapshot.ref.parent.parent.path;
          this.thisMirror = snapshot.data().mirror;
          this.show = !snapshot.data().hide;
        });
    }
  },
  methods: {
    deleteboard() {
      this.$confirm({
        message:
          "Are you sure You want to Delete " + this.subjectDate.title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.path + "/subjects/")
              .doc(this.subjectid)
              .delete()
              .then(() => {
                this.$bvToast.toast("Subject Deleted", {
                  title: "Subject Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    showSubject() {
      if (this.show) {
        firebase
          .firestore()
          .collection(this.$route.params.path + "/subjects/")
          .doc(this.subjectid)
          .update({ hide: false });
      } else {
        firebase
          .firestore()
          .collection(this.$route.params.path + "/subjects/")
          .doc(this.subjectid)
          .update({ hide: true });
      }
    },

    editSubject() {
      router.push({
        name: "EditSubject",
        params: {
          title: this.subjectDate.title,
          path: this.path
        }
      });
    },

    goToSubject() {
      if (this.path != null) {
        firebase
          .firestore()
          .doc(this.path)
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;

            let grade = snapshot.ref.path.split("/")[5];
            let school = snapshot.ref.path.split("/")[3];
            this.subjectid = snapshot.id;
            this.subjectDate = snapshot.data();
            this.thisGrade = grade;
            this.thisSchool = school;
            this.gradePath = snapshot.ref.parent.parent.path;
            this.thisMirror = snapshot.data().mirror;

            //! Master Teacher
            // if (this.from == "admin") this.$store.commit("master", "true");
            // else

            localStorage.setItem("classList", JSON.stringify(this.classlist));

            this.$store.commit("master", true);
            this.$store.commit("mirror", snapshot.data().mirror);
            this.$store.commit("subjectTitle", snapshot.data().title);
            this.$store.commit("thisGrade", this.thisGrade);
            this.$store.commit("gradePath", this.gradePath);
            this.$store.commit("thisBranch", this.thisSchool);
            this.$store.commit("originalsubject", this.path);

            this.$store.commit("subject", this.path);

            if (this.thisMirror == null || this.thisMirror == "") {
              this.$store.commit("subject", this.path);

              router.push({
                name: "SubjectDash"
              });
            } else {
              this.$store.commit("subject", this.subjectDate.mirror);

              router.push({
                name: "SubjectDash"
              });
            }
          });
      }
    }
  },
  computed: {
    subjectIcon() {
      return {
        ...this.subjectDate,
        icon:
          this.subjectDate.icon &&
          require("@/assets/subjects/" + this.subjectDate.icon)
      };
    }
  }
};
</script>
