<template>
  <v-row>
    <!-- Side Exam Panel -->

    <!-- Side Exam Panel -->
    <v-col cols="12">
      <v-container>
        <v-card flat outlined style="margin-bottom:10px;"
          >
          <!-- <v-card-title>
            {{ this.$route.params.sname }}
          </v-card-title> -->
          <v-row>
          <v-col cols="6">
            <v-card-title>
            {{ this.$route.params.sname }}
          </v-card-title>
          </v-col>
          <!-- <v-col cols="6">
            <p>select breakdown</p>
            <b-select
                      label="breakdown"
                      v-model.trim="breakdown"
                      :options="breakdowns"
                      @change="onBreakdownChange(breakdown)"
                    >
                    </b-select>
                  
          </v-col> -->
          </v-row>


        </v-card>

        <v-card
          flat
          height="100vh"
          v-show="$store.state.weightcount != weights.length"
          style=" position: relative; "
          v-if="this.loading == true"
        >
          <h6
            style="position: absolute; top: 20%; left: 40%; margin: -25px 0 0 -25px; text-align:center;"
          >
            <img src="@/assets/bgif.gif" width="400px" />
            <br />
            Please wait while your Progress Report is generated
          </h6>
        </v-card>
        <div v-show="$store.state.weightcount != weights.length">
          <v-card v-if="this.loading == false">
            <v-card-text>
              <Total
                v-if="$store.state.gradebook.length == weights.length"
                :current="setting.current"
              />
              <v-col cols="6">
            <p>select breakdown</p>
            <b-select
                      label="breakdown"
                      v-model.trim="breakdown"
                      :options="breakdowns"
                      @change="onBreakdownChange(breakdown)"
                    >
                    </b-select>
                  
          </v-col>
              <div style="margin-top:20px">
                <div v-for="(weight, i) in weights" :key="weight.key">
                  <Exams
                    v-if="weight.type == 'Exams'"
                    :title="weight.category"
                    :weight="weight.weight"
                    :color="colors[i]"
                    :fadecolor="colorsfade[i]"
                    :current="setting.current"
                    :student="$route.params.path"
                    :subject="$route.params.subject"
                    :key="breakdown"
                  />

                  <Attendance
                    v-else-if="weight.type == 'Attendance'"
                    :title="weight.category"
                    :weight="weight.weight"
                    :color="colors[i]"
                    :fadecolor="colorsfade[i]"
                    :student="$route.params.path"
                    :subject="$route.params.subject"
                    :key="breakdown"
                  />

                  <Participation
                    v-else-if="weight.type == 'Participation'"
                    :title="weight.category"
                    :weight="weight.weight"
                    :color="colors[i]"
                    :fadecolor="colorsfade[i]"
                    :student="$route.params.path"
                    :subject="$route.params.subject"
                    :key="breakdown"
                  />
                  <Preteaching
                    v-else-if="weight.type == 'Preteaching'"
                    :title="weight.category"
                    :weight="weight.weight"
                    :color="colors[i]"
                    :fadecolor="colorsfade[i]"
                    :student="$route.params.path"
                    :subject="$route.params.subject"
                    :key="breakdown"
                  />
                  <Assignment
                    v-else
                    :title="weight.category"
                    :weight="weight.weight"
                    :color="colors[i]"
                    :fadecolor="colorsfade[i]"
                    :current="setting.current"
                    :student="$route.params.path"
                    :subject="$route.params.subject"
                    :key="breakdown"
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-sheet v-else color="darken-2" class="pa-3" height="800px">
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-sheet>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import Attendance from "./Attendance";
import Exams from "./Exams";
import Participation from "./Participation";
import Total from "./Total";
import Preteaching from "./Preteaching.vue";
import Assignment from "./Assignment.vue";

export default {
  components: { Attendance, Exams, Participation, Total, Preteaching, Assignment },
  data() {
    return {
      submission: this.$store.state.submissionpath,
      sub: [],
      questions: [],
      assignments: [],
      finished: [],

      participation: [],
      weeks: [],
      weights: [],
      loading: false,
      subject: this.$store.state.subject,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,
      studentpath: this.$route.params.path,
      subjecttitle: this.$store.state.subjecttitle,

      school: this.$store.state.school,
      branch: this.$store.state.thisBranch,
      breakdowns:[],
      breakdown: this.$store.state.branchData.breakdown,
      setting: {},

      colors: [
        "#f22e2e",
        "#089d13",
        "#0082ca",
        "#ff8400",
        "#834588",
        "#363636",
        "#004e72"
      ],
      colorsfade: [
        "#ffa5b5",
        "#abdb92",
        "#acc9e8",
        "#f2d1a0",
        "#d9c5e6",
        "#bcc7c4",
        "#96d5ea"
      ],

      //Score Chart
      doseries: [0, 0, 0],
      dochartOptions: {
        colors: ["#059612", "#e1261c", "#ff8400"],
        chart: {
          type: "donut"
        },
        labels: ["Right", "Wrong", "Missed"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    };
  },
  created() {
    this.weights = [];
     this.$store.commit("cleargradebook");
    this.$store.commit("weightcount", 0);
    this.loading = true;
    console.log(this.$store.state)
    console.log("schools/" + this.school + "/branches/" + this.$store.state.thisBranch+"/breakdown")
    firebase
      .firestore()
      .collection("schools/" + this.school + "/branches/" + this.$store.state.thisBranch+"/breakdown")
      .get()
      .then((querySnapshot) => {
        console.log("breakdown request API",querySnapshot)
          querySnapshot.forEach((doc) => {
            console.log("breakdown: ",doc.data().title)
            this.breakdowns.push({
              text: doc.data().title,
          value: doc.data().title,
            })

          });
        });

    //! Get Subject Setting
    firebase
      .firestore()
      .doc("schools/" + this.school + "/branches/" + this.$store.state.thisBranch)
      .get()
      .then(doc => {
        console.log("branch data =>",doc.data())
        this.$store.commit("branchData", doc.data())
        if (doc.exists) {
          this.setting = doc.data();
        } else {
           alert("No such document!");
        }
      });

    //! Get Subject Weights
    firebase
      .firestore()
      .collection(this.subject + "/weights")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.weights.push({
            key: doc.id,
            category: doc.data()?.category,
            weight: doc.data()?.weight,
            type: doc.data()?.type
          });

          // this.$store.commit("gradebook", doc.data().category:{
          //   grade: 0,
          //   weight: doc.data().weight
          // });
        });

        this.loading = false;
      });
  },
  methods :{
    onBreakdownChange(value){
      this.breakdown = value
    }
  }
};
</script>
