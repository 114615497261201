<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ this.$route.params.empname }}</v-card-title>
        <v-card-text>
          <v-card dark>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="from"
                        label="From"
                        persistent-hint
                        prepend-icon="mdi-calendar-arrow-left"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="from"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="to"
                        label="To"
                        persistent-hint
                        prepend-icon="mdi-calendar-arrow-right"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="to"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2"><v-btn @click="getDates()">Get</v-btn></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br />
          <v-row
            ><v-col cols="6">
              <v-card>
                <v-card-title>Timesheet</v-card-title>
                <v-card-subtitle
                  >Between {{ this.from }} - {{ this.to }}</v-card-subtitle
                >
                <v-card-text>
                  <v-card dark>
                    <v-row style="font-size:large; background-color:#1b2e47">
                      <v-col cols="4">
                        Day
                      </v-col>
                      <v-col cols="3">
                        In
                      </v-col>
                      <v-col cols="3">
                        Out
                      </v-col>
                    </v-row>
                  </v-card>

                  <p v-for="d in days" :key="d">
                    <DayRecord :day="d" :emp="$route.params.path" />
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>Salary</v-card-title>
                <v-card-text>
                  <p>Salary: {{ this.emp.salary }}</p>
                  <p>Insurance Salary: {{ this.emp.inssalary }}</p>
                  <v-divider />
                  <p>Total Days: {{ this.days.length }}</p>
                  <p>Total Attendance: {{ this.timesheet.length }}</p>
                  <p>
                    Total Absence:
                    {{ this.days.length - this.timesheet.length }}
                  </p>
                  <p>
                    Late Less Than 15 min:
                    {{ this.late15.length }}
                  </p>
                  <p>
                    Late More Than 30 min:
                    {{ this.late30.length }}
                  </p>
                  <p>
                    Leave early 15 min:
                    {{ this.leave15.length }}
                  </p>
                  <p>
                    Leave early 30 min:
                    {{ this.leave30.length }}
                  </p>

                  <v-divider />
                  <p style="color:red; font-size:12px;">
                    Insurance {{ this.ins }} L.E
                  </p>
                  <p style="font-size:x-large">
                    Total Salary : {{ this.totalsalary.toFixed(2) }} L.E
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import moment from "moment";
import DayRecord from "./DayRecord";
export default {
  components: { DayRecord },
  data() {
    return {
      timesheet: [],
      days: [],
      emp: {},
      menu1: false,
      menu2: false,
      from: "",
      to: "",
      late15: [],
      late30: [],
      leave15: [],
      leave30: [],
      totalsalary: 0,
      daysalary: 0,
      ins: 0
    };
  },
  created() {},
  methods: {
    getDates() {
      this.days = [];
      firebase
        .firestore()
        .collection(this.$route.params.path + "/timesheet")
        .onSnapshot(querySnapshot => {
          this.timesheet = [];
          this.late15 = [];
          this.late30 = [];
          this.leave15 = [];
          this.leave30 = [];
          querySnapshot.forEach(doc => {
            this.timesheet.push({
              date: doc.data().date,
              in: doc.data().in,
              inrule: doc.data().inrule,
              out: doc.data().out,
              outrule: doc.data().outrule
            });

            if (doc.data().inrule == "Late 15") {
              this.late15.push({
                date: doc.data().date,
                in: doc.data().in,
                inrule: doc.data().inrule,
                out: doc.data().out,
                outrule: doc.data().outrule
              });
            }
            if (doc.data().inrule == "Late 30") {
              this.late30.push({
                date: doc.data().date,
                in: doc.data().in,
                inrule: doc.data().inrule,
                out: doc.data().out,
                outrule: doc.data().outrule
              });
            }
            if (doc.data().outrule == "Leave early 15") {
              this.leave15.push({
                date: doc.data().date,
                in: doc.data().in,
                inrule: doc.data().inrule,
                out: doc.data().out,
                outrule: doc.data().outrule
              });
            }
            if (doc.data().outrule == "Leave early 30") {
              this.leave30.push({
                date: doc.data().date,
                in: doc.data().in,
                inrule: doc.data().inrule,
                out: doc.data().out,
                outrule: doc.data().outrule
              });
            }
          });
        });

      var diff = (new Date(this.to) - new Date(this.from)) / 1000;
      diff /= 60 * 60 * 24;

      //   alert(diff);

      for (var i = 0; i < diff; i++) {
        var d = new Date();
        d.setDate(new Date(this.from).getDate() + i);
        this.days.push(moment(String(d)).format("DD/MM/YYYY"));
      }

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.emp = doc.data();

            this.daysalary = this.emp.salary / 30;

            this.ins = this.emp.inssalary * 0.02;

            this.totalsalary =
              this.emp.salary -
              this.daysalary * (this.days.length - this.timesheet.length);

            this.totalsalary = this.totalsalary - this.ins;
          }
        });
    }
  }
};
</script>
