<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <button class="btn btn-warning btn-sm" v-bind="attrs" v-on="on">
        <v-icon>mdi-timeline-alert-outline</v-icon>&nbsp;
        <small>Timeline</small>
      </button>
    </template>
    <v-card>
      <v-card-title>
        <v-divider />
        <v-icon @click="dialog = false">mdi-close</v-icon></v-card-title
      >

      <v-card-text>
        <div>
          <v-alert border="bottom" color="blue darken-1" dark>
            <h4>{{ this.student }}</h4>
            Admission Timeline
          </v-alert>

          <b-col cols="12">
            <AddTimeline
              :path="this.path"
              :student="this.student"
            ></AddTimeline>
            <br />
            <h3>Events List</h3>

            <vue-good-table
              :columns="fields"
              :rows="timeline"
              :search-options="{ enabled: true, skipDiacritics: true }"
              :pagination-options="{ enabled: true, mode: 'records' }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteboard(props.row.key, props.row.grade)"
                  >
                    <b-icon-trash></b-icon-trash>&nbsp; Delete
                  </button>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </div>
      </v-card-text>
    </v-card></v-dialog
  >
</template>

<script>
import firebase from "@/Firebase";
// import router from "@/router";
import AddTimeline from "@/components/admission/timeline/AddTimeline";

export default {
  name: "TimelineList",
  components: {
    AddTimeline
  },
  params: ["path"],
  props: ["path", "student"],

  data() {
    return {
      dialog: false,
      fields: [
        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },

        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "comment",
          label: "Comment",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "Action", class: "text-center" }
      ],
      timeline: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.path + "/timeline")
        .orderBy("date")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.timeline = [];
      querySnapshot.forEach(doc => {
        this.timeline.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          date: doc.data().date,
          type: doc.data().type,
          comment: doc.data().comment
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/timeline")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Event Deleted", {
                  title: "Event Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
