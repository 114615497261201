<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title
          >Exam Questions Bank

          <v-divider /><span style="font-size:10px; margin-right:10px;"
            >Total Questions</span
          >
          {{ this.count }}
        </v-card-title>
        <v-card-subtitle>{{ subjectTitle + " " + thisGrade }}</v-card-subtitle>
        <v-card-text>
          <AddQuesion :path="this.path" v-if="master == true"></AddQuesion>

          <br />

          <vue-good-table
            :columns="fields"
            :rows="boards"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->
              <span v-if="props.column.field == 'actions'">
                <div v-if="master == true">
                  <v-btn
                    depressed
                    x-small
                    color="error"
                    @click="deleteboard(props.row.path, props.row.title)"
                  >
                    <v-icon size="18">mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    x-small
                    color="info"
                    style="margin-left:5px"
                    @click="
                      Edit(props.row.path, props.row.qtype, props.row.qtype)
                    "
                  >
                    <v-icon size="18">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <span style="margin-left:5px">
                    <ViewQuestion :path="props.row.path" />
                  </span>
                </div>
              </span>

              <span v-else-if="props.column.field == 'options'">
                <button
                  class="btn btn-success btn-sm"
                  @click="options(props.row.path, props.row.title)"
                  v-if="props.row.qtype == 'mc' || props.row.qtype == null"
                >
                  <b-icon icon="list-check" scale="1"></b-icon>
                </button>
              </span>

              <span v-else-if="props.column.field == 'title'">
                {{ removeHTML(props.row.title) }}
                <p v-if="props.row.exam == 'Yes'">
                  <v-chip small color="red" dark> Exam Question</v-chip>
                </p>
              </span>
              <span
                v-else-if="props.column.field == 'report'"
                style="
              text-align:center"
              >
                <p v-if="props.row.report == true">
                  <v-icon color="red" size="30"> mdi-flag</v-icon>
                </p>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddQuesion from "@/components/lms/subjects/exambank/AddQuesionInBank";
// import QuestionsHub from "@/components/hub/QuestionsHub";
import EditQuestion from "@/components/lms/subjects/exambank/Question.vue";
// import TransferQuestion from "@/components/dna/TransferQuestion.vue";
import ViewQuestion from "@/components/dna/ViewQuestion.vue";

export default {
  name: "QuestionsList",
  components: {
    AddQuesion,
    // QuestionsHub,
    // TransferQuestion,
    ViewQuestion
  },
  data() {
    return {
      selected: "",
      count: 0,
      pages: 1,
      current: 1,
      lastVisible: {},
      firstVisible: {},
      master: this.$store.state.master,
      subject: this.$store.state.subject,
      subjectTitle: this.$store.state.subjectTitle,
      thisGrade: this.$store.state.thisGrade,
      grade: this.$route.params.path,
      fields: [
        {
          field: "report",
          label: "Report",
          sortable: true,
          class: "text-left",
          width: "5%"
        },

        {
          field: "weight",
          label: "Weight",
          sortable: true,
          class: "text-left",
          width: "5%"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left",
          width: "35%"
        },

        {
          field: "options",
          label: "Options",
          sortable: true,
          class: "text-center",
          width: "10%"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "20%"
        }
      ],
      boards: [],
      questions: [],
      questiontype: [{ mc: "Multiple Choice" }],
      errors: [],

      gradetitle: this.$route.params.title,
      path: this.$store.state.subject
    };
  },
  created() {
    this.loaddatd();
    this.loadweeks();
  },
  methods: {
    loaddatd() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/examquestions/")

        .onSnapshot(querySnapshot => {
          this.boards = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            this.boards.push({
              key: doc.id,
              path: doc.ref.path,
              week: data.topic,
              title: data.title,
              order: data.order,
              type: data.type,
              exam: data.exam,
              weight: data.weight,
              report: data.report,

              qtype: data.qtype
            });
          });

          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.firstVisible = querySnapshot.docs[0];

          firebase
            .firestore()
            .collection(this.$store.state.subject + "/examquestions/")
            .get()
            .then(snapshot => {
              this.count = snapshot.size;
              this.pages = Math.ceil(this.count / 10);
            });
        });
    },
    clearsearch() {
      this.loaddatd();
      this.selected = "";
    },

    next() {
      try {
        if (this.current < this.pages) {
          firebase
            .firestore()
            .collection(this.$store.state.subject + "/examquestions/")
            .orderBy("title")
            .startAfter(this.lastVisible)
            .limit(10)
            .get()
            .then(querySnapshot => {
              this.boards = [];
              querySnapshot.forEach(doc => {
                const data = doc.data();
                this.boards.push({
                  key: doc.id,
                  path: doc.ref.path,
                  week: data.topic,
                  title: data.title,
                  order: data.order,
                  type: data.type,
                  exam: data.exam,
                  report: data.report,
                  qtype: data.qtype
                });
              });
              this.lastVisible =
                querySnapshot.docs[querySnapshot.docs.length - 1];
              this.firstVisible = querySnapshot.docs[0];
              this.current++;
            });
        }
      } catch (error) {
        this.loaddatd();
      }
    },
    back() {
      try {
        if (this.current > 1) {
          firebase
            .firestore()
            .collection(this.$store.state.subject + "/examquestions/")
            .orderBy("title")
            .endBefore(this.firstVisible)
            .limitToLast(10)
            .get()
            .then(querySnapshot => {
              this.boards = [];
              querySnapshot.forEach(doc => {
                const data = doc.data();
                this.boards.push({
                  key: doc.id,
                  path: doc.ref.path,
                  week: data.topic,
                  title: data.title,
                  order: data.order,
                  type: data.type,
                  exam: data.exam,
                  qtype: data.qtype
                });
              });
              this.lastVisible =
                querySnapshot.docs[querySnapshot.docs.length - 1];
              this.firstVisible = querySnapshot.docs[0];
              this.current--;
            });
        }
      } catch (error) {
        this.loaddatd();
      }
    },
    Edit(path, type, qtype) {
      this.$modal.show(
        EditQuestion,
        {
          path: path,
          order: this.order,
          source: "edit",
          qtype: qtype
        },
        { width: "70%", height: "90vh" }
      );
    },

    deleteboard(question, title) {
      this.$confirm({
        message:
          "Are you sure You want to Delete " + title.substr(0, 50) + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(question)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  title,
                  this.path,
                  "Question"
                );

                this.$bvToast.toast("Question Deleted", {
                  title: "Question Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    details(id, title, grade) {
      router.push({
        name: "WeeksList",
        params: { id: id, title: title, grade: grade }
      });
    },
    options(path, title) {
      let routeData = this.$router.resolve({
        path: "/options",
        query: { id: path, title: title }
      });
      window.open(routeData.href, "_blank");
      // router.push({ name: "OptionsList", params: { id: path, title: title } });
    },
    removeHTML(text) {
      let regex = /(<([^>]+)>)/gi;

      return text.replace(regex, "");
    }
  }
};
</script>
<style scoped>
.foo >>> img {
  width: 200px;
}
</style>
