<template>
  <v-card style="margin-top:20px; background-color:#eee;" flat>
    <div
      v-if="this.assignments"
      style="margin-top:20px; background-color:green; padding-left:10px;"
    >
      {{ this.weight }}
    </div>
    <div
      v-else-if="this.assignment"
      style="margin-top:20px; background-color:red; padding-left:10px;"
    >
      {{ this.weight }}
    </div>

    <v-card-subtitle>
      <v-row>
        <v-col cols="6">
          Flipped Classroom Questions
        </v-col>
        <v-col cols="6">
          <v-chip v-if="this.assignment" color="green">
            {{ this.assignment }}/3</v-chip
          >
        </v-col>
      </v-row>
    </v-card-subtitle>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "AssignLesson",
  props: ["weekid", "type", "title", "grade", "opendate", "weight", "student"],
  data() {
    return {
      assignment: 0,
      lessons: [],
      week: ""
    };
  },
  created() {
    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "StudentsData/" +
          this.student +
          "/assignments"
      )
      .doc(this.weekid + "flipped")
      .get()
      .then(querySnapshot => {
        this.assignment = 0;
        this.assignment = querySnapshot.data().right;
      });
  },
  methods: {
    onWeekSelected() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.$store.state.id +
            "/assignments"
        )
        .where("subject", "==", this.$store.state.subject)
        .where("week", "==", this.week)
        .onSnapshot(querySnapshot => {
          this.assignments = [];

          querySnapshot.forEach(doc => {
            this.assignments.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              weight: doc.data().weight,
              type: doc.data().type,
              week: doc.data().week,
              score: doc.data().score,
              date: doc.data().date,
              right: doc.data().right,
              wrong: doc.data().wrong,
              missed: doc.data().missed,
              questionsCount: doc.data().questionsCount,
              weektitle: doc.data().weektitle
            });
          });
        });
    },

    onClearSearch() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.$store.state.id +
            "/assignments"
        )
        .where("subject", "==", this.$store.state.subject)
        .onSnapshot(querySnapshot => {
          this.assignments = [];

          querySnapshot.forEach(doc => {
            this.assignments.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              weight: doc.data().weight,
              type: doc.data().type,
              week: doc.data().week,
              score: doc.data().score,
              date: doc.data().date,
              right: doc.data().right,
              wrong: doc.data().wrong,
              missed: doc.data().missed,
              questionsCount: doc.data().questionsCount,
              weektitle: doc.data().weektitle
            });
          });
        });
    }
  }
};
</script>
