<template>
  <v-row>
    <v-col cols="8">
      <v-card>
        <v-card-text><StudentsList /> </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card
        flat
        style="cursor: pointer; text-align:center;"
        @click="goToPage('Attendance')"
      >
        <v-card-text>
          <img src="@/assets/timetable.png" height="80px" />

          <h6 style="padding-top:20px">
            <span style="color:#3096b2;">Students</span> Attendance
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('ParentsList')"
      >
        <v-card-text>
          <img src="@/assets/parents.png" height="80px" />

          <h6 style="padding-top:20px">
            Students <span style="color:#3096b2;">Parents</span>
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('ImportStudents')"
      >
        <v-card-text>
          <img src="@/assets/import.png" height="80px" />

          <h6 style="padding-top:20px">
            Import <span style="color:#3096b2;">Students</span>
          </h6>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import firebase from "@/Firebase";
import router from "@/router";
import StudentsList from "@/components/sis/students/StudentsList";

export default {
  name: "ManageSubjects",
  components: { StudentsList },

  data() {
    return {
      errors: [],
      subjects: [],

      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: ""
    };
  },
  created() {},
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
