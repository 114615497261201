<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <button class="btn btn-success btn-sm" v-bind="attrs" v-on="on">
        <v-icon>mdi-eye</v-icon>
      </button>
    </template>
    <v-card>
      <v-card-title>
        <v-divider />
        <v-icon @click="dialog = false">mdi-close</v-icon></v-card-title
      >

      <v-card-text>
        <v-card
          ><v-card-text>
            <v-btn color="success" small @click="print">
              <v-icon>mdi-printer</v-icon> Print Application</v-btn
            >

            <v-btn
              @click="printAccept"
              color="info"
              small
              style="margin-left:20px"
              ><v-icon @click="print">mdi-check-all</v-icon>Print Letter of
              Acceptance</v-btn
            >
          </v-card-text>
        </v-card>
        <br />
        <v-card id="printMe">
          <v-card-title>
            <v-spacer />
            <img :src="this.$store.state.logo" width="100px" />
          </v-card-title>

          <v-card-text>
            <br />
            <h4>Basic Information</h4>
            <hr
              style="width:25%;text-align:left;margin-left:0; height:4px;border-width:2px;"
            />
            <br />
            <h4>
              Student ID:
              {{
                student.nationalID == null
                  ? ""
                  : student.nationalID.substr(7, 13)
              }}
            </h4>
            <br />

            <b>Student Name:</b>
            {{
              student.firstname +
                " " +
                student.middlename +
                " " +
                student.lastname
            }}
            <br />
            <b>School:</b> {{ student.branch }}
            <br />
            <b>Grade:</b> {{ student.grade }}
            <br />
            <br />
            <b>Date Of Birth:</b> {{ student.date_of_birth }}
            <br />
            <b>Previous School / Nursery:</b> {{ student.preschool }}
            <br />
            <b>Religion:</b> {{ student.religion }}
            <br />
            <b>Second Language:</b> {{ student.secondlanguage }}
            <br />
            <b>Student Natioanl ID:</b> {{ student.nationalID }}
            <br />
            <b>Gender:</b> {{ student.gender }}
            <br />
            <b>Citizenship:</b> {{ student.citizenship }}
            <br />
            <br />
            <b>Address:</b> {{ student.address }}
            <br />
            <b>Home Phone:</b> {{ student.phone }}
            <br />
            <b>Mobile:</b> {{ student.mobile }}
            <hr
              style="width:100%;text-align:left;margin-left:0; height:4px;border-width:4px;"
            />
            <br />
            <h4>Father Information</h4>
            <hr
              style="width:25%;text-align:left;margin-left:0; height:4px;border-width:2px;"
            />
            <br />

            <b>Father Academic Degree:</b>
            {{ student.father_degree }}
            <br />
            <b>Father Occupation:</b> {{ student.father_occupation }}
            <br />
            <b>Father Company Business:</b> {{ student.father_company }}
            <br />
            <br />
            <b>Father Email:</b> {{ student.parent }}
            <br />
            <b>Father Mobile:</b> {{ student.father_mobile }}

            <br />
            <b>Father National ID::</b> {{ student.father_nationalID }}

            <hr
              style="width:100%;text-align:left;margin-left:0; height:4px;border-width:4px;"
            />
            <br />
            <h4>Mother Information</h4>
            <hr
              style="width:25%;text-align:left;margin-left:0; height:4px;border-width:2px;"
            />
            <br />

            <b>Mother Name:</b>
            {{ student.mother_name }}
            <br />
            <b>Mother Academic Degree:</b>
            {{ student.mother_degree }}
            <br />
            <b>Mother Occupation:</b> {{ student.mother_occupation }}
            <br />
            <b>Mother Company Business:</b> {{ student.mother_company }}
            <br />
            <br />
            <b>Mother Email:</b> {{ student.mother_email }}
            <br />
            <b>Mother Mobile:</b> {{ student.mother_mobile }}
            <br />
            <b>Mother National ID::</b> {{ student.mother_nationalID }}

            <hr
              style="width:100%;text-align:left;margin-left:0; height:4px;border-width:4px;"
            />

            <div id="printAccept" v-show="false">
              <div
                style="padding-left:50px; padding-top:200px; max-width:70%; font-size:18px;"
              >
                <img :src="this.$store.state.logo" width="100px" />
                <br />
                <br />
                <span style="text-align:right;"
                  ><b>Date:</b> {{ this.today }}</span
                >

                <br />
                <br />
                <h2 style="text-align:center;">
                  Dr. Nermien Ismail Schools
                </h2>

                <h6
                  style="text-align:center;  font-family: 'Great Vibes', cursive;"
                >
                  Inspiring, Empowering, Educating
                </h6>
                <br />
                <div style="font-size:18px; text-align:center;">From the</div>
                <div style="font-size:18px; text-align:center;">
                  Office of Admissions
                </div>

                <h3
                  style="text-align:center;  font-family: 'Great Vibes', cursive;"
                >
                  Letter of Acceptance
                </h3>
                <br />
                <h5>
                  To the Parent/Guardian
                  <b
                    >{{
                      student.firstname +
                        " " +
                        student.middlename +
                        " " +
                        student.lastname
                    }},</b
                  >
                </h5>
                <br />
                <h6>
                  Congratulations! It is with great pleasure that I inform you
                  that the student candidate,
                </h6>
                <b>{{
                  student.firstname +
                    " " +
                    student.middlename +
                    " " +
                    student.lastname
                }}</b
                >, has been accepted. He/she is welcome to join us, this August:
                effective the 2021-2022 academic year, in {{ student.grade }} at
                the {{ student.branch }}.<br /><br />We committed to offering
                each student an equal and equitable opportunity for a world
                class education. We aim to provide holistic instruction for the
                child’s mind, body and spirit. We look forward to celebrating
                {{ student.firstname }}’s success!<br /><br />
                It is our strong belief that a child’s academic success depends
                on a strong working relationship between the school and home.
                Therefore, we ask for your support in building successful
                learning opportunities for {{ student.firstname }}.
                <br /><br /><br />
                <h6>Welcome!</h6>

                <h6>Sincerely yours,</h6>
                <br />
                <br />
                <br />
                <h6>Admission Department</h6>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
// import * as moment from "moment/moment";

export default {
  name: "ViewApplication",
  params: ["path", "type"],
  props: ["path", "type"],

  data() {
    return {
      dialog: false,
      today: new Date().toISOString().substr(0, 10),
      fields: [
        {
          field: "status",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "timeline",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "admission_date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "admission_time",
          label: "Time",
          sortable: true,
          class: "text-left"
        },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        { field: "grade", label: "Grade", sortable: true, class: "text-left" },
        {
          field: "firstname",
          label: "First name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "middlename",
          label: "Middle name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Last name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "Actions",
          sortable: true,
          class: "text-left"
        }
      ],
      admission: [],
      brothersf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "status",
          label: "Status",
          sortable: true,
          class: "text-left"
        }
      ],
      parentf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "job",
          label: "Job",
          sortable: true,
          class: "text-left"
        },

        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        }
      ],

      panel: 0,
      age: "",
      time: null,
      menu2: false,
      modal2: false,
      brothers: {},
      father: {},
      mother: {},
      fees: {},
      student: {},
      grades: {},
      schools: {},
      gclasses: {},
      selectedGrade: "",
      studentRef: firebase.firestore().doc(this.path),
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),
      statusRef: firebase
        .firestore()
        .collection(
          "schools/" + this.$store.state.school + "/admission_status/"
        ),

      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      if (this.$route.params.type == "copy") {
        this.student.school = this.$store.state.school;
        this.student.type = "student";

        firebase
          .firestore()
          .collection("schools/" + this.$store.state.school + "/admission/")
          .add(this.student)
          .then(() => {
            this.student.firstname = "";
            this.student.middlename = "";
            this.student.lastname = "";
            this.student.grade = "";

            router.back();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      } else {
        if (this.picture != null) {
          this.student.image = this.picture;
        }

        firebase
          .firestore()
          .doc(this.$route.params.path)
          .set(this.student)
          .then(() => {
            router.back();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSchoolChange() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    price() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade
        )
        .get()
        .then(doc => {
          if (doc.exists) {
            this.fees = doc.data();
          } else {
            alert("No such document!");
          }
        });
    },

    onGradeChange() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/grades/" +
            this.student.grade +
            "/classes"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.gclasses = [];
          querySnapshot.forEach(doc => {
            this.gclasses.push({
              text: doc.data().title,
              value: doc.data().title
            });
          });
        });
    },

    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/admission")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    print() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },
    printAccept() {
      // Pass the element id here
      this.$htmlToPaper("printAccept");
    },

    ogToEdit(id) {
      router.push({ name: "EditApplication", params: { path: id } });
    },
    Timeline(id, student) {
      router.push({
        name: "TimelineList",
        params: { path: id, student: student }
      });
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          this.$store.state.school +
            "/StudentsData/" +
            this.student.firstname +
            this.student.date_of_birth +
            this.student.lastname
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.picture = url;
          });
        }
      );
    }
  },
  created() {
    this.studentRef.get().then(doc => {
      if (doc.exists) {
        this.student = doc.data();

        // Get Brothers Data
        firebase
          .firestore()
          .collection("schools/" + this.$store.state.school + "/StudentsData/")
          .where("father_nationalID", "==", this.student.father_nationalID)
          .onSnapshot(querySnapshot => {
            this.brothers = [];
            querySnapshot.forEach(doc => {
              if (doc.data().firstname != this.student.firstname) {
                this.brothers.push({
                  firsname: doc.data().firstname,
                  lastname: doc.data().lastname,
                  branch: doc.data().branch,
                  grade: doc.data().grade,
                  class: doc.data().class,
                  join: doc.data().join,
                  status: doc.data().status
                });
              }
            });
          });
        // Get Parents Data
        firebase
          .firestore()
          .collection("schools/" + this.$store.state.school + "/employees/")
          .where("nationalID", "==", this.student.father_nationalID)
          .onSnapshot(querySnapshot => {
            this.father = [];
            querySnapshot.forEach(doc => {
              this.father.push({
                firsname: doc.data().firstname,
                lastname: doc.data().lastname,
                job: doc.data().job,
                join: doc.data().join
              });
            });
          });
        firebase
          .firestore()
          .collection("schools/" + this.$store.state.school + "/employees/")
          .where("nationalID", "==", this.student.mother_nationalID)
          .onSnapshot(querySnapshot => {
            this.mother = [];
            querySnapshot.forEach(doc => {
              this.mother.push({
                firsname: doc.data().firstname,
                lastname: doc.data().lastname,
                job: doc.data().job,
                join: doc.data().join
              });
            });
          });
      } else {
        alert("No such document!");
      }
    });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });
  },

  computed: {
    // calculateAge: function() {
    //   let sAge = moment(this.student.date_of_birth, "YYYY-MM-DD").format(
    //     "M/DD/YYYY"
    //   );
    //   var age = moment().diff(sAge, "years", "10/1/2021", true);
    //   return age.toFixed(1);
    // }
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
</style>
