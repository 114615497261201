<template>
  <div class="app-container" style="padding-top: 100px">
    <v-card>
      <v-card-title>Discounts</v-card-title>
      <v-card-text>
        <upload-excel-component
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        />
        <br />
        <v-progress-linear
          v-if="progress"
          color="light-blue"
          height="20"
          :value="progress"
          striped
        ></v-progress-linear>
        <br />
        Rows Count: {{ tableData.length }}
        <br />
        <!--  <b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
        <b-button
          variant="success"
          style="color: #ffffff"
          href="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/upload_student.xlsx?alt=media&token=6802026e-bfea-4228-8951-535a6db50b30"
          >Download Excel Template</b-button
        >
        <b-button @click="importData" style="margin-left: 20px"
          >Start Importing</b-button
        >

        <!-- <b-button variant="info" @click="deleteOld" style="margin-left:20px;"
          >Delete</b-button
        > -->

        <el-table
          :data="tableData"
          border
          highlight-current-row
          style="width: 100%; margin-top: 20px"
          empty-text="There Is No Data Yet"
        >
          <el-table-column
            v-for="item of tableHeader"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";

export default {
  name: "UploadExcel",
  components: { UploadExcelComponent },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      student: {},
      current: 0,
      progress: 0
    };
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    // deleteOld() {
    //   this.studentscount = 0;
    //   //! Get students
    //   firebase
    //     .firestore()
    //     .collection("/schools" + this.$store.state.school + "students")
    //     .get()
    //     .then(students => {
    //       students.docs.forEach(student => {
    //         this.studentscount++;

    //         //! Get payments
    //         firebase
    //           .firestore()
    //           .collection(student.ref.path + "/discounts")
    //           .get()
    //           .then(payments => {
    //             payments.docs.forEach(payment => {
    //               firebase
    //                 .firestore()
    //                 .doc(payment.ref.path)
    //                 .delete()
    //                 .then(() => {
    //                   console.log("deleted");
    //                 });
    //             });
    //           });
    //       });
    //     });
    // },

    importData: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        if (
          this.tableData[i]["id"] != null &&
          this.tableData[i]["discount"] != "0"
        ) {
          this.student = {};

          this.student.amount =
            this.tableData[i]["discount"] == null
              ? 0
              : this.tableData[i]["discount"];

          this.student.school = "bicc";
          this.student.year = "2021-2022";
          // this.student.type = "Tuition Payments";

          this.student.branch =
            this.tableData[i]["branch"] == null
              ? ""
              : this.tableData[i]["branch"];

          this.student.gradelevel =
            this.tableData[i]["grade"] == null
              ? ""
              : this.tableData[i]["grade"];

          this.student.class =
            this.tableData[i]["class"] == null
              ? ""
              : this.tableData[i]["class"];

          this.student.reason =
            this.tableData[i]["reason"] == null
              ? ""
              : this.tableData[i]["reason"];

          this.student.studentid =
            this.tableData[i]["id"] == null ? "" : this.tableData[i]["id"];

          firebase
            .firestore()
            .collection("schools/" + this.$store.state.school + "/StudentsData")
            .doc(this.tableData[i]["id"])
            .collection("discounts")
            .add(this.student)
            .then(() => {
              //Create User
              console.log("Payments Success Added");
              this.current++;
              this.progress = (this.tableData.length / this.current) * 100;
            })
            .catch(error => {
              alert("Error adding document: ", error);
            });
        }
      }
    },

    transfer() {
      //   firebase
      //     .firestore()
      //     .collection("activities")
      //     .onSnapshot(querySnapshot => {
      //       querySnapshot.forEach(doc => {
      //         firebase
      //           .firestore()
      //           .collection(
      //             "activities_hub/" +
      //               doc.data().system +
      //               "/grades/" +
      //               doc.data().grade +
      //               "/subjects/" +
      //               doc.data().subject +
      //               "/videos"
      //           )
      //           .add(doc.data())
      //           .then(() => {
      //             console.log("Done");
      //           })
      //           .catch(() => {});
      //       });
      //     });
    },
    change() {
      firebase
        .firestore()
        .collection("students")
        .where("source", "==", "Egyption National")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            firebase
              .firestore()
              .doc(doc.ref.path)
              .update({ source: "Egyptian National" })
              .then(() => {
                console.log(doc.ref.path);
              })
              .catch(() => {});
          });
        });
    }
  },
  mounted() {
    // this.deleteOld();
    //this.importData();
  }
};
</script>
