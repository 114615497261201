<template>
  <v-card hover style="margin-bottom:20px" flat outlined>
    <v-card-text>
      {{ this.order + 1 }}
      <img
        src="@/assets/resources.png"
        width="50px"
        style="margin-right:20px; margin-left:20px;"
      />
      {{ this.title }}

      <v-divider />

      <v-card flat outlined hover
        ><v-card-text>
          <v-btn
            :color="questions.length == 0 ? 'error' : 'success'"
            class="ma-1"
            depressed
            small
            @click="showDetails('questions')"
          >
            {{ questions.length }} Questions
          </v-btn>
          <v-btn
            :color="assignments.length == 0 ? 'error' : 'success'"
            class="ma-1"
            depressed
            small
            @click="showDetails('assignments')"
          >
            {{ assignments.length }} assignments
          </v-btn>

          <v-btn
            :color="videos.length == 0 ? 'error' : 'success'"
            class="ma-1"
            depressed
            small
            @click="showDetails('schoolvideos')"
          >
            {{ videos.length }} Cerebral 21 Videos
          </v-btn>
          <v-btn
            :color="internetvideos.length == 0 ? 'error' : 'success'"
            class="ma-1"
            depressed
            small
            @click="showDetails('internetvideos')"
          >
            {{ internetvideos.length }} Lesson Videos
          </v-btn>
          <v-btn
            :color="sheets.length == 0 ? 'error' : 'success'"
            class="ma-1"
            depressed
            small
            @click="showDetails('sheets')"
          >
            {{ sheets.length }} Lesson Sheets
          </v-btn>
          <!-- Assignments Details -->
          <v-card
            flat
            outlined
            v-if="show == 'assignments'"
            style="background-color:#ecf4fc"
          >
            <v-card-title>Assignments</v-card-title>
            <v-card-text>
              <v-card
                v-for="assign in assignments"
                :key="assign.key"
                flat
                outlined
                style="margin-bottom:5px;"
              >
                <v-card-subtitle>
                  <v-row style="margin-right:10px; margin-left:10px;">
                    {{ assign.title }} <v-divider />Grade
                    {{ assign.grade }}</v-row
                  >
                </v-card-subtitle>
                <v-card-text style="margin-top:-30px">
                  <span style="color:orange"> {{ assign.weight }}</span>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <!-- School Videos Details -->
          <v-card
            flat
            outlined
            v-if="show == 'schoolvideos'"
            style="background-color:#ecf4fc"
          >
            <v-card-title>Cerebral 21 Videos</v-card-title>
            <v-card-text>
              <v-card
                v-for="video in videos"
                :key="video.key"
                flat
                outlined
                style="margin-bottom:5px;"
              >
                <v-card-subtitle>
                  <v-row style="margin-right:10px; margin-left:10px;">
                    {{ video.title }} <v-divider
                  /></v-row>
                </v-card-subtitle>
                <v-card-text style="margin-top:-30px">
                  <v-row>
                    <iframe
                      v-if="video.link.includes('vimeo')"
                      :src="video.link"
                      width="50%"
                      height="150px"
                      style="border:0"
                      allowfullscreen
                    ></iframe>
                    <video-embed
                      style="
                    width:300px; height:200px;"
                      :src="video.link"
                      v-else
                    ></video-embed>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <!-- Internet Videos Details -->
          <v-card
            flat
            outlined
            v-if="show == 'internetvideos'"
            style="background-color:#ecf4fc"
          >
            <v-card-title>Internet Videos</v-card-title>
            <v-card-text>
              <v-card
                v-for="video in internetvideos"
                :key="video.key"
                flat
                outlined
                style="margin-bottom:5px;"
              >
                <v-card-subtitle>
                  <v-row style="margin-right:10px; margin-left:10px;">
                    {{ video.title }} <v-divider
                  /></v-row>
                </v-card-subtitle>
                <v-card-text style="margin-top:-30px">
                  <v-row>
                    <iframe
                      v-if="video.link.includes('vimeo')"
                      :src="video.link"
                      width="50%"
                      height="150px"
                      style="border:0"
                      allowfullscreen
                    ></iframe>
                    <video-embed
                      style="
                    width:300px; height:200px;"
                      :src="video.link"
                      v-else
                    ></video-embed>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <!-- Sheets -->
          <v-card
            flat
            outlined
            v-if="show == 'sheets'"
            style="background-color:#ecf4fc"
          >
            <v-card-title>Sheets</v-card-title>
            <v-card-text>
              <v-card
                v-for="sheet in sheets"
                :key="sheet.key"
                flat
                outlined
                style="margin-bottom:5px;"
              >
                <v-card-subtitle>
                  <v-row style="margin-right:10px; margin-left:10px;">
                    {{ sheet.title }} <v-divider
                  /></v-row>
                </v-card-subtitle>
                <v-card-text style="margin-top:-30px">
                  <a :href="sheet.link" target="_blank"
                    >Click Here to Open The File</a
                  >
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <!-- Questions -->
          <v-card
            flat
            outlined
            v-if="show == 'questions'"
            style="background-color:#ecf4fc"
          >
            <v-card-title>Questions</v-card-title>
            <v-card-text>
              <v-card flat outlined style="margin-bottom:5px;"
                ><v-card-text
                  >Flipped Questions: {{ this.flipped }}</v-card-text
                ></v-card
              >
              <v-card flat outlined style="margin-bottom:5px;"
                ><v-card-text
                  >Exit Questions: {{ this.exit }}</v-card-text
                ></v-card
              >
              <v-card flat outlined style="margin-bottom:5px;"
                ><v-card-text
                  >Exam Questions: {{ this.exam }}</v-card-text
                ></v-card
              >
            </v-card-text>
          </v-card>
        </v-card-text></v-card
      >
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
// import router from "@/router";

export default {
  name: "SubjectReport",
  components: {},
  props: ["path", "title", "order"],
  data() {
    return {
      questions: [],
      assignments: [],
      videos: [],
      internetvideos: [],
      sheets: [],
      subjectpath: "",
      show: "",

      //! Questions
      flipped: 0,
      exit: 0,
      exam: 0
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.path + "/questions")
      .get()
      .then(querySnapshot => {
        this.questions = [];
        querySnapshot.forEach(doc => {
          this.questions.push({
            key: doc.id,
            path: doc.ref.path
          });

          if (doc.data().flipped == "Yes") this.flipped++;
          if (doc.data().exit == "Yes") this.exit++;
          if (doc.data().exam == "Yes") this.exam++;
        });
      });
    firebase
      .firestore()
      .collection(this.path + "/tasks")
      .get()
      .then(querySnapshot => {
        this.assignments = [];
        querySnapshot.forEach(doc => {
          this.assignments.push({
            key: doc.id,
            path: doc.ref.path,
            weight: doc.data().weight,
            title: doc.data().title,
            grade: doc.data().grade
          });
        });
      });
    firebase
      .firestore()
      .collection(this.path + "/videos")
      .get()
      .then(querySnapshot => {
        this.videos = [];
        querySnapshot.forEach(doc => {
          this.videos.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link
          });
        });
      });
    firebase
      .firestore()
      .collection(this.path + "/internetvideos")
      .get()
      .then(querySnapshot => {
        this.internetvideos = [];
        querySnapshot.forEach(doc => {
          this.internetvideos.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link
          });
        });
      });
    firebase
      .firestore()
      .collection(this.path + "/sheets")
      .get()
      .then(querySnapshot => {
        this.sheets = [];
        querySnapshot.forEach(doc => {
          this.sheets.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            link: doc.data().link
          });
        });
      });
  },
  methods: {
    showDetails(type) {
      if (this.show == type) this.show = "";
      else this.show = type;
    }
  }
};
</script>
