<template>
  <div class="login-access">
    <v-container>
      <b-form @submit.stop.prevent="onSubmit">
        <v-card>
          <v-card-title class="justify-center">
            <img :src="this.logo" width="100px" />
          </v-card-title>
          <v-card-subtitle
            ><h4>
              Find you children's accounts
            </h4></v-card-subtitle
          >

          <v-card-text>
            <v-select
              :items="searchWith"
              v-model.trim="searchKey"
              label="Search With"
            ></v-select>
            <v-text-field :label="searchKey" v-model.trim="fatherNum" />
            <b-button variant="primary" @click="search">Search</b-button>
            <div style="padding-top:50px">
              <vue-good-table
                :columns="fields"
                :rows="studentInfo"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
              >
              </vue-good-table>
            </div>
          </v-card-text>
        </v-card>
      </b-form>
    </v-container>
  </div>
</template>
<script>
import firebase from "@/Firebase";

export default {
  name: "GetAccount",
  data() {
    return {
      searchWith: ["Father Mobile", "Mother Mobile"],
      logo: "",
      SchoolName: "",
      searchKey: "",
      fields: [
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firstname",
          label: "First name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Last name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "email",
          label: "Email",
          sortable: true,
          class: "text-left"
        },
        {
          field: "username",
          label: "Username",
          sortable: true,
          class: "text-left"
        },
        {
          field: "password",
          label: "Password",
          sortable: true,
          class: "text-left"
        }
      ],
      fatherNum: "",
      studentInfo: [],
      schoolRef: firebase.firestore().doc("schools/" + this.$route.query.school)
    };
  },
  created() {
    this.schoolRef.get().then(snapshot => {
      if (!snapshot.exists) return;
      let school = snapshot.data();

      this.logo = school.logo;
      this.SchoolName = school.school_name;
    });
  },
  methods: {
    search() {
      //   if(this.fatherNum.length<11)
      //   {

      //   }
      if (this.searchKey == "") {
        alert("Please Enter Your Number First");
      } else {
        if (this.searchKey == "Mother Mobile") {
          firebase
            .firestore()
            .collection("schools/" + this.$route.query.school + "/StudentsData")
            .where("mother_mobile", "==", this.fatherNum)
            .onSnapshot(querySnapshot => {
              this.studentInfo = [];
              querySnapshot.forEach(doc => {
                this.studentInfo.push({
                  key: doc.id,
                  path: doc.ref.path,
                  branch: doc.data().branch,
                  grade: doc.data().grade,
                  firstname: doc.data().firstname,
                  middlename: doc.data().middlename,
                  lastname: doc.data().lastname,
                  username: doc.data().username,
                  password: doc.data().password
                });
              });
            });
        } else {
          firebase
            .firestore()
            .collection("schools/" + this.$route.query.school + "/students")
            .where("father_mobile", "==", this.fatherNum)
            .onSnapshot(querySnapshot => {
              this.studentInfo = [];
              querySnapshot.forEach(doc => {
                this.studentInfo.push({
                  key: doc.id,
                  path: doc.ref.path,
                  branch: doc.data().branch,
                  grade: doc.data().grade,
                  firstname: doc.data().firstname,
                  middlename: doc.data().middlename,
                  lastname: doc.data().lastname,
                  username: doc.data().username,
                  password: doc.data().password,
                  email: doc.data().email
                });
              });
            });
        }
      }
    }
  }
};
</script>
