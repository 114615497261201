<template>
  <div>
    <v-row>
      <v-col cols="12">
        <b-card-group deck>
          <b-card header="Total Applications" class="text-center">
            <b-card-text>
              <div class="row" style="margin-top:50px">
                <div class="col-6">
                  <div class="numbers">
                    <h1 class="card-title">{{ this.total.length }}</h1>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-card-group>
        <br />

        <v-card>
          <v-card-text>
            <v-card-title>Admission Reports</v-card-title>
            <v-list three-line v-for="school in schools" :key="school.key">
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><h1>{{ school.count }}</h1></v-list-item-title
                  >
                  <v-list-item-title>{{ school.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text></v-card
        ></v-col
      >
    </v-row>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  data() {
    return {
      schools: [],
      counter: 0,
      total: []
    };
  },
  created() {
    firebase
      .firestore()
      .collection("/schools/" + this.$store.state.school + "/branches")
      .where("openadmission", "==", true)
      .onSnapshot(querySnapshot => {
        this.schools = [];
        this.counter = 0;
        querySnapshot.forEach(doc => {
          firebase
            .firestore()
            .collection("/schools/" + this.$store.state.school + "/admission")
            .where("branch", "==", doc.id)
            .onSnapshot(querySnapshot => {
              querySnapshot.forEach(x => {
                console.log(x);
                this.counter++;
              });
              this.schools.push({
                key: doc.id,
                path: doc.ref.path,
                title: doc.data().title,
                count: this.counter
              });
              this.counter = 0;
            });
        });
      });

    firebase
      .firestore()
      .collection("/schools/" + this.$store.state.school + "/admission")
      .onSnapshot(querySnapshot => {
        this.total = [];
        querySnapshot.forEach(doc => {
          this.total.push({
            key: doc.id
          });
        });
      });
  }
};
</script>
