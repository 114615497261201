<template>
  <b-row>
    <b-col cols="12">
      <CRUD
        title="Payment"
        :path="
          '/schools/' +
            this.$store.state.school +
            '/StudentsData/' +
            this.$route.params.student +
            '/payments'
        "
        :fields="this.fields"
        width="500"
        height="400"
      ></CRUD>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";

// import router from "@/router";
// import AddFees from "./AddFees";

export default {
  name: "FeesList",
  components: { CRUD },

  data() {
    return {
      fields: [
        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/paymentstypes",
          ftype: "ref",
          lookup: "title"
        },
        {
          field: "amount",
          label: "Amount",
          sortable: true,
          class: "text-left"
        },
        {
          field: "comment",
          label: "Comment",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  }
};
</script>
