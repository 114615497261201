<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img src="@/assets/hr.png" width="50px" /> Subject
          Teachers</v-card-title
        >
        <v-card-text>
          <div v-for="teacher in teachers" :key="teacher.key">
            <Teacher
              :path="teacher.employeepath"
              :master="teacher.master"
              :classes="teacher.class"
            />
          </div> </v-card-text
      ></v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import Teacher from "./Teacher";

export default {
  name: "SectionsList",
  components: { Teacher },
  props: ["path"],
  data() {
    return {
      subjectPath: this.$store.state.originalsubject,
      teachers: [],
      errors: [],
      subjects: []
      //ref: firebase.firestore().collection(this.path + "/teachers")
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.subjectPath + "/teachers")
      .onSnapshot(querySnapshot => {
        this.teachers = [];
        querySnapshot.forEach(doc => {
          this.teachers.push({
            key: doc.id,
            path: doc.ref.path,
            employeeid: doc.data().employeeid,
            employeepath: doc.data().employeepath,
            master: doc.data().master,
            class: doc.data().class
          });
        });
      });
  },
  methods: {}
};
</script>
