<template>
  <b-row>
    <b-col cols="12">
      <br />
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-calendar-month</v-icon>
          <span class="title font-weight-light">Employees List</span>
          <v-row align="center" justify="end">
            <download-excel
              class="btn btn-default"
              :data="subjects"
              :fields="fexport"
              type="csv"
              name="employees.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
              <small>Export to Excel</small>
            </download-excel>
          </v-row>
        </v-card-title>

        <p class="card-text">
          <vue-good-table
            :columns="fields"
            :rows="subjects"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
          </vue-good-table>
        </p>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EmployeesList",
  components: {},

  data() {
    return {
      fexport: {
        School: "school",
        Salary: "salary",
        "Insurance Salary": "insalary",
        Taxes: "taxes",
        Deductions: "deductions",
        Bonus: "bonus",
        Employee: "employee",
        Grade: "grade",
        Subject: "title"
      },
      fields: [
        {
          field: "salary",
          label: "Salary",
          sortable: true,
          class: "text-left"
        },
        {
          field: "insalary",
          label: "Insurance Salary",
          sortable: true,
          class: "text-left"
        },
        {
          field: "taxes",
          label: "Taxes",
          sortable: true,
          class: "text-left"
        },
        {
          field: "deductions",
          label: "Deductions",
          sortable: true,
          class: "text-left"
        },
        {
          field: "bonus",
          label: "Bonus",
          sortable: true,
          class: "text-left"
        },
        {
          field: "employee",
          label: "Employee",
          sortable: true,
          class: "text-left"
        },
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Subject Title",
          sortable: true,
          class: "text-left"
        }
      ],
      salary: 0,

      employees: [],
      subjects: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/employees")
    };
  },
  created() {
    this.salary = 0;
    this.ref.onSnapshot(querySnapshot => {
      this.subjects = [];
      querySnapshot.forEach(doc => {
        firebase
          .firestore()
          .collection(doc.ref.path + "/subjects")
          .where("school", "==", "American School (Porto Said)")
          .onSnapshot(querySnapshot => {
            querySnapshot.forEach(sub => {
              this.subjects.push({
                employee: doc.data().firstname + " " + doc.data().lastname,
                salary: doc.data().salary,
                taxes: doc.data().taxes,
                inssalary: doc.data().inssalary,
                deductions: doc.data().deductions,
                bonus: doc.data().bonus,
                key: sub.id,
                path: sub.ref.path,
                title: sub.data().subjectTitle,
                school: sub.data().school,
                grade: sub.data().grade
              });
            });
            if (doc.data().salary != null)
              this.salary = this.salary + Number(doc.data().salary);
          });
      });
    });
  },
  methods: {
    gotoTimeSheet(path, empname) {
      router.push({
        name: "Timesheet",
        params: { empname: empname, path: path }
      });
    }
  }
};
</script>
