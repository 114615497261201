<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title
          ><v-icon left size="40px">mdi-cog-outline</v-icon
          >Recruitment</v-card-title
        >
        <v-card-text>
          <b-card-group deck>
            <SettingCard
              title="Vacancies"
              icon="mdi-account-tie-voice-outline"
              link="Recruitment"
              :path="this.path"
            ></SettingCard>
            <SettingCard
              title="Candidates"
              icon="mdi-account-group-outline"
              link="Recruitment"
              :path="this.cand"
            ></SettingCard>
          </b-card-group>

          <v-card flat outlined>
            <b-tabs pills>
              <b-tab active>
                <template v-slot:title>
                  <v-icon color="gray">mdi-account-tie-voice-outline</v-icon
                  >&nbsp; Vacancies
                </template>
                <b-card-text>
                  <CRUD
                    title="Vacancie"
                    :path="this.path"
                    :fields="this.fields"
                    width="500"
                    height="700"
                  ></CRUD>
                </b-card-text>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <v-icon color="gray">mdi-account-group-outline</v-icon>&nbsp;
                  Candidates
                </template>
                <b-card-text>
                  <CRUD
                    title="Candidate"
                    :path="this.cand"
                    :fields="this.canfields"
                    width="500"
                    height="700"
                  ></CRUD>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </v-card>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>
<script>
import SettingCard from "@/components/dna/widgets/SettingCard";
import CRUD from "@/components/dna/crud/ListRecords.vue";
export default {
  name: "Recruitment-page",
  components: { SettingCard, CRUD },
  data() {
    return {
      path: "/schools/" + this.$store.state.school + "/vacancies",
      cand: "/schools/" + this.$store.state.school + "/candidates",
      fields: [
        {
          field: "title",
          label: "Job Title",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/jobs",
          ftype: "ref",
          lookup: "title"
        },
        {
          field: "vacancy",
          label: "Vacancy Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "positions",
          label: "Number of Positions",
          sortable: true,
          class: "text-left",
          ftype: "number"
        },
        {
          field: "description",
          label: "Description",
          sortable: true,
          class: "text-left",
          ftype: "area"
        }
      ],
      canfields: [
        {
          field: "vacancy",
          label: "Vacancy",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/vacancies",
          ftype: "ref",
          lookup: "vacancy"
        },
        {
          field: "firstname",
          label: "First Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "middlename",
          label: "Middle Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Last Name",
          sortable: true,
          class: "text-left"
        }
        // {
        //   field: "resume",
        //   label: "Resume",
        //   sortable: true,
        //   class: "text-left",
        //   ftype: "file",
        //   folder:"resumes"
        // },
      ]
    };
  }
};
</script>
