<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-cash-usd-outline</v-icon>
          <span class="title font-weight-light"
            >{{ this.$route.params.title }} Fees Setup
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card>
                <v-card-title>Tuition Fees</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="Old Students Fees (Before 2019-2020)"
                        v-model="fees.old_fees"
                        type="number"
                        messages="Haven't Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="New Students Fees (2020-2021)"
                        v-model="fees.new_fees"
                        type="number"
                        messages="Haven't Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="Old Students Fees (Before 2019-2020)"
                        v-model="fees.old_fees_sib"
                        type="number"
                        messages="Have Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="New Students Fees (2020-2021)"
                        v-model="fees.new_fees_sib"
                        type="number"
                        messages="Have Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>Insurance fees</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="Old Students Fees (Before 2019-2020)"
                        v-model="fees.old_ins"
                        type="number"
                        messages="Haven't Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="New Students Fees (2020-2021)"
                        v-model="fees.new_ins"
                        type="number"
                        messages="Haven't Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="Old Students Fees (Before 2019-2020)"
                        v-model="fees.old_ins_sib"
                        type="number"
                        messages="Have Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        label="New Students Fees (2020-2021)"
                        v-model="fees.new_ins_sib"
                        type="number"
                        messages="Have Siblings"
                      />
                    </v-col>
                    <v-col cols="1">L.E</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card
                ><v-card-title>Instalments</v-card-title
                ><v-card-text>
                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        label="First Instalment"
                        v-model="fees.firstInstalment"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="1">%</v-col>
                    <v-col cols="8">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fees.firstInstalment_due"
                            label="First Instalment Due Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fees.firstInstalment_due"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        label="Second Instalment"
                        v-model="fees.secondInstalment"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="1">%</v-col>
                    <v-col cols="8">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fees.secondInstalment_due"
                            label="Second Instalment Due Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fees.secondInstalment_due"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        label="Third Instalment"
                        v-model="fees.thirdInstalment"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="1">%</v-col>
                    <v-col cols="8">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fees.thirdInstalment_due"
                            label="Third Instalment Due Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fees.thirdInstalment_due"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row> </v-card-text
              ></v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green" dark depressed @click="save">Save Fees</v-btn>
        </v-card-actions>
      </v-card>
    </b-col>
  </b-row>
</template>
<script>
import firebase from "@/Firebase";
//import router from "@/router";
export default {
  data() {
    return {
      menu: false,
      fees: {}
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(doc => {
        if (doc.exists) {
          this.fees = doc.data();
        } else {
          alert("No such document!");
        }
      });
  },
  methods: {
    save() {
      firebase
        .firestore()
        .doc(this.$route.params.path)
        .set(this.fees)
        .then(() => {
          this.$bvToast.toast("Fees Modified", {
            title: "Grade Fees Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
