<template>
  <v-card
    ><v-card-text>
      <v-card dark style="background-color:#1b2e47">
        <v-card-title>
          <v-icon large left>mdi-finance</v-icon>
          <span class="title">Students Leaderboard</span>
        </v-card-title>
        <v-card-text>
          <!-- School -->
          <v-row>
            <v-col class="d-flex" cols="4" sm="4">
              <v-select
                :items="schools"
                v-model="school"
                label="School"
                @change="onSchoolChange()"
              ></v-select>
            </v-col>
            <!-- Grade Level -->

            <v-col class="d-flex" cols="4" sm="4">
              <v-select
                :items="grades"
                v-model="grade"
                label="Grade Level"
                @change="onGradeChange()"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer
          ><b-button variant="success" @click="clearSearch"
            >Clear Search</b-button
          ></v-card-actions
        >
      </v-card>
      <br />

      <v-row>
        <v-col cols="8">
          <v-sheet v-if="loading == false" color="darken-2" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>

          <v-card flat v-else
            ><v-card-text>
              <v-row align="center" justify="center" style="padding-top:20px">
                <v-col
                  cols="4"
                  align="center"
                  justify="center"
                  style="background-color:red; height:150px; color:#fff;"
                >
                  <h1>2</h1>
                  <br />
                  {{
                    students[1].firstname +
                      " " +
                      students[1].middlename +
                      " " +
                      students[1].lastname
                  }}
                </v-col>
                <v-col
                  cols="4"
                  align="center"
                  justify="center"
                  style="background-color:green; height:200px; color:#fff;"
                >
                  <h1>1</h1>
                  <br />
                  {{
                    students[0].firstname +
                      " " +
                      students[0].middlename +
                      " " +
                      students[0].lastname
                  }}</v-col
                >
                <v-col
                  cols="4"
                  align="center"
                  justify="center"
                  style="background-color:#FF8F00; height:120px; color:#fff;"
                >
                  <h1>3</h1>
                  <br />
                  {{
                    students[2].firstname +
                      " " +
                      students[2].middlename +
                      " " +
                      students[2].lastname
                  }}</v-col
                >
              </v-row>
              <v-card flat>
                <div v-for="(student, index) in students" :key="student.key">
                  <v-card-text>
                    <v-card flat outlined>
                      <v-card-title>
                        <h4>
                          <b>{{ student.points }}</b>
                        </h4>
                        <small>Points</small></v-card-title
                      >
                      <v-card-text>
                        <v-row>
                          <v-col cols="9" style="background-color:#dbe5f6">
                            <b>
                              {{
                                student.firstname +
                                  " " +
                                  student.middlename +
                                  " " +
                                  student.lastname
                              }}</b
                            >
                            <br />
                            {{ "Class: " + student.class }}
                          </v-col>
                          <v-col cols="3">
                            <h3 style="margin-right:20px; color:green;">
                              {{ index + 1 }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-card-text></v-card
                    >
                  </v-card-text>
                </div>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" style="padding-right:50px">
          <v-sheet v-if="loading == false" color="darken-2" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>

          <div id="chart" v-else>
            <v-card flat outlined>
              <v-card-title>Classes' Rank</v-card-title>
              <v-card-text>
                <apexchart
                  type="bar"
                  width="100%"
                  :options="classchartOptions"
                  :series="classseries"
                ></apexchart>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Leaderboard-page",
  data() {
    return {
      students: [],
      loading: false,
      answers: [],
      schools: [],
      grades: [],
      school: "",
      grade: "",
      //   sclass: this.$store.state.sclass,
      //   student: this.$store.state.studentpath,
      myrecord: [],
      index: 1,
      classlist: [],
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),
      classpoints: [],
      classseries: [
        {
          data: [44, 55, 41, 64]
        }
      ],

      classchartOptions: {
        colors: ["#089d13"],
        chart: {
          toolbar: {
            show: false
          },
          type: "bar",
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"]
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: ["Class A", "Class B", "Class C", "Class D"]
        }
      }
    };
  },
  created() {
    this.schoolsRef.get().then(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  },
  methods: {
    clearSearch() {
      //this.students = [];

      window.location.reload();
    },
    totalClass() {
      var result = [];
      this.students.reduce(function(res, value) {
        if (!res[value.class]) {
          res[value.class] = { class: value.class, points: 0 };
          result.push(res[value.class]);
        }
        res[value.class].points += value.points;
        return res;
      }, {});

      console.log(result);
    },
    onSchoolChange() {
      this.grades = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.school +
            "/grades/"
        )
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.data().title
            });
          });
        });
    },
    onGradeChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData/")
        .where("branch", "==", this.school)
        .where("grade", "==", this.grade)
        .orderBy("points", "desc")
        .get()
        .then(querySnapshot => {
          this.students = [];
          this.index = 1;
          querySnapshot.forEach(doc => {
            this.students.push({
              index: this.index,
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              schoolName: doc.data().schoolName,
              grade: doc.data().grade,
              points: doc.data().points,
              class: doc.data().class
            });
            this.index++;
          });

          //! Get Current Student Rank
          this.myrecord = this.students.find(
            ({ path }) => path === this.student
          );

          //! Get Classes Rank
          var result = [];
          this.classlist = [];
          this.classpoints = [];

          this.classseries = [
            {
              name: "Points",
              data: []
            }
          ];

          this.classchartOptions = {
            colors: ["#089d13"],
            chart: {
              toolbar: {
                show: false
              },
              type: "bar",
              height: 430
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: "top"
                }
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: "12px",
                colors: ["#fff"]
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ["#fff"]
            },
            xaxis: {
              categories: []
            }
          };

          this.students.reduce(function(res, value) {
            if (!res[value.class]) {
              res[value.class] = { class: value.class, points: 0 };
              result.push(res[value.class]);
            }
            res[value.class].points += value.points;
            return res;
          }, {});

          result.forEach(cls => {
            this.classlist.push("Class " + cls.class);
            this.classpoints.push(cls.points);
          });

          this.classchartOptions = {
            colors: ["#089d13"],
            chart: {
              toolbar: {
                show: false
              },
              type: "bar",
              height: 430
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: "top"
                }
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: "12px",
                colors: ["#fff"]
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ["#fff"]
            },
            xaxis: {
              categories: this.classlist
            }
          };

          this.classseries = [
            {
              name: "Points",
              data: this.classpoints
            }
          ];

          this.loading = true;
        });
    }
  }
};
</script>
