<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon left size="40px">mdi-head-cog-outline</v-icon>Accounting
          Setting</v-card-title
        >

        <b-card-group deck>
          <SettingCard
            title="Fees Options"
            icon="mdi-badge-account-horizontal-outline"
            link="FeesType"
          ></SettingCard>
          <SettingCard
            title="Payments Options"
            icon="mdi-contacts"
            link="PaymentsType"
          ></SettingCard>
          <SettingCard
            title="Bank Accounts"
            icon="mdi-cash-multiple"
            link="Bank"
          ></SettingCard>
          <SettingCard
            title="Payment Methods"
            icon="mdi-currency-usd"
            link="Paymethods"
          ></SettingCard>
        </b-card-group>
      </v-card>
    </b-col>
  </b-row>
</template>
<script>
import SettingCard from "@/components/dna/widgets/SettingCard";
export default {
  name: "HRSetting",
  components: { SettingCard }
};
</script>
