<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-text>
          <div>
            <CRUD
              title="Job"
              :path="'jobs_hub'"
              :fields="this.fields"
              width="80%"
              height="100%"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
export default {
  name: "Dimensions-page",
  components: {
    CRUD
  },

  data() {
    return {
      fields: [
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "department_objective",
          label: "Objective / Purpose of the Department:",
          sortable: true,
          class: "text-left",
          ftype: "editor"
        },
        {
          field: "objective",
          label: "Job Objectives:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "authority",
          label: "Authority:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "relationships",
          label: "Functional Relationships:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "org_focus",
          label: "ORGANIZATIONAL FOCUS & SCOPE:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "ins_focus",
          label: "INSTRUCTIONAL FOCUS & SCOPE:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "exe_focus",
          label: "EXECUTIVE FOCUS & SCOPE:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "res_focus",
          label: "RESOURCES FOCUS & SCOPE:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "eth_focus",
          label: "ETHICS / BEHAVIORAL FOCUS & SCOPE:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "tools",
          label: "Tools & Skills needed for the job:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        },
        {
          field: "edu_background",
          label: "Essential Educational Background & Experience:",
          sortable: true,
          class: "text-left",
          ftype: "editor",
          show: "hide"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style></style>
