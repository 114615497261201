<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title> Attendance </v-card-title>

        <v-card-text>
          <b-row>
            <b-col cols="6" lg="3">
              <v-toolbar color="green light-3" dark flat>
                <v-toolbar-title>
                  <img src="@/assets/students.png" class=" ma-2" width="44px" />

                  &nbsp; Classes List
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-list shaped style="background-color:#d7e9f9">
                <v-list-item
                  v-for="c in classList"
                  :key="c"
                  href="#"
                  @click="loadClass(c)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-google-classroom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ c }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </b-col>
            <b-col cols="6" lg="9">
              <v-row>
                <v-col cols="6">
                  <b-form-datepicker
                    id="livedate"
                    v-model="date"
                    class="mb-2"
                  ></b-form-datepicker>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="reload()" color="info" depressed small
                    >Select Date</v-btn
                  >
                </v-col>
              </v-row>

              <vue-good-table
                :columns="fields"
                :rows="students"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 30,
                  perPageDropdown: [30, 20, 10],
                  dropdownAllowAll: false
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <v-row>
                      <v-col cols="12">
                        <!-- Status Select -->
                        <v-btn-toggle>
                          <v-btn
                            small
                            depressed
                            color="secondary"
                            @click="
                              saveAttendance(
                                'present',
                                props.row.key,
                                props.row.class,
                                props.row.studentname,
                                props.row.grade
                              )
                            "
                            >Present</v-btn
                          >
                          <v-btn
                            small
                            depressed
                            color="error"
                            @click="
                              saveAttendance(
                                'absent',
                                props.row.key,
                                props.row.class,
                                props.row.studentname,
                                props.row.grade
                              )
                            "
                            >Absent</v-btn
                          >
                          <v-btn
                            small
                            depressed
                            color="info"
                            @click="
                              saveAttendance(
                                'excused',
                                props.row.key,
                                props.row.class,
                                props.row.studentname,
                                props.row.grade
                              )
                            "
                            >Excused</v-btn
                          >
                          <v-btn
                            small
                            depressed
                            color="success"
                            @click="
                              saveAttendance(
                                'late',
                                props.row.key,
                                props.row.class,
                                props.row.studentname,
                                props.row.grade
                              )
                            "
                            >Late</v-btn
                          >
                          <v-btn
                            small
                            depressed
                            dark
                            color="purple"
                            @click="
                              saveAttendance(
                                'leave',
                                props.row.key,
                                props.row.class,
                                props.row.studentname,
                                props.row.grade
                              )
                            "
                            >Leave</v-btn
                          >
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-btn
                      color="success"
                      x-small
                      depressed
                      @click="clearAttendance(props.row.key)"
                      >Clear Attendance</v-btn
                    >
                  </span>
                  <span v-else-if="props.column.field == 'student'">
                    {{
                      props.row.firstname +
                        " " +
                        props.row.middlename +
                        " " +
                        props.row.lastname
                    }}
                    <br />
                  </span>
                  <span v-else-if="props.column.field == 'status'">
                    <getParticipation
                      :student="props.row.key"
                      :date="date"
                      :subject="subject"
                    />
                    <br />
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import getParticipation from "./getAttendance.vue";

export default {
  name: "Attendance-page",
  components: { getParticipation },
  props: ["path", "title"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      grades: [],
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "status",
          label: "Status",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center", width: "400px" }
      ],
      classes: [],
      week: localStorage.getItem("week"),
      weekPath: localStorage.getItem("weekpath"),
      weekId: localStorage.getItem("weekId"),
      classList: JSON.parse(localStorage.getItem("classList")),

      gradePath: this.$store.state.gradePath,

      grade: this.$store.state.thisGrade,
      subject: this.$store.state.subjectTitle,
      subjectPath: this.$store.state.subject,

      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.gradePath + "/classes")
    };
  },
  created() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.$store.state.thisBranch 
        )
        .get()
        .then((doc) => {
          this.$store.commit("breakdown", doc.data().breakdown)
          console.log("subject breakdown: ",doc.data().breakdown)
        })

    this.ref.onSnapshot(querySnapshot => {
      this.classes = [];
      querySnapshot.forEach(doc => {
        this.classes.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title
        });
      });
    });
  },
  methods: {
    reload() {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlenam +
                " " +
                doc.data().lastname,

              class: doc.data().class,
              grade: doc.data().grade,
              username: doc.data().username
            });
          });
        });
    },
    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/teachers/")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              class: doc.data().class,
              grade: doc.data().grade
            });
          });
        });
    },
    saveAttendance(status, student, sclass, studentname, grade) {
      this.record.date = this.date;
      this.record.type = status;
      this.record.area = "subject";
      this.record.school = this.$store.state.school;
      this.record.branch = this.$store.state.thisBranch;
      this.record.grade = grade;
      this.record.subjectPath = this.$store.state.originalsubject;
      this.record.subject = this.$store.state.subjectTitle;
      this.record.class = sclass;
      this.record.student = student;
      this.record.studentname = studentname;
      this.record.breakdown = this.$store.state.breakdown;
console.log("attendance record ==> ",this.record)
console.log("/schools/" + this.$store.state.school + "/attendance/"+student + this.date + this.record.subject)
      firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/attendance")
        .doc(student + this.date + this.record.subject)
        .set(this.record)
        .then(() => {console.log(this.record)})
        .catch(error => {
          alert("Error removing document: ", error);
        });

    },
    clearAttendance(student) {
      firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/attendance")
        .doc(student + this.date + this.record.subject)
        .delete()
        .then(() => {})
        .catch(error => {
          alert("Error removing document: ", error);
        });
    }
  }
};
</script>
