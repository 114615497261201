<template>
  <div>
    <FixedExam :path="this.path" />
  </div>
</template>
<script>
import firebase from "@/Firebase";
import FixedExam from "./FixedExam";

export default {
  props: ["path"],
  components: { FixedExam },
  data() {
    return { exam: {} };
  },
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.exam = snapshot.data();
      });
  }
};
</script>
