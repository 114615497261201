<template>
  <v-card style="margin-top:20px">
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <b-card
            bg-variant="danger"
            text-variant="white"
            header="Total Fees"
            class="text-center"
          >
            <b-card-text>
              <h1>{{ this.fees }}</h1>
            </b-card-text>
          </b-card>
        </v-col>
        <v-col cols="4">
          <b-card
            bg-variant="success"
            text-variant="white"
            header="Total Payments"
            class="text-center"
          >
            <b-card-text>
              <h1>{{ this.payments }}</h1>
            </b-card-text>
          </b-card>
        </v-col>
        <v-col cols="4">
          <b-card
            bg-variant="info"
            text-variant="white"
            header="Balance"
            class="text-center"
          >
            <b-card-text>
              <h1>{{ this.fees - this.payments }}</h1>
            </b-card-text>
          </b-card>
        </v-col>
      </v-row>
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Show Student Information</b-button
      >

      <b-collapse id="accordion-1" class="mt-2">
        <ShowStudent :path="this.studentid" />
      </b-collapse>
      <CRUD
        title="Fee"
        :path="
          '/schools/' +
            this.$store.state.school +
            '/StudentsData/' +
            this.studentid +
            '/fees'
        "
        :fields="this.feesfields"
        width="500"
        height="600"
      ></CRUD>
      <v-divider></v-divider>
      <CRUD
        title="Payment"
        :path="
          '/schools/' +
            this.$store.state.school +
            '/StudentsData/' +
            this.studentid +
            '/payments'
        "
        :fields="this.payfields"
        width="500"
        height="600"
      ></CRUD>

      <v-divider></v-divider>
      <CRUD
        title="Discount"
        :path="
          '/schools/' +
            this.$store.state.school +
            '/StudentsData/' +
            this.studentid +
            '/discounts'
        "
        :fields="this.discountsfields"
        width="500"
        height="400"
      ></CRUD>

      <v-divider></v-divider>
      <CRUD
        title="Comment"
        :path="
          '/schools/' +
            this.$store.state.school +
            '/StudentsData/' +
            this.studentid +
            '/comments'
        "
        :fields="this.commfields"
        width="500"
        height="400"
      ></CRUD>
    </v-card-text>
  </v-card>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import ShowStudent from "@/components/sis/students/ShowStudent.vue";

import firebase from "@/Firebase";

export default {
  name: "FeesList",
  components: { CRUD, ShowStudent },

  data() {
    return {
      total: "",
      first: "",
      second: "",
      third: "",

      studentbranch: localStorage.getItem("studentbranch"),
      studentid: localStorage.getItem("studentid"),
      studentgrade: localStorage.getItem("studentgrade"),
      studentclass: localStorage.getItem("studentclass"),

      commfields: [
        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left",
          ftype: "date"
        },
        {
          field: "comment",
          label: "Comment",
          sortable: true,
          class: "text-left",
          ftype: "area"
        }
      ],
      discountsfields: [],
      brothers: {},
      father: {},
      mother: {},
      fees: 0,
      payments: 0,
      newfees: {},
      student: {},
      parentf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "job",
          label: "Job",
          sortable: true,
          class: "text-left"
        },

        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        }
      ],

      feesfields: [],
      payfields: []
    };
  },
  created() {
    this.feesfields = [
      {
        field: "year",
        label: "Year",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/years",
        ftype: "ref",
        lookup: "title"
      },
      {
        field: "type",
        label: "Type",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/feestypes",
        ftype: "ref",
        lookup: "title"
      },

      {
        field: "amount",
        label: "Amount",
        sortable: true,
        class: "text-left",
        ftype: "number"
      },

      {
        field: "comment",
        label: "Comment",
        sortable: true,
        class: "text-left"
      },
      {
        field: "school",
        label: "School",
        ftype: "const",
        value: this.$store.state.school
      },
      {
        field: "gradelevel",
        label: "Grade",
        ftype: "const",
        value: this.studentgrade
      },
      {
        field: "class",
        label: "Class",
        ftype: "const",
        value: this.studentclass
      },
      {
        field: "branch",
        label: "Branch",
        ftype: "const",
        value: this.studentbranch
      },
      {
        field: "studentid",
        label: "studentid",
        ftype: "const",
        value: this.studentid
      }
    ];

    this.payfields = [
      {
        field: "year",
        label: "Year",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/years",
        ftype: "ref",
        lookup: "title"
      },
      {
        field: "type",
        label: "Type",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/paymentstypes",
        ftype: "ref",
        lookup: "title"
      },

      {
        field: "paymentmethod",
        label: "Payment Method",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/paymentmethod",
        ftype: "ref",
        lookup: "title"
      },
      {
        field: "invoice",
        label: "Invoice No",
        sortable: true,
        class: "text-left"
      },

      {
        field: "paymentother",
        label: "Other Payment Method",
        sortable: true,
        class: "text-left"
      },
      {
        field: "bank",
        label: "Bank",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/bank",
        ftype: "ref",
        lookup: "title"
      },
      {
        field: "amount",
        label: "Amount",
        sortable: true,
        class: "text-left"
      },
      {
        field: "date",
        label: "Payment Date",
        sortable: true,
        class: "text-left",
        ftype: "date"
      },
      {
        field: "comment",
        label: "Comment",
        sortable: true,
        class: "text-left"
      },
      {
        field: "school",
        label: "School",
        ftype: "const",
        value: this.$store.state.school
      },
      {
        field: "gradelevel",
        label: "Grade",
        ftype: "const",
        value: this.studentgrade
      },
      {
        field: "class",
        label: "Class",
        ftype: "const",
        value: this.studentclass
      },
      {
        field: "branch",
        label: "Branch",
        ftype: "const",
        value: this.studentbranch
      },
      {
        field: "studentid",
        label: "studentid",
        ftype: "const",
        value: this.studentid
      }
    ];

    this.discountsfields = [
      {
        field: "year",
        label: "Year",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/years",
        ftype: "ref",
        lookup: "title"
      },
      {
        field: "amount",
        label: "Discount",
        sortable: true,
        class: "text-left",
        ftype: "number"
      },
      {
        field: "reason",
        label: "Reason",
        sortable: true,
        class: "text-left",
        path: "/schools/" + this.$store.state.school + "/discountReasons",
        ftype: "ref",
        lookup: "title"
      },
      {
        field: "comment",
        label: "Comment",
        sortable: true,
        class: "text-left"
      },
      {
        field: "school",
        label: "School",
        ftype: "const",
        value: this.$store.state.school
      },
      {
        field: "gradelevel",
        label: "Grade",
        ftype: "const",
        value: this.studentgrade
      },
      {
        field: "class",
        label: "Class",
        ftype: "const",
        value: this.studentclass
      },
      {
        field: "branch",
        label: "Branch",
        ftype: "const",
        value: this.studentbranch
      },
      {
        field: "studentid",
        label: "studentid",
        ftype: "const",
        value: this.studentid
      }
    ];
    firebase
      .firestore()
      .doc(
        "/schools/" +
          this.$store.state.school +
          "/StudentsData/" +
          this.studentid
      )
      .get()
      .then(doc => {
        if (doc.exists) {
          this.student = doc.data();

          firebase
            .firestore()
            .collection(
              "schools/" +
                this.$store.state.school +
                "/StudentsData/" +
                this.studentid +
                "/fees"
            )
            .onSnapshot(querySnapshot => {
              this.fees = 0;
              querySnapshot.forEach(fee => {
                console.log("fee.data().amount");
                this.fees += Number(fee.data().amount);
              });
            });
          firebase
            .firestore()
            .collection(doc.ref.path + "/payments")
            .onSnapshot(querySnapshot => {
              this.payments = 0;
              querySnapshot.forEach(pay => {
                this.payments += Number(pay.data().amount);
              });
            });
        }
      });
  },

  methods: {
    autofees() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade
        )
        .get()
        .then(doc => {
          this.fees = doc.data();
          if (
            this.student.join == "2020-2021" &&
            this.brothers.length > 0 &&
            this.brothers[0].join == "2019-2020"
          ) {
            this.total = this.fees["new_fees_sib"];
          } else if (
            this.brothers.length > 0 &&
            this.brothers[0].join == "2019-2020"
          ) {
            this.total = this.fees["old_fees_sib"];
          } else if (this.student.join == "2020-2021") {
            this.total = this.fees["new_fees"];
          } else {
            this.total = this.fees["old_fees"];
          }

          this.first = (this.total * this.fees.firstInstalment) / 100;
          this.second = (this.total * this.fees.secondInstalment) / 100;
          this.third = (this.total * this.fees.thirdInstalment) / 100;

          this.save();
          //Discount

          //alert(this.first);
        });
    },
    price() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade
        )
        .get()
        .then(doc => {
          if (doc.exists) {
            this.fees = doc.data();
          } else {
            alert("No such document!");
          }
        });
    },
    save() {
      this.newfees = {
        amount: String(this.first),
        duo: this.fees.firstInstalment_due,
        type: "Tuition Fees"
      };
      firebase
        .firestore()
        .collection(
          "/schools" +
            this.$store.state.school +
            "StudentsData/" +
            this.studentid +
            "/fees"
        )
        .add(this.newfees)
        .then(() => {
          this.$bvToast.toast("First Instalment Set", {
            title: "First Instalment Set Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });

      this.newfees = {
        amount: String(this.second),
        duo: this.fees.secondInstalment_due,
        type: "Tuition Fees"
      };
      firebase
        .firestore()
        .collection(
          "/schools" +
            this.$store.state.school +
            "StudentsData/" +
            this.studentid +
            "/fees"
        )
        .add(this.newfees)
        .then(() => {
          this.$bvToast.toast("Seccond Instalment Set", {
            title: "Seccond Instalment Set Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
      this.newfees = {
        amount: String(this.third),
        duo: this.fees.thirdInstalment_due,
        type: "Tuition Fees"
      };
      firebase
        .firestore()
        .collection(
          "/schools" +
            this.$store.state.school +
            "StudentsData/" +
            this.studentid +
            "/fees"
        )
        .add(this.newfees)
        .then(() => {
          this.$bvToast.toast("Third Instalment Set", {
            title: "Third Instalment Set Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
