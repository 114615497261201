<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>Questions Bank</v-card-title>
        <v-card-subtitle>{{ gradetitle }}</v-card-subtitle>

        <v-card-text>
          <!-- Questions Hub -->

          <QuestionsHub
            :path="this.$route.params.id"
            :order="this.boards.length"
            source="questions"
            v-if="master == true"
          ></QuestionsHub>

          <!-- Subject Questions Hub -->
          <SubjectQuestionsHub
            :path="this.$route.params.id"
            :order="this.boards.length"
            source="questions"
            style="margin-left:20px;"
            v-if="master == true"
          ></SubjectQuestionsHub>
          <v-btn
            depressed
            style="margin-left:10px"
            color="success"
            @click="goToImport($route.params.id)"
            ><small>Import Questions</small>
          </v-btn>
          <br />

          <!-- End Questions Hub -->
          <AddQuesion
            :path="this.$route.params.id"
            v-if="master == true"
          ></AddQuesion>

          <v-row>
            <v-col cols="6">
              <b-card
                bg-variant="success"
                text-variant="white"
                header="Total Questions"
                class="text-center"
                style="cursor:pointer"
                @click="loadAllQuestions()"
              >
                <b-card-text>
                  <h1>{{ boards.length }}</h1>
                </b-card-text>
              </b-card>
            </v-col>

            <v-col cols="2">
              <b-card
                bg-variant="info"
                text-variant="white"
                header="Difficulty Level 1"
                class="text-center"
                style="cursor:pointer"
              >
                <b-card-text>
                  <h1>{{ this.level1 }}</h1>
                </b-card-text>
              </b-card>
            </v-col>

            <v-col cols="2">
              <b-card
                bg-variant="success"
                text-variant="white"
                header="Difficulty Level 2"
                class="text-center"
                style="cursor:pointer"
              >
                <b-card-text>
                  <h1>{{ this.level2 }}</h1>
                </b-card-text>
              </b-card>
            </v-col>

            <v-col cols="2">
              <b-card
                bg-variant="danger"
                text-variant="white"
                header="Difficulty Level 3"
                class="text-center"
                style="cursor:pointer"
              >
                <b-card-text>
                  <h1>{{ this.level3 }}</h1>
                </b-card-text>
              </b-card>
            </v-col>
          </v-row>

          <br />
          <v-row>
            <v-col cols="6">
              <v-card flat outlined color="#546E7A"
                ><v-card-title style="color:#fff">Bloom</v-card-title>
                <v-card-subtitle style="color:#333"
                  >Distribution of questions</v-card-subtitle
                >
                <v-card-text>
                  <v-divider />
                  <v-row v-for="blo in bloom" :key="blo.value">
                    <v-col cols="2">
                      <b style="color:yellow; font-size:18px;">
                        {{ blo.count }}
                      </b>
                    </v-col>
                    <v-col cols="8" style="color:#fff">
                      {{ blo.text }}
                    </v-col>
                    <v-col cols="2" style="color:#fff">
                      <span v-if="blo.count == 0">
                        <img src="@/assets/reports/wrong.png" width="20" />
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat outlined color="info" height="100%"
                ><v-card-title style="color:#fff">DOK</v-card-title>
                <v-card-subtitle style="color:#333"
                  >Distribution of questions</v-card-subtitle
                >
                <v-card-text>
                  <v-divider />
                  <v-row v-for="doo in dok" :key="doo.value">
                    <v-col cols="2">
                      <b style="color:yellow; font-size:18px;">
                        {{ doo.count }}
                      </b>
                    </v-col>
                    <v-col cols="8" style="color:#fff">
                      {{ doo.text }}
                    </v-col>
                    <v-col cols="2" style="color:#fff">
                      <span v-if="doo.count == 0">
                        <img src="@/assets/reports/wrong.png" width="20" />
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="false">
            <v-col cols="6">
              <b-card
                bg-variant="success"
                text-variant="white"
                header="Flipped Count"
                class="text-center"
                style="cursor:pointer"
                @click="filterFlipped()"
              >
                <b-card-text>
                  <h1>{{ this.flippedCount }}</h1>
                </b-card-text>
              </b-card>
            </v-col>
            <v-col cols="6">
              <b-card
                bg-variant="danger"
                text-variant="white"
                header="Exit Count"
                class="text-center"
                style="cursor:pointer"
                @click="filterExit()"
              >
                <b-card-text>
                  <h1>{{ this.exitCount }}</h1>
                </b-card-text>
              </b-card>
            </v-col>
          </v-row>
          <br />

          <vue-good-table
            :columns="fields"
            :rows="boards"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->
              <span v-if="props.column.field == 'actions'">
                <div v-if="master == true">
                  <v-btn
                    depressed
                    x-small
                    color="error"
                    @click="deleteboard(props.row.key, props.row.title)"
                  >
                    <v-icon size="18">mdi-delete-outline</v-icon>
                  </v-btn>

                  <br />
                  <v-btn
                    depressed
                    x-small
                    color="info"
                    @click="
                      Edit(props.row.path, props.row.type, props.row.qtype)
                    "
                  >
                    <v-icon size="18">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <br />

                  <TransferQuestion :path="props.row.path" />
                  <br />
                  <ViewQuestion :path="props.row.path" />
                </div>
              </span>

              <span v-else-if="props.column.field == 'options'">
                <button
                  class="btn btn-success btn-sm"
                  @click="options(props.row.path, props.row.title)"
                  v-if="
                    props.row.qtype == 'mc' ||
                      props.row.qtype == 'listening' ||
                      props.row.qtype == null
                  "
                >
                  <b-icon icon="list-check" scale="1"></b-icon>
                </button>
              </span>

              <span v-else-if="props.column.field == 'title'">
                <div v-katex:auto v-html="props.row.title" class="foo"></div>
                <p v-if="props.row.exam == 'Yes'">
                  <v-chip small color="red" dark> Exam Question</v-chip>
                </p>
                <p v-if="props.row.flipped == 'Yes'">
                  <v-chip small color="blue" dark> Flipped Question</v-chip>
                </p>
                <p v-if="props.row.exit == 'Yes'">
                  <v-chip small color="green" dark> Exit Question</v-chip>
                </p>
                <p v-if="props.row.inclass == true">
                  <v-chip small color="gray" dark
                    >Guided Practice Question</v-chip
                  >
                </p>
                <p>
                  <v-chip small color="blue" dark label
                    >Bloom: {{ props.row.bloom }}</v-chip
                  >
                  <v-chip small color="green" dark label
                    >DOK: {{ props.row.dok }}</v-chip
                  >
                  <v-chip small color="red" dark label
                    >Difficulty: {{ props.row.difficulty }}</v-chip
                  >
                </p>
              </span>

              <span
                v-else-if="props.column.field == 'report'"
                style="
              text-align:center"
              >
                <p v-if="props.row.report == true">
                  <v-icon color="red" size="30"> mdi-flag</v-icon>
                </p>
              </span>
              <span
                v-else-if="props.column.field == 'editor'"
                style="
              text-align:center"
              >
                <small>
                  {{
                    props.row.editorfirstname + " " + props.row.editorlastname
                  }}</small
                >
                <br />
                <small> {{ props.row.lastedit }}</small>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddQuesion from "./AddQuesionInBank";
import QuestionsHub from "@/components/hub/QuestionsHub";
import SubjectQuestionsHub from "@/components/hub/SubjectQuestionsHub";
import EditQuestion from "@/components/lms/activities/Create/Question.vue";
import TransferQuestion from "@/components/dna/TransferQuestion.vue";
import ViewQuestion from "@/components/dna/ViewQuestion.vue";

export default {
  name: "WeeksList",
  components: {
    AddQuesion,
    QuestionsHub,
    SubjectQuestionsHub,
    TransferQuestion,
    ViewQuestion
  },
  data() {
    return {
      level1: 0,
      level2: 0,
      level3: 0,
      flippedCount: 0,
      exitCount: 0,

      Remembering3: 0,

      master: this.$store.state.master,
      grade: this.$route.params.id,
      fields: [
        {
          field: "editor",
          label: "Last Editor",
          sortable: true,
          class: "text-left",
          width: "20%"
        },
        {
          field: "report",
          label: "Report",
          sortable: true,
          class: "text-left",
          width: "5%"
        },

        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left",
          width: "55%",
          html: true
        },
        {
          field: "options",
          label: "Options",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "5%"
        }
      ],

      bloom: [
        { value: "Knowledge (Remembering)", text: "Knowledge (Remembering)" },
        {
          value: "Comprehension (Understanding)",
          text: "Comprehension (Understanding)"
        },
        {
          value: "Application (Transferring)",
          text: "Application (Transferring)"
        },
        { value: "Analysis (Relating)", text: "Analysis (Relating)" },
        { value: "Evaluation (Judging)", text: "Evaluation (Judging)" },
        { value: "Synthesis (Creating)", text: "Synthesis (Creating)" }
      ],

      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      boards: [],
      bloomcount: [],
      dokcount: [],
      questiontype: [{ mc: "Multiple Choice" }],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$route.params.id + "/questions/"),
      gradetitle: this.$route.params.title
    };
  },
  created() {
    this.loadAllQuestions();
  },
  methods: {
    Edit(path, type, qtype) {
      this.$modal.show(
        EditQuestion,
        {
          path: path,
          order: this.order,
          source: "edit",
          qtype: qtype,
          week: this.$route.params.id
        },
        { width: "70%", height: "90vh" }
      );
    },

    deleteboard(id, title) {
      var school = this.$route.params.id.split("/")[1];
      var branch = this.$route.params.id.split("/")[3];
      var grade = this.$route.params.id.split("/")[5];
      var subject = this.$route.params.id.split("/")[7];

      this.$confirm({
        message:
          "Are you sure You want to Delete " + title.substr(0, 50) + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.id + "/questions/")
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  title,
                  this.$route.params.id,
                  "Question"
                );

                firebase
                  .firestore()
                  .collection(
                    "schools/" +
                      school +
                      "/branches/" +
                      branch +
                      "/grades/" +
                      grade +
                      "/subjects/" +
                      subject +
                      "/slink"
                  )
                  .where(
                    "source",
                    "==",
                    this.$route.params.id + "/questions/" + id
                  )
                  .get()
                  .then(links => {
                    links.forEach(link => {
                      firebase
                        .firestore()
                        .doc(link.ref.path)
                        .delete();
                    });
                  });

                this.$bvToast.toast("Question Deleted", {
                  title: "Question Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    details(id, title, grade) {
      router.push({
        name: "WeeksList",
        params: { id: id, title: title, grade: grade }
      });
    },
    options(path, title) {
      let routeData = this.$router.resolve({
        path: "/options",
        query: { id: path, title: title }
      });
      window.open(routeData.href, "_blank");
      // router.push({ name: "OptionsList", params: { id: path, title: title } });
    },
    removeHTML(text) {
      let regex = /(<([^>]+)>)/gi;

      return text.replace(regex, "");
    },

    loadAllQuestions() {
      this.level1 = 0;
      this.level2 = 0;
      this.level3 = 0;
      this.flippedCount = 0;
      this.exitCount = 0;

      this.ref.onSnapshot(querySnapshot => {
        this.boards = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.difficulty == 1) this.level1++;
          else if (data.difficulty == 2) this.level2++;
          else if (data.difficulty == 3) this.level3++;

          if (data.flipped == "Yes") this.flippedCount++;
          if (data.exit == "Yes") this.exitCount++;

          this.boards.push({
            key: doc.id,
            path: doc.ref.path,
            title: data.title,
            order: data.order,
            type: data.type,
            exam: data.exam,
            flipped: data.flipped,
            bloom: data.bloom,
            dok: data.dok,
            difficulty: data.difficulty,
            report: data.report,
            exit: data.exit,
            inclass: data.inclass,
            qtype: data.qtype,
            editorfirstname: data.editorfirstname ?? "",
            editorlastname: data.editorlastname ?? "",
            lastedit: data.lastedit
          });
        });

        //! Kamal Shalaby Bloom Counters
        this.bloom.forEach((blo, index) => {
          this.bloomcount = [];

          this.bloom[index]["count"] = this.boards.filter(
            o => o.bloom == blo.text
          ).length;
        });

        this.dok.forEach((doo, index) => {
          this.dokcount = [];

          this.dok[index]["count"] = this.boards.filter(
            o => o.dok == doo.text
          ).length;
        });
      });
    },
    filterExit() {
      this.ref.where("exit", "==", "Yes").onSnapshot(querySnapshot => {
        this.boards = [];
        this.level1 = 0;
        this.level2 = 0;
        this.level3 = 0;
        this.flippedCount = 0;
        this.exitCount = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.difficulty == 1) this.level1++;
          else if (data.difficulty == 2) this.level2++;
          else if (data.difficulty == 3) this.level3++;

          if (data.flipped == "Yes") this.flippedCount++;
          if (data.exit == "Yes") this.exitCount++;

          this.boards.push({
            key: doc.id,
            path: doc.ref.path,
            title: data.title,
            order: data.order,
            type: data.type,
            exam: data.exam,
            flipped: data.flipped,
            bloom: data.bloom,
            dok: data.dok,
            difficulty: data.difficulty,
            report: data.report,
            exit: data.exit,
            inclass: data.inclass,
            qtype: data.qtype,
            editorfirstname: data.editorfirstname ?? "",
            editorlastname: data.editorlastname ?? "",
            lastedit: data.lastedit
          });
        });
      });
    },
    filterFlipped() {
      this.ref.where("flipped", "==", "Yes").onSnapshot(querySnapshot => {
        this.boards = [];

        this.level1 = 0;
        this.level2 = 0;
        this.level3 = 0;
        this.flippedCount = 0;
        this.exitCount = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();

          if (data.difficulty == 1) this.level1++;
          else if (data.difficulty == 2) this.level2++;
          else if (data.difficulty == 3) this.level3++;

          if (data.flipped == "Yes") this.flippedCount++;
          if (data.exit == "Yes") this.exitCount++;

          this.boards.push({
            key: doc.id,
            path: doc.ref.path,
            title: data.title,
            order: data.order,
            type: data.type,
            exam: data.exam,
            flipped: data.flipped,
            bloom: data.bloom,
            dok: data.dok,
            difficulty: data.difficulty,
            report: data.report,
            exit: data.exit,
            inclass: data.inclass,
            qtype: data.qtype,
            editorfirstname: data.editorfirstname ?? "",
            editorlastname: data.editorlastname ?? "",
            lastedit: data.lastedit
          });
        });
      });
    },
    goToImport(path) {
      router.push({
        name: "ImportQuestions",
        params: { path: path }
      });
    }
  }
};
</script>
<style scoped>
.foo >>> img {
  width: 200px;
}
</style>
