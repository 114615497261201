<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined
        ><v-card-text>
          <iframe
            width="100%"
            height="5500"
            src="https://datastudio.google.com/embed/reporting/ceec84a6-f7d6-41c8-b256-69f1bfeec345/page/C10HC"
            frameborder="0"
            style="border:0"
            allowfullscreen
          ></iframe> </v-card-text
      ></v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Test-page",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {}
};
</script>
