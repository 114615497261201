<template>
  <v-row>
    <v-col cols="12">
      <v-card height="1000">
        <v-card-title> Zoom Report</v-card-title>
        <v-card-text>
          <v-row
            ><v-col cols="6">
              <v-card
                ><v-card-text>
                  <v-row
                    ><v-col cols="6">
                      <v-switch label="Managment"></v-switch>
                    </v-col>
                    <v-col cols="6">
                      <v-switch
                        label="Academic"
                        color="green"
                      ></v-switch> </v-col
                  ></v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card dark flat style="background-color:#006598;">
                <v-slider
                  v-model="value"
                  :tick-labels="ticksLabels"
                  :max="4"
                  step="1"
                  ticks="always"
                  tick-size="5"
                  style="cursor: pointer;"
                ></v-slider>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ZoomReport",
  data() {
    return {
      ticksLabels: ["Campuses", "Branches", "Grades", "Classes", "Students"]
    };
  }
};
</script>
