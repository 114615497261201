<template>
  <div>
    <v-chip color="red" v-if="this.grade == 0">{{ this.grade }} </v-chip>
    <v-chip color="success" v-else>{{ this.grade }} </v-chip>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["student", "week"],
  data() {
    return {
      grade: 0
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.student + "/participation/" + this.week)
      .onSnapshot(snapshot => {
        this.grade = 0;
        this.grade = snapshot.data().grade;
      });
  }
};
</script>
