<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-subtitle
          >{{ this.$store.state.reportbranch }} -
          {{ this.$store.state.reportgrade }}</v-card-subtitle
        >
        <v-card-title> {{ this.$store.state.reporttitle }}</v-card-title>
        <v-card-text>
          <v-card
            flat
            height="100vh"
            v-show="weeks.length == 0"
            style=" position: relative;"
          >
            <h6
              style="position: absolute; top: 20%; left: 40%; margin: -25px 0 0 -25px; text-align:center;"
            >
              <img src="@/assets/bgif.gif" width="400px" />
              <br />
              Please wait while report is being generated
            </h6>
          </v-card>

          <div v-show="weeks.length > 0">
            <v-row style=" margin-bottom:20px;">
              <v-col cols="4">
                <v-card
                  style="background-color:#d7e9f9;"
                  flat
                  outlined
                  class="ma-5"
                >
                  <v-card-subtitle> Total Weeks</v-card-subtitle>
                  <v-card-text style="font-size:30px">
                    <img
                      src="@/assets/resources.png"
                      width="80px"
                      style="margin-right:20px; margin-left:20px;"
                    />
                    {{ weeks.length }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  style="background-color:#d7e9f9;"
                  flat
                  outlined
                  class="ma-5"
                >
                  <v-card-subtitle>General Questions</v-card-subtitle>
                  <v-card-text style="font-size:30px">
                    <img
                      src="@/assets/questions.png"
                      width="80px"
                      style="margin-right:20px; margin-left:20px;"
                    />
                    {{ this.questions }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  style="background-color:#d7e9f9;"
                  flat
                  outlined
                  class="ma-5"
                >
                  <v-card-subtitle>Live Sessions</v-card-subtitle>
                  <v-card-text style="font-size:30px">
                    <img
                      src="@/assets/live.png"
                      width="80px"
                      style="margin-right:20px; margin-left:20px;"
                    />
                    {{ this.sessions }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <Week
              v-for="(week, index) in weeks"
              :key="week.key"
              :path="week.path"
              :title="week.title"
              :order="index"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import Week from "./Week";
// import router from "@/router";

export default {
  name: "SubjectReport",
  components: { Week },
  props: ["path"],
  data() {
    return {
      weeks: [],
      questions: 0,
      sessions: 0,
      subjectpath: ""
    };
  },
  created() {
    if (
      this.$store.state.reportmirror == null ||
      this.$store.state.reportmirror == "" ||
      this.$store.state.reportmirror == "undefined"
    ) {
      this.subjectpath = this.$store.state.reportpath;
    } else {
      this.subjectpath = this.$store.state.reportmirror;
    }

    firebase
      .firestore()
      .collection(this.subjectpath + "/weeks")
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        this.weeks = [];
        querySnapshot.forEach(doc => {
          this.weeks.push({
            key: doc.id,
            path: doc.ref.path,
            section: doc.data().section,
            title: doc.data().title,
            term: doc.data().term,
            order: doc.data().order,
            date: doc.data().date,
            standard: doc.data().standard,
            text: doc.data().title,
            revision: doc.data().revision,
            hidden: doc.data().hidden,
            noblended: doc.data().noblended,
            breakdown: doc.data().breakdown,
            value: doc.data().title
          });
        });
      });

    firebase
      .firestore()
      .collection(this.subjectpath + "/questions")
      .get()
      .then(snapshot => (this.questions = snapshot.size));

    firebase
      .firestore()
      .collection(this.subjectpath + "/sessions")
      .get()
      .then(snapshot => (this.sessions = snapshot.size));
  }
};
</script>
