<template>
  <b-col cols="12">
    <v-card>
      <v-card-title>
        Schools
      </v-card-title>
      <v-card-text>
        <v-card>
          <p class="card-text">
            <AddSchool></AddSchool>
          </p>
        </v-card>
        <br />
        <v-card>
          <v-card-title>
            <v-icon large left>mdi-bank</v-icon>
            <span class="title font-weight-light">Schools List</span>
          </v-card-title>

          <p class="card-text">
            <vue-good-table
              :columns="fields"
              :rows="branches"
              :search-options="{ enabled: true, skipDiacritics: true }"
              :pagination-options="{ enabled: true, mode: 'records' }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="btn btn-info btn-sm"
                    @click="editschool(props.row.path, props.row.title)"
                  >
                    <v-icon>mdi-pen</v-icon>&nbsp; Edit
                  </button>
                  &nbsp;
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteboard(props.row.key, props.row.title)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>&nbsp; Delete
                  </button>
                </span>
                <span v-else-if="props.column.field == 'grades'">
                  <button
                    class="btn btn-success btn-sm"
                    @click="goToGrades(props.row.path, props.row.title)"
                  >
                    <v-icon>mdi-call-split</v-icon>&nbsp; Grade Levels
                  </button>
                  <button
                    style="margin-left:10px;"
                    class="btn btn-info btn-sm"
                    @click="goToBreak(props.row.path, props.row.title)"
                  >
                    <v-icon>mdi-folder</v-icon>&nbsp; Breakdowns
                  </button>
                </span>
              </template>
            </vue-good-table>
          </p>
        </v-card>
      </v-card-text>
    </v-card>
  </b-col>
</template>

<script>
import firebase from "@/Firebase";
import AddSchool from "./AddSchool";
import router from "@/router";

export default {
  name: "SchoolsList",
  components: {
    AddSchool
  },

  data() {
    return {
      fields: [
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        { field: "grades", label: "Grades", class: "text-center" },
        { field: "actions", label: "Action", class: "text-center" }
      ],
      branches: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/branches")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.branches = [];
      querySnapshot.forEach(doc => {
        this.branches.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          location: doc.data().location
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/branches")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast(title + " Deleted", {
                  title: "School Deleted",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    goToGrades(path, school) {
      router.push({
        name: "GradesList",
        params: { path: path, school: school }
      });
    },
    goToBreak(path, branch) {
      router.push({
        name: "Breakdowns",
        params: { path: path, branch: branch }
      });
    },
    editschool(path, school) {
      router.push({
        name: "EditSchool",
        params: { path: path, school: school }
      });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
