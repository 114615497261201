<template>
  <div>
    <v-card-title
      >Term 3

      <v-divider />
      <!-- {{ this.$store.state.gradebook }} -->
      <span style="font-size:14px; margin-right:10px;">Total Grade</span>
      <v-chip dark label large color="red">
        <h3>{{ this.total.toFixed(2) }}%</h3></v-chip
      >
    </v-card-title>
  </div>
</template>
<script>
// import firebase from "@/Firebase";

export default {
  name: "total--page",
  components: {},
  props: ["title", "weight", "subject", "color", "fadecolor", "current"],

  data() {
    return {
      total: 0,
      gradebook: this.$store.state.gradebook,
      subjecttitle: this.$store.state.subjecttitle
    };
  },
  created() {
    this.total = 0;
    this.$store.state.gradebook.forEach(element => {
      this.total += (element.grade * element.weight) / 100;
    });
  }
};
</script>
