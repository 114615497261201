var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-group',{attrs:{"deck":""}},[_c('CountCard',{attrs:{"title":"Breakdown","color":"primary","path":'/schools/' +
            this.$store.state.school +
            '/branches/' +
            this.$route.params.branch +
            '/breakdown'}}),_c('CountCard',{attrs:{"title":"Active","color":"success"}}),_c('CountCard',{attrs:{"title":"InActive","color":"danger"}})],1),_c('br'),_c('v-card',[_c('v-card-text',[_c('div',[_c('CRUD',{attrs:{"title":"Breakdown","path":'/schools/' +
                this.$store.state.school +
                '/branches/' +
                this.$route.params.branch +
                '/breakdown',"fields":this.fields,"width":"500","height":"400"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }