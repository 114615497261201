<template>
  <div>
    <v-list-item style="background-color:#eee">
      <v-list-item-avatar>
        <img :src="this.image" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <small>{{
            this.firstname + " " + this.lastname
          }}</small></v-list-item-title
        >
        <v-list-item-subtitle>
          <small>{{
            this.grade + " - " + this.sclass
          }}</small></v-list-item-subtitle
        >
        <small>{{ this.category }}</small>

        <small> </small>
      </v-list-item-content>
      <v-list-item-icon>
        <slot></slot>
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Chat-page",
  props: ["userid", "type", "category", "id", "chatpath"],
  data() {
    return {
      user: [],
      firstname: "",
      lastname: "",
      image: "",
      grade: "",
      branch: "",
      sclass: ""
    };
  },
  methods: {},
  created() {
    //if (this.category == "student") {
    firebase
      .firestore()
      .collection("schools/nisStudentsData/")
      .doc(this.userid)
      .get()
      .then(snapshot => {
        let user = snapshot.data();
        this.firstname = user.firstname;
        this.lastname = user.lastname;
        this.image =
          user.image == null
            ? "https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/icons%2Fstudent.png?alt=media&token=fbe8c119-2855-4034-a7e1-834fcfcd59e3"
            : user.image;
        this.grade = user.grade;
        this.branch = user.branch;
        this.sclass = user.class;
      });
  }
  //}
};
</script>
