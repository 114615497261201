<template>
  <div>
    <v-chip color="#7B1FA2" small label dark
      >Type: {{ this.question.qtype.toUpperCase() }}</v-chip
    >

    <br />
    <v-card flat outlined style="margin-top:20px">
      <v-card-text>
        <div v-html="this.question.title" class="foo" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "QuestionPreview",
  props: ["path", "title", "answer"],
  data() {
    return { dialog: false, question: {} };
  },
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) return;
        this.question = snapshot.data();
      });
  }
};
</script>
<style scoped>
.foo >>> img {
  width: 400px;
}
</style>
