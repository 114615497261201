<template>
  <div>
    <small color="blue"> {{ this.fvalue }}</small>
    <v-autocomplete
      @change="onClickButton()"
      v-model.trim="selected"
      :items="options"
      :label="this.title"
    ></v-autocomplete>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "AddNew",
  props: ["path", "title", "fvalue", "lookup", "id"],
  data() {
    return {
      ref: firebase.firestore().collection(this.path),
      options: [],
      selected: this.fvalue
    };
  },
  methods: {
    onClickButton() {
      this.$emit("input", this.selected);
    }
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.options = [];
      querySnapshot.forEach(doc => {
        this.options.push({
          text: doc.data()[this.lookup],
          value: [doc.data()[this.lookup], doc.data()[this.id]]
        });
      });
    });
  },
  mounted() {
    this.$nextTick(function() {
      console.log(this.selected);
    });
  }
};
</script>
