var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"margin-top":"20px"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"danger","text-variant":"white","header":"Total Fees"}},[_c('b-card-text',[_c('h1',[_vm._v(_vm._s(this.fees))])])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"success","text-variant":"white","header":"Total Payments"}},[_c('b-card-text',[_c('h1',[_vm._v(_vm._s(this.payments))])])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"info","text-variant":"white","header":"Balance"}},[_c('b-card-text',[_c('h1',[_vm._v(_vm._s(this.fees - this.payments))])])],1)],1)],1),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],attrs:{"block":"","variant":"info"}},[_vm._v("Show Student Information")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"accordion-1"}},[_c('ShowStudent',{attrs:{"path":this.studentid}})],1),_c('CRUD',{attrs:{"title":"Fee","path":'/schools/' +
          this.$store.state.school +
          '/StudentsData/' +
          this.studentid +
          '/fees',"fields":this.feesfields,"width":"500","height":"600"}}),_c('v-divider'),_c('CRUD',{attrs:{"title":"Payment","path":'/schools/' +
          this.$store.state.school +
          '/StudentsData/' +
          this.studentid +
          '/payments',"fields":this.payfields,"width":"500","height":"600"}}),_c('v-divider'),_c('CRUD',{attrs:{"title":"Discount","path":'/schools/' +
          this.$store.state.school +
          '/StudentsData/' +
          this.studentid +
          '/discounts',"fields":this.discountsfields,"width":"500","height":"400"}}),_c('v-divider'),_c('CRUD',{attrs:{"title":"Comment","path":'/schools/' +
          this.$store.state.school +
          '/StudentsData/' +
          this.studentid +
          '/comments',"fields":this.commfields,"width":"500","height":"400"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }