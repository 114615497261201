<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          {{ gradetitle }} Resources

          <v-divider></v-divider>
        </v-card-title>

        <v-card-text>
          <!-- Activities Hub -->
          <VideosHub
            :path="this.weekpath"
            order="1"
            v-if="master == true"
          ></VideosHub>
          <!-- End Activities Hub -->
          &nbsp;
          <!-- Questions Hub -->
          <QuestionsHub
            :path="this.weekpath"
            order="1"
            source="lesson"
            v-if="master == true"
          ></QuestionsHub>
          <!-- End Questions Hub -->

          <br />
          <!-- School Videos-->
          <v-card style="margin-top:20px" flat outlined dark>
            <v-card-title
              >{{ this.school }} Videos

              <v-divider /><v-btn
                color="green"
                depressed
                x-small
                @click="video"
                v-if="master == true"
                >+ Add New Video</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-card
                v-for="n in videos"
                :key="n.key"
                flat
                outlined
                shaped
                light
                style="margin-bottom:20px"
              >
                <v-card-title>
                  <v-icon>mdi-youtube</v-icon> {{ n.title }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" v-if="n.link">
                      <iframe
                        v-if="n.link.includes('vimeo')"
                        :src="n.link"
                        width="100%"
                        height="300px"
                        style="border:0"
                        allowfullscreen
                      ></iframe>
                      <video-embed :src="n.link" v-else></video-embed>
                    </v-col>
                    <v-col cols="6">
                      <v-card flat outlined>
                        <v-card-title>Video Description</v-card-title>
                        <v-card-text>
                          <div v-html="n.description" />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-divider />
                  <v-btn
                    style="margin-left:20px"
                    color="red"
                    dark
                    depressed
                    x-small
                    @click="deletevideo(n.key)"
                    v-if="master == true"
                  >
                    <v-icon style="cursor: pointer;" size="14"
                      >mdi-close</v-icon
                    >
                    Delete Video</v-btn
                  >

                  <v-btn
                    style="margin-left:20px"
                    color="info"
                    dark
                    depressed
                    x-small
                    @click="EditVideo(n.path)"
                    v-if="master == true"
                  >
                    <v-icon style="cursor: pointer;" size="14"
                      >mdi-pencil-outline</v-icon
                    >
                    Edit Video</v-btn
                  >
                  <v-btn
                    style="margin-left:20px"
                    v-if="master == true"
                    color="success"
                    dark
                    depressed
                    x-small
                    @click="importS(n.title, n.link)"
                  >
                    <v-icon style="cursor: pointer;" size="14">mdi-cog</v-icon>
                    Copy Video to Activities</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
          <!-- Internet Videos-->
          <v-card style="margin-top:20px" dark flat outlined>
            <v-card-title>
              Internet Videos <v-divider />
              <v-btn
                color="green"
                dark
                x-small
                @click="internetvideo"
                v-if="master == true"
                >+ Add New Internet Video</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-card
                v-for="n in internet"
                :key="n.key"
                flat
                outlined
                shaped
                light
                style="margin-bottom:20px"
              >
                <v-card-title>
                  <v-icon>mdi-youtube</v-icon> {{ n.title }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" v-if="n.link">
                      <iframe
                        v-if="n.link.includes('vimeo')"
                        :src="n.link"
                        width="100%"
                        height="300px"
                        style="border:0"
                        allowfullscreen
                      ></iframe>
                      <video-embed :src="n.link" v-else></video-embed>
                    </v-col>
                    <v-col cols="6">
                      <v-card flat outlined>
                        <v-card-title>Video Description</v-card-title>
                        <v-card-text>
                          <div v-html="n.description" />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-divider />

                  <v-btn
                    style="margin-left:20px"
                    color="red"
                    dark
                    depressed
                    x-small
                    @click="deleteInternet(n.key)"
                    v-if="master == true"
                  >
                    <v-icon style="cursor: pointer;" size="14"
                      >mdi-close</v-icon
                    >
                    Delete Video</v-btn
                  >

                  <v-btn
                    style="margin-left:20px"
                    color="info"
                    dark
                    depressed
                    x-small
                    @click="EditVideo(n.path)"
                    v-if="master == true"
                  >
                    <v-icon style="cursor: pointer;" size="14"
                      >mdi-pencil-outline</v-icon
                    >
                    Edit Video</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
          <!-- Sheets-->
          <v-card style="margin-top:20px">
            <v-card-text>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    Files
                  </div>
                  <v-list-item-title class="headline mb-1">
                    Lesson Documents
                    <v-divider />
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn
                      color="green"
                      dark
                      x-small
                      @click="sheet"
                      v-if="master == true"
                      >+ Add New Lesson Documents</v-btn
                    >
                  </v-list-item-subtitle>
                  <ul style="margin-top:20px; list-style-type: none;">
                    <li v-for="sheet in sheets" :key="sheet.key">
                      <v-btn
                        color="red"
                        style="margin-left:10px"
                        dark
                        x-small
                        @click="deleteSheet(sheet.key)"
                        v-if="master == true"
                        >- Delete File</v-btn
                      >
                      <a :href="sheet.link" target="_blank">
                        <v-icon color="blue">mdi-file-powerpoint</v-icon>
                        {{ sheet.title }}</a
                      >
                    </li>
                  </ul>
                </v-list-item-content>

                <v-list-item-avatar tile size="80"
                  ><img src="@/assets/powerpoint.png" width="100px"
                /></v-list-item-avatar>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import VideosHub from "@/components/hub/VideosHub";
import QuestionsHub from "@/components/hub/QuestionsHub";
import EditVideo from "@/components/lms/activities/Create/Video.vue";
import CreateVideo from "@/components/lms/activities/Create/Video.vue";
import CreateSheet from "@/components/lms/activities/Create/Sheet.vue";

export default {
  name: "ActivitiesList",
  components: {
    VideosHub,
    QuestionsHub
  },
  data() {
    return {
      weekpath: localStorage.getItem("weekpath"),
      school: localStorage.getItem("schoolName"),
      master: this.$store.state.master,
      dragData: {},
      ldialog: false,
      thisOrder: 0,

      newActivity: {},
      grade: this.weekpath,
      activities: [],

      videos: [],
      internet: [],
      sheets: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(localStorage.getItem("weekpath") + "/videos"),
      refInternet: firebase
        .firestore()
        .collection(localStorage.getItem("weekpath") + "/internetvideos/"),
      refSheets: firebase
        .firestore()
        .collection(localStorage.getItem("weekpath") + "/sheets/"),
      gradetitle: localStorage.getItem("weektitle")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.videos = [];
      querySnapshot.forEach(doc => {
        this.videos.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          link: doc.data().link,
          section: doc.data().section,
          description: doc.data().description
        });
      });
    });
    this.refInternet.onSnapshot(querySnapshot => {
      this.internet = [];
      querySnapshot.forEach(doc => {
        this.internet.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          link: doc.data().link,
          description: doc.data().description
        });
      });
    });
    this.refSheets.onSnapshot(querySnapshot => {
      this.sheets = [];
      querySnapshot.forEach(doc => {
        this.sheets.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          link: doc.data().link
        });
      });
    });
  },
  methods: {
    video() {
      this.$modal.show(
        CreateVideo,
        {
          path: String(this.weekpath) + "/videos",
          order: this.order
        },
        { width: "50%", height: "750px" }
      );
    },
    internetvideo() {
      this.$modal.show(
        CreateVideo,
        {
          path: String(this.weekpath) + "/internetvideos",
          order: this.order
        },
        { width: "50%", height: "750px" }
      );
    },
    sheet() {
      this.$modal.show(
        CreateSheet,
        {
          path: String(this.weekpath) + "/sheets",
          order: this.order
        },
        { width: "50%", height: "600px" }
      );
    },

    removeHTML(text) {
      let regex = /(<([^>]+)>)/gi;

      return text.replace(regex, "");
    },
    deletevideo(id) {
      this.$confirm({
        message: "Are you sure You want to Delete this Activity ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.weekpath + "/videos/")
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  id,
                  this.$store.state.subject,
                  "Activity"
                );

                this.$bvToast.toast("Activity Deleted", {
                  title: "Activity Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    deleteInternet(id) {
      this.$confirm({
        message: "Are you sure You want to Delete this Activity ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.weekpath + "/internetvideos/")
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  id,
                  this.$store.state.subject,
                  "Activity"
                );

                this.$bvToast.toast("Activity Deleted", {
                  title: "Activity Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    deleteSheet(id) {
      this.$confirm({
        message: "Are you sure You want to Delete this Activity ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.weekpath + "/sheets/")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Activity Deleted", {
                  title: "Activity Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    options(path, title) {
      router.push({
        name: "OptionsList",
        params: {
          id: path,
          title: title
        }
      });
    },

    EditVideo(path) {
      this.$modal.show(
        EditVideo,
        {
          path: path,
          order: this.order,
          source: "edit"
        },
        { width: "50%", height: "750px" }
      );
    },
    goUp(path, order) {
      firebase
        .firestore()
        .doc(path)
        .get()
        .then(snapshot => {
          let act = snapshot.data();

          act.order = order - 1;

          firebase
            .firestore()
            .doc(path)
            .set(act)
            .then(() => {})
            .catch(error => {
              alert("Error adding document: ", error);
            });
        });
    },

    goDown(path, order) {
      firebase
        .firestore()
        .doc(path)
        .get()
        .then(snapshot => {
          let act = snapshot.data();

          act.order = order + 1;

          firebase
            .firestore()
            .doc(path)
            .set(act)
            .then(() => {})
            .catch(error => {
              alert("Error adding document: ", error);
            });
        });
    },

    importS(title, link) {
      this.newActivity = {};
      this.newActivity.order = 1;
      this.newActivity.type = "video";
      this.newActivity.title = title;
      this.newActivity.link = link;

      firebase
        .firestore()
        .collection(this.weekpath + "/lesson/")
        .add(this.newActivity)
        .then(() => {
          // this.AddLog(
          //   this.$store.state.username,
          //   this.$store.state.firstname,
          //   this.$store.state.lastname,
          //   "Import",
          //   this.newActivity,
          //   this.path,
          //   "Vidoe"
          // );

          this.$bvToast.toast("Video Transferred", {
            title: "Video Transferred Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
