<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>Guided Practice</v-card-title>

        <v-card-subtitle>{{ this.week }}</v-card-subtitle>

        <v-card-text>
          <b-row>
            <b-col cols="4" style="background-color:#eee">
              <v-toolbar color="#fdcd06" flat>
                <v-toolbar-title>
                  <img
                    src="@/assets/students.png"
                    class=" ma-2"
                    width="44px"
                  />&nbsp;Classes List
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-btn
                @click="clearSearch"
                x-small
                color="#a0c633"
                style="margin-bottom:10px;"
                block
                dark
                depressed
                >Clear Search</v-btn
              >

              <v-card v-for="c in classList" :key="c" flat outlined>
                <v-card-text @click="loadClass(c)" style="cursor:pointer;">
                  <h5>{{ c }}</h5>

                  <v-btn
                    small
                    depressed
                    color="green"
                    block
                    dark
                    @click="classclose(c, 'inclass')"
                    v-if="lesson[c + branch + 'inclass']"
                    >Close Guided Practice</v-btn
                  >
                  <v-btn
                    small
                    depressed
                    color="red"
                    block
                    dark
                    @click="classopen(c, 'inclass')"
                    v-else
                    >Open Guided Practice</v-btn
                  >
                </v-card-text>
              </v-card>
            </b-col>
            <b-col cols="8">
              <p v-for="(student, i) in students" :key="i">
                <Student
                  :key="i"
                  :fname="student.firstname"
                  :mname="student.middlename"
                  :lname="student.lastname"
                  :path="student.path"
                  :week="weekpath"
                  :studentid="student.key"
                />
              </p>
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import Student from "./Student";

export default {
  name: "Control-page",
  props: ["path", "grade"],
  components: { Student },
  data() {
    return {
      menu2: false,
      cname: "",
      date: new Date().toISOString().substr(0, 10),
      week: localStorage.getItem("week"),
      weekpath: localStorage.getItem("weekpath"),
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "student",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center" }
      ],
      classes: [],
      lesson: {},
      classList: JSON.parse(localStorage.getItem("classList")),
      gradePath: this.$store.state.gradePath,
      branch: this.$store.state.thisBranch,
      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.gradePath + "/classes")
    };
  },
  created() {
    //Get Lesson
    firebase
      .firestore()
      .doc(this.weekpath)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) return;
        this.lesson = snapshot.data();
      });
    // this.ref.onSnapshot(querySnapshot => {
    //   this.classes = [];
    //   querySnapshot.forEach(doc => {
    //     this.classes.push({
    //       key: doc.id,
    //       path: doc.ref.path,
    //       title: doc.data().title,
    //       flipped: doc.data().fliped
    //     });
    //   });
    // });

    // firebase
    //   .firestore()
    //   .collection("schools/" + this.$store.state.school + "/students")
    //   .where("educationPath", "==", this.gradePath)
    //   .onSnapshot(querySnapshot => {
    //     querySnapshot.forEach(doc => {
    //       this.students.push({
    //         key: doc.id,
    //         path: doc.ref.path,
    //         firstname: doc.data().firstname,
    //         middlename: doc.data().middlename,
    //         lastname: doc.data().lastname,
    //         class: doc.data().class
    //       });
    //     });
    //   });
  },
  methods: {
    openflipped() {
      this.$confirm({
        message: "Are you sure You want to Open This Lesson Flipped",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.weekpath)
              .update({ flipped: true })
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    closeflipped() {
      this.$confirm({
        message: "Are you sure You want to Close This Lesson Flipped",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.weekpath)
              .update({ flipped: false })
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    //Classe Manage Flipped
    classopen(c, type) {
      this.cname = c + this.branch + type;
      this.record[this.cname] = true;

      this.$confirm({
        message: "Are you sure You want to Open This Lesson " + type,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.weekpath)
              .update(this.record)
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    classclose(c, type) {
      this.cname = c + this.branch + type;
      this.record[this.cname] = false;

      this.$confirm({
        message: "Are you sure You want to Close This Lesson " + type,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.weekpath)
              .update(this.record)
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    //End Mannage Flipped
    openexit() {
      this.$confirm({
        message: "Are you sure You want to Open This Lesson Exit",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.$route.params.path)
              .update({ exit: true })
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    closeexit() {
      this.$confirm({
        message: "Are you sure You want to Close This Lesson Exit",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.$route.params.path)
              .update({ exit: false })
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    },

    goToGrades(student, studentName) {
      router.push({
        name: "Gradebook",
        params: { student: student, sname: studentName }
      });
    },
    clearSearch() {
      this.students = [];
      // firebase
      //   .firestore()
      //   .collection("schools/" + this.$store.state.school + "/students")
      //   .where("educationPath", "==", this.gradePath)
      //   .onSnapshot(querySnapshot => {
      //     querySnapshot.forEach(doc => {
      //       this.students.push({
      //         key: doc.id,
      //         path: doc.ref.path,
      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         lastname: doc.data().lastname,
      //         class: doc.data().class
      //       });
      //     });
      //   });
    }
  }
};
</script>
