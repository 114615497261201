<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined
        ><v-card-text>
          <iframe
            width="100%"
            height="4000"
            src="https://datastudio.google.com/embed/reporting/bc1a1182-78c7-4ee2-9e04-d0bd9dbcf6ab/page/CEFIC"
            frameborder="0"
            style="border:0"
            allowfullscreen
          ></iframe> </v-card-text
      ></v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Test-page",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {}
};
</script>
