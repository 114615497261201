<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Exam Invigilator</v-card-title>
        <v-card-subtitle>{{ this.$route.params.title }}</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn color="success" @click="openexam">Open Exam For All</v-btn>
              <v-btn
                color="red"
                dark
                style="margin-left:20px;"
                @click="closeexam"
                >Close Exam For All</v-btn
              >
            </v-col>
          </v-row>
          <b-row>
            <b-col cols="3" style="background-color:#eee">
              <v-toolbar dark flat>
                <v-toolbar-title>
                  <v-icon size="30px">mdi-account-tie-outline</v-icon
                  >&nbsp;Classes List
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-btn
                @click="clearSearch"
                x-small
                color="#66BB6A"
                block
                dark
                depressed
                >Clear Search</v-btn
              >

              <v-list
                shaped
                color="light-blue darken-3"
                dark
                style="margin-top:20px"
              >
                <v-list-item
                  v-for="c in classList"
                  :key="c"
                  href="#"
                  @click="loadClass(c)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-google-classroom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ c }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </b-col>
            <b-col cols="9">
              <p v-for="student in students" :key="student.key">
                <v-card
                  ><v-card-text>
                    <v-badge
                      bordered
                      color="success"
                      icon="mdi-check-all"
                      overlap
                    >
                      <v-avatar color="grey" size="50">
                        <span class="white--text headline">{{
                          student.firstname[0] + student.lastname[0]
                        }}</span>
                      </v-avatar>
                    </v-badge>

                    {{
                      student.firstname +
                        " " +
                        student.middlename +
                        " " +
                        student.lastname
                    }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="red" dark small>Close Exam</v-btn>
                    <v-btn color="success" dark small>Reopen Exam</v-btn>
                  </v-card-actions>
                </v-card>
              </p>

              <!-- <Attemp
                v-for="student in students"
                :key="student.key"
                style="margin-bottom:10px"
                :student="
                  student.firstname +
                    ' ' +
                    student.middlename +
                    ' ' +
                    student.lastname
                "
                :id="student.key"
                :assignment="$route.params.path"
              /> -->
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "Control-page",
  props: ["path", "grade"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "student",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center" }
      ],
      classes: [],
      classList: JSON.parse(localStorage.getItem("classList")),
      gradePath: this.$store.state.gradePath,
      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.gradePath + "/classes")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.classes = [];
      querySnapshot.forEach(doc => {
        this.classes.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title
        });
      });
    });
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/StudentsData")
      .where("educationPath", "==", this.gradePath)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.students.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            lastname: doc.data().lastname,
            class: doc.data().class
          });
        });
      });
  },
  methods: {
    openexam() {
      this.$confirm({
        message: "Are you sure You want to Open This Exam",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.$route.params.path)
              .update({ open: true })
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    closeexam() {
      this.$confirm({
        message: "Are you sure You want to Close This Exam",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.$route.params.path)
              .update({ open: false })
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    },

    goToGrades(student, studentName) {
      router.push({
        name: "Gradebook",
        params: { student: student, sname: studentName }
      });
    },
    clearSearch() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    }
  }
};
</script>
