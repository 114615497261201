<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img src="@/assets/accounting.png" class=" ma-2" width="44px" />
          <span class="title font-weight-light">Accounting Department</span>

          <v-row align="center" justify="end">
            <download-excel
              class="btn btn-default"
              :data="students"
              type="csv"
              name="accounting.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
              <small>Export to Excel</small>
            </download-excel>
          </v-row>
        </v-card-title>

        <v-card-text>
          <b-card-group deck>
            <v-row>
              <div class="col-4">
                <v-card flat dark color="green">
                  <v-card-text>
                    <div class="row">
                      <div class="col-3">
                        <div class="icon-big text-center">
                          <v-icon size="100"
                            >mdi-clipboard-account-outline</v-icon
                          >
                        </div>
                      </div>
                      <div class="col-7">
                        <div class="numbers">
                          <p class="card-category">Total Students</p>
                          <h4 style="color:#ffffff; font-size:40px;">
                            {{ students.length }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="col-4">
                <SettingCard
                  title="Fees Options"
                  icon="mdi-badge-account-horizontal-outline"
                  link="FeesType"
                ></SettingCard>
              </div>
              <div class="col-4">
                <SettingCard
                  title="Payments Options"
                  icon="mdi-contacts"
                  link="PaymentsType"
                ></SettingCard>
              </div>
            </v-row>
          </b-card-group>

          <v-card flat outlined>
            <v-card-text>
              <v-card style="background-color:#d7e9f9" flat outlined>
                <v-card-text>
                  <!-- School -->
                  <v-row>
                    <v-col class="d-flex" cols="4" sm="4">
                      <v-select
                        :items="schools"
                        v-model="student.school"
                        label="School"
                        @change="onSchoolChange()"
                      ></v-select>
                    </v-col>
                    <!-- Grade Level -->

                    <v-col class="d-flex" cols="4" sm="4">
                      <v-select
                        :items="level"
                        v-model="student.grade"
                        label="Grade Level"
                        @change="onGradeChange()"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="4" sm="4">
                      <v-select
                        :items="classes"
                        v-model="student.class"
                        label="Class"
                        @change="onClassChange()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer
                  ><v-btn color="success" small depressed @click="clearSearch"
                    >Clear Search</v-btn
                  ></v-card-actions
                >
              </v-card>
              <br />

              <vue-good-table
                :columns="fields"
                :rows="students"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'fees'">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="
                        goToFees(
                          props.row.key,
                          props.row.grade,
                          props.row.firstname +
                            ' ' +
                            props.row.middlename +
                            ' ' +
                            props.row.lastname,
                          props.row.branch,

                          props.row.class
                        )
                      "
                    >
                      <b-icon icon="credit-card"></b-icon>&nbsp; Collection
                    </button>
                  </span>
                </template>
              </vue-good-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import SettingCard from "@/components/dna/widgets/SettingCard";

export default {
  name: "Accounting-page",
  components: { SettingCard },

  data() {
    return {
      fields: [
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },

        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "fees",
          label: "",
          sortable: true,
          class: "text-left"
        }
      ],
      student: [],
      schools: [],
      level: [],
      classes: [],
      grades: [],
      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData"),
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  created() {
    this.$store.commit("master", true);
    this.ref.get().then(querySnapshot => {
      this.students = [];
      querySnapshot.forEach(doc => {
        this.students.push({
          key: doc.id,
          path: doc.ref.path,
          grade: doc.data().grade,
          branch: doc.data().branch,
          class: doc.data().class,
          nationalID: doc.data().nationalID,
          father_nationalID: doc.data().father_nationalID,
          mother_nationalID: doc.data().mother_nationalID,
          firstname: doc.data().firstname,
          middlename: doc.data().middlename,
          lastname: doc.data().lastname,
          studentname:
            doc.data().firstname +
            " " +
            doc.data().middlename +
            " " +
            doc.data().lastname
        });
      });
    });

    this.schoolsRef
      .where("active", "==", true)
      .get()
      .then(querySnapshot => {
        this.schools = [];
        querySnapshot.forEach(doc => {
          this.schools.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("profiles")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    clearSearch() {
      this.students = [];
      this.ref.get().then(querySnapshot => {
        this.students = [];
        querySnapshot.forEach(doc => {
          this.students.push({
            key: doc.id,
            path: doc.ref.path,
            grade: doc.data().grade,
            branch: doc.data().branch,
            class: doc.data().class,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            nationalID: doc.data().nationalID,
            father_nationalID: doc.data().father_nationalID,
            mother_nationalID: doc.data().mother_nationalID,
            lastname: doc.data().lastname,
            studentname:
              doc.data().firstname +
              " " +
              doc.data().middlename +
              " " +
              doc.data().lastname,
            username: doc.data().username,
            password: doc.data().password
          });
        });
      });
    },
    onGradeChange() {
      this.classes = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/" +
            this.student.grade +
            "/classes"
        )
        .get()
        .then(querySnapshot => {
          this.classes = [];
          querySnapshot.forEach(doc => {
            this.classes.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .get()
        .then(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              grade: doc.data().grade,
              branch: doc.data().branch,
              class: doc.data().class,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              nationalID: doc.data().nationalID,
              father_nationalID: doc.data().father_nationalID,
              mother_nationalID: doc.data().mother_nationalID,
              lastname: doc.data().lastname,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              username: doc.data().username,
              password: doc.data().password
            });
          });
        });
    },
    onClassChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .where("class", "==", this.student.class)
        .get()
        .then(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              grade: doc.data().grade,
              class: doc.data().class,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              nationalID: doc.data().nationalID,
              father_nationalID: doc.data().father_nationalID,
              mother_nationalID: doc.data().mother_nationalID,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              username: doc.data().username,
              password: doc.data().password
            });
          });
        });
    },

    onSchoolChange() {
      this.level = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/"
        )
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });

      // firebase
      //   .firestore()
      //   .collection("schools/" + this.$store.state.school + "/students")
      //   .where("branch", "==", this.student.school)
      //   .get().then(querySnapshot => {
      //     this.students = [];
      //     querySnapshot.forEach(doc => {
      //       this.students.push({
      //         key: doc.id,
      //         path: doc.ref.path,
      //         grade: doc.data().grade,
      //         branch: doc.data().branch,
      //         class: doc.data().class,
      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         nationalID: doc.data().nationalID,
      //         father_nationalID: doc.data().father_nationalID,
      //         mother_nationalID: doc.data().mother_nationalID,
      //         lastname: doc.data().lastname,
      //         studentname:
      //           doc.data().firstname +
      //           " " +
      //           doc.data().middlename +
      //           " " +
      //           doc.data().lastname,
      //         username: doc.data().username,
      //         password: doc.data().password
      //       });
      //     });
      //   });
    },
    details(id, title) {
      router.push({ name: "SubjectsList", params: { id: id, title: title } });
    },
    goToFees(id, grade, student, branch, sclass) {
      this.$store.commit("master", true);
      localStorage.setItem("studentid", id);
      localStorage.setItem("studentbranch", branch);
      localStorage.setItem("studentgrade", grade);
      localStorage.setItem("studentclass", sclass);

      router.push({
        name: "FeesList",
        params: { student: id, grade: grade, student_name: student }
      });
    },
    goToPayments(id, grade) {
      router.push({
        name: "PaymentsList",
        params: { student: id, grade: grade }
      });
    }
  }
};
</script>
