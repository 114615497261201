<template>
  <div>
    <v-card style="background-color: #eee">
      <v-card-text>
        <v-card dark flat style="background-color: #1b2e47"
          ><v-card-text>
            <v-row style="text-align: center">
              <v-col cols="4">
                <v-card light
                  ><v-card-text>
                    <img src="@/assets/school.png" width="50" />

                    School Vision</v-card-text
                  ></v-card
                >
              </v-col>
              <v-col cols="4">
                <v-card light
                  ><v-card-text>
                    <img src="@/assets/classroom.png" width="50" />

                    School Mission</v-card-text
                  ></v-card
                >
              </v-col>
              <v-col cols="4">
                <v-card light
                  ><v-card-text>
                    <img src="@/assets/rules.png" width="50" />

                    School Rules</v-card-text
                  ></v-card
                >
              </v-col>
            </v-row>
          </v-card-text></v-card
        >
        <v-card flat style="margin-top: 20px">
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row
              ><v-col cols="1">
                <v-list-item class="menu-group" two-line>
                  <v-list-item-avatar @click="goToPage('EditProfile')">
                    <img src="@/assets/avatar.jpg" />
                  </v-list-item-avatar>
                </v-list-item> </v-col
              ><v-col cols="10">
                <v-text-field
                  rounded
                  :label="'Whats on your mind, ' + this.$store.state.firstname"
                  v-model.trim="post.title"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn depressed color="white" small rounded @click="onSubmit"
                  >Send <v-icon>mdi-chevron-triple-right</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-btn depressed small color="white">
                <img
                  src="@/assets/image.png"
                  width="30px"
                  style="margin-right: 10px"
                />
                Photo/Video</v-btn
              >
              <v-btn depressed small color="white">
                <img
                  src="@/assets/employee.png"
                  width="30px"
                  style="margin-right: 10px"
                />
                Tag Colleague</v-btn
              >
              <v-btn depressed small color="white">
                <img
                  src="@/assets/feeling.png"
                  width="30px"
                  style="margin-right: 10px"
                />
                Feeling/Activity</v-btn
              ><v-spacer /><v-icon size="30">mdi-dots-horizontal</v-icon>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          style="margin-top: 20px"
          flat
          v-for="post in posts"
          :key="post.key"
        >
          <v-card-text>
            <v-row style="margin-left: 10px">
              <v-list-item-avatar>
                <img src="@/assets/avatar.jpg" />
              </v-list-item-avatar>

              <v-col cols="4">
                <b> {{ post.firstname + " " + post.lastname }}</b>
                <br />
                <small>{{ post.time }} </small>
              </v-col>
              <v-spacer />

              <v-icon
                color="red"
                v-if="post.owner == $store.state.userID"
                size="18"
                style="margin-right: 10px"
                @click="deletepost(post.key)"
                >mdi-close</v-icon
              >
              <v-icon size="20" style="margin-right: 10px"
                >mdi-pin-outline</v-icon
              >
              <v-icon size="30">mdi-dots-horizontal</v-icon>
            </v-row>
            <v-divider />
            {{ post.title }}
            <v-divider />
            <v-card-actions>
              <v-row>
                <v-btn depressed small color="white" style="margin-right: 10px">
                  <img
                    src="@/assets/like.png"
                    width="24px"
                    style="margin-right: 10px"
                  />
                  Good Idea
                </v-btn>
                <v-btn depressed small color="white" style="margin-right: 10px">
                  <img
                    src="@/assets/dislike.png"
                    width="24px"
                    style="margin-right: 10px"
                  />
                  Bad Idea
                </v-btn>
                <v-btn depressed small color="white" style="margin-right: 10px">
                  <img
                    src="@/assets/comment.png"
                    width="24px"
                    style="margin-right: 10px"
                  />
                  Comment
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Wall-page",
  data() {
    return {
      post: {},
      posts: []
    };
  },

  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/wall/")
      .orderBy("time", "desc")
      .onSnapshot(querySnapshot => {
        this.posts = [];
        querySnapshot.forEach(doc => {
          this.posts.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            time: doc.data().time,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            owner: doc.data().owner
          });
        });
      });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      if (this.post.title == null) {
        alert("Please Enter Title");
      } else {
        this.post.owner = this.$store.state.userID;
        this.post.firstname = this.$store.state.firstname;
        this.post.lastname = this.$store.state.lastname;

        const today = new Date();
        const date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        const dateTime = date + " " + time;

        this.post.time = dateTime;

        firebase
          .firestore()
          .collection("schools/" + this.$store.state.school + "/wall/")
          .add(this.post)
          .then(() => {
            this.post.title = "";
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    deletepost(id) {
      this.$confirm({
        message: "Are you sure You want to Delete this post ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("schools/" + this.$store.state.school + "/wall/")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Post Deleted", {
                  title: "Post Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
