<template>
  <b-col cols="12">
    <v-alert border="bottom" color="blue darken-1" dark>
      <h4>{{ this.$route.params.title }}</h4>
      Classes
    </v-alert>

    <b-row>
      <b-col cols="12">
        <v-card>
          <p class="card-text">
            <AddClass></AddClass>
          </p>
        </v-card>
        <br />

        <v-card>
          <v-card-title>
            <v-icon large left>mdi-calendar-month</v-icon>
            <span class="title font-weight-light">Classes List</span>
          </v-card-title>

          <p class="card-text">
            <vue-good-table
              :columns="fields"
              :rows="gclass"
              :search-options="{ enabled: true, skipDiacritics: true }"
              :pagination-options="{ enabled: true, mode: 'records' }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteboard(props.row.key, props.row.title)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </button>

                  <button
                    style="margin-left:10px"
                    class="btn btn-info btn-sm"
                    @click="Edit(props.row.path)"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </button>
                </span>
              </template>
            </vue-good-table>
          </p>
        </v-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddClass from "./AddClass";

export default {
  name: "ClassesList",
  components: {
    AddClass
  },
  data() {
    return {
      fields: [
        { field: "title", label: "Title", sortable: true, class: "text-left" },
        { field: "room", label: "Room", sortable: true, class: "text-left" },
        {
          field: "capacity",
          label: "Capacity",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "Action", class: "text-center" }
      ],
      gclass: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$route.params.path + "/classes/")
        .orderBy("title")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.gclass = [];
      querySnapshot.forEach(doc => {
        this.gclass.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          capacity: doc.data().capacity,
          room: doc.data().room
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.path + "/classes/")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Class Deleted", {
                  title: "Class Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    goToWeeks(id, title) {
      this.$store.state.subject = id;
      router.push({ name: "SubjectDash", params: { path: id, title: title } });
    },
    Edit(path) {
      router.push({ name: "EditClass", params: { path: path } });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
