<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img
            src="@/assets/avatar.png"
            width="50px"
            style="cursor: pointer; margin-right:10px;"
          />
          <span class="title font-weight-light">Edit Profile</span>
        </v-card-title>

        <v-container>
          <b-form @submit.stop.prevent="onSubmit">
            <v-col cols="12">
              <v-col cols="6">
                <v-text-field
                  label="Password"
                  v-model.trim="employees.password"
                  type="password"
                  required
                ></v-text-field>

                <b-form-file
                  placeholder="Your Photo"
                  type="file"
                  @change="previewImage"
                  accept=".jpg, .png, .jpeg"
                />

                <div>
                  <p>
                    <small>
                      Progress:
                      {{ uploadValue.toFixed() + "%" }}
                    </small>
                  </p>
                </div>
                <div v-if="image != null">
                  Upload Completed
                </div>
                <div v-if="imageData != null">
                  <v-progress-linear
                    id="progress"
                    color="light-blue"
                    height="10"
                    :value="uploadValue"
                    max="100"
                    striped
                  ></v-progress-linear>
                  <v-btn
                    @click="onUpload"
                    color="success"
                    small
                    depressed
                    style="margin-top:10px"
                    >Upload</v-btn
                  >
                </div>
              </v-col>
              <v-divider />
              <v-row
                ><v-spacer />
                <b-button
                  type="submit"
                  variant="primary"
                  style="margin-top:20px"
                  >Save Profile</b-button
                ></v-row
              >
            </v-col>
          </b-form>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditEmployee",
  params: ["path"],
  data() {
    return {
      imageData: null,
      image: null,
      uploadValue: 0,
      files: "",
      nameRules: [v => !!v || "Field is required"],
      rules: {},
      account: "",
      selectedEmployee: "",
      dialog: false,
      sdialog: false,
      schools: [],
      reporting: ["Direct", "Indirect"],
      level: [],
      classes: [],
      allemployees: {},
      studentInfo: [],
      student: [],
      subjects: [],
      subject: {},
      subordinates: {},
      master: false,
      empID: "",
      teacherSubjects: [],
      teacherSubordinate: [],

      employeeRef: firebase
        .firestore()
        .doc(
          "/schools/" +
            this.$store.state.school +
            "/employees/" +
            this.$store.state.userID
        ),
      employees: {},
      jobs: {}
    };
  },
  methods: {
    previewImage(event) {
      this.uploadValue = 0;
      this.image = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      if (this.files.size / 1048576 > 2) {
        alert("The File Is Bigger Than 2 MB");
      } else {
        this.picture = null;
        const storageRef = firebase
          .storage()
          .ref(
            "/Schools/" +
              this.$store.state.school +
              "/employees/images" +
              Math.floor(Math.random() * 900000 + 1000000).toString()
          )
          .put(this.imageData);

        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              this.image = url;
            });
          }
        );
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.image != null) {
        this.employees.image = this.image;
      }

      this.employeeRef
        .set(this.employees)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Update",
            this.employees,
            "HR",
            "Employee"
          );

          this.$store.commit("image", this.image);

          this.$bvToast.toast("Employee Updated", {
            title: "Employee Updated Successfully",
            variant: "success",
            solid: true
          });
          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  },

  created() {
    this.employeeRef.get().then(doc => {
      if (doc.exists) {
        this.employees = doc.data();
        this.empID = doc.ref.id;
      } else {
        alert("No such document!");
      }
    });
  }
};
</script>
