<template>
  <div>
    <v-card flat outlined>
      <v-card-text v-html="this.question.body" class="foo"> </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";

export default {
  name:"Question-page",
  props: ["path"],
  data() {
    return {
      question: {},
      dialog: false
    };
  },
  created() {
    this.question = [];
    firebase
      .firestore()
      .doc(this.path + "/details/details")
      .onSnapshot(doc => {
        this.question = doc.data();
      });
  },
  methods: {}
};
</script>
<style scoped>
.foo >>> img {
  width: 400px;
}
</style>
