<template>
  <v-card style="margin-top:20px">
    <v-card-title>{{ this.relation }}'s Account</v-card-title>
    <v-card-text>
      <div><b style="color:#133555">Username:</b> {{ this.username }}</div>
      <div><b style="color:#133555">Password:</b> {{ this.password }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "ParentAccounnt",
  props: ["id", "relation"],
  data() {
    return {
      username: "",
      password: ""
    };
  },
  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/parents")
      .doc(this.id)
      .get()
      .then(snapshot => {
        //if (!snapshot.exists) return;
        let parent = snapshot.data();

        this.username = parent.username;
        this.password = parent.password;
      });
  }
};
</script>
