<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Cars"
          color="primary"
          :path="'/schools/' + this.$store.state.school + '/fleets'"
        ></CountCard>
        <CountCard title="Drivers" color="success"></CountCard>
        <CountCard
          title="Services"
          color="danger"
          :path="'/schools/' + this.$store.state.school + '/services'"
        ></CountCard>
      </b-card-group>
      <br />

      <v-card>
        <v-card-text>
          <div>
            <CRUD
              title="Service"
              :path="'/schools/' + this.$store.state.school + '/services'"
              :fields="this.fields"
              width="500"
              height="600"
              :where_left="this.$route.params.where_left"
              :where_right="this.$route.params.where_right"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "Services-page",
  components: {
    CRUD,
    CountCard
  },
  params: ["where_left", "where_right"],

  data() {
    return {
      fields: [
        {
          field: "car",
          label: "Car",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/fleets",
          ftype: "ref",
          lookup: "no"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "date",
          label: "Service Date",
          sortable: true,
          class: "text-left",
          ftype: "date"
        },
        {
          field: "cost",
          label: "Cost",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>
