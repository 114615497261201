<template>
  <v-div>
    <v-row>
      <v-col cols="2">
        <img src="@/assets/cer.png" width="160px" />
      </v-col>

      <v-col cols="5">
        <v-card flat shaped color="#eee"
          ><v-card-text>Search</v-card-text></v-card
        >
      </v-col>
      <v-col cols="5" style="text-align:right">
        <v-icon color="green" style="margin-right:20px"
          >mdi-email-check-outline</v-icon
        >
        <v-icon color="red">mdi-bell-alert-outline</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9"
        ><v-card flat outlined>
          <v-card-text>
            <v-row
              ><v-col cols="7">
                <v-card flat outlined shaped>
                  <v-card-text>
                    <div style="overflow: auto; height: 540px;">
                      <div v-if="subjects.length > 0">
                        <v-card
                          v-for="subject in subjects"
                          :key="subject.id"
                          outlined
                          shaped
                          flat
                          width="95%"
                          tile
                          style="margin-bottom:10px; cursor:pointer;"
                          @click="
                            goToSubject(
                              subject.subject,
                              subject.classList,
                              subject.master,
                              subject.control
                            )
                          "
                        >
                          <v-card-subtitle>
                            <v-row>
                              <v-col cols="9">
                                <b style="font-size:18px">
                                  {{ subject.subject.split("/")[7] }}</b
                                >
                                <v-divider />
                                <v-row>
                                  {{
                                    subject.subject.split("/")[3] +
                                      " / " +
                                      subject.subject.split("/")[5]
                                  }}
                                  <v-spacer />
                                  <div v-for="c in subject.classList" :key="c">
                                    <v-avatar
                                      color="#fdcd06"
                                      tile
                                      size="24"
                                      style="margin-right:2px"
                                    >
                                      <span>{{ c }}</span>
                                    </v-avatar>
                                  </div>
                                </v-row>
                              </v-col>

                              <v-col cols="3" style="text-align:center">
                                <v-badge
                                  color="green"
                                  content="Editor"
                                  left
                                  overlap
                                  v-if="subject.master == true"
                                >
                                  <img
                                    src="@/assets/subjects/subject.png"
                                    width="60px"
                                  />
                                </v-badge>

                                <img
                                  src="@/assets/subjects/not.png"
                                  width="70px"
                                  v-else
                                />
                              </v-col>
                            </v-row>
                          </v-card-subtitle>
                        </v-card>
                      </div>
                      <div v-else style="text-align:center; margin-top:100px;">
                        <h4>There's No Assigned Subjects</h4>
                        <br />
                        <img src="@/assets/language.png" width="200px" />
                      </div>
                    </div>
                  </v-card-text>
                </v-card> </v-col
              ><v-col cols="5"
                ><v-card flat outlined
                  ><v-card-text>
                    <v-calendar></v-calendar>

                    <!-- Dashboard -->
                    <v-card flat outlined style="margin-top:20px">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="3" sm="6">
                            <v-card
                              v-if="this.rules.academic"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('ManageSubjects')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/academic.png"
                                  width="50px"
                                />
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-card style="cursor: pointer;" flat>
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/reports.png"
                                  width="50px"
                                />
                              </v-card-text> </v-card
                          ></v-col>
                          <v-col cols="12" md="3" sm="6"
                            ><v-card
                              v-if="this.rules.admission"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('AdmissionDash')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/admission.png"
                                  width="50px"
                                />
                              </v-card-text> </v-card
                          ></v-col>
                          <v-col cols="12" md="3" sm="6"
                            ><v-card
                              v-if="this.rules.students"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('StudentsDash')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/students.png"
                                  width="50px"
                                />
                              </v-card-text> </v-card
                          ></v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="3" sm="6"
                            ><v-card
                              v-if="this.rules.hr"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('HRDash')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/hr.png"
                                  width="50px"
                                />
                              </v-card-text> </v-card
                          ></v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-card
                              v-if="this.rules.accounting"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('AccountingDash')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/accounting.png"
                                  width="50px"
                                />
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-card
                              v-if="this.rules.fleet"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('FleetDash')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/fleet.png"
                                  width="50px"
                                />
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-card
                              v-if="this.rules.accounting"
                              style="cursor: pointer;"
                              flat
                              @click="goToPage('POS')"
                            >
                              <v-card-text style="text-align:center">
                                <img
                                  src="@/assets/dashboard/pos.png"
                                  width="50px"
                                />
                              </v-card-text> </v-card
                          ></v-col>
                        </v-row>
                      </v-card-text>
                    </v-card> </v-card-text
                ></v-card>

                <!-- End Dashboard -->
              </v-col></v-row
            >

            <v-card flat outlined style="margin-top:20px" color="#a0c633" dark
              ><v-card-title>Top Teachers</v-card-title></v-card
            >
          </v-card-text>
        </v-card></v-col
      >
      <v-col cols="3">
        <v-card flat outlined color="#fdcd06" shaped>
          <v-card-text style="text-align:center">
            <v-avatar size="80%" @click="goToPage('EditProfile')">
              <img
                src="@/assets/avatar.png"
                width="100%"
                style="cursor: pointer;"
              />
            </v-avatar>

            <div style="text-align:center">
              <h4 style="font-weight:900">
                Hello,<small> {{ this.$store.state.firstname }}</small>
              </h4>
              <div @click="goToJob($store.state.job)" style="cursor: pointer;">
                {{ $store.state.job }}
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          flat
          outlined
          color="#01b7f2"
          shaped
          dark
          height="400px"
          style="margin-top:20px;"
        >
          <v-card-subtitle
            style="color:#ffffff; font-weight:400; font-size:16px"
            >Announcements</v-card-subtitle
          >
          <v-card-text>
            <div style="text-align:center">
              <img
                src="@/assets/news.png"
                width="40%"
                style="cursor: pointer;"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-div>
</template>

<script>
import firebase from "firebase/app";
import router from "@/router";

export default {
  name: "Home-page",
  components: {},

  data() {
    return {
      grade: "",
      selectedsubject: "",
      rules: "",

      students: [],
      employees: [],
      fleet: [],
      errors: [],
      subjects: [],
      subject: "",
      studentscount: 0,
      batchcount: 0,
      grades: [
        "Grade 1",
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
        "Grade 8",
        "Grade 9",
        "Grade 10",
        "Grade 11",
        "Grade 12"
      ],
      classes: ["A", "B", "C", "D", "E", "F", "G", "H"]
    };
  },
  created() {
    this.rules = this.$store.state.rules;
    this.getSubjects();
  },
  methods: {
    getSubjects() {
      firebase
        .firestore()
        .collection("schools/")
        .doc(this.$store.state.school)
        .collection("employees")
        .doc(this.$store.state.userID)
        .collection("assign")
        .orderBy("grade")
        .get()
        .then(querySnapshot => {
          this.subjects = [];

          querySnapshot.forEach(doc => {
            const data = doc.data();
            this.subjects.push({
              subject: data.subject,
              classList: data.class,
              master: data.master,
              control: data.control
            });
          });
        });
    },
    goToPage(page) {
      router.push({
        name: page
      });
    },

    goToJob(job) {
      router.push({
        name: "JobDescription",
        params: { job: job }
      });
    },

    addstudentid() {
      //! Get subjects

      firebase
        .firestore()
        .collection(
          "/schools/" +
            this.$store.state.school +
            "/branches/British School (Sherouk)/grades/Grade 10/subjects"
        )
        .get()
        .then(subjects => {
          subjects.docs.forEach(subject => {
            //! Get Weeks
            firebase
              .firestore()
              .collection(subject.ref.path + "/submissions")
              .get()
              .then(submissions => {
                //! Update Tasks

                submissions.docs.forEach(submission => {
                  firebase
                    .firestore()
                    .doc(submission.ref.path)
                    .update({
                      student: submission.id.substr(0, 8) + "",
                      breakdown: "1"
                    });

                  console.log("submission");
                });
              });

            console.log("subject");
          });
        });
    },

    convertAssigToTasks() {
      //! Get subjects
      firebase
        .firestore()
        .collection(
          "/schools/" +
            this.$store.state.school +
            "/branches/British School (Nasr City)/grades/Grade 7/subjects"
        )
        .get()
        .then(subjects => {
          subjects.docs.forEach(subject => {
            //! Get Weeks
            firebase
              .firestore()
              .collection(subject.ref.path + "/weeks")
              .get()
              .then(weeks => {
                weeks.docs.forEach(week => {
                  //! Get Subjects
                  firebase
                    .firestore()
                    .collection(week.ref.path + "/tasks")
                    .get()
                    .then(snapShot => {
                      snapShot.docs.forEach(assign => {
                        //! Copy Task
                        firebase
                          .firestore()
                          .collection(subject.ref.path + "/tasks")
                          .doc(assign.id)
                          .set(assign.data())
                          .then(() => {
                            //! Read details
                            firebase
                              .firestore()
                              .collection(assign.ref.path + "/details")
                              .get()
                              .then(details => {
                                //! Copy details

                                details.docs.forEach(det => {
                                  firebase
                                    .firestore()
                                    .collection(
                                      subject.ref.path +
                                        "/tasks/" +
                                        assign.id +
                                        "/details"
                                    )
                                    .doc(det.id)
                                    .set(det.data());
                                });
                              });

                            console.log("assignment");
                          });
                      });
                    });
                });
              });

            console.log("subject");
          });
        });
    },

    updatStudents() {
      firebase
        .firestore()
        .collection("/schools" + this.$store.state.school + "StudentsData")
        .get()
        .then(students => {
          students.docs.forEach(student => {
            // console.log(student.data().second_language);
            // if (student.data().religion == null)
            firebase
              .firestore()
              .doc(student.ref.path)
              .update({
                points: 0
              })
              .then(() => console.log("Student"));

            // console.log("Student");
          });
        });
    },

    goToSubject(path, classlist, master, control) {
      if (path != null) {
        firebase
          .firestore()
          .doc(path)
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;

            let subject = snapshot;

            let grade = snapshot.ref.path.split("/")[5];
            let school = snapshot.ref.path.split("/")[3];
            this.subjectid = snapshot.id;
            this.subjectDate = snapshot.ref.path;
            this.thisGrade = grade;
            this.thisSchool = school;
            this.gradePath = snapshot.ref.parent.parent.path;
            this.thisMirror = subject.data().mirror;

            //! Master Teacher
            // if (this.from == "admin") this.$store.commit("master", "true");
            // else

            localStorage.setItem("classList", JSON.stringify(classlist));

            this.$store.commit("master", master);
            this.$store.commit("control", control);
            this.$store.commit("mirror", this.thisMirror);
            this.$store.commit("subjectTitle", subject.data().title);
            this.$store.commit("thisGrade", this.thisGrade);
            this.$store.commit("gradePath", this.gradePath);
            this.$store.commit("thisBranch", this.thisSchool);
            this.$store.commit("originalsubject", path);

            this.$store.commit("subject", path);

            if (this.thisMirror == null || this.thisMirror == "") {
              this.$store.commit("subject", path);

              router.push({
                name: "SubjectDash"
              });
            } else {
              this.$store.commit("subject", this.thisMirror);

              router.push({
                name: "SubjectDash"
              });
            }
          });
      }
    },

    firestore2json() {
      //       schema = {
      // 	all_users: {},
      // 	feedback: {},
      // 	reports: {},
      // 	schools: {
      // 		grades: {},
      // 		notifications: {},
      // 		publications: {},
      // 		users: {},
      // 	}
      // };

      const schema = {
        answers: {}
      };

      const firestore2json = (db, schema, current) => {
        return Promise.all(
          Object.keys(schema).map(collection => {
            return firebase
              .firestore()
              .collection(collection)
              .get()
              .then(data => {
                let promises = [];
                data.forEach(doc => {
                  if (!current[collection])
                    current[collection] = { __type__: "collection" };
                  current[collection][doc.id] = doc.data();
                  promises.push(
                    firestore2json(
                      firebase
                        .firestore()
                        .collection(collection)
                        .doc(doc.id),
                      schema[collection],
                      current[collection][doc.id]
                    )
                  );
                });
                return Promise.all(promises);
              });
          })
        ).then(() => current);
      };

      firestore2json(firebase.firestore(), { ...schema }, {}).then(res => {
        const data = JSON.stringify(res, null, 2);
        const blob = new Blob([data], { type: "text/plain" });
        const e = document.createEvent("MouseEvents"),
          a = document.createElement("a");
        a.download = "test.json";
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
        e.initEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
      });
    }
  }
};
</script>
