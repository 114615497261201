<template>
  <div>
    <v-card-title>
      <h3>Dashboard</h3>
    </v-card-title>
    <v-card-subtitle>Reports & Analytics Center </v-card-subtitle>
  </div>
</template>
<script>
export default {
  name: "SubjecHeader",
  data() {
    return {};
  },
  created() {}
};
</script>
