<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img src="@/assets/admission.png" class=" ma-2" width="44px" />
          <span class="title font-weight-light">Admission Department</span>

          <v-row align="center" justify="end">
            <download-excel
              class="btn btn-default"
              :data="admission"
              :fields="fexport"
              type="csv"
              name="admission.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
              <small>Export to Excel</small>
            </download-excel>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card flat outlined style="background-color:#d7e9f9">
            <v-card-text>
              <!-- School -->
              <v-row>
                <v-col cols="4" sm="4">
                  <v-select
                    :items="schools"
                    v-model="student.school"
                    label="School"
                    @change="onSchoolChange()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4">
                  <b-form-datepicker
                    style="background-color:#d7e9f9"
                    v-model="date"
                    locale="en"
                  ></b-form-datepicker>
                </v-col>
                <v-col cols="4" sm="4">
                  <v-btn color="info" small depressed @click="search"
                    ><v-icon>mdi-magnify</v-icon> Search</v-btn
                  >

                  <v-btn
                    depressed
                    small
                    color="success"
                    @click="clearSearch"
                    style="margin-left:10px"
                    >Clear Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="blue"
            dark
            @click="goToPage('AddApplication')"
            depressed
            small
            >Add New Application</v-btn
          >
          <vue-good-table
            style="margin-top:20px"
            :columns="fields"
            :rows="admission"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteboard(props.row.key, props.row.firstname)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </button>
                &nbsp;
                <button
                  class="btn btn-warning btn-sm"
                  @click="Edit(props.row.path, 'copy')"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </button>
                &nbsp;
                <!--   <button
                  class="btn btn-info btn-sm"
                  @click="Edit(props.row.path)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </button>-->

                <EditApplication
                  :path="props.row.path"
                  :key="props.row.key"
                ></EditApplication>

                &nbsp;
                <ViewApplication
                  :path="props.row.path"
                  :key="props.row.key"
                ></ViewApplication>

                &nbsp;
              </span>

              <span v-else-if="props.column.field == 'timeline'">
                <Timeline
                  :path="props.row.path"
                  :student="
                    props.row.firstname +
                      ' ' +
                      props.row.middlename +
                      ' ' +
                      props.row.lastname
                  "
                  :key="props.row.key"
                ></Timeline>
              </span>

              <span v-else-if="props.column.field == 'name'">{{
                props.row.firstname +
                  " " +
                  props.row.middlename +
                  " " +
                  props.row.lastname
              }}</span>
              <span v-else-if="props.column.field == 'status'">
                <v-chip
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                  small
                  v-if="props.row.status == 'Accepted'"
                >
                  <v-icon left>mdi-label</v-icon>Accepted
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="orange"
                  label
                  text-color="white"
                  small
                  v-if="
                    props.row.status == null ||
                      props.row.status == 'Progressing'
                  "
                >
                  <v-icon left>mdi-label</v-icon>Progressing
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="red"
                  label
                  text-color="white"
                  small
                  v-if="props.row.status == 'Rejected'"
                >
                  <v-icon left>mdi-label</v-icon>Rejected
                </v-chip>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import EditApplication from "@/components/admission/EditApplication";
import ViewApplication from "@/components/admission/ViewApplication";
import Timeline from "@/components/admission/timeline/TimelineList";

export default {
  name: "AdmissionList",
  components: { EditApplication, ViewApplication, Timeline },

  data() {
    return {
      fields: [
        {
          field: "application_date",
          label: "Application Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "admission_date",
          label: "Admission Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "admission_time",
          label: "Time",
          sortable: true,
          class: "text-left",
          width: "80px"
        },
        {
          field: "mother_mobile",
          label: "Mother Mobile",
          sortable: true,
          class: "text-left"
        },

        {
          field: "status",
          label: "",
          sortable: true,
          class: "text-left"
        },

        {
          field: "admission_current",
          label: "Current Status",
          sortable: true,
          class: "text-left"
        },
        {
          field: "timeline",
          label: "",
          sortable: true,
          class: "text-left"
        },
        // {
        //   field: "admission_date",
        //   label: "Date",
        //   sortable: true,
        //   class: "text-left"
        // },
        // {
        //   field: "admission_time",
        //   label: "Time",
        //   sortable: true,
        //   class: "text-left"
        // },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        { field: "grade", label: "Grade", sortable: true, class: "text-left" },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Actions",
          class: "text-center",
          width: "220px"
        }
      ],
      fexport: {
        "Application Date": "application_date",
        "Last Status": "admission_current",
        "Admission Date": "admission_date",
        "Admission Time": "admission_time",
        "School Name": "branch",
        "Grade Name": "grade",
        "First Name": "firstname",
        "Middle Name": "middlename",
        "Last Name": "lastname",
        Citizenship: "citizenship",
        "Date Of Birth": "date_of_birth",
        "Father Academic Degree": "father_academic_degree",
        father_company_business: "father_company_business",
        father_email: "father_email",
        father_mobile: "father_mobile",
        father_occupation: "father_occupation",
        mother_academic_degree: "mother_academic_degree",
        mother_email: "mother_email",
        mother_mobile: "mother_mobile",
        mother_name: "mother_name",
        mother_occupation: "mother_occupation",
        previous_school: "previous_school",
        religion: "religion",
        secondlanguage: "secondlanguage"
      },
      date: "",
      admission: [],
      errors: [],

      student: [],
      schools: [],
      level: [],

      accepted: [],
      progress: [],
      rejected: [],
      status: [],

      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/admission"),

      StatusRef: firebase
        .firestore()
        .collection(
          "/schools/" + this.$store.state.school + "/admission_status"
        ),

      AcceptedRef: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/admission")
        .where("admission_status", "==", "Accepted"),

      RejectedRef: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/admission")
        .where("admission_status", "==", "Rejected"),

      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
        .where("active", "==", true)
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.admission = [];
      querySnapshot.forEach(doc => {
        this.admission.push({
          key: doc.id,
          path: doc.ref.path,
          branch: doc.data().branch,
          status: doc.data().admission_status,
          laststatus: doc.data().admission_current,
          grade: doc.data().grade,
          firstname: doc.data().firstname,
          middlename: doc.data().middlename,
          lastname: doc.data().lastname,
          studentname:
            doc.data().firstname +
            " " +
            doc.data().middlename +
            " " +
            doc.data().lastname,
          admission_date: doc.data().admission_date,
          admission_time: doc.data().admission_time,

          address: doc.data().address,

          application_date: doc.data().application_date,

          citizenship: doc.data().citizenship,
          date_of_birth: doc.data().date_of_birth,
          father_academic_degree: doc.data().father_academic_degree,
          father_company_business: doc.data().father_company_business,
          father_email: doc.data().parent,

          father_mobile: doc.data().father_mobile,

          father_occupation: doc.data().father_occupation,

          mother_academic_degree: doc.data().mother_academic_degree,
          mother_email: doc.data().mother_email,
          mother_mobile: doc.data().mother_mobile,
          mother_name: doc.data().mother_name,

          mother_occupation: doc.data().mother_occupation,
          previous_school: doc.data().previous_school,
          religion: doc.data().religion,
          secondlanguage: doc.data().secondlanguage,
          admission_current: doc.data().admission_current
        });
      });
    });

    this.StatusRef.get().then(querySnapshot => {
      this.status = [];
      querySnapshot.forEach(doc => {
        this.status.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });

    this.AcceptedRef.get().then(querySnapshot => {
      this.accepted = [];
      querySnapshot.forEach(doc => {
        this.accepted.push({
          key: doc.id
        });
      });
    });

    this.RejectedRef.get().then(querySnapshot => {
      this.rejected = [];
      querySnapshot.forEach(doc => {
        this.rejected.push({
          key: doc.id
        });
      });
    });

    this.schoolsRef.get().then(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  },
  methods: {
    clearSearch() {
      this.admission = [];

      this.ref.get().then(querySnapshot => {
        this.admission = [];
        querySnapshot.forEach(doc => {
          this.admission.push({
            key: doc.id,
            path: doc.ref.path,
            branch: doc.data().branch,
            status: doc.data().admission_status,
            laststatus: doc.data().admission_current,
            grade: doc.data().grade,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            lastname: doc.data().lastname,
            studentname:
              doc.data().firstname +
              " " +
              doc.data().middlename +
              " " +
              doc.data().lastname,
            admission_date: doc.data().admission_date,
            admission_time: doc.data().admission_time,

            address: doc.data().address,

            application_date: doc.data().application_date,

            citizenship: doc.data().citizenship,
            date_of_birth: doc.data().date_of_birth,
            father_academic_degree: doc.data().father_academic_degree,
            father_company_business: doc.data().father_company_business,
            father_email: doc.data().parent,

            father_mobile: doc.data().father_mobile,

            father_occupation: doc.data().father_occupation,

            mother_academic_degree: doc.data().mother_academic_degree,
            mother_email: doc.data().mother_email,
            mother_mobile: doc.data().mother_mobile,
            mother_name: doc.data().mother_name,

            mother_occupation: doc.data().mother_occupation,
            previous_school: doc.data().previous_school,
            religion: doc.data().religion,
            secondlanguage: doc.data().secondlanguage,
            admission_current: doc.data().admission_current
          });
        });
      });
    },

    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/admission")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    onGradeChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/admission")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .get()
        .then(querySnapshot => {
          this.admission = [];
          querySnapshot.forEach(doc => {
            this.admission.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,
              grade: doc.data().grade,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().parent,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current
            });
          });
        });
    },
    search() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/admission")
        .where("branch", "==", this.student.school)
        .where("admission_date", "==", this.date)
        .get()
        .then(querySnapshot => {
          this.admission = [];
          querySnapshot.forEach(doc => {
            this.admission.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,
              grade: doc.data().grade,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().parent,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current
            });
          });
        });
    },
    onStatusChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/admission")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .where("admission_current", "==", this.student.status)
        .get()
        .then(querySnapshot => {
          this.admission = [];
          querySnapshot.forEach(doc => {
            this.admission.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,
              grade: doc.data().grade,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().parent,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current
            });
          });
        });
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/"
        )
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    Edit(id, type) {
      router.push({
        name: "EditApplication",
        params: { path: id, type: type }
      });
    },
    View(id, type) {
      router.push({
        name: "ViewApplication",
        params: { path: id, type: type }
      });
    },
    Timeline(id, student) {
      router.push({
        name: "TimelineList",
        params: { path: id, student: student }
      });
    },
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
