<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="470px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn  btn-sm"
        fab
        dark
        small
        color="info"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-volume-high</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-volume-high</v-icon>Option
          Audio
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row v-if="this.link">
          <v-col cols="10">
            <audio controls>
              <source :src="this.link" type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </v-col>
          <v-col cols="2">
            <v-btn class="mx-2" fab dark small color="red" @click="clearNote">
              <v-icon dark>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <audio-recorder
          :attempts="1"
          :time="2"
          :show-upload-button="false"
          :select-record="callback"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" small @click="uploadNote" v-if="this.url">
          <v-icon style="padding-right:10px">
            mdi-cloud-upload-outline
          </v-icon>
          Upload Note</v-btn
        >
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";

export default {
  props: ["index"],
  data() {
    return {
      url: "",
      note: "",
      blob: "",
      dialog: false,
      activities: [],
      csystems: [],
      grades: [],
      subjects: [],
      newActivity: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      system: "",
      grade: "",
      subject: "",
      weekid: ""
    };
  },

  created() {},
  methods: {
    callback(data) {
      this.url = data.url;
    },
    uploadNote() {
      if (this.url != null) {
        this.note = null;

        fetch(this.url)
          .then(res => res.blob())
          .then(blob => {
            const storageRef = firebase
              .storage()
              .ref(
                "notes/" +
                  Math.floor(Math.random() * 900000 + 1000000).toString()
              )
              .put(blob);

            storageRef.on(
              `state_changed`,
              snapshot => {
                this.uploadValue =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              error => {
                console.log(error.message);
              },
              () => {
                this.uploadValue = 100;
                storageRef.snapshot.ref.getDownloadURL().then(url => {
                  this.$emit("clicked", [url, this.index]);
                });
              }
            );
          });
      }
    },
    clearNote() {
      firebase
        .firestore()
        .doc(this.path)
        .update({ note: null })
        .then(() => {
          this.$bvToast.toast("Note Updated", {
            title: "Note Updated Successfully",
            variant: "success",
            solid: true
          });
          this.link = null;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
