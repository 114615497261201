<template>
 <v-app id="inspire">
  
    <router-view></router-view>
  </v-app>
</template>

<script>
import router from "@/router";
import firebase from "@/Firebase";

export default {
  name: "App",
  components: {},
  props: {
    source: String
  },

  data: () => ({
    domain: "",
    login: "",
    dialog: false,
    drawer: null
    // rules: JSON.parse(localStorage.getItem("rules"))
  }),
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    },

    signOut() {
      localStorage.setItem("loggedIn", false);
      this.$store.state.loggedIn = false;

      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.href = "/login/" + this.$store.state.school;
        });
    }
  },
  created() {
    this.login = localStorage.getItem("loggedIn");
    this.domain = window.location.host;
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Lalezar"); */
#inspire {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  background-color: #eee;
}

.card-text {
  padding: 20px;
}

.cbody {
  padding: 40px;
  padding-top: 100px;
}

.grad {
  position: fixed;
  background-image: linear-gradient(to right, #0e1f36, #345784);
}
</style>
