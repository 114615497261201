<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-account-edit-outline</v-icon>

          <span class="title font-weight-light">{{
            student.firstname +
              " " +
              student.middlename +
              " " +
              student.lastname
          }}</span>
        </v-card-title>
        <v-card-subtitle
          >{{ student.branch }} --- {{ student.grade }} --- ({{
            student.class
          }})</v-card-subtitle
        >

        <b-form @submit.stop.prevent="onSubmit">
          <!-- Student Name -->

          <v-container>
            <v-expansion-panels accordion :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-4"
                  style="color:#fff"
                  >Basic Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row center-block>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="First name"
                        v-model.trim="student.firstname"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Middle name"
                        v-model.trim="student.middlename"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Last name"
                        v-model.trim="student.lastname"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Student National Id (14 Numbers)"
                        messages="الرقم القومي للطالب"
                        v-model.trim="student.nationalID"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Upload Image -->
                  <v-col cols="12" md="4">
                    <div>
                      <p>Student Image:</p>

                      <b-form-file
                        type="file"
                        @change="previewImage"
                        accept="image/*"
                        size="sm"
                      />
                    </div>
                    <div>
                      <p>
                        <small
                          >Progress: {{ uploadValue.toFixed() + "%" }}</small
                        >
                        <v-progress-linear
                          id="progress"
                          color="light-blue"
                          height="10"
                          :value="uploadValue"
                          max="100"
                          striped
                        ></v-progress-linear>
                      </p>
                    </div>
                    <div v-if="imageData != null">
                      <img class="preview" :src="picture" width="80px" />
                      <br />

                      <b-button @click="onUpload" variant="primary"
                        >Upload</b-button
                      >
                    </div>
                  </v-col>

                  <!-- End Of Uploading -->

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Microsoft or Google Account"
                        v-model.trim="student.email"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Schools Level -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-select
                        :items="schools"
                        v-model="school"
                        label="School"
                        @change="onSchoolChange()"
                        :messages="student.branch"
                      ></v-select>
                    </v-col>
                    <!-- Grade Level -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-select
                        :items="grades"
                        v-model="grade"
                        label="Grade Level"
                        @change="onGradeChange()"
                        :messages="student.grade"
                      ></v-select>
                    </v-col>
                    <!-- Class -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-select
                        :items="gclasses"
                        v-model="gclass"
                        label="Class"
                        :messages="student.class"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Houses -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="this.houses"
                        v-model.trim="student.house"
                        label="House"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Join Year -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="[
                          '2020-2021',
                          '2019-2020',
                          '2018-2019',
                          '2017-2018',
                          '2016-2017',
                          '2015-2016',
                          '2014-2015',
                          '2013-2014',
                          '2012-2013',
                          '2011-2012',
                          '2010-2011',
                          '2009-2010'
                        ]"
                        v-model.trim="student.join"
                        label="Join Year"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row center-block>
                    <!-- Status -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="['Active', 'Terminated', 'Graduated']"
                        v-model.trim="student.status"
                        label="Student Status"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row center-block>
                    <!-- Status -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="['Muslim', 'Christian']"
                        v-model.trim="student.religion"
                        label="Student Religion"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row center-block>
                    <!-- Status -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="['French', 'German']"
                        v-model.trim="student.second_language"
                        label="Second Language"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header color="orange" style="color:#fff"
                  >Account Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-switch
                    color="red"
                    v-model="student.suspend"
                    label="Suspend Account"
                  ></v-switch>

                  <v-row center-block>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Username"
                        v-model.trim="student.username"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Password"
                        v-model.trim="student.password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-3"
                  style="color:#fff"
                  >Student Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row center-block>
                    <!-- Gender -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="['Male', 'Female']"
                        v-model.trim="student.gender"
                        label="Gender"
                      ></v-select>
                    </v-col>

                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Citizenship"
                        v-model.trim="student.citizenship"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Address"
                        v-model.trim="student.address"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Home Phone"
                        v-model.trim="student.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Mobile"
                        v-model.trim="student.mobile"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-4"
                  style="color:#fff"
                  >Family Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <br />
                  <!-- Father Information -->

                  <v-alert border="bottom" color="green darken-1" dark>
                    <h6>Father Information</h6>
                  </v-alert>

                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Father Academic Degree"
                      v-model.trim="student.father_degree"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Father Occupation"
                      v-model.trim="student.father_occupation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Father Company Business"
                      v-model.trim="student.father_company"
                    ></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Father Email"
                        v-model.trim="student.parent"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Father Mobile"
                        v-model.trim="student.father_mobile"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Father National Id (14 Numbers)"
                        messages="الرقم القومي للاب"
                        v-model.trim="student.father_nationalID"
                      ></v-text-field> </v-col
                  ></v-row>

                  <!-- End Father Information -->
                  <br />
                  <!-- Father Information -->

                  <v-alert border="bottom" color="green darken-1" dark>
                    <h6>Mother Information</h6>
                  </v-alert>

                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Name"
                      v-model.trim="student.mother_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Academic Degree"
                      v-model.trim="student.mother_degree"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Occupation"
                      v-model.trim="student.mother_occupation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Company Business"
                      v-model.trim="student.mother_company"
                    ></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Mother Email"
                        v-model.trim="student.mother_email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Mother Mobile"
                        v-model.trim="student.mother_mobile"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Mother National Id (14 Numbers)"
                        messages="الرقم القومي للام"
                        v-model.trim="student.mother_nationalID"
                      ></v-text-field> </v-col
                  ></v-row>

                  <!-- End Father Information -->
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!--
                <v-expansion-panel>
                  <v-expansion-panel-header color="green" style="color:#fff"
                    >Accounting</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card style="margin-top:20px"
                      ><v-card-title>Joining Year</v-card-title>
                      <v-card-text>
                        <h5>{{ this.student.join }}</h5>
                      </v-card-text>
                    </v-card>
                    <v-card style="margin-top:20px"
                      ><v-card-title>Siblings</v-card-title>
                      <v-card-text>
                        <vue-good-table :columns="brothersf" :rows="brothers">
                        </vue-good-table>
                      </v-card-text>
                    </v-card>
                    <v-card style="margin-top:20px"
                      ><v-card-title>Parents</v-card-title>
                      <v-card-subtitle
                        >Employees In this School</v-card-subtitle
                      >
                      <v-card-text>
                        <div v-if="this.father.length > 0">
                          <h5>Father</h5>
                          <br />

                          <vue-good-table :columns="parentf" :rows="father">
                          </vue-good-table>
                        </div>
                        <div
                          v-if="this.mother.length > 0"
                          style="margin-top:20px"
                        >
                          <h5>Mother</h5>
                          <br />

                          <vue-good-table :columns="parentf" :rows="mother">
                          </vue-good-table>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>-->
            </v-expansion-panels>
          </v-container>
          <b-card-footer>
            <!-- Submit Form -->
            <b-button type="submit" variant="primary">Edit Student</b-button>
          </b-card-footer>
        </b-form>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditStudent",
  params: ["path"],

  data() {
    return {
      //Upload Image
      brothersf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "status",
          label: "Status",
          sortable: true,
          class: "text-left"
        }
      ],
      parentf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "job",
          label: "Job",
          sortable: true,
          class: "text-left"
        },

        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        }
      ],
      menu: false,
      suspend: false,
      imageData: null,
      picture: null,
      uploadValue: 0,
      panel: 0,
      student: {},
      grades: {},
      schools: {},
      houses: [],
      brothers: {},
      father: {},
      mother: {},
      school: "",
      grade: "",
      gclass: "",
      gclasses: {},
      selectedGrade: "",
      studentRef: firebase.firestore().doc(this.$route.params.path),
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      console.log(this.grade[0]);
      this.student.email = this.student.email.trim().toLowerCase();

      if (this.picture != null) {
        this.student.image = this.picture;
      }
      this.student.branch =
        this.school == "" ? this.student.branch : this.school;
      this.student.grade =
        this.grade == "" ? this.student.grade : this.grade[1];
      this.student.class = this.gclass == "" ? this.student.class : this.gclass;

      this.student.educationPath =
        this.grade[0] == null ? this.student.educationPath : this.grade[0];

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .update(this.student)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Update",
            this.student,
            "SIS",
            "Student"
          );

          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          this.$store.state.school +
            "/StudentsData/" +
            this.student.firstname +
            this.student.date_of_birth +
            this.student.lastname
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.picture = url;
          });
        }
      );
    },

    onSchoolChange() {
      this.gclasses = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.school +
            "/grades"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: [doc.ref.path, doc.data().title]
            });
          });
        });
    },
    onGradeChange() {
      firebase
        .firestore()
        .collection(this.grade[0] + "/classes")
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.gclasses = [];
          querySnapshot.forEach(doc => {
            this.gclasses.push({
              text: doc.data().title,
              value: doc.data().title
            });
          });
        });
    }
  },
  created() {
    this.studentRef
      .get()
      .then(doc => {
        if (doc.exists) {
          this.student = doc.data();
        } else {
          alert("No such document!");
        }
      })
      .then(() => {
        // Get Brothers Data
        // firebase
        //   .firestore()
        //   .collection("schools/" + this.$store.state.school + "/students/")
        //   .where("father_nationalID", "==", this.student.father_nationalID)
        //   .onSnapshot(querySnapshot => {
        //     this.brothers = [];
        //     querySnapshot.forEach(doc => {
        //       if (doc.data().firstname != this.student.firstname) {
        //         this.brothers.push({
        //           firsname: doc.data().firstname,
        //           lastname: doc.data().lastname,
        //           branch: doc.data().branch,
        //           grade: doc.data().grade,
        //           class: doc.data().class,
        //           join: doc.data().join,
        //           status: doc.data().status
        //         });
        //       }
        //     });
        //   });
        // Get Parents Data
        // firebase
        //   .firestore()
        //   .collection("schools/" + this.$store.state.school + "/employees/")
        //   .where("nationalID", "==", this.student.father_nationalID)
        //   .onSnapshot(querySnapshot => {
        //     this.father = [];
        //     querySnapshot.forEach(doc => {
        //       this.father.push({
        //         firsname: doc.data().firstname,
        //         lastname: doc.data().lastname,
        //         job: doc.data().job,
        //         join: doc.data().join
        //       });
        //     });
        //   });
        // firebase
        //   .firestore()
        //   .collection("schools/" + this.$store.state.school + "/employees/")
        //   .where("nationalID", "==", this.student.mother_nationalID)
        //   .onSnapshot(querySnapshot => {
        //     this.mother = [];
        //     querySnapshot.forEach(doc => {
        //       this.mother.push({
        //         firsname: doc.data().firstname,
        //         lastname: doc.data().lastname,
        //         job: doc.data().job,
        //         join: doc.data().join
        //       });
        //     });
        //   });
      });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/houses")
      .onSnapshot(querySnapshot => {
        this.houses = [];
        querySnapshot.forEach(doc => {
          this.houses.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  }
};
</script>
