<template>
  <div
    class="login-access"
    style="
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card">
            <div class="card-body">
              <div style="text-align: center">
                <img :src="this.$route.params.logo" width="120px" />
              </div>
              <br />
              <br />
              <div style="text-align: left">
                <h6>Dear Parent,</h6>

                Thank you for your interest in
                {{ this.$route.params.school }}.<br />
                Your child’s assessment will be on
                {{ this.$route.params.date }} at {{ this.$route.params.time }}.

                <span v-html="this.admissionfeedback"></span>

                <br />
                <v-btn @click="sendEmail" color="success" small
                  >Send This Form to Email</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Confirmation-page",
  params: ["logo, school", "schoolname", "date", "time", "emails"],

  data() {
    return { admissionfeedback: "", school: {} };
  },

  created() {
    firebase
      .firestore()
      .doc(
        "schools/" +
          this.$route.params.schoolname +
          "/branches/" +
          this.$route.params.branch
      )
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        let school = snapshot.data();

        this.admissionfeedback = school.admissionfeedback;
      });

    firebase
      .firestore()
      .doc("schools/" + this.$store.state.school)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.school = snapshot.data();
        this.sendEmail();
      });
  },
  methods: {
    sendEmail() {
      var Email = {
        send: function(a) {
          return new Promise(function(n) {
            (a.nocache = Math.floor(1e6 * Math.random() + 1)),
              (a.Action = "Send");
            var t = JSON.stringify(a);
            Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function(
              e
            ) {
              n(e);
            });
          });
        },
        ajaxPost: function(e, n, t) {
          var a = Email.createCORSRequest("POST", e);
          a.setRequestHeader(
            "Content-type",
            "application/x-www-form-urlencoded"
          ),
            (a.onload = function() {
              var e = a.responseText;
              null != t && t(e);
            }),
            a.send(n);
        },
        ajax: function(e, n) {
          var t = Email.createCORSRequest("GET", e);
          (t.onload = function() {
            var e = t.responseText;
            null != n && n(e);
          }),
            t.send();
        },
        createCORSRequest: function(e, n) {
          var t = new XMLHttpRequest();
          return (
            "withCredentials" in t
              ? t.open(e, n, !0)
              : "undefined" != typeof XDomainRequest
              ? ""
              : (t = null),
            t
          );
        }
      };
      Email.send({
        Host: this.school.smtp,
        Username: this.school.email,
        Password: this.school.password,
        To: this.$route.params.emails,
        // From: this.school.from,
        From: "admission@admission.com",
        Subject: "Admission Department",
        Body:
          '<html><div style="text-align:center"><tr><td bgcolor="#ffffff" align="center"></td></tr></div><br /><br /><div style="text-align:left"><h4>Dear Parent,</h4>Thank you for your interest in ' +
          this.$route.params.school +
          ".<br />Your child’s assessment will be on " +
          this.$route.params.date +
          " at " +
          this.$route.params.time +
          " " +
          this.admissionfeedback
      }).then(message => console.log(message));
    }
  }
};
</script>
