<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title
          ><v-icon left size="40px">mdi-head-cog-outline</v-icon>HR
          Setting</v-card-title
        >
        <b-card-group deck>
          <SettingCard
            title="Jobs"
            icon="mdi-badge-account-horizontal-outline"
            link="JobsList"
          ></SettingCard>
          <SettingCard title="Job Categories" icon="mdi-contacts"></SettingCard>
          <SettingCard title="Locations" icon="mdi-domain"></SettingCard>
          <SettingCard
            title="Education"
            icon="mdi-school-outline"
          ></SettingCard>
          <SettingCard
            title="Job Status"
            icon="mdi-account-question-outline"
          ></SettingCard>
          <SettingCard title="Skills" icon="mdi-bank-outline"></SettingCard>
        </b-card-group>
      </v-card>
    </b-col>
  </b-row>
</template>
<script>
import SettingCard from "@/components/dna/widgets/SettingCard";
export default {
  name: "HRSetting",
  components: { SettingCard }
};
</script>
