<template>
  <div
    class="login-access"
    style="flex-direction: column; box-sizing: border-box; display: flex;  align-items: center;"
  >
    <div class="container">
      <div class="col-lg-7">
        <div style="text-align:left">
          <img src="@/assets/cer.png" width="150px" />
        </div>
      </div>
      <div style="text-align:center">
        Cerebral Managment System
      </div>
      <br />

      <div style="text-align:center">
        <h3>
          For an improved learning and<br />
          teaching experience
        </h3>
      </div>
      <div class="row ">
        <div class="col-lg-7">
          <div style="text-align:left"></div>
        </div>
        <div class="col-lg-5">
          <div class="card" style="background-color:#e4f4e6;">
            <div class="card-body">
              <div v-if="error" class="alert alert-danger">
                <small>{{ error }} </small>
              </div>

              <h3>Welcome Back</h3>

              <v-alert
                prominent
                type="error"
                v-show="this.suspended"
                style="margin-top:20px"
              >
                <v-row align="center">
                  <v-col class="grow">
                    This Account Has Been Suspended.
                  </v-col>
                </v-row>
              </v-alert>

              <form
                action="#"
                @submit.prevent="submit"
                v-show="!this.suspended"
              >
                <div class="form-group">
                  <v-text-field
                    label="email"
                    required
                    v-model="form.email"
                  ></v-text-field>

                  <v-text-field
                    label="password"
                    required
                    :type="'password'"
                    v-model="form.password"
                  ></v-text-field>

                  <div
                    class="col-12"
                    style="align-items: center;text-align: center;"
                  >
                    <button type="submit" class="btn login-btn success">
                      Login
                    </button>
                  </div>
                </div>
              </form>
              <div
                style="align-items: center;text-align: center;"
                v-show="!this.suspended"
              >
                <button class="btn login-btn" @click="socialLogin()">
                  Login with Microsoft
                </button>
              </div>
              <div
                fxlayout="column"
                fxlayoutalign="center center"
                class="register"
                style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center;"
              >
                <span class="text"><small>Eduology - 2022</small> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
// import router from "@/router";

export default {
  name: "Login-page",
  data() {
    return {
      form: {
        email: "",
        password: ""
      },

      data: "",
      error: null,
      domain: "",
      email: "",
      message: "",
      logo: "",
      SchoolShortName: this.$route.params.school,
      SchoolFullName: "",
      suspended: "",
      schoolRef: firebase
        .firestore()
        .doc("schools/" + this.$route.params.school)
    };
  },
  params: ["school"],
  methods: {
    submit() {
      if (this.suspended) {
        alert("This Account Has Been Suspended.");
      } else {
        firebase
          .firestore()
          // .collection("schools/" + this.$route.params.school + "/employees")
          .collection("schools/" + this.$route.params.school + "/employees")
          .where("username", "==", this.form.email)
          .onSnapshot(querySnapshot => {
            if (querySnapshot.docs.length == 0) {
              this.message = "Please Enter Valid Username & Password";
            } else {
              querySnapshot.forEach(user => {
                if (
                  user.data().password == Number(this.form.password) ||
                  user.data().password == this.form.password
                ) {
                  this.$store.commit("school", this.SchoolShortName);
                  this.$store.commit("schoolName", this.SchoolShortName);

                  localStorage.setItem("schoolDomain", user.data().domain);

                  this.$store.commit("userID", user.ref.id);
                  this.$store.commit("job", user.data().job);

                  this.$store.commit("firstname", user.data().firstname);
                  this.$store.commit("lastname", user.data().lastname);
                  this.$store.commit("username", user.data().username);

                  this.$store.commit("image", user.data().image);

                  this.$store.commit("rules", user.data().access);

                  localStorage.setItem("logo", this.logo);
                  localStorage.setItem("loggedIn", true);

                  // this.$route.params.school= this.SchoolShortName;
                  // this.$store.state.schoolName = this.SchoolFullName;
                  // this.$store.state.userID = user.ref.id;
                  // this.$store.state.schoolDomain = user.data().domain;
                  // this.$store.state.firstname = user.data().firstname;
                  // this.$store.state.lastname = user.data().lastname;
                  // this.$store.state.username = user.data().username;
                  // this.$store.state.rules = user.data().access;
                  // this.$store.state.logo = this.logo;
                  // this.$store.state.job = user.data().job;
                  // this.$store.state.loggedIn = true;

                  this.$router.replace({
                    name: "Home"
                  });
                } else {
                  this.message = "Please Enter Valid Username & Password";
                }
              });
            }
          });
      }
    },

    socialLogin() {
      //firebase.firestore().enablePersistence();
      // const provider = new firebase.auth.GoogleAuthProvider();

      if (this.suspended) {
        alert("This Account Has Been Suspended.");
      } else {
        firebase.database.enableLogging(true);
        const provider = new firebase.auth.OAuthProvider("microsoft.com");

        firebase
          .auth()
          .signInWithPopup(provider)
          .then(data => {
            if (data.user.email == null) {
              this.email = data.additionalUserInfo.profile.mail
                .toLowerCase()
                .trim();
            } else {
              this.email = data.user.email;
            }

            firebase
              .firestore()
              .collection("schools/" + this.$route.params.school + "/employees")
              .where("username", "==", this.email)
              .onSnapshot(querySnapshot => {
                let user = querySnapshot.docs[0].data();

                localStorage.setItem("school", this.SchoolShortName);
                localStorage.setItem("schoolName", this.SchoolFullName);
                localStorage.setItem("schoolDomain", user.domain);
                localStorage.setItem("userID", querySnapshot.docs[0].ref.id);
                localStorage.setItem("firstname", user.firstname);
                localStorage.setItem("lastname", user.lastname);
                localStorage.setItem("username", user.username);
                this.$store.commit("rules", user.data().access);

                localStorage.setItem("logo", this.logo);
                localStorage.setItem("loggedIn", true);

                localStorage.setItem("job", user.job);

                this.$store.state.school = this.SchoolShortName;
                this.$store.state.schoolName = this.SchoolFullName;
                this.$store.state.schoolDomain = user.domain;
                this.$store.state.firstname = user.firstname;
                this.$store.state.lastname = user.lastname;
                this.$store.state.username = user.username;
                this.$store.state.rules = user.access;
                this.$store.state.logo = this.logo;
                this.$store.state.job = user.job;
                this.$store.state.loggedIn = true;

                this.$router.replace({
                  name: "Home"
                });
              });
          })
          .catch(err => {
            console.log(err);
            this.error = err.message;
          });
      }
    }
  },
  created() {
    this.domain = window.location.host;

    this.schoolRef.get().then(snapshot => {
      if (!snapshot.exists) return;
      let school = snapshot.data();

      this.logo = school.logo;
      this.suspended = school.suspended;
      this.SchoolShortName = snapshot.id;
      this.SchoolFullName = school.title;
    });
  }
};
</script>
