<template>
  <v-card>
    <v-card-title>Parents Requests</v-card-title>

    <v-card-text>
      <b-row>
        <b-col cols="3">
          <v-card flat outlined>
            <v-card-title> {{ this.data.branch }}</v-card-title>
            <v-card-subtitle>Select Grade</v-card-subtitle>
            <v-card-text>
              <div v-for="grade in grades" :key="grade">
                <v-btn
                  depressed
                  color="primary"
                  small
                  style="margin-bottom:5px"
                  @click="selectGrade(grade.trim())"
                  block
                >
                  {{ grade }}</v-btn
                >
              </div>
            </v-card-text></v-card
          >
        </b-col>
        <b-col cols="9">
          <v-card flat outlined
            ><v-card-title> {{ this.grade }} </v-card-title
            ><v-card-text>
              <vue-good-table
                :columns="fields"
                :rows="requests"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'feedback'"> </span>
                  <span v-else-if="props.column.field == 'send'"
                    ><v-icon color="orange">mdi-share</v-icon>Send to
                  </span>
                  <span v-else-if="props.column.field == 'details'">
                    <!-- <v-btn x-small depressed color="info" block
                      ><v-icon size="14">mdi-file</v-icon></v-btn
                    > -->

                    <div v-for="(doc, key) in props.row.data" :key="doc">
                      <div v-if="key == 'requester'"></div>
                      <div v-else-if="key == 'grade'"></div>
                      <div v-else-if="key == 'studentname'"></div>
                      <div v-else-if="key == 'branch'"></div>
                      <div v-else-if="key == 'class'"></div>
                      <div v-else-if="key == 'request'"></div>
                      <div v-else-if="key == 'student'"></div>
                      <div v-else-if="key == 'requestdate'"></div>
                      <div v-else-if="key == 'from'"></div>
                      <div v-else-if="key == 'Date'"></div>
                      <div v-else-if="key == 'status'"></div>
                      <div v-else>
                        <span style="color:red; font-size:small;">{{
                          key.toUpperCase()
                        }}</span>
                        :
                        <span v-if="key.toUpperCase() == 'DOCUMENTS'">
                          <a :href="doc" target="_blank"
                            >Click here to open the Doc</a
                          >
                        </span>
                        <span v-else>
                          {{ doc }}
                        </span>
                      </div>
                    </div>

                    <v-divider />

                    <v-text-field
                      label="Enter your feedback"
                      v-model="props.row.feedback"
                    />
                    <v-btn
                      depressed
                      color="info"
                      block
                      x-small
                      dark
                      @click="savefeedback(props.row.path, props.row.feedback)"
                      >Save Feedback</v-btn
                    >
                    <v-divider />

                    <v-btn-toggle borderless>
                      <v-btn
                        small
                        depressed
                        color="success"
                        block
                        @click="saveStatus(props.row.path, 'approved')"
                        >Approved</v-btn
                      >
                      <v-btn
                        small
                        depressed
                        color="error"
                        block
                        @click="saveStatus(props.row.path, 'rejected')"
                        >Reject</v-btn
                      >
                    </v-btn-toggle>
                  </span>
                  <span v-else-if="props.column.field == 'status'">
                    <v-chip
                      color="red"
                      v-if="props.row.status == 'rejected'"
                      dark
                      small
                      >Rejected</v-chip
                    >
                    <v-chip
                      color="success"
                      v-else-if="props.row.status == 'approved'"
                      dark
                      small
                      >Approved</v-chip
                    >
                    <v-chip small color="info" v-else dark>Pending</v-chip>
                  </span>
                </template>
              </vue-good-table>
            </v-card-text></v-card
          >
        </b-col>
      </b-row>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "Requests-page",
  components: {},

  data() {
    return {
      emp: this.$store.state.userID,
      grade: "",
      data: {},
      grades: [],
      requests: [],
      record: {},
      fields: [
        // {
        //   field: "send",
        //   label: "Send",
        //   sortable: true,
        //   class: "text-left"
        // },
        {
          field: "status",
          label: "Status",
          sortable: true,
          class: "text-left"
        },
        {
          field: "request",
          label: "Request",
          sortable: true,
          class: "text-left"
        },

        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },

        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "requestdate",
          label: "Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "details",
          label: "Details",
          sortable: true,
          class: "text-left",
          width: "40%"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .doc(
        "schools/" +
          this.$store.state.school +
          "/employees/" +
          this.$store.state.userID
      )
      .get()
      .then(querySnapshot => {
        this.data = "";

        this.data = querySnapshot.data();
        this.grades = this.data.admingrades.split(",");
      });
  },
  methods: {
    savefeedback(path, feedback) {
      this.record.feedback = feedback;

      firebase
        .firestore()
        .doc(path)
        .update({ feedback: feedback })
        .then(() => {})
        .catch(error => {
          alert("Error removing document: ", error);
        });
    },
    selectGrade(grade) {
      this.grade = grade;
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/allrequests")
        .where("branch", "==", this.data.branch)
        .where("grade", "==", grade)
        .onSnapshot(querySnapshot => {
          this.requests = [];
          querySnapshot.forEach(doc => {
            this.requests.push({
              key: doc.id,
              path: doc.ref.path,
              grade: doc.data().grade,
              class: doc.data().class,
              studentname: doc.data().studentname,
              Reason: doc.data().Reason,
              requestdate: doc.data().requestdate,
              request: doc.data().request,
              status: doc.data().status,
              data: doc.data()
            });
          });
        });
    },

    saveStatus(path, status) {
      this.record.status = status;
      firebase
        .firestore()
        .doc(path)
        .update({ status: status })
        .then(() => {})
        .catch(error => {
          alert("Error removing document: ", error);
        });
    }
  }
};
</script>
