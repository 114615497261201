<template>
  <div>
    <img src="@/assets/coa.jpg" width="100%" />
  </div>
</template>
<script>
export default {
  name: "Accounting-page"
};
</script>
