<template>
  <div>
    <div><b style="color:blue">Bloom:</b> {{ question.bloom }}</div>
    <div><b style="color:blue">DOK:</b> {{ question.dok }}</div>
    <div><b style="color:blue">Difficulty:</b> {{ question.difficulty }}</div>
    <v-divider />
    <h5 v-html="question_body" style="color:#2a8ee6" class="foo"></h5>
    <div v-if="question.sound">
      <Audio style="margin-left:10px" :src="question.sound" controls></Audio>
    </div>
    <v-divider />

    <!-- Multiple Choice -->
    <div
      v-if="
        question.qtype == 'mc' ||
          question.qtype == null ||
          question.qtype == 'listening'
      "
    >
      <v-list-item-group color="primary">
        <v-list-item v-for="option in options" :key="option.id">
          <v-list-item-content>
            <h6 v-if="option.grade == 1">
              <v-card flat outlined
                ><v-card-text>
                  <v-icon color="green">mdi-check</v-icon> {{ option.title }}

                  <div v-if="option.sound">
                    <Audio
                      style="margin-left:10px"
                      :src="option.sound"
                      controls
                    ></Audio>
                  </div>
                </v-card-text>
              </v-card>
            </h6>
            <h6 v-else>
              <v-card flat outlined
                ><v-card-text>
                  <v-icon color="red">mdi-close</v-icon> {{ option.title }}

                  <div v-if="option.sound">
                    <Audio
                      style="margin-left:10px"
                      :src="option.sound"
                      controls
                    ></Audio>
                  </div> </v-card-text
              ></v-card>
            </h6>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </div>
    <!-- True/False -->
    <div v-else-if="question.qtype == 'tf'">
      <v-btn
        class="mx-2"
        fab
        dark
        x-large
        color="red"
        depressed
        @click="checkRight(question.correct, 'wrong')"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        x-large
        color="green"
        depressed
        @click="checkRight(question.correct, 'right')"
      >
        <v-icon dark>
          mdi-check
        </v-icon>
      </v-btn>
    </div>

    <!-- Complete -->
    <div v-else-if="question.qtype == 'complete'">
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="The Missing Word"
            outlined
            rounded
            v-model="missing"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" style="text-align:right">
          <v-btn
            depressed
            rounded
            color="green"
            dark
            @click="checkComplete(question.correct, missing)"
            >Check Answer</v-btn
          >
        </v-col></v-row
      >
    </div>

    <br />
    <!-- Answer Feedback -->
    <div style="text-align:center">
      <div v-html="question_answer" class="foo"></div>
    </div>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Qustion-page",
  props: ["path"],

  data() {
    return {
      missing: "",
      wrong: false,
      right: false,
      question: {},
      options: [],
      question_body: "",
      question_answer: "",
      ref: firebase.firestore().doc(this.path)
    };
  },

  created() {
    this.ref.get().then(doc => {
      if (doc.exists) {
        this.question = doc.data();
      } else {
        alert("No such document!");
      }
    });

    firebase
      .firestore()
      .doc(this.path + "/details/details")
      .get()
      .then(querySnapshot => {
        this.question_body = querySnapshot.data().body;
        this.question_answer = querySnapshot.data().answer;
      });

    firebase
      .firestore()
      .collection(this.path + "/options/")
      .onSnapshot(querySnapshot => {
        this.options = [];
        querySnapshot.forEach(doc => {
          this.options.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            grade: doc.data().grade,
            sound: doc.data().sound,
            dok: doc.data().dok,
            bloom: doc.data().bloom,
            difficulty: doc.data().difficulty
          });
        });
      });
  },

  methods: {
    checkComplete(answer, action) {
      if (answer.trim().toLowerCase() == action.trim().toLowerCase()) {
        this.right = true;
        this.wrong = false;
        this.$store.state.rightAnswers++;
        this.$store.state.lessonrightAnswers++;
      } else {
        this.right = false;
        this.wrong = true;
        this.$store.state.wrongAnswers++;
        this.$store.state.lessonwrongAnswers++;
      }
    },

    checkAnswer(grade) {
      if (this.right == false && this.wrong == false) {
        if (grade == "1") {
          this.right = true;
          this.wrong = false;
          this.$store.state.rightAnswers++;
          this.$store.state.lessonrightAnswers++;
        } else {
          this.right = false;
          this.wrong = true;
          this.$store.state.wrongAnswers++;
          this.$store.state.lessonwrongAnswers++;
        }
      }
    },

    checkRight(answer, action) {
      if (
        (answer == "true" && action == "right") ||
        (answer == "false" && action == "wrong")
      ) {
        this.right = true;
        this.wrong = false;
        this.$store.state.rightAnswers++;
        this.$store.state.lessonrightAnswers++;
      } else {
        this.right = false;
        this.wrong = true;
        this.$store.state.wrongAnswers++;
        this.$store.state.lessonwrongAnswers++;
      }
    }
  }
};
</script>
<style scoped>
.fooar >>> img {
  width: 400px;
}
.fooar {
  text-align: right;
  direction: rtl;
  width: 100%;
}
.foo >>> img {
  width: 300px;
}
</style>
