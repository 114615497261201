<template>
  <v-card flat outlined>
    <v-card-title>
      <span class="headline">
        <v-icon style="margin-right: 10px">mdi-table-arrow-left</v-icon>Import
        Domains
      </span>

      <v-divider></v-divider>
      <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
    </v-card-title>
    <v-card-subtitle>
      {{ this.$store.state.subject + "/domains" }}</v-card-subtitle
    >
    <v-card-text>
      <div class="app-container">
        <v-card flat outlined
          ><v-card-text>
            <upload-excel-component
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
            />
            <br />
            <v-progress-linear
              v-if="progress"
              color="light-blue"
              height="20"
              :value="progress"
              striped
            ></v-progress-linear>
            <br />
            <div v-if="tableData">Rows Count: {{ tableData.length }}</div>
            <div v-if="tableData">Rows Imported: {{ this.current }}</div>
            <br />
            <!--  <b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
            <b-button
              variant="success"
              style="color: #ffffff"
              href="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/templates%2Fdomains.xlsx?alt=media&token=9c49d658-2ec6-46e3-a6bf-486c752dbd89"
              >Download Excel Template</b-button
            >
            <b-button @click="importData" style="margin-left: 20px"
              >Start Importing</b-button
            >
            <!-- <b-button style="margin-left: 20px">Change Egyption</b-button>
         <b-button variant="info" @click="transfer" style="margin-left:20px;"
          >Start Transfer</b-button
        >-->

            <el-table
              :data="tableData"
              border
              highlight-current-row
              style="width: 100%; margin-top: 20px"
              empty-text="There Is No Data Yet"
            >
              <el-table-column
                v-for="item of tableHeader"
                :key="item"
                :prop="item"
                :label="item"
              />
            </el-table>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
    </v-card-actions>
  </v-card>

  <!-- End Activities Hub -->
</template>
<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";
export default {
  params: ["path"],
  components: { UploadExcelComponent },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      dialog: false,
      domains: {},
      activities: [],
      newStandard: {},
      current: 0,
      progress: 0
    };
  },

  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    importData: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        //! Add Parent Domain

        this.domains = {};

        this.domains.code = this.tableData[i]["code"];
        this.domains.title = this.tableData[i]["title"];

        firebase
          .firestore()
          .doc(this.$store.state.subject + "/domains/" + this.domains.code)
          //.doc(this.domains.code)
          .set(this.domains)
          .then(() => {
            console.log("Domain Success Added");
            this.current++;
            this.progress = (this.tableData.length / this.current) * 100;
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  }
};
</script>
