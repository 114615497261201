<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button pill variant="info" v-bind="attrs" v-on="on" size="sm">
        <v-icon size="20">mdi-shape-outline</v-icon>
        <small style="margin-left: 10px;">Select Category</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="padding-right: 20px;">mdi-shape-outline</v-icon>Select
          Category
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- School -->

        <br />

        <vue-good-table
          :columns="sfields"
          :rows="students"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records', perPage: 5 }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <v-btn
                small
                depressed
                color="blue"
                @click="startChat(props.row.key)"
                style=" color:#ffffff;"
              >
                <v-icon>mdi-forum-outline</v-icon>&nbsp; Start Chat
              </v-btn>
            </span>
            <span v-else-if="props.column.field == 'type'">
              <v-icon color="red">mdi-video-outline</v-icon>
            </span>
          </template>
        </vue-good-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "selectStudent",
  props: ["path", "order"],
  data() {
    return {
      dialog: false,
      activities: [],
      students: [],
      grades: [],
      subjects: [],
      selectedStudent: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      sfields: [
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firstname",
          label: "First Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Last Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/StudentsData/")
      .onSnapshot(querySnapshot => {
        this.students = [];
        querySnapshot.forEach(doc => {
          this.students.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            grade: doc.data().grade,
            class: doc.data().class
          });
        });
      });
  },
  methods: {
    startChat(student) {
      this.selectedStudent.category = "Student";
      this.selectedStudent.owner = this.$store.state.username;
      this.selectedStudent.receiver = student;

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/rooms/")
        .add(this.selectedStudent)
        .then(() => {
          this.dialog = false;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
