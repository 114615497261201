<template>
  <b-row>
    <b-col cols="12">
      <h2>
        Edit Week
        <b-link href="#/">(Weeks List)</b-link>
      </h2>
      <b-jumbotron>
        <template slot="header">
          {{ board.title }}
        </template>
        <template slot="lead">
          Title: {{ board.title }}<br />
          Section: {{ board.section }}<br />
          Term: {{ board.term }}<br />
        </template>
        <hr class="my-4" />
        <b-btn class="edit-btn" variant="success" @click.stop="editboard(key)"
          >Edit</b-btn
        >
        <b-btn variant="danger" @click.stop="deleteboard(key)">Delete</b-btn>
      </b-jumbotron>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "ShowLawyer",
  data() {
    return {
      key: "",
      board: {}
    };
  },
  created() {
    const ref = firebase
      .firestore()
      .collection(
        "/schools/" +
          this.$store.state.school +
          "/grades/grade1/subjects/arabic/weeks"
      )
      .doc(this.$route.params.id);
    ref.get().then(doc => {
      if (doc.exists) {
        this.key = doc.id;
        this.board = doc.data();
      } else {
        alert("No such document!");
      }
    });
  },
  methods: {
    editboard(id) {
      router.push({
        name: "EditLawyer",
        params: { id: id }
      });
    },
    deleteboard(id) {
      firebase
        .firestore()
        .collection(
          "/schools/" +
            this.$store.state.school +
            "/grades/grade1/subjects/arabic/weeks"
        )
        .doc(id)
        .delete()
        .then(() => {
          router.push({
            name: "LawyersList"
          });
        })
        .catch(error => {
          alert("Error removing document: ", error);
        });
    }
  }
};
</script>

<style>
.jumbotron {
  padding: 2rem;
}
.edit-btn {
  width: 70px;
  margin-right: 20px;
}
</style>
