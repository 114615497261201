var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-text',[(_vm.master == true)?_c('AddAssignment',{attrs:{"title":this.$route.params.title,"week":this.$route.params.path}}):_vm._e(),_c('br'),_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"success","text-variant":"white","header":"Assignments"}},[_c('b-card-text',[_c('h1',[_vm._v(_vm._s(this.assignments.length))])])],1),_c('CountCard',{attrs:{"title":"InActive","color":"danger"}})],1),_c('br'),_c('vue-good-table',{attrs:{"columns":_vm.fields,"rows":_vm.assignments,"search-options":{ enabled: true, skipDiacritics: true },"pagination-options":{ enabled: true, mode: 'records' }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(_vm.master == true)?_c('v-btn',{attrs:{"color":"error","depressed":"","x-small":""},on:{"click":function($event){return _vm.deleteAssignment(props.row.key, props.row.title)}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-delete-outline")])],1):_vm._e(),_vm._v("   "),(_vm.master == true)?_c('v-btn',{attrs:{"color":"info","depressed":"","x-small":""},on:{"click":function($event){return _vm.goToEdit(props.row.title, props.row.path)}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-pencil-outline")])],1):_vm._e(),_vm._v("   "),_c('v-btn',{attrs:{"color":"cyan","depressed":"","x-small":""},on:{"click":function($event){return _vm.goToView(props.row.title, props.row.path)}}},[_c('v-icon',{attrs:{"color":"white","size":"14"}},[_vm._v("mdi-eye")])],1),_vm._v("   "),_c('v-btn',{attrs:{"color":"success","x-small":"","depressed":""},on:{"click":function($event){return _vm.goToSubmissions(
                    props.row.key,
                    props.row.title,
                    props.row.path,
                    props.row.grade,
                    props.row.key,
                    props.row.type
                  )}}},[_vm._v(" Submissions "),_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-format-list-checks")])],1),_vm._v("   "),_c('v-btn',{attrs:{"color":"info","x-small":"","depressed":""},on:{"click":function($event){return _vm.goToExtend(
                    props.row.key,
                    props.row.title,
                    props.row.path,
                    props.row.grade,
                    props.row.key,
                    props.row.type
                  )}}},[_vm._v(" Extend "),_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-format-list-checks")])],1)],1):(props.column.field == 'note')?_c('span',[(_vm.master == true)?_c('div',[(props.row.note)?_c('v-badge',{attrs:{"bordered":"","color":"success","icon":"mdi-check","overlap":""}},[_c('Note',{key:props.row.key,attrs:{"path":props.row.path,"link":props.row.note}})],1):_c('Note',{key:props.row.key,attrs:{"path":props.row.path}})],1):_vm._e()]):(props.column.field == 'weight')?_c('span',[_vm._v(" "+_vm._s(props.row.weight)+" "),(props.row.graded == true)?_c('div',[_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v("Not Gradable")])],1):_vm._e()]):(props.column.field == 'title')?_c('span',[_vm._v(" "+_vm._s(props.row.title)+" "),(props.row.file)?_c('p',[_c('a',{attrs:{"href":props.row.file,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-paperclip")])],1)]):_vm._e()]):(props.column.field == 'questions')?_c('span'):(props.column.field == 'type')?_c('span',[(props.row.type == 'Textbook Assignment')?_c('div',[_vm._v(" Assignment ")]):_c('div',[_vm._v("Digitized Assignment")])]):_vm._e()]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }