<template>
  <!-- Side Exam Panel -->
  <v-row>
    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/intro.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">
                  {{ this.weighttitle }} Details
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subjecttitle + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9">
      <v-container>
        <v-card>
          <v-card-title
            >{{ this.weighttitle }}

            <v-divider />
<h3>
  {{ this.studentAttendance }} %
</h3>
            <!-- <h3>
              {{
                Math.round(
                  this.attendance.length == 0
                    ? "0"
                    : ((this.attendance.length - this.absence.length) /
                        this.attendance.length) *
                        100
                )
              }}
              %
            </h3> -->
          </v-card-title>
          <v-card-subtitle style="color:green">
            {{ this.weightpre }}% of {{ this.subjecttitle }} Total Grade
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-card flat outlined
                  ><v-card-text>
                    <b-card-group deck>
                      <b-card
                        bg-variant="info"
                        text-variant="white"
                        header="Total Days"
                        class="text-center"
                      >
                        <b-card-text>
                          <!-- <h1>{{ this.attendance.length }}</h1> -->
                          <h1>{{ this.total }}</h1>
                        </b-card-text>
                      </b-card>
                      <b-card
                        bg-variant="secondary"
                        text-variant="white"
                        header="Excuse"
                        class="text-center"
                      >
                        <b-card-text>
                          <!-- <h1>{{ this.excuse.length }}</h1> -->
                          <h1>{{ this.excuses }}</h1>
                        </b-card-text>
                      </b-card>
                      <b-card
                        bg-variant="danger"
                        text-variant="white"
                        header="Absence"
                        class="text-center"
                      >
                        <b-card-text>
                          <!-- <h1>{{ this.absence.length }}</h1> -->
                          <h1>{{ this.absences }}</h1>
                        </b-card-text>
                      </b-card>
                    </b-card-group>
                  </v-card-text></v-card
                >

                <br />
              </v-col>
            </v-row>

            <v-card
              v-for="att in attendance"
              :key="att.key"
              flat
              outlined
              style="margin-bottom:10px;"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <h6>{{ att.date }}</h6></v-col
                  >
                  <v-col cols="6" style="text-transform: uppercase;">
                    <v-chip
                      label
                      :color="att.type == 'present' ? 'success' : 'error'"
                    >
                      {{ att.type }}</v-chip
                    ></v-col
                  >
                </v-row>
              </v-card-text></v-card
            >
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";

export default {
  name: "AttendanceDetails--page",
  components: {},

  data() {
    return {
      attendance: [],

      absence: [],
      present: [],
      excuse: [],

      absences: sessionStorage.getItem("absence"),
      presents: 0,
      excuses: sessionStorage.getItem("excuse"),
      total: sessionStorage.getItem("total"),
      studentAttendance:sessionStorage.getItem("StudentAttendance"),

      loading: false,
      subjectpath: this.$store.state.subject,
      studentpath: this.$store.state.studentpath,
      weighttitle: this.$store.state.weighttitle,
      weightpre: this.$store.state.weightpre,
      subjecttitle: this.$store.state.subjecttitle,
      grade: this.$store.state.grade
    };
  },

  mounted() {
    this.attendance = [];
    this.absence = [];
    this.present = [];
    this.excuse = [];

    //! Get Student Attentance
    firebase
      .firestore()
      .collection(this.studentpath + "/attendance")
      .where("subject", "==", this.subjecttitle)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          //! All Days
          this.attendance.push({
            key: doc.id,
            date: doc.data().date,
            type: doc.data().type
          });

          if (doc.data().type == "absence") {
            this.absence.push({
              key: doc.id,
              date: doc.data().date,
              type: doc.data().type
            });
          } else if (doc.data().type == "present") {
            this.present.push({
              key: doc.id,
              date: doc.data().date,
              type: doc.data().type
            });
          } else if (doc.data().type == "excuse") {
            this.excuse.push({
              key: doc.id,
              date: doc.data().date,
              type: doc.data().type
            });
          }
        });
        this.loading = true;
      });
  },

  methods: {}
};
</script>
