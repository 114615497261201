<template>
  <v-row
    ><v-col cols="12">
      <v-card flat outlined>
        <v-card-title>Lesson Reports</v-card-title>
        <v-card-subtitle>{{ this.$route.params.title }}</v-card-subtitle>

        <v-card-text>

         
          <b-row>
            <b-col cols="3" style="background-color:#eee">
              <v-toolbar color="green light-3" dark flat>
                <v-toolbar-title>
                  <img
                    src="@/assets/students.png"
                    class=" ma-2"
                    width="44px"
                  />&nbsp;Classes List
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-btn
                @click="clearsearch()"
                x-small
                color="#66BB6A"
                block
                dark
                style="margin-bottom:10px"
                depressed
                >Clear Search</v-btn
              >

              <v-list shaped style="background-color:#d7e9f9">
                <v-list-item
                  v-for="c in classList"
                  :key="c"
                  href="#"
                  @click="loadClass(c)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-google-classroom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ c }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </b-col>
            <b-col cols="9">
              <v-row style="margin-top: -15px;">
                <v-col cols="1"> </v-col>

                <v-col cols="5">
                  <v-row></v-row>
                </v-col>

                <v-col cols="6">
                  <h6 style="color:#1B5E20">Lesson's Attempts</h6>
                </v-col>
              </v-row>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(student, i) in students" :key="i">
                    <v-list-item-content>
                      <StudentRecord
                        :student="student.path"
                        :firstname="student.firstname"
                        :lastname="student.lastname"
                        :image="student.image"
                        :week="$route.params.path"
                        :title="$route.params.title"
                        :sclass="student.sclass"
                        :student_id="student.key"
                        :path="path"
                      ></StudentRecord>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </b-col> </b-row></v-card-text
      ></v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import StudentRecord from "@/components/lms/reports/studentRecord";

export default {
  name: "LessonReport",
  components: { StudentRecord },
  props: ["path", "title"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      record: {},
  
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "student",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "absence",
          label: "",
          class: "text-center",
          width: "100px"
        },

        { field: "actions", label: "", class: "text-center" }
      ],
      classes: [],
      classList: JSON.parse(localStorage.getItem("classList")),
      grade: this.$store.state.thisGrade,
      gradePath: this.$store.state.gradePath,
      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.gradePath + "/classes")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.classes = [];
      querySnapshot.forEach(doc => {
        this.classes.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title
        });
      });
    });
    this.students = [];
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/students")
      .where("educationPath", "==", this.gradePath)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.students.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            middlename: doc.data().middlename,
            lastname: doc.data().lastname,
            image: doc.data().image,
            sclass: doc.data().class
          });
        });
      });
  },
  methods: {
    clearsearch() {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              image: doc.data().image,
              sclass: doc.data().class
            });
          });
        });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              image: doc.data().image,
              sclass: doc.data().class
            });
          });
        });
    }
  }
};
</script>
