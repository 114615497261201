<template>
  <v-card class="mx-auto" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">Total</div>
        <v-list-item-title class="headline mb-1">{{
          this.title
        }}</v-list-item-title>
        <h1>{{ records.length }}</h1>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-icon size="60px" :color="this.color">{{ this.icon }}</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "CountCard",
  props: ["path", "color", "title", "icon"],
  data() {
    return {
      records: [],
      ref: firebase.firestore().collection(this.path)
    };
  },
  created() {
    if (this.path != null) {
      this.ref.onSnapshot(querySnapshot => {
        this.records = [];
        querySnapshot.forEach(doc => {
          this.records.push({
            key: doc.id
          });
        });
      });
    }
  }
};
</script>
