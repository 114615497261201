<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img src="@/assets/hr.png" class=" ma-2" width="44px" />
          <span class="title font-weight-light">HR Department</span>
          <v-row align="center" justify="end">
            <download-excel
              class="btn btn-default"
              :data="employees"
              :fields="fexport"
              type="csv"
              name="employees.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
              <small>Export to Excel</small>
            </download-excel>
          </v-row>
        </v-card-title>

        <v-card-text>
          <b-card-group deck>
            <CountCardStatic
              title="Employees"
              color="primary"
              :count="employees.length"
            ></CountCardStatic>
          </b-card-group>

          <v-card flat outlined style="margin-top:10px">
            <p class="card-text">
              <v-btn
                color="blue"
                dark
                @click="goToPage('AddEmployee')"
                depressed
                small
                style="margin-bottom:20px"
                >Add New Employee</v-btn
              >
           


 <vue-good-table
      :columns="fields"
      :rows="employees"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
     <template slot="table-row" slot-scope="props">
       <span v-if="props.column.field == 'actions'">
      <v-btn
                    small
                    color="error"
                    depressed
                    @click="deleteboard(props.row.path, props.row.name)"
                  >
                    <v-icon size="14">mdi-delete-outline</v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn small color="info" depressed @click="Edit(props.row.path)">
                    <v-icon size="14">mdi-pencil-outline</v-icon>
                  </v-btn>
       </span>


      
     </template>
    </vue-good-table>


              
            </p>
          </v-card>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import CountCardStatic from "@/components/dna/widgets/CountCardStatic";


export default {
  name: "EmployeesList",
  components: {
    CountCardStatic,

  },

  data() {
    return {
      fields: [

    

         {
          field: "name",
          label: "Employee Name",
          class: "text-center",
       
        },
         {
          field: "username",
          label: "Employee Email",
          class: "text-center",
   
        },
         {
          field: "job",
          label: "Job Title",
          class: "text-center",
         
        },
         {
          field: "actions",
          label: "",
          class: "text-center",
          width: "100px"
        },



       
      ],
      fexport: {
        "First Name": "firstname",
        "Last Name": "lastname",
        Job: "job"
      },
      employees: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/employees")
    };
  },
  created() {
    this.loadDate();
  },
  methods: {
    loadDate() {
      this.ref.get().then(querySnapshot => {
        this.employees = [];
        querySnapshot.forEach(doc => {
          this.employees.push({
            key: doc.id,
            path: doc.ref.path,
            name: doc.data().firstname + " " + doc.data().lastname,
            username: doc.data().username,
            job: doc.data().job,
            access: doc.data().access
          });
        });
      });
    },
    switchUser(access, firstname, lastname, job, userID, username) {
      localStorage.setItem("userID", userID);
      localStorage.setItem("firstname", firstname);
      localStorage.setItem("lastname", lastname);
      localStorage.setItem("username", username);
      localStorage.setItem("job", job);
      localStorage.setItem("rules", JSON.stringify(access));
      localStorage.setItem("logo", this.logo);
      localStorage.setItem("loggedIn", true);

      this.$store.state.userID = userID;
      this.$store.state.firstname = firstname;
      this.$store.state.lastname = lastname;
      this.$store.state.username = username;
      this.$store.state.rules = JSON.stringify(access);
      this.$store.state.job = job;
      this.$store.state.loggedIn = true;

      this.$router.replace({
        name: "Home"
      });
      window.location.reload();
      //this.$router.reload();
    },
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  title,
                  "HR",
                  "Employee"
                );
                this.loadDate();
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });

            // firebase.auth().delete(email);
          }
        }
      });
    },
    ResetPassword(username) {
      firebase
        .auth()
        .sendPasswordResetEmail(username)
        .then(function() {
          alert("The password reset email has been sent");
        })
        .catch(function(error) {
          console.log(error);
        });

      // firebase.auth()
      //   .deleteUser("N8a5kOmEkrPaCN2aoyO9xRsyrP73")
      //   .then(() => {
      //     alert("deleted");
      //   })
      //   .catch(error => {
      //     alert("Error removing document: ", error);
      //   });
    },

    Edit(id) {
      router.push({ name: "EditEmployee", params: { path: id } });
    },
    gotoTimeSheet(path, empname) {
      router.push({
        name: "Timesheet",
        params: { empname: empname, path: path }
      });
    },
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
