<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar color="light-blue darken-3" dark>
        <v-toolbar-title>
          <v-icon left size="40px">mdi-account-cog-outline</v-icon>Admission
          Setting
        </v-toolbar-title>
      </v-toolbar>

      <b-card-group deck>
        <SettingCard
          title="Application Status"
          icon="mdi-file-clock-outline"
          link="#"
        ></SettingCard>
        <SettingCard
          title="Availability"
          icon="mdi-lock-clock"
          link="#"
        ></SettingCard>
        <SettingCard title="Locations" icon="mdi-domain" link="#"></SettingCard>
        <SettingCard
          title="General"
          icon="mdi-badge-account-horizontal-outline"
          link="#"
        ></SettingCard>
      </b-card-group>
    </v-col>
  </v-row>
</template>
<script>
import SettingCard from "@/components/dna/widgets/SettingCard";
export default {
  name: "AdmissionSetting",
  components: { SettingCard }
};
</script>
