<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>Academic Report</v-card-title>
        <v-card-text>
          <v-card flat style="background-color:#d7e9f9; margin-bottom:20px;">
            <v-card-text>
              <!-- School -->
              <v-row>
                <v-col class="d-flex" cols="4" sm="4">
                  <v-select
                    :items="schools"
                    v-model="report.school"
                    label="School"
                    @change="onSchoolChange()"
                  ></v-select>
                </v-col>
                <!-- Grade Level -->

                <v-col class="d-flex" cols="4" sm="4">
                  <v-select
                    :items="level"
                    v-model="report.grade"
                    label="Grade Level"
                    @change="onGradeChange()"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div v-if="subjects.length > 0">
            <v-card
              hover
              v-for="subject in subjects"
              :key="subject.key"
              flat
              outlined
              style="margin-bottom:20px"
            >
              <v-card-text>
                <img
                  src="@/assets/dashboard/academic.png"
                  width="50px"
                  style="margin-right:20px"
                />
                {{ subject.title }}
                <v-divider />
                <v-btn
                  depressed
                  color="success"
                  @click="
                    goToSubject(subject.title, subject.path, subject.mirror)
                  "
                  >Resources Report</v-btn
                >
                <v-btn
                  depressed
                  color="info"
                  class="ma-2"
                  @click="
                    goToProgress(subject.title, subject.path, subject.mirror)
                  "
                  >Progress Report</v-btn
                >
              </v-card-text>
            </v-card>
          </div>
          <div v-else style="height:300px; text-align:center;">
            <img src="@/assets/nodata.png" class=" ma-2" width="140px" />
            <br />
            Please Select The Grade Level
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "Report-page",
  components: {},
  props: ["path"],
  data() {
    return {
      report: {},
      schools: [],
      level: [],
      subjects: [],
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  created() {
    this.schoolsRef
      .where("active", "==", true)
      .get()
      .then(querySnapshot => {
        this.schools = [];
        querySnapshot.forEach(doc => {
          this.schools.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  },
  methods: {
    onGradeChange() {
      this.classes = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.report.school +
            "/grades/" +
            this.report.grade +
            "/subjects"
        )
        .onSnapshot(querySnapshot => {
          this.subjects = [];
          querySnapshot.forEach(doc => {
            this.subjects.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              mirror: doc.data().mirror
            });
          });
        });
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.report.school +
            "/grades/"
        )
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    goToSubject(title, path, mirror) {
      this.$store.commit("reporttitle", title);
      this.$store.commit("reportpath", path);
      this.$store.commit("reportmirror", mirror);
      this.$store.commit("reportbranch", this.report.school);
      this.$store.commit("reportgrade", this.report.grade);

      router.push({
        name: "ResourcesReport"
      });
    },
    goToProgress(title, path, mirror) {
      this.$store.commit("reporttitle", title);
      this.$store.commit("reportpath", path);
      this.$store.commit("reportmirror", mirror);
      this.$store.commit("reportbranch", this.report.school);
      this.$store.commit("reportgrade", this.report.grade);

      router.push({
        name: "sProgressReport"
      });
    }
  }
};
</script>
