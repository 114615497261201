<template>
  <v-row>
    <v-col cols="8">
      <v-card>
        <v-card-text><FleetList /> </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card
        flat
        style="cursor: pointer; text-align:center;"
        @click="goToPage('Drivers')"
      >
        <v-card-text>
          <img src="@/assets/drivers.png" height="80px" />

          <h6 style="padding-top:20px">
            Drivers
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('Supervisors')"
      >
        <v-card-text>
          <img src="@/assets/supervisors.png" height="80px" />

          <h6 style="padding-top:20px">
            Supervisors
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('Services')"
      >
        <v-card-text>
          <img src="@/assets/services.png" height="80px" />

          <h6 style="padding-top:20px">
            Fleet <span style="color:#3096b2;">Services</span>
          </h6>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import firebase from "@/Firebase";
import router from "@/router";
import FleetList from "@/components/fleet/FleetList";

export default {
  name: "ManageFleet",
  components: { FleetList },

  data() {
    return {
      errors: [],
      subjects: [],

      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: ""
    };
  },
  created() {},
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
