<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button
        variant="#fff"
        style="background-color:#01b7f2; color:#000; width:30%;"
        v-bind="attrs"
        v-on="on"
        squared
      >
        <img src="@/assets/target.png" width="40px" />
        <small style="margin-left: 10px;">Create Remedial</small>
      </b-button>
    </template>
    <v-card flat outlined>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-alpha-e-box</v-icon>Create
          Remedial
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <b-row>
          <b-col cols="12">
            <v-card outlined flat>
              <v-card-text>
                <b-form @submit.stop.prevent="onSubmit">
                  <!-- Week Name -->

                  <b-form-checkbox
                    v-model="week.hidden"
                    name="check-button"
                    switch
                  >
                    Disable Remedial
                  </b-form-checkbox>
                  <br />

                  <b-form-group
                    id="fieldseweeks"
                    horizontal
                    :label-cols="12"
                    breakpoint="md"
                  >
                    <!--Revision Select Weeks-->
                    <v-autocomplete
                      v-model.trim="selectedWeek"
                      :items="weeks"
                      dense
                      label="Select Week"
                    ></v-autocomplete>

                    <div style="margin-bottom:20px;">
                      <v-btn
                        color="success"
                        small
                        depressed
                        @click="addToRevision"
                        >Add Week to Remedial</v-btn
                      >
                      <v-btn
                        style="margin-left:10px; "
                        color="error"
                        small
                        depressed
                        @click="clearWeeks"
                        >Clear Weeks</v-btn
                      >
                    </div>

                    <RevisionWeek
                      v-for="week in revisionWeeks"
                      :key="week"
                      :path="week"
                    />
                  </b-form-group>

                  <v-card flat outlined
                    ><v-card-text>
                      <v-row>
                        <v-col cols="2">
                          <v-autocomplete
                            v-model.trim="selectedClass"
                            :items="classes"
                            index="type"
                            dense
                            label="Select Class"
                            required
                            @change="onClassChange"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="8">
                          <v-autocomplete
                            v-model.trim="selected"
                            :items="students"
                            index="type"
                            dense
                            label="Student Name"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="2"> </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <!-- <v-btn small color="info" depressed>Add All Class</v-btn> -->
                      <v-btn
                        small
                        color="warning"
                        depressed
                        @click="addToExtend"
                        >Add Student</v-btn
                      >
                      <v-divider />
                    </v-card-actions>
                  </v-card>
                  <v-card flat outlined style="margin-top:20px"
                    ><v-card-text>
                      <div
                        v-for="(student, index) in selectedPathes"
                        :key="student.key"
                        style="margin-bottom:10px"
                      >
                        <v-row>
                          <v-btn
                            color="error"
                            x-small
                            depressed
                            @click="remove(index)"
                            style="margin-right:10px"
                            >x</v-btn
                          >
                          <Student :path="student" />
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>

                  <!-- Submit Form -->
                </b-form>
              </v-card-text>
              <v-card-actions>
                <v-divider />
                <b-button @click="onSubmit" variant="primary"
                  >Add Remedial</b-button
                >
              </v-card-actions>
            </v-card>
          </b-col>
        </b-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "@/Firebase";
import RevisionWeek from "./RevisionWeek";
import Student from "@/components/lms/assignments/Student";

// import router from "../router";

export default {
  name: "AddWeek",
  props: ["path", "order"],
  components: { RevisionWeek, Student },
  data() {
    return {
      dialog: false,
      standarop: [{ standard: "", sub: "" }],
      standards_count: 0,
      standards: [],
      selectedPathes: [],
      selectedWeek: "",
      selectedClass: "",
      sub: [],
      ref: firebase.firestore().collection(this.path + "/weeks"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      date: new Date().toISOString().substr(0, 10),
      week: {},
      weeks: [],
      revisionWeeks: [],
      menu2: false,
      selected: null,
      options: [
        { value: "1", text: "First Term" },
        { value: "2", text: "Second Term" }
      ],
      sections: [],
      //Upload Weekly Plan

      grade: this.$store.state.thisGrade,
      branch: this.$store.state.thisBranch,
      students: [],
      classes: [],

      imageData: null,
      weekly: null,
      uploadValue: 0,

      sectionsRef: firebase.firestore().collection(this.path + "/sections/")
    };
  },
  methods: {
    onClassChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.branch)
        .where("grade", "==", this.grade)
        .where("class", "==", this.selectedClass)
        .get()
        .then(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              value: [
                doc.ref.path,
                doc.data().firstname +
                  " " +
                  doc.data().middlename +
                  " " +
                  doc.data().lastname
              ],
              text:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname
            });
          });
        });
    },

    remove(index) {
      this.selectedPathes.splice(index, 1);
    },

    addToExtend() {
      if (this.selectedPathes.includes(this.selected[0])) {
        alert("This Student alrady in this Remedial");
      } else {
        this.selectedPathes.push(this.selected[0]);
      }
    },

    onSubmit(evt) {
      evt.preventDefault();
      this.week.order = Number(this.order) + 1;

      this.week.weeks = this.revisionWeeks;
      this.week.students = this.selectedPathes;
      this.week.enrichment = true;
      this.week.title = "Enrichment";

      this.ref
        .add(this.week)
        .then(() => {
          this.week.title = "";
          this.week.term = "";
          this.week.order = "";
          this.week.section = "";
          this.week.weekly = "";
          this.standarop = "";

          this.$bvToast.toast("Remedial Created", {
            title: "Remedial Created Successfully",
            variant: "success",
            solid: true
          });
          this.dialog = false;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    clearWeeks() {
      this.revisionWeeks = [];
    },
    addToRevision() {
      if (this.revisionWeeks.includes(this.selectedWeek)) {
        alert("This Week alrady in this Revision");
      } else {
        this.revisionWeeks.push(this.selectedWeek);
      }
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.weekly = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/Weekly Plan/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.imageData.name
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.weekly = url;
          });
        }
      );
    }
  },
  created() {
    if (
      this.$store.state.mirror == null ||
      this.$store.state.mirror == "" ||
      this.$store.state.mirror == "undefined"
    ) {
      this.subject = this.path;
    } else {
      this.subject = this.$store.state.mirror;
    }
    firebase
      .firestore()
      .collection(this.subject + "/weeks")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.weeks = [];
        querySnapshot.forEach(doc => {
          const week = doc.data();
          this.weeks.push({
            key: doc.id,
            path: doc.ref.path,
            text: week.title,
            value: doc.ref.path
          });
        });
      });

    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.branch +
          "/grades/" +
          this.grade +
          "/classes"
      )
      .get()
      .then(querySnapshot => {
        this.classes = [];
        querySnapshot.forEach(doc => {
          this.classes.push({
            value: doc.data().title,
            text: doc.data().title
          });
        });
      });
  }
};
</script>
