<template>
  <v-card flat outlined>
    <v-card-text>
      <v-text-field
        prepend-icon="mdi-qrcode"
        filled
        background-color="#e5ffc0"
        clearable
        v-model="code"
        label="Scan Student Bracelet"
        type="text"
      />
      <v-btn
        depressed
        block
        color="success"
        small
        style="margin-top:-20px"
        @click="getStudent"
        >Scan</v-btn
      >
      <br />

      <v-text-field
        prepend-icon="mdi-cash"
        filled
        background-color="#afe5fa"
        clearable
        v-model="withdraw"
        label="Withdraw Cash"
        type="text"
      />
      <v-btn
        block
        depressed
        color="info"
        small
        style="margin-top:-20px"
        @click="setwithdraw"
        >Withdraw</v-btn
      >

      <br />
      <v-card flat outlined v-if="this.student.firstname">
        <v-card-title
          >{{
            this.student.firstname +
              " " +
              this.student.middlename +
              " " +
              this.student.lastname
          }}
          <v-divider /> {{ this.student.balance }} L.E
        </v-card-title>
        <v-card-text>
          <p style="color:red">{{ this.student.pos }}</p></v-card-text
        ></v-card
      >
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Test-page",
  created() {},
  data() {
    return {
      code: "",
      withdraw: "",
      student: [],
      studentpath: ""
    };
  },
  props: {},
  methods: {
    getStudent() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("code", "==", this.code)
        .onSnapshot(doc => {
          this.student = doc.docs[0].data();
          this.studentpath = doc.docs[0].ref.path;
        });
    },
    setwithdraw() {
      const newBalance = Number(this.student.balance) - Number(this.withdraw);
      firebase
        .firestore()
        .doc(this.studentpath)
        .update({ balance: newBalance })
        .then(() => {
          this.$swal.queue([
            {
              icon: "success",
              title: "Withdraw Done",
              html: "Withdraw Done Successfully",
              timer: 2000
            }
          ]);
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
