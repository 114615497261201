<template>
  <b-row>
    <b-col cols="12">
      <v-card dark>
        <v-card-title>Student Profile</v-card-title>
        <div class="el-row" style="margin-left: -10px; margin-right: -10px">
          <div
            class="el-col el-col-6 el-col-xs-24"
            style="padding-left: 10px; padding-right: 10px"
          >
            <v-card light>
              <div class="el-card__header">
                <div class="clearfix">
                  <span>{{ student.branch + " - " + student.grade }}</span>
                </div>
              </div>
              <div class="el-card__body">
                <div class="user-profile">
                  <div style="text-align: center">
                    <img
                      class="preview"
                      :src="student.image"
                      width="80px"
                      v-if="student.image != null"
                    />
                    <v-avatar
                      color="blue darken-3"
                      size="62"
                      v-if="student.image == null"
                    >
                      <span class="white--text headline">{{
                        student.firstname[0] +
                          (student.lastname == null ? "" : student.lastname[0])
                      }}</span>
                    </v-avatar>
                  </div>
                  <br />
                  <div class="box-center">
                    <div class="user-name text-center">
                      {{
                        student.firstname +
                          " " +
                          student.middlename +
                          " " +
                          (student.lastname == null ? "" : student.lastname)
                      }}
                    </div>
                    <div class="user-role text-center text-muted">
                      <small>{{
                        student.class == null ? "" : student.class
                      }}</small>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <v-btn
                    small
                    color="green"
                    dark
                    block
                    depressed
                    @click="unlock()"
                    >Unlock Account</v-btn
                  >
                  <v-divider></v-divider>

                  <p>
                    <b class="text-info">
                      <small>Student National ID:</small>
                    </b>
                    &nbsp;
                    <small>{{ student.nationalID }}</small>
                  </p>
                  <p>
                    <b class="text-info">
                      <small>Father National ID:</small>
                    </b>
                    &nbsp;
                    <small>{{ student.father_nationalID }}</small>
                  </p>
                  <p>
                    <b class="text-info">
                      <small>Mother National ID:</small>
                    </b>
                    &nbsp;
                    <small>{{ student.mother_nationalID }}</small>
                  </p>

                  <p>
                    <b class="text-info">
                      <small>Father Mobile:</small>
                    </b>
                    &nbsp;
                    <small>{{ student.father_mobile }}</small>
                  </p>
                  <p>
                    <b class="text-info">
                      <small>Mother Mobile:</small>
                    </b>
                    &nbsp;
                    <small>{{ student.mother_mobile }}</small>
                  </p>

                  <v-divider></v-divider>

                  <div>
                    <small>Behavior</small>
                    <v-progress-linear
                      color="deep-orange"
                      height="10"
                      value="60"
                      striped
                    ></v-progress-linear>

                    <small>Attendance</small>
                    <v-progress-linear
                      color="light-blue"
                      height="10"
                      value="30"
                      striped
                    ></v-progress-linear>

                    <small>Activities</small>
                    <v-progress-linear
                      color="light-green darken-4"
                      height="10"
                      value="80"
                      striped
                    ></v-progress-linear>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <div
            class="el-col el-col-18 el-col-xs-24"
            style="padding-left: 10px; padding-right: 10px"
          >
            <v-card dark style="background-color: #333">
              <!---->
              <div class="el-card__body">
                <v-card-title>{{
                  student.firstname +
                    " " +
                    student.middlename +
                    " " +
                    (student.lastname == null ? "" : student.lastname)
                }}</v-card-title>
                <v-card-subtitle>{{
                  student.branch + " - " + student.grade
                }}</v-card-subtitle>
                <v-tabs vertical dark show-arrows>
                  <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

                  <v-tab>
                    <v-icon left>mdi-account-details-outline</v-icon>
                    <small>Information</small>
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-school-outline</v-icon>
                    <small>Academic</small>
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-alert-circle-check-outline </v-icon>
                    <small>Discipline</small>
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-file-document-outline</v-icon>
                    <small>Documents</small>
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-clipboard-account-outline</v-icon>
                    <small>Medical</small>
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-timeline-text-outline</v-icon>
                    <small>Timeline</small>
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-account-check-outline</v-icon>
                    <small>Attendance</small>
                  </v-tab>

                  <v-tab>
                    <v-icon left>mdi-shield-lock-outline</v-icon>
                    <small>Account</small>
                  </v-tab>

                  <v-tab-item>
                    <v-card flat>
                      <v-card-title>Basic Information</v-card-title>
                      <v-card-text>
                        <div class="row">
                          <div class="col-12">
                            <v-card>
                              <v-card-text>
                                <v-list dense>
                                  <v-subheader
                                    >Personal Information</v-subheader
                                  >
                                  <v-list-item-group>
                                    <Field
                                      title="Date Of Birth"
                                      :value="student.dob"
                                      icon="mdi-baby-carriage"
                                    />
                                    <Field
                                      title="Gender"
                                      :value="student.gender"
                                      icon="mdi-gender-male-female"
                                    />
                                    <Field
                                      title="Citizenship"
                                      :value="student.citizenship"
                                      icon="mdi-home-city-outline"
                                    />
                                    <Field
                                      title="Religion"
                                      :value="student.religion"
                                      icon="mdi-weather-night"
                                    />
                                    <Field
                                      title="Second Language"
                                      :value="student.second_language"
                                      icon="mdi-translate"
                                    />
                                  </v-list-item-group>
                                </v-list>
                                <v-divider></v-divider>
                                <v-list dense>
                                  <v-subheader>Parents Information</v-subheader>
                                  <v-list-item-group>
                                    <Field
                                      title="Father Mobile"
                                      :value="student.father_mobile"
                                      icon="mdi-cellphone-text"
                                    />
                                    <Field
                                      title="Father Email"
                                      :value="student.father_email"
                                      icon="mdi-at"
                                    />
                                    <Field
                                      title="Father Academic Degree"
                                      :value="student.father_academic_degree"
                                      icon="mdi-school-outline"
                                    />
                                    <Field
                                      title="Father Company Business"
                                      :value="student.father_company_business"
                                      icon="mdi-briefcase-outline"
                                    />
                                    <Field
                                      title="Father Occupation"
                                      :value="student.father_occupation"
                                      icon="mdi-clipboard-account-outline"
                                    />
                                    <v-divider></v-divider>
                                    <Field
                                      title="Mother Name"
                                      :value="student.mother_name"
                                      icon="mdi-account-alert-outline"
                                    />
                                    <Field
                                      title="Mother Mobile"
                                      :value="student.mother_mobile"
                                      icon="mdi-cellphone-text"
                                    />

                                    <Field
                                      title="Mother Email"
                                      :value="student.mother_email"
                                      icon="mdi-at"
                                    />
                                    <Field
                                      title="Mother Academic Degree"
                                      :value="student.mother_academic_degree"
                                      icon="mdi-school-outline"
                                    />
                                    <Field
                                      title="Mother Company Business"
                                      :value="student.mother_company_business"
                                      icon="mdi-briefcase-outline"
                                    />
                                    <Field
                                      title="Mother Occupation"
                                      :value="student.mother_occupation"
                                      icon="mdi-clipboard-account-outline"
                                    />
                                  </v-list-item-group>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <Progress :id="student.id"></Progress>
                  </v-tab-item>
                  <v-tab-item>
                    <Discipline :id="student.id"></Discipline>
                  </v-tab-item>
                  <v-tab-item>
                    <v-container>
                      <CRUD
                        title="Document"
                        :path="this.$route.params.id + '/documents'"
                        :fields="this.doc_fields"
                        width="500"
                        height="500"
                      ></CRUD>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item>
                    <Medical :id="student.username"></Medical>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <h6>
                          <v-icon style="margin-right: 10px"
                            >mdi-marker-check</v-icon
                          >{{ student.dob }} {{ student.firstname }} Birth
                        </h6>
                        <h6>
                          <v-icon style="margin-right: 10px"
                            >mdi-marker-check</v-icon
                          >Apply on {{ this.school }}
                        </h6>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-container>
                      <CRUD
                        title="Attendance"
                        :path="this.$route.params.id + '/attendance'"
                        :fields="this.fields"
                        width="500"
                        height="500"
                      ></CRUD>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <!-- Student Account -->

                        <!-- Student Account -->

                        <v-card
                          class="mx-auto"
                          outlined
                          style="
                            background-image: linear-gradient(
                              #033557 10%,
                              #02091b 80%,
                              #033557 10%
                            );
                          "
                          max-width="500"
                          dark
                        >
                          <v-card-title style="color: #2ca8d6">
                            {{ this.school }}

                            <v-divider></v-divider>
                            <img
                              :src="this.$store.state.logo"
                              width="60px"
                              class="rounded-lg"
                              style="background-color: #ffffff; padding: 5px"
                            />
                          </v-card-title>
                          <v-card-subtitle> </v-card-subtitle>

                          <v-card-text>
                            <span style="font-size: 16px; color: #ffffff">
                              <b>{{
                                student.firstname +
                                  " " +
                                  student.middlename +
                                  " " +
                                  student.lastname
                              }}</b></span
                            >
                            <p>
                              {{ student.class == null ? "" : student.class }}
                            </p>
                            <p>
                              <b style="font-size: 12px; color: #2ca8d6"
                                >Username:</b
                              >
                              {{ student.username }}
                            </p>
                            <p>
                              <b style="font-size: 12px; color: #2ca8d6"
                                >Password:</b
                              >
                              {{ student.password }}
                            </p>
                          </v-card-text>
                          <v-card-actions style="font-size: 10px">
                            {{ student.branch + " - " + student.grade }}
                            <v-divider></v-divider>e-account
                          </v-card-actions>
                        </v-card>
                        <Parent
                          :id="student.father_nationalID"
                          relation="Father"
                        />
                        <Parent
                          :id="student.mother_nationalID"
                          relation="Mother"
                        />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-card>
          </div>
        </div>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import Progress from "@/components/sis/academic/Progress";
import Discipline from "@/components/sis/discipline/DisciplinesList";
import Medical from "@/components/sis/medical/MedicalsList";
import Parent from "@/components/sis/students/ParentAccount";
import Field from "@/components/dna/field";
import CRUD from "@/components/dna/crud/ListRecords.vue";

export default {
  name: "ShowStudent",
  components: { Parent, Progress, Discipline, Medical, Field, CRUD },
  props: ["path"],
  data() {
    return {
      student: {},
      school: localStorage.getItem("schoolName"),
      schoolname: localStorage.getItem("school"),

      fields: [
        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left"
        },
        {
          field: "area",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left"
        }
      ],

      doc_fields: [
        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "file",
          label: "File",
          sortable: true,
          class: "text-left",
          ftype: "file"
        }
      ]
    };
  },
  created() {
    var ref;
    if (this.path == null)
      ref = firebase.firestore().doc(this.$route.params.id);
    else
      ref = firebase
        .firestore()
        .doc("schools/" + this.schoolname + "/StudentsData/" + this.path);

    ref.get().then(doc => {
      if (doc.exists) {
        this.student = doc.data();
        this.student.id = doc.ref.id;
      } else {
        console.log(
          "schools/" + this.schoolname + "/StudentsData/" + this.path
        );
      }
    });
  },
  methods: {
    unlock() {
      this.$confirm({
        message: "Are you sure You want to unlock this account ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(
                "schools/" +
                  this.$store.state.school +
                  "/StudentsData/" +
                  this.student.id
              )
              .update({ failed_login_attempts: 0 })
              .then(() => {})
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
