<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>{{ week.title }}</v-card-title>

        <v-card-text>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Week Name -->
            <b-form-group
              id="fieldsetname"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Week title"
            >
              <b-form-input
                id="name"
                placeholder="Week title"
                v-model.trim="week.title"
              ></b-form-input>
            </b-form-group>

            <b-form-checkbox v-model="week.practice" name="check-button" switch>
              Open Practice
            </b-form-checkbox>
            <br />

            <b-form-checkbox v-model="week.current" name="check-button" switch>
              Current Lesson
            </b-form-checkbox>
            <br />
            <b-form-checkbox v-model="week.hidden" name="check-button" switch>
              Hide The Lesson
            </b-form-checkbox>
            <br />

            <b-form-checkbox v-model="week.revision" name="check-button" switch>
              Convert to Normal Week
            </b-form-checkbox>

            <!-- <b-form-checkbox v-model="week.nofc" name="check-button" switch>
              Exclude From Flipped Classroom
            </b-form-checkbox>
            <b-form-checkbox v-model="week.noexit" name="check-button" switch>
              Exclude From Exit Ticket
            </b-form-checkbox> -->
            <br />

            <b-form-checkbox v-model="week.summer" name="check-button" switch>
              Summer Work
            </b-form-checkbox>

            <br />

            <b-form-checkbox
              v-model="week.orientation"
              name="check-button"
              switch
            >
              Orientation Week
            </b-form-checkbox>

            <br />

            <b-form-group
              id="fieldBreakdown"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Breakdown"
            >
              <b-form-select
                id="Breakdown"
                placeholder="Breakdown"
                v-model.trim="week.breakdown"
                :options="breakdowns"
              ></b-form-select>
            </b-form-group>

            <StandardsPicker
              :path="this.$route.params.path"
              type="Lesson"
              :lesson="this.$route.params.path"
            />

            <b-form-group
              id="fieldsection"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Section"
            >
              <b-form-select
                id="section"
                placeholder="Section"
                v-model.trim="week.section"
                :options="sections"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="fielddate"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Lesson Date"
            >
              <b-form-datepicker
                id="SessionDate"
                v-model="date"
                class="mb-2"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group
              id="fieldObjectives"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Lesson Objectives"
            >
              <quill-editor
                ref="name"
                required
                v-model="objectives"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
              />
            </b-form-group>

            <!-- Upload Image -->
            <v-col cols="12" md="12">
              <b-form-group
                id="fielplan"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Weekly Plan"
              >
                <a :href="week.weekly" v-if="week.weekly" target="_blank">
                  Click Here To Open Weekly Plan</a
                >
                <p>Weekly Plan:</p>
                <b-form-file
                  type="file"
                  @change="previewImage"
                  accept=".pdf, .pptx, .doc, .docx"
                />

                <div>
                  <p>
                    <small>
                      Progress:
                      {{ uploadValue.toFixed() + "%" }}
                    </small>
                  </p>
                </div>
                <div v-if="this.weekly != null">
                  Upload Completed
                </div>
                <div v-if="imageData != null">
                  <v-progress-linear
                    id="progress"
                    color="light-blue"
                    height="10"
                    :value="uploadValue"
                    max="100"
                    striped
                  ></v-progress-linear>
                  <b-button @click="onUpload" variant="success"
                    >Upload</b-button
                  >
                </div>
              </b-form-group>
            </v-col>

            <!-- End Of Uploading -->

            <!-- Submit Form -->
            <b-button type="submit" variant="primary">Edit Lesson</b-button>
          </b-form>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import StandardsPicker from "@/components/lms/standards/StandardsPicker";

export default {
  name: "EditWeek",
  props: ["path"],
  components: { StandardsPicker },
  data() {
    return {
      standarop: [{ standard: "", sub: "" }],
      standards_count: 0,
      standards: [],
      sub: [],
      breakdowns: [],
      date: new Date().toISOString().substr(0, 10),
      week: {},
      objectives: "",
      menu2: false,
      selected: null,
      options: [
        { value: "1", text: "First Term" },
        { value: "2", text: "Second Term" }
      ],
      sections: [],
      key: this.$route.params.id,
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),

      //Upload Weekly Plan

      imageData: null,
      weekly: null,
      uploadValue: 0,

      weekRef: firebase.firestore().doc(this.$route.params.path),

      sectionsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/sections/")
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.week.date = this.date == null ? "" : this.date;
      
      if (this.weekly != null) {
        this.week.weekly = this.weekly;
      }

      this.week.standard = this.standarop;

      if (this.week.title == null || this.week.order == null) {
        alert("Please Enter Week Title First");
      } else {
        firebase
          .firestore()
          .doc(this.$route.params.path)
          .set(this.week)
          .then(() => {
            // this.AddLog(
            //   this.$store.state.username,
            //   this.$store.state.firstname,
            //   this.$store.state.lastname,
            //   "Update",
            //   this.week,
            //   this.$route.params.subject,
            //   "Lesson"
            // );

            //! Get Objective
            firebase
              .firestore()
              .doc(this.$route.params.path + "/details/1")
              .set({ objectives: this.objectives });

            router.back();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.weekly = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/Weekly Plan/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.imageData.name
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.weekly = url;
          });
        }
      );
    }
  },
  created() {
    //! Get Breakdowns
    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.$store.state.thisBranch +
          "/breakdown"
      )
      .get()
      .then(querySnapshot => {
        this.breakdowns = [];
        querySnapshot.forEach(doc => {
          this.breakdowns.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });

    this.sectionsRef.onSnapshot(querySnapshot => {
      this.sections = [];
      querySnapshot.forEach(doc => {
        this.sections.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
    this.weekRef.get().then(doc => {
      if (doc.exists) {
        this.week = doc.data();
        this.date = this.week.date;

        if (doc.data().standard[0].standard != null) {
          this.standarop = doc.data().standard;
          this.standards_count = this.standarop.length - 1;
        }
      } else {
        alert("No such document!");
      }
    });

    //! Get Objective
    firebase
      .firestore()
      .doc(this.$route.params.path + "/details/1")
      .get()
      .then(doc => {
        if (doc.exists) {
          this.objectives = doc.data().objectives;
        }
      });
  }
};
</script>

<style></style>
