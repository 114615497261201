<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <button class="btn btn-info btn-sm" v-bind="attrs" v-on="on">
        <v-icon>mdi-pencil-outline</v-icon>
      </button>
    </template>

    <v-card>
      <v-card-title>
        <img
          class="preview"
          :src="student.image"
          width="80px"
          v-if="student.image"
        />

        <span class="title font-weight-light"
          ><h4>
            {{
              student.firstname +
                " " +
                student.middlename +
                " " +
                student.lastname
            }}
          </h4></span
        >
        <v-divider /><v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-subtitle
        ><h6>
          Student ID:
          {{
            student.nationalID == null ? "" : student.nationalID.substr(7, 13)
          }}
        </h6></v-card-subtitle
      >

      <v-container>
        <b-form @submit.stop.prevent="onSubmit">
          <!-- Student Name -->

          <v-container>
            <v-expansion-panels accordion :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-4"
                  style="color:#fff"
                  >Basic Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row center-block>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="First name"
                        v-model.trim="student.firstname"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Middle name"
                        v-model.trim="student.middlename"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Last name"
                        v-model.trim="student.lastname"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Schools Level -->
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-select
                        :items="schools"
                        v-model="student.branch"
                        label="School"
                        @change="onSchoolChange()"
                      ></v-select>
                    </v-col>
                    <!-- Grade Level -->

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-select
                        :items="grades"
                        v-model="grade"
                        label="Grade Level"
                        @change="onGradeChange()"
                        :messages="student.grade"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-text-field
                        label="Previous School / Nursery"
                        v-model.trim="student.preschool"
                      ></v-text-field>
                    </v-col>

                    <!-- Upload Image -->
                    <v-col cols="12" md="4">
                      <div>
                        <p>Student Image:</p>

                        <b-form-file
                          type="file"
                          @change="previewImage"
                          accept="image/*"
                          size="sm"
                        />
                      </div>
                      <div>
                        <p>
                          <small
                            >Progress: {{ uploadValue.toFixed() + "%" }}</small
                          >
                          <v-progress-linear
                            id="progress"
                            color="light-blue"
                            height="10"
                            :value="uploadValue"
                            max="100"
                            striped
                          ></v-progress-linear>
                        </p>
                      </div>
                      <div v-if="imageData != null">
                        <img class="preview" :src="picture" width="80px" />
                        <br />

                        <b-button @click="onUpload" variant="primary"
                          >Upload</b-button
                        >
                      </div>
                    </v-col>

                    <!-- End Of Uploading -->
                  </v-row>

                  <v-row>
                    <v-col cols="6" md="4">
                      <v-text-field
                        label="Date Of Birth"
                        v-model.trim="student.date_of_birth"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-row>Age In 1-10-2021</v-row>
                      <v-row>
                        <h4>{{ calculateAge }}</h4>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Student National Id (14 Numbers)"
                        messages="الرقم القومي للطالب"
                        v-model.trim="student.nationalID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Join Year -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="[
                          '2020-2021',
                          '2019-2020',
                          '2018-2019',
                          '2017-2018',
                          '2016-2017',
                          '2015-2016',
                          '2014-2015',
                          '2013-2014',
                          '2012-2013',
                          '2011-2012',
                          '2010-2011',
                          '2009-2010'
                        ]"
                        v-model.trim="student.join"
                        label="Join Year"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Admission Comment"
                        v-model.trim="student.admission_comment"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" md="6">
                      <v-switch
                        v-model="havesibling"
                        label="Have sibling"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-switch
                        v-model="parentsEmployee"
                        label="Employee"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-3"
                  style="color:#fff"
                  >Student Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row center-block>
                    <!-- Gender -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <v-select
                        :items="['Male', 'Female']"
                        v-model.trim="student.gender"
                        label="Gender"
                      ></v-select>
                    </v-col>

                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Citizenship"
                        v-model.trim="student.citizenship"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Address"
                        v-model.trim="student.address"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Home Phone"
                        v-model.trim="student.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Mobile"
                        v-model.trim="student.mobile"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-4"
                  style="color:#fff"
                  >Family Information</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <br />
                  <!-- Father Information -->

                  <v-alert border="bottom" color="green darken-1" dark>
                    <h6>Father Information</h6>
                  </v-alert>

                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Father Academic Degree"
                      v-model.trim="student.father_degree"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Father Occupation"
                      v-model.trim="student.father_occupation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Father Company Business"
                      v-model.trim="student.father_company"
                    ></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Father Email"
                        v-model.trim="student.parent"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Father Mobile"
                        v-model.trim="student.father_mobile"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Father National Id (14 Numbers)"
                        messages="الرقم القومي للاب"
                        v-model.trim="student.father_nationalID"
                      ></v-text-field> </v-col
                  ></v-row>

                  <!-- End Father Information -->
                  <br />
                  <!-- Father Information -->

                  <v-alert border="bottom" color="green darken-1" dark>
                    <h6>Mother Information</h6>
                  </v-alert>

                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Name"
                      v-model.trim="student.mother_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Academic Degree"
                      v-model.trim="student.mother_degree"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Occupation"
                      v-model.trim="student.mother_occupation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Mother Company Business"
                      v-model.trim="student.mother_company"
                    ></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Mother Email"
                        v-model.trim="student.mother_email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        label="Mother Mobile"
                        v-model.trim="student.mother_mobile"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Mother National Id (14 Numbers)"
                        messages="الرقم القومي للام"
                        v-model.trim="student.mother_nationalID"
                      ></v-text-field> </v-col
                  ></v-row>

                  <!-- End Father Information -->
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  color="light-blue darken-3"
                  style="color:#fff"
                  >Admission Date & Time</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <p class="card-text">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <b-form-datepicker
                          v-model="student.admission_date"
                          locale="en"
                          required
                        ></b-form-datepicker>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <b-form-timepicker
                          v-model="student.admission_time"
                          locale="en"
                          required
                        ></b-form-timepicker>
                      </v-col>
                    </v-row>
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header color="green" style="color:#fff"
                  >Accounting</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <!--   <v-card style="margin-top:20px"
                      ><v-card-title>Joining Year</v-card-title>
                      <v-card-text>
                        <h5>{{ this.student.join }}</h5>
                      </v-card-text>
                    </v-card>
                    <v-card style="margin-top:20px"
                      ><v-card-title>Siblings</v-card-title>
                      <v-card-text>
                        <vue-good-table :columns="brothersf" :rows="brothers">
                        </vue-good-table>
                      </v-card-text>
                    </v-card>
                    <v-card style="margin-top:20px"
                      ><v-card-title>Parents</v-card-title>
                      <v-card-subtitle
                        >Employees In this School</v-card-subtitle
                      >
                      <v-card-text>
                        <div v-if="this.father.length > 0">
                          <h5>Father</h5>
                          <br />

                          <vue-good-table :columns="parentf" :rows="father">
                          </vue-good-table>
                        </div>
                        <div
                          v-if="this.mother.length > 0"
                          style="margin-top:20px"
                        >
                          <h5>Mother</h5>
                          <br />

                          <vue-good-table :columns="parentf" :rows="mother">
                          </vue-good-table>
                        </div>
                      </v-card-text>
                    </v-card>-->

                  <v-card style="margin-top:20px" dark
                    ><v-card-title
                      >Fees <v-spacer />
                      <h3>
                        <div v-if="student.discount == null">
                          {{ this.fees[student.pricelist] }}
                        </div>
                        <div v-else>
                          {{
                            this.fees[student.pricelist] -
                              (this.fees[student.pricelist] *
                                student.discount) /
                                100
                          }}
                          L.E
                        </div>
                      </h3></v-card-title
                    >

                    <v-card-text>
                      <v-row>
                        <!-- Schools Level -->
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-select
                            :items="[
                              {
                                text: 'Old Students Fees (Before 2019-2020)',
                                value: 'old_fees'
                              },
                              {
                                text: 'New Students Fees (2020-2021)',
                                value: 'new_fees'
                              },
                              {
                                text:
                                  'Old Students Fees (Before 2019-2020) Have Siblings',
                                value: 'old_fees_sib'
                              },
                              {
                                text:
                                  'New Students Fees (2020-2021) Have Siblings',
                                value: 'new_fees_sib'
                              }
                            ]"
                            v-model="student.pricelist"
                            label="Price List"
                            @click="price"
                          ></v-select>
                        </v-col>

                        <!-- Schools Level -->
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-select
                            :items="[
                              {
                                text: '5%',
                                value: '5'
                              },
                              {
                                text: '10%',
                                value: '10'
                              },
                              {
                                text: '20%',
                                value: '20'
                              },
                              {
                                text: '30%',
                                value: '30'
                              },
                              {
                                text: '40%',
                                value: '40'
                              },
                              {
                                text: '50%',
                                value: '50'
                              },
                              {
                                text: '60%',
                                value: '60'
                              },
                              {
                                text: '70%',
                                value: '70'
                              },
                              {
                                text: '80%',
                                value: '80'
                              },
                              {
                                text: '90%',
                                value: '90'
                              },
                              {
                                text: '100%',
                                value: '100'
                              }
                            ]"
                            v-model="student.discount"
                            label="Discount"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Notes"
                            v-model="student.feesnote"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="green" dark depressed
                        >Set Fees</v-btn
                      ></v-card-actions
                    >
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
          <b-card-footer>
            <!-- Submit Form -->

            <b-button
              variant="danger"
              @click="dialog = false"
              style="margin-right:20px"
              >Cancel</b-button
            >

            <b-button
              type="submit"
              variant="primary"
              v-if="this.$route.params.type == 'copy'"
              >Add Application</b-button
            >

            <b-button type="submit" variant="primary" v-else
              >Edit Application</b-button
            >
          </b-card-footer>
        </b-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditApplication",
  params: ["path", "type"],
  props: ["path", "type"],

  data() {
    return {
      dialog: false,
      fields: [
        {
          field: "status",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "timeline",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "admission_date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "admission_time",
          label: "Time",
          sortable: true,
          class: "text-left"
        },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        { field: "grade", label: "Grade", sortable: true, class: "text-left" },
        {
          field: "firstname",
          label: "First name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "middlename",
          label: "Middle name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Last name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "Actions",
          sortable: true,
          class: "text-left"
        }
      ],
      admission: [],
      brothersf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "branch",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "status",
          label: "Status",
          sortable: true,
          class: "text-left"
        }
      ],
      parentf: [
        {
          field: "join",
          label: "Join Year",
          sortable: true,
          class: "text-left"
        },
        {
          field: "job",
          label: "Job",
          sortable: true,
          class: "text-left"
        },

        {
          field: "firsname",
          label: "Firstname",
          sortable: true,
          class: "text-left"
        },
        {
          field: "lastname",
          label: "Lastname",
          sortable: true,
          class: "text-left"
        }
      ],
      havesibling: false,
      parentsEmployee: false,
      panel: 0,
      age: "",
      time: null,
      menu2: false,
      modal2: false,
      brothers: {},
      father: {},
      mother: {},
      fees: {},
      student: {},
      grades: [],
      grade: "",
      schools: [],
      gclasses: {},
      selectedGrade: "",
      studentRef: firebase.firestore().doc(this.path),
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),
      statusRef: firebase
        .firestore()
        .collection(
          "schools/" + this.$store.state.school + "/admission_status/"
        ),

      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      // if (this.$route.params.type == "copy") {
      //   this.student.school = this.$store.state.school;
      //   this.student.type = "student";

      //   firebase
      //     .firestore()
      //     .collection("schools/" + this.$store.state.school + "/admission/")
      //     .add(this.student)
      //     .then(() => {
      //       this.student.firstname = "";
      //       this.student.middlename = "";
      //       this.student.lastname = "";
      //       this.student.grade = "";

      //       router.back();
      //     })
      //     .catch(error => {
      //       alert("Error adding document: ", error);
      //     });
      // } else {
      //   if (this.picture != null) {
      //     this.student.image = this.picture;
      //   }

      this.student.image = this.picture;

      this.student.parentsEmployee = this.parentsEmployee;
      this.student.havesibling = this.havesibling;

      this.student.grade = this.grade == "" ? this.student.grade : this.grade;

      firebase
        .firestore()
        .doc(this.path)
        .set(this.student)
        .then(() => {
          //router.back();
          this.$bvToast.toast("Application Updated", {
            title: "Application Updated Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
      // }
    },

    onSchoolChange() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    price() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade
        )
        .get()
        .then(doc => {
          if (doc.exists) {
            this.fees = doc.data();
          } else {
            alert("No such document!");
          }
        });
    },

    onGradeChange() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/grades/" +
            this.student.grade +
            "/classes"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.gclasses = [];
          querySnapshot.forEach(doc => {
            this.gclasses.push({
              text: doc.data().title,
              value: doc.data().title
            });
          });
        });
    },

    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/admission")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    ogToEdit(id) {
      router.push({ name: "EditApplication", params: { path: id } });
    },
    Timeline(id, student) {
      router.push({
        name: "TimelineList",
        params: { path: id, student: student }
      });
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          this.$store.state.school +
            "/StudentsData/" +
            this.student.firstname +
            this.student.date_of_birth +
            this.student.lastname
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.picture = url;
          });
        }
      );
    }
  },
  created() {
    this.studentRef.get().then(doc => {
      if (doc.exists) {
        this.student = doc.data();
        this.parentsEmployee = doc.data().parentsEmployee;
        this.havesibling = doc.data().havesibling;

        // Get Brothers Data
        // firebase
        //   .firestore()
        //   .collection("schools/" + this.$store.state.school + "/students/")
        //   .where("father_nationalID", "==", this.student.father_nationalID)
        //   .onSnapshot(querySnapshot => {
        //     this.brothers = [];
        //     querySnapshot.forEach(doc => {
        //       if (doc.data().firstname != this.student.firstname) {
        //         this.brothers.push({
        //           firsname: doc.data().firstname,
        //           lastname: doc.data().lastname,
        //           branch: doc.data().branch,
        //           grade: doc.data().grade,
        //           class: doc.data().class,
        //           join: doc.data().join,
        //           status: doc.data().status
        //         });
        //       }
        //     });
        //   });
        // Get Parents Data
        // firebase
        //   .firestore()
        //   .collection("schools/" + this.$store.state.school + "/employees/")
        //   .where("nationalID", "==", this.student.father_nationalID)
        //   .onSnapshot(querySnapshot => {
        //     this.father = [];
        //     querySnapshot.forEach(doc => {
        //       this.father.push({
        //         firsname: doc.data().firstname,
        //         lastname: doc.data().lastname,
        //         job: doc.data().job,
        //         join: doc.data().join
        //       });
        //     });
        //   });
        // firebase
        //   .firestore()
        //   .collection("schools/" + this.$store.state.school + "/employees/")
        //   .where("nationalID", "==", this.student.mother_nationalID)
        //   .onSnapshot(querySnapshot => {
        //     this.mother = [];
        //     querySnapshot.forEach(doc => {
        //       this.mother.push({
        //         firsname: doc.data().firstname,
        //         lastname: doc.data().lastname,
        //         job: doc.data().job,
        //         join: doc.data().join
        //       });
        //     });
        //   });
      } else {
        alert("No such document!");
      }
    });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });
  },

  computed: {
    // calculateAge: function() {
    // let sAge = moment(this.student.date_of_birth, "YYYY-MM-DD").format(
    //   "M/DD/YYYY"
    // );
    // var age = moment().diff(sAge, "years", "10/1/2021", true);
    // return age.toFixed(1);
    // }
  }
};
</script>
