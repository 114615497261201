<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Event</b-button
      >

      <b-collapse id="accordion-1" class="mt-2">
        <b-card>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Grade Name -->

            <v-col cols="12" md="4">
              <b-form-datepicker
                label="Date"
                v-model.trim="event.date"
                class="mb-2"
                today-button
                required
              ></b-form-datepicker>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-autocomplete
                v-model.trim="event.title"
                :items="status"
                index="type"
                dense
                label="Status"
                @change="onStatusChange(event.title)"
                required
              ></v-autocomplete>
            </v-col>

            <v-col class="d-flex" cols="12">
              <v-text-field
                label="Comment"
                v-model.trim="event.comment"
              ></v-text-field>
            </v-col>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add event</b-button>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "Addevent-page",
  props: ["path", "student", "grade"],
  data() {
    return {
      ref: firebase.firestore().collection(this.path + "/timeline"),

      studentref: firebase.firestore().doc(this.path),

      statusRef: firebase
        .firestore()
        .collection(
          "schools/" + this.$store.state.school + "/admission_status/"
        ),
      status: {},

      event: {},
      studentUpdate: {}
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.event.type = this.selectStatus[0].text;

      this.studentUpdate.admission_status = this.selectStatus[0].text;
      this.studentUpdate.admission_current = this.event.title;

      this.ref
        .add(this.event)
        .then(() => {
          this.event.title = "";

          this.studentref.update(this.studentUpdate);

          this.$bvToast.toast("Event Created", {
            title: "Event Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    onStatusChange(ver) {
      this.selectStatus = [];
      this.statusRef.where("title", "==", ver).onSnapshot(querySnapshot => {
        this.selectStatus = [];
        querySnapshot.forEach(doc => {
          this.selectStatus.push({
            text: doc.data().type
          });
        });
      });
    }
  },
  created() {
    this.statusRef.onSnapshot(querySnapshot => {
      this.status = [];
      querySnapshot.forEach(doc => {
        this.status.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
