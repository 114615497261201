<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined
        ><v-card-text style=" overflow: auto;   height: 65vh;">
          <v-card flat>
            <v-card-title>
              <v-icon large left>mdi-video-outline</v-icon>
              <span class="title font-weight-light" v-if="source == 'edit'"
                >Edit This Video</span
              >
              <span class="title font-weight-light" v-else
                >Create New Video</span
              >
              <v-spacer /><v-icon @click="this.$modal.hideAll"
                >mdi-close</v-icon
              >
            </v-card-title>
            <v-card-text style="padding-right:50px; padding-left:50px;">
              <div>
                <b-form-group
                  id="fieldTtile"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Video Title"
                >
                  <v-text-field id="ftitle" v-model="lesson.title" />
                </b-form-group>

                <b-form-group
                  id="fielddescription"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Video Description"
                >
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="lesson.description"
                    :options="editorOption"
                  />
                </b-form-group>

                <StandardsPicker
                  :path="this.path"
                  type="Video"
                  v-if="source == 'edit'"
                  :lesson="this.week"
                />

                <b-form-group
                  id="fieldlink"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Link"
                >
                  <b-form-input
                    id="link"
                    placeholder="Activity link"
                    v-model.trim="lesson.link"
                  ></b-form-input>
                </b-form-group>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-divider></v-divider>

              <b-button
                variant="danger"
                class="float-right"
                @click="this.$modal.hideAll"
                >Close</b-button
              >
              <b-button
                style="margin-left:10px;"
                variant="success"
                class="float-right"
                @click="edit"
                v-if="source == 'edit'"
                >Edit Video</b-button
              >
              <b-button
                style="margin-left:10px;"
                variant="success"
                class="float-right"
                @click="onSubmit"
                v-else
                >Create Video</b-button
              >
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import StandardsPicker from "@/components/lms/standards/StandardsPicker";

export default {
  name: "ViewRecord",
  props: ["path", "order", "source", "week"],

  components: { StandardsPicker },
  data() {
    return {
      lesson: {},
      standards: [],
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      newfield: {}
    };
  },
  methods: {
    edit(evt) {
      evt.preventDefault();
      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        firebase
          .firestore()
          .doc(this.path)
          .set(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Update",
              this.lesson,
              this.$store.state.subject,
              "Video"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Video Created", {
              title: "Video Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        this.lesson.type = "video";
        this.lesson.order = Number(this.order) + 1;

        firebase
          .firestore()
          .collection(this.path)
          .add(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.lesson,
              this.$store.state.subject,
              "Video"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Video Created", {
              title: "Video Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  },
  mount() {
    this.show();
  },

  created() {
    console.log(this.path);
    if (this.source == "edit") {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.lesson = doc.data();
          } else {
            alert("No such document!");
          }
        });
    }
  }
};
</script>
<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(24, 146, 0, 0.904);
  cursor: pointer;
}
</style>
