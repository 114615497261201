<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-account-plus-outline</v-icon>

          <span class="title">Add New Student</span>
        </v-card-title>

        <v-container>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Student Name -->

            <v-container>
              <v-expansion-panels accordion :value="0">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="light-blue darken-4"
                    style="color: #fff"
                    >Basic Information</v-expansion-panel-header
                  >

                  <v-expansion-panel-content>
                    <v-row center-block>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="First name"
                          v-model.trim="firstname"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Middle name"
                          v-model.trim="student.middlename"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Last name"
                          v-model.trim="student.lastname"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field
                          label="Student National Id (14 Numbers)"
                          messages="الرقم القومي للطالب"
                          type="number"
                          v-model.trim="student.nationalID"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Join Year -->
                    <v-col class="d-flex" cols="6" sm="6">
                      <b-select
                        label="Join Year"
                        v-model.trim="student.join"
                        :options="[
                          '2020-2021',
                          '2019-2020',
                          '2018-2019',
                          '2017-2018',
                          '2016-2017',
                          '2015-2016',
                          '2014-2015',
                          '2013-2014',
                          '2012-2013',
                          '2011-2012',
                          '2010-2011',
                          '2009-2010',
                        ]"
                      >
                      </b-select>
                    </v-col>

                    <v-row center-block>
                      <!-- Upload Image -->
                      <v-col cols="12" md="4">
                        <div>
                          <p>Student Image:</p>
                          <b-form-file
                            type="file"
                            @change="previewImage"
                            accept="image/*"
                          />
                        </div>
                        <div>
                          <p>
                            <small>
                              Progress:
                              {{ uploadValue.toFixed() + "%" }}
                            </small>
                            <v-progress-linear
                              id="progress"
                              color="light-blue"
                              height="10"
                              :value="uploadValue"
                              max="100"
                              striped
                            ></v-progress-linear>
                          </p>
                        </div>
                        <div v-if="imageData != null">
                          <img class="preview" :src="picture" width="80px" />
                          <br />

                          <b-button @click="onUpload" variant="primary"
                            >Upload</b-button
                          >
                        </div>
                      </v-col>

                      <!-- End Of Uploading -->
                      <v-col></v-col>
                      <v-col style="background-color: #eee">
                        <v-card>
                          <v-card-title>Student Account</v-card-title>
                          <v-card-text>
                            <b> Username:</b>
                            <span style="color: green">
                              {{ this.username }}</span
                            >
                            <br />
                            <br />
                            <b>Password:</b
                            ><span style="color: green">
                              {{ this.password }}</span
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Microsoft or Google Account"
                          v-model.trim="student.email"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Schools Level -->
                      <v-col class="d-flex" cols="12" sm="4">
                        <b-select
                          label="School"
                          v-model.trim="student.branch"
                          :options="schools"
                          @change="onSchoolChange()"
                        >
                        </b-select>
                      </v-col>
                      <!-- Grade Level -->
                      <v-col class="d-flex" cols="12" sm="4">
                        <b-select
                          label="Grade Level"
                          v-model.trim="student.grade"
                          :options="grades"
                          @change="onGradeChange()"
                        >
                        </b-select>
                      </v-col>
                      <!-- Class -->
                      <v-col class="d-flex" cols="12" sm="4">
                        <b-select
                          label="Class"
                          v-model.trim="student.class"
                          :options="gclasses"
                          @change="onGradeChange()"
                        >
                        </b-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="light-blue darken-3"
                    style="color: #fff"
                    >Student Information</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row center-block>
                      <!-- Gender -->
                      <v-col class="d-flex" cols="6" sm="6">
                        <b-select
                          label="Gender"
                          v-model.trim="student.gender"
                          :options="['Male', 'Female']"
                        >
                        </b-select>
                      </v-col>

                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Citizenship"
                          v-model.trim="student.citizenship"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          label="Address"
                          v-model.trim="student.address"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Home Phone"
                          v-model.trim="student.phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Mobile"
                          v-model.trim="student.mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="light-blue darken-4"
                    style="color: #fff"
                    >Family Information</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <br />
                    <!-- Father Information -->

                    <v-alert border="bottom" color="green darken-1" dark>
                      <h6>Father Information</h6>
                    </v-alert>

                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Father Academic Degree"
                        v-model.trim="student.father_degree"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Father Occupation"
                        v-model.trim="student.father_occupation"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Father Company Business"
                        v-model.trim="student.father_company"
                      ></v-text-field>
                    </v-col>

                    <v-row>
                      <v-col cols="6" md="6">
                        <!-- Parent Dropdown
                          
                            <v-autocomplete
                              v-model.trim="student.parent"
                              :items="status"
                              index="type"
                              label="Status"
                              @change="onStatusChange()"
                            >
                              ></v-autocomplete
                            >
    
                            -->
                        <v-text-field
                          label="Father Email"
                          v-model.trim="student.parent"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Father Mobile"
                          v-model.trim="student.father_mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field
                          label="Father National Id (14 Numbers)"
                          messages="الرقم القومي للاب"
                          v-model.trim="student.father_nationalID"
                        ></v-text-field> </v-col
                    ></v-row>

                    <!-- End Father Information -->
                    <br />
                    <!-- Father Information -->

                    <v-alert border="bottom" color="green darken-1" dark>
                      <h6>Mother Information</h6>
                    </v-alert>

                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Name"
                        v-model.trim="student.mother_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Academic Degree"
                        v-model.trim="student.mother_degree"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Occupation"
                        v-model.trim="student.mother_occupation"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Company Business"
                        v-model.trim="student.mother_company"
                      ></v-text-field>
                    </v-col>

                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Mother Email"
                          v-model.trim="student.mother_email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Mother Mobile"
                          v-model.trim="student.mother_mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field
                          label="Mother National Id (14 Numbers)"
                          messages="الرقم القومي للام"
                          v-model.trim="student.mother_nationalID"
                        ></v-text-field> </v-col
                    ></v-row>

                    <!-- End Father Information -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add Student</b-button>
            </b-card-footer>
          </b-form>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "AddStudent",

  data() {
    return {
      panel: 0,
      student: {},
      grades: [],
      schools: [],
      gclasses: [],
      username: "",
      password: "",
      firstname: "",
      selectedGrade: "",
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),

      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.student.school = this.$store.state.school;
      this.student.type = "student";

      this.student.schoolName = this.$store.state.schoolName;
      this.student.username = this.student.username.toLowerCase();
      this.student.points = 0;
      if (this.student.email != null) {
        this.student.email = this.student.email.trim().toLowerCase();
      }

      this.student.educationPath =
        "schools/" +
        this.$store.state.school +
        "/branches/" +
        this.student.branch +
        "/grades/" +
        this.student.grade;

      if (this.picture != null) {
        this.student.image = this.picture;
      }

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .doc(this.student.username)
        .set(this.student)
        .then(() => {
          //Create User
          // firebase
          //   .auth()
          //   .createUserWithEmailAndPassword(
          //     this.student.username,
          //     this.student.password
          //   )
          //   .then()
          //   .catch(err => {
          //     this.error = err.message;
          //   });

          // this.student.firstname = "";
          // this.student.middlename = "";
          // this.student.lastname = "";
          // this.student.username = "";
          // this.student.password = "";
          // this.student.grade = "";

          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Create",
            this.student,
            "SIS",
            "Student"
          );

          router.back();
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },

    onSchoolChange() {
      this.gclasses = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades"
        )
        .orderBy("title")
        .onSnapshot((querySnapshot) => {
          this.grades = [];
          querySnapshot.forEach((doc) => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id,
            });
          });
        });
    },
    onGradeChange() {
      firebase
        .firestore()

        // /schools/dais/branches/American School/grades/Grade 5/classes
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade +
            "/classes/"
        )
        .orderBy("title")
        .onSnapshot((querySnapshot) => {
          this.gclasses = [];
          querySnapshot.forEach((doc) => {
            this.gclasses.push({
              text: doc.data().title,
              value: doc.data().title,
            });
          });
        });
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          this.$store.state.school +
            "/students/" +
            this.student.firstname +
            this.student.date_of_birth +
            this.student.lastname
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
    },
  },
  created() {
    this.schoolsRef.onSnapshot((querySnapshot) => {
      this.schools = [];
      querySnapshot.forEach((doc) => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id,
        });
      });
    });
  },
  watch: {
    firstname(newValue, oldValue) {
      this.username =
        newValue.toLowerCase().trim() +
        Math.floor(Math.random() * Math.floor(900000));
      this.password = Math.floor(Math.random() * 900000 + 1000000);

      this.student.firstname = newValue;
      this.student.username = this.username;
      this.student.password = this.password;

      console.log("Old Value: ", oldValue);
    },
  },
};
</script>
