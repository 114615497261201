<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Topics"
          color="primary"
          :path="this.$store.state.subject + '/topics'"
        ></CountCard>
      </b-card-group>
      <br />

      <v-card flat outlined>
        <v-card-title>Topics</v-card-title>
        <v-card-text>
          <div>
            <CRUD
              title="Topic"
              :path="this.$store.state.subject + '/topics'"
              :fields="this.fields"
              width="500"
              height="900"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";

import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "Topics-page",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      code: "",
      fixedItem: {},

      fixeds: [],

      fields: [
        {
          field: "code",
          label: "Code",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style></style>
