<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Standard</b-button
      >

      <b-collapse id="accordion-1" class="mt-2">
        <v-card flat outlined>
          <v-container>
            <b-form @submit.stop.prevent="onSubmit">
              <!-- Grade Order -->

              <v-autocomplete
                v-model.trim="standard.parent"
                :items="standards"
                label="Parent Standard"
              ></v-autocomplete>

              <v-text-field
                id="fieldCode"
                v-model="standard.code"
                label="Standard Code"
              ></v-text-field>

              <v-text-field
                id="Standardtitle"
                label="Standard Source"
                value
                autofocus
                v-model="standard.source"
              ></v-text-field>

              <v-text-field
                id="fieldSourcce"
                v-model="standard.topic"
                label="Standard Topic"
              ></v-text-field>

              <v-autocomplete
                v-model.trim="standard.domain"
                :items="domains"
                label="Domain"
                @change="onDomainChange()"
              ></v-autocomplete>

              <v-autocomplete
                v-model.trim="standard.cluster"
                :items="clusters"
                label="Cluster"
              ></v-autocomplete>

              <v-text-field
                id="StandardTtile"
                v-model="standard.title"
                label="Standard Description"
              ></v-text-field>

              <b-card-footer>
                <!-- Submit Form -->
                <b-button type="submit" variant="primary"
                  >Add Standard</b-button
                >
              </b-card-footer>
            </b-form>
          </v-container>
        </v-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddStandard",
  props: ["path"],
  data() {
    return {
      thisOrder: 0,

      standard: {},
      domains: [],
      clusters: [],
      standards: [],
      selected: null,

      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  created() {
    this.getDomains();
    this.getStandards();
  },

  methods: {
    getDomains() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/domains")
        .onSnapshot(querySnapshot => {
          this.domains = [];
          querySnapshot.forEach(doc => {
            this.domains.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().title,
              value: [doc.data().title, doc.data().code]
            });
          });
        });
    },
    getStandards() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards")
        .where("parent", "==", "")
        .onSnapshot(querySnapshot => {
          this.standards = [];
          querySnapshot.forEach(doc => {
            this.standards.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().title,
              value: doc.data().code
            });
          });
        });
    },

    onDomainChange() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/clusters")
        .where("domain_code", "==", this.standard.domain[1])
        .onSnapshot(querySnapshot => {
          this.clusters = [];
          querySnapshot.forEach(doc => {
            this.clusters.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().title,
              value: [doc.data().title, doc.data().code]
            });
          });
        });
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.standard.title == null) {
        alert("Please Enter the Standard Title");
      } else {
        //! Analysis Data
        this.standard.school = this.path.split("/")[1];
        this.standard.branch = this.path.split("/")[3];
        this.standard.grade = this.path.split("/")[5];
        this.standard.subject = this.path.split("/")[7];
        //!-------------------------------------------------------------

        this.standard.domain = this.standard.domain[0];
        this.standard.cluster = this.standard.cluster[0];

        if (this.standard.parent == null) {
          this.standard.parent = "";
        }

        firebase
          .firestore()
          .collection(this.path)
          .doc(this.standard.code)
          .set(this.standard)
          .then(() => {
            this.standard.title = "";

            this.$bvToast.toast("Standard Created", {
              title: "Standard Created Successfully",
              variant: "success",
              solid: true
            });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  }
};
</script>
