<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>Activities Schedule</v-card-title>
          <v-card-text>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-spacer />

                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="success"
                :events="events"
                :short-weekdays="false"
                :event-color="getEventColor"
                :type="type"
              ></v-calendar>
            </v-sheet>
          </v-card-text>
        </v-card>

        <v-card flat outlined tile style="margin-top:20px">
          <v-card-text>
            <v-row>
              <v-icon color="blue">mdi-checkbox-blank</v-icon> Lessons

              <v-icon color="green">mdi-checkbox-blank</v-icon> Assignments

              <v-icon color="red">mdi-checkbox-blank</v-icon> Live Sessions
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Calendar-page",
  data() {
    return {
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      focus: "",
      subjects: [],
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ]
    };
  },
  created() {
    this.events = [];

    //! Get Lessons
    firebase
      .firestore()
      .collection(this.$store.state.subject + "/weeks/")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().date != "")
            this.events.push({
              name: doc.data().title,
              start: doc.data().date,
              end: doc.data().date,
              color: "blue",
              timed: false
            });

          //! Get Lesssons assignments
          firebase
            .firestore()
            .collection(doc.ref.path + "/tasks/")
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                if (doc.data().opendate != "")
                  this.events.push({
                    name: doc.data().title,
                    start: doc.data().opendate,
                    end: doc.data().closedate,
                    color: "green",
                    timed: false
                  });
              });
            });
        });
      });

    //! Get Lesssons Live Sessions
    firebase
      .firestore()
      .collection(this.$store.state.subject + "/sessions/")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().date != "")
            this.events.push({
              name: doc.data().title,
              start: doc.data().date,
              end: doc.data().date,
              color: "red",
              timed: false
            });
        });
      });
  },
  methods: {
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    }
  }
};
</script>
