<template>
  <v-container>
    <v-toolbar color="deep-orange darken-1" dark>
      <v-toolbar-title>
        <v-icon left size="40px">mdi-gavel</v-icon>Discipline
      </v-toolbar-title>
    </v-toolbar>

    <AddDiscipline :id="this.id"></AddDiscipline>

    <vue-good-table
      :columns="fields"
      :rows="disciplines"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'faction'">
          <button
            class="btn btn-danger btn-sm"
            @click="
              deleteboard(
                props.row.key,
                props.row.firstname + ' ' + props.row.lastname
              )
            "
          >
            <v-icon>mdi-delete-outline</v-icon>
          </button>
        </span>
      </template>
    </vue-good-table>
  </v-container>
</template>

<script>
import firebase from "@/Firebase";
import AddDiscipline from "./AddDiscipline";

export default {
  name: "DisciplinesList",
  components: { AddDiscipline },
  props: ["id"],

  data() {
    return {
      fields: [
        {
          field: "date",
          label: "Date of Incident",
          sortable: true,
          class: "text-left"
        },
        {
          field: "reason",
          label: "Reason for referral",
          sortable: true,
          class: "text-left"
        },
        {
          field: "action",
          label: "Prior action",
          sortable: true,
          class: "text-left"
        },
        {
          field: "adminaction",
          label: "Administrative action",
          sortable: true,
          class: "text-left"
        },
        {
          field: "faction",
          label: "Action",
          sortable: true,
          class: "text-left"
        }
      ],
      disciplines: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.id +
            "/disciplines"
        )
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.disciplines = [];
      querySnapshot.forEach(doc => {
        this.disciplines.push({
          key: doc.id,
          path: doc.ref.path,
          date: doc.data().date,
          reason: doc.data().reason,
          action: doc.data().action,
          adminaction: doc.data().adminaction
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(
                "schools/" +
                  this.$store.state.school +
                  "/StudentsData/" +
                  this.id +
                  "/disciplines"
              )
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Record Deleted", {
                  title: "Record Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
