<template>
  <v-container>
    <v-toolbar color="deep-green darken-1" dark>
      <v-toolbar-title>
        <v-icon left size="40px">mdi-pill</v-icon>Medical
      </v-toolbar-title>
    </v-toolbar>

    <AddMedical :id="this.id"></AddMedical>

    <vue-good-table
      :columns="fields"
      :rows="disciplines"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button
            class="btn btn-danger btn-sm"
            @click="
              deleteboard(
                props.row.key,
                props.row.firstname + ' ' + props.row.lastname
              )
            "
          >
            <v-icon>mdi-delete-outline</v-icon>
          </button>
        </span>
      </template>
    </vue-good-table>
  </v-container>
</template>

<script>
import firebase from "@/Firebase";
import AddMedical from "./AddMedical";

export default {
  name: "MedicalsList",
  components: { AddMedical },
  props: ["id"],

  data() {
    return {
      fields: [
        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },

        {
          field: "action",
          label: "Action",
          sortable: true,
          class: "text-left"
        }
      ],
      disciplines: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("profiles")
        .doc(this.id)
        .collection("medical")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.disciplines = [];
      querySnapshot.forEach(doc => {
        this.disciplines.push({
          key: doc.id,
          path: doc.ref.path,
          date: doc.data().date
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("profiles")
              .doc(this.id)
              .collection("medical")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Record Deleted", {
                  title: "Record Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
