<template>
  <b-row>
    <b-col cols="12">
      <b-Card>
        <b-card-header>Edit Subject</b-card-header>
        <v-card-text>
          <v-text-field
            label="Subject Title"
            v-model.trim="subject.title"
          ></v-text-field>
          <v-text-field
            label="Subject Points"
            v-model.trim="subject.points"
            type="number"
          ></v-text-field>
          <v-btn color="primary" depressed @click="onSubmit()">Update</v-btn>
        </v-card-text>
      </b-Card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditSubject",
  data() {
    return {
      subject: {},
      board: {}
    };
  },
  created() {

    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        this.subject = snapshot.data();
      });
  },
  methods: {
    onSubmit() {
      this.subject.points = Number(this.subject.points);
      firebase
        .firestore()
        .doc(this.$route.params.path)
        .update(this.subject)
        .then(() => {
          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
