<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          Gradebook <v-spacer />
          <span @click="goToWeight()" style="font-size:12px; cursor:pointer;">
            <v-icon size="15">mdi-cog</v-icon>
            Manage Weights</span
          >
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card flat outlined @click="goToGradebookOverview()">
                <v-card-text>
                  <img src="@/assets/studentprog.png" width="80px" />

                  <h6 style="margin-top:20px; margin-bottom:-2px;">
                    Grades Overview
                  </h6>
                  Click Here to View Students Grades
                </v-card-text></v-card
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <vue-good-table
                :columns="fields"
                :rows="students"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{
                  enabled: false,
                  mode: 'records',
                  perPage: 100
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <v-btn
                      color="info"
                      small
                      depressed
                      block
                      @click="
                        goToGrades(
                          props.row.path,

                          props.row.studentname,
                          props.row.studentID
                        )
                      "
                    >
                      Progress Report
                    </v-btn>
                  </span>
                  <span v-else-if="props.column.field == 'check'">
                    <v-checkbox />
                  </span>
                </template>
              </vue-good-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "StudentsList",
  components: {},
  props: ["path", "grade"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      record: {},
      fields: [
        {
          field: "check",
          label: "",
          sortable: true,
          class: "text-left"
        },

        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center" }
      ],
      classes: [],
      students: [],
      errors: []
    };
  },
  created() {
    this.students = [];
  
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/StudentsData")
      .where("grade", "==", this.$store.state.thisGrade)
      .where("branch", "==", this.$store.state.thisBranch)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {

          this.students.push({
            key: doc.id,
            path: doc.ref.path,
            studentID:doc.data().username,
            studentname:
              doc.data().firstname +
              " " +
              doc.data().middlename +
              " " +
              doc.data().lastname,

            class: doc.data().class
          });
        });
      });
  },
  methods: {
    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/teachers/")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    },
    goToWeight() {
      router.push({ name: "Weights" });
    },
    goToGradebookOverview() {
      router.push({ name: "GradebookOverview" });
    },
    goToGrades(studentpath, studentName, studentID) {
      localStorage.setItem("studentid",studentID)
      let routeData = this.$router.resolve({
        name: "StudentReportCard",
        params: {
          sname: studentName,
          path: studentpath,
          subject: this.$store.state.subjectTitle
        }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
