<template>
  <div>
    <CRUD
      title="Fees-Type"
      :path="this.path"
      :fields="this.fields"
      width="500"
      height="400"
    ></CRUD>
  </div>
</template>
<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
export default {
  name: "FeesType",
  components: {
    CRUD
  },
  data() {
    return {
      path: "/schools/" + this.$store.state.school + "/feestypes",
      fields: [
        {
          field: "title",
          label: "Fees Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "account",
          label: "Account",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  }
};
</script>
