<template>
  <div>
    <b-col cols="12">
      <v-alert border="bottom" color="green darken-1" dark>
        <h4>{{ this.$route.params.school }}</h4>
        Grades / Years
      </v-alert>
      <v-card>
        <p class="card-text">
          <AddGrade></AddGrade>
        </p>
      </v-card>
      <br />
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-ballot-outline</v-icon>
          <span class="title font-weight-light">Grades List</span>
        </v-card-title>

        <p class="card-text">
          <vue-good-table
            :columns="fields"
            :rows="grades"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 20
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->

              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteboard(props.row.key, props.row.title)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </button>
                <button
                  style="margin-left:10px"
                  class="btn btn-info btn-sm"
                  @click="Edit(props.row.path)"
                >
                  <v-icon>mdi-pen</v-icon>
                </button>
              </span>

              <span v-else-if="props.column.field == 'subjects'">
                <button
                  class="btn btn-success btn-sm"
                  @click="goToSubjects(props.row.path, props.row.title)"
                >
                  <b-icon icon="book" scale="1"></b-icon>&nbsp;Subjects
                </button>
              </span>
              <span v-else-if="props.column.field == 'fees'">
                <button
                  class="btn btn-warning btn-sm"
                  @click="goToFees(props.row.path, props.row.title)"
                >
                  <v-icon>mdi-currency-usd</v-icon>&nbsp;Fees
                </button>
              </span>

              <span v-else-if="props.column.field == 'classes'">
                <button
                  class="btn btn-info btn-sm"
                  @click="goToClass(props.row.path, props.row.title)"
                >
                  <b-icon icon="grid" scale="1"></b-icon>&nbsp;Classes
                </button>

                <button
                  class="btn btn-danger btn-sm"
                  style="margin-left:10px"
                  @click="goToClass(props.row.path, props.row.title)"
                >
                  <v-icon color="white">mdi-account-group-outline</v-icon
                  >&nbsp;Cohorts
                </button>
              </span>
            </template>
          </vue-good-table>
        </p>
      </v-card>
    </b-col>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddGrade from "./AddGrade";
import { mapGetters } from "vuex";

export default {
  name: "GradesList",
  components: {
    AddGrade
  },

  data() {
    return {
      fields: [
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "order",
          label: "Order",
          sortable: true,
          class: "text-left"
        },
        { field: "title", label: "Title", sortable: true, class: "text-left" },

        { field: "classes", label: "", class: "text-center" },
        { field: "subjects", label: "Subjects", class: "text-center" },
        { field: "fees", label: "Fees", class: "text-center" },
        { field: "actions", label: "", class: "text-center" }
      ],
      grades: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$route.params.path + "/grades")
        .orderBy("order")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.grades = [];
      querySnapshot.forEach(doc => {
        this.grades.push({
          key: doc.id,
          path: doc.ref.path,
          order: doc.data().order,
          title: doc.data().title,
          school: doc.data().school
        });
      });
    });
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.path + "/grades")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Grade Deleted", {
                  title: "Grade Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    goToSubjects(id, title) {
      router.push({ name: "SubjectsList", params: { path: id, title: title } });
    },
    goToFees(id, title) {
      router.push({ name: "FeesSetup", params: { path: id, title: title } });
    },
    Edit(id) {
      router.push({ name: "EditGrade", params: { path: id } });
    },
    goToClass(id, title) {
      router.push({ name: "ClassesList", params: { path: id, title: title } });
    }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
