<template>
  <div>
    <v-card flat outlined
      ><v-card-text>
        <v-row>
          <!-- Start Date Name -->
          <v-col cols="12" sm="6" md="4">
            <label for="closedate">Extend Open Date</label>
            <b-form-datepicker
              id="opendate"
              v-model="assign.eopendate"
              class="mb-2"
            ></b-form-datepicker>
          </v-col>
          <!-- End Date Name -->

          <!-- Start Date Name -->
          <v-col cols="12" sm="6" md="4">
            <label for="closedate">Extend Close Date</label>
            <b-form-datepicker
              id="closedate"
              v-model="assign.eclosedate"
              class="mb-2"
            ></b-form-datepicker>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-btn @click="clearextend()" small depressed color="error" block
              >Clear Extend</v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <v-autocomplete
              v-model.trim="selectedClass"
              :items="classes"
              index="type"
              dense
              label="Select Class"
              required
              @change="onClassChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
              v-model.trim="selected"
              :items="students"
              index="type"
              dense
              label="Student Name"
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn small color="info" depressed>Add All Class</v-btn> -->
        <v-btn small color="warning" depressed @click="addToExtend"
          >Add Student</v-btn
        >
        <v-divider /><v-btn small color="success" depressed @click="saveExtend"
          >Save Extend</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card flat outlined style="margin-top:20px"
      ><v-card-text>
        <div
          v-for="(student, index) in selectedPathes"
          :key="student.key"
          style="margin-bottom:10px"
        >
          <v-row>
            <v-btn
              color="error"
              x-small
              depressed
              @click="remove(index)"
              style="margin-right:10px"
              >x</v-btn
            >
            <Student :path="student" />
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import Student from "./Student";
export default {
  name: "Extend-page",

  components: { Student },

  data() {
    return {
      path: localStorage.getItem("asspath"),
      grade: this.$store.state.thisGrade,
      branch: this.$store.state.thisBranch,
      students: [],
      classes: [],
      selectedStudents: [],
      selectedPathes: [],
      assign: {},
      selected: {},
      selectedClass: ""
    };
  },
  created() {
    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.branch +
          "/grades/" +
          this.grade +
          "/classes"
      )
      .get()
      .then(querySnapshot => {
        this.classes = [];
        querySnapshot.forEach(doc => {
          this.classes.push({
            value: doc.data().title,
            text: doc.data().title
          });
        });
      });

    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(doc => {
        this.assign = doc.data();
        firebase
          .firestore()
          .doc(this.path + "/details/extendto")
          .get()
          .then(ext => {
            this.selectedPathes = ext.data().extendto;
          });
      });
  },
  props: {},
  methods: {
    onClassChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where(
          "educationPath",
          "==",
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.branch +
            "/grades/" +
            this.grade
        )
        // .where("branch", "==", this.branch)
        // .where("grade", "==", this.grade)
        .where("class", "==", this.selectedClass)
        .get()
        .then(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              value: [
                doc.ref.path,
                doc.data().firstname +
                  " " +
                  doc.data().middlename +
                  " " +
                  doc.data().lastname
              ],
              text:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname
            });
          });
        });
    },

    remove(index) {
      this.selectedStudents.splice(index, 1);
      this.selectedPathes.splice(index, 1);
    },

    addToExtend() {
      if (this.selectedStudents.includes(this.selected)) {
        alert("This Student alrady in this Extend");
      } else {
        this.selectedStudents.push(this.selected);
        this.selectedPathes.push(this.selected[0]);
      }
    },
    clearextend() {
      console.log("delete");
      firebase
        .firestore()
        .doc(this.path)
        .update({ eopendate: null, eclosedate: null })
        .then(() => {
          firebase
            .firestore()
            .doc(this.path + "/details/extendto")
            .delete();
        });
    },
    saveExtend() {
      firebase
        .firestore()
        .doc(this.path)
        .update(this.assign)
        .then(() => {
          firebase
            .firestore()
            .doc(this.path + "/details/extendto")
            .set({ extendto: this.selectedPathes })
            .then(() => {
              this.$swal.queue([
                {
                  icon: "success",
                  title: "Extended Created",
                  html: "Extended Created Successfully",
                  timer: 2000
                }
              ]);
            });
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
