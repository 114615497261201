<template>
  <div style="margin-top:20px; width:80000px;">
    <v-row
      ><v-card
        style="width:300px; margin-left:20px; background-color:#eee;"
        class="studentslist"
        flat
        outlined
      >
        <v-card-text>
          {{ this.studentclass + " - " + this.studentname }}</v-card-text
        >
      </v-card>

      <v-row>
        <span v-for="assignment in assignmants" :key="assignment.key">
        
          <AssignmentGrade
            :student="path"
            :assignmant="assignment.key"
            :asspath="assignment.path"
            :title="assignment.title"
            :weight="assignment.weight"
            :studentid="studentid"
          />
        </span>
      </v-row>
    </v-row>
    <v-divider />
  </div>
</template>
<script>
// import firebase from "@/Firebase";
import AssignmentGrade from "./AssignmentGrade";

export default {
  name: "GradeLesson",
  props: ["path", "studentname", "assignmants", "studentclass", "studentid"],
  components: { AssignmentGrade },
  data() {
    return {
      assignments: [],
      lessons: [],
      weights: [],
      participation: [],
      week: ""
    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped>
.studentslist {
  height: 100%;
  background: darkgrey;
  position: sticky;
  left: 0;
  width: 100px;
  z-index: 1000;
}
</style>
