<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ this.assignment.title }}</v-card-title>

        <v-card-text>
          <b-row>
            <b-col cols="3" style="background-color:#eee">
              <v-toolbar dark flat>
                <v-toolbar-title>
                  <v-icon size="30px">mdi-account-tie-outline</v-icon
                  >&nbsp;Classes
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-btn
                @click="clearSearch"
                x-small
                color="#66BB6A"
                block
                dark
                depressed
                >Clear Search</v-btn
              >

              <v-list
                shaped
                color="light-blue darken-3"
                dark
                style="margin-top:20px"
              >
                <v-list-item
                  v-for="c in classList"
                  :key="c"
                  href="#"
                  @click="loadClass(c)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-google-classroom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ c }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </b-col>
            <b-col cols="9">
              <div v-if="classslected == true">
                <div v-if="students.length > 0">
                  <Attemp
                    v-for="student in students"
                    :key="student.key"
                    style="margin-bottom:10px"
                    :student="
                      student.firstname +
                        ' ' +
                        student.middlename +
                        ' ' +
                        student.lastname
                    "
                    :id="student.key"
                    :assgrade="assignment.grade"
                    :assignment="asspath"
                    :assid="assid"
                    :type="assignment.type"
                    :weight="assignment.weight"
                    :week="assignment.week"
                    :weektitle="assignment.weektitle"
                    :weightcode="assignment.weightcode"
                    :branch="assignment.branch"
                    :feedback="assignment.feedback"
                    :gradelevel="assignment.gradelevel"
                  />
                </div>
                <div v-else>
                  <v-sheet color="darken-2" class="pa-3" height="800px">
                    <v-skeleton-loader
                      type="article, actions"
                    ></v-skeleton-loader>
                  </v-sheet>
                </div>
              </div>
              <div v-else>
                Select Class First to Start Grading
              </div>
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import Attemp from "@/components/lms/assignments/Attemp";

export default {
  name: "Submissions-page",
  components: { Attemp },
  props: ["path", "grade"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "student",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center" }
      ],
      classes: [],
      classList: JSON.parse(localStorage.getItem("classList")),
      gradePath: this.$store.state.gradePath,

      assid: localStorage.getItem("assid"),
      asspath: localStorage.getItem("asspath"),
      assgrade: localStorage.getItem("assgrade"),
      asstype: localStorage.getItem("asstype"),

      classslected: false,
      assignment: {},

      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.gradePath + "/classes")
    };
  },
  created() {
 
    firebase
      .firestore()
      .doc(this.asspath)
      .get()
      .then(assign => {
        this.assignment = assign.data();

      });

    this.ref.get().then(querySnapshot => {
      this.classes = [];
      querySnapshot.forEach(doc => {
        this.classes.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title
        });
      });
    });
  },
  methods: {
    loadClass(sclass) {
      this.classslected = true;
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            console.log("key",doc.id)
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              class: doc.data().class
            });
          });
        });
        
    },

    goToGrades(student, studentName) {
      router.push({
        name: "Gradebook",
        params: { student: student, sname: studentName }
      });
    },
    clearSearch() {
      this.classslected = false;
      this.students = [];
    }
  }
};
</script>
