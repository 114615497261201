var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_vm._v(" Gradebook "),_c('v-spacer'),_c('span',{staticStyle:{"font-size":"12px","cursor":"pointer"},on:{"click":function($event){return _vm.goToWeight()}}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-cog")]),_vm._v(" Manage Weights")],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":"","outlined":""},on:{"click":function($event){return _vm.goToGradebookOverview()}}},[_c('v-card-text',[_c('img',{attrs:{"src":require("@/assets/studentprog.png"),"width":"80px"}}),_c('h6',{staticStyle:{"margin-top":"20px","margin-bottom":"-2px"}},[_vm._v(" Grades Overview ")]),_vm._v(" Click Here to View Students Grades ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{attrs:{"columns":_vm.fields,"rows":_vm.students,"search-options":{ enabled: true, skipDiacritics: true },"pagination-options":{
                enabled: false,
                mode: 'records',
                perPage: 100
              }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('v-btn',{attrs:{"color":"info","small":"","depressed":"","block":""},on:{"click":function($event){return _vm.goToGrades(
                        props.row.path,

                        props.row.studentname,
                        props.row.studentID
                      )}}},[_vm._v(" Progress Report ")])],1):(props.column.field == 'check')?_c('span',[_c('v-checkbox')],1):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }