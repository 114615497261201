<template>
  <div class="app-container" style="padding-top:100px">
    <v-card flat outlined
      ><v-card-text>
        <v-card v-for="log in logs" :key="log.date" flat outlined
          ><v-card-text>{{ log }}</v-card-text></v-card
        >
        <v-card><v-card-text> </v-card-text> </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "UploadExcel",
  components: {},
  data() {
    return {
      course: "",
      target: "",
      grade: "",
      week_title: "",
      question_path: "",
      new_path: "",
      weeks: [],
      tableData: [],
      tableHeader: [],
      logs: [],
      question: {},
      answer: {},
      current: 0,
      progress: 0,
      questionsCount: 0
    };
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    deleteOld() {
      console.log("Start Deleting");
      firebase
        .firestore()
        .collection("activities")
        .where("grade", "==", "Grade 1")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            doc.ref.delete().then(() => {
              console.log("Ok");
            });
          });
        });
    },

    importQuestions: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        this.question = {};

        this.question.system = this.tableData[i]["system"].trim();
        this.question.grade = this.tableData[i]["grade"].trim();

        this.question.subject = this.tableData[i]["subject"].trim();

        // this.video.section = this.tableData[i]["section"].trim();
        this.question.title =
          this.tableData[i]["text"] == null
            ? " "
            : this.tableData[i]["text"].trim();
        this.question.type =
          this.tableData[i]["type"] == null
            ? " "
            : this.tableData[i]["type"].trim();

        console.log(this.question);

        firebase
          .firestore()
          .collection(
            "questions_hub/" +
              this.question.system +
              "/grades/" +
              this.question.grade +
              "/subjects/" +
              this.question.subject +
              "/questions"
          )
          .doc(this.tableData[i]["question_Id"])
          .set(this.question)
          .then(() => {
            //Create User
            console.log("Success Added");
            this.current++;
            this.progress = (this.tableData.length / this.current) * 100;
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    importAnswers: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        this.answer = {};

        this.answer.system = this.tableData[i]["system"].trim();
        this.answer.grade = this.tableData[i]["grade"].trim();

        this.answer.subject = this.tableData[i]["subject"].trim();

        // this.video.section = this.tableData[i]["section"].trim();
        this.answer.title =
          this.tableData[i]["text"] == null
            ? " "
            : this.tableData[i]["text"].trim();

        this.answer.fraction =
          this.tableData[i]["fraction"].trim() == null
            ? "0"
            : this.tableData[i]["fraction"].trim();

        console.log(this.answer);

        firebase
          .firestore()
          .collection(
            "questions_hub/" +
              this.answer.system +
              "/grades/" +
              this.answer.grade +
              "/subjects/" +
              this.answer.subject +
              "/questions"
          )
          .doc(this.tableData[i]["question_Id"])
          .collection("options")
          .add(this.answer)
          .then(() => {
            //Create User
            console.log("Success Added");
            this.current++;
            this.progress = (this.tableData.length / this.current) * 100;
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    transfer() {
      //   firebase
      //     .firestore()
      //     .collection("activities")
      //     .onSnapshot(querySnapshot => {
      //       querySnapshot.forEach(doc => {
      //         firebase
      //           .firestore()
      //           .collection(
      //             "activities_hub/" +
      //               doc.data().system +
      //               "/grades/" +
      //               doc.data().grade +
      //               "/subjects/" +
      //               doc.data().subject +
      //               "/videos"
      //           )
      //           .add(doc.data())
      //           .then(() => {
      //             console.log("Done");
      //           })
      //           .catch(() => {});
      //       });
      //     });
    },

    transOptions(oldPath, newPath) {
      firebase
        .firestore()
        .collection(oldPath + "/options")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.options = doc.data();
            firebase
              .firestore()
              .collection(newPath + "/options")
              .add(this.options)
              .then(() => {
                console.log("Option Added");
              });
          });
        });
    },
    importfrom() {
      firebase
        .firestore()
        .collection("/subjects/" + this.grade + "/weeks")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(week => {
            console.log(
              week.ref.path + "/resources/" + week.data().test + "/questions"
            );

            //Start Transfer
            firebase
              .firestore()
              .collection(
                week.ref.path + "/resources/" + week.data().test + "/questions"
              )
              .onSnapshot(querySnapshot => {
                this.current = 0;
                querySnapshot.forEach(old_question => {
                  this.question = old_question.data();
                  this.question.difficulty =
                    old_question.data().level == null
                      ? "1"
                      : old_question.data().level;
                  this.question.week = week.data().title;

                  console.log(this.question);

                  firebase
                    .firestore()
                    .collection(
                      "/questions_hub/American System/grades/High School/subjects/" +
                        this.target +
                        "/questions"
                    )
                    .add(this.question)
                    .then(new_question => {
                      this.current++;

                      this.transOptions(
                        old_question.ref.path,
                        new_question.path
                      );
                      console.log("Question Added");
                    })
                    .catch(error => {
                      console.log(error);
                    });
                });
              });
          });
        });
    }
  },
  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/logs")
      .where("location", "==", "HR")
      .where("user", "==", "ramy.saad@nis-egypt.com")
      .get()
      .then(logs => {
        logs.forEach(doc => {
          this.logs.push(doc.data());
        });
      });
  }
};
</script>
