<template>
  <div style="padding-bottom:20px">
    <a @click="goToPage()">
      <b-card>
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <div class="icon-big text-center">
                <v-icon size="70px">{{ this.icon }}</v-icon>
              </div>
            </div>
            <div class="col-7">
              <div class="numbers">
                <h3 v-if="this.path != null">{{ this.reccount }}</h3>
                <small v-else class="card-title">Click to Manage</small>
                <p class="card-category">{{ this.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </a>
  </div>
</template>
<script>
import router from "@/router";
import firebase from "@/Firebase";

export default {
  name: "SettingCard",
  props: ["title", "icon", "link", "count", "path"],
  data() {
    return {
      reccount: 0
    };
  },
  methods: {
    goToPage() {
      router.push({ path: "/" + this.link });
    }
  },
  created() {
    if (this.path != null) {
      firebase
        .firestore()
        .collection(this.path)
        .get()
        .then(snapshot => (this.reccount = snapshot.size));
    }
  }
};
</script>
