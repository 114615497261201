<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>Edit Standard</v-card-title>
        <v-card-subtitle> {{ this.$route.params.title }} </v-card-subtitle>

        <v-card-text>
          <v-autocomplete
            v-model.trim="standard.parent"
            :items="standards"
            label="Parent Standard"
          ></v-autocomplete>

          <v-text-field
            id="fieldCode"
            v-model="standard.code"
            label="Standard Code"
          ></v-text-field>

          <v-text-field
            id="Standardtitle"
            label="Standard Source"
            value
            autofocus
            v-model="standard.source"
          ></v-text-field>

          <v-text-field
            id="fieldSourcce"
            v-model="standard.topic"
            label="Standard Topic"
          ></v-text-field>

          <v-autocomplete
            v-model.trim="standard.domain"
            :items="domains"
            label="Domain"
            @change="onDomainChange()"
          ></v-autocomplete>

          <v-autocomplete
            v-model.trim="standard.cluster"
            :items="clusters"
            label="Cluster"
          ></v-autocomplete>

          <v-text-field
            id="StandardTtile"
            v-model="standard.title"
            label="Standard Description"
          ></v-text-field>
        </v-card-text>
        <v-card-actions
          ><v-spacer /><v-btn color="success" small @click="onSubmit"
            >Edit Standard</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditStandard",
  props: ["week", "title"],
  components: {},
  data() {
    return {
      standard: {},
      domains: [],
      clusters: [],
      standards: []
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.standard = snapshot.data();
      });

    this.getDomains();
    this.getStandards();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .update(this.standard)
        .then(() => {
          this.$bvToast.toast("Standard Updated", {
            title: "Standard Updated Successfully",
            variant: "success",
            solid: true
          });

          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    getDomains() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/domains")
        .onSnapshot(querySnapshot => {
          this.domains = [];
          querySnapshot.forEach(doc => {
            this.domains.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().title,
              value: [doc.data().title, doc.data().code]
            });
          });
        });
    },
    getStandards() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards")
        .where("parent", "==", "")
        .onSnapshot(querySnapshot => {
          this.standards = [];
          querySnapshot.forEach(doc => {
            this.standards.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().title,
              value: doc.data().code
            });
          });
        });
    },

    onDomainChange() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/clusters")
        .where("domain_id", "==", this.standard.domain[1])
        .onSnapshot(querySnapshot => {
          this.clusters = [];
          querySnapshot.forEach(doc => {
            this.clusters.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().title,
              value: [doc.data().title, doc.data().code]
            });
          });
        });
    }
  }
};
</script>
