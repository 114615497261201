<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-account-plus-outline</v-icon>

          <span class="title font-weight-light">Add New Application</span>
        </v-card-title>

        <v-container>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Student Name -->

            <v-container>
              <v-expansion-panels accordion :value="0">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="light-blue darken-4"
                    style="color:#fff"
                    >Basic Information</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row center-block>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label="First name"
                          v-model.trim="student.firstname"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Middle name"
                          v-model.trim="student.middlename"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          label="Last name"
                          v-model.trim="student.lastname"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Schools Level -->
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-select
                          :items="schools"
                          v-model="student.branch"
                          label="School"
                          @change="onSchoolChange()"
                        ></v-select>
                      </v-col>
                      <!-- Grade Level -->
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-select
                          :items="grades"
                          v-model="student.grade"
                          label="Grade Level"
                          @change="onGradeChange()"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="light-blue darken-3"
                    style="color:#fff"
                    >Student Information</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row center-block>
                      <!-- Gender -->
                      <v-col class="d-flex" cols="6" sm="6">
                        <v-select
                          :items="['Male', 'Female']"
                          v-model.trim="student.gender"
                          label="Gender"
                        ></v-select>
                      </v-col>

                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Citizenship"
                          v-model.trim="student.citizenship"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          label="Address"
                          v-model.trim="student.address"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Home Phone"
                          v-model.trim="student.home_phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Mobile"
                          v-model.trim="student.mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="light-blue darken-4"
                    style="color:#fff"
                    >Family Information</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <br />
                    <!-- Father Information -->

                    <v-alert border="bottom" color="green darken-1" dark>
                      <h6>Father Information</h6>
                    </v-alert>

                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Father Academic Degree"
                        v-model.trim="student.father_academic_degree"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Father Occupation"
                        v-model.trim="student.father_occupation"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Father Company Business"
                        v-model.trim="student.father_company_business"
                      ></v-text-field>
                    </v-col>

                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Father Email"
                          v-model.trim="student.father_email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Father Mobile"
                          v-model.trim="student.father_mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- End Father Information -->
                    <br />
                    <!-- Father Information -->

                    <v-alert border="bottom" color="green darken-1" dark>
                      <h6>Mother Information</h6>
                    </v-alert>

                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Name"
                        v-model.trim="student.mother_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Academic Degree"
                        v-model.trim="student.mother_academic_degree"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Occupation"
                        v-model.trim="student.mother_occupation"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Mother Company Business"
                        v-model.trim="student.mother_company_business"
                      ></v-text-field>
                    </v-col>

                    <v-row>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Mother Email"
                          v-model.trim="student.mother_email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Mother Mobile"
                          v-model.trim="student.mother_mobile"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- End Father Information -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary"
                >Add Application</b-button
              >
            </b-card-footer>
          </b-form>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "AddApplication",

  data() {
    return {
      panel: 0,
      student: {},
      grades: [],
      schools: [],
      gclasses: [],
      selectedGrade: "",
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.student.school = this.$store.state.school;
      this.student.type = "student";

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/admission/")
        .add(this.student)
        .then(() => {
          this.student.firstname = "";
          this.student.middlename = "";
          this.student.lastname = "";
          this.student.grade = "";

          router.push("AdmissionList");
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    onSchoolChange() {
      this.gclasses = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },
    onGradeChange() {
      firebase
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade +
            "/classes/"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.gclasses = [];
          querySnapshot.forEach(doc => {
            this.gclasses.push({
              text: doc.data().title,
              value: doc.data().title
            });
          });
        });
    }
  },
  created() {
    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
