<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New School</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <b-card>
          <b-form @submit.stop.prevent="onSubmit">
            <v-col cols="12" md="4">
              <v-text-field
                label="School Title"
                v-model.trim="branches.title"
                required
              ></v-text-field>
            </v-col>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add School</b-button>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "AddSchool",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/branches"),
      branches: {}
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.ref
        .doc(this.branches.title)
        .set(this.branches)

        .then(() => {
          this.$bvToast.toast("School Created", {
            title: "School Created Successfully",
            variant: "success",
            solid: true
          });
          this.branches.title = "";
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
