<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Appraisals"
          color="primary"
          :path="'/schools/' + this.$store.state.school + '/appraisals'"
        ></CountCard>
        <CountCard title="Active" color="success"></CountCard>
        <CountCard title="Inactive" color="danger"></CountCard>
      </b-card-group>
      <br />

      <v-card>
        <v-card-text>
          <div>
            <CRUD
              title="Appraisal"
              :path="'/schools/' + this.$store.state.school + '/appraisals'"
              :fields="this.fields"
              width="500"
              height="600"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "Appraisal-page",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      fields: [
        {
          field: "date",
          label: "Appraisal Date",
          sortable: true,
          class: "text-left",
          ftype: "date"
        },
        { field: "title", label: "Title", sortable: true, class: "text-left" },
        {
          field: "active",
          label: "Active",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style></style>
