<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title> Exam Control </v-card-title>
        <v-card-subtitle> {{ this.$store.state.breakdown }} Control</v-card-subtitle>

        <v-card-text>
          <b-row>
            <b-col cols="3">
              <v-toolbar color="green light-3" dark flat>
                <v-toolbar-title>
                  <img
                    src="@/assets/students.png"
                    class=" ma-2"
                    width="44px"
                  />&nbsp;Classes List
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-list shaped style="background-color:#d7e9f9">
                <v-list-item
                  v-for="c in classList"
                  :key="c"
                  href="#"
                  @click="loadClass(c)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-google-classroom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ c }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </b-col>
            <b-col cols="9">
              <v-col cols="12" sm="6" md="4"> </v-col>

              <vue-good-table
                :columns="fields"
                :rows="students"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <div v-if="$store.state.control">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            type="number"
                            v-model="grades[props.row.key]"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            small
                            color="success"
                            depressed
                            @click="
                              saveExam(
                                props.row.path,
                                grades[props.row.key],
                                feedback[props.row.key],
                                props.row.class
                              )
                            "
                            >Save Exam Grade</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Feedback"
                            hint="Feedback"
                            v-model="feedback[props.row.key]"
                        /></v-col>
                      </v-row>
                    </div>
                  </span>

                  <span v-else-if="props.column.field == 'grade'">
                    <getgrade :key="props.row.key" :student="props.row.path" />
                    <br />
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import getgrade from "./getgrade.vue";

export default {
  name: "Participation-page",
  components: { getgrade },
  props: ["path", "title"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      grades: [],
      feedback: [],
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center", width: "400px" }
      ],
      classes: [],
      classList: JSON.parse(localStorage.getItem("classList")),

      gradePath: this.$store.state.gradePath,

      grade: this.$store.state.thisGrade,
      subject: this.$store.state.subjectTitle,
      subjectPath: this.$store.state.subject,

      students: [],
      errors: []
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$store.state.gradePath + "/classes")
      .get()
      .then(querySnapshot => {
        this.classes = [];
        querySnapshot.forEach(doc => {
          this.classes.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title
          });
        });
      });
  },
  methods: {
    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/teachers/")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    },
    saveExam(path, grade, feedback, sclass) {
      this.$confirm({
        message: "Are you sure You want to save this Exam Grade?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.record.date = this.date;
            this.record.type = "exam";
            this.record.breakdown = this.$store.state.breakdown;
            this.record.school = this.$store.state.school;
            this.record.gradelevel = this.grade;
            this.record.subject = this.subject;
            this.record.subjectPath = this.subjectPath;
            this.record.grade = Number(grade);

            this.record.branch = this.$store.state.thisBranch;
            this.record.class = sclass;

            this.record.employee = this.$store.state.userID;

            this.record.feedback = feedback ?? " ";

            firebase
              .firestore()
              .doc(path)
              .collection("examgrades")
              .doc(this.$store.state.subjectTitle)
              .set(this.record)
              .then(() => {})
              .catch(error => {
                alert("Error removing document: ", error);
              });
              firebase
              .firestore()
              .doc(path)
              .collection("examgrades")
              .doc(this.$store.state.subjectTitle)
              .collection('breakdown')
              .doc(this.$store.state.breakdown)
              .set(this.record)
              .then(() => {})
              .catch(error => {
                alert("Error removing document: ", error);
              });

          }
        }
      });
    }
  }
};
</script>
