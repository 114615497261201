<template>
  <v-card>
    <v-card-title>Students Attendance</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <b-form-datepicker
            id="livedate"
            v-model="date"
            class="mb-2"
          ></b-form-datepicker>
        </v-col>
        <v-col cols="3">
          <v-btn @click="reload()" color="info" depressed small
            >Select Date</v-btn
          >
        </v-col>
      </v-row>
      <b-row>
        <b-col cols="3">
          <v-card flat outlined>
            <v-card-title> {{ this.data.branch }}</v-card-title>
            <v-card-subtitle>Select Grade</v-card-subtitle>
            <v-card-text>
              <div v-for="grade in grades" :key="grade">
                <v-btn
                  depressed
                  color="primary"
                  small
                  style="margin-bottom:5px"
                  @click="selectGrade(grade.trim())"
                  block
                >
                  {{ grade }}</v-btn
                >
              </div>
            </v-card-text></v-card
          >
        </b-col>
        <b-col cols="9">
          <v-card flat outlined
            ><v-card-title> {{ this.grade }} </v-card-title
            ><v-card-text>
              <vue-good-table
                :columns="fields"
                :rows="requests"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action'">
                    <v-btn-toggle borderless>
                      <v-btn
                        small
                        depressed
                        color="success"
                        @click="saveStatus(props.row.path, 'approved')"
                        >Approved</v-btn
                      >
                      <v-btn
                        small
                        depressed
                        color="error"
                        @click="saveStatus(props.row.path, 'rejected')"
                        >Reject</v-btn
                      >
                    </v-btn-toggle>
                  </span>
                  <span v-else-if="props.column.field == 'send'"
                    ><v-icon color="orange">mdi-share</v-icon>Send to
                  </span>
                  <span v-else-if="props.column.field == 'details'">
                    <v-btn x-small depressed color="info" block
                      ><v-icon size="14">mdi-file</v-icon></v-btn
                    >
                  </span>
                  <span v-else-if="props.column.field == 'status'">
                    <v-chip
                      color="red"
                      v-if="props.row.status == 'rejected'"
                      dark
                      >Rejected</v-chip
                    >
                    <v-chip
                      color="success"
                      v-else-if="props.row.status == 'approved'"
                      dark
                      >Approved</v-chip
                    >
                    <v-chip color="info" v-else dark>Pending</v-chip>
                  </span>
                </template>
              </vue-good-table>
            </v-card-text></v-card
          >
        </b-col>
      </b-row>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "Requests-page",
  components: {},

  data() {
    return {
      emp: this.$store.state.userID,
      grade: "",
      data: {},
      grades: [],
      requests: [],
      record: {},
      fields: [
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },

        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "type",
          label: "Status",
          sortable: true,
          class: "text-left"
        },

        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "details",
          label: "Details",
          sortable: true,
          class: "text-left"
        }
        // {
        //   field: "action",
        //   label: "Action",
        //   sortable: true,
        //   class: "text-left"
        // }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .doc(
        "schools/" +
          this.$store.state.school +
          "/employees/" +
          this.$store.state.userID
      )
      .get()
      .then(querySnapshot => {
        this.data = "";

        this.data = querySnapshot.data();
        this.grades = this.data.admingrades.split(",");
      });
  },
  methods: {
    reload() {
      console.log(this.data.branch);
      console.log(this.grade);
      console.log(this.date);
      this.requests = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/attendance")
        .where("branch", "==", this.data.branch)
        .where("grade", "==", this.grade)
        .where("date", "==", this.date)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.requests.push({
              key: doc.id,
              path: doc.ref.path,
              grade: doc.data().grade,
              class: doc.data().class,
              date: doc.data().date,
              type: doc.data().type,
              subject: doc.data().subject,
              studentname: doc.data().studentname
            });
          });
        });
    },
    selectGrade(grade) {
      this.grade = grade;
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/attendance")
        .where("branch", "==", this.data.branch)
        .where("grade", "==", grade)
        .onSnapshot(querySnapshot => {
          this.requests = [];
          querySnapshot.forEach(doc => {
            this.requests.push({
              key: doc.id,
              path: doc.ref.path,
              grade: doc.data().grade,
              class: doc.data().class,
              date: doc.data().date,
              type: doc.data().type,
              subject: doc.data().subject,
              studentname: doc.data().studentname
            });
          });
        });
    },

    saveStatus(path, status) {
      this.record.status = status;
      firebase
        .firestore()
        .doc(path)
        .update(this.record)
        .then(() => {})
        .catch(error => {
          alert("Error removing document: ", error);
        });
    }
  }
};
</script>
