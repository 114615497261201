<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-container>
          <b-form @submit.stop.prevent="onSubmit">
            <b-form-group
              id="fieldsetname"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Title"
            >
              <quill-editor
                ref="name"
                v-model="lesson.title"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
              />
            </b-form-group>

            <b-form-group
              id="fieldbloom"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Bloom's Level"
            >
              <b-form-select
                id="bloom"
                placeholder="Bloom's Level"
                v-model.trim="lesson.bloom"
                :options="bloom"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="fielddok"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="DOK Level"
            >
              <b-form-select
                id="dok"
                placeholder="DOK Level"
                v-model.trim="lesson.dok"
                :options="dok"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="fielddifficulty"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Difficulty Level"
            >
              <b-form-select
                id="difficulty"
                placeholder="Difficulty Level"
                v-model.trim="lesson.difficulty"
                :options="difficulty"
              ></b-form-select>
            </b-form-group>

            <!-- Standards Faild -->
            <b-form-group
              id="fieldstandard"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Standard"
            >
              <b-form-select
                id="qstandard"
                placeholder="Standard"
                v-model.trim="lesson.standard"
                :options="standards"
              ></b-form-select>
            </b-form-group>
            <!-- ----------------- -->

            <b-form-group
              id="fieldqtype"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Question Type"
            >
              <b-form-select
                id="qtype"
                placeholder="Question Type"
                v-model.trim="lesson.qtype"
                :options="qtype"
              ></b-form-select>
            </b-form-group>

            <!-- <b-form-group
              id="fieldstandard"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Standard"
            >
              <b-form-select
                id="standard"
                placeholder="standard"
                v-model.trim="lesson.qtype"
                :options="qtype"
              ></b-form-select>
            </b-form-group>-->

            <b-form-group
              id="fieldanswer"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Answer Feedback"
            >
              <quill-editor
                ref="answer"
                v-model="lesson.answer"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
              />
            </b-form-group>
            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Edit Question</b-button>
            </b-card-footer>
          </b-form>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import katex from "katex";
import "katex/dist/katex.min.css";

export default {
  name: "EditQuestionInBank",
  props: ["path"],
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
            ["image"],
            ["video"],
            ["formula"]
          ]
        }
      },
      ref: firebase.firestore().doc(this.$route.params.path),

      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      lesson: {},
      selected: null,
      types: [
        { value: "video", text: "Video" },
        { value: "question", text: "Question" },
        { value: "show", text: "Lesson Documents" }
      ],
      bloom: [
        { value: "Remembering", text: "Remembering" },
        {
          value: "Understanding",
          text: "Understanding"
        },
        {
          value: "Applying",
          text: "Applying"
        },
        { value: "Analysing", text: "Analysing" },
        { value: "Evaluation", text: "Evaluation" },
        { value: "Creating", text: "Creating" }
      ],
      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      difficulty: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" }
      ],
      qtype: [
        { value: "mc", text: "Multiple Choice" },
        { value: "tf", text: "True/False" },
        { value: "speech", text: "Speech" },
        { value: "complete", text: "Complete" },
        { value: "listening", text: "Listening" }
      ],
      standards: []
    };
  },
  created() {
    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.data().code
        });
      });
    });

    this.ref.get().then(doc => {
      if (doc.exists) {
        this.lesson = doc.data();
      } else {
        alert("No such document!");
      }
    });
  },
  mounted() {
    window.katex = katex;
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .set(this.lesson)
        .then(() => {
          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
