<template>
  <b-card
    :bg-variant="this.color"
    text-variant="white"
    :header="this.title"
    class="text-center"
  >
    <b-card-text>
      <h1 v-if="this.path != null">{{ records.length }}</h1>
      <h1 v-else>0</h1>
    </b-card-text>
  </b-card>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "CountCard",
  props: ["path", "color", "title"],
  data() {
    return {
      records: []
    };
  },
  created() {
    if (this.path != null) {
      firebase
        .firestore()
        .collection(this.path)
        .onSnapshot(querySnapshot => {
          this.records = [];
          querySnapshot.forEach(doc => {
            this.records.push({
              key: doc.id
            });
          });
        });
    }
  }
};
</script>
