<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-view-headline</v-icon>
          <span class="title font-weight-light">View {{ this.title }}</span>
        </v-card-title>
        <v-card-text>
          <div style="overflow: auto; height: 400px">
            <vuescroll>
              <v-container>
                <!-- Job Title -->
                <v-row center-block>
                  <v-col
                    cols="12"
                    md="12"
                    v-for="field in this.fields"
                    :key="field.id"
                  >
                    <p>
                      <small class="m-2" style="color:#08589d"
                        >{{ field.label }}:</small
                      >
                      {{ newfield[field.field] }}
                    </p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <b-button
                  variant="danger"
                  class="float-right"
                  @click="this.$modal.hideAll"
                  >Close</b-button
                >
              </v-container>
            </vuescroll>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "ViewRecord",
  props: ["icon", "title", "path", "fields", "editpath"],

  components: {},
  data() {
    return {
      myfields: this.fields,
      ref: firebase.firestore().doc(this.editpath),
      newfield: {}
    };
  },
  methods: {},
  mount() {
    this.show();
  },

  created() {
    this.ref.get().then(doc => {
      if (doc.exists) {
        this.newfield = doc.data();
      } else {
        alert("No such document!");
      }
    });
  }
};
</script>

<style></style>
