import Vue from "vue";
import Page from "@/Page";
import SubjectPage from "@/SubjectPage";
import DashPage from "@/DashPage";
import Public from "@/Public";
import VueRouter from "vue-router";
// import store from "@/store/index.js";

//LMS-------------------------------------------------------------------
import Leaderboard from "@/components/leaderboard/Leaderboard";


import FleetDash from "@/components/fleet/Fleet";
import ImportStandards from "@/components/lms/standards/ImportStandards";



//LMS-------------------------------------------------------------------
import HODReport from "@/components/hod/Report";


//LMS-------------------------------------------------------------------
import ManageSubjects from "@/components/lms/ManageSubjects";
import Login from "@/components/access/Login";
import WeeksList from "@/components/lms/weeks/WeeksList";
import ShowWeek from "@/components/lms/weeks/ShowWeek";
import AddWeek from "@/components/lms/weeks/AddWeek";
import EditWeek from "@/components/lms/weeks/EditWeek";

import EditRevision from "@/components/lms/weeks/EditRevision";
import EditEnrichment from "@/components/lms/weeks/EditEnrichment";


import AnswersReport from "@/components/reports/AnswersReport";

//Grades
import GradesList from "@/components/setting/grades/GradesList";
import Breakdowns from "@/components/setting/breakdowns/Breakdowns";
import EditGrade from "@/components/setting/grades/EditGrade";
import ShowGrade from "@/components/setting/grades/ShowGrade";

//Subjects
import SubjectsList from "@/components/setting/subjects/SubjectsList";
import ClassesList from "@/components/setting/classes/ClassesList";
import EditClass from "@/components/setting/classes/EditClass";
import EditActivity from "@/components/lms/activities/EditActivity";
import EditQuestionInBank from "@/components/lms/bank/EditQuestionInBank";

import EditQuestion from "@/components/lms/activities/Create/Question.vue";

//Lessons
import ActivitiesList from "@/components/lms/activities/ActivitiesList";

//Options
import OptionsList from "@/components/lms/questions/OptionsList";

//Questions Bank
import BankList from "@/components/lms/bank/QuestionsBankList";

//Subject Dashboard
import SubjectDash from "@/components/lms/subjects/SubjectDashboard";

//Teachers
import TeachersList from "@/components/lms/teachers/TeachersList";

//Control
import Control from "@/components/lms/exam/Control";

import HRDash from "@/components/hr/HR";

//Accounting
import Accounting from "@/components/accounting/Accounting";
import FeesList from "@/components/accounting/fees/FeesList";
import PaymentsList from "@/components/accounting/payments/PaymentsList";
import AccountingSetting from "@/components/accounting/setting/AccountingSetting";

import FeesType from "@/components/accounting/setting/fees/FeesType.vue";
import Paymethods from "@/components/accounting/setting/paymethods/Paymethods.vue";
import Items from "@/components/accounting/inventory/Items.vue";
import Bank from "@/components/accounting/setting/bank/Bank.vue";
import PaymentsType from "@/components/accounting/setting/payments/PaymentsType.vue";
import FixedAssets from "@/components/accounting/fixed-assets/FixedAssets.vue";
import Dimensions from "@/components/accounting/dimensions/Dimensions.vue";

//-----------------------------------------------------------------------

//Fleet
import FleetList from "@/components/fleet/FleetList";
import Services from "@/components/fleet/services/Services";
import FleetSetting from "@/components/fleet/Setting";
import Drivers from "@/components/fleet/drivers/Drivers";
import Supervisors from "@/components/fleet/supervisors/Supervisors";

//-----------------------------------------------------------------------

//HR
import EmployeesList from "@/components/hr/employees/EmployeesList";
import AddEmployee from "@/components/hr/employees/AddEmployee";
import EditEmployee from "@/components/hr/employees/EditEmployee";
import JobsList from "@/components/hr/setting/jobs/JobsList";
import HRSetting from "@/components/hr/setting/HRSetting";
import Recruitment from "@/components/hr/recruitment/dashboard";

import Appraisal from "@/components/hr/appraisal/Appraisal";
import Zoom from "@/components/zoom/Zoom";

import QuestionBank from "@/components/lms/subjects/questionbank/questionbank";
import ExamBank from "@/components/lms/subjects/exambank/examquestionbank";
import ExamSections from "@/components/lms/subjects/exambank/ExamSections";


//-----------------------------------------------------------------------

//Dashboard
import Home from "@/components/dashboard/home";
import Reports from "@/components/dna/Reports";
import Accountingtemp from "@/components/dna/Accounting";

//-----------------------------------------------------------------------
//Assignments
import AssignmentsList from "@/components/lms/assignments/AssignmentsList";
import Extend from "@/components/lms/assignments/Extend";
import EditAssignment from "@/components/lms/assignments/EditAssignment";
import ViewAssignment from "@/components/lms/assignments/ViewAssignment";

import Submissions from "@/components/lms/assignments/Submissions";
import StudentAttempts from "@/components/lms/assignments/StudentAttempts";
import SubmissionReport from "@/components/lms/assignments/SubmissionReport";
import ExamControl from "@/components/lms/subjects/exams/Control";

import ReportCard from "@/components/lms/exam/ReportCard";
import StudentReportCard from "@/components/lms/grades/reports/SubjectProgress";
import WeightDetails from "@/components/lms/grades/reports/WeightDetails";
import AttendanceDetails from "@/components/lms/grades/reports/AttendanceDetails";



import BlendedControl from "@/components/lms/subjects/blended/Control";

import EditLive from "@/components/lms/subjects/live/EditLive";
import Live from "@/components/lms/subjects/live/Live";

//-----------------------------------------------------------------------

//Access
// import Login from "@/components/access/Login";
import Register from "@/components/access/Register";

//-----------------------------------------------------------------------
//Setting
import Setting from "@/components/setting/Setting";
import SchoolsList from "@/components/setting/schools/SchoolsList";
import EditSchool from "@/components/setting/schools/EditSchool";

//-----------------------------------------------------------------------

//SIS
import AddStudent from "@/components/sis/students/AddStudent";
import EditStudent from "@/components/sis/students/EditStudent";
import StudentsList from "@/components/sis/students/StudentsList";
import ParentsList from "@/components/sis/parents/ParentsList";
import ShowStudent from "@/components/sis/students/ShowStudent";

import manageAttendance from "@/components/sis/attendance/manageAttendance";

import Attendance from "@/components/sis/attendance/attendance";
import SubjectAttendance from "@/components/lms/attendance/Attendance";
import SubjectStudents from "@/components/lms/students/StudentsList";
import SubjectExams from "@/components/lms/subjects/exams/exams";
import EditExam from "@/components/lms/subjects/exams/EditExam";

import SubjectCalendar from "@/components/lms/Calendar/Calendar";

import EditSubject from "@/components/setting/subjects/EditSubject";



import ImportStudents from "@/components/sis/import-students";
import ImportEmployees from "@/components/hr/import-employees";
import UploadVideos from "@/components/dna/import-videos";
import UploadStandards from "@/components/dna/import-standards";
import UploadQuestions from "@/components/dna/import-questions";
import Distribution from "@/components/control/distribution";


import ExamSubmissions from "@/components/lms/subjects/exams/Submissions";

import Answers from "@/components/lms/subjects/exams/Answers";



import Exam from "@/components/lms/subjects/exams/exam";


import AllAssignments from "@/components/lms/subjects/AllAssignments";




//Wall
import Wall from "@/components/wall/Wall";

//Admission
import OnlineApplication from "@/components/admission/OnlineApplication";
import InfoApplication from "@/components/admission/InfoApplication";
import AddApplication from "@/components/admission/AddApplication";
import EditApplication from "@/components/admission/EditApplication";
import ViewApplication from "@/components/admission/ViewApplication";
import AdmissionList from "@/components/admission/AdmissionList";
import InformationList from "@/components/admission/infos/InfosList";
import AdmissionSetting from "@/components/admission/Setting";
import SchoolsReport from "@/components/admission/reports/SchoolsReport";

import Confirmation from "@/components/admission/Confirmation";
import InfoConfirmation from "@/components/admission/InfoConfirmation";

import StatusList from "@/components/admission/status/StatusList";
import TimelineList from "@/components/admission/timeline/TimelineList";

import TimeTables from "@/components/timetables/ManageTables";


import Weight from "@/components/lms/weights/Weights";
import GeneralSetting from "@/components/setting/GeneralSetting";
import Locations from "@/components/setting/locations/Locations";
import Houses from "@/components/setting/houses/Houses";

import JobsHub from "@/components/hub/JobsHub";



import EditRecord from "@/components/dna/crud/EditRecord";

import ResourcesReport from "@/components/hod/ResourcesReport";
import SProgressReport from "@/components/lms/reports/activityReport";


import Room from "@/components/messages/Room";
import EditProfile from "@/components/hr/profile/editProfile";
import getAccounts from "@/components/sis/accounts/getAccounts";

import StudentsDash from "@/components/sis/Students";
import AdmissionDash from "@/components/admission/Admission";
import AccountingDash from "@/components/accounting/AccountingDash";
import FeesSetup from "@/components/accounting/FeesSetup";
import Resources from "@/components/lms/weeks/Resources";

import SubjectResources from "@/components/lms/resources/Resources";
import Sections from "@/components/lms/sections/Sections";

import JobDescription from "@/components/hr/employees/JobDescription";


import Standards from "@/components/lms/standards/Standards";
import Domains from "@/components/lms/standards/Domains";
import Topics from "@/components/lms/standards/Topics";
import Clusters from "@/components/lms/standards/Clusters";
import SubStandards from "@/components/lms/standards/SubStandards";
import Distribute from "@/components/lms/standards/Distribute";
import EditStandard from "@/components/lms/standards/EditStandard";
import ImportDomains from "@/components/lms/standards/ImportDomains";
import ImportClusters from "@/components/lms/standards/ImportClusters";



import ImportQuestions from "@/components/lms/bank/ImportQuestions";


import SubjectSetting from "@/components/lms/setting/SubjectSetting";



import LessonReport from "@/components/lms/reports/lessonReports";
import ProgressReport from "@/components/lms/reports/activityReport";
import Gradebook from "@/components/lms/gradebook/Gradebook";
import GradebookOverview from "@/components/lms/gradebook/GradebookOverview";

import Participation from "@/components/lms/participation/Participation";

import SubjectQuestionBank from "@/components/lms/subjects/questionbank/questionbank.vue";
import SelfService from "@/components/hr/self/selfService.vue";
import Timesheet from "@/components/hr/employees/TimeSheet.vue";
import Requests from "@/components/hr/requests/Requests.vue";


import AllReports from "@/components/hod/Reports.vue";
import AssignmentsReport from "@/components/hod/AssignmentsReport.vue";
import AttendanceReport from "@/components/hod/AttendanceReport.vue";
import CollectionReport from "@/components/hod/CollectionReport.vue";
import SchoolsCollectionReport from "@/components/hod/SchoolsCollectionReport.vue";
import HRReport from "@/components/hod/HRReport.vue";
import SchoolsProgressReport from "@/components/hod/SchoolsProgressReport.vue";
import WeeklyProgressReport from "@/components/hod/WeeklyProgressReport.vue";
import StandardsReport from "@/components/hod/StandardsReport.vue";
import StandardsListReport from "@/components/hod/StandardsListReport.vue";
import StudentsReport from "@/components/hod/StudentsReport.vue";
import StudentsProgressReport from "@/components/hod/StudentsProgressReport.vue";
import BalanceMartrixReport from "@/components/hod/BalanceMartrixReport.vue";
import PerDateReport from "@/components/hod/PerDateReport.vue";
import DisountsReport from "@/components/hod/DisountsReport.vue";



import POS from "@/components/pos/Pos.vue";

//-----------------------------------------------------------------------

Vue.use(VueRouter);

const routes = [

    {

        path: '/',
        component: Page,
        meta: {
            requiresAuth: true
        },
        children: [

            {
                path: "/importstudents",
                name: "ImportStudents",
                component: ImportStudents,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/importquestions",
                name: "ImportQuestions",
                component: ImportQuestions,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/importdomains",
                name: "ImportDomains",
                component: ImportDomains,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/importclusters",
                name: "ImportClusters",
                component: ImportClusters,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/breakdowns",
                name: "Breakdowns",
                component: Breakdowns,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/selfservice",
                name: "SelfService",
                component: SelfService,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/zoom",
                name: "Zoom",
                component: Zoom,
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: "/importstandards:path",
                name: "ImportStandards",
                component: ImportStandards,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/questionbank",
                name: "QuestionBank",
                component: QuestionBank,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editsubject",
                name: "EditSubject",
                component: EditSubject,
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: "/requests",
                name: "Requests",
                component: Requests,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/manageattendance",
                name: "manageAttendance",
                component: manageAttendance,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/examsubmissions",
                name: "ExamSubmissions",
                component: ExamSubmissions,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/exam",
                name: "Exam",
                component: Exam,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/studentreportcard:path,:sname,:subject",
                name: "StudentReportCard",
                component: StudentReportCard,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/weightdetails:path",
                name: "WeightDetails",
                component: WeightDetails,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/AttendanceDetails",
                name: "AttendanceDetails",
                component: AttendanceDetails,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/pos",
                name: "POS",
                component: POS,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/answersreport",
                name: "AnswersReport",
                component: AnswersReport,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/wall",
                name: "Wall",
                component: Wall,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/timesheet",
                name: "Timesheet",
                component: Timesheet,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/locations",
                name: "Locations",
                component: Locations,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/houses",
                name: "Houses",
                component: Houses,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/distribution",
                name: "Distribution",
                component: Distribution,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/progressreport:student, :week, :name, :class, :title, :attempt",
                name: "ProgressReport",
                component: ProgressReport,
                meta: {
                    requiresAuth: true
                }
            },






            {
                path: "/feessetup/:path, :title",
                name: "FeesSetup",
                component: FeesSetup,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/room",
                name: "Room",
                component: Room,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editprofile",
                name: "EditProfile",
                component: EditProfile,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editrecord",
                name: "EditRecord",
                component: EditRecord,
                props: true,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/jobshub",
                name: "JobsHub",
                component: JobsHub,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/generalsetting",
                name: "GeneralSetting",
                component: GeneralSetting,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/importemployees",
                name: "ImportEmployees",
                component: ImportEmployees,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/uploadvideos",
                name: "UploadVideos",
                component: UploadVideos,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/uploadstandards",
                name: "UploadStandards",
                component: UploadStandards,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/uploadquestions",
                name: "UploadQuestions",
                component: UploadQuestions,
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: "/timetables",
                name: "TimeTables",
                component: TimeTables,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/feestype",
                name: "FeesType",
                component: FeesType,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/bank",
                name: "Bank",
                component: Bank,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/paymethods",
                name: "Paymethods",
                component: Paymethods,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/items",
                name: "Items",
                component: Items,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/services",
                name: "Services",
                component: Services,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/appraisal",
                name: "Appraisal",
                component: Appraisal,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/paymentstype",
                name: "PaymentsType",
                component: PaymentsType,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/recruitment",
                name: "Recruitment",
                component: Recruitment,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/addapplication",
                name: "AddApplication",
                component: AddApplication,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/admissionsetting",
                name: "AdmissionSetting",
                component: AdmissionSetting,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/schoolsreport",
                name: "SchoolsReport",
                component: SchoolsReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/statuslist",
                name: "StatusList",
                component: StatusList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/timelinelist/:path, :student",
                name: "TimelineList",
                component: TimelineList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/jobdescription",
                name: "JobDescription",
                component: JobDescription,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editapplication/:path, :type",
                name: "EditApplication",
                component: EditApplication,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/viewapplication/:path, :type",
                name: "ViewApplication",
                component: ViewApplication,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/admissionlist",
                name: "AdmissionList",
                component: AdmissionList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/Leaderboard",
                name: "Leaderboard",
                component: Leaderboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/informationlist",
                name: "InformationList",
                component: InformationList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/register",
                name: "Register",
                component: Register,
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: "/fleet",
                name: "FleetList",
                component: FleetList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/fleetsetting",
                name: "FleetSetting",
                component: FleetSetting,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/drivers",
                name: "Drivers",
                component: Drivers,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/supervisors",
                name: "Supervisors",
                component: Supervisors,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/fixedassets",
                name: "FixedAssets",
                component: FixedAssets,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/dimensions",
                name: "Dimensions",
                component: Dimensions,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/reports",
                name: "Reports",
                component: Reports,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/accountingtemp",
                name: "Accountingtemp",
                component: Accountingtemp,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/home",
                name: "Home",
                component: Home,
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                }
            },


            {
                path: "/academic",
                name: "ManageSubjects",
                component: ManageSubjects,
                meta: {
                    requiresAuth: true
                }
            },

            //Subject HR

            {
                path: "/employees",
                name: "EmployeesList",
                component: EmployeesList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/addemployee",
                name: "AddEmployee",
                component: AddEmployee,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editemployee/:path",
                name: "EditEmployee",
                component: EditEmployee,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/hrsetting",
                name: "HRSetting",
                component: HRSetting,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/jobslist",
                name: "JobsList",
                component: JobsList,
                meta: {
                    requiresAuth: true
                }
            },

            //-----------------------------------------

            {
                path: "/feeslist",
                name: "FeesList",
                component: FeesList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/paymentslist",
                name: "PaymentsList",
                component: PaymentsList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/accounting",
                name: "Accounting",
                component: Accounting,
                meta: {
                    requiresAuth: true
                }
            },



            //Grades

            {
                path: "/grades/:path,:school",
                name: "GradesList",
                component: GradesList,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/show-grade/:id,:title",
                name: "ShowGrade",
                component: ShowGrade,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editgrade/:path",
                name: "EditGrade",
                component: EditGrade,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/subjects/:path,:title",
                name: "SubjectsList",
                component: SubjectsList,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/classes/:path",
                name: "ClassesList",
                props: true,
                component: ClassesList,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/editclass/:path",
                name: "EditClass",
                component: EditClass,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editactivity/:path, :type, :subject",
                name: "EditActivity",
                component: EditActivity,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editquestion/:path",
                name: "EditQuestionInBank",
                component: EditQuestionInBank,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editquestionsubject",
                name: "EditQuestion",
                component: EditQuestion,
                meta: {
                    requiresAuth: true
                }
            },







            //Weeks

            {
                path: "/weeks",
                name: "WeeksList",
                component: WeeksList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/show-week/:id",
                name: "ShowWeek",
                component: ShowWeek,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/add-week",
                name: "AddWeek",
                component: AddWeek,
                meta: {
                    requiresAuth: true
                }
            },


            //SIS-------------------------------------
            {
                path: "/students",
                name: "StudentsList",
                component: StudentsList,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/studentattempts",
                name: "StudentAttempts",
                component: StudentAttempts,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/submissionreport",
                name: "SubmissionReport",
                component: SubmissionReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/parents",
                name: "ParentsList",
                component: ParentsList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/student/:id",
                name: "ShowStudent",
                component: ShowStudent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/addstudent",
                name: "AddStudent",
                component: AddStudent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editstudent/:path",
                name: "EditStudent",
                component: EditStudent,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/attendance",
                name: "Attendance",
                component: Attendance,
                meta: {
                    requiresAuth: true
                }
            },

            //Setting-------------------------------------
            {
                path: "/setting",
                name: "Setting",
                component: Setting,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/schools",
                name: "SchoolsList",
                component: SchoolsList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editschool",
                name: "EditSchool",
                component: EditSchool,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/accsetting",
                name: "AccountingSetting",
                component: AccountingSetting,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/studentsdash",
                name: "StudentsDash",
                component: StudentsDash,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/admissiondash",
                name: "AdmissionDash",
                component: AdmissionDash,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/hrdash",
                name: "HRDash",
                component: HRDash,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/accountingdash",
                name: "AccountingDash",
                component: AccountingDash,
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: "/progressreport",
                name: "sProgressReport",
                component: SProgressReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/fleetdash",
                name: "FleetDash",
                component: FleetDash,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },



    {

        path: '/',
        component: SubjectPage,
        meta: {
            requiresAuth: true
        },
        children: [


            {
                path: "/submissions",
                name: "Submissions",
                component: Submissions,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/answers",
                name: "Answers",
                component: Answers,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/teacherslist",
                name: "TeachersList",
                component: TeachersList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/gradebookoverview",
                name: "GradebookOverview",
                component: GradebookOverview,
                meta: {
                    requiresAuth: true
                }
            },


            //Subject Dashboard
            {
                path: "/subjectdash",
                name: "SubjectDash",
                component: SubjectDash,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/resources",
                name: "Resources",
                component: Resources,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editstandard",
                name: "EditStandard",
                component: EditStandard,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/standards",
                name: "Standards",
                component: Standards,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/domains",
                name: "Domains",
                component: Domains,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/topics",
                name: "Topics",
                component: Topics,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/clusters",
                name: "Clusters",
                component: Clusters,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/weights",
                name: "Weights",
                component: Weight,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/subjectresources",
                name: "SubjectResources",
                component: SubjectResources,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/sections",
                name: "Sections",
                component: Sections,
                meta: {
                    requiresAuth: true
                }
            },

            //Lessons
            {
                path: "/lessons/:id,:title",
                name: "ActivitiesList",
                component: ActivitiesList,
                meta: {
                    requiresAuth: true
                }
            },

            //options
            {
                path: "/options",
                name: "OptionsList",
                component: OptionsList,
                meta: {
                    requiresAuth: true
                }
            },


            //Bank
            {
                path: "/bank/:id,:title",
                name: "BankList",
                component: BankList,
                meta: {
                    requiresAuth: true
                }
            },
            //Assignments
            {
                path: "/assignments/:path,:title",
                name: "AssignmentsList",
                component: AssignmentsList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/exambank",
                name: "ExamBank",
                component: ExamBank,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/examsections",
                name: "ExamSections",
                component: ExamSections,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/extend",
                name: "Extend",
                component: Extend,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editassignment/:path,:title",
                name: "EditAssignment",
                component: EditAssignment,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/viewassignment/:path,:title",
                name: "ViewAssignment",
                component: ViewAssignment,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/examcontrol",
                name: "ExamControl",
                component: ExamControl,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/reportcard",
                name: "ReportCard",
                component: ReportCard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/blendedcontrol",
                name: "BlendedControl",
                component: BlendedControl,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/participation",
                name: "Participation",
                component: Participation,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/control",
                name: "Control",
                component: Control,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/lessonreport:title/:path",
                name: "LessonReport",
                component: LessonReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/edit-week/:path, subject",
                name: "EditWeek",
                component: EditWeek,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editenrichment/:path, subject",
                name: "EditEnrichment",
                component: EditEnrichment,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/editrevision/:path, subject",
                name: "EditRevision",
                component: EditRevision,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "subjectattendance",
                name: "SubjectAttendance",
                component: SubjectAttendance,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "subjectstudents",
                name: "SubjectStudents",
                component: SubjectStudents,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/gradebook",
                name: "Gradebook",
                component: Gradebook,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/squestionbank",
                name: "SubjectQuestionBank",
                component: SubjectQuestionBank,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/subjectexams",
                name: "SubjectExams",
                component: SubjectExams,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/allassignments",
                name: "AllAssignments",
                component: AllAssignments,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: "/editexam",
                name: "EditExam",
                component: EditExam,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/subjectcalendar",
                name: "SubjectCalendar",
                component: SubjectCalendar,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/subjectsetting",
                name: "SubjectSetting",
                component: SubjectSetting,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/substandards",
                name: "SubStandards",
                component: SubStandards,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/distribute",
                name: "Distribute",
                component: Distribute,
                meta: {
                    requiresAuth: true
                }
            },



            {
                path: "/editlive",
                name: "EditLive",
                component: EditLive,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/Live",
                name: "Live",
                component: Live,
                meta: {
                    requiresAuth: true
                }
            },




        ]
    },

    //! Reports Router
    {

        path: '/',
        component: DashPage,
        meta: {
            requiresAuth: true
        },
        children: [


            {
                path: "/allreports",
                name: "AllReports",
                component: AllReports,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/hodreport",
                name: "HODReport",
                component: HODReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/disountsreport",
                name: "DisountsReport",
                component: DisountsReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/balancemartrixreport",
                name: "BalanceMartrixReport",
                component: BalanceMartrixReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/schoolsprogressreport",
                name: "SchoolsProgressReport",
                component: SchoolsProgressReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/weeklyprogressreport",
                name: "WeeklyProgressReport",
                component: WeeklyProgressReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/perdatereport",
                name: "PerDateReport",
                component: PerDateReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/standardsreportv",
                name: "StandardsReport",
                component: StandardsReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/studentsreport",
                name: "StudentsReport",
                component: StudentsReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/standardslistreport",
                name: "StandardsListReport",
                component: StandardsListReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/studentsprogressreport",
                name: "StudentsProgressReport",
                component: StudentsProgressReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/resourcesreport",
                name: "ResourcesReport",
                component: ResourcesReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/collectionreport",
                name: "CollectionReport",
                component: CollectionReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/schoolscollectionreport",
                name: "SchoolsCollectionReport",
                component: SchoolsCollectionReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/hrreport",
                name: "HRReport",
                component: HRReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/attendancereport",
                name: "AttendanceReport",
                component: AttendanceReport,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/assignmentsreport",
                name: "AssignmentsReport",
                component: AssignmentsReport,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },

    {
        path: '/',
        component: Public,
        children: [

            {
                path: "/login",
                name: "Login1",
                component: Login
            },
            {
                path: "/school/:school",
                name: "Login2",
                component: Login
            },
            {
                path: "/",
                name: "Login3",
                component: Login
            },

            {
                path: "/reg",
                name: "Admission",
                component: OnlineApplication
            },
            {
                path: "/info",
                name: "Info",
                component: InfoApplication
            },
            {
                path: "/confirmation",
                name: "Confirmation",
                component: Confirmation
            },
            {
                path: "/infoconfirmation",
                name: "InfoConfirmation",
                component: InfoConfirmation
            },
            {
                path: "/getaccounts",
                name: "getAccounts",
                component: getAccounts
            },


        ]
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("loggedIn") == "true") {
            next();
        } else {
            next("/school/" + localStorage.getItem("school"));
        }
    } else {
        next();
    }
});

export default router;