<template>
  <v-row>
    <v-col cols="8">
      <v-card flat outlined>
        <v-card-text><AccountingList /> </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card
        flat
        style="cursor: pointer; text-align:center;"
        @click="goToPage()"
      >
        <v-card-text>
          <img src="@/assets/rec.png" height="80px" />

          <h6 style="padding-top:20px">
            Banks
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage()"
      >
        <v-card-text>
          <img src="@/assets/leaderboard.png" height="80px" />

          <h6 style="padding-top:20px">
            Payments
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage()"
      >
        <v-card-text>
          <img src="@/assets/employees.png" height="80px" />

          <h6 style="padding-top:20px">
            Import <span style="color:#3096b2;">Payments</span>
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage()"
      >
        <v-card-text>
          <img src="@/assets/reports.png" height="80px" />

          <h6 style="padding-top:20px">
            ERP <span style="color:#3096b2;">Setting</span>
          </h6>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import firebase from "@/Firebase";
import router from "@/router";
import AccountingList from "@/components/accounting/Accounting";

export default {
  name: "ManageSubjects",
  components: { AccountingList },

  data() {
    return {
      errors: [],
      subjects: [],

      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: ""
    };
  },
  created() {},
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
