<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button
        variant="#fff"
        style="background-color:#fdcd06; color:#000; width:30%;"
        v-bind="attrs"
        v-on="on"
        squared
      >
        <img src="@/assets/favourite.png" width="40px" />
        <small style="margin-left: 10px;">Create Revision</small>
      </b-button>
    </template>
    <v-card flat outlined>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-history</v-icon>Create
          Revision
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <b-row>
          <b-col cols="12">
            <v-card outlined flat>
              <v-card-text>
                <b-form @submit.stop.prevent="onSubmit">
                  <!-- Week Name -->
                  <b-form-group
                    id="fieldsetname"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="Name"
                  >
                    <b-form-input
                      id="name"
                      placeholder="Revision title"
                      v-model.trim="week.title"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-checkbox
                    v-model="week.hidden"
                    name="check-button"
                    switch
                  >
                    Hide The Revision
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="week.current"
                    name="check-button"
                    switch
                  >
                    Current Revision
                  </b-form-checkbox>

                  <br />

                  <b-form-group
                    id="fieldseweeks"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                  >
                    <!--Revision Select Weeks-->
                    <v-autocomplete
                      v-model.trim="selectedWeek"
                      :items="weeks"
                      dense
                      label="Select Week"
                    ></v-autocomplete>

                    <div style="margin-bottom:20px;">
                      <v-btn
                        color="success"
                        small
                        depressed
                        @click="addToRevision"
                        >Add Week to Revision</v-btn
                      >
                      <v-btn
                        style="margin-left:10px; "
                        color="error"
                        small
                        depressed
                        @click="clearWeeks"
                        >Clear Weeks</v-btn
                      >
                    </div>

                    <RevisionWeek
                      v-for="week in revisionWeeks"
                      :key="week"
                      :path="week"
                    />
                  </b-form-group>
                  <br />

                  <b-form-group
                    id="fieldObjectives"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="Exam Outline & Guideline"
                  >
                    <quill-editor
                      ref="name"
                      required
                      v-model="week.outline"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                    />
                  </b-form-group>

                  <!-- Upload Image -->
                  <v-col cols="12" md="12">
                    <b-form-group
                      id="fielplan"
                      horizontal
                      :label-cols="4"
                      breakpoint="md"
                      label="Review Packs"
                    >
                      <b-form-file
                        type="file"
                        @change="previewImage"
                        accept=".pdf, .pptx, .doc, .docx"
                      />

                      <div>
                        <p>
                          <small>
                            Progress:
                            {{ uploadValue.toFixed() + "%" }}
                          </small>
                        </p>
                      </div>
                      <div v-if="this.weekly != null">
                        Upload Completed
                      </div>
                      <div v-if="imageData != null">
                        <v-progress-linear
                          id="progress"
                          color="light-blue"
                          height="10"
                          :value="uploadValue"
                          max="100"
                          striped
                        ></v-progress-linear>
                        <b-button @click="onUpload" variant="success"
                          >Upload</b-button
                        >
                      </div>
                    </b-form-group>

                    <!-- Answers Model -->
                    <b-form-group
                      id="fielplan"
                      horizontal
                      :label-cols="4"
                      breakpoint="md"
                      label="Answer Key"
                    >
                      <b-form-file
                        type="file"
                        @change="previewAnswer"
                        accept=".pdf, .pptx, .doc, .docx"
                      />

                      <div>
                        <p>
                          <small>
                            Progress:
                            {{ uploadValue.toFixed() + "%" }}
                          </small>
                        </p>
                      </div>
                      <div v-if="this.answer != null">
                        Upload Completed
                      </div>
                      <div v-if="answerData != null">
                        <v-progress-linear
                          id="progress"
                          color="light-blue"
                          height="10"
                          :value="uploadValue"
                          max="100"
                          striped
                        ></v-progress-linear>
                        <b-button @click="onUploadAnswer" variant="success"
                          >Upload</b-button
                        >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="fielddate"
                      horizontal
                      :label-cols="4"
                      breakpoint="md"
                      label="Revision Date"
                    >
                      <b-form-datepicker
                        id="SessionDate"
                        v-model="date"
                        class="mb-2"
                      ></b-form-datepicker>
                    </b-form-group>
                  </v-col>

                  <!-- Submit Form -->
                  <b-button type="submit" variant="primary"
                    >Add Revision</b-button
                  >
                </b-form>
              </v-card-text>
            </v-card>
          </b-col>
        </b-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "@/Firebase";
import RevisionWeek from "./RevisionWeek";

// import router from "../router";

export default {
  name: "AddWeek",
  props: ["path", "order"],
  components: { RevisionWeek },
  data() {
    return {
      dialog: false,
      selectedWeek: "",
      revisionWeeks: [],
      weeks: [],
      standarop: [{ standard: "", sub: "" }],
      standards_count: 0,
      standards: [],
      sub: [],
      ref: firebase.firestore().collection(this.path + "/weeks"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      date: new Date().toISOString().substr(0, 10),
      week: {},
      menu2: false,
      selected: null,
      options: [
        { value: "1", text: "First Term" },
        { value: "2", text: "Second Term" }
      ],
      sections: [],
      //Upload Weekly Plan

      imageData: null,
      weekly: null,

      uploadValue: 0,

      answerData: null,
      answer: null,

      sectionsRef: firebase.firestore().collection(this.path + "/sections/")
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.week.order = Number(this.order) + 1;
      this.week.date = this.date;
      this.week.revision = true;
      this.week.weeks = this.revisionWeeks;

      if (this.weekly != null) {
        this.week.packs = this.weekly;
      }
      if (this.answer != null) {
        this.week.answers = this.answer;
      }

      this.ref
        .add(this.week)
        .then(() => {
          this.week.title = "";
          this.week.term = "";
          this.week.order = "";
          this.week.section = "";
          this.revisionWeeks = [];

          this.$bvToast.toast("Revision Created", {
            title: "Revision Created Successfully",
            variant: "success",
            solid: true
          });
          this.dialog = false;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },
    clearWeeks() {
      this.revisionWeeks = [];
    },
    addToRevision() {
      if (this.revisionWeeks.includes(this.selectedWeek)) {
        alert("This Week alrady in this Revision");
      } else {
        this.revisionWeeks.push(this.selectedWeek);
      }
    },

    //! Packs
    previewImage(event) {
      this.uploadValue = 0;
      this.weekly = null;
      this.imageData = event.target.files[0];
    },
    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/Review Packs/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.imageData.name
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.weekly = url;
          });
        }
      );
    },

    //! Answers
    previewAnswer(event) {
      this.uploadValue = 0;
      this.answer = null;
      this.answerData = event.target.files[0];
    },
    onUploadAnswer() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/Review Packs/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.answerData.name
        )
        .put(this.answerData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.answer = url;
          });
        }
      );
    }
  },
  created() {
    if (
      this.$store.state.mirror == null ||
      this.$store.state.mirror == "" ||
      this.$store.state.mirror == "undefined"
    ) {
      this.subject = this.path;
    } else {
      this.subject = this.$store.state.mirror;
    }
    firebase
      .firestore()
      .collection(this.subject + "/weeks")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.weeks = [];
        querySnapshot.forEach(doc => {
          const week = doc.data();
          this.weeks.push({
            key: doc.id,
            path: doc.ref.path,
            text: week.title,
            value: doc.ref.path
          });
        });
      });
  }
};
</script>
