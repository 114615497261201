<template>
  <b-col cols="12">
    <b-row>
      <b-col cols="12">
        <v-card>
          <v-card-title>{{ this.$route.params.title }}</v-card-title>
          <p class="card-text">
            <AddSubject></AddSubject>
          </p>
        </v-card>
        <br />
        <v-card>
          <v-card-title>
            <v-icon large left>mdi-calendar-month</v-icon>
            <span class="title font-weight-light">Subjects List</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <Subject
                  v-for="(subject, index) in subjects"
                  :key="index"
                  :path="subject.subject"
                  :index="index"
                  from="admin"
                ></Subject>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- End Subject -->
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddSubject from "./AddSubject";
import Subject from "@/components/dna/Subject";

export default {
  name: "SubjectsList",
  components: {
    AddSubject,
    Subject
  },
  data() {
    return {
      fields: [
        { field: "title", label: "Title", sortable: true, class: "text-left" },

        { field: "actions", label: "Action", class: "text-center" }
      ],
      grade: this.$route.params.title,
      subjects: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$route.params.path + "/subjects/")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.subjects = [];
      querySnapshot.forEach(doc => {
        this.subjects.push({
          subject: doc.ref.path
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.path + "/subjects/")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Subject Deleted", {
                  title: "Subject Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    goToSubject(id, title) {
      this.$store.state.subject = id;
      this.$store.state.grade = this.$route.params.path;
      router.push({
        name: "SubjectDash",
        params: { path: id, title: title }
      });
    }
  }
};
</script>
