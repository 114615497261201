<template>
  <b-col cols="12">
    <v-card flat outlined>
      <v-card-title>
        <img
          src="@/assets/gsetting.png"
          height="50px"
          style="margin-right:10px"
        />
        General Setting
      </v-card-title>
      <v-card-text>
        <b-card-group deck>
          <SettingCard
            title="Schools"
            icon="mdi-bank"
            link="schools"
          ></SettingCard>
          <SettingCard
            title="General Setting"
            icon="mdi-cog"
            link="GeneralSetting"
          ></SettingCard>
          <SettingCard
            title="Locations"
            icon="mdi-domain"
            link="locations"
          ></SettingCard>
          <SettingCard
            title="Admission Status"
            icon="mdi-domain"
            link="statuslist"
          ></SettingCard>
          <SettingCard
            title="Access"
            icon="mdi-lock-clock"
            link="uploadvideos"
          ></SettingCard>

          <!-- <SettingCard
            title="General"
            icon="mdi-badge-account-horizontal-outline"
            link="uploadquestions"
          ></SettingCard> -->

          <SettingCard
            title="General"
            icon="mdi-text-account"
            link="uploadstandards"
          ></SettingCard>
          <SettingCard
            title="Houses"
            icon="mdi-home-group"
            link="houses"
          ></SettingCard>
        </b-card-group>
      </v-card-text>
    </v-card>
  </b-col>
</template>
<script>
import SettingCard from "@/components/dna/widgets/SettingCard";
// import firebase from "@/Firebase";
export default {
  name: "Setting-page",
  components: { SettingCard },
  data() {
    return {
      logs: []
    };
  },
  created() {
    // firebase
    //   .firestore()
    //   .collection("schools" + this.$store.state.school + "logs")
    //   .where(
    //     "location",
    //     "==",
    //     "schools" + this.$store.state.school + "branches/American School/grades/Grade 5/subjects/English/weeks/21/questions/2iXEObGCVnROzwez5SdF"
    //   )
    //   // .where("action", "==", "Delete")
    //   // .where("type", "==", "Question")
    //   .get()
    //   .then(logs => {
    //     logs.forEach(doc => {
    //       this.logs.push({
    //         path: doc.ref.path
    //       });
    //     });
    //   });
  }
};
</script>
