<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Job Title</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <v-card>
          <v-card-title>
            <v-icon large left>mdi-account-plus-outline</v-icon>
            <span class="title font-weight-light">Add New Job Title</span>
          </v-card-title>

          <v-container>
            <b-form @submit.stop.prevent="onSubmit">
              <!-- Job Title -->
              <v-row center-block>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Job Title"
                    v-model.trim="job.title"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <b-form-group
                id="fieldsetname"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Job Description"
              >
                <ckeditor
                  id="name"
                  :editor="editor"
                  v-model.trim="job.description"
                  :config="editorConfig"
                ></ckeditor>
              </b-form-group>

              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add Job Title</b-button>
            </b-form>
          </v-container>
        </v-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "AddApplication",
  data() {
    return {
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/jobs"),

      job: {}
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.ref
        .add(this.job)
        .then(() => {
          this.job.title = "";
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
