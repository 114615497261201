<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-file-pdf-outline</v-icon>

          <span class="title font-weight-light" v-if="source == 'edit'"
            >Edit This Sheet</span
          >
          <span class="title font-weight-light" v-else>Create New Sheet</span>

          <v-spacer /><v-icon @click="this.$modal.hideAll">mdi-close</v-icon>
        </v-card-title>
        <v-card-text
          style="padding-right:50px; padding-left:50px;overflow: auto; height:400px;"
        >
          <div>
            <vuescroll>
              <v-row>
                <v-col cols="4">
                  <b-form-group
                    id="fieldtype"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="File Type"
                  >
                    <v-autocomplete
                      v-model.trim="lesson.filetype"
                      :items="['PPTX', 'PDF']"
                      dense
                    ></v-autocomplete>
                  </b-form-group>
                </v-col>
                <v-col cols="8">
                  <b-form-group
                    id="fieldTtile"
                    horizontal
                    :label-cols="2"
                    breakpoint="md"
                    label="Sheet Title"
                  >
                    <v-text-field id="ftitle" v-model="lesson.title" />
                  </b-form-group>
                </v-col>
              </v-row>

              <StandardsPicker
                :path="this.path"
                type="Sheet"
                v-if="source == 'edit'"
                :lesson="this.week"
              />

              <b-form-group
                id="fieldlink"
                horizontal
                :label-cols="2"
                breakpoint="md"
                label="Link"
              >
                <b-form-input
                  id="link"
                  placeholder="Activity link"
                  v-model.trim="lesson.link"
                ></b-form-input>
              </b-form-group>

              <!-- Upload Image -->

              <v-col cols="12" md="12">
                <b-form-group
                  id="livefile"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Sheet File"
                  description="Accept Only PDF or PPTX Files Max Size 30 MB"
                >
                  <v-row>
                    <v-col cols="10" md="10">
                      <v-file-input
                        v-model="files"
                        show-size
                        accept=".pdf, .pptx, .doc, .docx"
                        prepend-icon="mdi-file"
                        label="File"
                        @change="previewImage"
                      ></v-file-input>
                      {{ uploadValue.toFixed() + "%" }}
                      <v-progress-linear
                        id="progress"
                        color="light-blue"
                        height="10"
                        :value="uploadValue"
                        max="100"
                        striped
                      ></v-progress-linear>
                    </v-col>
                    <v-col cols="2" md="2">
                      <b-button
                        @click="onUpload"
                        variant="primary"
                        style="font-size:14px"
                        >Upload</b-button
                      >
                    </v-col>
                  </v-row>
                </b-form-group>
              </v-col>

              <!-- End Of Uploading -->
            </vuescroll>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-divider></v-divider>

          <b-button
            variant="danger"
            class="float-right"
            @click="this.$modal.hideAll"
            >Close</b-button
          >
          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="edit"
            v-if="source == 'edit'"
            >Edit Sheet</b-button
          >
          <b-button
            v-show="this.picture || lesson.link"
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="onSubmit"
            v-else
            >Create Sheet</b-button
          >
        </v-card-actions>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import StandardsPicker from "@/components/lms/standards/StandardsPicker";

export default {
  name: "SheetFile",
  props: ["path", "order", "source", "week"],

  components: { StandardsPicker },
  data() {
    return {
      lesson: {},
      standards: [],
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      newfield: {},
      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  methods: {
    edit(evt) {
      evt.preventDefault();
      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        firebase
          .firestore()
          .doc(this.path)
          .set(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Update",
              this.lesson,
              this.$store.state.subject,
              "Sheet"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Video Created", {
              title: "Video Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        if (this.picture != null) {
          this.lesson.link = this.picture;
        }

        this.lesson.type = "show";
        this.lesson.order = Number(this.order) + 1;

        firebase
          .firestore()
          .collection(this.path)
          .add(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.lesson,
              this.$store.state.subject,
              "Sheet"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Activity Created", {
              title: "Activity Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    previewImage(event) {
      // this.uploadValue = 0;
      // this.picture = null;
      this.imageData = event;
      console.log(this.imageData);
    },

    onUpload() {
      if (this.files.size / 1048576 > 30) {
        alert("The File Is Bigger Than 30 MB");
      } else {
        this.picture = null;
        const storageRef = firebase
          .storage()
          .ref(
            "schools/" +
              this.$store.state.school +
              "/activities/" +
              Math.floor(Math.random() * 900000 + 1000000).toString() +
              "." +
              this.lesson.filetype.toLowerCase()
          )
          .put(this.files);

        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              this.picture = url;
            });
          }
        );
      }
    }
  },
  mount() {
    this.show();
  },

  created() {
    if (this.source == "edit") {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.lesson = doc.data();
          } else {
            alert("No such document!");
          }
        });
    }
  }
};
</script>
<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(24, 146, 0, 0.904);
  cursor: pointer;
}
</style>
