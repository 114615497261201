<template>
  <v-row>
    <v-col cols="8">
      <v-card flat outlined>
        <v-card-text><AdmissionList /> </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card
        flat
        style="cursor: pointer; text-align:center;"
        @click="goToPage('SchoolsReport')"
      >
        <v-card-text>
          <img src="@/assets/reports.png" height="80px" />

          <h6 style="padding-top:20px">
            <span style="color:#3096b2;">Admission</span> Reports
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('AdmissionSetting')"
      >
        <v-card-text>
          <img src="@/assets/admissionsetting.png" height="80px" />

          <h6 style="padding-top:20px">
            Admission <span style="color:#3096b2;">Setting</span>
          </h6>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import firebase from "@/Firebase";
import router from "@/router";
import AdmissionList from "@/components/admission/AdmissionList";

export default {
  name: "ManageSubjects",
  components: { AdmissionList },

  data() {
    return {
      errors: [],
      subjects: [],

      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: ""
    };
  },
  created() {},
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
