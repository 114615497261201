<template>
  <div>
    <v-card flat outlined style="margin-bottom:10px" hover>
      <v-card-subtitle>
        <span v-show="this.master" style="color:green">Master Teacher</span>
      </v-card-subtitle>
      <v-card-text>
        <v-row
          ><v-col cols="1">
            <v-avatar
              size="54px"
              color="#121212"
              @click="goToPage('EditProfile')"
            >
              <img
                src="@/assets/avatar.png"
                width="100px"
                v-if="employee.image == null"
              />
              <img :src="employee.image" width="100px" v-else />
            </v-avatar>
          </v-col>
          <v-col cols="11">
            <b style="font-size:16px">
              {{ this.employee.firstname + " " + this.employee.lastname }}</b
            >
            <br />
            {{ this.employee.job }}
            <br />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-divider />
        <v-avatar
          v-for="c in classes"
          :key="c"
          color="red"
          size="24"
          style="margin-right:2px"
        >
          <span class="white--text ">{{ c }}</span>
        </v-avatar>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Teacher-page",

  data() {
    return {
      employee: {}
    };
  },
  props: ["path", "master", "classes"],
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(emp => {
        this.employee = emp.data();
      });
  },
  methods: {}
};
</script>
