<template>
  <v-card>
    <v-card-title>
      <v-icon size="40" style="margin-right:10px"
        >mdi-video-wireless-outline</v-icon
      >
      Edit Live Session
    </v-card-title>
    <v-card-text>
      <v-row
        ><v-col cols="4">
          <v-select
            :items="eclasses"
            v-model.trim="live.sessionclass"
            label="Class"
          ></v-select>
        </v-col>

        <v-col cols="8">
          <v-text-field label="Session Label" v-model.trim="live.title" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="weeks"
            v-model.trim="live.week"
            label="Week"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        ><v-col cols="6">
          <v-btn small color="info" depressed @click="goToLive"
            >Create New Session on Microsoft Teams</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-text-field label="Session Link" v-model.trim="live.link" />
          <v-text-field
            label="PreRecorded Link"
            v-model.trim="live.prerecorded"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <label for="livedate">Session Date</label>
          <b-form-datepicker
            id="livedate"
            v-model="live.date"
            class="mb-2"
          ></b-form-datepicker>
        </v-col>

        <v-col cols="6">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="live.time"
                label="Picker in menu"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="live.time"
              class="mt-4"
              scrollable
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-divider />
      <v-btn color="success" small @click="onSubmit"> Edit Session</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditLive",
  props: ["path", "title"],
  components: {},
  data() {
    return {
      live: {},
      weeks: [],
      eclasses: [],
      subjectPath: this.$store.state.subject,
      gradePath: this.$store.state.gradePath
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.live = snapshot.data();
      });
    firebase
      .firestore()
      .collection(this.subjectPath + "/weeks/")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.weeks = [];
        querySnapshot.forEach(doc => {
          this.weeks.push({
            text: doc.data().title,
            value: doc.ref.path
          });
        });
      });

    firebase
      .firestore()
      .collection(this.gradePath + "/classes")
      .onSnapshot(querySnapshot => {
        this.eclasses = [];
        querySnapshot.forEach(doc => {
          this.eclasses.push({
            value: doc.data().title,
            text: doc.data().title
          });
        });
      });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .update(this.live)
        .then(() => {
          this.$bvToast.toast("Session Updated", {
            title: "Session Updated Successfully",
            variant: "success",
            solid: true
          });

          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
