<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button pill variant="warning" v-bind="attrs" v-on="on">
        <v-icon size="20">mdi-badge-account-outline</v-icon>
        <small style="margin-left: 10px;">Jobs Hub</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon>mdi-badge-account-outline</v-icon>Jobs Hub
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- School -->

        <br />

        <vue-good-table
          :columns="sfields"
          :rows="jobs"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records' }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn btn-danger btn-sm"
                @click="
                  setvideo(
                    props.row.link,
                    props.row.title,
                    props.row.week_title
                  )
                "
              >
                <v-icon>mdi-card-account-details-outline</v-icon>&nbsp; View
              </button>
              &nbsp;
              <button
                class="btn btn-success btn-sm"
                style="padding-left:20"
                @click="importS(props.row)"
              >
                <v-icon>mdi-application-import</v-icon>&nbsp; Import
              </button>
            </span>
            <span v-else-if="props.column.field == 'type'">
              <v-icon color="red">mdi-video-outline</v-icon>
            </span>
          </template>
        </vue-good-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["path", "order"],
  data() {
    return {
      dialog: false,
      activities: [],
      jobs: [],
      grades: [],
      subjects: [],
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      sfields: [
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    firebase
      .firestore()
      .collection("jobs_hub/")
      .onSnapshot(querySnapshot => {
        this.jobs = [];
        querySnapshot.forEach(doc => {
          this.jobs.push(doc.data());
        });
      });
  },
  methods: {
    importS(job) {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/jobs/")
        .add(job)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Import",
            "HR",
            "HR",
            "Job"
          );

          this.$bvToast.toast("Job Imported", {
            title: "Job Imported Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
