<template>
  <v-card flat outlined>
    <v-card-title>
      <span class="headline">
        <v-icon style="margin-right: 10px">mdi-table-arrow-left</v-icon>Import
        Standards
      </span>

      <v-divider></v-divider>

      <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
    </v-card-title>
    <v-card-text>
      <div class="app-container">
        <v-card flat outlined
          ><v-card-text>
            <upload-excel-component
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
            />
            <br />
            <v-progress-linear
              v-if="progress"
              color="light-blue"
              height="20"
              :value="progress"
              striped
            ></v-progress-linear>
            <br />
            <div v-if="tableData">Rows Count: {{ tableData.length }}</div>
            <div v-if="tableData">Rows Imported: {{ this.current }}</div>
            <br />
            <!--  <b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
            <b-button
              variant="success"
              style="color: #ffffff"
              href="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/templates%2Fstandards.xlsx?alt=media&token=d7d89f67-dcbf-4a3c-9c7b-f666d1613935"
              >Download Excel Template</b-button
            >
            <b-button @click="importData" style="margin-left: 20px"
              >Start Importing</b-button
            >
            <!-- <b-button style="margin-left: 20px">Change Egyption</b-button>
         <b-button variant="info" @click="transfer" style="margin-left:20px;"
          >Start Transfer</b-button
        >-->

            <el-table
              :data="tableData"
              border
              highlight-current-row
              style="width: 100%; margin-top: 20px"
              empty-text="There Is No Data Yet"
            >
              <el-table-column
                v-for="item of tableHeader"
                :key="item"
                :prop="item"
                :label="item"
              />
            </el-table>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
    </v-card-actions>
  </v-card>

  <!-- End Activities Hub -->
</template>
<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";
export default {
  params: ["path"],
  components: { UploadExcelComponent },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      dialog: false,
      standard: {},
      domain: {},
      cluster: {},
      activities: [],
      newStandard: {},
      current: 0,
      progress: 0
    };
  },

  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    importData: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        this.standard = {};

        this.standard.code = this.tableData[i]["code"] + "";
        this.standard.parent =
          this.tableData[i]["parent"] == null
            ? ""
            : this.tableData[i]["parent"] + "";
        this.standard.title = this.tableData[i]["title"];
        this.standard.description = this.tableData[i]["description"];
        this.standard.domain_code = this.tableData[i]["domain_code"] + "";
        this.standard.domain = this.tableData[i]["domain_title"];
        this.standard.cluster_code = this.tableData[i]["cluster_code"] + "";
        this.standard.cluster = this.tableData[i]["cluster_title"];

        //! Analysis Data
        this.standard.school = this.$route.params.path.split("/")[1];
        this.standard.branch = this.$route.params.path.split("/")[3];
        this.standard.grade = this.$route.params.path.split("/")[5];
        this.standard.subject = this.$route.params.path.split("/")[7];
        //!-------------------------------------------------------------

        this.domain.code = this.tableData[i]["domain_code"] + "";
        this.domain.title = this.tableData[i]["domain_title"];

        //! Analysis Data
        this.domain.school = this.$route.params.path.split("/")[1];
        this.domain.branch = this.$route.params.path.split("/")[3];
        this.domain.grade = this.$route.params.path.split("/")[5];
        this.domain.subject = this.$route.params.path.split("/")[7];
        //!-------------------------------------------------------------

        this.cluster.code = this.tableData[i]["cluster_code"] + "";
        this.cluster.title = this.tableData[i]["cluster_title"];
        this.cluster.domain = this.tableData[i]["domain_title"];
        this.cluster.domain_code = this.tableData[i]["domain_code"] + "";

        //! Analysis Data
        this.cluster.school = this.$route.params.path.split("/")[1];
        this.cluster.branch = this.$route.params.path.split("/")[3];
        this.cluster.grade = this.$route.params.path.split("/")[5];
        this.cluster.subject = this.$route.params.path.split("/")[7];
        //!-------------------------------------------------------------

        if (this.domain.code != "") {
          //! Add Domain
          firebase
            .firestore()
            .doc(this.$route.params.path + "/domains/" + this.domain.code)

            .set(this.domain)
            .then(() => {
              console.log("Domain Success Added");
            })
            .catch(error => {
              alert("Error adding document: ", error);
            });
        }

        if (this.cluster.code != "") {
          //! Add Cluster
          firebase
            .firestore()
            .doc(this.$route.params.path + "/clusters/" + this.cluster.code)
            .set(this.cluster)
            .then(() => {
              console.log("Cluster Success Added");
            })
            .catch(error => {
              alert("Error adding document: ", error);
            });
        }
        //! Add Standard
        firebase
          .firestore()
          .doc(this.$route.params.path + "/standards/" + this.standard.code)

          .set(this.standard)
          .then(() => {
            console.log("Standard Success Added");
            this.current++;
            this.progress = (this.tableData.length / this.current) * 100;
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  }
};
</script>
