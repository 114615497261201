<template>
  <div class="app-container" style="padding-top:100px">
    <CountIconCard
      title="Students"
      color="blue lighten-2"
      icon="mdi-badge-account-horizontal-outline"
      :path="'/schools/' + this.$store.state.school + '/StudentsData'"
    ></CountIconCard>
    <br />
    <v-card
      ><v-card-text>
        <upload-excel-component :on-success="handleSuccess" />
        <br />
        <v-progress-linear
          v-if="progress"
          color="light-blue"
          height="20"
          :value="progress"
          striped
        ></v-progress-linear>
        <br />
        Last Student Id:
        <span style="color:green; margin-bottom:50px;"> {{ lastStudent }}</span>
        <br />
        Maximum File To Upload 500 Student per Once
        <br />
        Rows Count:{{ tableData.length }}

        <br />
        <span v-if="done" style="color:green; margin-bottom:50px;"
          >Upload Done Successfully</span
        >
        <span v-else style="color:red; margin-bottom:50px;"
          >Waiting the Data to Be Uploaded</span
        >

        <v-divider />
        <!-- <b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
        <b-button
          variant="success"
          style="color:#ffffff"
          href="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/students.xls?alt=media&token=7cdcef41-3f02-47c6-a116-6d4dfbc05ae0"
          >Download Excel Template</b-button
        >
        <b-button @click="importData" style="margin-left:20px;"
          >Start Importing</b-button
        >
        <!-- <v-btn @click="deleteOld" color="red" dark style="margin-left:20px;"
          >Delete Old</v-btn
        > -->

        <el-table
          :data="tableData"
          border
          highlight-current-row
          style="width: 100%;margin-top:20px;"
          empty-text="There Is No Data Yet"
        >
          <el-table-column
            v-for="item of tableHeader"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";
import CountIconCard from "@/components/dna/widgets/CountIconCard";

export default {
  name: "UploadExcel",
  components: { UploadExcelComponent, CountIconCard },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      student: {},
      current: 0,
      progress: 0,
      schoolRef: firebase
        .firestore()
        .doc("schools/" + this.$store.state.school),
      lastStudent: 0,
      done: false
    };
  },
  methods: {
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    beforeUpload() {
      return true;
    },

    deleteOld() {
      console.log("Deleting");

      const batch = firebase.firestore().batch();
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", "National School (New Capital)")
        // .where("grade", "==", "Grade 3")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            batch.delete(doc.ref);
          });

          batch
            .commit()
            .then(() => {
              console.log("Success Deleted");
            })
            .catch(error => {
              console.log(error);
            });
        });
    },

    importData: function() {
      this.done = false;
      if (this.tableData.length <= 500) {
        // Get a new write batch
        const batch = firebase.firestore().batch();

        for (var i = 0; i < this.tableData.length; i++) {
          this.student = {};
          this.student = this.tableData[i];
          this.student.points = 0;

          this.student.school = this.$store.state.school;
          this.student.mother_nationalID =
            this.tableData[i]["mother_nationalID"] == null
              ? ""
              : this.tableData[i]["mother_nationalID"].toString() + "";
          this.student.father_nationalID =
            this.tableData[i]["father_nationalID"] == null
              ? ""
              : this.tableData[i]["father_nationalID"].toString() + "";
          this.student.nationalID =
            this.tableData[i]["nationalID"] == null
              ? ""
              : this.tableData[i]["nationalID"].toString() + "";
          this.student.branch = this.tableData[i]["branch"].trim();
          this.student.grade = this.tableData[i]["grade"].trim();
          this.student.class = this.tableData[i]["class"].trim();

          // this.lastStudent++;
          // this.student.id = this.lastStudent;

          const studentRef = firebase
            .firestore()
            .doc(
              "schools/" +
                this.$store.state.school +
                "/StudentsData/" +
                this.student.id.toString()
            );

          batch.set(studentRef, this.student);
        }

        batch
          .commit()
          .then(() => {
            this.done = true;
            // this.schoolRef
            //   .update({ laststudent: this.lastStudent })
            //   .then(() => {
            //     console.log("Success Uploaded");
            //     this.done = true;
            //   });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        alert("Maximum File To Upload 500 Student per Once");
      }
    }
  },
  created() {
    // this.schoolRef.get().then(snapshot => {
    //   if (!snapshot.exists) return;
    //   let school = snapshot.data();
    //   this.lastStudent = school.laststudent;
    // });
  },
  mounted() {
    // this.importData();
  }
};
</script>
