<template>
  <div>
    <v-card-title>
      <img src="@/assets/books.png" width="50px" style="margin-right:10px" />
      {{ this.$store.state.thisGrade }} - {{ this.$store.state.subjectTitle }}

      <v-spacer />
      <div v-if="this.$store.state.mirror.length > 20">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="error" v-bind="attrs" v-on="on">
              M
            </v-chip>
          </template>
          <span>This Subject Is a Mirror of another Subject</span>
        </v-tooltip>
      </div>
    </v-card-title>
  </div>
</template>
<script>
export default {
  name: "SubjecHeader",
  data() {
    return {
      lessons: 0,
      standards: 0,
      resources: 0
    };
  },
  created() {}
};
</script>
