<template>
  <v-card style="margin-top:20px; background-color:#033b59;" dark flat>
    <!--  Overdue Assignments -->
    <div v-if="this.assignments == ''">
      <div
        v-if="Date.now() > Date.parse(closedate)"
        style="margin-top:20px; background-color:red; padding-left:40px; padding-right:40px;"
      >
        <v-row>
          <span>
            <b>{{ this.weight }}</b>

            <v-spacer />{{ this.opendate }}</span
          >
          <v-spacer />
          <div @click="goToSubmissions()" small light style="cursor: pointer;">
            Go To Assignment
          </div>
        </v-row>
      </div>
      <div
        v-else
        style="margin-top:20px; background-color:#40b1e5; padding-left:40px; padding-right:40px;"
      >
        <v-row>
          <span>
            <b>{{ this.weight }}</b>

            <v-spacer />{{ this.opendate }}</span
          >
          <v-spacer />
          <div @click="goToSubmissions()" small light style="cursor: pointer;">
            Go To Assignment
          </div>
        </v-row>
      </div>
    </div>

    <!--  Delivered Assignments -->
    <div
      v-else
      style="margin-top:20px; background-color:green; padding-left:40px; padding-right:40px;"
    >
      <v-row>
        <span
          ><b> {{ this.weight }}</b
          ><v-spacer />{{ this.opendate }}</span
        >
        <v-spacer />
        <div @click="goToSubmissions()" small light style="cursor: pointer;">
          Go To Assignment
        </div>
      </v-row>
    </div>
    <v-card-subtitle>
      <v-row>
        <v-col cols="10">
          {{ this.title }}
        </v-col>
        <v-col
          cols="2"
          v-if="this.assignments != ''"
          style="background-color:green; color:#ffffff; font-size:16px;"
        >
          {{ this.assignments.right }} /{{ this.assignments.grade }}
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <div v-if="this.assignments != ''">
        <div v-if="this.assignments.feedback">
          <v-card light
            ><v-card-text> {{ this.assignments.feedback }}</v-card-text></v-card
          >
        </div>
      </div>
      <!-- <br />
      Number Of Attempts: {{ this.assignments.length }}-->
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "AssignLesson",
  props: [
    "path",
    "type",
    "title",
    "grade",
    "opendate",
    "closedate",
    "weight",
    "student",
    "assid"
  ],
  data() {
    return {
      assignments: [],
      lessons: [],
      week: ""
    };
  },
  created() {
    firebase
      .firestore()
      .doc(
        "schools/" +
          this.$store.state.school +
          "/StudentsData/" +
          this.student +
          "/assignments/" +
          this.assid
      )
      .onSnapshot(querySnapshot => {
        this.assignments = querySnapshot.data();
        // querySnapshot.forEach(doc => {
        //   this.assignments.push({
        //     type: doc.data().type,
        //     title: doc.data().title,
        //     grade: doc.data().grade,
        //     right: doc.data().right,
        //     opendate: doc.data().opendate,
        //     weight: doc.data().weight,
        //     feedback: doc.data().feedback ?? false
        //   });
        // });
      });
  },
  methods: {
    goToSubmissions() {
      localStorage.setItem("assid", this.assid);
      localStorage.setItem("asspath", this.path);
      localStorage.setItem("assgrade", this.grade);
      localStorage.setItem("asstype", this.type);

      router.push({
        name: "Submissions",
        params: {
          id: this.assid,
          title: this.title,
          path: this.path,
          grade: this.grade,
          assid: this.assid,
          type: this.type
        }
      });
    }
  }
};
</script>
