<template>
  <v-row>
    <v-col cols="4">
      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center; margin-bottom:20px;"
        @click="goToPage()"
      >
        <v-card-text>
          <img src="@/assets/timetable.png" height="80px" />

          <h6 style="padding-top:20px">
            Schedule & <span style="color:#3096b2;">Timetable</span>
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center;"
        @click="goToPage('Leaderboard')"
      >
        <v-card-text>
          <img src="@/assets/leaderboard.png" height="80px" />

          <h6 style="padding-top:20px">
            <span style="color:#3096b2;">Students</span> Leaderboard
          </h6>
        </v-card-text>
      </v-card>

      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('manageAttendance')"
      >
        <v-card-text>
          <img src="@/assets/message.png" height="80px" />

          <h6 style="padding-top:20px">
            Students <span style="color:#3096b2;">Attendance</span>
          </h6>
        </v-card-text>
      </v-card>
      <v-card
        flat
        outlined
        style="cursor: pointer; text-align:center; margin-top:20px;"
        @click="goToPage('Requests')"
      >
        <v-card-text>
          <img src="@/assets/hr.png" height="80px" />

          <h6 style="padding-top:20px">
            Parents <span style="color:#3096b2;">Requests</span>
          </h6>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="8">
      <v-card
        v-for="subject in subjects"
        :key="subject.id"
        outlined
        shaped
        flat
        width="95%"
        tile
        style="margin-bottom:10px; cursor:pointer;"
        @click="
          goToSubject(
            subject.subject,
            subject.classList,
            subject.master,
            subject.control
          )
        "
      >
        <v-card-subtitle>
          <v-row>
            <v-col cols="9">
              <b style="font-size:18px"> {{ subject.subject.split("/")[7] }}</b>
              <v-divider />
              <v-row>
                {{
                  subject.subject.split("/")[3] +
                    " / " +
                    subject.subject.split("/")[5]
                }}
                <v-spacer />
                <div v-for="c in subject.classList" :key="c">
                  <v-avatar
                    color="#fdcd06"
                    tile
                    size="24"
                    style="margin-right:2px"
                  >
                    <span>{{ c }}</span>
                  </v-avatar>
                </div>
              </v-row>
            </v-col>

            <v-col cols="3" style="text-align:center">
              <v-badge
                color="green"
                content="Editor"
                left
                overlap
                v-if="subject.master == true"
              >
                <img src="@/assets/subjects/subject.png" width="60px" />
              </v-badge>

              <img src="@/assets/subjects/not.png" width="70px" v-else />
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase/app";
import router from "@/router";

export default {
  name: "ManageSubjects",
  components: {},

  data() {
    return {
      errors: [],
      subjects: [],

      subjectDate: [],
      thisGrade: "",
      thisSchool: "",
      thisMirror: "",
      gradePath: "",
      subjectid: "",
      lastupdate: ""
    };
  },
  created() {
    firebase
      .firestore()
      .collection("schools/")
      .doc(this.$store.state.school)
      .collection("employees")
      .doc(this.$store.state.userID)
      .collection("assign")
      .orderBy("grade")
      .get()
      .then(querySnapshot => {
        this.subjects = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          this.subjects.push({
            subject: data.subject,
            classList: data.class,
            master: data.master,
            control: data.control
          });
        });
      });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("profiles")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    goToSubject(path, classlist, master, control) {
      if (path != null) {
        firebase
          .firestore()
          .doc(path)
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;

            let subject = snapshot;

            let grade = snapshot.ref.path.split("/")[5];
            let school = snapshot.ref.path.split("/")[3];
            this.subjectid = snapshot.id;
            this.subjectDate = snapshot.ref.path;
            this.thisGrade = grade;
            this.thisSchool = school;
            this.gradePath = snapshot.ref.parent.parent.path;
            this.thisMirror = subject.data().mirror;

            //! Master Teacher
            // if (this.from == "admin") this.$store.commit("master", "true");
            // else

            localStorage.setItem("classList", JSON.stringify(classlist));

            this.$store.commit("master", master);
            this.$store.commit("control", control);
            this.$store.commit("mirror", this.thisMirror);
            this.$store.commit("subjectTitle", subject.data().title);
            this.$store.commit("thisGrade", this.thisGrade);
            this.$store.commit("gradePath", this.gradePath);
            this.$store.commit("thisBranch", this.thisSchool);
            this.$store.commit("originalsubject", path);

            this.$store.commit("subject", path);

            if (this.thisMirror == null || this.thisMirror == "") {
              this.$store.commit("subject", path);

              router.push({
                name: "SubjectDash"
              });
            } else {
              this.$store.commit("subject", this.thisMirror);

              router.push({
                name: "SubjectDash"
              });
            }
          });
      }
    },

    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
