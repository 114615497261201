<template>
  <div>
    <!--Filter by Month-->
    <v-expansion-panels style="margin-top:20px" hover flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="def">
          <div>
            <img
              src="@/assets/lessonsearch.png"
              width="50px"
              style="margin-right:10px;"
            />
          </div>

          Search For Lesson
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            flat
            outlined
            style="background-color:#fdcd06; margin-top:20px;"
          >
            <v-card-text>
              <v-row
                ><v-col cols="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Time Filter"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                      @change="changeFilter"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!--End Filter by Month-->
                <v-col cols="8">
                  <v-autocomplete
                    :items="boards"
                    label="Select Lesson"
                    v-model="selected"
                    @change="search"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-btn @click="clearsearch" color="#fff" block depressed small
                    >Clear Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <br />
    <v-row>
      <v-col cols="12" style="text-align:center">
        <AddWeek
          :path="this.path"
          :order="this.boards.length"
          v-if="master == true"
        ></AddWeek>

        <span class="ma-0">
          <AddRevision
            :path="this.path"
            :order="this.boards.length"
            v-if="master == true"
          ></AddRevision>
        </span>
        <span class="ma-0">
          <AddEnrichment
            :path="this.path"
            :order="this.boards.length"
            v-if="master == true"
          ></AddEnrichment>
        </span>
      </v-col>
    </v-row>

    <v-expansion-panels
      class="mb-6"
      hover
      flat
      popout
      v-if="boards.length > 0"
      style="margin-top:20px"
    >
      <Week
        v-for="week in boards"
        :key="week.id"
        :title="week.title"
        :order="week.order"
        :hidden="week.hidden"
        :breakdown="week.breakdown"
        :date="week.date"
        :revision="week.revision"
        :id="week.key"
        :current="week.current"
        :path="week.path"
        :packs="week.packs"
        :answers="week.answers"
        :summer="week.summer"
        :enrichment="week.enrichment"
        :orientation="week.orientation"
        :section="week.section"
      />
    </v-expansion-panels>

    <v-sheet v-else color="darken-2" class="pa-3" height="800px">
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import AddWeek from "@/components/lms/weeks/AddWeek";
import AddRevision from "@/components/lms/weeks/AddRevision";
import AddEnrichment from "@/components/lms/weeks/AddEnrichment";
import Week from "./Week";

export default {
  name: "WeeksList",
  components: { AddWeek, AddRevision, Week, AddEnrichment },
  props: ["path"],
  data() {
    return {
      fields: [
        {
          field: "order",
          label: "Order",
          sortable: true,
          class: "text-left"
        },
        {
          field: "section",
          label: "Section",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        {
          field: "term",
          label: "Term",
          sortable: true
          // class: "text-left"
        },
        {
          field: "flipped",
          label: "Flipped",
          class: "text-center"
        },
        {
          field: "lesson",
          label: "Lesson",
          class: "text-center"
        },
        {
          field: "bank",
          label: "Quesions",
          class: "text-center"
        },
        {
          field: "assignments",
          label: "Assignments",
          class: "text-center"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "180px"
        }
      ],
      loading: false,

      date: new Date().toISOString().substr(0, 7),
      mirror: this.$store.state.mirror,
      master: this.$store.state.master,
      menu: false,
      modal: false,
      boards: [],
      errors: [],
      selected: "",
      subject: "",

      subjectTitle: this.title
    };
  },
  created() {
    if (
      this.$store.state.mirror == null ||
      this.$store.state.mirror == "" ||
      this.$store.state.mirror == "undefined"
    ) {
      this.subject = this.path;
    } else {
      this.subject = this.$store.state.mirror;
    }
    firebase
      .firestore()
      .collection(this.subject + "/weeks")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.boards = [];
        querySnapshot.forEach(doc => {
          const week = doc.data();
          this.boards.push({
            key: doc.id,
            path: doc.ref.path,
            section: week.section,
            title: week.title,
            term: week.term,
            order: week.order,
            date: week.date,
            standard: week.standard,
            text: week.title,
            revision: week.revision,
            hidden: week.hidden,
            noblended: week.noblended,
            breakdown: week.breakdown,
            current: week.current,
            value: week.title,
            packs: week.packs,
            summer: week.summer,
            enrichment: week.enrichment,
            orientation: week.orientation,
            answers: week.answers
          });
        });
      });
  },
  methods: {
    changeFilter() {
      var mon;
      mon = new Date(this.date).getMonth() + 1;
      //this.$refs.menu.save(new Date(this.date).getMonth() + 1);
      this.$refs.menu.save(this.date);
      this.menu = false;

      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.boards = [];
          querySnapshot.forEach(doc => {
            if (new Date(doc.data().date).getMonth() + 1 == mon)
              this.boards.push({
                key: doc.id,
                path: doc.ref.path,
                section: doc.data().section,
                title: doc.data().title,
                term: doc.data().term,
                order: doc.data().order,
                date: doc.data().date,
                standard: doc.data().standard,
                hidden: doc.data().hidden,
                text: doc.data().title,
                value: doc.data().title,
                revision: doc.data().revision
              });
          });
        });
    },
    search() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.boards = [];
          querySnapshot.forEach(doc => {
            if (doc.data().title == this.selected)
              this.boards.push({
                key: doc.id,
                path: doc.ref.path,
                section: doc.data().section,
                title: doc.data().title,
                term: doc.data().term,
                order: doc.data().order,
                date: doc.data().date,
                standard: doc.data().standard,
                hidden: doc.data().hidden,
                text: doc.data().title,
                breakdown: doc.data().breakdown,
                value: doc.data().title,
                revision: doc.data().revision
              });
          });
        });
    },
    clearsearch() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.boards = [];
          querySnapshot.forEach(doc => {
            this.boards.push({
              key: doc.id,
              path: doc.ref.path,
              section: doc.data().section,
              title: doc.data().title,
              term: doc.data().term,
              order: doc.data().order,
              date: doc.data().date,
              standard: doc.data().standard,
              hidden: doc.data().hidden,
              text: doc.data().title,
              breakdown: doc.data().breakdown,
              value: doc.data().title,
              revision: doc.data().revision
            });
          });
        });
    }
  }
};
</script>

<style scoped>
.def {
  border-style: solid;
  border-color: #eee;
}
.hid {
  border-style: dotted;
  border-color: #d0d0d0;
}
</style>
