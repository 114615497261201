<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          ({{ this.$route.params.parent }}) Distribute</v-card-title
        >

        <v-card-text>
          <b-card-group deck>
            <CountCardStatic
              title="Questions"
              color="success"
              :count="this.questions"
            ></CountCardStatic>

            <CountCardStatic
              title="Lessons"
              color="primary"
              :count="this.lessons"
            ></CountCardStatic>

            <CountCardStatic
              title="Videos"
              color="danger"
              :count="this.videos"
            ></CountCardStatic>
            <CountCardStatic
              title="Sheets"
              color="info"
              :count="this.sheets"
            ></CountCardStatic>
            <CountCardStatic
              title="Lives"
              color="warning"
              :count="this.lives"
            ></CountCardStatic>
          </b-card-group>

          <!-- <vue-good-table
            style="margin-top: 20px"
            :columns="fields"
            :rows="standards"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <v-btn
                  style="margin-left: 5px"
                  small
                  color="info"
                  dark
                  depressed
                  @click="goToEdit(props.row.title, props.row.path)"
                >
                  <v-icon color="white" size="20">mdi-playlist-edit</v-icon>
                </v-btn>
                <v-btn
                  style="margin-left: 5px"
                  small
                  color="red"
                  dark
                  depressed
                  @click="deletestandard(props.row.key, props.row.code)"
                >
                  <v-icon color="white" size="20">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </template>
          </vue-good-table> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import CountCardStatic from "@/components/dna/widgets/CountCardStatic";

export default {
  name: "Standards-page",
  components: { CountCardStatic },
  props: ["parent"],
  data() {
    return {
      parentid: localStorage.getItem("parent"),
      isparent: localStorage.getItem("isparent"),
      newStandard: {},
      standards: [],
      dialog: false,
      questions: 0,
      lessons: 0,
      videos: 0,
      lives: 0,
      sheets: 0,
      ref: "",
      fields: [
        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left",
          width: "120px"
        }
      ]
    };
  },
  created() {
    this.isparent = localStorage.getItem("isparent");
    if (this.isparent == "true") {
      this.ref = firebase
        .firestore()
        .collection(this.$store.state.subject + "/slink")
        .where("parent", "==", this.$route.params.parent);
    } else {
      this.ref = firebase
        .firestore()
        .collection(this.$store.state.subject + "/slink")
        .where("standard", "==", this.$route.params.parent);
    }

    this.ref.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          key: doc.id,
          path: doc.ref.path,
          type: doc.data().type
        });
        if (doc.data().type == "Lesson") {
          this.lessons++;
        } else if (doc.data().type == "Question") {
          this.questions++;
        } else if (doc.data().type == "Video") {
          this.videos++;
        } else if (doc.data().type == "Live") {
          this.lives++;
        } else if (doc.data().type == "Sheet") {
          this.sheets++;
        }
      });
    });
  },
  methods: {
    goToEdit(title, path) {
      router.push({
        name: "EditStandard",
        params: { title: title, path: path }
      });
    },

    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$store.state.subject + "/standards")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Standard Deleted", {
                  title: "Standard Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
