<template>
  <v-navigation-drawer
    v-model="drawer"
    permanent
    app
    color="#eee"
    floating
    rounded
    width="88"
    style="margin-left:20px; margin-top:40px; "
  >
    <v-list dense rounded style="margin-left:-10px">
      <!-- End Fleet Menu -->

      <!-- Fleet Menu -->
      <!-- v-if="this.rules.accounting" -->
      <div>
        <v-list-item @click="goToPage('Home')">
          <v-list-item-action>
            <img src="@/assets/home.png" width="30px" />
          </v-list-item-action>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </div>

      <div v-if="this.rules.accounting">
        <v-list-item @click="goToPage('Home')">
          <v-list-item-action>
            <img src="@/assets/email.png" width="30px" />
          </v-list-item-action>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </div>
      <div v-if="this.rules.accounting">
        <v-list-item @click="goToPage('Home')">
          <v-list-item-action>
            <img src="@/assets/calendar2.png" width="30px" />
          </v-list-item-action>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </div>

      <div v-if="this.rules.setting">
        <v-list-item @click="goToPage('Setting')">
          <v-list-item-action>
            <img src="@/assets/settings.png" width="30px" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Setting</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </div>

      <v-list-item @click="signOut()">
        <v-list-item-action>
          <img src="@/assets/exit.png" width="30px" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import router from "@/router";
import firebase from "@/Firebase";

export default {
  name: "Page-page",
  components: {},
  props: {
    source: String
  },

  data: () => ({
    domain: "",
    login: "",
    onLine: null,
    onlineSlot: "online",
    offlineSlot: "offline",
    dialog: false,
    drawer: null,
    school: localStorage.getItem("schoolName"),
    rules: ""
  }),
  methods: {
    amIOnline(e) {
      this.onLine = e;
    },
    goToPage(page) {
      router.push({
        name: page
      });
    },
    goToJob(job) {
      router.push({
        name: "JobDescription",
        params: { job: job }
      });
    },

    signOut() {
      localStorage.setItem("loggedIn", false);
      this.$store.state.loggedIn = false;
      firebase
        .auth()
        .signOut()
        .then(() => {
         window.location.href = "/school/"+this.$store.state.school;
        });
    }
  },
  created() {
    this.rules = this.$store.state.rules;
    this.login = localStorage.getItem("loggedIn");
    this.domain = window.location.host;
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Lalezar"); */
#inspire {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  background-color: #eee;
}

.card-text {
  padding: 20px;
}

.cbody {
  padding: 40px;
  padding-top: 100px;
}

.grad {
  position: fixed;
  background-image: linear-gradient(to right, #0e1f36, #345784);
}

.offline {
  color: red;
  font-size: small;
}
.online {
  visibility: hidden;
}
</style>
