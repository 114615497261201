<template>
  <v-app id="inspire">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  props: {
    source: String
  },

  data: () => ({}),
  methods: {},
  created() {}
};
</script>
