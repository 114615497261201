<template>
  <v-app id="inspire">
    <vue-confirm-dialog></vue-confirm-dialog>

    <!-- Sidebar Begin -->
    <Drawer />
    <!-- End Sidebar -->

    <v-content style="background-color:#121212; ">
      <!-- Topbar Begin -->

      <v-app-bar flat im class="grad" height="380px" src="@/assets/home.jpg">
      </v-app-bar>

      <!-- End Topbar -->

      <div class="cbody">
        <v-card>
          <DashHeader />

          <v-card-text style="margin-top:-40px;">
            <v-row>
              <v-col cols="12">
                <DashSections />
                <router-view></router-view>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-content>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from "@/components/theme/Footer";
import Drawer from "@/components/Drawer";
import router from "@/router";
import firebase from "@/Firebase";
import DashSections from "@/components/dna/widgets/DashSections";
import DashHeader from "@/components/dna/widgets/DashHeader";

export default {
  name: "Page-page",
  components: {
    Footer,
    Drawer,
    DashSections,
    DashHeader
  },
  props: {
    source: String
  },

  data: () => ({
    domain: "",
    login: "",
    onLine: null,
    onlineSlot: "online",
    offlineSlot: "offline",
    dialog: false,
    drawer: null,
    school: localStorage.getItem("schoolName"),
    rules: ""
  }),
  methods: {
    amIOnline(e) {
      this.onLine = e;
    },
    goToPage(page) {
      router.push({
        name: page
      });
    },
    goToJob(job) {
      router.push({
        name: "JobDescription",
        params: { job: job }
      });
    },

    signOut() {
      localStorage.setItem("loggedIn", false);
      this.$store.state.loggedIn = false;

      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.href = "/login/" + this.$store.state.school;
        });
    }
  },
  created() {
    this.rules = this.$store.state.rules;
    this.login = localStorage.getItem("loggedIn");
    this.domain = window.location.host;
  }
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Lalezar"); */
#inspire {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  background-color: #eee;
}

.card-text {
  padding: 20px;
}

.cbody {
  padding: 40px;
  padding-top: 100px;
}

.grad {
  position: fixed;
  background-image: linear-gradient(to right, #0e1f36, #345784);
}

.offline {
  color: red;
  font-size: small;
}
.online {
  visibility: hidden;
}
</style>
