<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn  btn-sm"
        dark
        x-small
        color="orange"
        depressed
        v-bind="attrs"
        block
        v-on="on"
      >
        <v-icon size="15">mdi-calendar-text-outline</v-icon>Answers
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-calendar-text-outline</v-icon
          >Answers
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3"
            ><v-text-field label="Grade" messages="/20" v-model="grades"
          /></v-col>
          <v-col cols="9"
            ><v-text-field label="Feedback" v-model="feedbacks"
          /></v-col>
        </v-row>
        <v-btn
          small
          color="success"
          depressed
          @click="savegrade(grades, feedbacks)"
          >Save Question Grade</v-btn
        >
        <div v-for="ans in answers" :key="ans.key" style="margin-bottom:10px">
          <v-card flat outlined>
            <v-card-text>
              <Question :path="ans.question" />
              {{ ans.answer }}
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
import Question from "./Question";

export default {
  name: "Answers-page",
  props: ["path", "type", "week", "student", "weekid", "studentid"],
  components: { Question },
  data() {
    return {
      answers: [],
      dialog: false,
      grades: 0,
      feedbacks: ""
    };
  },
  created() {
    this.answers = [];
    firebase
      .firestore()
      .doc(
        this.$store.state.originalsubject +
          "/inclass/" +
          this.studentid +
          this.week.split("/")[9]
      )
      .onSnapshot(querySnapshot => {
        this.answers = querySnapshot.data()["answers"];
      });
  },
  methods: {
    savegrade(grade, feedback) {
      firebase
        .firestore()
        .doc(
          this.$store.state.originalsubject +
            "/inclass/" +
            this.studentid +
            this.week.split("/")[9]
        )
        .update({ grade: parseInt(grade), feedback: feedback })
        .then(() => {
          this.$bvToast.toast("Grade Updated", {
            title: "Grade Updated Successfully",
            variant: "success",
            solid: true
          });
          this.link = null;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    clearNote() {
      firebase
        .firestore()
        .doc(this.path)
        .update({ note: null })
        .then(() => {
          this.$bvToast.toast("Note Updated", {
            title: "Note Updated Successfully",
            variant: "success",
            solid: true
          });
          this.link = null;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
