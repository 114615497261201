<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        @click="help"
        v-bind="attrs"
        v-on="on"
        tag="Best Practice"
        size="12"
        style="background-color:#006e47; color:#fff; padding:5px; border-radius: 25px;"
        >BP</v-icon
      >
    </template>
    <span>Best Practice</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["text", "image", "title"],
  methods: {
    help() {
      this.$swal.fire({
        title: "",
        text: this.text,
        imageUrl: this.image,
        imageAlt: this.title
      });
    }
  }
};
</script>
