<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Parent</b-button
      >

      <b-collapse id="accordion-1" class="mt-2">
        <b-card>
          <b-form @submit.stop.prevent="onSubmit">
            <v-col cols="12">
              <v-text-field
                label="First Name"
                v-model.trim="firstname"
                required
              ></v-text-field>

              <v-text-field
                label="Last Name"
                v-model.trim="parent.lastname"
                required
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model.trim="parent.email"
                required
              ></v-text-field>
              <v-text-field
                label="Username"
                v-model.trim="parent.username"
                required
              ></v-text-field>

              <v-text-field
                label="Password"
                v-model.trim="parent.password"
                required
              ></v-text-field>
            </v-col>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add Parent</b-button>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddParent",
  props: ["path"],
  data() {
    return {
      parent: {},
      schools: [],
      grades: [],
      username: "",
      password: "",
      branch: "",
      grade: "",
      firstname: ""
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      // this.parent.username = this.parent.username.toLowerCase().trim();
      // this.parent.password = this.parent.password.trim();
      this.parent.email = this.parent.email.toLowerCase().trim();
      this.parent.type = "parent";
      this.parent.school = this.$store.state.school;
      this.ref
        .doc(this.parent.username)
        .set(this.parent)
        .then(() => {
          this.$bvToast.toast("Parent Created", {
            title: "Parent Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },
    onSchoolChange() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.branch +
            "/grades"
        )
        .orderBy("title")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },
    generate() {
      this.$confirm({
        message: "Are you sure You want to regenerate parents accounts?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(
                "schools/" + this.$store.state.school + "/StudentsData"
              )
              .where("grade", "==", "Grade 4")
              .get()
              .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                  //! Create Father Account

                  this.parent.name =
                    doc.data().middlename + " " + doc.data().lastname;
                  this.parent.username =
                    doc
                      .data()
                      .firstname.toLowerCase()
                      .trim() + doc.data().father_nationalID.substr(7, 8);

                  this.parent.password = String(
                    doc.data().father_nationalID.substr(4, 10)
                  );
                  this.parent.relation = "Father";
                  this.parent.nationalID = doc.data().father_nationalID ?? "";
                  this.parent.email = doc.data().parent ?? "";
                  this.parent.mobile = doc.data().father_mobile ?? "";

                  firebase
                    .firestore()
                    .collection(
                      "schools/" + this.$store.state.school + "/parents"
                    )
                    .doc(doc.data().father_nationalID ?? "11111")
                    .set(this.parent)
                    .then(() => {
                      console.log("Parent successfully added");
                    })
                    .catch(error => {
                      console.log(error);
                    });

                  //! Create Mother Account

                  this.parent.name =
                    doc.data().mother_name == null ||
                    doc.data().mother_name == ""
                      ? "Mother Name"
                      : doc.data().mother_name;

                  this.parent.username =
                    doc
                      .data()
                      .firstname.toLowerCase()
                      .trim() + doc.data().mother_nationalID.substr(7, 8);

                  this.parent.nationalID = doc.data().mother_nationalID ?? "";

                  this.parent.password = String(
                    doc.data().mother_nationalID.substr(4, 10)
                  );
                  this.parent.relation = "Mother";

                  this.parent.email = doc.data().mother_email ?? "";
                  this.parent.mobile = doc.data().mother_mobile ?? "";

                  firebase
                    .firestore()
                    .collection(
                      "schools/" + this.$store.state.school + "/parents"
                    )
                    .doc(doc.data().mother_nationalID ?? "11111")
                    .set(this.parent)
                    .then(() => {
                      console.log("Parent successfully added");
                    })
                    .catch(error => {
                      console.log(error);
                    });
                });
              });
          }
        }
      });
    }
  },

  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/branches/")
      .onSnapshot(querySnapshot => {
        this.schools = [];
        querySnapshot.forEach(doc => {
          this.schools.push({
            text: doc.data().title,
            value: doc.id
          });
        });
      });
  },
  watch: {
    firstname(newValue, oldValue) {
      this.username =
        newValue.toLowerCase().trim() +
        Math.floor(Math.random() * Math.floor(900000));
      this.password = Math.floor(Math.random() * 900000 + 1000000);

      this.parent.firstname = newValue;
      this.parent.username = this.username;
      this.parent.password = this.password;

      console.log("Old Value: ", oldValue);
    }
  }
};
</script>
