<template>
  <div
    class="login-access"
    style="flex-direction: column; box-sizing: border-box; display: flex;  align-items: center;"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card">
            <div class="card-body">
              <div style="text-align:center">
                <img :src="this.$route.params.logo" width="120px" />
              </div>
              <br />
              <br />
              <div style="text-align:left">
                <h6>Dear Parent,</h6>

                Thank you for your to Submit this Form

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoConfirmation"
};
</script>
