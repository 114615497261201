<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-form @submit.stop.prevent="onSubmit">
          <!-- Form Body -->
          <v-col cols="6" md="4">
            <v-text-field
              label="Class Name"
              readonly
              v-model.trim="gclass.title"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              label="Class Room"
              v-model.trim="gclass.room"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              label="Class Capacity"
              v-model.trim="gclass.capacity"
            ></v-text-field>
          </v-col>
          <!-- Upload Image -->
          <img :src="gclass.timetable" />

          <v-col cols="12" md="12">
            <div>
              <p>Timetable:</p>
              <b-form-file
                type="file"
                @change="previewImage"
                accept=".jpg, .jpeg, .png"
              />
            </div>
            <div>
              <p>
                <small>
                  Progress:
                  {{ uploadValue.toFixed() + "%" }}
                </small>
                <v-progress-linear
                  id="progress"
                  color="light-blue"
                  height="10"
                  :value="uploadValue"
                  max="100"
                  striped
                ></v-progress-linear>
              </p>
            </div>
            <div v-if="this.timetable != null">
              Upload Completed
            </div>
            <div v-if="imageData != null">
              <b-button @click="onUpload" variant="success">Upload</b-button>
            </div>
          </v-col>

          <!-- End Of Uploading -->

          <!-- End Form Body -->

          <!-- Submit Form -->
          <b-card-footer>
            <b-button type="submit" variant="primary">Edit Class</b-button>
          </b-card-footer>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditClass",
  params: ["path"],
  data() {
    return {
      gclass: {},
      schools: {},
      key: this.$route.params.id,
      //Upload Weekly Plan

      imageData: null,
      timetable: null,
      uploadValue: 0,

      classRef: firebase.firestore().doc(this.$route.params.path)
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      if (this.timetable != null) {
        this.gclass.timetable = this.timetable;
      }

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .set(this.gclass)
        .then(() => {
          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.timetable = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(
          "/timetables/" +
            Math.floor(Math.random() * 900000 + 1000000).toString() +
            this.imageData.name
        )
        .put(this.imageData);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.timetable = url;
          });
        }
      );
    }
  },
  created() {
    this.classRef.get().then(doc => {
      if (doc.exists) {
        this.gclass = doc.data();
      } else {
        alert("No such document!");
      }
    });
  }
};
</script>
