import { render, staticRenderFns } from "./AddEmployee.vue?vue&type=template&id=1397f36c&"
import script from "./AddEmployee.vue?vue&type=script&lang=js&"
export * from "./AddEmployee.vue?vue&type=script&lang=js&"
import style0 from "./AddEmployee.vue?vue&type=style&index=0&id=1397f36c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports