<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Subject</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <b-jumbotron>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Lawyer Name -->
            <b-form-group
              id="fieldsetname"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Title"
            >
              <b-form-input
                id="name"
                placeholder="Subject title"
                v-model.trim="subject.title"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="fieldsicon"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Icon"
            >
              <b-form-select
                id="icon"
                placeholder="Icon"
                v-model.trim="subject.icon"
                :options="icons"
              ></b-form-select>
            </b-form-group>

            <!-- Submit Form -->
            <b-button type="submit" variant="primary">Add Subject</b-button>
          </b-form>
        </b-jumbotron>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddSubject",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection(this.$route.params.path + "/subjects/"),
      subject: {},
      selected: null,
      icons: [
        { value: "arabic.png", text: "Arabic" },
        { value: "english.png", text: "English" },
        { value: "math.png", text: "Math" },
        { value: "franch.png", text: "Franch" },
        { value: "social.png", text: "Social" },
        { value: "science.png", text: "Science" },
        { value: "physics.png", text: "Physics" },
        { value: "psychology.png", text: "Psychology" },
        { value: "philosophy.png", text: "Philosophy" },
        { value: "history.png", text: "History" },
        { value: "geometry.png", text: "Geometry" },
        { value: "calculus.png", text: "Calculus" },
        { value: "biology.png", text: "Biology" },
        { value: "logic.png", text: "Logic" },
        { value: "solid.png", text: "Solid" },
        { value: "statistics.png", text: "Statistics" }
      ]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.ref
        .doc(this.subject.title)
        .set(this.subject)
        .then(() => {
          this.subject.title = "";
          this.subject.icon = "";
          this.$bvToast.toast("Subject Created", {
            title: "Subject Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
