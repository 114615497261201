<template>
  <v-card class="mx-auto" dark max-width="90%" style="margin-bottom:10px">
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="questionorder" />

          <v-btn
            x-small
            color="success"
            block
            depressed
            @click="SaveOrder(questionorder)"
            >Change Order</v-btn
          >
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="pageorder" />

          <v-btn
            x-small
            color="success"
            block
            depressed
            @click="SavePage(pageorder)"
            >Change Page</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1">
          <h1>{{ this.index + 1 }}</h1>
        </v-col>
        <v-col cols="11">
          <div class="kamal" v-html="this.qustionDate.title"></div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
export default {
  name: "FixedQuestion",
  props: ["path", "index"],
  data() {
    return {
      records: [],
      qustionDate: [],
      thisGrade: "",
      thisSchool: "",
      gradePath: "",
      subjectid: "",
      lastupdate: "",
      lessons: 0,
      questionorder: 0,
      pageorder: 0
    };
  },
  created() {
    if (this.path != null) {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;
          let question = snapshot.data();
          this.questionorder = question.order;
          this.pageorder = question.page;

          this.subjectid = snapshot.id;
          this.qustionDate = question;
        });
    }
  },
  methods: {
    deleteboard() {
      this.$confirm({
        message:
          "Are you sure You want to Delete " + this.qustionDate.title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.path + "/subjects/")
              .doc(this.subjectid)
              .delete()
              .then(() => {
                this.$bvToast.toast("Subject Deleted", {
                  title: "Subject Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    goToSubject(id, title) {
      this.$store.state.subject = id;
      this.$store.state.subjectName = this.subjectDate;
      this.$store.state.thisGrade = this.thisGrade;
      this.$store.state.thisBranch = this.thisSchool;
      this.$store.state.grade = this.gradePath;

      router.push({ name: "SubjectDash", params: { path: id, title: title } });
    },
    SaveOrder(o) {
      firebase
        .firestore()
        .doc(this.path)
        .update({ order: Number(o) })
        .then(() => {
          this.$swal.queue([
            {
              icon: "success",
              title: "Order Saved",
              html: "Order Saved Successfully",
              timer: 2000
            }
          ]);
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },
    SavePage(o) {
      firebase
        .firestore()
        .doc(this.path)
        .update({ page: Number(o) })
        .then(() => {
          this.$swal.queue([
            {
              icon: "success",
              title: "Page Saved",
              html: "Page Saved Successfully",
              timer: 2000
            }
          ]);
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  },
  computed: {
    subjectIcon() {
      return {
        ...this.subjectDate,
        icon:
          this.subjectDate.icon &&
          require("@/assets/subjects/" + this.subjectDate.icon)
      };
    }
  }
};
</script>
