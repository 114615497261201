<template>
  <div
    class="login-access"
    style="flex-direction: column; box-sizing: border-box; display: flex;  align-items: center;"
  >
    <v-container>
      <v-card>
        <v-card-title class="justify-center">
          <img :src="this.logo" width="100px" />
        </v-card-title>
        <v-form
          @submit.stop.prevent="onSubmit"
          ref="form"
          data-vv-scope="admission"
          v-model="valid"
        >
          <v-container>
            <v-alert border="bottom" color="blue darken-1" dark>
              <h6>Student Information {{ this.date }}</h6>
            </v-alert>

            <v-row center-block>
              <v-col cols="12" md="4">
                <v-text-field
                  label="First name"
                  v-model.trim="student.firstname"
                  messages="الاسم الاول"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  label="Middle name"
                  v-model.trim="student.middlename"
                  messages="الاسم الاوسط"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  label="Last name"
                  v-model.trim="student.lastname"
                  messages="الاسم الاخير"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Student National Id (14 Numbers)"
                  messages="الرقم القومي للطالب من واقع شهادة الميلاد"
                  v-model.trim="student.nationalID"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- Schools Level -->
              <v-col class="d-flex" cols="12" sm="4">
                <v-select
                  :items="schools"
                  v-model="student.branch"
                  label="School"
                  messages="المدرسة"
                  @change="onSchoolChange()"
                  required
                ></v-select>
              </v-col>
              <!-- Grade Level -->
              <v-col class="d-flex" cols="12" sm="4">
                <v-select
                  :items="grades"
                  v-model="student.grade"
                  label="Year Group"
                  messages="الصف الدراسي"
                  @change="onGradeChange()"
                  required
                ></v-select>
              </v-col>
              <!-- Class Level -->
              <v-col class="d-flex" cols="12" sm="4">
                <v-select
                  :items="gclasses"
                  v-model="student.class"
                  label="Class"
                  messages="الفصل"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-select
                  :items="houses"
                  v-model="student.house"
                  label="House"
                  messages="المنزل"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <!-- Gender -->
            <v-row>
              <v-col class="d-flex" cols="6" sm="6">
                <v-select
                  :items="['Male', 'Female']"
                  v-model.trim="student.gender"
                  label="Gender"
                  messages="النوع"
                  required
                ></v-select>
              </v-col>

              <!--   <v-col class="d-flex" cols="6" sm="6">
                <v-select
                  :items="['English (French School)', 'French', 'German']"
                  v-model.trim="student.secondlanguage"
                  label="Second Language"
                  messages="اللغه الثانية"
                  
                  required
                ></v-select>
              </v-col>-->
              <v-col class="d-flex" cols="6" sm="6">
                <v-select
                  :items="['Muslim', 'Christian', 'Other']"
                  v-model.trim="student.religion"
                  label="Religion"
                  messages="الديانة"
                  required
                ></v-select>
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  label="Citizenship"
                  messages="الجنسية"
                  v-model.trim="student.citizenship"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <label for="example-datepicker">Date Of Birth</label>
                <label for="example-datepicker">تاريخ الميلاد</label>
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="student.date_of_birth"
                  class="mb-2"
                  required
                ></b-form-datepicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Address"
                  messages="العنوان"
                  v-model.trim="student.address"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Home Phone"
                  messages="تليفون المنزل"
                  v-model.trim="student.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Mobile"
                  messages="رقم التليفون المحمول"
                  v-model.trim="student.mobile"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider />

            <br />
            <v-alert border="bottom" color="green darken-1" dark>
              <h6>Father's Information</h6>
            </v-alert>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Father's Academic Degree"
                  v-model.trim="student.father_degree"
                  messages="المؤهل الدراسي للاب"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Father's Occupation"
                  v-model.trim="student.father_occupation"
                  messages="وظيفة الاب"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Father's Company Business"
                  v-model.trim="student.father_company"
                  messages="مجال العمل"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Father's Email"
                  v-model.trim="student.parent"
                  messages="البريد الالكتروني - الاب"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Father's Mobile"
                  v-model.trim="student.father_mobile"
                  messages="رقم المحمول - الاب"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Father's National Id (14 Numbers)"
                  messages="الرقم القومي للاب"
                  v-model.trim="student.father_nationalID"
                  required
                ></v-text-field> </v-col
            ></v-row>

            <!-- End Father Information -->
            <br />
            <!-- Father Information -->

            <v-alert border="bottom" color="green darken-1" dark>
              <h6>Mother's Information</h6>
            </v-alert>

            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Mother's Name"
                  v-model.trim="student.mother_name"
                  messages="اسم الام"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Mother's Academic Degree"
                  v-model.trim="student.mother_degree"
                  messages="المؤهل الدراسي للام"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Mother's Occupation"
                  v-model.trim="student.mother_occupation"
                  messages="وظيفة الام"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Mother's Company Business"
                  v-model.trim="student.mother_company"
                  messages="مجال عمل الام"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Mother's Email"
                  v-model.trim="student.mother_email"
                  messages="البريد الالكتروني - الام"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Mother's Mobile"
                  v-model.trim="student.mother_mobile"
                  messages="رقم المحمول - الام"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Mother's National Id (14 Numbers)"
                  messages="الرقم القومي للام"
                  v-model.trim="student.mother_nationalID"
                  required
                ></v-text-field> </v-col
            ></v-row>
            <br />

            <!-- Father Information -->

            <v-alert border="bottom" color="orange darken-1" dark>
              <h6>Emergency Contact</h6>
            </v-alert>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Emergency contact person"
                  messages="شخص الاتصال في حالات الطوارئ"
                  v-model.trim="student.emergencyname"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Relation"
                  messages="الصلة"
                  v-model.trim="student.emergencyrelation"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Emergency phone number"
                  messages="رقم اتصال الطوارئ"
                  v-model.trim="student.emergencyphone"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <br />
            <v-divider />
            <br />

            <div style="color:red">
              * Please make sure to enter the correct ID numbers as this may
              affect the fees plan for your children
            </div>
            <div style="color:red">
              * برجاء التاكد من ادخال الرقم القومي بشكل صحيح اذ قد يؤثر ذلك علي
              خطة المصروفات الخاصة بآبنائكم
            </div>
            <br />
            <v-btn type="validate" color="primary">Apply Now</v-btn>
          </v-container>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "OnlineAdmission",

  data() {
    return {
      havesibling: false,
      parentsEmployee: false,
      valid: true,
      nameRules: [v => !!v || "Field is required"],
      e1: 1,
      date: "",
      time: "",
      logo: "",
      SchoolName: "",
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: "",
      disabledDates: { weekdays: [1, 7] },
      student: {},
      grades: {},
      houses: {},
      schools: {},
      gclasses: {},
      gradeSetting: {},
      timeTo: "",
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$route.query.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$route.query.school + "/branches/"),
      housesRef: firebase
        .firestore()
        .collection("schools/" + this.$route.query.school + "/houses/"),
      schoolRef: firebase.firestore().doc("schools/" + this.$route.query.school)
    };
  },
  methods: {
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      //Return `true` if the date should be disabled

      return (
        weekday === 4 ||
        weekday === 5 ||
        weekday === 6 ||
        day === 31 ||
        day === 7
      );
    },
    onSubmit(evt) {
      if (this.$refs.form.validate() == true) {
        evt.preventDefault();

        this.student.type = "student";
        this.student.application_date = new Date().toLocaleString();

        firebase
          .firestore()
          .collection("schools/" + this.$route.query.school + "/infos/")
          .add(this.student)
          .then(() => {
            router.push({
              name: "InfoConfirmation"
            });

            //   router.back();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSchoolChange() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch
        )
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;
          let school = snapshot.data();

          //this.minDate = school.startadmission;
          this.maxDate = school.endadmission;
        });

      this.gclasses = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch +
            "/grades"
        )
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },
    onGradeChange() {
      this.gradeSetting = {};
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade
        )
        .get()
        .then(doc => {
          if (doc.exists) {
            this.gradeSetting = doc.data();
          } else {
            alert("No such document!");
          }
        });

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade +
            "/classes"
        )
        .onSnapshot(querySnapshot => {
          this.gclasses = [];
          querySnapshot.forEach(doc => {
            this.gclasses.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },

    validate() {
      if (this.$refs.form.validate() == true) {
        this.$refs.form.submi;
      }
    }
  },
  created() {
    this.schoolRef.get().then(snapshot => {
      if (!snapshot.exists) return;
      let school = snapshot.data();

      this.logo = school.logo;
      this.SchoolName = school.school_name;
    });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });

    this.housesRef.onSnapshot(querySnapshot => {
      this.houses = [];
      querySnapshot.forEach(doc => {
        this.houses.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });
  }
};
</script>
