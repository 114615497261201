<template>
  <v-row>
    <v-col cols="12">
      <b-form @submit.stop.prevent="onSubmit">
        <v-card>
          <v-card-title>{{ this.$route.params.school }}</v-card-title>
          <v-card-text>
            <v-switch v-model="openAdmission" label="Open Admission"></v-switch>
            <!--School Year -->
            <v-card dark>
              <v-card-title>Admission Setting</v-card-title>
              <v-card-text>
                <v-row>
                  <!-- Start Date Name -->
                  <v-col cols="12" sm="6" md="4">
                    <label for="startAdmission">Admission Open Date</label>
                    <b-form-datepicker
                      id="startAdmission"
                      v-model="startAdmission"
                      class="mb-2"
                    ></b-form-datepicker>
                  </v-col>
                  <!-- End Date Name -->

                  <!-- Start Date Name -->
                  <v-col cols="12" sm="6" md="4">
                    <label for="endAdmission">Admission Close Date</label>
                    <b-form-datepicker
                      id="endAdmission"
                      v-model="endAdmission"
                      class="mb-2"
                    ></b-form-datepicker>
                  </v-col>
                  <!-- End Date Name -->
                </v-row>
                <v-row>
                  <!-- Start Time  -->
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startTime"
                          label="Admission Open Time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="startTime"
                        @input="time = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <!-- End Time  -->

                  <!-- Start Time -->
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endTime"
                          label="Admission Close Time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="endTime"
                        @input="time2 = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <!-- End Time -->
                </v-row>

                <br />
                <v-alert type="info" icon="mdi-calendar">
                  Admission Weekends
                </v-alert>
                <v-row>
                  <v-col cols="2">
                    <v-switch label="Saturday" color="red" />
                  </v-col>
                  <v-col cols="2">
                    <v-switch label="Sunday" color="red" />
                  </v-col>

                  <v-col cols="2">
                    <v-switch label="Monday" color="red" />
                  </v-col>

                  <v-col cols="2">
                    <v-switch label="Tuesday" color="red" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-switch label="Wednesday" color="red" />
                  </v-col>
                  <v-col cols="2">
                    <v-switch label="Thursday" color="red" />
                  </v-col>
                  <v-col cols="2">
                    <v-switch label="Friday" color="red" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!--End School Year -->
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="locations"
                  v-model="location"
                  label="Location"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <b-form-group
                  id="fieldobjectives"
                  horizontal
                  :label-cols="2"
                  breakpoint="md"
                  label="Admission Feedback"
                >
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="admissionFeedback"
                    :options="editorOption"
                  />
                </b-form-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <b-button type="submit" variant="primary">Edit School</b-button>
          </v-card-actions>
        </v-card>
      </b-form>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
// import router from "@/router";

export default {
  name: "EditSchool",
  data() {
    return {
      location: "",
      locations: [],
      menu2: false,
      time: false,
      time2: false,
      openAdmission: false,
      admissionFeedback: "",
      weekends: [{ sunday: false }],
      school: {},
      startAdmission: new Date().toISOString().substr(0, 10),
      endAdmission: new Date().toISOString().substr(0, 10),
      startTime: "",
      endTime: "",
      schoolRef: firebase.firestore().doc(this.$route.params.path)
    };
  },
  created() {
    this.schoolRef.get().then(doc => {
      if (doc.exists) {
        this.school = doc.data();
        this.startAdmission = this.school.startadmission;
        this.endAdmission = this.school.endadmission;

        this.startTime = this.school.starttime;
        this.endTime = this.school.endtime;
        this.openAdmission = this.school.openadmission;
        this.admissionFeedback = this.school.admissionfeedback;
        this.location = this.school.location;
        this.weekends = this.school.weekends;
      } else {
        alert("No such document!");
      }
    });

    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/locations")
      .onSnapshot(querySnapshot => {
        this.locations = [];
        querySnapshot.forEach(doc => {
          this.locations.push({
            text: doc.data().title,
            value: doc.id
          });
        });
      });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.school.startadmission = this.startAdmission;
      this.school.endadmission = this.endAdmission;
      this.school.openadmission = this.openAdmission;
      this.school.admissionfeedback = this.admissionFeedback;
      this.school.location = this.location;
      //this.school.weekends = this.weekends;

      this.school.starttime = this.startTime ?? "09:00";
      this.school.endtime = this.endTime ?? "13:00";

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .set(this.school)
        .then(() => {
          this.$bvToast.toast("School Modified", {
            title: "School Modified Successfully",
            variant: "success",
            solid: true
          });
          //router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
