<template>

  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img src="@/assets/live.png" class=" ma-2" width="44px" />
          <span class="title font-weight-light">Students List</span>

          <v-row align="center" justify="end">
            <download-excel
              class="btn btn-default"
              :data="studentInfo"
              :fields="fexport"
              type="csv"
              name="students.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
              <small>Export to Excel</small>
            </download-excel>
          </v-row>
        </v-card-title>

        <v-card-text>
          <b-card-group deck>
            <div class="col-xl-3 col-md-6">
              <v-card flat dark color="green">
                <v-card-text>
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <v-icon size="100"
                          >mdi-clipboard-account-outline</v-icon
                        >
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <p class="card-category">Total Students</p>
                        <h4 style="color:#ffffff; font-size:40px;">
                          {{ studentInfo.length }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="col-xl-3 col-md-6">
              <v-card flat dark color="blue">
                <v-card-text>
                  <div class="row">
                    <div class="col-5">
                      <div class="icon-big text-center">
                        <v-icon size="100">mdi-domain</v-icon>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="numbers">
                        <p class="card-category">Schools</p>
                        <h4 style="color:#ffffff; font-size:40px;">
                          {{ schools.length }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </b-card-group>

          <v-card
            flat
            outlined
            style="background-color:#d7e9f9; margin-bottom:20px;"
          >
            <v-card-text>
              <!-- School -->

              <v-row>
                <v-col class="d-flex" cols="4" sm="4">
              <b-select
               label="School"
                @change="onSchoolChange()"
              v-model="student.school" :options="schools"
              ></b-select>
   
                </v-col>
                <!-- Grade Level -->

                <v-col class="d-flex" cols="4" sm="4">

                  <b-select
               label="Grade Level"
                @change="onGradeChange()"
              v-model="student.grade" :options="level"
              ></b-select>

                 
                </v-col>
                <v-col class="d-flex" cols="4" sm="4">


                     <b-select
               label="Class"
                @change="onClassChange()"
              v-model="student.class" :options="classes"
              ></b-select>



                 
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn depressed small color="success" @click="clearSearch"
                >Clear Search</v-btn
              ></v-card-actions
            >
          </v-card>

          <v-btn
            color="blue"
            dark
            @click="goToPage('AddStudent')"
            depressed
            small
            style="margin-bottom:20px"
            >Add New Student</v-btn
          >

          <!--  {{ studentscount }}
          <v-btn
            color="grren"
            dark
            @click="transferSubmission"
            depressed
            small
            style="margin-bottom:20px; margin-left:20px;"
            >Start</v-btn
          >-->


          <vue-good-table
      :columns="fields"
      :rows="studentInfo"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
     <template slot="table-row" slot-scope="props">
       <span v-if="props.column.field == 'actions'">
 <v-btn
                x-small
                color="success"
                depressed
                @click="details(props.row.path)"
              >
                <v-icon size="14">mdi-eye</v-icon>
              </v-btn>
              &nbsp;
              <v-btn
                x-small
                color="error"
                depressed
                @click="deleteboard(props.row.path, props.row.name)"
              >
                <v-icon size="14">mdi-delete-outline</v-icon>
              </v-btn>
              &nbsp;
              <v-btn x-small color="info" depressed @click="Edit(props.row.path)">
                <v-icon size="14">mdi-pencil-outline</v-icon>
              </v-btn>
       </span>
       <span v-else-if="props.column.field == 'suspend'">
        <v-chip v-if="props.row.suspend" color="error" small>Suspend</v-chip>
              <v-chip v-else color="success" small>Active</v-chip>
       </span>
       </template>
       </vue-good-table>


         
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import axios from "axios";

export default {
  name: "StudentsList",
  components: {  },

  data() {
    return {
      studentscount: 0,
      fields: [
        {
          field: "suspend",
          label: "Active",
         class: "text-center",
        },
        {
          field: "id",
          label: "Id",
          class: "text-center",
        },
       
        {
          field: "branch",
          label: "School",
          class: "text-center",
        },
        {
          field: "grade",
          label: "Grade",
           class: "text-center",
        },
        {
          field: "class",
          label: "Class",
          class: "text-center",
        },

        {
          field: "name",
          label: "Student Name",
          class: "text-center",
        },
        {
          field: "actions",
          label: "Actions",
          class: "text-center",
        }
      ],

      fexport: {
        id: "id",
        school: "school",
        branch: "branch",
        grade: "grade",
        class: "class",
        firstname: "firstname",
        middlename: "middlename",
        lastname: "lastname",
        citizenship: "citizenship",
        date_of_birth: "date_of_birth",
        father_degree: "father_degree",
        father_company: "father_company",
        father_email: "father_email",
        father_mobile: "father_mobile",
        father_occupation: "father_occupation",
        mother_degree: "mother_degree",
        mother_email: "mother_email",
        mother_mobile: "mother_mobile",
        mother_name: "mother_name",
        mother_occupation: "mother_occupation",
        previous_school: "previous_school",
        religion: "religion",
        second_language: "second_language",
        username: "username",
        password: "password",
        nationalID: "nationalID",
        father_nationalID: "father_nationalID",
        mother_nationalID: "mother_nationalID",
        educationPath: "educationPath",
        email: "email",
        points: "points"
      },

      info: "",
      studentInfo: [],
      studentExpo: {},
      errors: [],
      student: [],
      schools: [],
      level: [],
      classes: [],
      ref: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData"),
      // .orderBy("email")
      // .startAfter("ahmed.st4012@nis-egypt.com"),

      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order"),
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/")
    };
  },
  created() {
    this.schoolsRef
      //.where("active", "==", true)
      .get()
      .then(querySnapshot => {
        this.schools = [];
        querySnapshot.forEach(doc => {
          this.schools.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });

    // firebase
    //   .firestore()
    //   .collection("schools" + this.$store.state.school + "StudentsData")
    //   // .where("branch", "==", this.student.school)
    //   // .where("grade", "==", this.student.grade)
    //   .get()
    //   .then(querySnapshot => {
    //     this.studentInfo = [];
    //     querySnapshot.forEach(doc => {
    //       this.FullStudents(doc);
    //     });
    //   });
  },
  methods: {
    FullStudents(doc) {
      this.studentInfo.push({
        key: doc.id,
        path: doc.ref.path,
        id: doc.data().id,
        grade: doc.data().grade,
        branch: doc.data().branch,
        class: doc.data().class,
        firstname: doc.data().firstname,
        middlename: doc.data().middlename,
        lastname: doc.data().lastname,
        name:
          doc.data().firstname +
          " " +
          doc.data().middlename +
          " " +
          doc.data().lastname,

        address: doc.data().address,

        application_date: doc.data().application_date,

        citizenship: doc.data().citizenship,
        date_of_birth: doc.data().date_of_birth,
        father_degree: doc.data().father_degree,
        father_company: doc.data().father_company,
        father_email: doc.data().parent,

        father_mobile: doc.data().father_mobile,

        father_occupation: doc.data().father_occupation,

        mother_degree: doc.data().mother_degree,
        mother_email: doc.data().mother_email,
        mother_mobile: doc.data().mother_mobile,
        mother_name: doc.data().mother_name,

        mother_occupation: doc.data().mother_occupation,
        previous_school: doc.data().previous_school,
        religion: doc.data().religion,
        second_language: doc.data().second_language,
        admission_current: doc.data().admission_current,

        nationalID: doc.data().nationalID,
        father_nationalID: doc.data().father_nationalID,
        mother_nationalID: doc.data().mother_nationalID,

        username: doc.data().username,
        password: doc.data().password,
        email: doc.data().email,
        suspend: doc.data().suspend,
        educationPath: doc.data().educationPath,
        points: doc.data().points,
        school: doc.data().school
      });
    },

    async transferSubmission() {
      this.studentscount = 0;

      //! Get students

      const students = await firebase
        .firestore()
        .collection("/schools" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade)
        .where("class", "==", this.student.class)
        .get();

      await students.docs.forEach(async student => {
        // const batch = firebase.firestore().batch();
        const promises = [];
        this.subject = "";

        //! Get Assignments

        const assignments = await firebase
          .firestore()
          .collection(student.ref.path + "/assignments")
          .get();

        await assignments.docs.forEach(async assignment => {
          //! Get Subject Title
          if (
            assignment.data().type == "flipped" ||
            assignment.data().type == "exit"
          ) {
            this.subject = assignment.data().week.split("/")[7];
          } else {
            this.subject = assignment.data().asspath.split("/")[7];
          }

          const subRef = assignment.ref;

          promises.push(
            subRef.update({
              school: this.$store.state.school,
              branch: student.data().branch,
              gradelevel: student.data().grade,
              class: student.data().class,
              subject: this.subject,
              description: ""
            })
          );
        });

        await Promise.all(promises).then(() => {
          console.log("student");
        });
      });
    },

    plog() {
      // axios
      //   .get("https://api.copyleaks.com/v3/education/submit/file/SCAN-ID")
      //   .then(response => (this.info = response));

      var xmlBodyStr =
        '{"email": "ahmed.kamal@nis-egypt.com","key": "e271cdce-037d-4eb0-8eea-98430e7c6a1e"}';
      var config = {
        headers: {
          crossDomain: true,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,GET,PUT,DELETE",
          "Access-Control-Allow-Headers": "Authorization, Lang"
        },
        withCredentials: true
      };

      axios
        .post(
          "https://id.copyleaks.com/v3/account/login/api",
          xmlBodyStr,
          config
        )
        .then(function(response) {
          this.token = response.data.token;
          //if login token works then get records
        })
        .catch(e => {
          console.log(e);
        });
    },
    clearSearch() {
      this.studentInfo = [];
      // this.ref.onSnapshot(querySnapshot => {
      //   this.studentInfo = [];
      //   querySnapshot.forEach(doc => {
      //     if (doc.data().type != "parent")
      //       this.studentInfo.push({
      //         key: doc.id,
      //         path: doc.ref.path,
      //         branch: doc.data().branch,
      //         grade: doc.data().grade,
      //         class: doc.data().class,
      //         status: doc.data().admission_status,
      //         laststatus: doc.data().admission_current,

      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         lastname: doc.data().lastname,
      //         admission_date: doc.data().admission_date,
      //         admission_time: doc.data().admission_time,

      //         address: doc.data().address,

      //         application_date: doc.data().application_date,

      //         citizenship: doc.data().citizenship,
      //         date_of_birth: doc.data().date_of_birth,
      //         father_academic_degree: doc.data().father_academic_degree,
      //         father_company_business: doc.data().father_company_business,
      //         father_email: doc.data().father_email,

      //         father_mobile: doc.data().father_mobile,

      //         father_occupation: doc.data().father_occupation,

      //         mother_academic_degree: doc.data().mother_academic_degree,
      //         mother_email: doc.data().mother_email,
      //         mother_mobile: doc.data().mother_mobile,
      //         mother_name: doc.data().mother_name,

      //         mother_occupation: doc.data().mother_occupation,
      //         previous_school: doc.data().previous_school,
      //         religion: doc.data().religion,
      //         second_language: doc.data().second_language,
      //         admission_current: doc.data().admission_current,

      //         nationalID: doc.data().nationalID,
      //         father_nationalID: doc.data().father_nationalID,
      //         mother_nationalID: doc.data().mother_nationalID,

      //         username: doc.data().username,
      //         password: doc.data().password
      //       });
      //   });
      // });
    },
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(id)
              .delete()
              .then(() => {
                const index = this.studentInfo.findIndex(el => el.path === id)
                if (index !== -1) {
                  this.studentInfo.splice(index, 1)
                }
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  title,
                  "SIS",
                  "Student"
                );
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.student.school +
            "/grades/"
        )
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: [doc.ref.path, doc.data().title]
            });
          });
        });

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.student.school)
        .get()
        .then(querySnapshot => {
          this.studentInfo = [];
          querySnapshot.forEach(doc => {
            this.FullStudents(doc);
          });
        });
    },

    onGradeChange() {
      this.classes = [];

      firebase
        .firestore()
        .collection(this.student.grade[0] + "/classes")
        .get()
        .then(querySnapshot => {
          this.classes = [];
          querySnapshot.forEach(doc => {
            this.classes.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade[1])
        .get()
        .then(querySnapshot => {
          this.studentInfo = [];
          querySnapshot.forEach(doc => {
            this.FullStudents(doc);
          });
        });
    },
    onClassChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.student.school)
        .where("grade", "==", this.student.grade[1])
        .where("class", "==", this.student.class)
        .get()
        .then(querySnapshot => {
          this.studentInfo = [];
          querySnapshot.forEach(doc => {
            this.FullStudents(doc);
          });
        });
    },

    details(id) {
      router.push({
        name: "ShowStudent",
        params: {
          id: id
        }
      });
    },
    Edit(id) {
      router.push({
        name: "EditStudent",
        params: {
          path: id
        }
      });

      // let routeData = this.$router.resolve({
      //   path: "/editstudent",
      //   params: { path: id }
      // });
      // window.open(routeData.href, "_blank");
    },

    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
