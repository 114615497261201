<template>
  <div>
    <!-- Videos Hub -->
    <StandardsHub
      :path="this.$store.state.subject + '/standards'"
      v-if="master == true"
    ></StandardsHub>

    <!-- End Videos Hub -->

    <b-button
      variant="success"
      @click="goToImport($store.state.subject)"
      style="margin-left: 10px"
    >
      <v-icon size="20">mdi-table-arrow-left</v-icon>
      <small style="margin-left: 10px">Import Standards</small>
    </b-button>
    <br />
    <v-row>
      <v-col cols="6">
        <v-card
          flat
          outlined
          style="margin-top:20px;"
          @click="goToPage('Domains')"
          ><v-card-text>
            <img src="@/assets/assignments.png" class=" ma-2" width="44px" />
            Domains</v-card-text
          ></v-card
        >
      </v-col>
      <v-col cols="6">
        <v-card
          flat
          outlined
          style="margin-top:20px;"
          @click="goToPage('Clusters')"
          ><v-card-text>
            <img src="@/assets/books.png" class=" ma-2" width="44px" />
            Clusters</v-card-text
          ></v-card
        >
      </v-col>
    </v-row>

    <AddStandard
      :path="this.$store.state.subject + '/standards'"
      v-if="master == true"
    ></AddStandard>
    <vue-good-table
      style="margin-top: 20px"
      :columns="fields"
      :rows="standards"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <v-btn
            style="margin-left: 5px"
            small
            color="info"
            dark
            depressed
            v-if="master == true"
            @click="goToEdit(props.row.title, props.row.path)"
          >
            <v-icon color="white" size="20">mdi-playlist-edit</v-icon>
          </v-btn>
          <v-btn
            style="margin-left: 5px"
            small
            color="red"
            dark
            depressed
            v-if="master == true"
            @click="deletestandard(props.row.key, props.row.code)"
          >
            <v-icon color="white" size="20">mdi-trash-can-outline</v-icon>
          </v-btn>
        </span>
        <span v-else-if="props.column.field == 'sub'">
          <v-btn
            small
            color="green"
            block
            dark
            depressed
            @click="gotosub(props.row.code)"
          >
            <v-icon>mdi-format-list-bulleted-type</v-icon>
            Sub Standards</v-btn
          >
        </span>
        <span v-else-if="props.column.field == 'des'">
          <v-btn
            small
            color="info"
            block
            dark
            depressed
            @click="gotodistribute(props.row.code)"
          >
            <v-icon>mdi-align-vertical-distribute</v-icon>
            Distribution</v-btn
          >
        </span>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import StandardsHub from "@/components/hub/StandardsHub";
import AddStandard from "@/components/lms/standards/AddStandard";

import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "Standards-page",
  components: { StandardsHub, AddStandard },

  data() {
    return {
      master: this.$store.state.master,
      newStandard: {},
      standards: [],
      dialog: false,
      fields: [
        {
          field: "code",
          label: "Code",
          sortable: true,
          class: "text-left",
          width: "120px"
        },

        {
          field: "domain",
          label: "Domain",
          sortable: true,
          class: "text-left"
        },
        {
          field: "cluster",
          label: "Clusters",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Description",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Actions",
          sortable: true,
          class: "text-left",
          width: "150px"
        },
        {
          field: "sub",
          label: "",
          sortable: true,
          class: "text-left"
        },

        {
          field: "des",
          label: "",
          sortable: true,
          class: "text-left"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$store.state.subject + "/standards")
      .where("parent", "==", "")
      .onSnapshot(querySnapshot => {
        this.standards = [];
        querySnapshot.forEach(doc => {
          this.standards.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            code: doc.data().code,
            domain: doc.data().domain,
            cluster: doc.data().cluster,
            source: doc.data().source,
            strand: doc.data().strand,
            parent: doc.data().parent,
            topic: doc.data().topic
          });
        });
      });
  },
  methods: {
    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$store.state.subject + "/standards")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Standard Deleted", {
                  title: "Standard Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    gotosub(parent) {
      localStorage.setItem("parent", parent);

      router.push({
        name: "SubStandards",
        params: { parent: parent }
      });
    },
    gotodistribute(parent) {
      localStorage.setItem("parent", parent);
      localStorage.setItem("isparent", true);

      router.push({
        name: "Distribute",
        params: { parent: parent }
      });
    },
    goToPage(page) {
      router.push({
        name: page
      });
    },

    goToEdit(title, path) {
      router.push({
        name: "EditStandard",
        params: { title: title, path: path }
      });
    },
    goToImport(path) {
      router.push({
        name: "ImportStandards",
        params: { path: path }
      });
    }
  }
};
</script>
