<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Submission Report</v-card-title>
        <v-card-text
          ><h5>Your Grade</h5>
          <h3 style="color: green">
            {{ this.sub.right + "/" + this.sub.grade }}
          </h3>

          <v-row>
            <v-col cols="12" style="text-align: left">
              <v-card outlined style="border-style: solid;  border-width: 1px;">
                <v-card-title>
                  <h5>Lesson Answers</h5>
                </v-card-title>
                <v-card-text>
                  <div v-if="this.sub.score > 80">
                    <img src="@/assets/reports/80.png" width="50%" />
                    <br />
                    <br />
                    <h6>
                      {{ this.$store.state.firstname }}, Congratulations, you’ve
                      mastered this topic!
                    </h6>
                  </div>
                  <div v-else-if="this.sub.score >= 60 && this.sub.score <= 80">
                    <img src="@/assets/reports/61.png" width="50%" />
                    <br />
                    <br />
                    <h6>
                      {{ this.$store.state.firstname }}, Congratulations, your
                      work here is commendable.
                    </h6>
                  </div>
                  <div v-else-if="this.sub.score >= 41 && this.sub.score <= 60">
                    <img src="@/assets/reports/41.png" width="50%" />
                    <br />
                    <br />
                    <h6>
                      {{ this.$store.state.firstname }}, Well-done, you’ve done
                      a good job.
                    </h6>
                  </div>
                  <div v-else-if="this.sub.score >= 21 && this.sub.score <= 40">
                    <img src="@/assets/reports/21.png" width="50%" />
                    <br />
                    <br />
                    <h6>
                      {{ this.$store.state.firstname }}, watch out, that wasn’t
                      your best work. You can do better next time!
                    </h6>
                  </div>
                  <div v-else-if="this.sub.score < 21">
                    <img src="@/assets/reports/0.png" width="50%" />
                    <br />
                    <br />
                    <h6>
                      {{ this.$store.state.firstname }}, Oops, you didn’t make
                      it, That’s okay, take your time and go over this lesson
                      one more time.
                    </h6>
                  </div>

                  <img
                    src="@/assets/reports/questions.png"
                    width="30px"
                    style="margin-top: 10px"
                  />
                  {{ this.sub.right + this.sub.wrong + this.sub.missed }}
                  Total Questions

                  <br />

                  <img
                    src="@/assets/reports/right.png"
                    width="30px"
                    style="margin-top: 10px"
                  />
                  {{ this.sub.right }} Your Answer is Correct

                  <br />

                  <img
                    src="@/assets/reports/wrong.png"
                    width="30px"
                    style="margin-top: 10px"
                  />
                  {{ this.sub.wrong }} Your Answer is Incorrect
                  <br />

                  <v-row>
                    <v-col cols="12" sm="6" xs="6" style="text-align: left">
                      <v-card
                        outlined
                        style="border-style: solid;  border-width: 1px;"
                      >
                        <v-card-title>Questions Review</v-card-title>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item-group
                              v-model="selectedItem"
                              color="primary"
                            >
                              <QuestionPreview
                                v-for="(q, index) in questions"
                                :key="q.key"
                                :title="'Question ' + (index + 1)"
                                :answer="q.answer"
                                :path="q.question"
                              />
                            </v-list-item-group>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="6" xs="6" style="text-align: left">
                      <v-card
                        outlined
                        style="border-style: solid;  border-width: 1px;"
                      >
                        <v-card-title>Questions Answers</v-card-title>
                        <v-card-text>
                          <div id="chart">
                            <apexchart
                              type="donut"
                              width="450"
                              :options="dochartOptions"
                              :series="doseries"
                            ></apexchart>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import QuestionPreview from "./QuestionPreview";

export default {
  components: { QuestionPreview },
  data() {
    return {
      submission: localStorage.getItem("submissionpath"),
      sub: [],
      questions: [],
      loading: false,
      subject: this.$store.state.subjectTitle,
      grade: localStorage.getItem("grade"),
      week: localStorage.getItem("weekpath"),
      //Score Chart
      doseries: [0, 0, 0],
      dochartOptions: {
        colors: ["#059612", "#e1261c", "#ff8400"],
        chart: {
          type: "donut"
        },
        labels: ["Right", "Wrong", "Missed"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.submission)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) return;
        this.sub = snapshot.data();
        this.doseries = [this.sub.right, this.sub.wrong, this.sub.missed];

        firebase
          .firestore()
          .collection(this.submission + "/questions")
          .orderBy("time")
          .onSnapshot(querySnapshot => {
            this.questions = [];
            querySnapshot.forEach(doc => {
              this.questions.push({
                key: doc.id,
                path: doc.ref.path,
                title: doc.data().title,
                type: doc.data().qtype,
                answer: doc.data().answer,
                date: doc.data().date,
                question: doc.data().question
              });
            });
          });

        this.loading = true;
      });
  }
};
</script>
