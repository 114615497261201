<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Timetables</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      path: "/schools/" + this.$store.state.school + "/timetables",
      fields: [
        {
          field: "branch",
          label: "Branch",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/branches",
          ftype: "ref",
          lookup: "title"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/jobs",
          ftype: "ref",
          lookup: "title"
        },
        {
          field: "teacher",
          label: "Teacher",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/jobs",
          ftype: "ref",
          lookup: "title"
        },
        {
          field: "vacancy",
          label: "Vacancy Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "positions",
          label: "Number of Positions",
          sortable: true,
          class: "text-left",
          ftype: "number"
        },
        {
          field: "description",
          label: "Description",
          sortable: true,
          class: "text-left",
          ftype: "area"
        }
      ]
    };
  }
};
</script>
