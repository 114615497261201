<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          Pre Teaching

          <v-divider></v-divider>
          <!-- Lesson Preview -->
          <v-dialog v-model="ldialog" width="60%">
            <template v-slot:activator="{ on, attrs }">
              <b-button pill variant="success" v-bind="attrs" v-on="on">
                <v-icon color="white" size="20">mdi-monitor-eye</v-icon>
                <small style="margin-left: 10px;">Preview Lesson</small>
              </b-button>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">
                  <v-icon>mdi-view-day-outline</v-icon>Lesson Preview
                </span>
                <v-divider></v-divider>
                <v-icon color="red" @click="ldialog = false"
                  >mdi-close-thick</v-icon
                >
              </v-card-title>
              <v-card-text>
                <LessonPreview
                  :path="this.$route.params.id + '/lesson/'"
                ></LessonPreview>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="ldialog = false"
                  >Back</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- End Lesson Preview -->
        </v-card-title>
        <v-card-subtitle>{{ gradetitle }} </v-card-subtitle>
        <v-card-text>
          <!-- Activities Hub -->
          <VideosHub
            :path="this.$route.params.id"
            :order="this.boards.length"
            v-if="master == true"
          ></VideosHub>
          <!-- End Activities Hub -->
          &nbsp;
          <!-- Questions Hub -->
          <QuestionsHub
            :path="this.$route.params.id"
            :order="this.boards.length"
            source="lesson"
            v-if="master == true"
          ></QuestionsHub>
          <!-- End Questions Hub -->

          <!-- Subject Questions Hub -->
          <SubjectQuestionsHub
            :path="this.$route.params.id"
            :order="this.boards.length"
            source="lesson"
            style="margin-left:20px;"
            v-if="master == true"
          ></SubjectQuestionsHub>

          <!-- Subject Questions Hub -->
          <LessonQuestionsHub
            :path="this.$route.params.id"
            :order="this.boards.length"
            source="lesson"
            style="margin-left:20px;"
            v-if="master == true"
          ></LessonQuestionsHub>

          <br />
          <AddActivity
            :order="this.boards.length"
            source="Activity"
            v-if="master == true"
          ></AddActivity>
          <br />

          <vue-good-table
            :columns="fields"
            :rows="boards"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->
              <span v-if="props.column.field == 'actions'">
                <v-icon
                  color="red"
                  @click="deleteboard(props.row.key)"
                  v-if="master == true"
                  >mdi-delete-outline</v-icon
                >

                <v-icon
                  style="margin-left:10px"
                  color="blue"
                  @click="Edit(props.row.path, props.row.type, props.row.qtype)"
                  v-if="master == true"
                  >mdi-square-edit-outline</v-icon
                >
              </span>

              <span v-else-if="props.column.field == 'options'">
                <div v-if="props.row.type == 'question'">

               
                  <button
                    class="btn btn-info btn-sm"
                    @click="options(props.row.path, props.row.title)"
                    v-if="props.row.qtype == 'mc' || props.row.qtype == null ||props.row.qtype == 'Listening'" 
                  >
                 
                    Options
                  </button>
                </div>
              </span>

              <span v-else-if="props.column.field == 'type'">
                <span v-if="props.row.type == 'video'">
                  <v-icon color="green" size="40">mdi-youtube</v-icon>
                </span>
                <span v-else-if="props.row.type == 'question'">
                  <v-icon color="red" size="40">mdi-help-box</v-icon>
                </span>
                <span v-else-if="props.row.type == 'show'">
                  <v-icon color="orange" size="40"
                    >mdi-file-powerpoint-box</v-icon
                  >
                </span>
                <span v-else-if="props.row.type == 'Live Session'">
                  <v-icon color="blue darken-1" size="40">mdi-video</v-icon>
                </span>
                <span v-else-if="props.row.type == 'flipped'">
                  <v-icon color="orange darken-1" size="40"
                    >mdi-orbit-variant</v-icon
                  >
                </span>
                <span v-else-if="props.row.type == 'exit'">
                  <v-icon color="red darken-1" size="35"
                    >mdi-location-exit</v-icon
                  >
                </span>
              </span>

              <span v-else-if="props.column.field == 'title'">
                {{ props.row.title == null ? "" : removeHTML(props.row.title) }}
                <p style="margin-top:15px">
                  <v-chip dark small v-show="props.row.standard"
                    >Standard: {{ props.row.standard }}</v-chip
                  >
                  <v-chip
                    color="green"
                    dark
                    small
                    v-show="props.row.dok"
                    style="margin-left:5px"
                    >DOK: {{ props.row.dok }}</v-chip
                  >
                  <v-chip
                    color="primary"
                    dark
                    small
                    v-show="props.row.bloom"
                    style="margin-left:5px"
                    >Bloom: {{ props.row.bloom }}</v-chip
                  >
                  <v-chip
                    color="red"
                    dark
                    small
                    v-show="props.row.difficulty"
                    style="margin-left:5px"
                    >Difficulty: {{ props.row.difficulty }}</v-chip
                  >
                </p>
              </span>
              <span v-else-if="props.column.field == 'order'">
                <v-icon
                  @click="goUp(props.row.path, props.row.order)"
                  color="blue"
                  >mdi-arrow-up-bold</v-icon
                >
                &nbsp;
                <v-icon
                  @click="goDown(props.row.path, props.row.order)"
                  color="red"
                  >mdi-arrow-down-bold</v-icon
                >
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "@/router";
import AddActivity from "./AddActivity";
import LessonPreview from "./LessonPreview";
import VideosHub from "@/components/hub/VideosHub";
import QuestionsHub from "@/components/hub/QuestionsHub";
import EditVideo from "@/components/lms/activities/Create/Video.vue";
import EditSheet from "@/components/lms/activities/Create/Sheet.vue";
import EditFlipped from "@/components/lms/activities/Create/Flipped.vue";
import EditExit from "@/components/lms/activities/Create/Exit.vue";
import EditLive from "@/components/lms/activities/Create/Live.vue";
import EditQuestion from "@/components/lms/activities/Create/Question.vue";
import SubjectQuestionsHub from "@/components/hub/SubjectQuestionsHub";
import LessonQuestionsHub from "@/components/hub/LessonQuestionsHub";

export default {
  name: "ActivitiesList",
  components: {
    AddActivity,
    LessonPreview,
    VideosHub,
    QuestionsHub,
    SubjectQuestionsHub,
    LessonQuestionsHub
  },
  data() {
    return {
      dragData: {},
      ldialog: false,
      thisOrder: 0,
      master: this.$store.state.master,

      newActivity: {},
      grade: this.$route.params.id,
      activities: [],

      fields: [
        {
          field: "order",
          label: "",
          class: "text-center",
          width: "5%"
        },
        {
          field: "type",
          label: "",
          class: "text-left",
          width: "5%"
        },
        {
          field: "title",
          label: "Title",
          class: "text-center",
          width: "65%",
          html: false
        },
        {
          field: "options",
          label: "Options",
          class: "text-left",
          width: "5%"
        },

        {
          field: "actions",
          label: "",
          class: "text-center"
        }
      ],
      boards: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$route.params.id + "/lesson/")
        .orderBy("order"),
      gradetitle: this.$route.params.title
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.boards = [];
      querySnapshot.forEach(doc => {
        this.boards.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          order: doc.data().order,
          type: doc.data().type,
          standard: doc.data().standard,
          difficulty: doc.data().difficulty,
          dok: doc.data().dok,
          bloom: doc.data().bloom,
          qtype: doc.data().qtype
        });
      });
    });
  },
  methods: {
    removeHTML(text) {
      let regex = /(<([^>]+)>)/gi;

      return text.replace(regex, "");
    },
    deleteboard(id) {
      this.$confirm({
        message: "Are you sure You want to Delete this Activity ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$route.params.id + "/lesson/")
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  id,
                  this.$store.state.subject,
                  "Activity"
                );

                this.$bvToast.toast("Activity Deleted", {
                  title: "Activity Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    options(path, title) {

      let routeData = this.$router.resolve({
        path: "/options",
        query: { id: path, title: title }
      });
      window.open(routeData.href, "_blank");

      // router.push({
      //   name: "OptionsList",
      //   params: {
      //     id: path,
      //     title: title
      //   }
      // });
    },

    Edit(path, type, qtype) {
      if (type == "video") {
        this.$modal.show(
          EditVideo,
          {
            path: path,
            order: this.order,
            source: "edit",
            week: this.$route.params.id
          },
          { width: "70%", height: "750px" }
        );
      } else if (type == "show") {
        this.$modal.show(
          EditSheet,
          {
            path: path,
            order: this.order,
            source: "edit",
            week: this.$route.params.id
          },
          { width: "70%", height: "600px" }
        );
      } else if (type == "flipped") {
        this.$modal.show(
          EditFlipped,
          {
            path: path,
            order: this.order,
            source: "edit"
          },
          { width: "70%", height: "600px" }
        );
      } else if (type == "exit") {
        this.$modal.show(
          EditExit,
          {
            path: path,
            order: this.order,
            source: "edit"
          },
          { width: "70%", height: "600px" }
        );
      } else if (type == "Live Session") {
        this.$modal.show(
          EditLive,
          {
            path: path,
            order: this.order,
            source: "edit",
            week: this.$route.params.id
          },
          { width: "70%", height: "600px" }
        );
      } else if (type == "question") {
        this.$modal.show(
          EditQuestion,
          {
            path: path,
            order: this.order,
            source: "edit",
            qtype: qtype,
            week: this.$route.params.id
          },
          { width: "70%", height: "90vh" }
        );
      }
    },
    goUp(path, order) {
      firebase
        .firestore()
        .doc(path)
        .get()
        .then(snapshot => {
          let act = snapshot.data();

          act.order = order - 1;

          firebase
            .firestore()
            .doc(path)
            .set(act)
            .then(() => {})
            .catch(error => {
              alert("Error adding document: ", error);
            });
        });
    },

    goDown(path, order) {
      firebase
        .firestore()
        .doc(path)
        .get()
        .then(snapshot => {
          let act = snapshot.data();

          act.order = order + 1;

          firebase
            .firestore()
            .doc(path)
            .set(act)
            .then(() => {})
            .catch(error => {
              alert("Error adding document: ", error);
            });
        });
    }
  }
};
</script>
