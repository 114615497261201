<template>
  <v-card flat outlined>
    <v-card-title>
      <v-badge
        bordered
        color="success"
        icon="mdi-check-all"
        overlap
        style="margin-right:10px;"
      >
        <v-avatar color="grey" size="50">
          <span class="white--text headline">{{
            this.fname[0] + this.lname[0]
          }}</span>
        </v-avatar>
      </v-badge>
      <small>
        {{ this.fname + " " + this.mname + " " + this.lname }}
      </small>
      <v-spacer />

      <v-btn
        @click="closeblended"
        v-if="blended == true || blended == null"
        x-small
        fab
        color="green"
        depressed
        dark
        ><v-icon>mdi-check</v-icon></v-btn
      >

      <v-btn @click="openblended" v-else x-small color="red" fab depressed dark
        ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div flat style="background-color:#eee; margin-top:20px">
        <!-- Flipped-->
        <v-row style="margin-left:10px"
          ><v-col cols="2" style="background-color:#333; color:#fff;">
            Flipped
          </v-col>
          <v-col cols="8" v-if="this.flipped.data()">
            <v-chip
              color="green"
              small
              dark
              style="margin-right:20px"
              v-if="this.flipped.data().right"
            >
              <v-avatar left>
                <v-icon>mdi-check</v-icon>
              </v-avatar>

              Right ({{ this.flipped.data().right }})</v-chip
            >

            <v-chip
              color="red"
              small
              dark
              style="margin-right:20px"
              v-if="this.flipped.data().wrong"
            >
              <v-avatar left> <v-icon>mdi-close</v-icon> </v-avatar>
              Wrong ({{ this.flipped.data().wrong }})</v-chip
            >

            <v-chip color="blue" small dark v-if="this.flipped.data().missed">
              <v-avatar left>
                <v-icon>mdi-alert-circle-outline</v-icon>
              </v-avatar>
              Missed ({{ this.flipped.data().missed }})</v-chip
            >
          </v-col>

          <v-col cols="2" style="background-color:#333">
            <span v-if="this.flipped.data()">
              <BAnswers :answers="this.flipped.data().answers" />
            </span>
            <br />
            <Override
              :path="this.path + '/assignments/' + weekId + 'flipped'"
              v-if="master == true"
              type="Flipped"
              :week="this.week"
            />
            <v-btn
              style="margin-top:10px"
              dark
              x-small
              width="
              92px"
              color="red"
              depressed
              block
              @click="deleteAtt(flipped.ref.path, 'flipped')"
            >
              <v-icon size="15">mdi-close</v-icon>Delete
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div flat style="background-color:#eee; margin-top:5px">
        <!-- Exit-->
        <v-row style="margin-left:10px"
          ><v-col cols="2" style="background-color:#333; color:#fff;">
            Exit
          </v-col>
          <v-col cols="8" v-if="this.exit.data()">
            <v-chip
              color="green"
              small
              dark
              style="margin-right:20px"
              v-if="this.exit.data().right"
            >
              <v-avatar left>
                <v-icon>mdi-check</v-icon>
              </v-avatar>
              Right ({{ this.exit.data().right }})</v-chip
            >

            <v-chip
              color="red"
              small
              dark
              style="margin-right:20px"
              v-if="this.exit.data().wrong"
              ><v-avatar left> <v-icon>mdi-close</v-icon> </v-avatar>Wrong ({{
                this.exit.data().wrong
              }})</v-chip
            >

            <v-chip color="blue" small dark v-if="this.exit.data().missed">
              <v-avatar left>
                <v-icon>mdi-alert-circle-outline</v-icon>
              </v-avatar>
              Missed ({{ this.exit.data().missed }})</v-chip
            >
          </v-col>
          <v-col cols="2" style="background-color:#333">
            <span v-if="this.exit.data()">
              <BAnswers :answers="this.exit.data().answers" />
            </span>

            <br />
            <Override
              :path="this.path + '/assignments/' + weekId + 'exit'"
              v-if="master == true"
              type="Exit"
              :week="this.week"
            />

            <v-btn
              class="btn btn-sm"
              style="margin-top:10px"
              dark
              x-small
              width="
              92px"
              color="red"
              depressed
              block
              @click="deleteAtt(exit.ref.path, 'exit')"
            >
              <v-icon size="15">mdi-close</v-icon>Delete
            </v-btn>
          </v-col>
        </v-row>

        <!-- In Class Quiz-->
        <v-row style="margin-left:10px; margin-top:5px;">
          <v-col cols="2" style="background-color:green; color:#fff;">
            Guided Practice
          </v-col>
          <v-col cols="8" v-if="this.inclass.data()">
            <v-chip color="blue" small dark v-if="this.inclass.data().grade">
              {{ this.inclass.data().grade }}/20
            </v-chip>
            <div color="blue" small dark v-if="this.inclass.data().feedback">
              {{ this.inclass.data().feedback }}
            </div>
          </v-col>
          <v-col cols="2" style="background-color:#333">
            <Answers
              type="inclass"
              :week="this.week"
              :studentid="this.studentid"
            />
            <br />
            <Override
              :path="this.path + '/assignments/' + weekId + 'exit'"
              v-if="master == true"
              type="inclass"
              :week="this.week"
            />

            <v-btn
              class="btn btn-sm"
              style="margin-top:10px"
              dark
              x-small
              width="
              92px"
              color="red"
              depressed
              block
              @click="deleteAtt(inclass.ref.path, 'inclass')"
            >
              <v-icon size="15">mdi-close</v-icon>Delete
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import Answers from "./Answers";
import BAnswers from "./BAnswers";
import Override from "./Override";
export default {
  name: "Student-page",
  props: ["fname", "mname", "lname", "path", "week", "studentid"],
  components: { Answers, Override, BAnswers },
  data() {
    return {
      flipped: [],
      exit: [],
      inclass: [],
      master: this.$store.state.master,
      blended: true,
      weekId: localStorage.getItem("weekId")
    };
  },
  created() {
    this.flipped = [];
    this.exit = [];
    this.inclass = [];
    firebase
      .firestore()
      .collection(this.$store.state.originalsubject + "/flipped")
      .doc(this.path.split("/")[3] + this.weekId)
      .onSnapshot(querySnapshot => {
        this.flipped = querySnapshot;
      });
    firebase
      .firestore()
      .collection(this.$store.state.originalsubject + "/flipped")
      .doc(this.path.split("/")[3] + this.weekId)
      .onSnapshot(querySnapshot => {
        this.flipped = querySnapshot;
      });
    firebase
      .firestore()
      .collection(this.$store.state.originalsubject + "/exit")
      .doc(this.path.split("/")[3] + this.weekId)

      .onSnapshot(querySnapshot => {
        this.exit = querySnapshot;
      });
    firebase
      .firestore()
      .collection(this.$store.state.originalsubject + "/inclass")
      .doc(this.path.split("/")[3] + this.weekId)

      .onSnapshot(querySnapshot => {
        this.inclass = querySnapshot;
      });

    firebase
      .firestore()
      .doc(this.path)
      .onSnapshot(snapshot => {
        this.blended = snapshot.data().blended;
      });
  },
  methods: {
    openblended() {
      firebase
        .firestore()
        .doc(this.path)
        .update({ blended: true })
        .then(() => {})
        .catch(() => {});
    },

    deleteAtt(path, type) {
      this.$confirm({
        message: "Are you sure You want to Delete this Attempt?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(path)
              .delete()
              .then(() => {
                firebase
                  .firestore()
                  .collection(path + "/assignmentattemps/1/questions")
                  .get()
                  .then(question => {
                    question.forEach(doc => {
                      firebase
                        .firestore()
                        .doc(doc.ref.path)
                        .delete();
                    });
                  });

                this.$bvToast.toast("Attempt Deleted", {
                  title: "Attempt Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
                if (type == "exit") this.exit = [];
                else this.flipped = [];
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    closeblended() {
      firebase
        .firestore()
        .doc(this.path)
        .update({ blended: false })
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>
