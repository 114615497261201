<template>
  <div>
    <b-button block v-b-toggle="this.title" variant="info"
      >Standards Picker
    </b-button>
    <b-collapse :id="this.title" class="mt-2">
      <v-card flat dark outlined style="background-color:#333">
        <v-card-text>
          <v-row
            ><v-col cols="6">
              <v-autocomplete
                v-model.trim="standard.parent"
                :items="standards"
                label="Standard"
                @change="onStandardChange()"
              ></v-autocomplete>

              <v-autocomplete
                v-model.trim="substandard"
                :items="sub"
                label="Sub Standard"
              ></v-autocomplete>

              <v-btn
                depressed
                dark
                style="background-color:green"
                small
                @click="addToLink()"
                >Add Standard</v-btn
              >
            </v-col>
          </v-row>
          <div v-for="link in slink" :key="link.key">
            <v-card flat outlined
              ><v-card-text>
                <v-row
                  ><v-col cols="10">
                    {{ link.standard }}
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      depressed
                      color="red"
                      small
                      style="background-color:red"
                      @click="deletestandard(link.path, link.standard)"
                    >
                      <v-icon color="white" size="20"
                        >mdi-trash-can-outline</v-icon
                      ></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </b-collapse>
  </div>
</template>
<script>
import firebase from "@/Firebase";
// import router from "@/router";

export default {
  name: "StandardsPicker",
  props: ["path", "type", "lesson"],
  components: {},
  data() {
    return {
      standards: [],
      sub: [],
      title: "kamal",
      substandard: "",
      slink: [],
      standard: {}
    };
  },
  created() {
    console.log(this.path)
    this.getStandards();
    this.getLink();
  },
  methods: {
    getStandards() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards")
        .where("parent", "==", "")
        .onSnapshot(querySnapshot => {
          this.standards = [];
          querySnapshot.forEach(doc => {
            this.standards.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().code + " - " + doc.data().title,
              value: doc.data().code
            });
          });
        });
    },

    getLink() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/slink")
        .where("source", "==", this.path)
        .onSnapshot(querySnapshot => {
          this.slink = [];
          querySnapshot.forEach(doc => {
            console.log("slink: ",doc.data())
            this.slink.push({
              key: doc.id,
              path: doc.ref.path,
              standard: doc.data().standard
            });
          });
        });
    },

    onStandardChange() {
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards")
        .where("parent", "==", this.standard.parent)
        .onSnapshot(querySnapshot => {
          this.sub = [];
          querySnapshot.forEach(doc => {
            this.sub.push({
              key: doc.id,
              path: doc.ref.path,
              text: doc.data().code + " - " + doc.data().title,
              value: doc.data().code
            });
          });
        });
    },

    addToLink() {
      this.standard.type = this.type;
      this.standard.source = this.path;
      this.standard.lesson = this.lesson;

      if (this.substandard == "") {
        this.standard.standard = this.standard.parent;
      } else {
        this.standard.standard = this.substandard;
      }

      firebase
        .firestore()
        .collection(this.$store.state.subject + "/slink")
        .add(this.standard)
        .then(() => {
          this.$bvToast.toast("Standard Added", {
            title: "Standard Added Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    deletestandard(path, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(path)
              .delete()
              .then(() => {
                this.$bvToast.toast("Standard Deleted", {
                  title: "Standard Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
