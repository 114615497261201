<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Sections"
          color="primary"
          :path="this.subject + '/examsections'"
        ></CountCard>
        <CountCard title="Active" color="success"></CountCard>
        <CountCard title="InActive" color="danger"></CountCard>
      </b-card-group>
      <br />

      <v-card>
        <v-card-text>
          <div>
            <CRUD
              title="Section"
              :path="this.subject + '/examsections'"
              :fields="this.fields"
              width="500"
              height="550"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "Dimensions-page",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      fields: [
        {
          field: "version",
          label: "Exam Version",
          sortable: true,
          ftype: "number",
          class: "text-left"
        },
        {
          field: "order",
          label: "Order",
          sortable: true,
          ftype: "number",
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "description",
          label: "Description",
          sortable: true,
          ftype: "area",
          class: "text-left"
        },
        {
          field: "instructions",
          label: "Instructions",
          sortable: true,
          ftype: "area",
          class: "text-left"
        }
      ],
      subject: this.$store.state.subject
    };
  },
  created() {},
  methods: {}
};
</script>

<style></style>
