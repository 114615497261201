<template>
  <div>
    {{
      this.student.firstname +
        " " +
        this.student.middlename +
        " " +
        this.student.lastname
    }}
  </div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "Test-page",

  data() {
    return {
      student: {}
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(doc => {
        this.student = doc.data();
      });
  },

  props: ["path"],
  methods: {}
};
</script>

<style lang="scss" scoped></style>
