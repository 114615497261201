<template>
  <div>
    <v-card>
      <v-card-title>
        <v-icon size="40" style="margin-right:10px"
          >mdi-video-wireless-outline</v-icon
        >
        Add new Live Session
      </v-card-title>
      <v-card-text>
        <v-row
          ><v-col cols="4">
            <v-select
              :items="eclasses"
              v-model.trim="session.sessionclass"
              label="Class"
            ></v-select>
          </v-col>

          <v-col cols="8">
            <v-text-field label="Session Label" v-model.trim="session.title" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              :items="weeks"
              v-model.trim="session.week"
              label="Week"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          ><v-col cols="6">
            <v-btn small color="info" depressed @click="goToLive"
              >Create New Session on Microsoft Teams</v-btn
            >
          </v-col>
          <v-col cols="6">
            <v-text-field label="Session Link" v-model.trim="session.link" />
            <v-text-field
              label="PreRecorded Link"
              v-model.trim="session.prerecorded"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label for="SessionDate">Session Date</label>
            <b-form-datepicker
              id="SessionDate"
              v-model="session.date"
              class="mb-2"
            ></b-form-datepicker>
          </v-col>

          <v-col cols="6">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="session.time"
                  label="Picker in menu"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="session.time"
                class="mt-4"
                scrollable
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-divider />
        <v-btn color="success" small @click="onSubmit">
          Create New Session</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card style="margin-top:20px">
      <v-card-title>
        Live Session List
      </v-card-title>
      <v-card-text>
        <v-card
          flat
          outlined
          style="background-color:#d7e9f9; margin-top:20px;"
        >
          <v-card-text>
            <v-row>
              <!--End Filter by Month-->
              <v-col cols="8">
                <v-autocomplete
                  :items="weeks"
                  label="Select Lesson"
                  v-model="selected"
                  @change="search"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-btn
                  @click="clearsearch"
                  color="success"
                  block
                  depressed
                  small
                  >Clear Search</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <vue-good-table
          :columns="fields"
          :rows="sessions"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records' }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- <template v-slot:cell(actions)="row"> -->
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteSession(props.row.key, props.row.title)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </button>
              &nbsp;
              <button
                class="btn btn-info btn-sm"
                @click="goToEdit(props.row.title, props.row.path)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </button>
              &nbsp;
              <button
                class="btn btn-warning btn-sm"
                @click="goToSession(props.row.link)"
              >
                Enter This Session Now
                <v-icon>mdi-video-wireless-outline</v-icon>
              </button>
            </span>

            <!--<span v-else-if="props.column.field == 'title'">
          {{ removeHTML(props.row.title) }}
        </span>-->
          </template>
        </vue-good-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
export default {
  name: "Live-page",
  data() {
    return {
      sessions: {},
      session: {},
      weeks: [],
      eclasses: [],
      selected: "",
      subjectPath: this.$store.state.subject,
      gradePath: this.$store.state.gradePath,
      menu2: "",
      time: "",
      fields: [
        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "time",
          label: "Time",
          sortable: true,
          class: "text-left"
        },

        {
          field: "sessionclass",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "350px"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.subjectPath + "/sessions")
      .onSnapshot(querySnapshot => {
        this.sessions = [];
        querySnapshot.forEach(doc => {
          this.sessions.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            sessionclass: doc.data().sessionclass,
            date: doc.data().date,
            week: doc.data().week,
            link: doc.data().link,
            time: doc.data().time
          });
        });
      });

    firebase
      .firestore()
      .collection(this.subjectPath + "/weeks/")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.weeks = [];
        querySnapshot.forEach(doc => {
          this.weeks.push({
            text: doc.data().title,
            value: doc.ref.path
          });
        });
      });

    firebase
      .firestore()
      .collection(this.gradePath + "/classes")
      .get()
      .then(querySnapshot => {
        this.eclasses = [];
        querySnapshot.forEach(doc => {
          this.eclasses.push({
            value: doc.data().title,
            text: doc.data().title
          });
        });
      });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      firebase
        .firestore()
        .collection(this.subjectPath + "/sessions")
        .add(this.session)
        .then(() => {
          this.session.title = "";
          this.session.link = "";

          this.$bvToast.toast("Sessions Created", {
            title: "Sessions Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    search() {
      firebase
        .firestore()
        .collection(this.subjectPath + "/sessions")
        .where("week", "==", this.selected)
        .onSnapshot(querySnapshot => {
          this.sessions = [];
          querySnapshot.forEach(doc => {
            this.sessions.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              sessionclass: doc.data().sessionclass,
              date: doc.data().date,
              week: doc.data().week,
              link: doc.data().link,
              time: doc.data().time
            });
          });
        });
    },

    clearsearch() {
      firebase
        .firestore()
        .collection(this.subjectPath + "/sessions")
        .onSnapshot(querySnapshot => {
          this.sessions = [];
          querySnapshot.forEach(doc => {
            this.sessions.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              sessionclass: doc.data().sessionclass,
              date: doc.data().date,
              week: doc.data().week,
              link: doc.data().link,
              time: doc.data().time
            });
          });
        });
    },

    deleteSession(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.subjectPath + "/sessions")
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Exam Deleted", {
                  title: "Exam Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    goToLive() {
      window.open("https://teams.microsoft.com", "_blank");
    },
    goToSession(link) {
      window.open(link, "_blank");
    },
    goToEdit(title, path) {
      router.push({
        name: "EditLive",
        params: { title: title, path: path }
      });
    }
  }
};
</script>
