<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button pill variant="warning" v-bind="attrs" v-on="on" size="sm">
        <v-icon size="20">mdi-face-recognition</v-icon>
        <small style="margin-left: 20px;">Select Student</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="padding-right: 20px;">mdi-face-recognition</v-icon
          >Select Student
        </span>

        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- Fillter -->

        <v-card
          flat
          outlined
          style="background-color:#d7e9f9; margin-bottom:20px;"
        >
          <v-card-text>
            <!-- School -->
            <v-row>
              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="schools"
                  v-model="school"
                  label="School"
                  @change="onSchoolChange()"
                ></v-select>
              </v-col>
              <!-- Grade Level -->

              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="level"
                  v-model="grade"
                  label="Grade Level"
                  @change="onGradeChange()"
                ></v-select>
              </v-col>
              <v-col class="d-flex" cols="4" sm="4">
                <v-select
                  :items="classes"
                  v-model="sclass"
                  label="Class"
                  @change="onClassChange()"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer
            ><v-btn depressed small color="success" @click="clearSearch"
              >Clear Search</v-btn
            ></v-card-actions
          >
        </v-card>

        <br />

        <vue-good-table
          :columns="sfields"
          :rows="students"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records', perPage: 5 }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <v-btn
                small
                depressed
                color="blue"
                @click="startChat(props.row.key)"
                style=" color:#ffffff;"
              >
                <v-icon style="margin-right:20px;">mdi-forum-outline</v-icon
                >&nbsp; Start Chat
              </v-btn>
            </span>
            <span v-else-if="props.column.field == 'type'">
              <v-icon color="red">mdi-video-outline</v-icon>
            </span>
          </template>
        </vue-good-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "selectStudent",
  props: ["path", "order"],
  data() {
    return {
      school: "",
      grade: "",
      sclass: "",
      dialog: false,
      activities: [],
      students: [],
      classes: [],
      grades: [],
      level: [],
      schools: [],
      subjects: [],
      selectedStudent: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      sfields: [
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "name",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/branches/")
      .where("active", "==", true)
      .get()
      .then(querySnapshot => {
        this.schools = [];
        querySnapshot.forEach(doc => {
          this.schools.push({
            text: doc.data().title,
            value: doc.data().title
          });
        });
      });
  },
  methods: {
    startChat(student) {
      this.selectedStudent.category = "Student";
      this.selectedStudent.owner = this.$store.state.username;
      this.selectedStudent.receiver = student;

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/chats")
        .doc(this.$store.state.userID + student)
        .set(this.selectedStudent)
        .then(() => {
          //! Add to Employee Chat Collection
          firebase
            .firestore()
            .collection(
              "/schools/" +
                this.$store.state.school +
                "/employees/" +
                this.$store.state.userID +
                "/chats"
            )
            .doc(this.$store.state.userID + student)
            .set({
              chatpath:
                "schools/" +
                this.$store.state.school +
                "/chats/" +
                this.$store.state.userID +
                student,
              receiver: student,
              owner: this.$store.state.userID
            })
            .then(() => {});

          //! Add to Employee Chat Collection
          firebase
            .firestore()
            .collection(
              "/schools/" +
                this.$store.state.school +
                "/StudentsData/" +
                student +
                "/chats"
            )
            .doc(this.$store.state.userID + student)
            .set({
              chatpath:
                "schools/" +
                this.$store.state.school +
                "/chats/" +
                this.$store.state.userID +
                student,
              receiver: student,
              owner: this.$store.state.userID
            })
            .then(() => {});
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    clearSearch() {
      this.students = [];
    },

    onGradeChange() {
      this.classes = [];
      
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.school +
            "/grades/" +
            this.grade +
            "/classes"
        )
        .get()
        .then(querySnapshot => {
          this.classes = [];
          querySnapshot.forEach(doc => {
            this.classes.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },
    onClassChange() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.school)
        .where("grade", "==", this.grade)
        .where("class", "==", this.sclass)
        .get()
        .then(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              branch: doc.data().branch,
              grade: doc.data().grade,
              class: doc.data().class,
              status: doc.data().admission_status,
              laststatus: doc.data().admission_current,

              name:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              admission_date: doc.data().admission_date,
              admission_time: doc.data().admission_time,

              address: doc.data().address,

              application_date: doc.data().application_date,

              citizenship: doc.data().citizenship,
              date_of_birth: doc.data().date_of_birth,
              father_academic_degree: doc.data().father_academic_degree,
              father_company_business: doc.data().father_company_business,
              father_email: doc.data().father_email,

              father_mobile: doc.data().father_mobile,

              father_occupation: doc.data().father_occupation,

              mother_academic_degree: doc.data().mother_academic_degree,
              mother_email: doc.data().mother_email,
              mother_mobile: doc.data().mother_mobile,
              mother_name: doc.data().mother_name,

              mother_occupation: doc.data().mother_occupation,
              previous_school: doc.data().previous_school,
              religion: doc.data().religion,
              second_language: doc.data().second_language,
              admission_current: doc.data().admission_current,

              nationalID: doc.data().nationalID,
              father_nationalID: doc.data().father_nationalID,
              mother_nationalID: doc.data().mother_nationalID,

              username: doc.data().username,
              password: doc.data().password
            });
          });
        });
    },

    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.school +
            "/grades/"
        )
        .orderBy("order")
        .get()
        .then(querySnapshot => {
          this.level = [];
          querySnapshot.forEach(doc => {
            this.level.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    }
  }
};
</script>
