<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <CountCard
          title="Domains"
          color="primary"
          :path="this.$store.state.subject + '/domains'"
        ></CountCard>
      </b-card-group>
      <br />
      <v-btn
        depressed
        small
        color="success"
        style="margin-bottom:20px"
        @click="goToPage('ImportDomains')"
        >Import Domains</v-btn
      >

      <v-card flat outlined>
        <v-card-title>Domains</v-card-title>
        <v-card-text>
          <div>
            <CRUD
              title="Domain"
              :path="this.$store.state.subject + '/domains'"
              :fields="this.fields"
              width="500"
              height="900"
            ></CRUD>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import router from "@/router";
import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "Domains-page",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      code: "",
      fixedItem: {},

      fixeds: [],

      fields: [
        {
          field: "code",
          label: "Code",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "school",
          label: "School",
          ftype: "const",
          value: this.$store.state.subject.split("/")[1]
        },
        {
          field: "branch",
          label: "Branch",
          ftype: "const",
          value: this.$store.state.subject.split("/")[3]
        },
        {
          field: "grade",
          label: "Grade",
          ftype: "const",
          value: this.$store.state.subject.split("/")[5]
        },
        {
          field: "subject",
          label: "Subject",
          ftype: "const",
          value: this.$store.state.subject.split("/")[7]
        }
      ]
    };
  },
  created() {},
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>

<style></style>
