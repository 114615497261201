<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <b-button variant="warning" v-bind="attrs" v-on="on">
        <v-icon size="20">mdi-checkbook</v-icon>
        <small style="margin-left: 10px">Academic Standards</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px">mdi-checkbook</v-icon>Academic
          Standards
        </span>

        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- School -->
        <v-row class="card-text">
          <!-- System -->

          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="csystems"
              v-model="activities.system"
              label="System"
            ></v-select>
          </v-col>

          <!-- Grade -->
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="subjects"
              v-model="activities.subject"
              label="Subject"
              @change="onSubjectChange"
            ></v-select>
          </v-col>

          <!--  
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              :items="grades"
              v-model="activities.grade"
              label="Grade"
            ></v-select>
          </v-col>-->

          <v-col class="d-flex" cols="3" sm="3">
            <div>
              <v-btn color="blue" @click="search" small dark>
                <v-icon>mdi-magnify</v-icon>Search
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="selectedTitle">
          <v-col class="d-flex" cols="12" sm="12">
            <v-card flat>
              <v-card-subtitle>Code : {{ this.code }}</v-card-subtitle>
              <v-card-title style="font-size: 14px"
                >Standard : {{ this.selectedTitle }}</v-card-title
              >
              <v-card-text>
                <b-button size="sm">Import to lesson</b-button></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <br />

        <vue-good-table
          :columns="sfields"
          :rows="activities"
          :search-options="{ enabled: true, skipDiacritics: true }"
          :pagination-options="{ enabled: true, mode: 'records' }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <!--     <button
                class="btn btn-danger btn-sm"
                @click="preview(props.row.code, props.row.title)"
              >
                <v-icon>mdi-checkbook</v-icon>&nbsp; Preview
              </button>
              &nbsp;-->
              <button
                class="btn btn-success btn-sm"
                style="padding-left: 20"
                @click="importS(props.row.path)"
              >
                <v-icon>mdi-application-import</v-icon>&nbsp; Import
              </button>
            </span>
            <span v-else-if="props.column.field == 'type'">
              <v-icon color="green">mdi-checkbook</v-icon>
            </span>
          </template>
        </vue-good-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["path", "order"],
  data() {
    return {
      dialog: false,
      standard: [],
      activities: [],
      newStandard: {},
      csystems: ["American System", "British System", "Egyptian National"],

      subjects: ["English", "Math", "Science", "Social Studies", "Arabic"],
      code: "",
      selectedTitle: "",
      sfields: [
        {
          field: "type",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "system",
          label: "System",
          sortable: true,
          class: "text-left"
        },
        {
          field: "source",
          label: "Source",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "cluster",
          label: "Cluster",
          sortable: true,
          class: "text-left"
        },
        {
          field: "code",
          label: "Code",
          sortable: true,
          class: "text-left"
        },

        {
          field: "title",
          label: "Standard",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "100px"
        }
      ]
    };
  },

  methods: {
    search() {
      firebase
        .firestore()
        .collection("standards")
        .where("system", "==", this.activities.system)
        .where("subject", "==", this.activities.subject)
        .orderBy("grade")
        .onSnapshot(querySnapshot => {
          this.activities = [];
          querySnapshot.forEach(doc => {
            this.activities.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              code: doc.data().code,
              system: doc.data().system,
              grade: doc.data().grade,
              subject: doc.data().subject,
              source: doc.data().source,
              anchor: doc.data().anchor,
              strand: doc.data().strand,
              cluster: doc.data().cluster,
              type: doc.data().type
            });
          });
        });
    },
    preview(code, title) {
      this.code = code;
      this.selectedTitle = title;
    },

    importS(path) {
      firebase
        .firestore()
        .doc(path)
        .get()
        .then(snapshot => {
          this.newStandard = {};
          this.newStandard = snapshot.data();

          firebase
            .firestore()
            .collection(this.path)
            .doc(this.newStandard.code)
            .set(this.newStandard)
            .then(() => {
              firebase
                .firestore()
                .collection(path + "/substandards")
                .onSnapshot(querySnapshot => {
                  querySnapshot.forEach(doc => {
                    this.options = doc.data();

                    firebase
                      .firestore()
                      .collection(
                        this.path +
                          "/" +
                          this.newStandard.code +
                          "/substandards"
                      )
                      .doc(this.options.code)
                      .set(this.options)
                      .then(() => {});
                  });
                });

              this.$bvToast.toast("Standard Imported", {
                title: "Standard Imported Successfully",
                variant: "success",
                solid: true
              });
            })
            .catch(error => {
              alert("Error adding document: ", error);
            });
        });
    }
  }
};
</script>
