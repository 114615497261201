<template>
  <b-row>
    <b-col cols="12">
      <v-card height="300px" flat outlined style="background-color:#d7e9f9">
        <v-card-title>
          <span style="margin-right:10px">Create New {{ this.source }}</span>
          <BestPractice
            image="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/pics%2Factivities.png?alt=media&token=0a2f00a5-63d7-4734-b63b-bb68a07375fb"
            text="Create as many questions as you can to make sure students practice enough after each video. Also make sure your questions cover all objectives. Each objective should be practiced enough."
          />
        </v-card-title>
        <!--Activity Type -->
        <v-card-text>
          <v-row style="text-align:center">
            <v-col cols="12">
              <v-btn-toggle v-model="toggle_multiple" color="red" light>
                <v-btn width="150px" height="80px" @click="video">
                  <v-icon>mdi-camera-outline</v-icon>
                  Video</v-btn
                >

                <v-btn width="150px" height="80px">
                  <v-icon>mdi-help-circle-outline</v-icon>
                  Question</v-btn
                >

                <v-btn width="150px" height="80px" @click="sheet">
                  <v-icon>mdi-file-powerpoint-outline </v-icon>
                  File Sheet</v-btn
                >

                <v-btn width="150px" height="80px" @click="live">
                  <v-icon>mdi-video-outline</v-icon>
                  Live</v-btn
                >

                <v-btn width="150px" height="80px" @click="Flipped">
                  <v-icon>mdi-orbit-variant </v-icon>
                  Random Questions</v-btn
                >
               
              </v-btn-toggle>
            </v-col>

            <!-- Questions Types -->
            <v-col cols="12" v-show="toggle_multiple == 1">
              <v-btn-toggle
                v-model="question_type"
                background-color="warning"
                color="black"
                light
              >
                <div
                  class="question"
                  @click="Question('Multiple Choice', 'mc')"
                >
                  <v-icon color="white">mdi-format-list-text</v-icon>
                  <br />
                  Multiple Choice
                </div>
                <div class="question" @click="Question('True/False', 'tf')">
                  <v-icon color="white">mdi-list-status</v-icon>
                  <br />
                  True/False
                </div>
                <div class="question" @click="Question('Complete', 'complete')">
                  <v-icon color="white">mdi-code-json</v-icon>
                  <br />
                  Complete
                </div>
                <div class="question" @click="Question('Essay', 'essay')">
                  <v-icon color="white">mdi-text-search</v-icon>
                  <br />
                  Essay
                </div>

                <div class="question" @click="Question('Speech', 'speech')">
                  <v-icon color="white">mdi-volume-high</v-icon>
                  <br />
                  Speech
                </div>
                <div
                  class="question"
                  @click="Question('Listening', 'listening')"
                >
                  <v-icon color="white">mdi-ear-hearing</v-icon>
                  <br />
                  Listening
                </div>
              </v-btn-toggle>
            </v-col>
            <!-- End Questions Types -->
          </v-row>
        </v-card-text>
        <!-- End Activity Type -->
        <template v-slot:footer> </template>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";

import katex from "katex";
import "katex/dist/katex.min.css";
import CreateVideo from "@/components/lms/activities/Create/Video.vue";
import CreateSheet from "@/components/lms/activities/Create/Sheet.vue";
import CreateLive from "@/components/lms/activities/Create/Live.vue";
import CreateFlipped from "@/components/lms/activities/Create/Flipped.vue";
import CreateExit from "@/components/lms/activities/Create/Exit.vue";
import CreateQuestion from "@/components/lms/activities/Create/Question.vue";
import BestPractice from "@/components/dna/BestPractice.vue";

export default {
  name: "AddActivity",
  props: ["order", "source"],
  components: { BestPractice },
  data() {
    return {
      e1: 1,
      toggle_multiple: "",
      question_type: "",
      thisOrder: 0,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
            ["image"],
            ["video"],
            ["formula"]
          ]
        }
      },
      ref: firebase.firestore().collection(this.$route.params.id + "/lesson/"),

      lesson: {},
      selected: null,
      types: [
        { value: "video", text: "Video" },
        { value: "Live Session", text: "Live Session" },
        { value: "question", text: "Question" },
        { value: "show", text: "Sheet File" },
        { value: "Flipped", text: "Flipped Classroom Questions" },
        { value: "Exit", text: "Exit Ticket Questions" }
      ],
      bloom: [
        { value: "Knowledge (Remembering)", text: "Knowledge (Remembering)" },

        {
          value: "Comprehension (Understanding)",
          text: "Comprehension (Understanding)"
        },
        {
          value: "Application (Transferring)",
          text: "Application (Transferring)"
        },
        { value: "Analysis (Relating)", text: "Analysis (Relating)" },
        { value: "Evaluation (Judging)", text: "Evaluation (Judging)" },
        { value: "Synthesis (Creating)", text: "Synthesis (Creating)" }
      ],

      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      difficulty: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" }
      ],
      qtype: [
        { value: "mc", text: "Multiple Choice" },
        { value: "tf", text: "True/False" },
        { value: "speech", text: "Speech" },
        { value: "complete", text: "Complete" },
        { value: "listening", text: "Listening" }
      ],
      standards: [],
      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  created() {},
  mounted() {
    window.katex = katex;
  },

  methods: {
    video() {
      this.$modal.show(
        CreateVideo,
        {
          path: String(this.$route.params.id) + "/lesson",
          order: this.order
        },
        { width: "50%", height: "750px" }
      );
    },
    sheet() {
      this.$modal.show(
        CreateSheet,
        {
          path: String(this.$route.params.id) + "/lesson",
          order: this.order
        },
        { width: "70%", height: "90vh" }
      );
    },

    live() {
      this.$modal.show(
        CreateLive,
        {
          path: this.$route.params.id,
          order: this.order
        },
        { width: "50%", height: "600px" }
      );
    },
    Flipped() {
      this.$modal.show(
        CreateFlipped,
        {
          path: this.$route.params.id,
          order: this.order
        },
        { width: "50%", height: "560px" }
      );
    },
    Exit() {
      this.$modal.show(
        CreateExit,
        {
          path: this.$route.params.id,
          order: this.order
        },
        { width: "50%", height: "560px" }
      );
    },
    Question(type, qtype) {
      this.$modal.show(
        CreateQuestion,
        {
          path: this.$route.params.id,
          order: this.order,
          type: type,
          target: "lesson",
          qtype: qtype
        },
        { width: "70%", height: "90vh" }
      );
    },

    nextStep() {
      if (this.toggle_multiple >= 0) {
        if (this.e1 < 3) {
          this.e1 = Number(this.e1) + 1;
        } else {
          this.e1 = 1;
        }
      } else {
        this.$swal("Select Activity Type First", "", "warning");
      }
    },
    backStep() {
      if (this.e1 > 1) this.e1 = Number(this.e1) - 1;
    },
    previewImage(event) {
      // this.uploadValue = 0;
      // this.picture = null;
      this.imageData = event;
    },

    onUpload() {
      if (this.files.size / 1048576 > 30) {
        alert("The File Is Bigger Than 30 MB");
      } else {
        this.picture = null;
        const storageRef = firebase
          .storage()
          .ref(
            "schools/" +
              this.$store.state.school +
              "/activities/" +
              Math.floor(Math.random() * 900000 + 1000000).toString()
          )
          .put(this.files);

        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              this.picture = url;
            });
          }
        );
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        this.lesson.order = Number(this.order) + 1;

        if (this.picture != null) {
          this.lesson.link = this.picture;
        }

        this.ref
          .add(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.lesson,
              this.$store.state.subject,
              "Activity"
            );

            this.lesson.title = "";
            this.lesson.icon = "";
            this.lesson.link = "";
            this.lesson.bloom = "";

            this.$bvToast.toast("Activity Created", {
              title: "Activity Created Successfully",
              variant: "success",
              solid: true
            });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    goToLive() {
      window.open("https://teams.microsoft.com", "_blank");
    }
  }
};
</script>
<style scoped>
.question {
  width: 110px;
  font-size: small;
  border-color: #de6c47;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}
body.modal-open {
  overflow: hidden;
}
</style>
