<template>
  <div class="app-container" style="padding-top:100px">
    <CountIconCard
      title="Employees"
      color="blue lighten-2"
      icon="mdi-badge-account-horizontal-outline"
      :path="'/schools/' + this.$store.state.school + '/employees'"
    ></CountIconCard>

    <v-card
      ><v-card-text>
        <upload-excel-component
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        />
        <br />
        <v-progress-linear
          v-if="progress"
          color="light-blue"
          height="20"
          :value="progress"
          striped
        ></v-progress-linear>
        <br />
        Rows Count: {{ tableData.length }}
        <br />

        <!-- <b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
        <b-button
          variant="success"
          style="color:#ffffff"
          href="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/employees.xlsx?alt=media&token=28d149f0-f9c2-443f-9c2d-84c36c7ab8a9"
          >Download Excel Template</b-button
        >
        <b-button @click="importData" style="margin-left:20px;"
          >Start Importing</b-button
        >

        <el-table
          :data="tableData"
          border
          highlight-current-row
          style="width: 100%;margin-top:20px;"
          empty-text="There Is No Data Yet"
        >
          <el-table-column
            v-for="item of tableHeader"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";
import CountIconCard from "@/components/dna/widgets/CountIconCard";

export default {
  name: "UploadExcel",
  components: { UploadExcelComponent, CountIconCard },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      employee: {},
      current: 0,
      progress: 0
    };
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    deleteOld() {
      // console.log("Start Deleting");
      // firebase
      //   .firestore()
      //   .collection("profiles")
      //   .where("school", "==", "dais")
      //   .onSnapshot(querySnapshot => {
      //     querySnapshot.forEach(doc => {
      //       doc.ref.delete().then(() => {
      //         console.log("Ok");
      //       });
      //     });
      //   });
    },

    importData: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        this.employee = {};
        this.employee = this.tableData[i];

        this.employee.firstname =
          this.tableData[i]["firstname"] == null
            ? " "
            : this.tableData[i]["firstname"].trim();

        this.employee.lastname =
          this.tableData[i]["lastname"] == null
            ? " "
            : this.tableData[i]["lastname"].trim();

        this.employee.nationalID =
          this.tableData[i]["nationalID"] == null
            ? " "
            : this.tableData[i]["nationalID"].trim();

        this.employee.job =
          this.tableData[i]["job"] == null
            ? " "
            : this.tableData[i]["job"].trim();

        this.employee.username =
          this.tableData[i]["username"] == null
            ? " "
            : this.tableData[i]["username"].trim().toLowerCase();

        this.employee.password = String(this.tableData[i]["password"]).trim();

        console.log(this.employee.username);
        console.log(this.employee);

        firebase
          .firestore()
          .collection("schools/" + this.$store.state.school + "/employees")
          .add(this.employee)
          .then(() => {
            // firebase
            //   .auth()
            //   .createUserWithEmailAndPassword(
            //     this.employee.username,
            //     this.employee.password
            //   )
            //   .then()
            //   .catch(err => {
            //     console.log(err);
            //   });

            //Create User
            console.log("Success Added");
            this.current++;
            this.progress = (this.tableData.length / this.current) * 100;
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  mounted() {
    // this.deleteOld();
    this.importData();
  }
};
</script>
