<template>
  <v-container>
    <v-row style="margin-top: -15px">
      <v-col cols="1">
        <v-avatar>
          <img :src="pic" />
        </v-avatar>
      </v-col>

      <v-col cols="3">
        <v-row>{{
          this.firstname + " " + (this.lastname == null ? "" : this.lastname)
        }}</v-row>
      </v-col>

      <v-col cols="2">
        <div v-for="(attempt, i) in attempts" :key="i" style="margin-bottom: 5px;">
          <v-chip small v-if="attempt.score > 50" color="green" dark style="margin-bottom: 5px;">
          {{ Number(attempt.score).toFixed(2) }} %</v-chip>
          <v-chip small v-else color="red" dark style="margin-bottom: 8px;"> {{ Number(attempt.score).toFixed(2) }} %</v-chip>
        </div>
      </v-col>
      <v-col cols="3">
        <v-btn
        v-for="(attempt, i) in attempts" :key="i"
          color="green"
          small
          style="margin-left: 20px;margin-bottom: 5px;"
          dark
          depressed
          @click="goToReport(i)"
        >
          <v-icon>mdi-finance</v-icon>
          Report</v-btn
        >
      
        </v-col>
        <v-col cols="3">
        <p v-for="(attempt, i) in attempts" :key="i" style="margin-top: 10px;"> {{ new Date(attempt.date).toLocaleDateString() }}</p>
        </v-col>
        <!-- <v-col cols="1">
        <v-btn
          color="red"
          small
          style="margin-left: 25px"
          dark
          depressed
          @click="deleteAtt()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
       
      </v-col> -->
    </v-row>

    <v-divider />
  </v-container>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "StudentRecord",
  components: {},
  props: [
    "student_id",
    "student",
    "firstname",
    "lastname",
    "image",
    "week",
    "title",
    "sclass",
    "path",

  ],
  data() {
    return {
      week_id: localStorage.getItem("weekId"),
      pic: "",
      activities: [],
      totalRight: [],
      totalWrong: [],
      submissions: [],
      attempts:[],
      score: 0,
    };
  },
  created() {
    console.log(this.student)
    firebase
      .firestore()
      .collection(this.student + "/flipped/" + this.week_id + "/attempts")
      .get()
      .then((querySnapshot) => {
        this.attempts = [];
        querySnapshot.forEach((doc) => {
          this.attempts.push(doc.data());
        })
      })
    firebase
      .firestore()
      .collection(this.student + "/flipped/" + this.week_id + "/answers")
      .get()
      .then((querySnapshot) => {
        this.submissions = [];
        querySnapshot.forEach((doc) => {
          this.submissions.push({
            key: doc.id,
            path: doc.ref.path,
            title: doc.data().title,
            type: doc.data().qtype,
            answer: doc.data().answer,
            date: doc.data().date,
          });

          if (doc.data().answer == "Right") {
            this.totalRight.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date,
            });
          } else if (doc.data().answer == "Wrong") {
            this.totalWrong.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              type: doc.data().qtype,
              answer: doc.data().answer,
              date: doc.data().date,
            });
          }

          this.score = (
            (this.totalRight.length /
              (this.totalRight.length + this.totalWrong.length)) *
            100
          ).toFixed(2);
        });
      });
  },
  methods: {
    calc() {},
    goToReport(i) {
      router.push({
        name: "ProgressReport",
        params: {
          student: this.student,
          week: this.week_id,
          name:
            this.firstname + " " + (this.lastname == null ? "" : this.lastname),
          title: this.title,
          class: this.sclass,
          attempt:i+1,
        },
      });
    },


    deleteAtt() {
      this.$confirm({
        message: "Are you sure You want to Delete this Attemp ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(this.student + "/flipped/" + this.week_id )
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
  },
};
</script>
