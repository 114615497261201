<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-form @submit.stop.prevent="onSubmit">
          <!-- School Name -->
          <v-col class="d-flex" cols="12" sm="6">
            <b-select
              label="School"
              v-model.trim="grade.school"
              :options="schools"
              readonly
            >
            </b-select>
          </v-col>

          <!-- Grade Order -->

          <v-col class="d-flex" cols="12" sm="2">
            <v-text-field
              type="number"
              label="Grade Order"
              v-model.trim="grade.order"
            ></v-text-field>
          </v-col>
          <!-- Grade Name -->
          <v-col class="d-flex" cols="12" sm="6">
            <v-text-field
              label="Grade Title"
              v-model.trim="grade.title"
              required
              readonly
            ></v-text-field>
          </v-col>

          <v-switch
            inset
            v-model="grade.openadmission"
            label="Open Admission"
          ></v-switch>
          <!-- Submit Form -->

          <b-card-footer>
            <b-button type="submit" variant="primary">Edit Grade</b-button>
          </b-card-footer>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "EditGrade",
  params: ["path"],
  data() {
    return {
      grade: {},
      mirror: "",
      schools: {},
      key: this.$route.params.id,
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),
      gradeRef: firebase.firestore().doc(this.$route.params.path),
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.grade.order = parseInt(this.grade.order);

      firebase
        .firestore()
        .doc(this.$route.params.path)
        .set(this.grade)
        .then(() => {
          router.back();
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
  },
  created() {
    this.gradeRef.get().then((doc) => {
      if (doc.exists) {
        this.grade = doc.data();
      } else {
        alert("No such document!");
      }
    });

    this.schoolsRef.onSnapshot((querySnapshot) => {
      this.schools = [];
      querySnapshot.forEach((doc) => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title,
        });
      });
    });
  },
};
</script>
