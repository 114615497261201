<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Medical</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <v-card>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Grade Name -->

            <!-- <v-col cols="12" md="6">
                <v-text-field
                  
                  :counter="10"
                  label="Reason"
                  v-model.trim="record.reason"
                  required
                ></v-text-field>
              </v-col>-->

            <v-col class="d-flex" cols="12" sm="12">
              <v-select
                :items="reasons"
                v-model.trim="record.reason"
                label="Reason of referral"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="12">
              <v-select
                :items="action"
                v-model.trim="record.action"
                label="Prior action"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="12">
              <v-select
                :items="adminaction"
                v-model.trim="record.adminaction"
                label="Administrative action"
              ></v-select>
            </v-col>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary"
                >Add Discipline</b-button
              >
            </b-card-footer>
          </b-form>
        </v-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddDiscipline",
  props: ["id"],
  data() {
    return {
      ref: firebase.firestore().collection("/profiles/" + this.id + "/medical"),
      record: {},
      timestamp: "",
      reasons: [
        { value: "Unacceptable Language", text: "Unacceptable Language" },
        { value: "Disruptive Horseplay", text: "Disruptive Horseplay" },
        { value: "Fighting", text: "Fighting" },
        {
          value: "Willful Disrespect to School Official",
          text: "Willful Disrespect to School Official"
        },
        {
          value: "Willful Damage to School Property",
          text: "Willful Damage to School Property"
        },
        {
          value: "Willful Damage to Personal Property",
          text: "Willful Damage to Personal Property"
        },
        {
          value: "Skipping Class or Unauthorized Area",
          text: "Skipping Class or Unauthorized Area"
        },
        { value: "Bullying or Hazing", text: "Bullying or Hazing" },
        {
          value: "Willful Refusal to Follow Directions or Instructions",
          text: "Willful Refusal to Follow Directions or Instructions"
        },
        {
          value:
            "Disruption in: Cafeteria / Classroom / Hallway / Restroom / Outside",
          text:
            "Disruption in: Cafeteria / Classroom / Hallway / Restroom / Outside"
        },
        {
          value: "Possession of a Controlled",
          text: "Possession of a Controlled"
        },
        { value: "Non-Controlled Substance", text: "Non-Controlled Substance" },
        { value: "Oral Tobacco / Smoking", text: "Oral Tobacco / Smoking" },
        { value: "Chronic Tardiness", text: "Chronic Tardiness" },
        {
          value:
            "Under Influence of Drugs / Alcohol – Law enforcement intervention",
          text:
            "Under Influence of Drugs / Alcohol – Law enforcement intervention"
        },
        {
          value: "Possession of a Weapon – Law enforcement intervention",
          text: "Possession of a Weapon – Law enforcement intervention"
        },
        { value: "Dress Code Violation", text: "Dress Code Violation" }
      ],

      action: [
        { value: "Behavior Card Deduction", text: "Behavior Card Deduction" },
        { value: "Verbal Warning", text: "Verbal Warning" },
        { value: "Silent Lunch", text: "Silent Lunch" },
        { value: "Conference with Parents", text: "Conference with Parents" },
        { value: "Conference with Student", text: "Conference with Student" },
        {
          value: "In-Team / In-Grade Displacement",
          text: "In-Team / In-Grade Displacement"
        },
        { value: "After-School Detentio", text: "After-School Detentio" }
      ],
      adminaction: [
        {
          value: "Consultation with Student in Office",
          text: "Consultation with Student in Office"
        },
        {
          value: "Warning Issued for Offense",
          text: "Warning Issued for Offense"
        },
        {
          value: "In-School Suspension (ISS)",
          text: "In-School Suspension (ISS)"
        },
        {
          value: "Out-of-School Suspension (OSS)",
          text: "Out-of-School Suspension (OSS)"
        },
        {
          value: "After-School Detention (ASD)",
          text: "After-School Detention (ASD)"
        },
        { value: "Saturday School (SS)", text: "Saturday School (SS)" },
        {
          value: "Guidance Counselor Referral (GCR)",
          text: "Guidance Counselor Referral (GCR)"
        },
        { value: "Compensation for Damages", text: "Compensation for Damages" },
        { value: "Campus Police Referral", text: "Campus Police Referral" }
      ]
    };
  },

  created() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.record.date = this.timestamp;

      this.ref
        .add(this.record)
        .then(() => {
          this.record.reason = "";
          this.record.action = "";
          this.record.adminaction = "";

          this.$bvToast.toast("Record Created", {
            title: "Record Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
