<template>
  <b-row>
    <b-col cols="12">
      <b-card-group deck>
        <b-card
          bg-variant="primary"
          text-variant="white"
          header="Jobs"
          class="text-center"
        >
          <b-card-text>
            <h1>{{ jobs.length }}</h1>
          </b-card-text>
        </b-card>
        <b-card
          bg-variant="success"
          text-variant="white"
          header="Full Time"
          class="text-center"
        >
          <b-card-text>
            <h1>0</h1>
          </b-card-text>
        </b-card>
        <b-card
          bg-variant="danger"
          text-variant="white"
          header="Part Time"
          class="text-center"
        >
          <b-card-text>
            <h1>0</h1>
          </b-card-text>
        </b-card>
      </b-card-group>
      <br />
      <!-- Activities Hub -->
      <JobsHub :path="this.$route.params.id"></JobsHub>
      <!-- End Activities Hub -->

      <AddJob></AddJob>
      <br />
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-calendar-month</v-icon>
          <span class="title font-weight-light">Jobs List</span>
        </v-card-title>

        <p class="card-text">
          <vue-good-table
            :columns="fields"
            :rows="jobs"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteboard(props.row.key, props.row.title)"
                >
                  <b-icon-trash></b-icon-trash>&nbsp; Delete
                </button>
              </span>
            </template>
          </vue-good-table>
        </p>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import AddJob from "./AddJob";
import JobsHub from "@/components/hub/HRJobsHub";

export default {
  name: "JobsList",
  components: {
    AddJob,
    JobsHub
  },

  data() {
    return {
      fields: [
        {
          field: "title",
          label: "Job Title",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "Action", class: "text-center" }
      ],
      jobs: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/jobs")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.jobs = [];
      querySnapshot.forEach(doc => {
        this.jobs.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title
        });
      });
    });
  },
  methods: {
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("/schools/" + this.$store.state.school + "/jobs")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
    // details(id, title) {
    //   router.push({ name: "SubjectsList", params: { id: id, title: title } });
    // }
  }
};
</script>
