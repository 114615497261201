<template>
  <v-container>
    <v-toolbar color="blue darken-1" dark>
      <v-toolbar-title>
        <v-icon left size="40px">mdi-finance</v-icon>Progress
      </v-toolbar-title>
    </v-toolbar>

    <vue-good-table
      :columns="fields"
      :rows="progress"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- <template v-slot:cell(actions)="row"> -->

        <span v-if="props.column.field == 'percent'"
          >{{
            (props.row.right / (props.row.right + props.row.wrong)) * 100
          }}
          %</span
        >
      </template>
    </vue-good-table>
  </v-container>
</template>

<script>
import firebase from "@/Firebase";
// import router from "@/router";
// import moment from "moment";

export default {
  name: "Progress-page",
  components: {},
  props: ["id"],

  data() {
    return {
      fields: [
        {
          field: "date",
          label: "Date",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "week",
          label: "Week",
          sortable: true,
          class: "text-left"
        },
        {
          field: "type",
          label: "Activity",
          sortable: true,
          class: "text-left"
        },
        {
          field: "right",
          label: "Right",
          sortable: true,
          class: "text-left"
        },
        {
          field: "wrong",
          label: "Wrong",
          sortable: true,
          class: "text-left"
        },
        {
          field: "percent",
          label: "Percent",
          sortable: true,
          class: "text-left"
        }
      ],
      progress: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection("profiles")
        .doc(this.id)
        .collection("activities")
        .orderBy("date")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.progress = [];
      querySnapshot.forEach(doc => {
        this.progress.push({
          key: doc.id,
          path: doc.ref.path,
          date: doc.data().date,
          type: doc.data().type,
          week: doc.data().week_title,
          subject: doc.data().subject_title,
          right: doc.data().right,
          wrong: doc.data().wrong
        });
      });
    });
  },
  methods: {
    // deleteboard(id, title) {
    //   this.$confirm({
    //     message: "Are you sure You want to Delete " + title + " ?",
    //     button: {
    //       no: "No",
    //       yes: "Yes"
    //     },
    //     /**
    //      * Callback Function
    //      * @param {Boolean} confirm
    //      */
    //     callback: confirm => {
    //       if (confirm) {
    //         firebase
    //           .firestore()
    //           .collection("/schools/" + this.$store.state.school + "/collection")
    //           .doc(id)
    //           .delete()
    //           .then(() => {})
    //           .catch(error => {
    //             alert("Error removing document: ", error);
    //           });
    //       }
    //     }
    //   });
    // }
  }
};
</script>

<style>
.table {
  width: 96%;
  margin: 0 auto;
}
</style>
