<template>
  <v-footer padless>
    <v-row class="pt-2 pr-10 pb-2 ">
      <v-spacer></v-spacer>
      <span style="font-size:14px">
        {{ new Date().getFullYear() }} — <strong>Cerebral 21</strong></span
      >
      <v-spacer />
      <div class="align-self-center">
        <v-avatar
          color="pink"
          size="30"
          style="margin-right:5px; cursor: pointer;"
          @click="goToPage('Room')"
        >
          <v-icon dark size="18">
            mdi-message
          </v-icon>
        </v-avatar>

        <!-- <span
          style="font-size:12px; cursor: pointer;"
          @click="goToPage('Room')"
        >
          Messaging Center</span
        > -->
      </div>
    </v-row>
  </v-footer>
</template>

<script>
import router from "@/router";

export default {
  name: "Footer-page",
  data() {
    return {
      domain: ""
    };
  },
  created() {
    this.domain = window.location.host;
  },
  methods: {
    goToPage(page) {
      router.push({
        name: page
      });
    }
  }
};
</script>
