<template>
  <v-expansion-panel style="margin-bottom:10px;">
    <v-card flat outlined height="20px" dark style="background-color:#eee" tile>
    </v-card>
    <v-expansion-panel-header expand-icon="" class="def">
      <v-row>
        <v-col cols="1">
          <img
            src="@/assets/calendarindex.png"
            width="100%"
            style="margin-bottom:10px;"
          />
        </v-col>
        <v-col cols="2" style="text-align:center">
          <h3 style="color:#153048">{{ this.order }}</h3>

          <small style="color:green"> {{ this.breakdown }}</small>
        </v-col>
        <v-col cols="9">
          <v-row>
            <small style="color:#989898; margin-bottom:20px;">
              {{ this.date }}</small
            ><v-spacer></v-spacer>
            <v-chip v-if="this.section">{{ this.section }}</v-chip>
          </v-row>
          <br />

          <p>{{ this.title }}</p>

          <img
            src="@/assets/hidden.png"
            width="20px"
            v-if="this.hidden == true"
          />

          <v-row
            ><v-col cols="12">
              <v-chip
                label
                color="success"
                class="ma-2"
                small
                v-if="this.current == true"
              >
                <v-icon left size="20">
                  mdi-check-outline
                </v-icon>
                Current Week</v-chip
              >
              <v-chip
                label
                color="info"
                class="ma-2"
                small
                v-if="this.revision == true"
              >
                <v-icon left size="20">
                  mdi-history
                </v-icon>
                Revision Week</v-chip
              >

              <v-chip
                label
                color="#FF6D00"
                style="color:#fff"
                class="ma-2"
                small
                v-if="this.summer == true"
              >
                <v-icon left size="20">
                  mdi-umbrella-outline
                </v-icon>
                Summer Work</v-chip
              >
              <v-chip
                label
                color="#8E24AA"
                style="color:#fff"
                class="ma-2"
                small
                v-if="this.enrichment == true"
              >
                <v-icon left size="20">
                  mdi-alpha-r-box
                </v-icon>
                Remedial</v-chip
              >

              <v-chip
                label
                color="#424242
"
                style="color:#fff"
                class="ma-2"
                small
                v-if="this.orientation == true"
              >
                <v-icon left size="20">
                  mdi-alpha-o-box
                </v-icon>
                Orientation Week</v-chip
              ></v-col
            ></v-row
          >

          <div>
            <v-row>
              <v-spacer />

              <v-btn
                fab
                dark
                x-small
                outlined
                color="red"
                size="sm"
                @click="deleteWeek(path, title)"
                v-if="master == true"
              >
                <v-icon dark>mdi-trash-can-outline</v-icon>
              </v-btn>

              <v-btn
                style="margin-left:10px"
                fab
                dark
                x-small
                outlined
                color="blue"
                @click="Edit(path, revision, enrichment)"
                v-if="master == true"
              >
                <v-icon dark>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content color="#eaf4fd">
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="weekorder" />

          <v-btn
            x-small
            color="success"
            block
            depressed
            @click="SaveOrder(path, weekorder)"
            >Save Order</v-btn
          >
        </v-col>
        <v-col cols="10">
          <v-slide-group multiple show-arrows v-if="this.revision != true">
            <v-slide-item>
              <v-card
                height="100"
                width="100"
                flat
                tile
                outlined
                hover
                @click="goToResources(path, title)"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/resources.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />
                  Resources
                </v-row>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                height="100"
                width="150"
                flat
                tile
                outlined
                hover
                @click="goToLesson(path, title,breakdown)"
              >
                <v-col class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/prep.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />
                 <br/>
                Pre-Teaching
                </v-col>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                height="100"
                width="100"
                flat
                tile
                outlined
                hover
                @click="goToBank(path, title)"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/tools.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />

                  Questions
                </v-row>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                height="100"
                width="100"
                flat
                tile
                outlined
                hover
                @click="goToAssign(path, title,breakdown)"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/question.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />
                  Assignments
                </v-row>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                height="100"
                width="100"
                flat
                tile
                outlined
                hover
                @click="goToBlended(path, title, id)"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/blended.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />
                  Practice
                </v-row>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                height="100"
                width="100"
                flat
                tile
                outlined
                hover
                @click="goToParticipation(path, title, id,breakdown)"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/part.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />
                  Participation
                </v-row>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                height="100"
                width="100"
                flat
                outlined
                tile
                hover
                @click="goToReport(path, title, id)"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <img
                    src="@/assets/subject/report.png"
                    width="40px"
                    style="margin-bottom:10px;"
                  />
                  Reports
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <div v-else>
            <!-- Review Week -->

            <v-slide-group multiple show-arrows>
              <v-slide-item>
                <a :href="this.packs" target="_blank">
                  <v-card
                    class="ma-4"
                    height="100"
                    width="200"
                    flat
                    outlined
                    hover
                  >
                    <v-row class="fill-height" align="center" justify="center">
                      <img
                        src="@/assets/sections.png"
                        width="40px"
                        style="margin-bottom:10px;"
                      />
                      Review Packs
                    </v-row>
                  </v-card>
                </a>
              </v-slide-item>

              <v-slide-item>
                <a :href="this.answers" target="_blank">
                  <v-card
                    class="ma-4"
                    height="100"
                    width="200"
                    flat
                    outlined
                    hover
                  >
                    <v-row class="fill-height" align="center" justify="center">
                      <img
                        src="@/assets/assignments.png"
                        width="40px"
                        style="margin-bottom:10px;"
                      />
                      Answer Key
                    </v-row>
                  </v-card>
                </a>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
export default {
  name: "Week-page",
  props: [
    "title",
    "order",
    "hidden",
    "breakdown",
    "date",
    "revision",
    "id",
    "path",
    "current",
    "packs",
    "summer",
    "enrichment",
    "orientation",
    "answers",
    "section"
  ],
  data() {
    return {
      weekorder: this.order,
      mirror: this.$store.state.mirror,
      master: this.$store.state.master
    };
  },

  created() {},

  methods: {
    goToLesson(path, title,breakdown) {
      localStorage.setItem("lessonBreakdown", breakdown);
      router.push({
        name: "ActivitiesList",
        params: {
          id: path,
          title: title
        }
      });
    },
    goToBlended(path, title, weekid) {
      localStorage.setItem("week", title);
      localStorage.setItem("weekpath", path);
      localStorage.setItem("weekId", weekid);

      router.push({
        name: "BlendedControl",
        params: {
          path: path,
          title: title
        }
      });
    },
    goToParticipation(path, title, weekid,breakdown) {
      localStorage.setItem("week", title);
      localStorage.setItem("weekpath", path);
      localStorage.setItem("weekId", weekid);
      localStorage.setItem("lessonBreakdown", breakdown);
      router.push({
        name: "Participation",
        params: {
          path: path,
          title: title
        }
      });
    },
    goToResources(path, title) {
      localStorage.setItem("weekpath", path);
      localStorage.setItem("weektitle", title);
      router.push({
        name: "Resources",
        params: {
          id: path,
          title: title
        }
      });
    },
    goToAssign(path, title,breakdown) {
      localStorage.setItem("weekpath", path);
      localStorage.setItem("lessonBreakdown", breakdown);
      router.push({
        name: "AssignmentsList",
        params: {
          path: path,
          title: title
        }
      });
    },

    goToBank(path, title) {
      router.push({
        name: "BankList",
        params: {
          id: path,
          title: title
        }
      });
    },
    goToReport(path, title, weekid) {
      localStorage.setItem("weekId", weekid);
      router.push({
        name: "LessonReport",
        params: {
          path: path,
          title: title
        }
      });
    },

    Edit(path, revision, enrichment) {
      if (revision == true)
        router.push({
          name: "EditRevision",
          params: {
            path: path,
            subject: this.path
          }
        });
      else if (enrichment == true)
        router.push({
          name: "EditEnrichment",
          params: {
            path: path,
            subject: this.path
          }
        });
      else
        router.push({
          name: "EditWeek",
          params: {
            path: path,
            subject: this.path
          }
        });
    },

    SaveOrder(path, o) {
      firebase
        .firestore()
        .doc(path)
        .update({ order: Number(o) })
        .then(() => {})
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    deleteWeek(path, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .doc(path)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  title,
                  this.path,
                  "Lesson"
                );
                this.$bvToast.toast("Week Deleted", {
                  title: "Week Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.def {
  border-style: solid;
  border-color: #eee;
}
.hid {
  border-style: dotted;
  border-color: #d0d0d0;
}
</style>
