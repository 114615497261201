<template>
  <b-row>
    <v-col cols="12">
      <v-card height="130px" flat outlined style="background-color:#d7e9f9">
        <v-card-title style="margin-bottom:-40px">
          <span style="margin-right:10px">Create New Question</span>
          <BestPractice
            image="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/pics%2Factivities.png?alt=media&token=0a2f00a5-63d7-4734-b63b-bb68a07375fb"
            text="Create as many questions as you can to make sure students practice enough after each video. Also make sure your questions cover all objectives. Each objective should be practiced enough."
          />
        </v-card-title>
        <!--Activity Type -->
        <v-card-text>
          <v-row style="text-align:center">
            <v-col cols="12">
              <v-btn-toggle
                v-model="question_type"
                background-color="warning"
                color="black"
                light
              >
                <div
                  class="question"
                  @click="Question('Multiple Choice', 'mc')"
                >
                  <v-icon color="white">mdi-format-list-text</v-icon>
                  <br />
                  Multiple Choice
                </div>
                <div class="question" @click="Question('True/False', 'tf')">
                  <v-icon color="white">mdi-list-status</v-icon>
                  <br />
                  True/False
                </div>
                <div class="question" @click="Question('Complete', 'complete')">
                  <v-icon color="white">mdi-code-json</v-icon>
                  <br />
                  Complete
                </div>
                <div class="question" @click="Question('Essay', 'essay')">
                  <v-icon color="white">mdi-text-search</v-icon>
                  <br />
                  Essay
                </div>

                <div class="question" @click="Question('Speech', 'speech')">
                  <v-icon color="white">mdi-volume-high</v-icon>
                  <br />
                  Speech
                </div>
                <div
                  class="question"
                  @click="Question('Listening', 'listening')"
                >
                  <v-icon color="white">mdi-ear-hearing</v-icon>
                  <br />
                  Listening
                </div>
                <div class="question" @click="Question('Passage', 'passage')">
                  <v-icon color="white">mdi-text-box-check-outline</v-icon>
                  <br />
                  Passage
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import katex from "katex";
import "katex/dist/katex.min.css";
import CreateQuestion from "@/components/lms/subjects/exambank/Question.vue";
import BestPractice from "@/components/dna/BestPractice.vue";

// import router from "../router";

export default {
  name: "AddQuestion",
  props: ["path"],
  components: { BestPractice },
  data() {
    return {
      question_type: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
            ["image"],
            ["video"],
            ["formula"]
          ]
        }
      },
      ref: firebase.firestore().collection(this.path + "/examquestions/"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      lesson: {},
      selected: null,
      types: [
        { value: "video", text: "Video" },
        { value: "question", text: "Question" },
        { value: "show", text: "Lesson Documents" }
      ],
      bloom: [
        { value: "Remembering", text: "Remembering" },
        {
          value: "Understanding",
          text: "Understanding"
        },
        {
          value: "Applying",
          text: "Applying"
        },
        { value: "Analysing", text: "Analysing" },
        { value: "Evaluation", text: "Evaluation" },
        { value: "Creating", text: "Creating" }
      ],
      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      difficulty: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" }
      ],
      qtype: [
        { value: "mc", text: "Multiple Choice" },
        { value: "tf", text: "True/False" },
        { value: "speech", text: "Speech" },
        { value: "complete", text: "Complete" },
        { value: "listening", text: "Listening" }
      ],
      standards: []
    };
  },
  mounted() {
    window.katex = katex;
  },

  created() {
    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.data().code
        });
      });
    });
  },
  methods: {
    Question(type, qtype) {
      this.$modal.show(
        CreateQuestion,
        {
          path: this.path,
          order: this.order,
          type: type,
          target: "examquestions",
          qtype: qtype
        },
        { width: "70%", height: "90vh" }
      );
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.lesson.title != null) {
        this.ref
          .add(this.lesson)
          .then(() => {
            this.lesson.title = "";
            this.lesson.bloom = "";
            this.lesson.answer = "";
            this.lesson.qtype = "";
            this.lesson.difficulty = "";
            this.$bvToast.toast("Question Created", {
              title: "Question Created Successfully",
              variant: "success",
              solid: true
            });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  }
};
</script>

<style scoped>
.question {
  width: 110px;
  font-size: small;
  border-color: #de6c47;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}
body.modal-open {
  overflow: hidden;
}
</style>
