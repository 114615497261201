<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle.accordion-1 variant="info"
        >Click Here To Add New Status</b-button
      >
      <b-collapse id="accordion-1" class="mt-2">
        <b-card>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Grade Name -->

            <v-col cols="12" md="4">
              <v-text-field
                label="Status Title"
                v-model.trim="status.title"
                required
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                :items="types"
                v-model.trim="status.type"
                label="Status Type"
              ></v-select>
            </v-col>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add Status</b-button>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "../router";

export default {
  name: "AddStatus",
  props: ["student", "grade"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection(
          "/schools/" + this.$store.state.school + "/admission_status"
        ),
      status: {},
      types: [
        { value: "Accepted", text: "Accepted" },
        { value: "Progressing", text: "Progressing" },
        { value: "Rejected", text: "Rejected" }
      ],

      imageData: null,
      picture: null,
      uploadValue: 0
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.ref
        .add(this.status)
        .then(() => {
          this.status.title = "";
          this.$bvToast.toast("Status Created", {
            title: "Status Created Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>

<style></style>
