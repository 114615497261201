<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title
          ><v-icon left size="40px">mdi-account-cog-outline</v-icon>Fleet
          Settingg</v-card-title
        >

        <b-card-group deck>
          <SettingCard
            title="Drivers"
            icon="mdi-card-account-details-outline"
            link="Drivers"
          ></SettingCard>
          <SettingCard
            title="Supervisors"
            icon="mdi-human-female-boy"
            link="Supervisors"
          ></SettingCard>
          <SettingCard title="Routes" icon="mdi-routes" link="#"></SettingCard>
        </b-card-group>
      </v-card>
    </b-col>
  </b-row>
</template>
<script>
import SettingCard from "@/components/dna/widgets/SettingCard";
export default {
  name: "FleetSetting",
  components: { SettingCard }
};
</script>
