<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn  btn-sm"
        dark
        x-small
        color="purple"
        depressed
        v-bind="attrs"
        block
        v-on="on"
      >
        <v-icon size="15">mdi-history</v-icon>Override
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-history</v-icon>Override
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Right" v-model="right" type="number" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Wrong" v-model="wrong" type="number" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Missed" v-model="missed" type="number" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red darken-1" text @click="dialog = false">Back</v-btn>
        <v-btn color="green darken-1" text @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";

export default {
  name: "Override-page",
  props: ["path", "type", "week"],
  components: {},
  data() {
    return {
      answers: [],
      right: 0,
      wrong: 0,
      missed: 0,
      dialog: false
    };
  },
  created() {},
  methods: {
    save() {
      firebase
        .firestore()
        .doc(this.path)
        .set({ right: this.right, wrong: this.wrong, missed: this.missed })
        .then(() => {
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
