<template>
  <div>
    <v-row>
      <v-col cols="3" :style="'background-color:' + this.color">
        <v-row>
          <v-card
            :min-height="this.weight * 10"
            width="200px"
            dark
            tile
            flat
            :style="'background-color:' + this.color"
          >
            <v-card-title>{{ this.weight }}%</v-card-title>
            <v-card-subtitle style="color:#ffffff">
              {{ this.title }}
            </v-card-subtitle></v-card
          >
        </v-row>
      </v-col>
      <v-col cols="7" :style="'background-color:' + this.fadecolor">
        {{ this.grade }} Current Participation Grade
        <br />

        {{ this.heights }} Total Grade
      </v-col>
      <v-col cols="2" :style="' text-align:center; align-self: center;'">
        {{ this.title }}<br />
        <h3>
          {{
            this.grade == 0
              ? "0"
              : ((this.grade / this.heights) * 100).toFixed(2)
          }}
          %
        </h3>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>
<script>
import firebase from "@/Firebase";

export default {
  name:"part--page",
  components: {},
  props: ["title", "weight", "subject", "color", "fadecolor", "student"],

  data() {
    return {
      participation: [],
      totallesson:0,
      weeks: [],
      grade: 0,
      heights: 0,
      subjectgrade: 0,
      subjectpath: this.$store.state.originalsubject,
      subjecttitle: this.subject,
      studentpath: this.student
    };
  },

  created() {
    this.participation = [];
    this.grade = 0;
    this.heights=0
    this.subjectgrade = 0;
    firebase
      .firestore()
      .collection(this.studentpath + "/participation")
      .where("subject", "==", this.subject)
      .where("breakdown","==",this.$vnode.key)
      .get()
      .then(querySnapshot => {
        console.log(querySnapshot.docs.length)
        querySnapshot.forEach(doc => {
          this.participation.push({
            key: doc.id,
            date: doc.data().date,
            grade: doc.data().grade
          });
          this.grade = this.grade + doc.data().grade;
        });
      });

    firebase
      .firestore()
      .doc(this.subjectpath)
      //.where("breakdown", "==", this.$store.state.breakdown)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.data()) {
          this.subjectgrade = querySnapshot.data().participation;
          //this.heights = this.participation.length * this.subjectgrade;

          //! Flag As completed
          this.$store.commit("weightcount", this.$store.state.weightcount + 1);

          firebase
            .firestore()
            .collection(this.subjectpath + "/weeks")
            .where("hidden", "==", false)
            .get()
            .then(querySnapshot => {
              this.heights = querySnapshot.docs.length * Number(this.subjectgrade);
              console.log(querySnapshot.docs.length)
              this.$store.commit("gradebook", {
            title: "Participation",
            grade: (this.grade / this.heights) * 100,
            weight: this.weight
          });
            });
        } else {
          //! Flag As completed
          this.$store.commit("weightcount", this.$store.state.weightcount + 1);

          this.$store.commit("gradebook", {
            title: "Participation",
            grade: 0,
            weight: this.weight
          });
        }
      });
  }
};
</script>
