/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie';
import SecureLS from "secure-ls";
import createMultiTabState from 'vuex-multi-tab-state';


const ls = new SecureLS({ isCompression: false });


Vue.use(Vuex);

export default new Vuex.Store({
    name: "store",

    state: {

        subject: "",
        subjectName: "",
        thisGrade: "",
        thisBranch: "",
        job: "",
        breakdown:"",
        rules: [],
        branchData:{},

        school: "",

        schoolName: "",
        schoolDomain: "",
        firstname: "",
        lastname: "",
        username: "",
        image: "",

        userID: "",
        grade: "",
        icon: "",

        //! Subject
        master: "",
        control: "",
        mirror: "",
        subjectTitle: "",
        thisGrade: "",
        gradePath: "",
        thisBranch: "",
        originalsubject: "",


        //! Report
        reportbranch: "",
        reportgrade: "",
        reportpath: "",
        reportmirror: "",
        reporttitle: "",



        weightcount: 0,
        gradebook: [],


        weighttitle: "",
        weightpre: 0,
        weightcount: 0,



        logo: localStorage.getItem("logo"),
        user: {
            loggedIn: false,
            data: null
        },

        loggedIn: localStorage.getItem("loggedIn")
    },
    plugins: [
        createMultiTabState({ statesPaths: ['rules'] }),


        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],


    getters: {
        loggedIn: state => state.loggedIn,
        user(state) {
            return state.user;
        },




    },
    mutations: {




        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        rules: (state, value) =>
            value ? (state.rules = value) : (state.rules = []),

        school: (state, value) =>
            value ? (state.school = value) : (state.school = ""),
        userID: (state, value) =>
            value ? (state.userID = value) : (state.userID = ""),
            branchData: (state, value) =>
            value ? (state.branchData = value) : (state.branchData = {}),

        //! Subject
        master: (state, value) =>
            value ? (state.master = value) : (state.master = ""),
        control: (state, value) =>
            value ? (state.control = value) : (state.control = ""),
        mirror: (state, value) =>
            value ? (state.mirror = value) : (state.mirror = ""),
        subjectTitle: (state, value) =>
            value ? (state.subjectTitle = value) : (state.subjectTitle = ""),
        thisGrade: (state, value) =>
            value ? (state.thisGrade = value) : (state.thisGrade = ""),
        subject: (state, value) =>
            value ? (state.subject = value) : (state.subject = ""),
        gradePath: (state, value) =>
            value ? (state.gradePath = value) : (state.gradePath = ""),
        thisBranch: (state, value) =>
            value ? (state.thisBranch = value) : (state.thisBranch = ""),
        originalsubject: (state, value) =>
            value ? (state.originalsubject = value) : (state.originalsubject = ""),
        job: (state, value) =>
            value ? (state.job = value) : (state.job = ""),
            breakdown: (state, value) =>
            value ? (state.breakdown = value) : (state.breakdown = ""),

        firstname: (state, value) =>
            value ? (state.firstname = value) : (state.firstname = ""),
        image: (state, value) =>
            value ? (state.image = value) : (state.image = ""),
        lastname: (state, value) =>
            value ? (state.lastname = value) : (state.lastname = ""),
        username: (state, value) =>
            value ? (state.username = value) : (state.username = ""),
        schoolName: (state, value) =>
            value ? (state.schoolName = value) : (state.schoolName = ""),



        //! Report
        reportbranch: (state, value) =>
            value ? (state.reportbranch = value) : (state.reportbranch = ""),
        reportgrade: (state, value) =>
            value ? (state.reportgrade = value) : (state.reportgrade = ""),
        reportpath: (state, value) =>
            value ? (state.reportpath = value) : (state.reportpath = ""),
        reportmirror: (state, value) =>
            value ? (state.reportmirror = value) : (state.reportmirror = ""),
        reporttitle: (state, value) =>
            value ? (state.reporttitle = value) : (state.reporttitle = ""),



        weightcount: (state, value) =>
            value ? (state.weightcount = value) : (state.weightcount = 0),

        cleargradebook(state) {
            state.gradebook = [];
        },
        gradebook(state, value) {
            value ? (state.gradebook.push(value)) : (state.gradebook = []);
        },



        //! Weights For Report Card
        weighttitle: (state, value) =>
            value ? (state.weighttitle = value) : (state.weighttitle = ""),
        weightpre: (state, value) =>
            value ? (state.weightpre = value) : (state.weightpre = ""),

        weightcount: (state, value) =>
            value ? (state.weightcount = value) : (state.weightcount = 0),





    },
    actions: {



        fetchUser({
            commit
        }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                commit("SET_USER", null);
            }
        }
    },


    modules: {



    }
});