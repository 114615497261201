<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title> Participation </v-card-title>
        <v-card-subtitle> {{ this.week }} </v-card-subtitle>
        <v-card-text>
          <b-row>
            <b-col cols="3">
              <v-toolbar color="green light-3" dark flat>
                <v-toolbar-title>
                  <img
                    src="@/assets/students.png"
                    class=" ma-2"
                    width="44px"
                  />&nbsp;Classes List
                </v-toolbar-title>
              </v-toolbar>
              <br />
              <v-list shaped style="background-color:#d7e9f9">
                <v-list-item
                  v-for="c in classList"
                  :key="c"
                  href="#"
                  @click="loadClass(c)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-google-classroom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ c }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </b-col>
            <b-col cols="9">
              <v-col cols="12" sm="6" md="4"> </v-col>

              <vue-good-table
                :columns="fields"
                :rows="students"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field type="number" v-model="props.row.grade" />
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          small
                          color="success"
                          depressed
                          @click="
                            saveParticipation(
                              props.row.path,
                              props.row.grade,
                              props.row.feedback
                            )
                          "
                          >Save Participation</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Feedback"
                          hint="Feedback"
                          v-model="props.row.feedback"
                      /></v-col>
                    </v-row>
                  </span>

                  <span v-else-if="props.column.field == 'grade'">
                    <getParticipation
                      :key="props.row.key"
                      :student="props.row.path"
                      :week="weekId"
                    />
                    <br />
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/Firebase";
import getParticipation from "./getParticipation.vue";

export default {
  name: "Participation-page",
  components: { getParticipation },
  props: ["path", "title"],
  data() {
    return {
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      grades: [],
      feedback: [],
      value: [],
      record: {},
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center", width: "400px" }
      ],
      classes: [],
      week: localStorage.getItem("week"),
      weekPath: localStorage.getItem("weekpath"),
      weekId: localStorage.getItem("weekId"),
      classList: JSON.parse(localStorage.getItem("classList")),

      gradePath: this.$store.state.gradePath,

      grade: this.$store.state.thisGrade,
      subject: this.$store.state.subjectTitle,
      subjectPath: this.$store.state.subject,

      students: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.gradePath + "/classes")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.classes = [];
      querySnapshot.forEach(doc => {
        this.classes.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title
        });
      });
    });
  },
  methods: {
    deletestandard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path + "/teachers/")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    loadClass(sclass) {
      this.students = [];
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("educationPath", "==", this.gradePath)
        .where("class", "==", sclass)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              class: doc.data().class,
              feedback: doc.data().feedback,
              grade: doc.data().grade
            });
          });
        });
    },
    saveParticipation(path, grade, feedback) {
      this.$confirm({
        message: "Are you sure You want to save this Participation Grade?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.record.date = this.date;
            this.record.type = "participation";
            this.record.week = this.weekPath;
            this.record.school = this.$store.state.school;
            this.record.gradelevel = this.grade;
            this.record.subject = this.subject;
            this.record.subjectPath = this.subjectPath;
            this.record.grade = Number(grade);
            this.record.feedback = feedback ?? " ";
            this.record.breakdown = localStorage.getItem("lessonBreakdown") //this.$store.state.breakdown;
            firebase
              .firestore()
              .doc(path)
              .collection("participation")
              .doc(this.weekId)
              .set(this.record)
              .then(() => {})
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
