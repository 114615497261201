<template>
  <div>
    <v-card flat outlined>
      <v-card-text>
        <ExamPaper :path="this.$route.params.path" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import ExamPaper from "./ExamPaper";
export default {
  name: "Exam-page",
  components: { ExamPaper },
  data() {
    return {
      fullscreen: false,
      exam: {}
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.exam = snapshot.data();
      });
  },

  methods: {
    toggle() {
      this.$refs["fullscreen"].toggle(); // recommended
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    getMinutes(str) {
      var time = str.split(":");
      return time[0] * 60 + time[1] * 1;
    },

    getMinutesNow() {
      var timeNow = new Date();
      return timeNow.getHours() * 60 + timeNow.getMinutes();
    },
    checkDate(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },

    checkTime(startexam, endexam, opendate, disable) {
      // console.log(startexam + opendate);
      var now = this.getMinutesNow();
      var start = this.getMinutes(startexam);
      var end = this.getMinutes(endexam);
      if (start > end) end += this.getMinutes("24:00");
      if (
        now > start &&
        now < end &&
        new Date().getDate() == new Date(opendate).getDate() &&
        disable != true
      )
        return true;
      else return false;
    }
  }
};
</script>
