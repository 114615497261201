<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-account-plus-outline</v-icon>
          <span class="title font-weight-light">Add New Employee</span>
        </v-card-title>

        <v-container>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- First Name -->
            <v-row center-block>
              <v-col cols="12" md="3">
                <v-text-field
                  label="First name"
                  v-model.trim="employees.firstname"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Last name"
                  v-model.trim="employees.lastname"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row center-block>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Nationality"
                  v-model.trim="employees.nationality"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Job Level -->
            <v-row center-block>
              <v-col class="d-flex" cols="12" sm="6">
                <v-autocomplete
                  v-model.trim="employees.job"
                  :items="jobs"
                  dense
                  label="Job"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-alert
              type="info"
              dark
              dense
              icon="mdi-account-edit-outline"
              prominent
              >Employee Account</v-alert
            >

            <v-container fluid>
              <v-row>
                <v-col cols="6" md="4">
                  <v-text-field
                    label="Email"
                    v-model.trim="employees.username"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-text-field
                    label="Password"
                    v-model.trim="employees.password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-alert
                color="green"
                dark
                dense
                icon="mdi-account-key-outline"
                prominent
              >
                Employee Account Rules
              </v-alert>
              <v-row>
                <v-col cols="4" md="4">
                  <v-switch
                    v-model="rules.academic"
                    label="Academic"
                  ></v-switch>
                  <v-switch
                    v-model="rules.students"
                    label="Students"
                  ></v-switch>
                  <v-switch
                    v-model="rules.admission"
                    label="Admission"
                  ></v-switch>
                </v-col>
                <v-col cols="4" md="4">
                  <v-switch v-model="rules.hr" label="HR"></v-switch>
                  <v-switch v-model="rules.fleet" label="Fleet"></v-switch>
                </v-col>

                <v-col cols="4" md="4">
                  <v-switch
                    v-model="rules.accounting"
                    label="Accounting"
                  ></v-switch>
                  <v-switch v-model="rules.setting" label="Setting"></v-switch>
                </v-col>
              </v-row>
            </v-container>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Add Employee</b-button>
            </b-card-footer>
          </b-form>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "AddEmployee",
  data() {
    return {
      rules: {},
      account: "",
      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/employees"),
      jobsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/jobs/"),
      employees: {},
      jobs: []
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.employees.type = "employee";
      this.employees.school = this.$store.state.school;
      this.employees.username =
        this.employees.username == null
          ? ""
          : this.employees.username.toLowerCase();
      this.employees.access = this.rules;

      this.ref
        .add(this.employees)
        .then(() => {
          if (
            this.employees.username != null &&
            this.employees.password != null
          ) {
            //Create User
            // firebase
            //   .auth()
            //   .createUserWithEmailAndPassword(
            //     this.employees.username,
            //     this.employees.password
            //   )
            //   .then()
            //   .catch(err => {
            //     this.error = err.message;
            //   });
          }

          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Create",
            this.employees,
            "HR",
            "Employee"
          );

          this.$bvToast.toast("Employee Created", {
            title: "Employee Created Successfully",
            variant: "success",
            solid: true
          });

          router.push("employees");
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  },

  created() {
    this.jobsRef.onSnapshot(querySnapshot => {
      this.jobs = [];
      querySnapshot.forEach(doc => {
        this.jobs.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  }
};
</script>

<style>
.jumbotron {
  padding: 1rem;
}
</style>
