<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card dark style="background-color:#1b2e47">
                <v-card-title>
                  <v-icon large left>mdi-apps</v-icon>
                  <span class="title">Students Distribution</span>

                  <v-row align="center" justify="end">
                    <download-excel
                      class="btn btn-default"
                      :data="studentInfo"
                      :fields="fexport"
                      type="csv"
                      name="students.xls"
                    >
                      <v-icon color="green">mdi-microsoft-excel</v-icon>&nbsp;
                      <small style="color:#ffffff;">Export to Excel</small>
                    </download-excel>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <!-- School -->
                  <v-row>
                    <v-col class="d-flex" cols="6">
                      <v-select
                        :items="schools"
                        v-model="school"
                        label="School"
                        @change="onSchoolChange()"
                      ></v-select>
                    </v-col>
                    <!-- Grade Level -->

                    <v-col class="d-flex" cols="6">
                      <v-select
                        :items="grades"
                        v-model="grade"
                        label="Grade Level"
                        @change="onGradeChange()"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="4">
                      <v-text-field label="Rooms Count" />
                    </v-col>
                    <v-col class="d-flex" cols="4">
                      <v-text-field
                        v-model="exam.cols"
                        label="Room's Columns"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="4">
                      <v-text-field v-model="exam.rows" label="Room's Rows" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="8" sm="8">
                      <v-text-field v-model="exam.title" label="Exam Title" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer
                  ><b-button variant="success" @click="clearSearch"
                    >Start Distribution</b-button
                  ></v-card-actions
                >
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>
                  {{
                    this.exam.title == null ? "Exam Title" : this.exam.title
                  }}</v-card-title
                >
                <v-card-text>
                  <div v-if="exam.cols" style="text-align:center">
                    <div v-for="r in Number(exam.rows)" :key="r">
                      <v-icon
                        v-for="c in Number(exam.cols)"
                        :key="c"
                        size="40"
                        style="margin-right:20px; margin-left:20px; margin-top:5px;"
                      >
                        mdi-account-outline</v-icon
                      >
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  Students Count
                  {{ this.students.length }}</v-card-actions
                >
              </v-card>
            </v-col>
          </v-row>
        </v-card-text></v-card
      >
    </v-col></v-row
  >
</template>
<script>
import firebase from "@/Firebase";
export default {
  name: "Distribution-page",
  data() {
    return {
      schools: [],
      grades: [],
      students: [],
      exam: [],
      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/grades/")
        .orderBy("order")
    };
  },
  created() {
    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title
        });
      });
    });
  },
  methods: {
    onSchoolChange() {
      this.grades = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.school +
            "/grades/"
        )
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },
    onGradeChange() {
      this.students = [];

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.school)
        .where("grade", "==", this.grade)
        .onSnapshot(querySnapshot => {
          this.students = [];
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              grade: doc.data().grade,
              branch: doc.data().branch,
              class: doc.data().class,
              firstname: doc.data().firstname,
              middlename: doc.data().middlename,
              lastname: doc.data().lastname,
              username: doc.data().username,
              password: doc.data().password
            });
          });
        });
    }
  }
};
</script>
