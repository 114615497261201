<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>{{ this.studentname }}</v-card-title>
        <v-card-text>
          <!-- <div>
          <p>Select Attempt</p>
          <b-select
              label="attempts"
              v-model.trim="selectedAttempt"
              :options="StudentAttempts"
              @change="onAttemptSelect()"
            >
            </b-select>
        </div> -->
        <h5>Your Grade</h5>
              <h3 style="color: green" >
                {{
                  this.right+
                  "/" +
                  (this.total)
                }}
              </h3>
          <v-row>
            <v-col cols="6">
              <v-card flat outliend>
                <v-card-title>Answers Perview</v-card-title>

                <v-card-text>
                  <QuestionPreview
                    v-for="(q, index) in answers"
                    :key="q.key"
                    :title="'Question ' + (index + 1)"
                    :answer="q.answer"
                    :path="q.path"
                  /> </v-card-text
              ></v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat outliend>
                <v-card-title>Weaknesses</v-card-title>

                <v-card-text>
                  <Standard
                    v-for="standard in standards"
                    :key="standard.key"
                    :code="standard.standard"
                    :right="standard.right"
                    :wrong="standard.wrong"
                  /> </v-card-text
              ></v-card>
            </v-col>
          </v-row> </v-card-text
      ></v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
import QuestionPreview from "./QuestionPreview";
import Standard from "@/components/lms/reports/Standard.vue";

export default {
  components: { Standard, QuestionPreview },
  data() {
    return {
      studentname: localStorage.getItem("studentname"),
      assid: localStorage.getItem("assignmentid"),
      studentid: localStorage.getItem("studentid"),
      battemps: [],
      standards: [],
      answers: [],
      selectedAttempt:0,
      StudentAttempts:[],
      right: 0,
      wrong: 0,
      total: 0
    };
  },
  created() {
    this.right = 0;
      this.wrong = 0;
      this.total = 0;
      this.getAttempts();
    this.getAttemptStandards(this.$route.params.attempt);
    this.getAttemptAnswers(this.$route.params.attempt);
    
    // this. getAnswers();
    // this.getStandards();
  },
  methods: {
    getAttempts(){
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.studentid +
            "/quizes/" +
            this.assid +
            "/attempts/"+this.$route.params.attempt
        )
        .get()
        .then((querySnapshot) => {
         // this.StudentAttempts = [];
            this.total = querySnapshot.data().assgrade
           // this.right = querySnapshot.data().right
          // querySnapshot.forEach((doc) => {
          //   // this.StudentAttempts.push({
          //   //   text:String(doc.data().attempts),
          //   //   value:doc.data().attempts
          //   // });
          // });
        });
    },
    onAttemptSelect(){
      this.getAttemptStandards(this.selectedAttempt);
      this.getAttemptAnswers(this.selectedAttempt);
    },
    getAttemptStandards(attempt) {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.studentid +
            "/quizes/" +
            this.assid +
            "/weakness_new"
        )
        .where("attempts","==",attempt)
        .get()
        .then((querySnapshot) => {
          this.standards = [];
          querySnapshot.forEach((doc) => {
            if(this.standards.find(el => el.standard == doc.data().standard)){
            let el = this.standards.find(el => el.standard == doc.data().standard)
            el.right = Number(el.right) + Number(doc.data().right);
            el.wrong = Number(el.wrong) + Number(doc.data().wrong);
          }else{
            this.standards.push({
              key: doc.id,
              path: doc.ref.path,
              standard: doc.data().standard,
              parent: doc.data().parent,
              right: doc.data().right,
              wrong: doc.data().wrong,
            });
          }
            // this.standards.push({
            //   key: doc.id,
            //   path: doc.ref.path,
            //   standard: doc.data().standard,
            //   parent: doc.data().parent,
            //   right: doc.data().right,
            //   wrong: doc.data().wrong,
            // });
          });
        });
    },
    getAttemptAnswers(attempt) {
      this.answers = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.studentid +
            "/quizes/" +
            this.assid +
            "/answers_new"
        )
        .where("attempts","==",attempt)
        .get()
        .then((querySnapshot) => {
          console.log("answers questions length =>",querySnapshot.size)
          //this.total = querySnapshot.size
          querySnapshot.forEach((doc) => {
            console.log("answers ==>",doc.data())
            this.answers.push({
              key: doc.id,
              path: doc.data().question,
              answer: doc.data().answer,
      
            });
            if(doc.data().answer == "Right"){
                    this.right = this.right + 1;
                }else{
                    this.wrong = this.wrong + 1;
                }
              });
             // this.total = this.answers.length;
        });
      },
    getStandards() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.studentid +
            "/quizes/" +
            this.assid +
            "/weakness"
        )
        .get()
        .then((querySnapshot) => {
          this.standards = [];
          querySnapshot.forEach((doc) => {
            this.standards.push({
              key: doc.id,
              path: doc.ref.path,
              standard: doc.data().standard,
              parent: doc.data().parent,
              right: doc.data().right,
              wrong: doc.data().wrong,
            });
          });
        });
    },
    getAnswers() {
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.studentid +
            "/quizes/" +
            this.assid +
            "/answers"
        )
        .get()
        .then((querySnapshot) => {
          this.answers = [];
          querySnapshot.forEach((doc) => {
            this.answers.push({
              key: doc.id,
              path: doc.data().question,
              answer: doc.data().answer,
      
            });
          });
        });
    },
    gotoReport(path) {
      localStorage.setItem("submissionpath", path);
      router.push({
        name: "SubmissionReport",
      });
    },

    deleteAttempt(path) {
      this.$confirm({
        message: "Are you sure You want to Attempt?",
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            firebase
              .firestore()
              .doc(path)
              .delete()
              .then(() => {
                firebase
                  .firestore()
                  .doc(
                    "schools/" +
                      this.$store.state.school +
                      "/StudentsData/" +
                      this.studentid +
                      "/assignments/" +
                      this.assid
                  )
                  .update({ attempts: this.battemps.length });

                this.$bvToast.toast("Attempt Deleted", {
                  title: "Attempt Deleted Successfully",
                  variant: "danger",
                  solid: true,
                });
              })
              .catch((error) => {
                alert("Error removing document: ", error);
              });
          }
        },
      });
    },
  },
};
</script>
