<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-container>
          <b-form @submit.stop.prevent="onSubmit">
            <!-- Grade Order -->

            <b-form-group
              id="fieldsicon"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Type"
              >{{ lesson.type }}</b-form-group
            >

            <b-form-group
              id="fieldsetname"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Title"
            >
              <quill-editor
                ref="name"
                v-model="lesson.title"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
              />
            </b-form-group>

            <!-- Standards Faild -->
            <b-form-group
              id="fieldstandard"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Standard"
            >
              <b-form-select
                id="qstandard"
                placeholder="Standard"
                v-model.trim="lesson.standard"
                :options="standards"
              ></b-form-select>
            </b-form-group>
            <!-- ----------------- -->

            <div v-if="lesson.type == 'question'">
              <b-form-group
                id="fieldbloom"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Bloom Level"
              >
                <b-form-select
                  id="bloom"
                  placeholder="Bloom Level"
                  v-model.trim="lesson.bloom"
                  :options="bloom"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="fielddok"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="DOK Level"
              >
                <b-form-select
                  id="dok"
                  placeholder="DOK Level"
                  v-model.trim="lesson.dok"
                  :options="dok"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="fielddifficulty"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Difficulty Level"
              >
                <b-form-select
                  id="difficulty"
                  placeholder="Difficulty Level"
                  v-model.trim="lesson.difficulty"
                  :options="difficulty"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="fieldqtype"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Question Type"
              >
                <b-form-select
                  id="qtype"
                  placeholder="Question Type"
                  v-model.trim="lesson.qtype"
                  :options="qtype"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="fieldanswer"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Answer"
              >
                <quill-editor
                  ref="answer"
                  v-model="lesson.answer"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                />
              </b-form-group>
            </div>

            <!-- Live Faild -->
            <div v-else-if="lesson.type == 'Live Session'">
              <v-row>
                <v-col cols="2">
                  <b-button variant="success" size="sm" @click="goToLive"
                    >Create Video Session</b-button
                  >
                </v-col>
                <v-col cols="10">
                  <b-form-group
                    id="livelink"
                    horizontal
                    :label-cols="4"
                    breakpoint="md"
                    label="Live Link"
                  >
                    <b-form-input
                      id="llink"
                      placeholder="Live link"
                      v-model.trim="lesson.livelink"
                    ></b-form-input>
                  </b-form-group>
                </v-col>
              </v-row>
              <v-toolbar color="blue darken-3" dark>
                <v-toolbar-title
                  >{{ gradetitle }} Live Session Date & Time</v-toolbar-title
                >
              </v-toolbar>
              <br />

              <v-row>
                <v-col cols="6">
                  <label for="livedate">Live Date</label>
                  <b-form-datepicker
                    id="livedate"
                    v-model="lesson.livedate"
                    class="mb-2"
                  ></b-form-datepicker>
                </v-col>
                <v-col cols="6">
                  <v-time-picker v-model="lesson.livetime"></v-time-picker>
                </v-col>
              </v-row>
            </div>
            <!-- ----------------- -->

            <div v-else>
              <b-form-group
                id="fieldlink"
                horizontal
                :label-cols="4"
                breakpoint="md"
                label="Link"
              >
                <b-form-input
                  id="link"
                  placeholder="Activity link"
                  v-model.trim="lesson.link"
                ></b-form-input>
              </b-form-group>
            </div>

            <b-card-footer>
              <!-- Submit Form -->
              <b-button type="submit" variant="primary">Edit Activity</b-button>
            </b-card-footer>
          </b-form>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import katex from "katex";
import "katex/dist/katex.min.css";

export default {
  name: "EditActivity",

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
            ["image"],
            ["video"],
            ["formula"]
          ]
        }
      },

      ref: firebase
        .firestore()
        .collection(this.$route.params.subject + "/lesson/"),

      activityRef: firebase.firestore().doc(this.$route.params.path),

      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      lesson: {},
      selected: null,
      types: [
        { value: "video", text: "Video" },
        { value: "Live Session", text: "Live Session" },
        { value: "question", text: "Question" },
        { value: "show", text: "Lesson Documents" }
      ],
      bloom: [
        { value: "Knowledge (Remembering)", text: "Knowledge (Remembering)" },
        {
          value: "Comprehension (Understanding)",
          text: "Comprehension (Understanding)"
        },
        {
          value: "Application (Transferring)",
          text: "Application (Transferring)"
        },
        { value: "Analysis (Relating)", text: "Analysis (Relating)" },
        { value: "Synthesis (Creating)", text: "Synthesis (Creating)" },
        { value: "Evaluation (Judging)", text: "Evaluation (Judging)" }
      ],
      dok: [
        { value: "Recall", text: "Recall" },
        { value: "Skill Concept", text: "Skill Concept" },
        { value: "Strategic Thinking", text: "Strategic Thinking" },
        { value: "Extended Thinking", text: "Extended Thinking" }
      ],
      difficulty: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" }
      ],
      qtype: [
        { value: "mc", text: "Multiple Choice" },
        { value: "tf", text: "True/False" },
        { value: "speech", text: "Speech" },
        { value: "complete", text: "Complete" },
        { value: "listening", text: "Listening" }
      ],
      standards: []
    };
  },
  created() {
    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.data().code
        });
      });
    });

    this.activityRef.get().then(doc => {
      if (doc.exists) {
        this.lesson = doc.data();
      } else {
        alert("No such document!");
      }
    });
  },
  mounted() {
    window.katex = katex;
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.lesson.order = Number(this.lesson.order);
      firebase
        .firestore()
        .doc(this.$route.params.path)
        .set(this.lesson)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Update",
            this.lesson,
            this.$store.state.subject,
            "Activity"
          );

          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },
    goToLive() {
      window.open("https://teams.microsoft.com", "_blank");
    }
  }
};
</script>
