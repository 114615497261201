<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          Resources List

          <v-divider></v-divider>
        </v-card-title>
        <v-card-text>
          <br />
          <AddResource v-if="master == true"></AddResource>
          <br />

          <vue-good-table
            :columns="fields"
            :rows="boards"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->
              <span v-if="props.column.field == 'actions'">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteboard(props.row.key)"
                  v-if="master == true"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </button>
                <!--  &nbsp;
                <button
                  class="btn btn-info btn-sm"
                  @click="Edit(props.row.path, props.row.type)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </button>-->
              </span>
              <span v-else-if="props.column.field == 'title'">
                <a :href="props.row.link" target="_blank">
                  {{ props.row.title }}</a
                >
              </span>
              <span v-else-if="props.column.field == 'hide'">
                <v-switch
                  color="red"
                  v-model="props.row.hide"
                  inset
                  @change="Hide(props.row.path, props.row.hide)"
                >
                  {{ props.row.hide }}
                </v-switch>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddResource from "./AddResource";

export default {
  name: "ActivitiesList",
  components: {
    AddResource
  },
  props: ["path"],
  data() {
    return {
      dragData: {},
      ldialog: false,
      thisOrder: 0,
      master: this.$store.state.master,
      newActivity: {},
      grade: this.$route.params.id,
      activities: [],

      fields: [
        {
          field: "hide",
          label: "Hide",
          class: "text-left",
          width: "10%"
        },
        {
          field: "title",
          label: "Title",
          class: "text-left",
          width: "60%",
          html: false
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center"
        }
      ],
      boards: [],
      errors: [],
      ref: firebase
        .firestore()
        .collection(this.$store.state.subject + "/resources")
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.boards = [];
      querySnapshot.forEach(doc => {
        this.boards.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          link: doc.data().link,
          hide: doc.data().hide
        });
      });
    });
  },
  methods: {
    deleteboard(id) {
      this.$confirm({
        message: "Are you sure You want to Delete this Recource ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$store.state.subject + "/resources")
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  id,
                  this.$store.state.subject,
                  "Activity"
                );

                this.$bvToast.toast("Activity Deleted", {
                  title: "Activity Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    Hide(path, hiden) {
      firebase
        .firestore()
        .doc(path)
        .update({ hide: hiden });
    },

    Edit(path, type) {
      router.push({
        name: "EditActivity",
        params: {
          path: path,
          type: type,
          subject: this.$route.params.id
        }
      });
    }
  }
};
</script>
