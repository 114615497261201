<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn  btn-sm"
        dark
        x-small
        color="orange"
        depressed
        v-bind="attrs"
        block
        v-on="on"
      >
        <v-icon size="15">mdi-calendar-text-outline</v-icon>Answers
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-calendar-text-outline</v-icon
          >Answers
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <div v-for="ans in answers" :key="ans.key" style="margin-bottom:10px">
          <v-card flat outlined>
            <v-card-text>
              <v-chip color="red" dark v-if="ans.type == 'wrong'" label>
                {{ ans.type.toUpperCase() }}</v-chip
              >
              <v-chip color="green" dark v-else-if="ans.type == 'right'" label>
                {{ ans.type.toUpperCase() }}</v-chip
              >
              <Question :path="ans.question" />
              {{ ans.answer }}
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import Question from "./Question";

export default {
  props: ["answers"],
  components: { Question },
  data() {
    return { dialog: false };
  },
  created() {},
  methods: {}
};
</script>
