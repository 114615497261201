<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-format-list-bulleted</v-icon>
          <span class="title">School Setting</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            label="School Name"
            v-model.trim="school.title"
            readonly
          />

          <v-alert type="warning">
            Subjects Setting
          </v-alert>
          <v-select
            label="Default Lesson View"
            v-model="school.lessonview"
            :items="['Lesson Page', 'Lesson Prep']"
          ></v-select>
          <v-alert type="info">
            Email Setting
          </v-alert>
          <v-text-field label="Email" v-model.trim="school.email" />
          <v-text-field label="Password" v-model.trim="school.password" />
          <v-text-field label="SMTP Host" v-model.trim="school.smtp" />
          <v-text-field label="SMTP Port" v-model.trim="school.port" />
          <v-text-field label="From email" v-model.trim="school.from" />
        </v-card-text>
        <v-card-actions
          ><v-btn color="success" depressed @click="onSubmit">
            <v-icon>mdi-content-save-outline</v-icon>
            Save Setting</v-btn
          ></v-card-actions
        >
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "StudentsList",
  components: {},

  data() {
    return {
      school: {},

      ref: firebase.firestore().doc("schools/" + this.$store.state.school)
    };
  },
  created() {
    this.ref.get().then(snapshot => {
      if (!snapshot.exists) return;
      this.school = snapshot.data();
    });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      firebase
        .firestore()
        .doc("schools/" + this.$store.state.school)
        .update(this.school)
        .then(() => {
          this.$bvToast.toast("School Setting Saved", {
            title: "School Setting Saved Successfully",
            variant: "success",
            solid: true
          });
        });
    }
  }
};
</script>
