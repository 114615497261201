<template>
  <b-row>
    <b-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon large left>mdi-account-edit-outline</v-icon>
          <span class="title font-weight-light">Edit Employee </span>
        </v-card-title>

        <v-container>
          <!-- First Name -->
          <v-row center-block>
            <v-col cols="12" md="3">
              <v-text-field
                label="First name"
                v-model.trim="employees.firstname"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Last name"
                v-model.trim="employees.lastname"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row center-block>
            <v-col cols="12" md="3">
              <v-text-field
                label="Nationality"
                v-model.trim="employees.nationality"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                label="Employee National Id (14 Numbers)"
                messages="الرقم القومي للموظف"
                v-model.trim="employees.nationalID"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label for="joiningdate">Joining Date</label>

              <b-form-datepicker
                id="joiningdate"
                v-model="employees.join"
                class="mb-2"
              ></b-form-datepicker>
            </v-col>
          </v-row>
          <div v-if="this.emprules.salaries">
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Salary"
                  messages="الراتب"
                  v-model.trim="employees.salary"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field
                  label="Insurance Salary"
                  messages="الراتب التأميني"
                  v-model.trim="employees.inssalary"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="12">
                <v-text-field
                  label="Taxes"
                  messages="الضرائب"
                  v-model.trim="employees.taxes"
                ></v-text-field>
                <v-text-field
                  label="Deductions"
                  messages="خصومات"
                  v-model.trim="employees.deductions"
                ></v-text-field>
                <v-text-field
                  label="Bonus"
                  messages="إضافات"
                  v-model.trim="employees.bonus"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              ><v-col cols="6">
                <v-text-field
                  label="Fixed Deduction"
                  messages="الخصومات الثابته"
                  v-model.trim="employees.fixeddeductions"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Fixed Deduction Reason"
                  messages="سبب الخصومات الثابته"
                  v-model.trim="employees.fixeddeductionsr"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              ><v-col cols="6">
                <v-text-field
                  label="Fixed Compensation"
                  messages="تعويض ثابت"
                  v-model.trim="employees.fixedcompensation"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Fixed Compensation Reason"
                  messages="سبب تعويض ثابت"
                  v-model.trim="employees.fixedcompensationr"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                :items="[
                  { text: 'Male', value: 'Male' },
                  { text: 'Female', value: 'Female' },
                ]"
                v-model="employees.gender"
                label="Gender"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Job Level -->
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model.trim="employees.job"
                :items="jobs"
                dense
                label="Job"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model.trim="employees.branch"
                :items="schools"
                dense
                label="School"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                label="Administration Grades"
                messages="Ex: Grade 1,Grade 2,Grade 3"
                v-model.trim="employees.admingrades"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-alert
            type="info"
            dark
            dense
            icon="mdi-account-edit-outline"
            prominent
            >Employee Account</v-alert
          >
          <v-divider></v-divider>
          <v-row>
            <v-col cols="6" md="4">
              <v-text-field
                label="Email"
                v-model.trim="employees.username"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4">
              <v-text-field
                label="Password"
                v-model.trim="employees.password"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-container fluid>
            <div v-if="this.emprules.salaries">
              <v-alert
                color="green"
                dark
                dense
                icon="mdi-account-key-outline"
                prominent
              >
                Employee Account Rules
              </v-alert>

              <v-row>
                <v-col cols="4" md="4">
                  <v-switch
                    inset
                    v-model="rules.academic"
                    label="Academic"
                  ></v-switch>
                  <v-switch
                    inset
                    v-model="rules.students"
                    label="Students"
                  ></v-switch>
                  <v-switch
                    inset
                    v-model="rules.admission"
                    label="Admission"
                  ></v-switch>
                </v-col>
                <v-col cols="4" md="4">
                  <v-switch inset v-model="rules.hr" label="HR"></v-switch>
                  <v-switch
                    inset
                    v-model="rules.salaries"
                    label="HR (Salaries)"
                  ></v-switch>
                  <v-switch
                    inset
                    v-model="rules.fleet"
                    label="Fleet"
                  ></v-switch>
                  <v-switch
                    inset
                    v-model="rules.exam"
                    label="Exam Admin"
                  ></v-switch>
                </v-col>

                <v-col cols="4" md="4">
                  <v-switch
                    inset
                    v-model="rules.accounting"
                    label="Accounting"
                  ></v-switch>
                  <v-switch
                    inset
                    v-model="rules.setting"
                    label="Setting"
                  ></v-switch>
                </v-col>
              </v-row>
            </div>

            <div v-if="rules.academic">
              <v-alert
                color="red"
                dark
                dense
                icon="mdi-account-key-outline"
                prominent
              >
                Assign Subjects
              </v-alert>

              <!-- Assign Dialog -->
              <v-dialog v-model="dialog" width="60%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" v-on="on">
                    <v-icon color="orange" style="padding-right: 20px"
                      >mdi-text-box-plus-outline</v-icon
                    >
                    Assign Subject
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">
                      <v-icon>mdi-text-box-plus-outline</v-icon>
                      Assign Subject</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <!-- School -->
                    <v-row class="card-text">
                      <!-- School -->
                      <v-row class="card-text">
                        <v-col class="d-flex" cols="3" sm="3">
                          <b-select
                            label="School"
                            v-model.trim="subject.school"
                            :options="schools"
                             @change="onSchoolChange()"
                          >
                          </b-select>
                        </v-col>
                        <!-- Grade Level -->

                        <v-col class="d-flex" cols="3" sm="3">
                          <b-select
                            label="Grade Level"
                            v-model.trim="subject.grade"
                            :options="level"
                            @change="onGradeChange()"
                          >
                          </b-select>
                        </v-col>
                        <v-col class="d-flex" cols="3" sm="3">
                          <b-select
                            label="Subject"
                            v-model.trim="selectedSubject"
                            :options="subjects"
                          >
                          </b-select>
                        </v-col>
                        <v-col class="d-flex" cols="3" sm="3">
                          <b-select
                            label="Class"
                            v-model.trim="subject.class"
                            :options="classes"
                            multiple
                          >
                          </b-select>
                        </v-col>

                        <v-col class="d-flex" cols="4" sm="4">
                          <v-switch
                            v-model="master"
                            label="Master Teacher"
                          ></v-switch>
                        </v-col>
                        <v-col class="d-flex" cols="4" sm="4">
                          <v-switch
                            v-model="control"
                            label="Exam Control"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="dialog = false"
                      >Back</v-btn
                    >
                    <v-btn color="green darken-1" text @click="onAssign"
                      >Assign</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- End Assign Dialog -->
              <!-- List of Subjects & Classes -->
              <vue-good-table
                :columns="fields"
                :rows="teacherSubjects"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
                style="padding-top: 20px"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <button
                      class="btn btn-danger btn-sm"
                      @click.stop="
                        deleteSubject(props.row.key, props.row.subject)
                      "
                    >
                      <v-icon color="white">mdi-delete-outline</v-icon>
                    </button>
                  </span>
                  <span v-else-if="props.column.field == 'subject'">
                    <b> {{ props.row.subjectTitle }}</b>
                    <br />
                    <small style="color: green">{{
                      props.row.subject.split("/")[3]
                    }}</small>
                  </span>

                  <span v-else-if="props.column.field == 'class'">
                    <v-row style="margin-left: 10px">
                      <div v-for="c in props.row.class" :key="c">
                        <v-avatar
                          color="green"
                          size="24"
                          style="margin-right: 2px"
                        >
                          <span class="white--text">{{ c }}</span>
                        </v-avatar>
                      </div>
                    </v-row>
                  </span>
                </template>
              </vue-good-table>

              <!-- End List of Subjects & Classes -->
            </div>

            <!-- Assigned Subordinates -->
            <br />
            <div>
              <v-alert
                color="orange"
                dark
                dense
                icon="mdi-human-capacity-decrease"
                prominent
              >
                Assign Subordinates
              </v-alert>

              <!-- Assign Subordinates Dialog -->
              <v-dialog v-model="sdialog" width="60%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" v-on="on">
                    <v-icon color="orange" style="padding-right: 20px"
                      >mdi-text-box-plus-outline</v-icon
                    >
                    Assign Subordinates
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">
                      <v-icon>mdi-text-box-plus-outline</v-icon>
                      Assign Subordinates</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <!-- School -->
                    <v-row class="card-text">
                      <v-col class="d-flex" cols="6" sm="6">
                        <v-autocomplete
                          v-model.trim="selectedEmployee"
                          :items="allemployees"
                          dense
                          label="Select Subordinate"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="card-text">
                      <v-col class="d-flex" cols="3" sm="3">
                        <v-select
                          :items="reporting"
                          v-model="subordinates.reporting"
                          label="Reporting Method"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="sdialog = false"
                      >Back</v-btn
                    >
                    <v-btn color="green darken-1" text @click="onSubordinates"
                      >Assign Subordinate</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- End Assigned Subordinates Dialog -->
              <!-- List of Subordinates -->
              <vue-good-table
                :columns="sfields"
                :rows="teacherSubordinate"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
                style="padding-top: 20px"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <button
                      class="btn btn-danger btn-sm"
                      @click.stop="
                        deleteSubordinate(props.row.key, props.row.firstname)
                      "
                    >
                      <v-icon color="white">mdi-delete-outline</v-icon>
                    </button>
                  </span>
                </template>
              </vue-good-table>

              <!-- End List of Subordinates -->
            </div>

            <!-- Assigned Supervisors -->
            <br />
            <div>
              <v-alert
                color="red"
                dark
                dense
                icon="mdi-human-capacity-increase"
                prominent
              >
                Assign Supervisor
              </v-alert>

              <!-- Assign Supervisors Dialog -->
              <v-dialog v-model="superdialog" width="60%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" v-on="on">
                    <v-icon color="red" style="padding-right: 20px"
                      >mdi-text-box-plus-outline</v-icon
                    >
                    Assign Supervisor
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">
                      <v-icon>mdi-text-box-plus-outline</v-icon>
                      Assign Supervisor</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <!-- School -->
                    <v-row class="card-text">
                      <v-col class="d-flex" cols="6" sm="6">
                        <v-autocomplete
                          v-model.trim="selectedEmployee"
                          :items="allemployees"
                          dense
                          label="Select Supervisor"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="card-text">
                      <v-col class="d-flex" cols="3" sm="3">
                        <v-select
                          :items="reporting"
                          v-model="supervisors.reporting"
                          label="Reporting Method"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="red darken-1"
                      text
                      @click="superdialog = false"
                      >Back</v-btn
                    >
                    <v-btn color="green darken-1" text @click="onSupervisors"
                      >Assign Supervisor</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- End Assigned Supervisors Dialog -->
              <!-- List of Supervisors -->
              <vue-good-table
                :columns="sfields"
                :rows="teacherSupervisor"
                :search-options="{ enabled: true, skipDiacritics: true }"
                :pagination-options="{ enabled: true, mode: 'records' }"
                style="padding-top: 20px"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <button
                      class="btn btn-danger btn-sm"
                      @click.stop="
                        deleteSupervisor(props.row.key, props.row.firstname)
                      "
                    >
                      <v-icon color="white">mdi-delete-outline</v-icon>
                    </button>
                  </span>
                </template>
              </vue-good-table>

              <!-- End List of Supervisors -->
            </div>
          </v-container>
          <v-divider />

          <v-card-actions>
            <!-- Submit Form -->

            <v-spacer />
            <b-button @click.self="onSubmit" variant="primary"
              >Edit Employee</b-button
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  components: {},
  name: "EditEmployee",
  params: ["path"],
  data() {
    return {
      nameRules: [(v) => !!v || "Field is required"],
      menu: false,
      fields: [
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left",
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left",
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left",
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left",
          width: "250px",
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "100px",
        },
      ],
      sfields: [
        {
          field: "firstname",
          label: "First name",
          sortable: true,
          class: "text-left",
        },
        {
          field: "lastname",
          label: "Last Name",
          sortable: true,
          class: "text-left",
        },
        {
          field: "reporting",
          label: "Reporting",
          sortable: true,
          class: "text-left",
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "100px",
        },
      ],

      rules: {},
      account: "",
      selectedEmployee: "",
      dialog: false,
      sdialog: false,
      superdialog: false,
      schools: [],
      reporting: ["Direct", "Indirect"],
      level: [],
      classes: [],
      allemployees: {},
      studentInfo: [],
      student: [],
      subjects: [],
      subject: {},
      subordinates: {},
      supervisors: {},
      master: false,
      control: false,
      selectedSubject: [],
      empID: "",
      teacherSubjects: [],
      teacherSubordinate: [],
      teacherSupervisor: [],
      jobs: {},
      emprules: this.$store.state.rules,

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/branches/"),

      ref: firebase
        .firestore()
        .collection("/schools/" + this.$store.state.school + "/employees"),

      jobsRef: firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/jobs/"),

      employeeRef: firebase.firestore().doc(this.$route.params.path),
      employees: {},
    };
  },
  methods: {
    onSchoolChange() {
      this.level = [];

      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/branches/" +
            this.subject.school +
            "/grades/"
        )
        .orderBy("order")
        .onSnapshot((querySnapshot) => {
          this.level = [];
          querySnapshot.forEach((doc) => {
            this.level.push({
              text: doc.data().title,
              value: doc.ref.path,
            });
          });
        });
    },
    onGradeChange() {
      this.classes = [];
      this.subjects = [];

      firebase
        .firestore()
        .collection(this.subject.grade + "/classes")
        .onSnapshot((querySnapshot) => {
          this.classes = [];
          querySnapshot.forEach((doc) => {
            this.classes.push({
              text: doc.data().title,
              value: doc.id,
            });
          });
        });

      firebase
        .firestore()
        .collection(this.subject.grade + "/subjects")
        .onSnapshot((querySnapshot) => {
          this.subjects = [];
          querySnapshot.forEach((doc) => {
            this.subjects.push({
              text: doc.data().title,
              value: [doc.ref.path, doc.data().title],
            });
          });
        });
    },

    onSubmit(evt) {
      evt.preventDefault();

      this.employees.type = "employee";
      this.employees.school = this.$store.state.school;

      this.employees.access = this.rules;

      this.employeeRef
        .set(this.employees)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Update",
            this.employees,
            "HR",
            "Employee"
          );

          this.$bvToast.toast("Employee Updated", {
            title: "Employee Updated Successfully",
            variant: "success",
            solid: true,
          });
          router.back();
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
    onAssign() {
      this.subject.grade = this.subject.grade.split("/")[5];
      this.subject.master = this.master;
      this.subject.control = this.control;
      this.subject.subject = this.selectedSubject[0];
      this.subject.subjectTitle = this.selectedSubject[1];

      //! Add to Assign
      firebase
        .firestore()
        .collection("schools/")
        .doc(this.$store.state.school)
        .collection("employees")
        .doc(this.empID)
        .collection("assign")
        .add(this.subject)
        .then((doc) => {
          // //! Add to Subject
          firebase
            .firestore()
            .collection(this.subject.subject + "/teachers")
            .doc(doc.id)
            .set({
              employeeid: this.empID,
              employeepath:
                "schools/" +
                this.$store.state.school +
                "/employees/" +
                this.empID,
              master: this.master == null ? false : this.master,
              control: this.control == null ? false : this.control,
              class: this.subject.class == null ? [] : this.subject.class,
            })
            .then(() => {
              this.subject.subject = "";
              this.dialog = false;
            });
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
    onSubordinates() {
      //console.log(this.selectedEmployee);
      this.subordinates.firstname = this.selectedEmployee.data().firstname;
      this.subordinates.lastname = this.selectedEmployee.data().lastname;
      this.subordinates.email = this.selectedEmployee.data().username;
      this.subordinates.id = this.selectedEmployee.ref.id;
      firebase
        .firestore()
        .collection("schools/")
        .doc(this.$store.state.school)
        .collection("employees")
        .doc(this.empID)
        .collection("subordinates")
        .add(this.subordinates)
        .then(() => {
          this.selectedEmployee = "";
          this.sdialog = false;
          this.subordinates = {};
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
    onSupervisors() {
      this.supervisors.firstname = this.selectedEmployee.data().firstname;
      this.supervisors.lastname = this.selectedEmployee.data().lastname;
      this.supervisors.email = this.selectedEmployee.data().username;
      this.supervisors.id = this.selectedEmployee.ref.id;
      firebase
        .firestore()
        .collection("schools/")
        .doc(this.$store.state.school)
        .collection("employees")
        .doc(this.empID)
        .collection("supervisors")
        .add(this.supervisors)
        .then(() => {
          this.selectedEmployee = "";
          this.superdialog = false;
          this.supervisors = {};
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
    deleteSubject(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            firebase
              .firestore()
              .collection("schools/")
              .doc(this.$store.state.school)
              .collection("employees")
              .doc(this.empID)
              .collection("assign")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch((error) => {
                alert("Error removing document: ", error);
              });

            firebase
              .firestore()
              .collection(title + "/teachers")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch((error) => {
                alert("Error removing document: ", error);
              });
          }
        },
      });
    },

    deleteSubordinate(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            firebase
              .firestore()
              .collection("schools/")
              .doc(this.$store.state.school)
              .collection("employees")
              .doc(this.empID)
              .collection("subordinates")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch((error) => {
                alert("Error removing document: ", error);
              });
          }
        },
      });
    },
    deleteSupervisor(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            firebase
              .firestore()
              .collection("schools/")
              .doc(this.$store.state.school)
              .collection("employees")
              .doc(this.empID)
              .collection("supervisors")
              .doc(id)
              .delete()
              .then(() => {
                // router.push({
                //   name: 'BoardList'
                // })
              })
              .catch((error) => {
                alert("Error removing document: ", error);
              });
          }
        },
      });
    },
  },

  created() {
    this.schoolsRef.where("active", "==", true).onSnapshot((querySnapshot) => {
      this.schools = [];
      querySnapshot.forEach((doc) => {
        this.schools.push({
          text: doc.data().title,
          value: doc.data().title,
        });
      });
    });
    this.ref.onSnapshot((querySnapshot) => {
      this.allemployees = [];
      querySnapshot.forEach((doc) => {
        this.allemployees.push({
          value: doc,
          text: doc.data().firstname + " " + doc.data().lastname,
        });
      });
    });

    this.jobsRef.onSnapshot((querySnapshot) => {
      this.jobs = [];
      querySnapshot.forEach((doc) => {
        this.jobs.push({
          text: doc.data().title,
          value: doc.data().title,
        });
      });
    });

    this.employeeRef.get().then((doc) => {
      if (doc.exists) {
        this.employees = doc.data();
        this.empID = doc.ref.id;

        if (this.empID != "") {
          firebase
            .firestore()
            .collection("schools/")
            .doc(this.$store.state.school)
            .collection("employees")
            .doc(this.empID)
            .collection("assign")
            .onSnapshot((querySnapshot) => {
              this.teacherSubjects = [];
              querySnapshot.forEach((doc) => {
                this.teacherSubjects.push({
                  key: doc.id,
                  path: doc.ref.path,
                  school: doc.data().school,
                  grade: doc.data().grade,
                  class: doc.data().class,
                  subject: doc.data().subject,
                  subjectTitle: doc.data().subjectTitle,
                });
              });
            });

          firebase
            .firestore()
            .collection("schools/")
            .doc(this.$store.state.school)
            .collection("employees")
            .doc(this.empID)
            .collection("subordinates")
            .onSnapshot((querySnapshot) => {
              this.teacherSubordinate = [];
              querySnapshot.forEach((doc) => {
                this.teacherSubordinate.push({
                  key: doc.id,
                  path: doc.ref.path,
                  firstname: doc.data().firstname,
                  lastname: doc.data().lastname,
                  reporting: doc.data().reporting,
                });
              });
            });

          firebase
            .firestore()
            .collection("schools/")
            .doc(this.$store.state.school)
            .collection("employees")
            .doc(this.empID)
            .collection("supervisors")
            .onSnapshot((querySnapshot) => {
              this.teacherSupervisor = [];
              querySnapshot.forEach((doc) => {
                this.teacherSupervisor.push({
                  key: doc.id,
                  path: doc.ref.path,
                  firstname: doc.data().firstname,
                  lastname: doc.data().lastname,
                  reporting: doc.data().reporting,
                });
              });
            });
        }
      } else {
        alert("No such document!");
      }

      this.rules.academic = this.employees.access.academic
        ? this.employees.access.academic
        : false;
      this.rules.students = this.employees.access.students
        ? this.employees.access.students
        : false;
      this.rules.admission = this.employees.access.admission
        ? this.employees.access.admission
        : false;
      this.rules.exam = this.employees.access.exam
        ? this.employees.access.exam
        : false;
      this.rules.salaries = this.employees.access.salaries
        ? this.employees.access.salaries
        : false;
      this.rules.hr = this.employees.access.hr
        ? this.employees.access.hr
        : false;
      this.rules.fleet = this.employees.access.fleet
        ? this.employees.access.fleet
        : false;
      this.rules.accounting = this.employees.access.accounting
        ? this.employees.access.accounting
        : false;
      this.rules.setting = this.employees.access.setting
        ? this.employees.access.setting
        : false;
    });
  },
};
</script>
