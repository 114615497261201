<template>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title>View Assignment</v-card-title>
        <v-card-subtitle> {{ this.$route.params.title }} </v-card-subtitle>

        <v-card-text>
          <div>
            <v-stepper v-model="e1">
              <b-form @submit.stop.prevent="onSubmit">
                <v-stepper-header>
                  <v-stepper-step editable :complete="e1 > 1" step="1">
                    Assignment Type
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step editable :complete="e1 > 2" step="2">
                    Assignment Options
                  </v-stepper-step>
                  <v-divider></v-divider>

                  <v-stepper-step editable step="3">
                    Assignment Schedule
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <b-card height="500px">
                      <!--Assignment Type -->
                      <b-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="3">
                            <b>Assignment Type:</b> {{ assign.type }}
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <b>Assignment Title:</b> {{ assign.title }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="6" md="3">
                            <b>Assignment Weight:</b> {{ assign.weight }}
                          </v-col>

                          <v-col cols="12" sm="6" md="2">
                            <b>Assignment Grade:</b> {{ assign.grade }}
                          </v-col>
                        </v-row>
                      </b-card-text>
                      <!-- End Assignment Type -->
                      <template v-slot:footer>
                        <v-btn color="info" @click="backStep">
                          Back
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="nextStep"
                          style="margin-left:10px"
                        >
                          Continue
                        </v-btn>
                      </template>
                    </b-card>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <b-card height="500px">
                      <b-card-text>
                        <b>Assignment File:</b>
                        <a :href="assign.file" target="_blank"
                          >Click Here to View The Attached File</a
                        >

                        <!-- End Of File -->

                        <div v-if="assign.type == 'Quiz Assignment'">
                          <!-- Quiz Area  -->

                          <b>Quiz Type:</b> {{ assign.quiztype }}

                          <!--Random Questions-->

                          <div
                            v-if="
                              assign.quiztype == 'Random From Question Bank'
                            "
                          >
                            <v-row>
                              <v-col cols="4">
                                <b>Random Questions Count:</b>
                                {{ assign.count }}
                              </v-col>
                            </v-row>
                          </div>

                          <!--EndRandom Questions-->
                        </div>
                        <div v-else-if="assign.type == 'Textbook Assignment'">
                          <b>Assignment Description:</b>
                          <span v-html="description" class="foo"></span>
                          <br />
                          <b>Reading Paragraph:</b>
                          <span v-html="reading" class="foo"></span>
                        </div>
                      </b-card-text>
                      <template v-slot:footer>
                        <v-btn color="info" @click="backStep">
                          Back
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="nextStep"
                          style="margin-left:10px"
                        >
                          Continue
                        </v-btn>
                      </template>
                    </b-card>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <b-card height="300px">
                      <b-card-text>
                        <v-row>
                          <!-- Start Date Name -->
                          <v-col cols="12" sm="6" md="4">
                            <b>Assignment Open Date:</b>
                            {{ assign.opendate }}
                          </v-col>
                          <!-- End Date Name -->

                          <!-- Start Date Name -->
                          <v-col cols="12" sm="6" md="4">
                            <b>Assignment Due Date:</b>
                            {{ assign.cutoff }}
                          </v-col>
                          <!-- Start Date Name -->
                          <v-col cols="12" sm="6" md="4">
                            <b>Assignment Close Date:</b>
                            {{ assign.closedate }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="2">
                            <b>Number of allowed attempts:</b>
                            {{ assign.attempts }}
                          </v-col>
                        </v-row>
                        <!-- End Date Name -->
                      </b-card-text>
                      <template v-slot:footer>
                        <v-btn color="primary" @click="onSubmit">
                          Back To Assignments List
                        </v-btn>
                      </template>
                    </b-card>
                  </v-stepper-content>
                </v-stepper-items>
              </b-form>
            </v-stepper>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "ViewAssignment",
  props: ["week", "title"],
  components: {},
  data() {
    return {
      e1: 1,
      menu: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      assign: {},
      selected: null,
      standards: [],
      dialog: false,
      selectedquestion: "",
      fixedQuestions: [],
      questionsbank: [],
      subject: this.$store.state.subjectTitle,
      weekpath: localStorage.getItem("weekpath"),
      fields: [
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "100px"
        }
      ],
      ref: firebase.firestore().doc(this.$route.params.path),

      quiztypes: [
        {
          value: "Random From Question Bank",
          text: "Random From Question Bank"
        },
        {
          value: "Fixed Questions",
          text: "Fixed Questions"
        }
      ],
      types: [
        { value: "Quiz Assignment", text: "Digitized Assignment" },
        { value: "Textbook Assignment", text: "Assignment" }
      ],

      weights: [],
      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.assign = snapshot.data();
        // this.fixedQuestions = snapshot.data().fixedquestions;
        firebase
          .firestore()
          .doc(this.$route.params.path + "/details/fixedquestions")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.fixedquestions = snapshot.data().fixedquestions;
          });

        firebase
          .firestore()
          .doc(this.$route.params.path + "/details/description")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.description = snapshot.data().description;
          });

        firebase
          .firestore()
          .doc(this.$route.params.path + "/details/reading")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.reading = snapshot.data().reading;
          });
      });
  },
  methods: {
    deleteQuestion(index) {
      this.fixedQuestions.splice(index, 1);
    },
    onSubmit() {
      router.back();
    },

    addToFixed() {
      if (this.fixedQuestions.includes(this.selectedquestion)) {
        alert("This Question alrady in this Quiz");
      } else {
        this.fixedQuestions.push(this.selectedquestion);
      }
    },

    clearFile(path) {
      firebase
        .firestore()
        .doc(path)
        .update({ file: null })
        .then(() => {
          this.$bvToast.toast("File Removed", {
            title: "File Removed Successfully",
            variant: "success",
            solid: true
          });
          this.link = null;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    nextStep() {
      if (this.e1 < 3) {
        this.e1 = Number(this.e1) + 1;
      } else {
        this.e1 = 1;
      }
    },
    backStep() {
      if (this.e1 > 1) this.e1 = Number(this.e1) - 1;
    }
  }
};
</script>
<style scoped>
.foo >>> img {
  width: 400px;
}
</style>
