<template>
  <b-row>
    <b-col cols="12">
      <AddStatus></AddStatus>
      <br />
      <h3>Admission Status</h3>

      <vue-good-table
        :columns="fields"
        :rows="status"
        :search-options="{ enabled: true, skipDiacritics: true }"
        :pagination-options="{ enabled: true, mode: 'records' }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <button
              class="btn btn-danger btn-sm"
              @click="deleteboard(props.row.key, props.row.grade)"
            >
              <b-icon-trash></b-icon-trash>
            </button>
            <button
              class="btn btn-success btn-sm"
              style="margin-left:5px;"
              @click="transferStudents(props.row.title)"
            >
              Transfer to SIS
            </button>
          </span>
        </template>
      </vue-good-table>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
// import router from "@/router";
import AddStatus from "./AddStatus";

export default {
  name: "StatusList",
  components: {
    AddStatus
  },

  data() {
    return {
      fields: [
        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "Action", class: "text-center" }
      ],
      status: [],
      students: [],
      student: [],
      errors: [],
      semail: "",
      ref: firebase
        .firestore()
        .collection(
          "/schools/" + this.$store.state.school + "/admission_status"
        )
    };
  },
  created() {
    this.ref.onSnapshot(querySnapshot => {
      this.status = [];
      querySnapshot.forEach(doc => {
        this.status.push({
          key: doc.id,
          path: doc.ref.path,
          title: doc.data().title,
          type: doc.data().type
        });
      });
    });
  },
  methods: {
    transferStudents(status) {
      // firebase
      //   .firestore()
      //   .collection("/schools/" + this.$store.state.school + "/admission")
      //   .where("admission_current", "==", status)
      //   .onSnapshot(querySnapshot => {
      //     this.students = [];
      //     querySnapshot.forEach(doc => {
      //       this.students.push({
      //         school: this.$store.state.school,
      //         branch: doc.data().branch,
      //         grade: doc.data().grade,
      //         firstname: doc.data().firstname,
      //         middlename: doc.data().middlename,
      //         lastname: doc.data().lastname,
      //         citizenship: doc.data().citizenship,
      //         date_of_birth: doc.data().date_of_birth,
      //         father_academic_degree: doc.data().father_academic_degree,
      //         father_company_business: doc.data().father_company_business,
      //         father_email: doc.data().father_email,
      //         father_mobile: doc.data().father_mobile,
      //         father_occupation: doc.data().father_occupation,
      //         mother_academic_degree: doc.data().mother_academic_degree,
      //         mother_email: doc.data().mother_email,
      //         mother_mobile: doc.data().mother_mobile,
      //         mother_name: doc.data().mother_name,
      //         mother_occupation: doc.data().mother_occupation,
      //         previous_school: doc.data().previous_school,
      //         religion: doc.data().religion,
      //         second_language: doc.data().second_language,
      //         email:
      //           doc
      //             .data()
      //             .firstname.trim()
      //             .toLowerCase() +
      //           "." +
      //           doc
      //             .data()
      //             .lastname.trim()
      //             .toLowerCase() +
      //           "@icb-school.con",
      //         username:
      //           doc
      //             .data()
      //             .firstname.toLowerCase()
      //             .trim() + Math.floor(Math.random() * Math.floor(900000)),
      //         password: Math.floor(Math.random() * 900000 + 1000000).toString()
      //       });
      //     });

      // if (this.students.length > 0) {
      this.$confirm({
        message:
          "Are you sure You want to transfer " + status + " students to SIS",
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection("schools/" + this.$store.state.school + "/admission")
              .where("admission_current", "==", status)
              .onSnapshot(querySnapshot => {
                querySnapshot.forEach(doc => {
                  this.student = doc.data();
                  this.student.educationPath =
                    "schools/" +
                    doc.data().school +
                    "/branches/" +
                    doc.data().branch +
                    "/grades/" +
                    doc.data().grade;

                  this.student.username =
                    doc
                      .data()
                      .firstname.toLowerCase()
                      .trim() + Math.floor(Math.random() * Math.floor(900000));
                  this.student.password = Math.floor(
                    Math.random() * 900000 + 1000000
                  );

                  firebase
                    .firestore()
                    .collection(
                      "schools/" + this.$store.state.school + "/StudentsData"
                    )
                    .doc(this.student.username)
                    .set(this.student)
                    .then(() => {})
                    .catch(() => {});
                });
              });
          }
        }
      });
      // } else {
      //   this.$confirm({
      //     message: "There's no Students in this status",
      //     button: {
      //       no: "Ok"
      //     }
      //   });
      // }
    },

    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(
                "/schools/" + this.$store.state.school + "/admission_status"
              )
              .doc(id)
              .delete()
              .then(() => {
                this.$bvToast.toast("Status Deleted", {
                  title: "Status Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    }
  }
};
</script>
