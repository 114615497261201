<template>
  <v-container>
    <v-toolbar style="background-color:#d7e9f9" flat>
      <v-toolbar-title>
        <v-icon color="orange" size="30px">mdi-ballot-outline</v-icon>
        &nbsp;{{ this.title }}s
      </v-toolbar-title>
    </v-toolbar>

    <AddNew
      :path="this.path"
      :editpath="this.editpath"
      :title="this.title"
      :fields="this.fields"
    ></AddNew>
    <vue-good-table
      :columns="this.myfields"
      :rows="this.rows"
      :search-options="{ enabled: true, skipDiacritics: true }"
      :pagination-options="{ enabled: true, mode: 'records' }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <button
            class="btn btn-danger btn-sm"
            @click="deleteboard(props.row.key, props.row.title)"
          >
            <v-icon color="white">mdi-trash-can-outline</v-icon>
          </button>

          <button
            class="btn btn-info btn-sm"
            style="margin-left: 5px; margin-right: 5px;"
            @click="editModal(props.row.path)"
          >
            <v-icon color="white">mdi-pencil-outline</v-icon>
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="viewModal(props.row.path)"
          >
            <v-icon color="white">mdi-view-headline</v-icon>
          </button>
        </span>

        <span v-else-if="props.column.field == 'childs'">
          <v-btn
            small
            :color="child.color"
            style="margin-left: 5px"
            v-for="child in childs"
            :key="child.id"
            @click="goToChilds(child.link, child.where_left, props.row.no)"
            >{{ child.label }}</v-btn
          >
        </span>

        <span v-else>
          {{
            props.row[props.column.field] != null
              ? props.row[props.column.field]
              : props.row[props.column.field]
          }}
        </span>
      </template>
    </vue-good-table>
  </v-container>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";
import AddNew from "@/components/dna/crud/AddNew.vue";
import EditRecord from "@/components/dna/crud/EditRecord.vue";
import ViewRecord from "@/components/dna/crud/ViewRecord.vue";

export default {
  name: "CRUD",
  props: [
    "icon",
    "title",
    "path",
    "fields",
    "childs",
    "width",
    "height",
    "where_right",
    "where_left",
    "newpage"
  ],

  components: {
    AddNew
  },

  data() {
    return {
      // master: this.$store.state.master,
      myfields: [],
      tempfields: [],
      rows: [],
      errors: [],
      editpath: ""
    };
  },
  created() {
    this.tempfields = [];
    this.myfields = [];

    this.fields.forEach(field => {
      if (field.ftype != "const") {
        this.myfields.push(field);
      }
    });

    this.myfields.push({
      field: "actions",
      label: "Action",
      class: "text-center",
      width: "180px"
    });

    this.myfields.push({
      field: "childs",
      label: "",
      class: "text-center",
      hidden: !this.childs
    });

    if (this.where_left != null) {
      firebase
        .firestore()
        .collection(this.path)
        .where(this.where_left, "==", this.where_right)
        .onSnapshot(querySnapshot => {
          this.rows = [];
          querySnapshot.forEach(doc => {
            this.rows.push(
              Object.assign(
                {
                  key: doc.id,
                  path: doc.ref.path
                },
                doc.data()
              )
            );
          });
        });
    } else {
      firebase
        .firestore()
        .collection(this.path)
        .onSnapshot(querySnapshot => {
          this.rows = [];
          querySnapshot.forEach(doc => {
            this.rows.push(
              Object.assign(
                {
                  key: doc.id,
                  path: doc.ref.path
                },
                doc.data()
              )
            );
          });
        });
    }
  },
  methods: {
    editModal(value) {
      if (this.newpage) {
        router.push({
          path: "editrecord",
          component: EditRecord,
          props: {
            title: this.title,
            path: this.path,
            fields: this.fields,
            editpath: value,
            newpage: this.newpage
          }
        });
      } else {
        this.$modal.show(
          EditRecord,
          {
            title: this.title,
            path: this.path,
            fields: this.fields,
            editpath: value,
            newpage: this.newpage
          },
          { width: this.width, height: this.height }
        );
      }
    },

    viewModal(value) {
      this.$modal.show(
        ViewRecord,
        {
          title: this.title,
          path: this.path,
          fields: this.fields,
          editpath: value
        },
        { width: this.width, height: this.height }
      );
    },
    deleteboard(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.path)
              .doc(id)
              .delete()
              .then(() => {})
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },

    goToChilds(link, left, right) {
      router.push({
        name: link,
        params: { where_left: left, where_right: right }
      });
    }
  }
};
</script>
