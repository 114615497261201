<template>
  <b-row>
    <b-col cols="12">
      <b-button block v-b-toggle="this.title" variant="success"
        >Click Here To Add New {{ this.title }}</b-button
      >
      <b-collapse :id="this.title" class="mt-2">
        <v-card flat outlined>
          <v-card-title>
            <v-icon large left>mdi-playlist-plus</v-icon>
            <span class="title font-weight-light"
              >Add New {{ this.title }}</span
            >
          </v-card-title>

          <v-container>
            <b-form @submit.stop.prevent="onSubmit">
              <!-- Job Title -->
              <v-row center-block>
                <v-col
                  cols="12"
                  md="8"
                  v-for="field in this.fields"
                  :key="field.id"
                >
                  <div v-if="field.ftype == 'number'">
                    <v-text-field
                      :label="field.label"
                      v-model.trim="newfield[field.field]"
                    ></v-text-field>
                  </div>
                  <div v-else-if="field.ftype == 'ref'">
                    <DropDown
                      :path="field.path"
                      v-model.trim="newfield[field.field]"
                      :title="field.label"
                      :lookup="field.lookup"
                    ></DropDown>
                  </div>
                  <div v-else-if="field.ftype == 'refid'">
                    <DropDownID
                      :path="field.path"
                      v-model.trim="newfield[field.field]"
                      :title="field.label"
                      :lookup="field.lookup"
                      :id="field.id"
                    ></DropDownID>
                  </div>
                  <div v-else-if="field.ftype == 'area'">
                    <v-textarea
                      :label="field.label"
                      v-model.trim="newfield[field.field]"
                    ></v-textarea>
                  </div>
                  <div v-else-if="field.ftype == 'const'">
                    <v-text-field
                      :label="field.label"
                      v-show="false"
                      :value="field.value"
                      v-model.trim="newfield[field.field]"
                    ></v-text-field>
                  </div>
                  <div v-else-if="field.ftype == 'editor'">
                    <p>{{ field.label }}</p>
                    <quill-editor
                      v-model="newfield[field.field]"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                    />
                  </div>
                  <div v-else-if="field.ftype == 'date'">
                    <label for="example-datepicker">{{ field.label }}</label>
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model.trim="newfield[field.field]"
                      class="mb-2"
                    ></b-form-datepicker>
                  </div>

                  <div v-else-if="field.ftype == 'file'">
                    <!-- Upload File -->
                    <v-col cols="12" md="12">
                      <b-form-group
                        id="livefile"
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        :label="field.label"
                        description="Max Size 30 MB"
                      >
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-file-input
                              v-model="files"
                              show-size
                              accept=".pdf, .pptx, .doc, .docx"
                              prepend-icon="mdi-file"
                              label="File"
                              @change="previewImage"
                            ></v-file-input>
                            {{ uploadValue.toFixed() + "%" }}
                            <v-progress-linear
                              id="progress"
                              color="light-blue"
                              height="10"
                              :value="uploadValue"
                              max="100"
                              striped
                            ></v-progress-linear>
                          </v-col>
                          <v-col cols="12" md="12">
                            <b-button @click="onUpload" variant="primary"
                              >Upload File</b-button
                            >
                          </v-col>
                        </v-row>
                        <!--  <b-form-file
                        type="file"
                        @change="previewImage"
                        v-validate="{ size: 1 }"
                        accept="pdf/*"
                      />-->
                      </b-form-group>
                    </v-col>

                    <!-- End Of Uploading -->
                  </div>

                  <div v-else>
                    <v-text-field
                      :label="field.label"
                      v-model.trim="newfield[field.field]"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-card-actions>
                <!-- Submit Form -->
                <v-divider></v-divider>
                <b-button type="submit" variant="primary"
                  >Add {{ this.title }}</b-button
                >
              </v-card-actions>
            </b-form>
          </v-container>
        </v-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import DropDown from "@/components/dna/crud/DropDown";
import DropDownID from "@/components/dna/crud/DropDownID";

export default {
  name: "AddNew",
  props: ["icon", "title", "path", "fields"],
  components: { DropDown, DropDownID },
  data() {
    return {
      myfields: this.fields,
      ref: firebase.firestore().collection(this.path),
      newfield: {},
      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.fields.forEach(field => {
        if (field.ftype == "const") {
          this.newfield[field.field] = field.value;
        } else if (field.ftype == "refid") {
          this.newfield[field.field + "_code"] = this.newfield[field.field][1];
          this.newfield[field.field] = this.newfield[field.field][0];
        }
      });

      this.ref
        .add(this.newfield)
        .then(() => {
          this.newfield.title = "";
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
