<template>
  <v-card flat>
    <!--  <v-card-title v-html="activities[current].title"></v-card-title>-->
    <v-card-title>
      <small>{{ this.current + 1 }} / {{ this.activities.length }}</small>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn color="primary" fab dark @click="back()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-divider></v-divider>
      <v-btn color="primary" fab dark @click="next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div v-if="activities[current].type == 'video'">
        <iframe
          v-if="activities[current].link.includes('vimeo')"
          :src="activities[current].link"
          width="100%"
          height="300px"
          style="border:0"
        ></iframe>
        <video-embed :src="activities[current].link" v-else></video-embed>

        <h5 v-html="activities[current].title" style="padding-top: 20px"></h5>
      </div>

      <div v-else-if="activities[current].type == 'show'">
        <iframe
          width="100%"
          height="600"
          :src="activities[current].link"
          frameborder="0"
        >
        </iframe>

        <!-- <VueDocPreview v-else :value="activities[current].link" type="office" />-->
      </div>

      <div v-else-if="activities[current].type == 'Live Session'">
        <h4>The session starts at:</h4>
        <br />
        {{ activities[current].livedate }}
        <br />
        {{ activities[current].livetime }}
        <br />
        <br />
        <b-button
          variant="success"
          size="sm"
          @click="goToLive(activities[current].livelink)"
          >Join The Video Session</b-button
        >
      </div>

      <div v-else style="width: 100%; height: 600px">
        <Question :path="activities[current].path" :key="current"></Question>
      </div>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import Question from "@/components/dna/Question";
import router from "@/router";
// import VueDocPreview from "vue-doc-preview";
export default {
  name: "ListPage",
  props: ["path"],
  components: {
    Question
    // VueDocPreview
  },
  data() {
    return {
      activities: [],
      questions: [],
      current: 0,
      record: {}
    };
  },
  methods: {
    goToLive(link) {
      window.open(link, "_blank");
    },

    next() {
      if (this.current < this.activities.length - 1) {
        this.current++;
      } else {
        this.record.date = new Date().toLocaleString();
        this.record.right = this.$store.state.rightAnswers;
        this.record.wrong = this.$store.state.wrongAnswers;
        this.record.skipped =
          this.questions.length -
          (this.$store.state.wrongAnswers + this.$store.state.rightAnswers);

        this.record.subject = this.$store.state.subject;
        this.record.subject_title = this.$store.state.subjectTitle;
        this.record.type = "Lesson";
        this.record.weekTitle = this.$store.state.weekTitle;
        this.record.totalQuestions = this.questions.length;

        firebase
          .firestore()
          .collection("profiles/" + this.$store.state.email + "/activities")
          .add(this.record)
          .then(() => {
            this.$store.state.totalQuestions = this.questions.length;
            router.replace({
              name: "activityReport"
            });
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    }
  },
  created() {
    firebase
      .firestore()
      .collection(this.path)
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.activities = [];

        querySnapshot.forEach(doc => {
          this.activities.push({
            key: doc.id,
            path: doc.ref.path,
            link: doc.data().link,
            order: doc.data().order,
            title: doc.data().title,
            type: doc.data().type,
            livelink: doc.data().livelink,
            livedate: doc.data().livedate,
            livetime: doc.data().livetime
          });
        });
      });

    firebase
      .firestore()
      .collection(this.$route.params.path + "/lesson")
      .where("type", "==", "question")

      .onSnapshot(querySnapshot => {
        this.questions = [];

        querySnapshot.forEach(doc => {
          this.questions.push({
            key: doc.id
          });
        });
      });
  }
};
</script>
