<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon style="padding-right:10px">mdi-human-edit</v-icon> Self
          Service
        </v-card-title>
        <v-card-text>
          <p>
            <v-alert
              color="#2A3B4D"
              dark
              icon="mdi-human-capacity-increase"
              dense
              >Supervisors
            </v-alert>
          </p>
          <vue-good-table :columns="fields" :rows="supervisors">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <Vacation />
              </span>
              <span v-else-if="props.column.field == 'firstname'"
                >{{ props.row.firstname + " " + props.row.lastname }}
              </span>
              <span v-else-if="props.column.field == 'reporting'"
                >{{ props.row.reporting }}
              </span>
            </template>
          </vue-good-table>
          <br />
          <p>
            <v-alert
              color="orange"
              dark
              icon="mdi-human-capacity-decrease"
              dense
              >Subordinates
            </v-alert>
          </p>
          <vue-good-table :columns="fields" :rows="subordinates">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <p>
                  <Termination />
                </p>

                <p>
                  <v-btn color="info" small depressed dark block
                    >Deduction Request</v-btn
                  >
                </p>

                <p>
                  <v-btn
                    color="blue-grey darken-1
"
                    small
                    depressed
                    dark
                    block
                    >Bonus request</v-btn
                  >
                </p>
                <p>
                  <v-btn
                    color="brown darken-1
"
                    small
                    depressed
                    dark
                    block
                    >Timesheet</v-btn
                  >
                </p>
              </span>
              <span v-else-if="props.column.field == 'firstname'"
                >{{ props.row.firstname + " " + props.row.lastname }}
              </span>
              <span v-else-if="props.column.field == 'reporting'"
                >{{ props.row.reporting }}
              </span>
            </template>
          </vue-good-table>
          <br />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import Vacation from "./requests/Vacation";
import Termination from "./requests/Termination";
export default {
  components: { Vacation, Termination },
  data() {
    return {
      fields: [
        {
          field: "reporting",
          label: "Reporting Type",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firstname",
          label: "Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Actions",
          class: "text-center",
          width: "220px"
        }
      ],
      subfields: [
        {
          field: "reporting",
          label: "Reporting Type",
          sortable: true,
          class: "text-left"
        },
        {
          field: "firstname",
          label: "Name",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Actions",
          class: "text-center",
          width: "220px"
        }
      ],
      supervisors: {},
      subordinates: {}
    };
  },
  created() {
    firebase
      .firestore()
      .collection("schools/")
      .doc(this.$store.state.school)
      .collection("employees")
      .doc(this.$store.state.userID)
      .collection("supervisors")
      .get()
      .then(querySnapshot => {
        this.supervisors = [];
        querySnapshot.forEach(doc => {
          this.supervisors.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            reporting: doc.data().reporting
          });
        });
      });
    firebase
      .firestore()
      .collection("schools/")
      .doc(this.$store.state.school)
      .collection("employees")
      .doc(this.$store.state.userID)
      .collection("subordinates")
      .get()
      .then(querySnapshot => {
        this.subordinates = [];
        querySnapshot.forEach(doc => {
          this.subordinates.push({
            key: doc.id,
            path: doc.ref.path,
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            reporting: doc.data().reporting
          });
        });
      });
  }
};
</script>
