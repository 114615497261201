<template>
  <v-card flat outlined>
    <v-card-title>
      <span class="headline">
        <v-icon style="margin-right: 10px">mdi-table-arrow-left</v-icon>Import
        Questions
      </span>

      <v-divider></v-divider>
      <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
    </v-card-title>
    <v-card-text>
      <div class="app-container">
        <v-card flat outlined
          ><v-card-text>
            <upload-excel-component
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
            />
            <br />
            <v-progress-linear
              v-if="progress"
              color="light-blue"
              height="20"
              :value="progress"
              striped
            ></v-progress-linear>
            <br />
            <div v-if="tableData">Rows Count: {{ tableData.length }}</div>
            <div v-if="tableData">Rows Imported: {{ this.current }}</div>
            <br />
            <!--  <b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
            <b-button
              variant="success"
              style="color: #ffffff"
              href="https://firebasestorage.googleapis.com/v0/b/new-cerebral.appspot.com/o/templates%2Fquestion%20template.xlsx?alt=media&token=9dfd8ffa-5191-48fe-9820-ee1a697af482"
              >Download Excel Template</b-button
            >
            <b-button @click="importData" style="margin-left: 20px"
              >Start Importing</b-button
            >
            <!-- <b-button style="margin-left: 20px">Change Egyption</b-button>
         <b-button variant="info" @click="transfer" style="margin-left:20px;"
          >Start Transfer</b-button
        >-->

            <el-table
              :data="tableData"
              border
              highlight-current-row
              style="width: 100%; margin-top: 20px"
              empty-text="There Is No Data Yet"
            >
              <el-table-column
                v-for="item of tableHeader"
                :key="item"
                :prop="item"
                :label="item"
              />
            </el-table>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="green darken-1" text @click="dialog = false">Back</v-btn>
    </v-card-actions>
  </v-card>

  <!-- End Activities Hub -->
</template>
<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";
export default {
  params: ["path"],
  components: { UploadExcelComponent },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      dialog: false,
      question: {},
      details: {},
      option: {},
      domain: {},
      cluster: {},
      activities: [],
      options: [],
      newquestion: {},
      current: 0,
      progress: 0,
      standard:{}
    };
  },

  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    importData: function() {
      let arr = []
      let obj = {}
      for (var i = 0; i < this.tableData.length; i++) {

        this.question = {};
        this.details = {};
        this.options = [];
        arr = [];
        
        this.question.title = this.tableData[i]["title"] != undefined ? this.tableData[i]["title"] : "";
        this.question.difficulty = this.tableData[i]["difficulty"] != undefined ? this.tableData[i]["difficulty"] : "";
        this.question.qtype = this.tableData[i]["type"] != undefined ? this.tableData[i]["type"] : "";
        this.question.bloom = this.tableData[i]["bloom"] != undefined ? this.tableData[i]["bloom"]: "";
        this.question.dok = this.tableData[i]["dok"] !=undefined ? this.tableData[i]["dok"] : "";
        this.question.type = "question";
        if (this.tableData[i]["standard"] != undefined || this.tableData[i]["sub"] != undefined) {
          arr = [...arr,{standard:this.tableData[i]["standard"],sub:this.tableData[i]["sub"]}]
        this.question.standard = arr;
        }
        if(this.tableData[i]["right_answer"] != undefined){
          if (this.tableData[i]["right_answer"] == "Right") {
                  this.question.correct = true;
                } else {
                  this.question.correct = false;
                }
        }

        if (this.tableData[i]["sound"] != undefined) {
          this.question.sound = this.tableData[i]["sound"];
          this.question.qtype = "listening"
        } 

        //! Question Details
        this.details.body = this.tableData[i]["body"] != null ?this.tableData[i]["body"] : "";
        this.details.answer = this.tableData[i]["feedback"] != null ? this.tableData[i]["feedback"] : "";
        this.details.objectives = this.tableData[i]["objectives"] != undefined ? this.tableData[i]["objectives"] : "";
        this.details.right = this.tableData[i]["right_answer"] != undefined? this.tableData[i]["right_answer"] : "";

        if (this.tableData[i]["type"] == "mc") {
          this.options = [];
           obj = {}

          if (this.tableData[i]["option_1"] != null && this.tableData[i]["sound_1"] != null){
            obj = {
              title: this.tableData[i]["option_1"],
              grade: this.tableData[i]["grade_1"],
              sound: this.tableData[i]["sound_1"]
            }
            this.options.push(obj);
          }
           if(this.tableData[i]["option_1"] == null && this.tableData[i]["sound_1"] != null){
            obj = {
              sound: this.tableData[i]["sound_1"],
              grade: this.tableData[i]["grade_1"]
            }
            this.options.push(obj);
          } if(this.tableData[i]["option_1"] != null && this.tableData[i]["sound_1"] == null){
            obj = {
              title: this.tableData[i]["option_1"],
              grade: this.tableData[i]["grade_1"]
            }
            this.options.push(obj);
          }
       
          
            if (this.tableData[i]["option_2"] != null && this.tableData[i]["sound_2"] != null){
            obj = {
              title: this.tableData[i]["option_2"],
              grade: this.tableData[i]["grade_2"],
              sound: this.tableData[i]["sound_2"]
            }
            this.options.push(obj);
          }
          if(this.tableData[i]["option_2"] == null && this.tableData[i]["sound_2"] != null){
            obj = {
              sound: this.tableData[i]["sound_2"],
              grade: this.tableData[i]["grade_2"]
            }
            this.options.push(obj);
          }if(this.tableData[i]["option_2"] != null && this.tableData[i]["sound_2"] == null){
            obj = {
              title: this.tableData[i]["option_2"],
              grade: this.tableData[i]["grade_2"]
            }
            this.options.push(obj);
          }



            if (this.tableData[i]["option_3"] != null && this.tableData[i]["sound_3"] != null){
            obj = {
              title: this.tableData[i]["option_3"],
              grade: this.tableData[i]["grade_3"],
              sound: this.tableData[i]["sound_3"]
            }
            this.options.push(obj);
          }
          if(this.tableData[i]["option_3"] == null && this.tableData[i]["sound_3"] != null){
            obj = {
              sound: this.tableData[i]["sound_3"],
              grade: this.tableData[i]["grade_3"]
            }
            this.options.push(obj);
          }if(this.tableData[i]["option_3"] != null && this.tableData[i]["sound_3"] == null){
            obj = {
              title: this.tableData[i]["option_3"],
              grade: this.tableData[i]["grade_3"]
            }
            this.options.push(obj);
          }
      


            if (this.tableData[i]["option_4"] != null && this.tableData[i]["sound_4"] != null){
            obj = {
              title: this.tableData[i]["option_4"],
              grade: this.tableData[i]["grade_4"],
              sound: this.tableData[i]["sound_4"]
            }
            this.options.push(obj);
          }
          if(this.tableData[i]["option_4"] == null && this.tableData[i]["sound_4"] != null){
            obj = {
              sound: this.tableData[i]["sound_4"],
              grade: this.tableData[i]["grade_4"]
            }
            this.options.push(obj);
          }if(this.tableData[i]["option_4"] != null && this.tableData[i]["sound_4"] == null){
            obj = {
              title: this.tableData[i]["option_4"],
              grade: this.tableData[i]["grade_4"]
            }
            this.options.push(obj);
          }
           

        }
        this.addQuestion(this.question,this.details,this.options)

      }
    },
    addQuestion(quest,detail,opt) {
        //! Add question
        firebase
          .firestore()
          .collection(this.$route.params.path + "/questions/")
          .add(quest)
          .then(qu => {

            //! Add details
            firebase
              .firestore()
              .doc(qu.path + "/details/details")
              .set(detail)
              .then(() => {
                console.log("Question Success Added");
                this.current++;
                this.progress = (this.tableData.length / this.current) * 100;
              });

            //! Add Options

            opt.forEach(option => {
              firebase
                .firestore()
                .collection(qu.path + "/options")
                .add(option)
                .then(() => {});
            });

            //! Add slink
            if (quest?.standard?.length > 0) {
            this.standard.type = "Question";
            this.standard.source = qu.path;
            this.standard.lesson = this.$route.params.path;
            this.standard.standard = quest.standard[0].sub;
            this.standard.parent = quest.standard[0].standard;
                firebase
                .firestore()
              .collection(this.$store.state.subject + "/slink")
              .add(this.standard)
              .then(() => {
              })
            }
          })
          .catch(error => {
            console.log("Error adding document: ", error);
          });

    }
  }
};
</script>
