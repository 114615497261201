<template>
  <v-card flat outlined
    ><v-card-text>
      Students Count: {{ this.students.length }}<br />
      Weeks: {{ this.weeks }}<br />
      Participation: {{ this.hparticipationgrade }}
      <br />
      Assignments: {{ this.assignments.length }}
      <br />
      Assignments Total Grades: {{ this.assignmentstotal }}
      <br />
      {{ this.weightgrade }}

      <v-divider />

      <v-btn
        depressed
        small
        color="error"
        style="
        margin-left:10px"
        @click="createStudents"
        >Create Students Records</v-btn
      >
      <v-btn
        depressed
        small
        color="success"
        style="
        margin-left:10px"
        @click="createExam"
        >Calculate Exam Grade</v-btn
      >

      <v-btn
        depressed
        small
        color="info"
        style="
        margin-left:10px"
        @click="createParticipation"
        >Calculate Participation Grade</v-btn
      >

      <v-divider />

      <v-select
        :items="weightslist"
        v-model="selectedweight"
        label="Weight"
      ></v-select>

      <v-btn
        depressed
        small
        color="success"
        dark
        style="
        margin-left:10px"
        @click="getAssignments"
        >Get {{ selectedweight[0] }} Assignments
      </v-btn>
      <v-btn
        depressed
        small
        color="#121212"
        dark
        style="
        margin-left:10px"
        @click="createWeights"
        >Calculate {{ selectedweight[0] }} Grade</v-btn
      >

      <v-divider />

      <vue-good-table
        :columns="fields"
        :rows="students"
        :search-options="{ enabled: true, skipDiacritics: true }"
        :pagination-options="{ enabled: true, mode: 'records' }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <div v-if="$store.state.control"></div>
          </span>
        </template>
      </vue-good-table> </v-card-text
  ></v-card>
</template>

<script>
import firebase from "@/Firebase";

export default {
  name: "Test-page",

  data() {
    return {
      students: [],
      selectedweight: [],
      gradePath: this.$store.state.gradePath,
      fields: [
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },
        {
          field: "studentname",
          label: "Student Name",
          sortable: true,
          class: "text-left"
        },
        {
          field: "attendance",
          label: "Attendance",
          sortable: true,
          class: "text-left"
        },

        {
          field: "participation",
          label: "Participation",
          sortable: true,
          class: "text-left"
        },

        {
          field: "performance",
          label: "Performance Task",
          sortable: true,
          class: "text-left"
        },
        {
          field: "quiz",
          label: "Online Quiz",
          sortable: true,
          class: "text-left"
        },
        {
          field: "classwork",
          label: "Classwork",
          sortable: true,
          class: "text-left"
        },
        {
          field: "homework",
          label: "Homework",
          sortable: true,
          class: "text-left"
        },
        {
          field: "exam",
          label: "Exam",
          sortable: true,
          class: "text-left"
        },

        { field: "actions", label: "", class: "text-center" }
      ],

      weightslist: [
        {
          text: "Performance Task",
          value: ["Performance Task", "performance"]
        },
        { text: "Online Quiz", value: ["Online Quiz", "quiz"] },
        { text: "Classwork", value: ["Classwork", "classwork"] },
        { text: "Homework", value: ["Homework", "homework"] }
      ],
      studentscount: 0,

      //! Participatio
      subjectgrade: 0,
      participationheights: 0,
      participation: [],
      participationgrade: 0,
      totalparticipationgrade: 0,
      hparticipationgrade: 0,
      weeks: 0,

      //!Exam
      examgrade: 0,

      //!Weights
      assignments: [],
      assignmentstotal: 0,
      weightgrade: 0,
      count: 0,

      collection:
        this.$store.state.thisBranch +
        this.$store.state.thisGrade +
        this.$store.state.subjectTitle
    };
  },
  props: {},
  created() {
    this.loadStudents();
    this.assignmentstotal = 0;

    firebase
      .firestore()
      .collection(this.$store.state.subject + "/weeks")
      .where("breakdown", "==", "Quarter 4")
      .get()
      .then(weeks => {
        this.weeks = weeks.docs.length;
        weeks.forEach(doc => {
          //! Get Subject Assignments
          firebase
            .firestore()
            .collection(doc.ref.path + "/tasks")
            .where("weight", "==", "Online Quiz")
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(assign => {
                if (assign.data().graded != true) {
                  this.assignments.push({
                    key: assign.id,
                    grade: assign.data().grade,
                    //! Add Week title & Order to Assignments
                    week: doc.title,
                    weekorder: doc.order
                  });
                  this.assignmentstotal =
                    this.assignmentstotal + Number(assign.data().grade);
                }
              });
            });
        });
      });
    firebase
      .firestore()
      .doc(this.$store.state.subject)

      .get()
      .then(subject => {
        this.hparticipationgrade = subject.data().participation;
      });
  },
  methods: {
    getAssignments() {
      this.assignments = [];
      this.assignmentstotal = 0;
      this.weightgrade = 0;
      firebase
        .firestore()
        .collection(this.$store.state.subject + "/weeks")
        .where("breakdown", "==", this.$store.state.breakdown)
        .get()
        .then(weeks => {
          this.weeks = weeks.docs.length;
          weeks.forEach(doc => {
            //! Get Subject Assignments
            firebase
              .firestore()
              .collection(doc.ref.path + "/tasks")
              .where("weight", "==", this.selectedweight[0])
              .get()
              .then(querySnapshot => {
                querySnapshot.forEach(assign => {
                  if (assign.data().graded != true) {
                    this.assignments.push({
                      key: assign.id,
                      grade: assign.data().grade,
                      //! Add Week title & Order to Assignments
                      week: doc.title,
                      weekorder: doc.order
                    });
                    this.assignmentstotal =
                      this.assignmentstotal + Number(assign.data().grade);
                  }
                });
              });
          });
        });
    },
    createStudents() {
      this.students = [];
      this.studentscount = 0;
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.$store.state.thisBranch)
        .where("grade", "==", this.$store.state.thisGrade)
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            firebase
              .firestore()
              .collection(
                "schools/" + this.$store.state.school + "/" + this.collection
              )
              .doc(doc.id)
              .set({ ...doc.data(), ...{ attendance: 100 } })
              .then(() => {
                console.log("Student");
                this.studentscount++;
              });
          });
        });
    },
    // createAttentance() {
    //   firebase
    //     .firestore()
    //     .collection("schools" + this.$store.state.school + "students")
    //     .where("educationPath", "==", this.gradePath)
    //     .get()
    //     .then(querySnapshot => {
    //       querySnapshot.forEach(doc => {
    //         firebase
    //           .firestore()
    //           .collection("schools" + this.$store.state.school + "" + this.collection)
    //           .doc(doc.id)
    //           .set({ attendance: 100 })
    //           .then(() => {
    //             console.log("attendance");
    //           });

    //         // this.present = 0;
    //         // this.absent = 0;

    //         // //!Get Attentace
    //         // firebase
    //         //   .firestore()
    //         //   .collection(doc.ref.path + "/attendance")
    //         //   .where("subject", "==", this.$store.state.subjectTitle)
    //         //   .get()
    //         //   .then(attendance => {
    //         //     attendance.forEach(doc => {
    //         //       if (doc.data().type == "present") this.present++;
    //         //       else this.absent++;
    //         //     });
    //         //   });

    //         //! Set In students Collection
    //       });
    //     });
    // },
    createParticipation() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.$store.state.thisBranch)
        .where("grade", "==", this.$store.state.thisGrade)
        .get()
        .then(querySnapshot => {
          //! For Each Student
          querySnapshot.forEach(doc => {
            this.participation = [];
            this.participationgrade = 0;
            this.totalparticipationgrade = 0;

            firebase
              .firestore()
              .collection(doc.ref.path + "/participation")
              .where("subject", "==", this.$store.state.subjectTitle)
              .get()
              .then(querySnapshot => {
                this.participation = [];
                this.participationgrade = 0;
                this.totalparticipationgrade = 0;

                querySnapshot.forEach(doc => {
                  //! All Participation
                  this.participation.push({
                    key: doc.id,
                    date: doc.data().date,
                    grade: doc.data().grade
                  });
                  this.participationgrade =
                    this.participationgrade + Number(doc.data().grade);
                });

                this.totalparticipationgrade =
                  (this.participationgrade /
                    (this.weeks * this.hparticipationgrade)) *
                  100;

                firebase
                  .firestore()
                  .collection(
                    "schools/" +
                      this.$store.state.school +
                      "/" +
                      this.collection
                  )
                  .doc(doc.id)
                  .update({
                    participation: Math.round(this.totalparticipationgrade)
                  })
                  .then(() => {
                    console.log("Participation");
                  });
              });
          });
        });
    },
    createExam() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.$store.state.thisBranch)
        .where("grade", "==", this.$store.state.thisGrade)
        .get()
        .then(querySnapshot => {
          //! For Each Student
          querySnapshot.forEach(doc => {
            firebase
              .firestore()
              .collection(doc.ref.path + "/examgrades" + this.$store.state.subjectTitle)
              .get()
              .then(querySnapshot => {
                firebase
                  .firestore()
                  .collection(
                    "schools/" +
                      this.$store.state.school +
                      "/" +
                      this.collection
                  )
                  .doc(doc.id)
                  .update({
                    exam: querySnapshot.data().grade
                  });
              })
              .then(() => {
                console.log("Exam");
              });
          });
        });
    },
    async createWeights() {
      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/StudentsData")
        .where("branch", "==", this.$store.state.thisBranch)
        .where("grade", "==", this.$store.state.thisGrade)
        .get()
        .then(querySnapshot => {
          //! For Each Student
          querySnapshot.forEach(async doc => {
            var total = 0;

            //! get Assignments Grades
            this.assignments.forEach((assign, index) => {
              console.log(index);
              firebase
                .firestore()
                .doc(doc.ref.path + "/assignments/" + assign.key)
                .get()
                .then(ass => {
                  if (ass.data() != null) {
                    total += ass.data().right;
                  }

                  if ((index = this.assignments.length - 1)) {
                    const we = this.selectedweight[1];
                    console.log(we);
                    //! Update Student Doc
                    firebase
                      .firestore()
                      .collection(
                        "schools/" +
                          this.$store.state.school +
                          "/" +
                          this.collection
                      )
                      .doc(doc.id)
                      .update({
                        we: Math.round((total / this.assignmentstotal) * 100)
                      })
                      .then(() => {
                        console.log(this.selectedweight[0]);
                      });
                  }
                });
            });

            this.weightgrade = 0;
          });
        });
    },

    increment(num) {
      this.weightgrade = this.weightgrade + Number(num);
    },

    loadStudents() {
      this.students = [];
      firebase
        .firestore()
        .collection(
          "schools/" + this.$store.state.school + "/" + this.collection
        )
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.students.push({
              key: doc.id,
              path: doc.ref.path,
              attendance: doc.data().attendance,
              quiz: doc.data().quiz,
              participation: doc.data().participation,
              exam: doc.data().exam,
              studentname:
                doc.data().firstname +
                " " +
                doc.data().middlename +
                " " +
                doc.data().lastname,
              class: doc.data().class
            });
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
