<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-title>
          <img src="@/assets/fleet.png" class=" ma-2" width="44px" />
          <span class="title font-weight-light">Fleet Managment</span>
          <v-row align="center" justify="end"> </v-row>
        </v-card-title>

        <v-card-text>
          <b-card-group deck>
            <CountCard
              title="Cars"
              color="primary"
              :path="'/schools/' + this.$store.state.school + '/fleets'"
            ></CountCard>
            <CountCard title="Drivers" color="success"></CountCard>
            <CountCard
              title="Services"
              color="danger"
              :path="'/schools/' + this.$store.state.school + '/services'"
            ></CountCard>
          </b-card-group>
          <br />

          <v-card>
            <v-card-text>
              <div>
                <CRUD
                  title="Car"
                  :path="'/schools/' + this.$store.state.school + '/fleets'"
                  :fields="this.fields"
                  :childs="this.childs"
                  width="500"
                  height="70%"
                ></CRUD>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";
import CountCard from "@/components/dna/widgets/CountCard.vue";
export default {
  name: "FleetList",
  components: {
    CRUD,
    CountCard
  },

  data() {
    return {
      fields: [
        { field: "no", label: "Car No", sortable: true, class: "text-left" },
        {
          field: "board",
          label: "Board No",
          sortable: true,
          class: "text-left"
        },
        { field: "brand", label: "Brand", sortable: true, class: "text-left" },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "driver",
          label: "Driver",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/drivers",
          ftype: "ref",
          lookup: "name"
        },
        {
          field: "supervisor",
          label: "Supervisor",
          sortable: true,
          class: "text-left",
          path: "/schools/" + this.$store.state.school + "/supervisors",
          ftype: "ref",
          lookup: "name"
        }
      ],
      childs: [
        {
          field: "services",
          label: "Services",
          path: "/schools/" + this.$store.state.school + "/services",
          link: "Services",
          color: "primary",
          where_left: "car"
        },
        {
          field: "students",
          label: "Students",
          color: "error",
          path: "/schools/" + this.$store.state.school + "/services"
        }
      ],

      errors: []
    };
  },
  created() {},
  methods: {}
};
</script>

<style></style>
