<template>
  <div>
    <v-card flat outlined>
      <v-card-title> </v-card-title>

      <v-card-text>
        <div
          v-for="question in examquestions"
          :key="question"
          style="margin-bottom:10px"
        >
          <Question
            :path="question.path"
            source="Exam"
            :key="question.key"
          ></Question>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import Question from "./Question";
export default {
  name: "ExamQustion",
  props: ["section", "source"],
  components: {
    Question
  },

  data() {
    return {
      missing: "",
      questionID: "",
      wrong: false,
      right: false,
      question: {},
      options: [],
      answer: [],
      examquestions: [],
      question_body: "",
      question_answer: "",
      essay: "",
      checked: false,
      subject: this.$store.state.subject
    };
  },

  created() {
    //! Get Questions

    firebase
      .firestore()
      .collection(this.subject + "/examquestions")
      .where("section", "==", this.section)
      .get()
      .then(snapshot => {
        snapshot.forEach(question => {
          this.examquestions.push({
            key: question.id,
            path: question.ref.path
          });
        });
      });
  },

  methods: {}
};
</script>

<style scoped>
.a >>> img {
  width: 300px;
}
</style>
