<template>
  <div>
    <v-chip color="red" dark v-if="this.status == 'absent'"
      >{{ this.status }}
    </v-chip>
    <v-chip color="secondary" dark v-else-if="this.status == 'present'"
      >{{ this.status }}
    </v-chip>
    <v-chip color="info" dark v-else-if="this.status == 'excused'"
      >{{ this.status }}
    </v-chip>
    <v-chip color="success" dark v-else-if="this.status == 'late'"
      >{{ this.status }}
    </v-chip>
    <v-chip color="purple" dark v-else-if="this.status == 'leave'"
      >{{ this.status }}
    </v-chip>
    <div v-else>{{ this.status }}</div>
  </div>
</template>
<script>
import firebase from "@/Firebase";
export default {
  props: ["student", "date", "subject"],
  data() {
    return {
      status: ""
    };
  },
  created() {
    firebase
      .firestore()
      .doc(
        "/schools/" +
          this.$store.state.school +
          "/attendance/" +
          this.student +
          this.date +
          this.subject
      )
      .onSnapshot(snapshot => {
        this.status = "";
        this.status = snapshot.data().type;
      });
  }
};
</script>
