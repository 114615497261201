<template>
  <div>
    <v-card flat outlined>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <h5>{{ this.student }}</h5>
<!-- {{this.id}} -->
            <div v-if="this.type == 'Textbook Assignment'">
              <div v-if="this.attemps.length > 0">
                <div v-html="this.attemps[0].submission" class="foo" />
                <a :href="this.attemps[0].file" target="_blank"
                  >Check Submitted File</a
                >
                <br />
                Submission Date
                <br />
                {{ this.attemps[0].date }}

                <br />
                <v-divider />

                <video
                  width="320"
                  height="240"
                  controls
                  v-if="
                    this.attemps[0].video != '' && this.attemps[0].video != null
                  "
                >
                  <source :src="this.attemps[0].video" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <br />
                <v-divider />

                <audio
                  controls
                  v-if="
                    this.attemps[0].audio != '' && this.attemps[0].audio != null
                  "
                >
                  <source :src="this.attemps[0].audio" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>

            <span v-if="this.last">
              <v-btn color="info" x-small depressed style="margin-right:10px"
                >View All</v-btn
              >
              <b>Last Attempt:</b> {{ this.last }}

              <br />
              <v-btn color="warning" x-small depressed style="margin-right:10px"
                >Recalculate</v-btn
              >
            </span>
          </v-col>

          <v-col cols="6" v-if="this.attemps.length > 0">
            <v-text-field
              label="Grade"
              placeholder="Grade"
              v-model="grade"
              outlined
            ></v-text-field>
            <v-text-field
              label="Feedback"
              placeholder="Feedback"
              v-model="feedback"
              outlined
            ></v-text-field>
            <br />
            <v-btn
              color="success"
              block
              depressed
              small
              @click="updategrade(grade, feedback)"
              ><v-icon>mdi-check</v-icon>Submit</v-btn
            >
          </v-col>

          <v-col cols="6" v-else>
            <v-text-field
              label="Grade"
              placeholder="Grade"
              v-model="grade"
              outlined
            ></v-text-field>

            <br />
            <v-text-field
              label="Feedback"
              placeholder="Feedback"
              v-model="feedback"
              outlined
            ></v-text-field>
            <v-btn
              color="success"
              block
              depressed
              small
              @click="newgrade(grade, feedback)"
              ><v-icon>mdi-check</v-icon>Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row v-if="this.type != 'Textbook Assignment'">
          <v-col cols="4">
            <v-btn
          v-for="(attempt, i) in StudentAttempts" :key="i"
            depressed
            color="info"
            small
            style="margin-bottom: 5px;"
            @click="goToAttempts(id, assid, student,attempt.attempt)"
            >Go To Student Attempt: {{ attempt.attempt }} </v-btn
          >
          </v-col>
          <v-col cols="3">
            <p
          v-for="(attempt, i) in StudentAttempts" :key="i"
          style="margin-left: 17px;"
            > {{ new Date(attempt.date).toLocaleDateString()}}</p
          >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";
export default {
  name:"attempt--page",
  props: ["student", "id", "assignment", "type", "weight", "week","weektitle","weightcode","branch","gradelevel","assgrade"],
  data() {
    return {
      grade: 0,
      Highestgrade:0,
      maxAttempt:0,
      feedback: "",
      last: "",
      wrong: 0,
      right: 0,
      missed: 0,
      gradeList: [],
      attemps: [],
      newattemps: [],
      StudentAttempts:[],
      thisass: localStorage.getItem("asspath"),
      thisassgrade: localStorage.getItem("assgrade"),
      assid: localStorage.getItem("assid"),
      subjectPath: this.$store.state.originalsubject
    };
  },
  created() {

this.attemps = [];
this.grade = 0;
    firebase
      .firestore()
      .collection(this.subjectPath + "/submissions")
      .doc(this.id + this.assid)
      .get()
      .then(doc => {
        console.log(this.id + this.assid ,doc.data())
       if(doc.data()){
 this.maxAttempt = doc?.data()?.attempts
        this.attemps.push({
          key: doc?.id,
          path: doc?.ref.path,
          right: Number(doc?.data()?.right),
          grade: Number(doc?.data()?.grade),
          feedback: doc?.data()?.feedback,
          submission: doc?.data()?.submission,
          description: doc?.data()?.description,
          type: doc?.data()?.type,
          file: doc?.data()?.file,
          video: doc?.data()?.video,
          date: doc?.data()?.date,
          audio: doc?.data()?.audio
        });

        if (this.attemps.length > 0) {
          this.feedback = this.attemps[0].feedback ?? "";
          this.grade = doc?.data()?.right;
        }
      }
      this.getAttempts();

      });
  },
  methods: {
    getAttempts(){
      // firebase
      //   .firestore()
      //   .doc(
      //     "schools/" +
      //       this.$store.state.school +
      //       "/StudentsData/" +
      //       this.id +
      //       "/quizes/" +
      //       this.assid
      //   )
      //   .get()
      //   .then(doc =>{
          // console.log("key ==> ",this.assid," quiz ==>",doc?.data()?.highestRight)
          // console.log("max attempt ==>",this.maxAttempt)
         //this.grade = doc?.data()?.highestRight ?? 0;
         firebase
        .firestore()
        .collection(
          "schools/" +
            this.$store.state.school +
            "/StudentsData/" +
            this.id +
            "/quizes/" +
            this.assid +
            "/attempts"
        )
        .get()
        .then((querySnapshot) => {
          this.StudentAttempts = [];
          this.gradeList = []
          querySnapshot.forEach((doc) => {
            console.log("attempt ==>",doc.data())
            this.StudentAttempts.push({
              attempt: doc.data().attempts,
              text:String(doc.data().attempts),
              value:doc.data().attempts,
              date:doc.data().date,
              right: doc.data().right
            });
            if(Number(doc.data().attempts) < Number(this.maxAttempt) || Number(doc.data().attempts) == 1){
              this.gradeList.push(Number(doc.data().right))
            }
          }); 
          let max = Math.max(...this.gradeList) == -Infinity ? 0: Math.max(...this.gradeList)
          if(max != 0 && !Number.isNaN(max)){
            //if(!Number.isNaN(max)){
              this.grade = max
            // }else{
            //   this.grade = "n/a"
            // }
          }
          console.log(!Number.isNaN(max))
          console.log("max grade==>",this.grade)
        });
        // })
    },
    goToAttempts(student, assid, studentname,i) {
      console.log(student, assid, studentname)
      localStorage.setItem("studentid", student);
      localStorage.setItem("assignmentid", assid);
      localStorage.setItem("studentname", studentname);

      router.push({
        name: "StudentAttempts",
        params: {
          attempt:i,
        }
      });
    },
    updategrade(g, feed) {
      console.log(this.id + this.assid)
      console.log("grade to be update ==> ",g,feed)
      // if (g > this.assgrade) {
      //   this.$swal.queue([
      //     {
      //       icon: "error",
      //       title: "Grade Error",
      //       html: "The Grade You Entered Is Greater Than The Assignment Grade",
      //       timer: 2000
      //     }
      //   ]);
      // } else {
      firebase
        .firestore()
        .collection(this.subjectPath + "/submissions")
        .doc(this.id + this.assid)
        .update({
          right: g,
          grade: g,
          wrong:Number(this.assgrade) - Number(g),
          subject:this.subjectPath,
          asspath: this.thisass,
          assgrade: this.assgrade,
          feedback: feed,
          weight: this.weight,
          week: this.week,
          student: this.id,
          breakdown: this.$store.state.breakdown,
          attempts:1,
          weektitle:this.weektitle,
          weightcode:this.weightcode,
          branch:this.branch,
          gradelevel:this.gradelevel,
          type:this.type,
          date:new Date().toLocaleString('en-US', { timeZone: 'UTC' }),
          school:this.$store.state.school
        })
        .then(() => {
          this.$swal(
            {
              icon: "success",
              title: "Grade Updated",
              html: "Grade Updated Successfully",
              timer: 2000
            }
          );
        })
        // .catch(error => {
        //   alert("Error removing document: ", error);
        // });
      // }
    },
    newgrade(g, feed) {
      console.log(this.id + this.assid)
      console.log("grade to be new ==> ",g,feed)
      // if (g > this.assgrade) {
      //   this.$swal.queue([
      //     {
      //       icon: "error",
      //       title: "Grade Error",
      //       html: "The Grade You Entered Is Greater Than The Assignment Grade",
      //       timer: 2000
      //     }
      //   ]);
      // } else {
      firebase
        .firestore()
        .collection(this.subjectPath + "/submissions")
        .doc(this.id + this.assid)
        .set({
          right: g,
          grade: g,
          wrong:Number(this.assgrade) - Number(g),
          subject:this.subjectPath,
          asspath: this.thisass,
          assgrade: this.assgrade,
          feedback: feed,
          weight: this.weight,
          week: this.week,
          student: this.id,
          breakdown: this.$store.state.breakdown,
          attempts:1,
          weektitle:this.weektitle,
          weightcode:this.weightcode,
          branch:this.branch,
          gradelevel:this.gradelevel,
          type:this.type,
          date:new Date().toLocaleString('en-US', { timeZone: 'UTC' }),
          school:this.$store.state.school
        })
        .then(() => {
          this.$swal(
            {
              icon: "success",
              title: "Grade Updated",
              html: "Grade Updated Successfully",
              timer: 2000
            }
          );
        })
        // .catch(error => {
        //   alert("Error removing document: ", error);
        // });
    }
  }
  // }
};
</script>
<style scoped>
.foo >>> img {
  width: 400px;
}
</style>
