<template>
  <div>
    <!--    <p v-for="q in examquestions" :key="q.key">{{ q.path }}</p> -->
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          color="orange"
          style="margin-left: 20px"
          rounded
          depressed
          @click="finish()"
          small
        >
          <v-icon>mdi-chevron-right</v-icon> Finish Exam
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <div
            v-for="section in examsections"
            :key="section"
            style="margin-bottom:10px"
          >
            <h4>
              <b>{{ section.title }}</b>
            </h4>

            <Examsection :section="section.key" />
          </div>

          <!-- <div
            v-for="question in examquestions"
            :key="question"
            style="margin-bottom:10px"
          >
            <Question
              :path="question.path"
              source="Exam"
              :key="question.key"
            ></Question>
          </div> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="orange"
          style="margin-left: 20px"
          rounded
          depressed
          @click="finish()"
          small
        >
          <v-icon>mdi-chevron-right</v-icon> Finish Exam
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import firebase from "@/Firebase";
import router from "@/router";

import Examsection from "./Examsection";

export default {
  props: ["path"],
  components: {
    // Question
    Examsection
  },
  data() {
    return {
      subject: this.$store.state.subject,
      current: 0,
      count: 5,

      exam: {},
      qmatrix: [],
      examquestions: [],
      examsections: [],
      lessonquestions: [],
      record: []
    };
  },
  created() {
    this.$store.state.rightAnswers = 0;
    this.$store.state.wrongAnswers = 0;
    this.examquestions = [];
    this.examsections = [];

    //! Get Sections

    firebase
      .firestore()
      .collection(this.subject + "/examsections")
      .orderBy("order")
      .get()
      .then(snapshot => {
        snapshot.forEach(section => {
          this.examsections.push({
            key: section.id,
            path: section.ref.path,
            title: section.data().title
          });
        });
      });
  },

  methods: {
    finish() {
      this.record.date = new Date().toLocaleString();
      this.record.right = this.$store.state.rightAnswers;
      this.record.wrong = this.$store.state.wrongAnswers;
      this.record.skipped =
        this.count -
        (this.$store.state.wrongAnswers + this.$store.state.rightAnswers);

      this.record.subject = this.$store.state.subject;
      this.record.subject_title = this.$store.state.subjecttitle;
      this.record.type = "Exam";
      this.record.weektitle = this.$store.state.weektitle;
      this.record.weekpath = this.$store.state.weekpath;
      this.record.totalQuestions = this.count;

      router.push({ name: "Home" });
    },
    next() {
      if (this.current < this.examquestions.length - 1) {
        this.current++;
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      }
    }
  }
};
</script>
