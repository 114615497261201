<template>
  <div>
    <v-row>
      <v-col cols="3" :style="'background-color:' + this.color">
        <v-row>
          <v-card
            :min-height="this.weight * 10"
            width="200px"
            dark
            tile
            flat
            :style="'background-color:' + this.color"
          >
            <v-card-title>{{ this.weight }}%</v-card-title>
            <v-card-subtitle style="color:#ffffff">
              {{ this.title }}
            </v-card-subtitle></v-card
          >
        </v-row>
      </v-col>
      <v-col cols="7" :style="'background-color:' + this.fadecolor">
        {{ this.current }} Exam Grade
      </v-col>
      <v-col cols="2" :style="' text-align:center; align-self: center;'">
        {{ this.title }}<br />
        <h3>
          {{ this.grade == 0 ? "0" : ((this.grade / 100) * 100).toFixed(2) }} %
        </h3>
        <br />
        <br />
        <!-- <h5 style="color:red">
          {{
            this.grade == 0
              ? "0"
              : (((this.grade / 100) * 100).toFixed(2) * this.weight) / 100
          }}
        </h5> -->
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>
<script>
import firebase from "@/Firebase";

export default {
  name: "exam--page",
  components: {},
  props: [
    "title",
    "weight",
    "subject",
    "color",
    "fadecolor",
    "current",
    "student"
  ],

  data() {
    return {
      assignments: [],
      weeks: [],
      grade: 0,
      subjectpath: this.$store.state.subject,
      studentpath: this.student,
      subjecttitle: this.subject
    };
  },

  created() {
    this.participation = [];
    this.grade = 0;

    //! Get Student Attentance
    firebase
      .firestore()
      .collection(this.studentpath + "/examgrades/"+this.subjecttitle+"/breakdown")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if(doc.data().breakdown == this.$vnode.key ){
            this.grade = doc.data().grade;
          } 
        })
        // if (querySnapshot.data() != null){
        //   if(querySnapshot.data().breakdown == this.$store.state.breakdown ){
        //     this.grade = querySnapshot.data().grade;
        //   }
        // }
          

        //! Flag As completed
        this.$store.commit("weightcount", this.$store.state.weightcount + 1);

        this.$store.commit("gradebook", {
          title: "Exam",
          grade: (this.grade / 100) * 100,
          weight: this.weight
        });
      });

    // this.$store.commit("gradebook", {
    //   title: "Exam",
    //   grade: 0,
    //   weight: this.weight
    // });
  }
};
</script>
