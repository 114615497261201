<template>
  <v-card flat>
    <v-card-title>
      <v-icon style="margin-right:10px;" size="50"
        >mdi-clipboard-check-outline</v-icon
      >

      {{ this.$route.params.sname }} Gradebook</v-card-title
    >

    <v-card-text>
      <!--Filter by Month-->
      <v-card dark style="background-color:#1b2e47">
        <v-card-text>
          <v-row
            ><v-col cols="4">
              <label for="filter">Time Filter</label>
              <b-form-datepicker
                id="filter"
                v-model="date"
                class="mb-2"
              ></b-form-datepicker>
            </v-col>

            <v-col cols="8">
              <v-autocomplete
                :items="lessons"
                label="Select Lesson"
                v-model="selected"
                @change="search"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-btn @click="clearsearch" color="green" block depressed small
                >Clear Search</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br />

      <!-- Attendance-->
      <v-card>
        <v-card-title>Student Attendance</v-card-title>
        <v-card-text>
          <ul>
            <li>
              <v-row
                ><v-col cols="2"> Total Present:</v-col>
                <v-col cols="2"> {{ this.present }}</v-col>
              </v-row>
            </li>
            <li>
              <v-row
                ><v-col cols="2"> Total Absence:</v-col>
                <v-col cols="2"> {{ this.absence }}</v-col>
              </v-row>
            </li>
            <li>
              <v-row
                ><v-col cols="2"> Total Excuse:</v-col>
                <v-col cols="2"> {{ this.excuse }}</v-col>
              </v-row>
            </li>
            <li>
              <v-row
                ><v-col cols="2"> Total Late:</v-col>
                <v-col cols="2"> {{ this.late }}</v-col>
              </v-row>
            </li>
            <li>
              <v-row
                ><v-col cols="2"> Total Leave:</v-col>
                <v-col cols="2"> {{ this.leave }}</v-col>
              </v-row>
            </li>
          </ul>
        </v-card-text>
      </v-card>

      <v-row style="margin-left:10px; margin-top:50px">
        <p>
          <v-icon color="#033b59">mdi-alpha-u-box</v-icon>
          Upcoming Assignments
        </p>
        <p>
          <v-icon color="green">mdi-alpha-d-box</v-icon>
          Delivered Assignments
        </p>
        <p>
          <v-icon color="red">mdi-alpha-m-box</v-icon>
          Overdue Assignments
        </p>
      </v-row>
      <v-col cols="12">
        <div v-for="lesson in lessons" :key="lesson.key">
          <GradeLesson
            :path="lesson.value"
            :noblended="lesson.noblended"
            :title="lesson.text"
            :date="lesson.date"
            :student="$route.params.student"
            :studentpath="$route.params.studentpath"
            :weekid="lesson.key"
          />
        </div>
      </v-col>
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
import GradeLesson from "./GradeLesson";
export default {
  name: "GradeBook",
  components: { GradeLesson },

  data() {
    return {
      assignments: [],
      lessons: [],
      weights: [],
      week: "",
      date: new Date().toISOString().substr(0, 7),
      subject: this.$store.state.subject,
      subjecttitle: this.$store.state.subjectTitle,
      menu: false,
      modal: false,
      selected: "",
      mirror: this.$store.state.mirror,

      //Attendance
      present: 0,
      absence: 0,
      excuse: 0,
      late: 0,
      leave: 0
    };
  },
  created() {
    if (
      this.mirror == null ||
      this.mirror == "undefined" ||
      this.mirror == ""
    ) {
      this.subject = this.$store.state.subject;
    } else {
      this.subject = this.$store.state.mirror;
    }

    firebase
      .firestore()
      .collection(this.$route.params.studentpath + "/attendance")
      .where("subject", "==", this.subjecttitle)
      .onSnapshot(querySnapshot => {
        this.present = 0;
        this.absence = 0;
        this.excuse = 0;
        this.late = 0;
        this.leave = 0;
        querySnapshot.forEach(doc => {
          if (doc.data().type == "present") this.present++;
          else if (doc.data().type == "absence") this.absence++;
          else if (doc.data().type == "excuse") this.excuse++;
          else if (doc.data().type == "late") this.late++;
          else if (doc.data().type == "leave") this.leave++;
        });
      });

    firebase
      .firestore()
      .collection(this.subject + "/weeks/")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        this.lessons = [];
        querySnapshot.forEach(doc => {
          this.lessons.push({
            key: doc.id,
            text: doc.data().title,
            date: doc.data().date,
            value: doc.ref.path,
            noblended: doc.data().noblended
          });
        });
      });
  },
  methods: {
    //Search By Title
    search() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.lessons = [];
          querySnapshot.forEach(doc => {
            if (doc.ref.path == this.selected)
              this.lessons.push({
                key: doc.id,
                text: doc.data().title,
                date: doc.data().date,
                value: doc.ref.path
              });
          });
        });
    },
    //Search By Date
    changeFilter() {
      var mon;
      mon = new Date(this.date).getMonth() + 1;
      this.$refs.menu.save(this.date);
      this.menu = false;

      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.lessons = [];
          querySnapshot.forEach(doc => {
            if (new Date(doc.data().date).getMonth() + 1 == mon)
              this.lessons.push({
                key: doc.id,
                text: doc.data().title,
                date: doc.data().date,
                value: doc.ref.path
              });
          });
        });
    },
    clearsearch() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks/")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.lessons = [];
          querySnapshot.forEach(doc => {
            this.lessons.push({
              key: doc.id,
              text: doc.data().title,
              date: doc.data().date,
              value: doc.ref.path
            });
          });
        });
    }
  }
};
</script>
