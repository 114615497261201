<template>
  <div
    class="login-access"
    style="flex-direction: column; box-sizing: border-box; display: flex;  align-items: center;"
  >
    <v-container>
      <v-card flat outlined>
        <v-card-title class="justify-center">
          <img :src="this.logo" width="100px" />
        </v-card-title>

        <v-form
          @submit.stop.prevent="onSubmit"
          ref="form"
          data-vv-scope="admission"
          v-model="valid"
        >
          <v-card-text>
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-app-bar-nav-icon></v-app-bar-nav-icon>

                <v-toolbar-title>Admission Application</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>

                <template v-slot:extension>
                  <v-tabs v-model="tab" align-with-title>
                    <v-tabs-slider color="orange"></v-tabs-slider>
                    <v-tab>
                      Student Information
                    </v-tab>
                    <v-tab>
                      Father's Information
                    </v-tab>
                    <v-tab>
                      Mother's Information
                    </v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row center-block>
                        <v-col cols="12" md="4">
                          <v-text-field
                            label="First name"
                            v-model.trim="student.firstname"
                            messages="الاسم الاول"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            label="Middle name"
                            v-model.trim="student.middlename"
                            messages="الاسم الاوسط"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            label="Last name"
                            v-model.trim="student.lastname"
                            messages="الاسم الاخير"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-text-field
                            label="Student National Id (14 Numbers)"
                            messages="الرقم القومي للطالب من واقع شهادة الميلاد"
                            v-model.trim="student.nationalID"
                            required
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Schools Level -->
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-select
                            :items="schools"
                            v-model="student.branch"
                            label="School applying to"
                            messages="المدرسة المتقدم لها الطالب"
                            @change="onSchoolChange()"
                            outlined
                            clearable
                            required
                          ></v-select>
                        </v-col>
                        <!-- Grade Level -->
                        <v-col class="d-flex" cols="12" sm="4">
                          <v-select
                            :items="grades"
                            v-model="student.grade"
                            label="Grade Level applying to"
                            messages="الصف الدراسي المتقدم له الطالب"
                            @change="onGradeChange()"
                            outlined
                            clearable
                            required
                          ></v-select>
                        </v-col>
                      </v-row>

                      <!-- Gender -->
                      <v-row>
                        <v-col class="d-flex" cols="6" sm="6">
                          <v-select
                            :items="['Male', 'Female']"
                            v-model.trim="student.gender"
                            label="Gender"
                            messages="النوع"
                            outlined
                            clearable
                            required
                          ></v-select>
                        </v-col>

                        <v-col class="d-flex" cols="6" sm="6">
                          <v-select
                            :items="['English', 'French', 'German']"
                            v-model.trim="student.secondlanguage"
                            label="Second Language"
                            messages="اللغه الثانية"
                            outlined
                            clearable
                            required
                          ></v-select>
                        </v-col>
                        <v-col class="d-flex" cols="6" sm="6">
                          <v-select
                            :items="['English', 'French', 'German', 'Arabic']"
                            v-model.trim="student.mothertongue"
                            label="Mother Tongue"
                            messages="اللغة الام"
                            outlined
                            clearable
                            required
                          ></v-select>
                        </v-col>

                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Citizenship"
                            messages="الجنسية"
                            v-model.trim="student.citizenship"
                            required
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Previous School / Nursery:"
                            messages="المدرسة / الحضانة السابقة"
                            v-model.trim="student.preschool"
                            required
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="d-flex" cols="6" sm="6">
                          <label for="example-datepicker">Date Of Birth</label>
                          <label for="example-datepicker">تاريخ الميلاد</label>
                          <b-form-datepicker
                            id="example-datepicker"
                            v-model="student.date_of_birth"
                            class="mb-2"
                            required
                            outlined
                            clearable
                          ></b-form-datepicker>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Address"
                            messages="العنوان"
                            v-model.trim="student.address"
                            required
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Home Phone"
                            messages="تليفون المنزل"
                            v-model.trim="student.phone"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Mobile"
                            messages="رقم التليفون المحمول"
                            v-model.trim="student.mobile"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row>
                        <v-col cols="6" md="6">
                          <v-switch
                            v-model="havesibling"
                            :label="'Have sibling in ' + this.SchoolName"
                            outlined
                            clearable
                          ></v-switch>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-switch
                            v-model="parentsEmployee"
                            :label="this.SchoolName + ' Employee'"
                            outlined
                            clearable
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Father's Academic Degree"
                            v-model.trim="student.father_degree"
                            messages="المؤهل الدراسي للاب"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Father's Occupation"
                            v-model.trim="student.father_occupation"
                            messages="وظيفة الاب"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Father's Company Business"
                            v-model.trim="student.father_company"
                            messages="مجال العمل"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Father's Email"
                            v-model.trim="student.parent"
                            messages="البريد الالكتروني - الاب"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Father's Mobile"
                            v-model.trim="student.father_mobile"
                            messages="رقم المحمول - الاب"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-text-field
                            label="Father's National Id (14 Numbers)"
                            messages="الرقم القومي للاب"
                            v-model.trim="student.father_nationalID"
                            required
                            outlined
                            clearable
                          ></v-text-field> </v-col
                      ></v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Mother's Name"
                            v-model.trim="student.mother_name"
                            messages="اسم الام"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Mother's Academic Degree"
                            v-model.trim="student.mother_degree"
                            messages="المؤهل الدراسي للام"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Mother's Occupation"
                            v-model.trim="student.mother_occupation"
                            messages="وظيفة الام"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Mother's Company Business"
                            v-model.trim="student.mother_company"
                            messages="مجال عمل الام"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Mother's Email"
                            v-model.trim="student.mother_email"
                            messages="البريد الالكتروني - الام"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            label="Mother's Mobile"
                            v-model.trim="student.mother_mobile"
                            messages="رقم المحمول - الام"
                            outlined
                            clearable
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-text-field
                            label="Mother's National Id (14 Numbers)"
                            messages="الرقم القومي للام"
                            v-model.trim="student.mother_nationalID"
                            outlined
                            clearable
                            required
                          ></v-text-field> </v-col
                      ></v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>

            <!-- <v-alert border="bottom" color="blue darken-1" dark>
              <h6>Appointment</h6>
            </v-alert>
            <v-row center-block class="justify-center" v-if="student.branch">
              <v-col cols="12" sm="4">
                <b-form-datepicker
                  v-model="date"
                  :min="this.minDate"
                  :max="this.maxDate"
                  
                  :date-disabled-fn="dateDisabled"
                  locale="en"
                  required
                ></b-form-datepicker>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4">
                <v-time-picker
                  v-model="time"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedMinutes"
                  color="green lighten-1"
                  scrollable
                  :min="this.minTime"
                  :max="this.maxTime"
                  
                  required
                ></v-time-picker>
              </v-col>
            </v-row> -->
            <br />
            <!-- <div style="color:red">
              * Please make sure to enter the correct ID numbers as this may
              affect the fees plan for your children
            </div>
            <div style="color:red">
              * برجاء التاكد من ادخال الرقم القومي بشكل صحيح اذ قد يؤثر ذلك علي
              خطة المصروفات الخاصة بآبنائكم
            </div> -->
            <br />
            <v-spacer />
            <v-btn type="validate" color="primary" depressed block
              ><v-icon>mdi-send</v-icon> Register Now</v-btn
            >
          </v-card-text>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/Firebase";
import router from "@/router";

export default {
  name: "OnlineAdmission",

  data() {
    return {
      havesibling: false,
      parentsEmployee: false,
      valid: true,
      nameRules: [v => !!v || "Field is required"],
      e1: 1,
      date: "",
      tab: null,
      time: "",
      logo: "",
      SchoolName: "",
      minDate: new Date().toISOString().substr(0, 10),
      maxDate: "",
      minTime: "",
      maxTime: "",
      disabledDates: { weekdays: [1, 7] },
      student: {},
      grades: {},
      schools: {},
      gclasses: {},
      gradeSetting: {},
      timeTo: "",
      gradesRef: firebase
        .firestore()
        .collection("schools/" + this.$route.query.school + "/grades/")
        .orderBy("order"),

      schoolsRef: firebase
        .firestore()
        .collection("schools/" + this.$route.query.school + "/branches/")
        .where("openadmission", "==", true),

      schoolRef: firebase.firestore().doc("schools/" + this.$route.query.school)
    };
  },
  methods: {
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();

      //Return `true` if the date should be disabled

      // return weekday === 4 || weekday === 5 || weekday === 6;

      if (
        weekday === 5 ||
        weekday === 6 ||
        day === 29 ||
        day === 2 ||
        day === 3 ||
        day === 12 ||
        day === 13
      )
        return true;
      else return false;
    },
    onSubmit(evt) {
      if (this.$refs.form.validate() == true) {
        evt.preventDefault();

        this.student.type = "student";
        this.student.school = this.$route.query.school;
        this.student.admission_date = this.date;
        this.student.admission_time = this.time;
        this.student.application_date = new Date().toLocaleString();
        this.student.havesibling = this.havesibling;
        this.student.parentsEmployee = this.parentsEmployee;

        firebase
          .firestore()
          .collection("schools/" + this.$route.query.school + "/admission/")
          .add(this.student)
          .then(() => {
            router.push({
              name: "Confirmation",
              params: {
                logo: this.logo,
                school: this.SchoolName,
                schoolname: this.$route.query.school,
                branch: this.student.branch,
                date: this.student.admission_date,
                time: this.student.admission_time,
                emails: this.student.parent + "," + this.student.mother_email
              }
            });

            //   router.back();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSchoolChange() {
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch
        )
        .get()
        .then(snapshot => {
          if (!snapshot.exists) return;
          let school = snapshot.data();

          //this.minDate = school.startadmission;
          this.maxDate = school.endadmission;
          this.minTime = school.starttime;
          this.maxTime = school.endtime;
        });

      this.gclasses = [];
      firebase
        .firestore()
        .collection(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch +
            "/grades"
        )
        .where("openadmission", "==", true)
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.grades = [];
          querySnapshot.forEach(doc => {
            this.grades.push({
              text: doc.data().title,
              value: doc.id
            });
          });
        });
    },
    onGradeChange() {
      this.gradeSetting = {};
      firebase
        .firestore()
        .doc(
          "schools/" +
            this.$route.query.school +
            "/branches/" +
            this.student.branch +
            "/grades/" +
            this.student.grade
        )
        .get()
        .then(doc => {
          if (doc.exists) {
            this.gradeSetting = doc.data();
          } else {
            alert("No such document!");
          }
        });
    },

    validate() {
      if (this.$refs.form.validate() == true) {
        this.$refs.form.submi;
      }
    }
  },
  created() {
    this.schoolRef.get().then(snapshot => {
      if (!snapshot.exists) return;
      let school = snapshot.data();

      this.logo = school.logo;
      this.SchoolName = school.school_name;
    });

    this.schoolsRef.onSnapshot(querySnapshot => {
      this.schools = [];
      querySnapshot.forEach(doc => {
        this.schools.push({
          text: doc.data().title,
          value: doc.id
        });
      });
    });
  }
};
</script>
