<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-timeline-help-outline</v-icon>

          <span
            class="title font-weight-light"
            style="margin-right:10px"
            v-if="source == 'edit'"
            >Edit Flipped Classroom Questions</span
          >
          <span class="title font-weight-light" style="margin-right:10px" v-else
            >Create Random Questions</span
          >

          <Tip
            style="margin-left:10px"
            text="A flipped classroom is a type of blended learning where students are introduced to content at home and practice working through it at school. This is the reverse of the more common practice of introducing new content at school, then assigning homework and projects to completed by the students independently at home."
          />
          <v-spacer /><v-icon @click="this.$modal.hideAll">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle
          >This Activity will Bring a random questions from the lesson Question
          Bank for every student</v-card-subtitle
        >
        <v-card-text style="padding-right:50px; padding-left:50px;">
          <div>
            <b-form-group
              id="fieldTtile"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Questions Count"
            >
              <v-text-field type="number" id="ftitle" v-model="lesson.qcount" />
            </b-form-group>

            <b-form-group
              id="fieldmin"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Minimum Level Of Difficulty"
            >
              <v-text-field type="number" id="fmin" v-model="lesson.minlevel" />
            </b-form-group>
            <b-form-group
              id="fieldmax"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Maximum Level Of Difficulty"
            >
              <v-text-field type="number" id="fmax" v-model="lesson.maxlevel" />
            </b-form-group>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-divider></v-divider>

          <b-button
            variant="danger"
            class="float-right"
            @click="this.$modal.hideAll"
            >Close</b-button
          >

          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="edit"
            v-if="source == 'edit'"
            >Edit Flipped Activity</b-button
          >
          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="onSubmit"
            v-else
            >Create Random Activity</b-button
          >
        </v-card-actions>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import Tip from "@/components/dna/Tip";

export default {
  name: "ViewRecord",
  props: ["path", "order", "source"],

  components: { Tip },
  data() {
    return {
      lesson: {},
      standards: [],
      ref: firebase.firestore().collection(this.path + "/lesson/"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      newfield: {}
    };
  },
  methods: {
    edit(evt) {
      evt.preventDefault();
      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        firebase
          .firestore()
          .doc(this.path)
          .set(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Update",
              this.lesson,
              this.$store.state.subject,
              "Flipped"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Flipped Created", {
              title: "Flipped Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.lesson.qcount == null) {
        alert("Please Enter the Questions Count");
      } else {
        this.lesson.type = "flipped";
        this.lesson.order = Number(this.order) + 1;
        this.lesson.title = "Flipped Classroom Questions";

        this.ref
          .add(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.lesson,
              this.$store.state.subject,
              "Flipped"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Flipped Created", {
              title: "Flipped Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  },
  mount() {
    this.show();
  },

  created() {
    if (this.source == "edit") {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.lesson = doc.data();
          } else {
            alert("No such document!");
          }
        });
    }
    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.data().code
        });
      });
    });
  }
};
</script>
