<template>
  <div class="app-container" style="padding-top:100px">
    <CountIconCard
      title="Videos"
      color="blue lighten-2"
      icon="mdi-badge-account-horizontal-outline"
      :path="'activities'"
    ></CountIconCard>

    <v-card
      ><v-card-text>
        <upload-excel-component
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        />
        <br />
        <v-progress-linear
          v-if="progress"
          color="light-blue"
          height="20"
          :value="progress"
          striped
        ></v-progress-linear>
        <br />
        Rows Count: {{ tableData.length }}
        <br />
        <v-text-field label="Grade" v-model="grade" />
        <v-text-field label="Subject" v-model="subject" />
        <!--<b-button variant="danger" @click="deleteOld">Delete Old Data</b-button> -->
        <b-button
          variant="success"
          style="color:#ffffff"
          href="https://firebasestorage.googleapis.com/v0/b/madrasa-tech.appspot.com/o/upload_student.xlsx?alt=media&token=6802026e-bfea-4228-8951-535a6db50b30"
          >Download Excel Template</b-button
        >

        <v-btn @click="createWeeks" style="margin-left:20px;" color="info"
          >Create Weeks</v-btn
        >
        <b-button @click="importData" style="margin-left:20px;"
          >Start Importing</b-button
        >

        <!--  <b-button variant="info" @click="transfer" style="margin-left:20px;"
          >Start Transfer</b-button
        >-->

        <el-table
          :data="tableData"
          border
          highlight-current-row
          style="width: 100%;margin-top:20px;"
          empty-text="There Is No Data Yet"
        >
          <el-table-column
            v-for="item of tableHeader"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/dna/UploadExcel/index.vue";
import firebase from "@/Firebase";
import CountIconCard from "@/components/dna/widgets/CountIconCard";

export default {
  name: "UploadExcel",
  components: { UploadExcelComponent, CountIconCard },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      video: {},
      week: {},
      current: 0,
      progress: 0,
      grade: "",
      subject: ""
    };
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      //Start Add to Collection & Create Accounts

      //End

      this.tableData = results;
      this.tableHeader = header;
    },

    deleteOld() {
      console.log("Start Deleting");
      firebase
        .firestore()
        .collection("activities")
        .where("grade", "==", "Grade 1")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            doc.ref.delete().then(() => {
              console.log("Ok");
            });
          });
        });
    },

    importData: function() {
      for (var i = 0; i < this.tableData.length; i++) {
        this.video = {};
        this.video.week =
          this.tableData[i]["week"] == null
            ? 1
            : Number(this.tableData[i]["week"]);

        this.week.order =
          this.tableData[i]["week"] == null
            ? 1
            : Number(this.tableData[i]["week"]);

        this.week.week_title =
          this.tableData[i]["week_title"] == null
            ? " "
            : this.tableData[i]["week_title"].trim();

        this.video.week_title =
          this.tableData[i]["week_title"] == null
            ? " "
            : this.tableData[i]["week_title"].trim();

        this.video.section =
          this.tableData[i]["section"] == null
            ? " "
            : this.tableData[i]["section"].trim();
        this.video.title =
          this.tableData[i]["title"] == null
            ? " "
            : this.tableData[i]["title"].trim();
        this.video.link =
          this.tableData[i]["link"] == null
            ? " "
            : this.tableData[i]["link"].trim();

        console.log(this.video);

        firebase
          .firestore()
          .collection(
            "/schools/" +
              this.$store.state.school +
              "/branches/American School/grades/" +
              this.grade +
              "/subjects/" +
              this.subject +
              "/weeks/" +
              this.week.order +
              "/videos"
          )
          .add(this.video)
          .then(() => {
            //Create User
            console.log("Success Added");
            this.current++;
            this.progress = (this.tableData.length / this.current) * 100;
          })
          .catch(error => {
            console.log("Error adding document: ", error);
          });
      }
    },

    createWeeks() {
      for (var i = 0; i < this.tableData.length; i++) {
        this.video = {};
        this.video.week =
          this.tableData[i]["week"] == null
            ? 1
            : Number(this.tableData[i]["week"]);

        this.week.order =
          this.tableData[i]["week"] == null
            ? 1
            : Number(this.tableData[i]["week"]);

        this.week.title =
          this.tableData[i]["week_title"] == null
            ? " "
            : this.tableData[i]["week_title"].trim();

        this.video.week_title =
          this.tableData[i]["week_title"] == null
            ? " "
            : this.tableData[i]["week_title"].trim();

        this.video.section =
          this.tableData[i]["section"] == null
            ? " "
            : this.tableData[i]["section"].trim();
        this.video.title =
          this.tableData[i]["title"] == null
            ? " "
            : this.tableData[i]["title"].trim();
        this.video.link =
          this.tableData[i]["link"] == null
            ? " "
            : this.tableData[i]["link"].trim();

        console.log(this.video);

        firebase
          .firestore()
          .doc(
            "/schools/" +
              this.$store.state.school +
              "/branches/American School/grades/" +
              this.grade +
              "/subjects/" +
              this.subject +
              "/weeks/" +
              this.week.order
          )
          .set(this.week)
          .then(() => {
            console.log("Success Added");
          })
          .catch(error => {
            console.log("Error adding document: ", error);
          });
      }
    },

    transfer() {
      //   firebase
      //     .firestore()
      //     .collection("activities")
      //     .onSnapshot(querySnapshot => {
      //       querySnapshot.forEach(doc => {
      //         firebase
      //           .firestore()
      //           .collection(
      //             "activities_hub/" +
      //               doc.data().system +
      //               "/grades/" +
      //               doc.data().grade +
      //               "/subjects/" +
      //               doc.data().subject +
      //               "/videos"
      //           )
      //           .add(doc.data())
      //           .then(() => {
      //             console.log("Done");
      //           })
      //           .catch(() => {});
      //       });
      //     });
    }
  },
  mounted() {
    // this.deleteOld();
    this.importData();
  }
};
</script>
