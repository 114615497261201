<template>
  <div>{{ this.week.title }}</div>
</template>

<script>
import firebase from "@/Firebase";
export default {
  name: "RevisionWeek",
  props: ["path"],

  data() {
    return {
      week: {}
    };
  },
  created() {
    firebase
      .firestore()
      .doc(this.path)
      .get()
      .then(doc => {
        this.weeks = {};
        this.week = doc.data();
      });
  },

  methods: {}
};
</script>
