<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-timeline-help-outline</v-icon>

          <span
            class="title font-weight-light"
            style="margin-right:10px"
            v-if="source == 'edit'"
            >Edit Exit Ticket Questions</span
          >
          <span class="title font-weight-light" style="margin-right:10px" v-else
            >Create Exit Ticket Questions</span
          >

          <Tip
            text="An exit ticket is essentially a student's ticket out the door. Assigned at the end of the day, or end of a class period, exit tickets require students to demonstrate something they have learned, or to process some part of the day's lesson."
          />
          <v-spacer /><v-icon @click="this.$modal.hideAll">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle
          >This Activity will Bring a random questions from the lesson Question
          Bank for every student</v-card-subtitle
        >
        <v-card-text style="padding-right:50px; padding-left:50px;">
          <div>
            <b-form-group
              id="fieldTtile"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Questions Count"
            >
              <v-text-field type="number" id="ftitle" v-model="lesson.qcount" />
            </b-form-group>

            <b-form-group
              id="fieldmin"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Minimum Level Of Difficulty"
            >
              <v-text-field type="number" id="fmin" v-model="lesson.minlevel" />
            </b-form-group>
            <b-form-group
              id="fieldmax"
              horizontal
              :label-cols="4"
              breakpoint="md"
              label="Maximum Level Of Difficulty"
            >
              <v-text-field type="number" id="fmax" v-model="lesson.maxlevel" />
            </b-form-group>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-divider></v-divider>

          <b-button
            variant="danger"
            class="float-right"
            @click="this.$modal.hideAll"
            >Close</b-button
          >

          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="edit"
            v-if="source == 'edit'"
            >Edit Exit Ticket Activity</b-button
          >
          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="onSubmit"
            v-else
            >Create Exit Ticket Activity</b-button
          >
        </v-card-actions>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import Tip from "@/components/dna/Tip";

export default {
  name: "ViewRecord",
  props: ["path", "order", "source"],

  components: { Tip },
  data() {
    return {
      lesson: {},
      standards: [],
      ref: firebase.firestore().collection(this.path + "/lesson/"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      newfield: {}
    };
  },
  methods: {
    edit(evt) {
      evt.preventDefault();
      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        firebase
          .firestore()
          .doc(this.path)
          .set(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Update",
              this.lesson,
              this.$store.state.subject,
              "Flipped"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Flipped Created", {
              title: "Flipped Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (this.lesson.qcount == null) {
        alert("Please Enter the Questions Count");
      } else {
        this.lesson.type = "exit";
        this.lesson.order = Number(this.order) + 1;
        this.lesson.title = "Exit Ticket Questions";

        this.ref
          .add(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.lesson,
              this.$store.state.subject,
              "Activity"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Activity Created", {
              title: "Activity Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    }
  },
  mount() {
    this.show();
  },

  created() {
    if (this.source == "edit") {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.lesson = doc.data();
          } else {
            alert("No such document!");
          }
        });
    }
    this.StandardsRef.onSnapshot(querySnapshot => {
      this.standards = [];
      querySnapshot.forEach(doc => {
        this.standards.push({
          text: doc.data().code + "  " + doc.data().title,
          value: doc.data().code
        });
      });
    });
  }
};
</script>
