<template>
  <b-row>
    <b-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <AddAssignment
            :title="this.$route.params.title"
            :week="this.$route.params.path"
            v-if="master == true"
          ></AddAssignment>

          <br />
          <b-card-group deck>
            <b-card
              bg-variant="success"
              text-variant="white"
              header="Assignments"
              class="text-center"
            >
              <b-card-text>
                <h1>{{ this.assignments.length }}</h1>
              </b-card-text>
            </b-card>

            <CountCard title="InActive" color="danger"></CountCard>
          </b-card-group>
          <br />
          <vue-good-table
            :columns="fields"
            :rows="assignments"
            :search-options="{ enabled: true, skipDiacritics: true }"
            :pagination-options="{ enabled: true, mode: 'records' }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <template v-slot:cell(actions)="row"> -->
              <span v-if="props.column.field == 'actions'">
                <v-btn
                  color="error"
                  depressed
                  x-small
                  @click="deleteAssignment(props.row.key, props.row.title)"
                  v-if="master == true"
                >
                  <v-icon size="14">mdi-delete-outline</v-icon>
                </v-btn>
                &nbsp;
                <v-btn
                  color="info"
                  depressed
                  x-small
                  @click="goToEdit(props.row.title, props.row.path)"
                  v-if="master == true"
                >
                  <v-icon size="14">mdi-pencil-outline</v-icon>
                </v-btn>
                &nbsp;
                <v-btn
                  color="cyan"
                  depressed
                  x-small
                  @click="goToView(props.row.title, props.row.path)"
                >
                  <v-icon color="white" size="14">mdi-eye</v-icon>
                </v-btn>
                &nbsp;

                <v-btn
                  color="success"
                  x-small
                  depressed
                  @click="
                    goToSubmissions(
                      props.row.key,
                      props.row.title,
                      props.row.path,
                      props.row.grade,
                      props.row.key,
                      props.row.type
                    )
                  "
                >
                  Submissions
                  <v-icon size="14">mdi-format-list-checks</v-icon>
                </v-btn>
                &nbsp;
                <v-btn
                  color="info"
                  x-small
                  depressed
                  @click="
                    goToExtend(
                      props.row.key,
                      props.row.title,
                      props.row.path,
                      props.row.grade,
                      props.row.key,
                      props.row.type
                    )
                  "
                >
                  Extend
                  <v-icon size="14">mdi-format-list-checks</v-icon>
                </v-btn>
              </span>

              <span v-else-if="props.column.field == 'note'">
                <div v-if="master == true">
                  <v-badge
                    bordered
                    color="success"
                    icon="mdi-check"
                    overlap
                    v-if="props.row.note"
                  >
                    <Note
                      :path="props.row.path"
                      :key="props.row.key"
                      :link="props.row.note"
                    />
                  </v-badge>
                  <Note v-else :path="props.row.path" :key="props.row.key" />
                </div>
              </span>
              <span v-else-if="props.column.field == 'weight'">
                {{ props.row.weight }}
                <div v-if="props.row.graded == true">
                  <v-chip small color="error">Not Gradable</v-chip>
                </div>
              </span>

              <span v-else-if="props.column.field == 'title'">
                {{ props.row.title }}
                <p v-if="props.row.file">
                  <a :href="props.row.file" target="_blank"
                    ><v-icon color="red">mdi-paperclip</v-icon></a
                  >
                </p>
              </span>
              <span v-else-if="props.column.field == 'questions'">
                <!-- <span v-if="props.row.quiztype == 'Fixed Questions'">
                  {{ this.fixedquestions.length }}</span
                >
                <span
                  v-else-if="props.row.quiztype == 'Random From Question Bank'"
                >
                  {{ props.row.count }}</span
                >-->
              </span>
              <span v-else-if="props.column.field == 'type'">
                <div v-if="props.row.type == 'Textbook Assignment'">
                  Assignment
                </div>
                <div v-else>Digitized Assignment</div>
              </span>
            </template>
          </vue-good-table>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import Note from "@/components/dna/notes/Note.vue";
import CountCard from "@/components/dna/widgets/CountCard.vue";
import AddAssignment from "./AddAssignment.vue";
import router from "@/router";

export default {
  name: "Dimensions-page",
  components: {
    CountCard,
    AddAssignment,
    Note
  },

  data() {
    return {
      assignments: {},
      fixedquestions: [],
      master: this.$store.state.master,
      fields: [
        {
          field: "note",
          label: "Note",
          sortable: true,
          class: "text-left"
        },
        {
          field: "type",
          label: "Type",
          sortable: true,
          class: "text-left"
        },
        {
          field: "weight",
          label: "Weight",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },

        {
          field: "questions",
          label: "Questions",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "400px"
        }
      ]
    };
  },
  created() {
    firebase
      .firestore()
      // .collection(this.$route.params.path + "/tasks")
      .collection(this.$store.state.subject + "/tasks")
      .where("week", "==", this.$route.params.path)
      .onSnapshot(querySnapshot => {
        this.assignments = [];
        querySnapshot.forEach(doc => {
          const assignment = doc.data();
          this.assignments.push({
            key: doc.id,
            path: doc.ref.path,
            title: assignment.title,
            type: assignment.type,
            grade: assignment.grade,
            note: assignment.note,
            file: assignment.file,
            weight: assignment.weight,
            quiztype: assignment.quiztype,
            count: assignment.count,
            graded: assignment.graded
            // fixedquestions: assignment.fixedquestions
          });
        });
      });
  },
  methods: {
    deleteAssignment(id, title) {
      this.$confirm({
        message: "Are you sure You want to Delete " + title + " ?",
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            firebase
              .firestore()
              .collection(this.$store.state.subject + "/tasks")
              .doc(id)
              .delete()
              .then(() => {
                this.AddLog(
                  this.$store.state.username,
                  this.$store.state.firstname,
                  this.$store.state.lastname,
                  "Delete",
                  title,
                  this.$store.state.subject + "/tasks/" + id,
                  "Assignment"
                );
                this.$bvToast.toast("Assignment Deleted", {
                  title: "Assignment Deleted Successfully",
                  variant: "danger",
                  solid: true
                });
              })
              .catch(error => {
                alert("Error removing document: ", error);
              });
          }
        }
      });
    },
    goToSubmissions(id, title, path, grade, assid, type) {
      localStorage.setItem("assid", assid);
      localStorage.setItem("asspath", path);
      localStorage.setItem("assgrade", grade);
      localStorage.setItem("asstype", type);

      router.push({
        name: "Submissions",
        params: {
          id: id,
          title: title,
          path: path,
          grade: grade,
          assid: assid,
          type: type
        }
      });
    },
    goToExtend(id, title, path, grade, assid, type) {
      localStorage.setItem("assid", assid);
      localStorage.setItem("asspath", path);
      localStorage.setItem("assgrade", grade);
      localStorage.setItem("asstype", type);

      router.push({
        name: "Extend",
        params: {
          id: id,
          title: title,
          path: path,
          grade: grade,
          assid: assid,
          type: type
        }
      });
    },
    goToEdit(title, path) {
      router.push({
        name: "EditAssignment",
        params: { title: title, path: path }
      });
    },
    goToView(title, path) {
      router.push({
        name: "ViewAssignment",
        params: { title: title, path: path }
      });
    }
  }
};
</script>

<style></style>
