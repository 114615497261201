<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-pencil-outline</v-icon>
          <span class="title font-weight-light">Edit {{ this.title }}</span>
        </v-card-title>

        <v-card-text>
          <div style="overflow: auto; height: 400px">
            <vuescroll>
              <v-container>
                <b-form @submit.stop.prevent="onSubmit">
                  <!-- Job Title -->

                  <v-row center-block>
                    <v-col
                      cols="12"
                      md="12"
                      v-for="field in this.fields"
                      :key="field.id"
                    >
                      <div v-if="field.ftype == 'number'">
                        <v-text-field
                          :label="field.label"
                          v-model.trim="newfield[field.field]"
                        ></v-text-field>
                      </div>
                      <div v-else-if="field.ftype == 'ref'">
                        <DropDown
                          :path="field.path"
                          v-model.trim="newfield[field.field]"
                          :fvalue="newfield[field.field]"
                          :title="field.label"
                          :lookup="field.lookup"
                        ></DropDown>
                      </div>
                      <div v-else-if="field.ftype == 'area'">
                        <v-textarea
                          :label="field.label"
                          v-model.trim="newfield[field.field]"
                        ></v-textarea>
                      </div>

                      <div v-else-if="field.ftype == 'const'">
                        <v-text-field
                          :label="field.label"
                          v-show="false"
                          :value="field.value"
                          v-model.trim="newfield[field.field]"
                        ></v-text-field>
                      </div>

                      <div v-else-if="field.ftype == 'editor'">
                        <p>{{ field.label }}</p>
                        <quill-editor
                          v-model="newfield[field.field]"
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                        />
                      </div>
                      <div v-else>
                        <v-text-field
                          :label="field.label"
                          v-model.trim="newfield[field.field]"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>

                  <!-- Submit Form -->
                  <b-button
                    type="submit"
                    variant="primary"
                    class="float-right m-1"
                    >Update {{ this.title }}</b-button
                  >
                  <b-button
                    variant="danger"
                    class="float-right m-1"
                    @click="this.$modal.hideAll"
                    >Close</b-button
                  >
                </b-form>
              </v-container>
            </vuescroll>
          </div>
        </v-card-text>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import DropDown from "@/components/dna/crud/DropDown";

export default {
  name: "EditRecord",
  props: ["icon", "title", "path", "fields", "editpath", "newpage"],

  components: { DropDown },
  data() {
    return {
      myfields: this.fields,
      ref: firebase.firestore().doc(this.editpath),
      newfield: {}
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.fields.forEach(field => {
        if (field.ftype == "const") this.newfield[field.field] = field.value;
      });

      this.ref
        .set(this.newfield)
        .then(() => {
          this.$modal.hideAll();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  },
  mount() {
    this.show();
  },

  created() {
    this.ref.get().then(doc => {
      if (doc.exists) {
        this.newfield = doc.data();
      } else {
        alert("No such document!");
      }
    });
  }
};
</script>

<style></style>
