<template>
  <b-row>
    <b-col cols="12">
     
      <CRUD
        title="Option"
        :path="this.$route.query.id + '/options/'"
        :fields="this.fields"
        width="500"
        height="600"
      ></CRUD>
    </b-col>
  </b-row>
</template>

<script>
import CRUD from "@/components/dna/crud/ListRecords.vue";

export default {
  name: "OptionsList",
  components: {
    CRUD
  },
  data() {
    return {
      fields: [
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left",
          ftype: "number"
        },
        {
          field: "sound",
          label: "Sound",
          sortable: true,
          class: "text-left",
          ftype: "sound"
        }
      ]
    };
  }
};
</script>
