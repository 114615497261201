<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="50%">
    <template v-slot:activator="{ on, attrs }">
      <b-button pill variant="danger" v-bind="attrs" v-on="on" block>
        <v-icon size="20">mdi-badge-account-outline</v-icon>
        <small style="margin-left: 10px;">Termination Request</small>
      </b-button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon>mdi-badge-account-outline</v-icon>Termination Request
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Termination Reason"
          v-model.trim="request.reason"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="dialog = false">Back</v-btn>
        <v-btn color="green darken-1" text @click="sendrequest">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";

export default {
  name:'termenation-page',
  props: ["path", "order"],

  data() {
    return {
      dialog: false,
      request: {}
    };
  },

  created() {},

  methods: {
    sendrequest(evt) {
      evt.preventDefault();
      this.request.owner = this.$store.state.userID;
      this.request.type = "Termination Request";
      this.request.date = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");

      firebase
        .firestore()
        .collection("schools/" + this.$store.state.school + "/requests")
        .add(this.request)
        .then(() => {
          this.dialog = false;
        });
    }
  }
};
</script>
