<template>
  <b-row>
    <b-col cols="12">
      <v-card flat>
        <v-card-title>
          <v-icon large left>mdi-video-outline</v-icon>

          <span class="title font-weight-light" v-if="source == 'edit'"
            >Edit Live Session</span
          >
          <span class="title font-weight-light" v-else
            >Create Live Session</span
          >

          <v-spacer /><v-icon @click="this.$modal.hideAll">mdi-close</v-icon>
        </v-card-title>
        <v-card-text
          style="padding-right:50px; padding-left:50px;overflow: auto; height:400px;"
        >
          <div>
            <vuescroll>
              <b-form-group
                id="fieldTtile"
                horizontal
                :label-cols="2"
                breakpoint="md"
                label="Live Title"
              >
                <v-text-field id="ftitle" v-model="lesson.title" />
              </b-form-group>

              <b-form-group
                id="fieldPreRecorder"
                horizontal
                :label-cols="2"
                breakpoint="md"
                label="PreRecorded Link"
              >
                <v-text-field id="fprerecorder" v-model="lesson.prerecorded" />
              </b-form-group>

              <StandardsPicker
                :path="this.path"
                type="Live"
                v-if="source == 'edit'"
                :lesson="this.week"
              />

              <v-row>
                <v-col cols="12">
                  <b-form-group
                    id="livelink"
                    horizontal
                    :label-cols="2"
                    breakpoint="md"
                    label="Live Link"
                  >
                    <b-form-input
                      id="llink"
                      placeholder="Live link"
                      v-model.trim="lesson.livelink"
                    ></b-form-input>
                    <v-btn
                      style="margin-top:5px; color:red"
                      color="red"
                      outlined
                      small
                      @click="goToLive"
                      ><v-icon>mdi-video-outline</v-icon> Create Live
                      Session</v-btn
                    >
                  </b-form-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <label for="livedate">Session Date</label>
                  <b-form-datepicker
                    id="livedate"
                    v-model="lesson.livedate"
                    class="mb-2"
                  ></b-form-datepicker>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="lesson.livetime"
                        label="Session Time"
                        persistent-hint
                        prepend-icon="mdi-clock"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="lesson.livetime"
                      no-title
                      @input="menu2 = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </vuescroll>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-divider></v-divider>

          <b-button
            variant="danger"
            class="float-right"
            @click="this.$modal.hideAll"
            >Close</b-button
          >

          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="edit"
            v-if="source == 'edit'"
            >Edit Live Session</b-button
          >
          <b-button
            style="margin-left:10px;"
            variant="success"
            class="float-right"
            @click="onSubmit"
            v-else
            >Create Live Session</b-button
          >
        </v-card-actions>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import firebase from "@/Firebase";
import StandardsPicker from "@/components/lms/standards/StandardsPicker";

export default {
  name: "ViewRecord",
  props: ["path", "order", "source", "week"],

  components: { StandardsPicker },
  data() {
    return {
      menu1: false,
      menu2: false,
      lesson: {},
      standards: [],
      ref: firebase.firestore().collection(this.path + "/lesson/"),
      StandardsRef: firebase
        .firestore()
        .collection(this.$store.state.subject + "/standards/"),
      newfield: {}
    };
  },
  methods: {
    edit(evt) {
      evt.preventDefault();
      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        firebase
          .firestore()
          .doc(this.path)
          .set(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Update",
              this.lesson,
              this.$store.state.subject,
              "Video"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Video Created", {
              title: "Video Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.lesson.title == null) {
        alert("Please Enter the Activity Title");
      } else {
        this.lesson.type = "Live Session";
        this.lesson.order = Number(this.order) + 1;

        this.ref
          .add(this.lesson)
          .then(() => {
            this.AddLog(
              this.$store.state.username,
              this.$store.state.firstname,
              this.$store.state.lastname,
              "Create",
              this.lesson,
              this.$store.state.subject,
              "Activity"
            );

            this.lesson.title = "";
            this.lesson.link = "";

            this.$bvToast.toast("Activity Created", {
              title: "Activity Created Successfully",
              variant: "success",
              solid: true
            });
            this.$modal.hideAll();
          })
          .catch(error => {
            alert("Error adding document: ", error);
          });
      }
    },
    goToLive() {
      window.open("https://teams.microsoft.com", "_blank");
    }
  },
  mount() {
    this.show();
  },

  created() {
    if (this.source == "edit") {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.lesson = doc.data();
          } else {
            alert("No such document!");
          }
        });
    }
  }
};
</script>
<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(24, 146, 0, 0.904);
  cursor: pointer;
}
</style>
