<template>
  <v-card
    flat
    outlined
    width="300px"
    @click="goToSubmissions"
    style=" margin-right: 20px;"
  >
  
    <v-card-text :class="this.thekey == '' ? 'zero' : 'grade'">
      {{ this.grade }}
    </v-card-text>
  </v-card>
</template>
<script>
import firebase from "@/Firebase";
// import router from "@/router";

export default {
  name: "GradeLesson",
  props: ["student", "assignmant", "title", "weight", "asspath", "studentid"],
  data() {
    return {
      assignments: [],
      lessons: [],
      weights: [],
      participation: [],
      week: "",
      grade: 0,
      thekey: ""
    };
  },
  created() {

    firebase
        .firestore()
        .collection(this.$store.state.originalsubject + "/submissions")
        .doc(this.studentid + this.assignmant)
        .get()
        .then(doc => {
          if (doc.data()) {
            console.log(doc.data());
            this.grade = doc.data().right;
            this.thekey = doc.id;
          }
        });



    // if (this.title == "Flipped") {
    //   firebase
    //     .firestore()
    //     .collection(this.$store.state.originalsubject + "/flipped")
    //     .doc(this.studentid + this.assignmant)
    //     .get()
    //     .then(doc => {
    //       if (doc.data()) {
        
    //         this.grade = doc.data().grade;
    //         this.thekey = doc.id;
    //       }
    //     });
    // } else if (this.title == "Exit") {
    //   firebase
    //     .firestore()
    //     .collection(this.$store.state.originalsubject + "/exit")
    //     .doc(this.studentid + this.assignmant)
    //     .get()
    //     .then(doc => {
    //       if (doc.data()) {
    //         this.grade = doc.data().grade;
    //         this.thekey = doc.id;
    //       }
    //     });
    // } else if (this.title == "Inclass") {
    //   firebase
    //     .firestore()
    //     .collection(this.$store.state.originalsubject + "/inclass")
    //     .doc(this.studentid + this.assignmant)
    //     .get()
    //     .then(doc => {
    //       if (doc.data()) {
    //         this.grade = doc.data().grade;
    //         this.thekey = doc.id;
    //       }
    //     });
    // } else if (this.title == "Participation") {
    //   firebase
    //     .firestore()
    //     .collection(this.student + "/participation")
    //     .doc(this.assignmant)
    //     .get()
    //     .then(doc => {
    //       if (doc.data()) {
    //         this.grade = doc.data().grade;
    //         this.thekey = doc.id;
    //       }
    //     });
    // } else {
    //   firebase
    //     .firestore()
    //     .collection(this.$store.state.originalsubject + "/submissions")
    //     .doc(this.studentid + this.assignmant)
    //     .get()
    //     .then(doc => {
    //       if (doc.data()) {
    //         this.grade = doc.data().right;
    //         this.thekey = doc.id;
    //       }
    //     });
    // }
  },
  methods: {
    showTitle() {
      this.$swal.fire({
        title: this.weight + "-" + this.title,
        html:
          "<a href='google.com'> Click Here To Go To Assigments Submissions</a>"
      });
    },

    goToSubmissions() {
      if (
        this.title == "Flipped" ||
        this.title == "Exit" ||
        this.title == "Participation"
      ) {
        console.log("");
      } else {
        localStorage.setItem("assid", this.assignmant);
        localStorage.setItem("asspath", this.asspath);

        let routeData = this.$router.resolve({
          name: "Submissions",
          params: {
            id: this.assignmant,
            title: this.title,
            path: this.asspath,
            grade: this.grade,
            assid: this.assignmant,
            type: this.type
          }
        });
        window.open(routeData.href, "_blank");

        // router.push({
        //   name: "Submissions",
        //   params: {
        //     id: this.assignmant,
        //     title: this.title,
        //     path: this.asspath,
        //     grade: this.grade,
        //     assid: this.assignmant,
        //     type: this.type
        //   }
        // });
      }
    }
  }
};
</script>
<style scoped>
.zero {
  background-color: lightpink;
  float: left;
  text-align: center;

  cursor: pointer;
}
.grade {
  background-color: lightgreen;
  float: left;
  text-align: center;

  cursor: pointer;
}
</style>
