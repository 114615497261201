<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Edit Assignment</v-card-title>
        <v-card-subtitle> {{ this.$route.params.title }} </v-card-subtitle>

        <v-card-text>
          <div>
            <v-stepper v-model="e1">
              <b-form @submit.stop.prevent="onSubmit">
                <v-stepper-header>
                  <v-stepper-step editable :complete="e1 > 1" step="1">
                    Assignment Type
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step editable :complete="e1 > 2" step="2">
                    Assignment Options
                  </v-stepper-step>
                  <v-divider></v-divider>

                  <v-stepper-step editable step="3">
                    Assignment Schedule
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <b-card height="500px">
                      <!--Assignment Type -->
                      <b-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="3">

                             <b-select
               label="Assignment Type"
            
              v-model.trim="assign.type" :options="types"
              ></b-select>
   



                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              id="Assignmenttitle"
                              label="Assignment Title"
                              required
                              autofocus
                              v-model="assign.title"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="6" md="3">
                            <b-form-checkbox
                              v-model="assign.graded"
                              name="check-button"
                              switch
                            >
                              Assignment Not Gradable
                            </b-form-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">

                                   <b-select
               label="Assignment Weight"
              
              v-model.trim="assign.weight" :options="weights"
              ></b-select>



                           
                          </v-col>

                          <v-col cols="12" sm="6" md="2">
                            <v-text-field
                              id="Gradetitle"
                              label="Grade"
                              type="number"
                              required
                              autofocus
                              v-model="assign.grade"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </b-card-text>
                      <!-- End Assignment Type -->
                      <template v-slot:footer>
                        <v-btn color="info" @click="backStep">
                          Back
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="nextStep"
                          style="margin-left:10px"
                        >
                          Continue
                        </v-btn>
                      </template>
                    </b-card>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <b-card height="500px">
                      <b-card-text>
                        <div v-if="assign.file">
                          <a :href="assign.file" target="_blank">
                            Click Here to View The Attached File</a
                          ><v-btn
                            color="red"
                            dark
                            small
                            depressed
                            style="margin-left:10px"
                            @click="clearFile($route.params.path)"
                            >Remove File</v-btn
                          >
                        </div>

                        <!-- Upload File -->

                        <v-col cols="12" md="12">
                          <v-row>
                            <v-col cols="8" md="8">
                              <v-file-input
                                show-size
                                accept=".pdf, .pptx, .doc, .docx"
                                prepend-icon="mdi-file"
                                label="File (Files Max Size 60 MB)"
                                @change="previewImage"
                              ></v-file-input>
                              {{ uploadValue.toFixed() + "%" }}
                              <v-progress-linear
                                id="progress"
                                color="light-blue"
                                height="10"
                                :value="uploadValue"
                                max="100"
                                striped
                              ></v-progress-linear>
                            </v-col>
                            <v-col cols="4" md="4">
                              <b-button @click="onUpload" variant="primary"
                                >Upload File</b-button
                              >
                            </v-col>
                          </v-row>
                        </v-col>

                        <!-- End Of File -->

                        <div
                          v-if="
                            assign.type == 'Quiz Assignment' ||
                              assign.type == 'Diagnostic Assessment'
                          "
                        >
                          <!-- Quiz Area  -->
                          <v-switch
                            inset
                            v-model="assign.feedback"
                            color="green"
                            label="Show Answer Feedback"
                          ></v-switch>

                                 <b-select label="Quiz Type"
                                 v-model.trim="assign.quiztype" :options="quiztypes">
                                 </b-select>



                      

                          <!--Fixed Questions-->
                          <div v-if="assign.quiztype == 'Fixed Questions'">
                            <div>
                              <v-alert
                                color="orange"
                                dark
                                dense
                                icon="mdi-help-circle-outline"
                                prominent
                              >
                                Quiz Questions
                              </v-alert>

                              <!-- Fixed Questions Dialog -->
                              <v-dialog v-model="dialog" width="60%">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="grey darken-3"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    style="margin-bottom:20px;"
                                  >
                                    <v-icon
                                      color="orange"
                                      style="padding-right:20px;"
                                      >mdi-help-circle-outline</v-icon
                                    >
                                    Select from Question Bank
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title>
                                    <span class="headline">
                                      <v-icon>mdi-help-circle-outline</v-icon>
                                      Select Question</span
                                    >
                                  </v-card-title>

                                  <v-card-text>
                                    <!-- Select Questions -->
                                    <v-row class="card-text">
                                      <v-col class="d-flex" cols="12" sm="12">
                                        <v-autocomplete
                                          v-model.trim="selectedquestion"
                                          :items="questionsbank"
                                          dense
                                          label="Select Question"
                                        ></v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                      color="red darken-1"
                                      text
                                      @click="dialog = false"
                                      >Back</v-btn
                                    >
                                    <v-btn
                                      color="green darken-1"
                                      text
                                      @click="addToFixed"
                                      >Add Question</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>

                              <!-- End Fixed Questions  Dialog -->
                              <!-- List of Fixed Questions -->

                              <FixedQuestion
                                v-for="(q, index) in fixedquestions"
                                :key="q"
                                :path="q"
                                :index="index"
                              >
                                <v-btn
                                  style="margin-left:20px"
                                  small
                                  color="red"
                                  @click="deleteQuestion(index)"
                                >
                                  <v-icon>mdi-trash-can-outline</v-icon>

                                  Delete Question</v-btn
                                ></FixedQuestion
                              >

                              <!-- End List of Fixed Questions -->
                            </div>
                          </div>

                          <!--End Fixed Questions-->

                          <!--Random Questions-->

                          <div
                            v-else-if="
                              assign.quiztype == 'Random From Question Bank'
                            "
                          >
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  v-model.trim="assign.count"
                                  label="Questions Count"
                                  type="number"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </div>

                          <!--EndRandom Questions-->
                        </div>
                        <div v-else-if="assign.type == 'Textbook Assignment'">
                          <b-form-group
                            id="fieldanswer"
                            horizontal
                            :label-cols="2"
                            breakpoint="md"
                            label="Assignment Description"
                          >
                            <quill-editor ref="answer" v-model="description" />
                          </b-form-group>

                          <b-form-group
                            id="fieldreading"
                            horizontal
                            :label-cols="2"
                            breakpoint="md"
                            label="Reading Paragraph"
                          >
                            <v-textarea
                              v-model="reading"
                              label="Reading Paragraph "
                            ></v-textarea>
                          </b-form-group>
                        </div>
                      </b-card-text>
                      <template v-slot:footer>
                        <v-btn color="info" @click="backStep">
                          Back
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="nextStep"
                          style="margin-left:10px"
                        >
                          Continue
                        </v-btn>
                      </template>
                    </b-card>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <b-card height="300px">
                      <b-card-text>
                        <v-switch
                          inset
                          v-model="assign.open"
                          color="green"
                          label="Always Open"
                        ></v-switch>

                        <v-row>
                          <!-- Start Date Name -->
                          <v-col cols="12" sm="6" md="4">
                            <label for="closedate">Assignment Open Date</label>
                            <b-form-datepicker
                              id="opendate"
                              v-model="assign.opendate"
                              class="mb-2"
                            ></b-form-datepicker>
                          </v-col>
                          <!-- End Date Name -->

                          <!-- Start Date Name -->
                          <v-col cols="12" sm="6" md="4">
                            <label for="cutoff">Assignment Due Date</label>
                            <b-form-datepicker
                              id="cutoff"
                              v-model="assign.cutoff"
                              class="mb-2"
                            ></b-form-datepicker>
                          </v-col>

                          <!-- Start Date Name -->
                          <v-col cols="12" sm="6" md="4">
                            <label for="closedate"
                              >Assignment Cut-off Date</label
                            >
                            <b-form-datepicker
                              id="closedate"
                              v-model="assign.closedate"
                              class="mb-2"
                            ></b-form-datepicker>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="2">
                            <v-text-field
                              v-model.trim="assign.time"
                              label="Time Limit"
                              type="number"
                            ></v-text-field>
                            Minutes
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model.trim="assign.attempts"
                              label="Number of allowed attempts"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- End Date Name -->
                      </b-card-text>
                      <template v-slot:footer>
                        <v-btn color="primary" @click="onSubmit">
                          Update
                        </v-btn>
                      </template>
                    </b-card>
                  </v-stepper-content>
                </v-stepper-items>
              </b-form>
            </v-stepper>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
import FixedQuestion from "./FixedQuestion";
import router from "@/router";

export default {
  name: "EditAssignment",
  props: ["week", "title"],
  components: { FixedQuestion },
  data() {
    return {
      e1: 1,
      menu: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      assign: {},
      selected: null,
      standards: [],
      dialog: false,
      selectedquestion: "",
      fixedquestions: [],
      questionsbank: [],
      description: "",
      reading: "",
      subject: this.$store.state.subjectTitle,
      weekpath: localStorage.getItem("weekpath"),
      fields: [
        {
          field: "school",
          label: "School",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "class",
          label: "Class",
          sortable: true,
          class: "text-left"
        },

        {
          field: "actions",
          label: "Action",
          class: "text-center",
          width: "100px"
        }
      ],
      ref: firebase.firestore().doc(this.$route.params.path),

      quiztypes: [
        {
          value: "Random From Question Bank",
          text: "Random From Question Bank"
        },
        {
          value: "Fixed Questions",
          text: "Fixed Questions"
        }
      ],
      types: [
        { value: "Quiz Assignment", text: "Digitized Assignment" },
        { value: "Textbook Assignment", text: "Assignment" },
        { value: "Diagnostic Assessment", text: "Diagnostic Assessment" }
      ],

      weights: [],
      //Upload Image
      imageData: null,
      picture: null,
      uploadValue: 0,
      files: ""
    };
  },
  created() {

    firebase
      .firestore()
      .doc(this.$route.params.path)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) return;
        this.assign = snapshot.data();
        // this.fixedQuestions = snapshot.data().fixedquestions;

        firebase
          .firestore()
          .doc(this.$route.params.path + "/details/description")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.description = snapshot.data().description;
          });

        firebase
          .firestore()
          .doc(this.$route.params.path + "/details/reading")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.reading = snapshot.data().reading;
          });

        firebase
          .firestore()
          .doc(this.$route.params.path + "/details/fixedquestions")
          .get()
          .then(snapshot => {
            if (!snapshot.exists) return;
            this.fixedquestions = snapshot.data().fixedquestions;
          });
      });

    firebase
      .firestore()
      .collection(this.$store.state.subject + "/weights")
      .onSnapshot(querySnapshot => {
        this.weights = [];
        querySnapshot.forEach(doc => {
          this.weights.push({
            value: doc.data().category,
            text: doc.data().category
          });
        });
      });

    firebase
      .firestore()
      .collection(localStorage.getItem("weekpath") + "/questions")
      .onSnapshot(querySnapshot => {
        this.questionsbank = [];
        querySnapshot.forEach(doc => {
          if (doc.data().exam != "Yes")
            this.questionsbank.push({
              value: doc.ref.path,
              text: doc.data().title.replace(/<\/?[^>]+(>|$)/g, "")
            });
        });
      });

    firebase
      .firestore()
      .collection(this.$store.state.subject + "/standards")
      .onSnapshot(querySnapshot => {
        this.standards = [];
        querySnapshot.forEach(doc => {
          this.standards.push({
            value: doc.ref.id,
            text: doc.data().code
          });
        });
      });
  },
  methods: {
    previewImage(event) {
      this.imageData = event;
      console.log(this.imageData);
    },

    onUpload() {
      if (this.files.size / 1048576 > 60) {
        alert("The File Is Bigger Than 60 MB");
      } else {
        this.picture = null;
        const storageRef = firebase
          .storage()
          .ref(
            "schools/" +
              this.$store.state.school +
              "/resources/" +
              Math.floor(Math.random() * 900000 + 1000000).toString() +
              this.imageData.name
          )
          .put(this.imageData);

        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              this.picture = url;
            });
          }
        );
      }
    },
    deleteQuestion(index) {
      this.fixedquestions.splice(index, 1);
    },
    onSubmit(evt) {
      evt.preventDefault();

      // this.assign.fixedquestions = this.fixedQuestions;

      if (this.picture != null) {
        this.assign.file = this.picture;
      }
      this.assign.breakdown = localStorage.getItem("lessonBreakdown")
      this.ref
        .update(this.assign)
        .then(() => {
          firebase
            .firestore()
            .doc(this.$route.params.path + "/details/description")
            .set({
              description: this.description == null ? " " : this.description
            });
          firebase
            .firestore()
            .doc(this.$route.params.path + "/details/reading")
            .set({ reading: this.reading == null ? " " : this.reading });
          firebase
            .firestore()
            .doc(this.$route.params.path + "/details/fixedquestions")
            .set({
              fixedquestions:
                this.fixedquestions == null ? " " : this.fixedquestions
            });

          this.$bvToast.toast("Assignment Updated", {
            title: "Assignment Updated Successfully",
            variant: "success",
            solid: true
          });

          router.back();
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    addToFixed() {
      if (this.fixedquestions.includes(this.selectedquestion)) {
        alert("This Question alrady in this Quiz");
      } else {
        this.fixedquestions.push(this.selectedquestion);
      }
    },

    clearFile(path) {
      firebase
        .firestore()
        .doc(path)
        .update({ file: null })
        .then(() => {
          this.$bvToast.toast("File Removed", {
            title: "File Removed Successfully",
            variant: "success",
            solid: true
          });
          this.link = null;
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    },

    nextStep() {
      if (this.e1 < 3) {
        this.e1 = Number(this.e1) + 1;
      } else {
        this.e1 = 1;
      }
    },
    backStep() {
      if (this.e1 > 1) this.e1 = Number(this.e1) - 1;
    }
  }
};
</script>
